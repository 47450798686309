import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import { Input } from '@gamma/form-fields';
import { i18n } from '@gamma/investigator/localization';
import { Alert } from '@gamma/overlay';
import { CognitoUserInterface } from '@gamma/shared/types';
import { formatOTPCode } from '@gamma/util';
import { Auth } from 'aws-amplify';
import { ChangeEvent, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { IHelperText, OnMFACompletedType, SetStepType } from '../types';

interface MfaHintInterface {
  hint: string;
  error: string;
}

interface AuthMFABodyProps {
  isButtonLoading: boolean;
  width?: string;
  helperTexts: IHelperText;
  verifyUser: CognitoUserInterface;
  isExportNoMfa?: boolean;
  setIsButtonLoading: (e: boolean) => void;
  setIsViewInfoButtonDisabled: (e: boolean) => void;
}

interface AuthMFAFooterProps {
  isButtonLoading: boolean;
  isViewInfoButtonDisabled: boolean;
  helperTexts: IHelperText;
  isExportNoMfa?: boolean;
  onClose: () => void;
  setStep: SetStepType;
  onMFACompleted: OnMFACompletedType;
}

interface MfaForm {
  OTPCode: string;
}

export const AuthMFABody = ({
  isButtonLoading,
  width = '86%',
  helperTexts,
  isExportNoMfa,
  setIsButtonLoading,
  setIsViewInfoButtonDisabled,
  verifyUser,
}: AuthMFABodyProps) => {
  const { mfaValid, mfaInvalid } = i18n.forms.validation;
  const [mfaHint, setMfaHint] = useState<MfaHintInterface>({
    hint: '',
    error: '',
  });
  const { register, handleSubmit, setFocus, setValue } = useForm<MfaForm>();
  const { text, warning } = helperTexts.mfa;

  useEffect(() => {
    setFocus('OTPCode');
  }, []);

  const handleOTPInputChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setValue('OTPCode', formatOTPCode(value));
  };
  const handleMFASubmit: SubmitHandler<{ OTPCode: string }> = async ({
    OTPCode,
  }) => {
    setIsButtonLoading(true);
    try {
      const verifiedUser = await Auth.confirmSignIn(
        verifyUser,
        OTPCode.replace(' ', ''),
        'SOFTWARE_TOKEN_MFA',
      );

      if (verifiedUser) {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          setMfaHint({ hint: mfaValid, error: '' });
          setIsViewInfoButtonDisabled(false);
        }
      }
    } catch (error) {
      setMfaHint({ hint: '', error: mfaInvalid });
    }

    setIsButtonLoading(false);
  };

  return (
    <VStack spacing={4}>
      {warning && <Alert status="warning" title={warning} />}
      {!isExportNoMfa && (
        <>
          <Text alignSelf="flex-start">{helperTexts.mfa.text}</Text>

          <HStack
            w={width}
            p={4}
            borderRadius="4px"
            as="form"
            onSubmit={handleSubmit(handleMFASubmit)}
          >
            <Input
              {...register('OTPCode', {
                onChange: handleOTPInputChange,
              })}
              data-testid="auth-modal-otp-input"
              label={i18n.forms.labels.enterOTP}
              hint={mfaHint.hint}
              error={mfaHint.error}
              placeholder="xxx xxx"
              isLabelHidden
            />
            <Button
              alignSelf="flex-start"
              data-testid="auth-modal-otp-verify"
              variant="solid"
              isLoading={isButtonLoading}
              type="submit"
            >
              {i18n.buttons.verify}
            </Button>
          </HStack>
        </>
      )}
    </VStack>
  );
};
