import { ApolloError, useApolloClient } from '@apollo/client';
import { Box, Flex, HStack, Text, VStack, usePrevious } from '@chakra-ui/react';
import { IPill, MuiIcon, PillBox } from '@gamma/display';
import { AsyncSelect, Checkbox, Input } from '@gamma/form-fields';
import { ROUTES } from '@gamma/investigator/constants';
import { useDateRangeQueryString } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  Detection,
  IQueryDetectionsPaginated,
  QUERY_DETECTIONS_FIELD_SUGGESTIONS,
  useQueryDetectionsPaginated,
} from '@gamma/investigator/queries';
import { isEqual } from 'lodash';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { SortingRule } from 'react-table';

import { IDetectionContext } from '../../../../../../Detection';

interface SelectOption {
  label: string;
  value: string;
}

interface DetectionsFormData {
  alertCategory: SelectOption[];
  entity: SelectOption[];
  limitAlertCategory: boolean | string;
  limitEntity: boolean | string;
}

const { controls, reset } = i18n.pages.detections;

const {
  filters,
  alertCategory,
  searchCategory,
  entity,
  searchEntity,
  noResults,
  adjustSearch,
} = controls;

const { detectionDetails } = ROUTES;

const LIMIT_ALERT_CATEGORY = 'true';
const LIMIT_ENTITY = 'true';

const defaultFilterValues = {
  alertCategory: [],
  limitAlertCategory: false,
  entity: [],
  limitEntity: false,
};

interface DetectionsFiltersProps {
  size: number;
  offset: number;
  getPageCount: any;
  getSortsForAPI: (
    sortBy: SortingRule<Detection>[],
  ) => { sort_by: string; sort_dir: string }[];
  formatSort: (value: string) => { id: string; desc: boolean }[];
  setQueryVariables: React.Dispatch<React.SetStateAction<any>>;
  setDetectionsData: React.Dispatch<
    React.SetStateAction<IQueryDetectionsPaginated | undefined>
  >;
  setDetectionsError: React.Dispatch<
    React.SetStateAction<ApolloError | undefined>
  >;
  setDetectionsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RelatedFilters = memo(
  ({
    size,
    offset,
    formatSort,
    getPageCount,
    getSortsForAPI,
    setQueryVariables,
    setDetectionsData,
    setDetectionsError,
    setDetectionsLoading,
  }: DetectionsFiltersProps) => {
    const { detectionData, setFetchRelatedCount, setFetchRelatedLoading } =
      useOutletContext<IDetectionContext>();

    const client = useApolloClient();
    const { start, end } = useDateRangeQueryString();

    const {
      alert_entity,
      alert_info,
      detection_id,
      detection_keys,
      tenant_info,
      earliest_start_timestamp,
      latest_start_timestamp,
    } = detectionData?.getDetections?.detections?.[0] || {};

    const { entity_name } = alert_entity || {};
    const { alert_name } = alert_info || {};

    const { search } = useLocation();
    const navigate = useNavigate();
    const params = useMemo(() => new URLSearchParams(search), [search]);

    const sort_by = params.get('sort_by');
    const date_start = params.get('start');
    const date_end = params.get('end');

    const { control, register, setValue, watch } = useForm<DetectionsFormData>({
      mode: 'onSubmit',
      defaultValues: defaultFilterValues,
    });

    const formData = watch();

    useEffect(() => {
      if (!date_end || !date_start) {
        params.set('start', String(earliest_start_timestamp));
        params.set('end', String(latest_start_timestamp));
        navigate({
          pathname: detectionDetails(detection_id),
          search: params.toString(),
        });
      }
    }, [
      date_end,
      date_start,
      detection_id,
      earliest_start_timestamp,
      latest_start_timestamp,
      navigate,
    ]);

    const [pills, setPills] = useState<IPill[] | []>([]);
    const [previousFormData, setPreviousFormData] =
      useState<DetectionsFormData | null>(null);

    const buildPills = useCallback(() => {
      const newPills: IPill[] = [];

      Object.keys(formData).forEach((key) => {
        if (key === 'alertCategory' || key === 'entity') {
          if (formData[key].length > 0) {
            formData[key]?.forEach((assignee) => {
              const pill: IPill = {
                label: assignee.label,
                value: assignee.value,
              };
              newPills.push(pill);
            });
          }
        }

        setPills(newPills);
      });
    }, [formData, pills]);

    const handleOnPillClose = (pill: IPill, pillIndex: number) => {
      const { value } = pill;

      if (value.includes(alert_name)) {
        // set to false because defaultFilterValues.openStatus has it enabled
        setValue('limitAlertCategory', false);
      }

      if (value.includes(entity_name)) {
        // set to false because defaultFilterValues.openStatus has it enabled
        setValue('limitEntity', false);
      }

      const isInAlertCategoryList = formData.alertCategory.some(
        (category) => category.value === value,
      );

      const isInEntityList = formData.entity.some(
        (entity) => entity.value === value,
      );

      if (isInAlertCategoryList) {
        const newAlertCategoryList = formData.alertCategory.filter(
          (category) => category.value !== value,
        );
        setValue('alertCategory', newAlertCategoryList);
      }

      if (isInEntityList) {
        const newEntityList = formData.entity.filter(
          (entity) => entity.value !== value,
        );
        setValue('entity', newEntityList);
      }
    };

    const onFilterClear = () => {
      setValue('limitAlertCategory', defaultFilterValues.limitAlertCategory);
      setValue('limitEntity', defaultFilterValues.limitEntity);
      setValue('alertCategory', defaultFilterValues.alertCategory);
      setValue('entity', defaultFilterValues.entity);
      buildPills();
    };

    const filtersChanged = () => {
      return !isEqual({ ...formData }, { ...defaultFilterValues });
    };

    useEffect(() => {
      if (!isEqual(previousFormData, formData)) {
        setPreviousFormData(formData);
        buildPills();
      }
    }, [formData, previousFormData, buildPills]);

    const wrapQuery = (conditions: string) =>
      `{"query":{"bool":{"must":[${conditions}],"must_not":{"term":{"detection_id":"${detection_id}"}}}}}`;

    const wrapSuggestionsQuery = (conditions: string) =>
      `{"query":{"bool":{"must":[${conditions}],"must_not":{"term":{"detection_id":"${detection_id}"}}}}}`;

    const [sharedConditions, setSharedConditions] = useState<string>();

    useEffect(() => {
      if (
        start &&
        end &&
        tenant_info?.tenant_id &&
        detection_keys?.tenant_detection &&
        detection_keys?.tenant_entity
      )
        setSharedConditions(
          `{"range":{"total_alert_count":{"gt":0}}},{"range":{"latest_start_timestamp":{"gte":${start}}}},{"range":{"earliest_start_timestamp":{"lte":${end}}}}${
            tenant_info?.tenant_id ? ',' : ''
          }${
            tenant_info?.tenant_id
              ? `{"terms":{"tenant":["${tenant_info?.tenant_id}"]}}`
              : ''
          },{"bool":{"should":[{"term":{"detection_keys.tenant_detection":"${
            detection_keys?.tenant_detection
          }"}},{"term":{"detection_keys.tenant_entity":"${
            detection_keys?.tenant_entity
          }"}}]}}`,
        );
    }, [
      start,
      end,
      tenant_info?.tenant_id,
      detection_keys?.tenant_detection,
      detection_keys?.tenant_entity,
    ]);

    const conditions = `${sharedConditions}${
      formData.alertCategory.length > 0 ? ',' : ''
    }${
      formData.alertCategory.length > 0
        ? `{"bool":{"should":[${formData.alertCategory
            ?.map((category) =>
              category?.value !== ''
                ? `{"term":{"alert_info.alert_name.keyword":{"value":"${category?.value}"}}}`
                : '',
            )
            .join(',')}]}}`
        : ''
    }${formData.entity.length > 0 ? ',' : ''}${
      formData.entity.length > 0
        ? `{"bool":{"should":[${formData.entity
            ?.map((entity) =>
              entity?.value !== ''
                ? `{"term":{"alert_entity.entity_name.keyword":{"value":"${entity?.value}"}}}`
                : '',
            )
            .join(',')}]}}`
        : ''
    }`;

    const [query, setQuery] = useState<string>('');

    useEffect(() => {
      if (sharedConditions) {
        setQuery(wrapQuery(conditions));
      }
    }, [formData, sharedConditions]);

    const variables = useMemo(() => {
      setQueryVariables({
        query,
        size,
        offset,
        sortBy: sort_by ?? '',
      });
      return {
        query,
        size,
        offset,
        sortBy: sort_by ?? '',
      };
    }, [query, size, offset, sort_by, setQueryVariables]);

    const [fetchData, { called, loading, data, error, refetch }] =
      useQueryDetectionsPaginated({
        variables: {
          query: variables.query,
          size: variables.size,
          offset: variables.offset,
          sort: getSortsForAPI(formatSort(variables.sortBy)),
        },
        onCompleted: (data) => {
          setDetectionsData(data);
          getPageCount(data?.queryDetectionsPaginated);
          setFetchRelatedCount(data?.queryDetectionsPaginated?.total_items);
        },
      });

    useEffect(() => {
      if (
        sharedConditions &&
        variables?.query &&
        variables?.sortBy &&
        tenant_info?.tenant_id
      ) {
        fetchData();
      }
    }, [fetchData, sharedConditions, variables]);

    useEffect(() => {
      setDetectionsError(error);
      setDetectionsLoading(loading);
      setFetchRelatedLoading(loading);
    }, [error, loading, setDetectionsError, setDetectionsLoading]);

    const getSuggestions = async (inputValue: string, matchValue: string) => {
      const { data } = await client.query({
        fetchPolicy: 'network-only',
        query: QUERY_DETECTIONS_FIELD_SUGGESTIONS,
        variables: {
          query: wrapSuggestionsQuery(
            `${sharedConditions},{"match_phrase":{"${matchValue}":{"query":"${inputValue}","analyzer":"standard"}}}`,
          ),
          collapse_field: `${matchValue}.keyword`,
          size: 10,
          sort: [
            {
              sort_by: `${matchValue}.keyword`,
              sort_dir: 'asc',
            },
          ],
        },
      });
      const suggestions = data?.queryDetectionsFieldSuggestions?.map(
        (suggestion: string) => {
          return {
            label: suggestion,
            value: suggestion,
          };
        },
      );
      return suggestions;
    };

    const prevData = usePrevious(data);
    useEffect(() => {
      if (data !== prevData) {
        setDetectionsData(data);
      }
    }, [data, prevData, setDetectionsData]);

    useEffect(() => {
      if (formData?.limitAlertCategory) {
        setValue('alertCategory', [{ label: alert_name, value: alert_name }]);
      } else {
        setValue('alertCategory', defaultFilterValues.alertCategory);
      }
    }, [formData?.limitAlertCategory]);

    useEffect(() => {
      if (formData?.limitEntity) {
        setValue('entity', [{ label: entity_name, value: entity_name }]);
      } else {
        setValue('entity', defaultFilterValues.entity);
      }
    }, [formData?.limitEntity]);

    return (
      <>
        <Text ml={4} as="span" textStyle="h5">
          {filters}
        </Text>
        <Flex px={4} pb={4} w="100%">
          <PillBox
            pills={pills}
            layerStyle="first"
            colorScheme="gray"
            clearButtonText={reset}
            onClearAll={onFilterClear}
            panelCustomStyles={{
              border: 0,
              padding: 0,
              minHeight: '26px',
            }}
            onPillClose={(pill, index) => handleOnPillClose(pill, index)}
            shouldShowClearAllButton={filtersChanged()}
          />
        </Flex>
        <VStack pb={4} as="form" spacing={3} alignItems="start">
          <HStack px={4} spacing={4}>
            <Box w="275px">
              {!formData?.limitAlertCategory ? (
                <Controller
                  name={'alertCategory'}
                  control={control}
                  render={({ field: { name, value } }) => {
                    return (
                      <AsyncSelect
                        data-testid="detection-alert-category-async-select"
                        name={name}
                        cacheOptions
                        isMenuPortal={true}
                        value={value}
                        leftElement={<MuiIcon size="sm">search</MuiIcon>}
                        onChange={(selectedValue) => {
                          const hasAlreadySelectedValue = value.some(
                            (item) =>
                              item.label ===
                              (selectedValue as SelectOption)?.label,
                          );

                          const hasAlertCategory =
                            (selectedValue as SelectOption)?.value !== ''
                              ? selectedValue
                              : null;

                          if (hasAlertCategory && !hasAlreadySelectedValue) {
                            setValue('alertCategory', [
                              ...value,
                              selectedValue,
                            ] as SelectOption[]);
                          }
                        }}
                        label={alertCategory}
                        placeholder={searchCategory}
                        loadOptions={async (event) => {
                          const loadedOptions = await getSuggestions(
                            event.length > 20 ? event.slice(0, 20) : event,
                            'alert_info.alert_name',
                          );
                          return loadedOptions;
                        }}
                        // defaultOptions={categorySuggestions}
                        noOptionsMessage={
                          <Box px={2.5} textAlign="left">
                            <Text mb={2.5} textStyle="body-md" fontWeight={600}>
                              {noResults}
                            </Text>
                            <Text textStyle="body-md">{adjustSearch}</Text>
                          </Box>
                        }
                        components={{
                          DropdownIndicator: null,
                        }}
                      />
                    );
                  }}
                />
              ) : (
                alert_name && (
                  <Input
                    name=""
                    label={alertCategory}
                    isReadOnly={true}
                    value={alert_name}
                  />
                )
              )}
            </Box>
            <Box w="275px">
              {!formData?.limitEntity ? (
                <Controller
                  name={'entity'}
                  control={control}
                  render={({ field: { name, value } }) => {
                    return (
                      <AsyncSelect
                        data-testid="detection-alert-entity-async-select"
                        name={name}
                        cacheOptions
                        isMenuPortal
                        value={value}
                        leftElement={<MuiIcon size="sm">search</MuiIcon>}
                        width="100%"
                        onChange={(selectedValue) => {
                          const hasAlreadySelectedValue = value.some(
                            (entity) =>
                              entity.label ===
                              (selectedValue as SelectOption).label,
                          );
                          const hasEntity =
                            (selectedValue as SelectOption)?.value !== ''
                              ? selectedValue
                              : null;
                          if (hasEntity && !hasAlreadySelectedValue) {
                            setValue('entity', [
                              ...value,
                              selectedValue,
                            ] as SelectOption[]);
                          }
                        }}
                        label={entity}
                        placeholder={searchEntity}
                        loadOptions={async (event) => {
                          const loadedOptions = await getSuggestions(
                            event.length > 20 ? event.slice(0, 20) : event,
                            'alert_entity.entity_name',
                          );
                          return loadedOptions;
                        }}
                        // defaultOptions={entitySuggestions}
                        noOptionsMessage={
                          <Box px={2.5} textAlign="left">
                            <Text mb={2.5} textStyle="body-md" fontWeight={600}>
                              {noResults}
                            </Text>
                            <Text textStyle="body-md">{adjustSearch}</Text>
                          </Box>
                        }
                        components={{
                          DropdownIndicator: null,
                        }}
                      />
                    );
                  }}
                />
              ) : (
                entity_name && (
                  <Input
                    name=""
                    label={entity}
                    isReadOnly={true}
                    value={entity_name}
                  />
                )
              )}
            </Box>
          </HStack>
          <HStack px={4} w="100%" spacing={4} alignItems="start">
            <Box w="275px">
              <Controller
                name="limitAlertCategory"
                control={control}
                render={() => (
                  <Checkbox
                    {...register('limitAlertCategory')}
                    data-testid="detection-status-open"
                    value={LIMIT_ALERT_CATEGORY}
                    isChecked={!!formData.limitAlertCategory}
                  >
                    <Text textStyle="body-sm">
                      Show detections from the current category
                    </Text>
                  </Checkbox>
                )}
              />
            </Box>
            <Box w="275px">
              <Controller
                name="limitEntity"
                control={control}
                render={() => (
                  <Checkbox
                    {...register('limitEntity')}
                    data-testid="detection-status-closed"
                    value={LIMIT_ENTITY}
                    isChecked={!!formData.limitEntity}
                  >
                    <Text textStyle="body-sm">
                      Show detections from the current entity
                    </Text>
                  </Checkbox>
                )}
              />
            </Box>
          </HStack>
        </VStack>
      </>
    );
  },
);
