import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';
import { IGetAlertMetadata } from './types';

export const GET_ALERT_METADATA_SURICATA_DESCRIBE_RULE = gql`
  query getAlertMetadata($items: [GetAlertMetadataInput]!) {
    getAlertMetadata(items: $items) {
      metadata {
        chatbot_chats {
          suri_describe_rule
        }
      }
    }
  }
`;

export const useGetAlertMetadataSuricataRuleDescription = (
  options: QueryHookOptions<IGetAlertMetadata, OperationVariables>,
) =>
  useQuery<IGetAlertMetadata>(
    GET_ALERT_METADATA_SURICATA_DESCRIBE_RULE,
    options,
  );
