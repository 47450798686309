import { createIcon } from '@chakra-ui/react';

export const LockIcon = createIcon({
  displayName: 'LockIcon',
  viewBox: '0 0 24 24',
  d: 'M18 8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2h1V6a5 5 0 1 1 10 0v2h1Zm-6-5a3 3 0 0 0-3 3v2h6V6a3 3 0 0 0-3-3Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
