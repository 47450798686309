import notFound from './404.json';
import access from './Access.json';
import userAccount from './Account.json';
import alertDetails from './AlertDetails.json';
import alertExport from './AlertExport.json';
import alerts from './Alerts.json';
import appFooter from './AppFooter.json';
import detections from './Detections.json';
import detectionSettings from './DetectionSettings.json';
import entities from './Entities.json';
import entityDetails from './EntityDetails.json';
import forgotPassword from './ForgotPassword.json';
import humio from './Humio.json';
import integrations from './Integrations.json';
import irOverview from './IROverview.json';
import licenseDashboard from './LicenseDashboard.json';
import login from './Login.json';
import passwordChange from './PasswordChange.json';
import registration from './Registration.json';
import securityAudit from './SecurityAudit.json';
import securityOverview from './SecurityOverview.json';
import sensorMonitoring from './SensorMonitoring.json';
import system from './System.json';
import tenantSettings from './TenantSettings.json';
import twoFactorRecovery from './twoFactorRecovery.json';
import usersAccess from './UsersAccess.json';

export const pagesi18n = {
  notFound,
  alerts,
  alertDetails,
  detections,
  entities,
  entityDetails,
  irOverview,
  login,
  registration,
  securityOverview,
  twoFactorRecovery,
  forgotPassword,
  usersAccess,
  userAccount,
  system,
  sensorMonitoring,
  alertExport,
  appFooter,
  humio,
  integrations,
  licenseDashboard,
  access,
  passwordChange,
  securityAudit,
  detectionSettings,
  tenantSettings,
};
