import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

import { ISaveEscalationConfigurations } from './types';

export const SAVE_ESCALATION_CONFIGURATIONS = gql`
  mutation saveEscalationConfigurations(
    $escalation_configurations: [EscalationConfigurationInput!]!
  ) {
    saveEscalationConfigurations(
      escalation_configurations: $escalation_configurations
    ) {
      enabled
      escalation_configuration_id
      service_name
      ... on ServiceNowEscalationConfiguration {
        username
        password
        enabled
        escalation_configuration_id
        instance_name
        service_name
        table_name
      }
    }
  }
`;

export const useSaveEscalationConfigurations = (
  options: MutationHookOptions<
    ISaveEscalationConfigurations,
    OperationVariables
  >,
) => useMutation(SAVE_ESCALATION_CONFIGURATIONS, options);
