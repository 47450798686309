import { BoxProps, Flex } from '@chakra-ui/react';

export const FlushPageContent = ({ children, ...rest }: BoxProps) => (
  <Flex
    m="-6"
    h="calc(100% + var(--chakra-space-12))"
    w="calc(100% + var(--chakra-space-12))"
    {...rest}
  >
    {children}
  </Flex>
);
