import { createIcon } from '@chakra-ui/react';

export const CorelightSupportIcon = createIcon({
  displayName: 'CorelightSupportIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4.496A7.504 7.504 0 1 0 19.504 12H21.6A9.6 9.6 0 1 1 12 2.4v2.096Z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
