import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

import { EdrDeviceInput, EntityAction, IPerformEdrAction } from './types';

export const PERFORM_EDR_ACTION = gql`
  mutation performEdrAction(
    $tenant: String
    $action: EntityAction!
    $edr_device_input: EdrDeviceInput!
  ) {
    performEdrAction(
      action: $action
      edr_device_input: $edr_device_input
      tenant: $tenant
    ) {
      device_id
      edr_provider
      entity_edr_status
      hostname
      updated_timestamp
    }
  }
`;

export interface IPerformEdrActionVariables extends OperationVariables {
  tenant?: string;
  action?: EntityAction;
  edr_device_input?: EdrDeviceInput;
}

export const usePerformEdrAction = (
  options: MutationHookOptions<IPerformEdrAction, IPerformEdrActionVariables>,
) => {
  return useMutation(PERFORM_EDR_ACTION, options);
};
