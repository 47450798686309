import { CheckCircleIcon } from '@chakra-ui/icons';
import { Box, ChakraProps, Tag, TagLeftIcon } from '@chakra-ui/react';

export interface IDetectionStatusPill {
  text: string;
  customStyles?: {
    box?: ChakraProps;
    text?: ChakraProps;
  };
  isStatusOpen?: boolean;
}

export const DetectionStatusPill = ({
  text,
  isStatusOpen,
}: IDetectionStatusPill) => {
  return (
    <Tag
      size="md"
      variant="solid"
      textTransform="capitalize"
      data-testid="detection-alert-status"
      colorScheme={isStatusOpen ? 'green' : 'gray'}
    >
      {isStatusOpen ? (
        <Box
          h={3}
          w={3}
          borderRadius="100px"
          border="1px solid"
          borderColor="gray.50"
          data-testid="entity-panel-pill-open"
          mr={2}
        />
      ) : (
        <TagLeftIcon>
          <CheckCircleIcon
            color={'gray.50'}
            data-testid="entity-panel-pill-closed"
            mr={2}
            boxSize={3}
          />
        </TagLeftIcon>
      )}
      {text}
    </Tag>
  );
};
