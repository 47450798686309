import { Board, Column } from '@gamma/layout';

import { AuthContext } from '@gamma/investigator/context';
import { useContext } from 'react';
import {
  DetectionSettingsDashboard,
  LicenseDashboard,
  TenantSettingsDashboard,
} from './Components';

export const General = () => {
  const { isOrgTenant } = useContext(AuthContext);
  return (
    <Board>
      <Column>
        <TenantSettingsDashboard />
      </Column>
      {!isOrgTenant && (
        <Column>
          <DetectionSettingsDashboard />
        </Column>
      )}

      <Column>
        <LicenseDashboard />
      </Column>
    </Board>
  );
};
