import { Box, BoxProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

export interface PanelProps extends BoxProps {
  layerStyle?: string;
  'data-testid'?: string;
}

// change that will affect everything
export const Panel = forwardRef<HTMLDivElement, PanelProps>(
  ({ children, layerStyle = 'first', ...rest }, ref) => {
    return (
      <Box
        borderRadius="base"
        p="4"
        w="100%"
        h={layerStyle === 'first' ? '100%' : 'auto'}
        layerStyle={layerStyle}
        ref={ref}
        {...rest}
      >
        {children}
      </Box>
    );
  },
);
