import { Flex, IconButtonProps, TooltipProps } from '@chakra-ui/react';
import { DateSelect, DateSelectOption } from '@gamma/form-fields';
import { dateRangeOptions } from '@gamma/investigator/constants';
import {
  useDateRangeContext,
  useDateRangeQueryString,
} from '@gamma/investigator/hooks';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { NavigateFunction, useLocation } from 'react-router-dom';

interface DateSelectSyncProps {
  label?: string;
  isLabelHidden?: boolean;
  orientation?: 'vertical' | 'horizontal';
  dateSelectButtonConfig?: {
    toolTipProps: Omit<TooltipProps, 'children'>;
    iconButtonProps: IconButtonProps;
  };
  params: URLSearchParams;
  navigate: NavigateFunction;
}

export const DateSelectSync = ({
  params,
  navigate,
  orientation,
  isLabelHidden = true,
  dateSelectButtonConfig,
  label = 'Select Date Range',
}: DateSelectSyncProps) => {
  const location = useLocation();
  const { search, pathname } = location;

  const { start, end } = useDateRangeQueryString();
  const {
    dateRange,
    setDateRange,
    showCustomDateRangePicker,
    setShowCustomDateRangePicker,
  } = useDateRangeContext();

  const [endExpiry, setEndExpiry] = useState<number>(end + 60 || 0);

  const navigateWithParams = useCallback(
    (start: number, end: number) => {
      params.set('start', String(start));
      params.set('end', String(end));
      navigate({
        pathname,
        search: params.toString(),
      });
    },
    [navigate, pathname, search],
  );

  const navigateWithStartEnd = useCallback(() => {
    if (dateRange) {
      const end = moment().unix();
      const start = moment().subtract(dateRange[0], dateRange[1]).unix();
      navigateWithParams(start, end);
    }
  }, [dateRange, navigateWithParams]);

  useEffect(() => {
    if (end && end !== endExpiry && moment().unix() > endExpiry) {
      setEndExpiry(end + 60);
      // navigateWithStartEnd();
    }
    if ((!start || !end) && dateRange) {
      navigateWithStartEnd();
    }
  }, [dateRange, start, end, endExpiry, navigateWithStartEnd]);

  const handleOnChange = (
    startDate: Date,
    endDate: Date,
    option?: DateSelectOption | 'custom',
  ) => {
    if (setDateRange && option !== 'custom') {
      setDateRange(option);
      setShowCustomDateRangePicker?.(false);
    } else {
      setShowCustomDateRangePicker?.(true);
    }

    if (startDate && endDate) {
      const start = moment(startDate).unix();
      const end = moment(endDate).unix();
      navigateWithParams(start, end);
    }
  };

  const defaultValue = showCustomDateRangePicker ? 'custom' : dateRange;

  const customDates =
    showCustomDateRangePicker && start && end
      ? [moment.unix(start).toDate(), moment.unix(end).toDate()]
      : undefined;

  return (
    <Flex
      zIndex="popover"
      position="relative"
      data-testid="date-select-container"
      justifyContent={orientation === 'vertical' ? 'start' : 'end'}
    >
      <DateSelect
        name="dateRangeSelect"
        label={label}
        isLabelHidden={isLabelHidden}
        allowCustom={true}
        customDates={customDates}
        onChange={handleOnChange}
        options={dateRangeOptions}
        defaultValue={defaultValue}
        orientation={orientation ?? 'horizontal'}
        pickerProps={{ maxDate: new Date() }}
        lapsedTimeDate={end * 1000}
        {...(dateSelectButtonConfig && {
          dateSelectButtonConfig: {
            iconButtonProps: {
              ...dateSelectButtonConfig?.iconButtonProps,
              isDisabled:
                dateSelectButtonConfig?.iconButtonProps?.isDisabled ||
                defaultValue === 'custom',
            },
            toolTipProps: {
              ...dateSelectButtonConfig?.toolTipProps,
            },
          },
        })}
      />
    </Flex>
  );
};
