import { createIcon } from '@chakra-ui/react';

export const BellOffIcon = createIcon({
  displayName: 'BellOffIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <g>
        <path
          fill="currentColor"
          d="M20 18.69 7.84 6.14 5.27 3.49 4 4.76l2.8 2.8v.01c-.52.99-.8 2.16-.8 3.42v5l-2 2v1h13.73l2 2L21 19.72l-1-1.03ZM12 22c1.11 0 2-.89 2-2h-4c0 1.11.89 2 2 2Zm6-7.32V11c0-3.08-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68c-.15.03-.29.08-.42.12-.1.03-.2.07-.3.11h-.01c-.01 0-.01 0-.02.01-.23.09-.46.2-.68.31 0 0-.01 0-.01.01L18 14.68Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </>
  ),
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
