import { Button, ButtonGroup, Text, useDisclosure } from '@chakra-ui/react';
import { DataTable } from '@gamma/data-table';
import { useSimpleSearchQuery } from '@gamma/investigator/queries';
import { Modal } from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Column } from 'react-table';
import { LogscaleDetailsModal } from '../LogscaleDetailsModal';

export interface InvestigateLogsTableProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  alertsName: string;
  alertsUid?: string;
  alertsTimestamp: string;
}

export interface LogDetails {
  ['#path']: string;
  ['id.orig_h']: string;
  ['id.resp_p']: string;
  local_orig: string;
  local_resp: string;
}

export const InvestigateLogsTableModal = ({
  isOpen,
  onClose,
  onOpen,
  alertsName,
  alertsUid,
  alertsTimestamp,
}: InvestigateLogsTableProps) => {
  const [investigateLogsTableData, setInvestigateLogsTableData] = useState<
    any[]
  >([]);
  const [activeRow, setActiveRow] = useState<object>({});
  const [logscaleType, setLogscaleType] = useState<string>('');

  const {
    isOpen: logScaleDetailsIsOpen,
    onOpen: logScaleDetailsOnOpen,
    onClose: logScaleDetailsOnClose,
  } = useDisclosure();

  const startTime = moment(alertsTimestamp).subtract(1, 'hours').unix();

  const endTime = moment(alertsTimestamp).add(24, 'hours').unix();

  const { loading } = useSimpleSearchQuery({
    skip: !isOpen,
    variables: {
      clientSessionId: localStorage.getItem('session_id_token'),
      queryString: `uid="${alertsUid}"`,
      start: startTime.toString(),
      end: endTime.toString(),
    },
    onCompleted: (data) => {
      const logsData = data?.simpleSearchQuery?.events?.map((item) =>
        JSON.parse(item),
      );
      setInvestigateLogsTableData(logsData);
    },
  });

  const dataTableColumns: Column<LogDetails>[] = useMemo(() => {
    return [
      {
        Header: '#path',
        accessor: '#path',
      },
      {
        Header: 'id.orig_h',
        accessor: (row) => row['id.orig_h'],
        Cell: (props: any) => <Text>{props?.value ?? '-'}</Text>,
      },
      {
        Header: 'id.resp_p',
        accessor: (row) => row['id.resp_p'],
        Cell: (props: any) => <Text>{props?.value ?? '-'}</Text>,
      },
      {
        Header: 'local_orig',
        accessor: 'local_orig',
        Cell: (props: any) => <Text>{props?.value ?? '-'}</Text>,
      },
      {
        Header: 'local_resp',
        accessor: 'local_resp',
        Cell: (props: any) => <Text>{props?.value ?? '-'}</Text>,
      },
    ];
  }, []);

  const handleRowClick = (e: any) => {
    setLogscaleType(e.original?.['#path']);
    setActiveRow(e.original);
    logScaleDetailsOnOpen();
    onClose();
  };

  return (
    <>
      <Modal
        size="xl"
        isOpen={isOpen}
        onClose={onClose}
        title={`${alertsName} | ${alertsTimestamp}`}
        body={
          loading ? (
            <GraphQLReqStatus loading={loading} />
          ) : (
            <DataTable
              data={investigateLogsTableData}
              columns={dataTableColumns}
              isLined={true}
              autoResetPage={false}
              autoResetSortBy={false}
              autoResetRowSelection={false}
              onRowClick={handleRowClick}
            />
          )
        }
        footer={
          <ButtonGroup>
            <Button variant="solid" colorScheme="gray" onClick={onClose}>
              Close
            </Button>
          </ButtonGroup>
        }
      />
      <LogscaleDetailsModal
        alertsName={alertsName}
        alertsTimestamp={alertsTimestamp}
        isModalOpen={logScaleDetailsIsOpen}
        onModalClose={logScaleDetailsOnClose}
        onOpen={onOpen}
        data={activeRow}
        title={`${logscaleType.toUpperCase()} Details`}
      />
    </>
  );
};
