import { createIcon } from '@chakra-ui/react';

export const PlayIcon = createIcon({
  displayName: 'PlayIcon',
  viewBox: '0 0 24 24',
  d: 'M8 5.14v14l11-7-11-7Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
