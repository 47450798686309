import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
  chakra,
  CloseButton,
  UseToastOptions,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { MuiIcon } from '@gamma/display';

export interface ToastProps
  extends UseToastOptions,
    Omit<AlertProps, keyof UseToastOptions> {
  onClose?: () => void;
}

/**
 * The `Toast` component is used to give feedback to users after an action has taken place.
 *
 * @see Docs https://chakra-ui.com/docs/components/toast
 */
export const Toast: React.FC<ToastProps> = (props) => {
  const {
    status,
    variant = 'solid',
    id,
    title,
    isClosable,
    onClose,
    description,
    colorScheme,
    icon,
  } = props;

  const statusIcon = useMemo(() => {
    switch (status) {
      case 'error':
        return 'error';
      case 'info':
        return 'info';
      case 'success':
        return 'check_circle';
      case 'warning':
        return 'error';
      default:
        return 'info';
    }
  }, [status]);

  const ids = id
    ? {
        root: `toast-${id}`,
        title: `toast-${id}-title`,
        description: `toast-${id}-description`,
      }
    : undefined;

  return (
    <Alert
      addRole={false}
      status={status}
      variant={variant}
      id={ids?.root}
      alignItems="start"
      borderRadius="md"
      boxShadow="lg"
      paddingEnd={8}
      textAlign="start"
      width="auto"
      colorScheme={colorScheme}
    >
      <AlertIcon h={5}>
        <MuiIcon>{statusIcon}</MuiIcon>
      </AlertIcon>
      <chakra.div flex="1" maxWidth="100%">
        {title && <AlertTitle id={ids?.title}>{title}</AlertTitle>}
        {description && (
          <AlertDescription id={ids?.description} display="block">
            {description}
          </AlertDescription>
        )}
      </chakra.div>
      {isClosable && (
        <CloseButton
          size="sm"
          onClick={onClose}
          position="absolute"
          insetEnd={1}
          top={1}
        >
          <MuiIcon>close</MuiIcon>
        </CloseButton>
      )}
    </Alert>
  );
};
