import { Grid, GridItem } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { Label, LabelProps } from '../Label';

export interface SelectWrapperProps extends LabelProps {
  minWidth?: string;
  afterInput?: ReactNode;
}

export const SelectWrapper = ({
  children,
  width,
  minWidth,
  afterInput,
  ...labelProps
}: SelectWrapperProps) => {
  return (
    <Label {...labelProps}>
      <Grid
        templateColumns="minmax(0, 1fr) min-content"
        alignItems="center"
        width={width}
        minW={minWidth}
      >
        <GridItem data-testid="gamma-select-wrapper" w="full">
          {children}
        </GridItem>
        {afterInput && <GridItem pl={4}>{afterInput}</GridItem>}
      </Grid>
    </Label>
  );
};
