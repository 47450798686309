import { Context, useContext } from 'react';
import { DataTableContext, DataTableContextValue } from '../../context';

export const useDataTableContext = <DataType extends {}>() => {
  const context = useContext<DataTableContextValue<DataType>>(
    DataTableContext as unknown as Context<DataTableContextValue<DataType>>,
  );

  return context;
};
