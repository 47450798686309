import { i18n } from '@gamma/investigator/localization';
import { customLineBreaks } from '@gamma/investigator/utilities';
import {
  CardPageBackButton,
  CardPageBody,
  CardPageHeading,
  CardPageIllustration,
} from '@gamma/pages';
import { useLocation, useNavigate } from 'react-router-dom';

interface CustomizedState {
  message: string;
}

export const SSOLoginErrors = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const { title } = i18n.pages.login.ssoSignInErrors.errorPage;

  return (
    <>
      <CardPageBackButton
        data-testid="sso-error-back-button"
        onClick={() => navigate('/login')}
      />
      <CardPageHeading data-testid="sso-error-title">{title}</CardPageHeading>
      <CardPageIllustration
        illustration={'TwoFactorAuth'}
        width="160px"
        height="128px"
      />
      <CardPageBody textAlign="center" data-testid="sso-error-body">
        {customLineBreaks(state.message)}
      </CardPageBody>
    </>
  );
};
