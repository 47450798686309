import { ApolloError } from '@apollo/client';
import {
  Box,
  Button,
  HStack,
  Link,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { MuiIcon, SnippetBox } from '@gamma/display';
import {
  ChatGPTAnswer,
  ChatGPTPromptCloudPanel,
  ChatGPTQAPromptCloudPanel,
  SeverityScore,
} from '@gamma/investigator/components';
import { ROUTES } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import {
  useDateRangeQueryString,
  useManualPagination,
} from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  AlertEntity,
  Detection,
  IGetAlertMetadata,
  IGetDetections,
  useGetAlertMetadataNonSuricataAlertAttack,
  useGetAlertMetadataNonSuricataAlertTTP,
  useGetAlertMetadataSuricataAlertExplain,
  useGetAlertMetadataSuricataInvestigateAlert,
  useGetAlertMetadataSuricataRuleDescription,
  useGetDetections,
  useQueryAlertMetadataExcludedEntitiesPaginated,
} from '@gamma/investigator/queries';
import { stringifyAlertType } from '@gamma/investigator/utilities';
import { AccordionPanel, Panel, PanelHeader } from '@gamma/layout';
import { RouterLink } from '@gamma/navigation';
import { Drawer, Popover } from '@gamma/overlay';
import { GraphQLReqStatus, LoadingSpinner } from '@gamma/progress';
import DOMPurify from 'dompurify';
import moment from 'moment';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import dedent from 'ts-dedent';

import {
  DetailsGridRow,
  DetectionAssignee,
  DetectionDrawer,
  DetectionDrawerActions,
  DetectionEntityEDRPanel,
  DetectionEntityPanel,
  DetectionStatus,
  IGetDetectionVariables,
  MitreTechniquesGrid,
} from '../../Components';
import { formatSort } from '../../Detections';
import { IDetectionContext } from '../Detection';
import { RelatedDetections } from './Components';
import { DetectionDetailsTable } from './Components/DetectionDetailsTable';

const { systemSettingsAlertCatalog, systemSettingsIntegrationsConfig } = ROUTES;

const { detection: detectionText, entityEDR } = i18n.pages.detections;

const {
  assignee,
  alertCategory,
  significance,
  mitreTechniques,
  validation,
  nextSteps,
  description: localeDesc,
  status,
  source,
  destination,
  entity,
  type,
  severity,
  firstTimeSeen,
  lastTimeSeen,
  numberOfAlerts,
} = detectionText;

const { chatGPT } = i18n.pages.integrations;

interface DetectionDetailsProps {
  getAppConfigurationsData: string | null;
  getAppConfigurationsLoading: boolean;
  detailsProps?: {
    detectionData?: IGetDetections;
    detectionError?: ApolloError;
    detectionLoading?: boolean;
    metadataData?: IGetAlertMetadata;
    metadataError?: ApolloError;
    metadataLoading?: boolean;
    getDetectionVariables?: IGetDetectionVariables;
  };
}

export const DetectionDetails = ({
  detailsProps,
  getAppConfigurationsData,
  getAppConfigurationsLoading,
}: DetectionDetailsProps) => {
  const {
    detectionData,
    detectionError,
    detectionLoading,
    metadataData,
    metadataError,
    metadataLoading,
    edrConfig,
    edrConfigError,
    edrConfigLoading,
    getDetectionVariables,
  } = useOutletContext<IDetectionContext>() || {};

  const { user } = useContext(AuthContext);

  const { start, end } = useDateRangeQueryString();

  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isPreviewOpen,
    onOpen: onPreviewOpen,
    onClose: onPreviewClose,
  } = useDisclosure();

  const detectionDetailsProps = useMemo(() => {
    return detailsProps
      ? {
          ...detailsProps,
        }
      : {
          detectionData,
          detectionError,
          detectionLoading,
          metadataData,
          metadataError,
          metadataLoading,
          getDetectionVariables,
        };
  }, [
    detailsProps,
    detectionData,
    detectionError,
    detectionLoading,
    metadataData,
    metadataError,
    metadataLoading,
    getDetectionVariables,
  ]);

  const isCorrelationChart = getAppConfigurationsData
    ? JSON.parse(getAppConfigurationsData).feature_flags.correlation_chart
    : false;

  const [descriptionText, setDescriptionText] = useState<string>('');
  const [chatGPTAnswersList, setChatGPTAnswersList] = useState<ChatGPTAnswer[]>(
    [],
  );
  const [chatGPTQuestionsList, setChatGPTQuestionsList] = useState<string[]>(
    [],
  );
  const [suricataRuleText, setSuricataRuleText] = useState('');

  const detection =
    detectionDetailsProps?.detectionData?.getDetections?.detections[0];

  const [selectedDetectionId, setSelectedDetectionId] = useState<string>();

  const { loading, data, error } = useGetDetections({
    skip: !selectedDetectionId,
    variables: {
      detection_ids: [
        {
          detection_id: selectedDetectionId,
          tenant: user?.attributes['custom:tenant_id'],
        },
      ],
    },
  });

  const [selectedDetection, setSelectedDetection] = useState<
    Detection | undefined
  >();

  useEffect(() => {
    setSelectedDetection(data?.getDetections?.detections?.[0]);
  }, [data]);

  useEffect(() => {
    setSelectedDetectionId(detection?.detection_id);
  }, [detection]);

  const {
    rank,
    alert_info,
    assignment_info,
    detection_status,
    total_alert_count,
    earliest_start_timestamp,
    latest_start_timestamp,
    paginated_destination_entities,
    paginated_source_entities,
  } = detection || {};

  const GPTQuestionList = Object.values(
    alert_info?.alert_type === 'suricata_corelight'
      ? chatGPT.suricataQuestions
      : chatGPT.nonSuricataQuestions,
  );

  useEffect(() => {
    if (alert_info?.alert_type) {
      setChatGPTQuestionsList(GPTQuestionList);
    }
  }, [alert_info?.alert_type]);

  const {
    description,
    content_doc,
    mitre_mappings: metadata_mitre_mappings,
  } = detectionDetailsProps?.metadataData?.getAlertMetadata?.metadata[0] || {};

  const detectionInfo =
    detectionDetailsProps?.detectionData?.getDetections?.detections[0];

  const [activeEntity, setActiveEntity] = useState<AlertEntity | null>(null);
  const { alert_entity } = detectionInfo || {};
  const { entity_name, entity_type } = activeEntity || alert_entity || {};

  const queryVariables = {
    query:
      entity_type === 'DOMAIN'
        ? `{"query": {"bool": {"must": [{"match": {"content_id": "${
            detectionInfo?.alert_info?.content_id
          }"}}, {"term": {"entity_name.keyword": "${entity_name}"}}, {"term": {"entity_type": "${entity_type}"}}${
            detectionInfo?.tenant_info?.tenant_id ? ',' : ''
          }${
            detectionInfo?.tenant_info?.tenant_id
              ? `{"terms":{"tenant":["${detectionInfo?.tenant_info?.tenant_id}"]}}`
              : ''
          }]}}}`
        : `{"query": {"bool": {"must": [{"match": {"content_id": "${
            detectionInfo?.alert_info?.content_id
          }"}}, {"term": {"entity_type": "${entity_type}"}}, {"bool": {"should": [{"term": {"entity_ip": "${entity_name}"}}, {"range": {"entity_ip_range": {"from": "${entity_name}", "to": "${entity_name}"}}}]}}${
            detectionInfo?.tenant_info?.tenant_id ? ',' : ''
          }${
            detectionInfo?.tenant_info?.tenant_id
              ? `{"terms":{"tenant":["${detectionInfo?.tenant_info?.tenant_id}"]}}`
              : ''
          }]}}}`,
    offset: 0,
    size: 10,
    sort: [
      {
        sort_by: 'entity_name.keyword',
        sort_dir: 'desc',
      },
    ],
  };

  const gptQueryVariables = {
    items: [
      {
        content_id: detectionInfo?.alert_info?.content_id,
        tenant: detectionInfo?.tenant_info?.tenant_id,
      },
    ],
  };

  const {
    loading: excludeEntitiesLoading,
    data: excludeEntitiesData,
    error: excludeEntitiesError,
  } = useQueryAlertMetadataExcludedEntitiesPaginated({
    skip:
      !entity_name ||
      !entity_type ||
      !detectionInfo?.tenant_info?.tenant_id ||
      !detectionInfo?.alert_info?.content_id,
    variables: queryVariables,
  });

  // suri_alert_explain
  const [
    getSuricataAlertExplain,
    {
      loading: getSuricataAlertExplainLoading,
      data: getSuricataAlertExplainData,
      error: getSuricataAlertExplainError,
    },
  ] = useGetAlertMetadataSuricataAlertExplain({
    variables: gptQueryVariables,
  });

  // suri_investigate_alert
  const [
    getSuricataAlertInvestigation,
    {
      loading: getSuricataAlertInvestigationLoading,
      data: getSuricataAlertInvestigationData,
      error: getSuricataAlertInvestigationError,
    },
  ] = useGetAlertMetadataSuricataInvestigateAlert({
    variables: gptQueryVariables,
  });

  // non_suri_alert_attack
  const [
    getNonSuricataAlertAttack,
    {
      loading: getNonSuricataAlertAttackLoading,
      data: getNonSuricataAlertAttackData,
      error: getNonSuricataAlertAttackError,
    },
  ] = useGetAlertMetadataNonSuricataAlertAttack({
    variables: gptQueryVariables,
  });

  // non_suri_alert_ttp
  const [
    getNonSuricataAlertTTP,
    {
      loading: getNonSuricataAlertTTPLoading,
      data: getNonSuricataAlertTTPData,
      error: getNonSuricataAlertTTPError,
    },
  ] = useGetAlertMetadataNonSuricataAlertTTP({
    variables: gptQueryVariables,
  });

  const isLoadingGPTQuestionPromptData =
    getSuricataAlertExplainLoading ||
    getSuricataAlertInvestigationLoading ||
    getNonSuricataAlertAttackLoading ||
    getNonSuricataAlertTTPLoading;

  const hasGPTQuestionPromptError =
    getSuricataAlertExplainError ||
    getSuricataAlertInvestigationError ||
    getNonSuricataAlertAttackError ||
    getNonSuricataAlertTTPError;

  const chatGPTQuestionPromptData = useMemo(() => {
    return {
      suricataAlertExplain:
        getSuricataAlertExplainData?.getAlertMetadata?.metadata[0]
          ?.chatbot_chats?.suri_alert_explain,
      suricataAlertInvestigation:
        getSuricataAlertInvestigationData?.getAlertMetadata?.metadata[0]
          ?.chatbot_chats?.suri_investigate_alert,
      nonSuricataAlertAttack:
        getNonSuricataAlertAttackData?.getAlertMetadata?.metadata[0]
          ?.chatbot_chats?.non_suri_alert_attack,
      nonSuricataAlertTTP:
        getNonSuricataAlertTTPData?.getAlertMetadata?.metadata[0]?.chatbot_chats
          ?.non_suri_alert_ttp,
    };
  }, [
    getSuricataAlertExplainData,
    getSuricataAlertInvestigationData,
    getNonSuricataAlertAttackData,
    getNonSuricataAlertTTPData,
  ]);

  const callChatGPTPromptDataFunction = (question: string) => {
    const questionToAnswerMap = {
      [`${chatGPT.suricataQuestions.whatDoesThisAlertMean.toLowerCase()}`]:
        getSuricataAlertExplain,
      [`${chatGPT.suricataQuestions.whatNextStepShouldITake.toLowerCase()}`]:
        getSuricataAlertInvestigation,
      [`${chatGPT.nonSuricataQuestions.whatTypeOfAttacksMayBeAssociated.toLowerCase()}`]:
        getNonSuricataAlertAttack,
      [`${chatGPT.nonSuricataQuestions.whatAreSomeTechniquesSpecificToThisAlert.toLowerCase()}`]:
        getNonSuricataAlertTTP,
    };

    const gptFunction = questionToAnswerMap[question.toLowerCase()];

    if (typeof gptFunction === 'function') {
      gptFunction();
    }
  };

  // update answer list based on result of lazilyDynamicallyLoadGetAlertMetadata
  useEffect(() => {
    const whatDoesThisAlertMean =
      chatGPTQuestionPromptData?.suricataAlertExplain;
    const nextStepsAnswerText =
      chatGPTQuestionPromptData?.suricataAlertInvestigation;
    const associatedAttacksText =
      chatGPTQuestionPromptData?.nonSuricataAlertAttack;
    const ttpAdversariesText = chatGPTQuestionPromptData?.nonSuricataAlertTTP;

    const hasWhatDoesThisAlertMean = chatGPTAnswersList.some(
      (answer) =>
        answer.questionText.toLowerCase() ===
        chatGPT.suricataQuestions.whatDoesThisAlertMean.toLowerCase(),
    );

    const hasNextStepsInAnswersList = chatGPTAnswersList.some(
      (answer) =>
        answer.questionText.toLowerCase() ===
        chatGPT.suricataQuestions.whatNextStepShouldITake.toLowerCase(),
    );

    const hasAssociatedAttacksInAnswersList = chatGPTAnswersList.some(
      (answer) =>
        answer.questionText.toLowerCase() ===
        chatGPT.nonSuricataQuestions.whatTypeOfAttacksMayBeAssociated.toLowerCase(),
    );

    const hasTTPAdversariesInAnswersList = chatGPTAnswersList.some(
      (answer) =>
        answer.questionText.toLowerCase() ===
        chatGPT.nonSuricataQuestions.whatAreSomeTechniquesSpecificToThisAlert.toLowerCase(),
    );

    if (whatDoesThisAlertMean && !hasWhatDoesThisAlertMean) {
      setChatGPTAnswersList((prev) => [
        ...prev,
        {
          questionText: chatGPT.suricataQuestions.whatDoesThisAlertMean,
          answerText: whatDoesThisAlertMean,
        },
      ]);
    }

    if (nextStepsAnswerText && !hasNextStepsInAnswersList) {
      setChatGPTAnswersList((prev) => [
        ...prev,
        {
          questionText: chatGPT.suricataQuestions.whatNextStepShouldITake,
          answerText: nextStepsAnswerText,
        },
      ]);
    }

    if (associatedAttacksText && !hasAssociatedAttacksInAnswersList) {
      setChatGPTAnswersList((prev) => [
        ...prev,
        {
          questionText:
            chatGPT.nonSuricataQuestions.whatTypeOfAttacksMayBeAssociated,
          answerText: associatedAttacksText,
        },
      ]);
    }

    if (ttpAdversariesText && !hasTTPAdversariesInAnswersList) {
      setChatGPTAnswersList((prev) => [
        ...prev,
        {
          questionText:
            chatGPT.nonSuricataQuestions
              .whatAreSomeTechniquesSpecificToThisAlert,
          answerText: ttpAdversariesText,
        },
      ]);
    }
  }, [chatGPTQuestionPromptData]);

  const hasChatGPTQuestionPromptData = Boolean(
    (chatGPTQuestionPromptData?.suricataAlertExplain ||
      chatGPTQuestionPromptData?.suricataAlertInvestigation ||
      chatGPTQuestionPromptData?.nonSuricataAlertAttack ||
      chatGPTQuestionPromptData?.nonSuricataAlertTTP) &&
      descriptionText?.length > 0,
  );

  const {
    data: suricataAlertDescriptionData,
    loading: suricataAlertDescriptionLoading,
    error: suricataAlertDescriptionError,
  } = useGetAlertMetadataSuricataRuleDescription({
    skip:
      !detectionInfo?.alert_info?.content_id ||
      excludeEntitiesLoading ||
      detectionDetailsProps?.metadataLoading ||
      alert_info?.alert_type !== 'suricata_corelight' ||
      hasChatGPTQuestionPromptData,
    variables: gptQueryVariables,
  });

  const [currentQuestionPromptText, setCurrentQuestionPromptText] =
    useState<string>('');

  const handleUpdatingChatGPTQuestionsAndAnswersList = async (
    question: string,
    hasError: boolean,
  ) => {
    setCurrentQuestionPromptText(question);

    callChatGPTPromptDataFunction(question);

    const isQuestionInList = chatGPTQuestionsList.includes(question);

    if (isQuestionInList && !hasError) {
      const newQuestionsList = chatGPTQuestionsList.filter(
        (q) => q !== question,
      );
      setChatGPTQuestionsList(newQuestionsList);
    } else {
      setChatGPTQuestionsList([...chatGPTQuestionsList, question]);
    }

    if (hasError) {
      setChatGPTQuestionsList(GPTQuestionList);
    }
  };

  // set panel description
  useEffect(() => {
    if (detectionDetailsProps?.metadataLoading) {
      return;
    }

    if (
      alert_info?.alert_type === 'suricata_corelight' &&
      !descriptionText?.length
    ) {
      setDescriptionText(
        suricataAlertDescriptionData?.getAlertMetadata?.metadata?.[0]
          ?.chatbot_chats?.suri_describe_rule ?? '',
      );
    }

    if (
      description &&
      alert_info?.alert_type !== 'suricata_corelight' &&
      alert_info?.alert_type &&
      !descriptionText?.length
    ) {
      setDescriptionText(description);
    }

    const suricataRuleText =
      ((alert_info?.alert_type === 'suricata_corelight' &&
        suricataAlertDescriptionData?.getAlertMetadata?.metadata?.[0]?.rule) ||
        detectionDetailsProps?.metadataData?.getAlertMetadata?.metadata[0]
          ?.rule) ??
      '';

    setSuricataRuleText(suricataRuleText);
  }, [
    suricataAlertDescriptionData,
    description,
    detectionDetailsProps?.metadataLoading,
    alert_info?.alert_type,
    detectionDetailsProps?.metadataData?.getAlertMetadata?.metadata,
    descriptionText?.length,
  ]);

  const hasMetaContent = !!(description || content_doc?.significance);

  const isLoading =
    edrConfigLoading ||
    getAppConfigurationsLoading ||
    detectionDetailsProps?.metadataLoading ||
    detectionDetailsProps?.detectionLoading;

  const nextStepsRef = useRef<HTMLDivElement>(null);

  const validationRef = useRef<HTMLDivElement>(null);

  const [isExcluded, setIsExcluded] = useState<boolean>(false);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [entityType, setEntityType] = useState<string>();

  const sourceEntities = paginated_source_entities?.alert_entities;
  const destinationEntities = paginated_destination_entities?.alert_entities;
  const hasSource = Boolean(sourceEntities?.length);
  const hasDestination = Boolean(destinationEntities?.length);

  const NUM_ENTITIES_SHOWN = 2;
  const sourceEntitiesOverflow =
    sourceEntities && sourceEntities.length - NUM_ENTITIES_SHOWN;
  const destinationEntitiesOverflow =
    destinationEntities && destinationEntities.length - NUM_ENTITIES_SHOWN;

  const entities =
    entityType === 'source'
      ? sourceEntities
      : entityType === 'destination'
      ? destinationEntities
      : undefined;

  const { getSortsForAPI } = useManualPagination<any>({
    // @ts-ignore
    sortBy: formatSort(queryVariables?.sortBy),
  });

  return isLoading || detectionDetailsProps?.metadataError ? (
    <GraphQLReqStatus
      loading={!!isLoading}
      error={
        detectionDetailsProps?.detectionError ||
        detectionDetailsProps?.metadataError ||
        edrConfigError
      }
    />
  ) : (
    <>
      <VStack spacing={4} data-testid="detection-details-wrapper">
        <Panel>
          <PanelHeader
            borderBottomWidth="1px"
            borderBottomColor={'border.layer.1'}
          >
            <Text textStyle="h5">Detection Details</Text>
          </PanelHeader>
          <HStack>
            <VStack w="315px" alignItems="start">
              <Text textStyle="body-md" color="text.secondary">
                {status}
              </Text>
              <DetectionStatus
                refetchMain={true}
                detectionInfo={detectionInfo}
                detectionStatus={detection_status}
                getDetectionVariables={
                  detectionDetailsProps?.getDetectionVariables
                }
              />
            </VStack>
            <VStack w="315px" alignItems="start">
              <Text textStyle="body-md" color="text.secondary">
                {assignee}
              </Text>
              <DetectionAssignee
                refetchMain={true}
                detectionInfo={detectionInfo}
                assignmentInfo={assignment_info}
                getDetectionVariables={
                  detectionDetailsProps?.getDetectionVariables
                }
              />
            </VStack>
          </HStack>
        </Panel>
        <AccordionPanel
          w="100%"
          title="Alert Category"
          reduceMotion={true}
          storageKey="detection-details-alert-category"
        >
          <HStack gap={8} alignItems="start">
            <VStack w="70%" alignItems="start">
              {hasMetaContent &&
                (description ?? '')?.length > 0 &&
                alert_info?.alert_type !== 'suricata_corelight' && (
                  <DetailsGridRow
                    isDetailsPage
                    title={localeDesc}
                    data-testid="detection-description"
                  >
                    <Text>{description}</Text>
                  </DetailsGridRow>
                )}
              {hasMetaContent && content_doc?.significance && (
                <DetailsGridRow
                  isDetailsPage
                  title={significance}
                  data-testid="detection-significance"
                >
                  <Text as="div">{content_doc.significance}</Text>
                </DetailsGridRow>
              )}
              {isExpanded && hasMetaContent && content_doc?.validation && (
                <DetailsGridRow
                  isDetailsPage
                  title={validation}
                  data-testid="detection-content-doc-validation"
                >
                  <Box
                    ref={validationRef}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(content_doc?.validation) ?? '',
                    }}
                    __css={{
                      ul: {
                        'margin-left': '16px',
                      },
                    }}
                  />
                </DetailsGridRow>
              )}
              {isExpanded && hasMetaContent && content_doc?.nextsteps && (
                <DetailsGridRow
                  isDetailsPage
                  title={nextSteps}
                  data-testid="detection-content-doc-next-steps"
                >
                  <Box
                    ref={nextStepsRef}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(content_doc?.nextsteps) ?? '',
                    }}
                    __css={{
                      ul: {
                        'margin-left': '16px',
                      },
                    }}
                  />
                </DetailsGridRow>
              )}
              {alert_info?.alert_type !== 'custom_search_rule' && (
                <DetailsGridRow
                  isDetailsPage
                  title=""
                  data-testid="detection-ask-gpt"
                >
                  <VStack w="100%" alignItems="start">
                    {alert_info?.alert_type === 'suricata_corelight' && (
                      <VStack w="100%" alignItems="start">
                        <ChatGPTPromptCloudPanel
                          titleText={chatGPT.description}
                          descriptionText={descriptionText}
                          isLoadingDescription={
                            suricataAlertDescriptionLoading ||
                            detectionDetailsProps?.metadataLoading
                          }
                          isDescriptionQuickView={false}
                          loadingDescriptionError={
                            suricataAlertDescriptionError
                          }
                          corelightDescription={description}
                          storageKey="gpt-prompt-panel-suri-details"
                          showGPTDisabled={false}
                        />
                        {detectionDetailsProps?.metadataLoading ? (
                          <LoadingSpinner />
                        ) : suricataRuleText ? (
                          <>
                            <Text>Suricata {detectionText?.rule}</Text>
                            <SnippetBox
                              snippet={dedent`${suricataRuleText}`}
                              isCopyable={true}
                            />
                          </>
                        ) : null}
                        <ChatGPTQAPromptCloudPanel
                          titleText={chatGPT.askGPT}
                          descriptionText={descriptionText}
                          questionsTextList={chatGPTQuestionsList}
                          answersTextList={chatGPTAnswersList}
                          isLoadingAnswerList={isLoadingGPTQuestionPromptData}
                          questionLoadingText={currentQuestionPromptText}
                          onQuestionClick={
                            handleUpdatingChatGPTQuestionsAndAnswersList
                          }
                          loadingAnswerListError={hasGPTQuestionPromptError}
                          storageKey="gpt-prompt-panel-suri-ask-details"
                        />
                      </VStack>
                    )}
                    {alert_info?.alert_type !== 'suricata_corelight' &&
                      !description && (
                        <ChatGPTPromptCloudPanel
                          titleText={chatGPT.description}
                          descriptionText={descriptionText}
                          isLoadingDescription={
                            !descriptionText && suricataAlertDescriptionLoading
                          }
                          isDescriptionQuickView={false}
                          loadingDescriptionError={
                            suricataAlertDescriptionError
                          }
                          storageKey="gpt-prompt-panel-details"
                          showGPTDisabled={true}
                        />
                      )}
                    {alert_info?.alert_type !== 'suricata_corelight' &&
                      description && (
                        <ChatGPTQAPromptCloudPanel
                          titleText={chatGPT.askGPT}
                          descriptionText={descriptionText}
                          questionsTextList={chatGPTQuestionsList}
                          answersTextList={chatGPTAnswersList}
                          isLoadingAnswerList={isLoadingGPTQuestionPromptData}
                          questionLoadingText={currentQuestionPromptText}
                          onQuestionClick={
                            handleUpdatingChatGPTQuestionsAndAnswersList
                          }
                          loadingAnswerListError={hasGPTQuestionPromptError}
                          isLoadingDescription={
                            !descriptionText && suricataAlertDescriptionLoading
                          }
                          storageKey="gpt-prompt-non-suri-search-panel-details"
                        />
                      )}
                  </VStack>
                </DetailsGridRow>
              )}
              {hasMetaContent &&
                (content_doc?.nextsteps || content_doc?.validation) && (
                  <Link
                    color="text.link"
                    onClick={() => setIsExpanded(!isExpanded)}
                  >
                    {!isExpanded ? 'See More' : 'See Less'}
                  </Link>
                )}
            </VStack>
            <VStack alignItems="start">
              {alert_info?.alert_name && (
                <DetailsGridRow
                  isDetailsPage
                  data-testid="detection-alert-name"
                  title={alertCategory}
                >
                  <Box
                    __css={{
                      wordWrap: 'anywhere',
                      _hover: { textDecoration: 'underline' },
                    }}
                  >
                    <RouterLink
                      color="text.link"
                      __css={{
                        wordWrap: 'anywhere',
                      }}
                      data-testid="pivot-detection-alert-catalog-link"
                      to={`${systemSettingsAlertCatalog(
                        `/${alert_info?.content_id}?start=${start}&end=${end}`,
                      )}`}
                    >
                      {alert_info?.alert_name}
                    </RouterLink>
                  </Box>
                </DetailsGridRow>
              )}
              {rank?.severity !== null && rank?.severity !== undefined && (
                <DetailsGridRow
                  isDetailsPage
                  data-testid="detection-alert-severity-score"
                  title={severity}
                >
                  <SeverityScore
                    score={rank.severity}
                    isCustom={rank?.is_custom_severity}
                  />
                </DetailsGridRow>
              )}
              {total_alert_count && (
                <DetailsGridRow
                  isDetailsPage
                  title={numberOfAlerts}
                  data-testid="detection-alert-count"
                >
                  <Text>{total_alert_count}</Text>
                </DetailsGridRow>
              )}
              {earliest_start_timestamp && (
                <DetailsGridRow
                  isDetailsPage
                  title={firstTimeSeen}
                  data-testid="detection-first-timestamp"
                >
                  <Text>
                    {moment
                      .unix(earliest_start_timestamp)
                      .format('MMMM Do, h:mma')}
                  </Text>
                </DetailsGridRow>
              )}
              {latest_start_timestamp && (
                <DetailsGridRow
                  isDetailsPage
                  title={lastTimeSeen}
                  data-testid="detection-last-timestamp"
                >
                  <Text>
                    {moment
                      .unix(latest_start_timestamp)
                      .format('MMMM Do, h:mma')}
                  </Text>
                </DetailsGridRow>
              )}
              {alert_info?.alert_type && (
                <DetailsGridRow
                  title={type}
                  isDetailsPage
                  data-testid="detection-type"
                >
                  <Text>{stringifyAlertType(alert_info?.alert_type)}</Text>
                </DetailsGridRow>
              )}
              {!!metadata_mitre_mappings?.tactics?.length && (
                <VStack alignItems="start">
                  <Text
                    color="text.secondary"
                    data-testid={'metadata_mitre_mappings-label'}
                  >
                    {mitreTechniques}
                  </Text>
                  <MitreTechniquesGrid
                    tactics={metadata_mitre_mappings?.tactics}
                  />
                </VStack>
              )}
            </VStack>
          </HStack>
        </AccordionPanel>
        <AccordionPanel
          w="100%"
          title={
            <HStack>
              <Text>{entity}</Text>
              {!edrConfig?.enabled && (
                <Box h="20px">
                  <Popover
                    isLazy
                    width="320px"
                    trigger="hover"
                    placement="right"
                    title={entityEDR.entityEnrichment}
                    body={entityEDR.getEntityEnrichment}
                    footer={
                      <Button
                        size="md"
                        variant="solid"
                        colorScheme="blue"
                        rightIcon={<MuiIcon>arrow_forward</MuiIcon>}
                        onClick={() => {
                          navigate({
                            pathname: systemSettingsIntegrationsConfig,
                          });
                        }}
                      >
                        {entityEDR.integrationPage}
                      </Button>
                    }
                  >
                    <MuiIcon color="text.secondary">info</MuiIcon>
                  </Popover>
                </Box>
              )}
            </HStack>
          }
          reduceMotion={true}
          storageKey="detection-details-entity"
        >
          <HStack alignItems="start">
            <VStack w="50%" alignItems="start">
              <HStack>
                <MuiIcon size="lg" color={hasSource ? 'white' : 'gray'}>
                  crisis_alert
                </MuiIcon>
                <Text
                  as="h5"
                  textStyle="h5"
                  color={hasSource ? 'white' : 'gray'}
                >
                  {source}
                  {sourceEntities && sourceEntities.length !== 1
                    ? ` (${sourceEntities?.length})`
                    : ''}
                </Text>
              </HStack>
              {sourceEntities
                ?.slice(0, NUM_ENTITIES_SHOWN)
                .map((entity, index) => (
                  <Button
                    size="xs"
                    key={index}
                    variant="solid"
                    colorScheme="gray"
                    onClick={() => {
                      onOpen();
                      setActiveEntity(entity);
                      setEntityType('source');
                    }}
                  >
                    {entity.entity_name}
                  </Button>
                ))}
              {sourceEntitiesOverflow && sourceEntitiesOverflow > 0 && (
                <Button
                  size="xs"
                  variant="solid"
                  colorScheme="blue"
                  onClick={() => {
                    onOpen();
                    setActiveEntity(sourceEntities[NUM_ENTITIES_SHOWN]);
                    setEntityType('source');
                  }}
                >
                  +{sourceEntitiesOverflow}
                </Button>
              )}
            </VStack>
            <VStack w="50%" alignItems="start">
              <HStack>
                <MuiIcon size="lg" color={hasDestination ? 'white' : 'gray'}>
                  location_on
                </MuiIcon>
                <Text
                  as="h5"
                  textStyle="h5"
                  color={hasDestination ? 'white' : 'gray'}
                >
                  {destination}
                  {destinationEntities && destinationEntities.length !== 1
                    ? ` (${destinationEntities.length})`
                    : ''}
                </Text>
              </HStack>
              <HStack>
                {destinationEntities
                  ?.slice(0, NUM_ENTITIES_SHOWN)
                  .map((entity, index) => (
                    <Button
                      size="xs"
                      key={index}
                      variant="solid"
                      colorScheme="gray"
                      onClick={() => {
                        onOpen();
                        setActiveEntity(entity);
                        setEntityType('destination');
                      }}
                    >
                      {entity.entity_name}
                    </Button>
                  ))}
                {destinationEntitiesOverflow &&
                  destinationEntitiesOverflow > 0 && (
                    <Button
                      size="xs"
                      variant="solid"
                      colorScheme="blue"
                      onClick={() => {
                        onOpen();
                        setActiveEntity(
                          destinationEntities[NUM_ENTITIES_SHOWN],
                        );
                        setEntityType('destination');
                      }}
                    >
                      +{destinationEntitiesOverflow}
                    </Button>
                  )}
              </HStack>
            </VStack>
          </HStack>
        </AccordionPanel>
        {isCorrelationChart ? (
          detection && (
            <AccordionPanel
              w="100%"
              reduceMotion={true}
              title="Correlation Chart"
              storageKey="detection-details-chart"
            >
              <RelatedDetections
                detection={detection}
                onPreviewOpen={onPreviewOpen}
                suricataRuleText={suricataRuleText}
                selectedDetection={selectedDetection}
                setSelectedDetectionId={setSelectedDetectionId}
                getAppConfigurationsData={getAppConfigurationsData}
              />
            </AccordionPanel>
          )
        ) : (
          <DetectionDetailsTable
            suricataRuleText={suricataRuleText}
            getAppConfigurationsData={getAppConfigurationsData}
          />
        )}
      </VStack>
      {entityType && activeEntity && (
        <Drawer
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={() => {
            onClose();
            setActiveEntity(null);
            setEntityType(undefined);
          }}
          data-testid="detection-entity-details-drawer"
          title={
            <HStack>
              <Text textTransform="capitalize">{entityType}</Text>
              {entities && entities?.length > 1 && (
                <Text>({entities.length})</Text>
              )}
            </HStack>
          }
          body={
            edrConfig && edrConfig?.enabled ? (
              <DetectionEntityEDRPanel
                edrConfig={edrConfig}
                refetchMain={true}
                entities={entities}
                entityInfo={activeEntity}
                detectionInfo={detectionInfo}
                queryVariables={queryVariables}
                setActiveEntity={setActiveEntity}
                excludeEntitiesError={excludeEntitiesError}
                excludeEntitiesData={
                  excludeEntitiesData
                    ?.queryAlertMetadataExcludedEntitiesPaginated
                    ?.excluded_entities
                }
              />
            ) : (
              <DetectionEntityPanel
                refetchMain={true}
                entities={entities}
                entityInfo={activeEntity}
                detectionInfo={detectionInfo}
                queryVariables={queryVariables}
                setActiveEntity={setActiveEntity}
                excludeEntitiesError={excludeEntitiesError}
                excludeEntitiesData={
                  excludeEntitiesData
                    ?.queryAlertMetadataExcludedEntitiesPaginated
                    ?.excluded_entities
                }
              />
            )
          }
        ></Drawer>
      )}
      {selectedDetection && (
        <Drawer
          isOpen={isPreviewOpen}
          onOpen={onPreviewOpen}
          onClose={() => {
            onPreviewClose();
          }}
          data-testid="detections-table-row-drawer"
          title={`${selectedDetection?.alert_info?.alert_name} | ${selectedDetection?.alert_entity?.entity_name}`}
          body={
            <VStack w="100%">
              {queryVariables && (
                <Box w="100%" my={2}>
                  <DetectionDrawerActions
                    detection={detectionData}
                    setIsExcluded={setIsExcluded}
                    queryDetectionsPaginatedVariables={{
                      query: queryVariables.query,
                      size: queryVariables.size,
                      offset: queryVariables.offset,
                      // @ts-ignore
                      sort: getSortsForAPI(formatSort(queryVariables.sortBy)),
                    }}
                  />
                </Box>
              )}
              <DetectionDrawer
                item={selectedDetection}
                queryDetectionsPaginatedVariables={{
                  query: queryVariables.query,
                  size: queryVariables.size,
                  offset: queryVariables.offset,
                  // @ts-ignore
                  sort: getSortsForAPI(formatSort(queryVariables.sortBy)),
                }}
              />
            </VStack>
          }
          footer={<></>}
        ></Drawer>
      )}
    </>
  );
};
