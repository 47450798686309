import { CTAButton } from '@gamma/buttons';
import { i18n } from '@gamma/investigator/localization';
import {
  CardPageBackButton,
  CardPageCTA,
  CardPageHeading,
  CardPageIllustration,
  CardPageSubtitle,
} from '@gamma/pages';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useOutletContext } from 'react-router-dom';

import { IForgotPasswordContext } from '../ForgotPassword';

export const Complete = () => {
  const navigate = useNavigate();
  const { userEmail } = useOutletContext<IForgotPasswordContext>();

  useEffect(() => {
    if (!userEmail) {
      navigate('/login', { replace: true });
    }
  }, [userEmail]);

  const { title, subtitle, cta } = i18n.pages.forgotPassword.complete;

  return (
    <>
      <CardPageBackButton onClick={() => navigate('/login/trouble')} />
      <CardPageHeading>{title}</CardPageHeading>
      <CardPageIllustration illustration={'CheckOnlyComplete'} />
      <CardPageSubtitle>{subtitle}</CardPageSubtitle>
      <CardPageCTA>
        <CTAButton onClick={() => navigate('/login', { replace: true })}>
          {cta}
        </CTAButton>
      </CardPageCTA>
    </>
  );
};
