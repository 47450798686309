import { ComponentStyleConfig } from '@chakra-ui/react';
import { textStyles } from '../../common';

export const MenuStyles: ComponentStyleConfig = {
  baseStyle: (props: any) => ({
    list: {
      bgColor: 'layer.1',
      borderColor: 'border.1',
      maxHeight: '50vh', // prevent the list from ever being longer than viewport
      overflowY: 'auto',
    },
    item: {
      ...textStyles['body-md'],
      bgColor: 'layer.1',
      _focus: {
        bgColor: 'border.1',
      },
    },
    divider: {
      borderColor: 'layer.2',
    },
    groupTitle: {
      ...textStyles['body-sm'],
      color: 'text.secondary',
      m: 0,
      px: 4,
      py: 1.5,
    },
  }),
  sizes: {
    xs: {
      item: {
        ...textStyles['body-sm'],
        py: 1, // 4px
        px: 4, // 16px
        minH: 6, // 24px
      },
    },
    sm: {
      item: {
        ...textStyles['body-sm'],
        py: 1.5, // 6px
        px: 4, // 16px
        minH: 7, // 28px
      },
    },
    md: {
      item: {
        ...textStyles['body-md'],
        py: 1.5, // 6px
        px: 4, // 16px
        minH: 8, // 32px
      },
    },
    lg: {
      item: {
        ...textStyles['body-md'],
        py: 2.5, // 10px
        px: 4, // 16px
        minH: 10, // 40px
      },
    },
  },
};
