import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

export const ACCEPT_EULA_TC = gql`
  mutation acceptEulaTC {
    acceptEulaTC {
      status_code
      body {
        message
        data
      }
    }
  }
`;

export const useAcceptEulaTC = (
  options?: MutationHookOptions<any, OperationVariables>,
) => {
  return useMutation(ACCEPT_EULA_TC, options);
};
