import { createIcon } from '@chakra-ui/react';

export const MinusIcon = createIcon({
  displayName: 'MinusIcon',
  viewBox: '0 0 24 24',
  d: 'M19 13H5v-2h14v2Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
