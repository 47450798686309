import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

export const UPDATE_TENANT_DISPLAY_NAME = gql`
  mutation updateTenantDisplayName($name: String!) {
    updateTenantDisplayName(name: $name) {
      display_name
      updated_by
      updated_on
    }
  }
`;

export const useUpdateTenantDisplayName = (
  options: MutationHookOptions<any, OperationVariables>,
) => useMutation(UPDATE_TENANT_DISPLAY_NAME, options);
