import { useLazyQuery } from '@apollo/client';
import { Box, Link, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { i18n } from '@gamma/investigator/localization';
import {
  SIMPLE_SEARCH_QUERY,
  useSimpleSearchQuery,
} from '@gamma/investigator/queries';
import { AccordionPanel } from '@gamma/layout';
import { GraphQLReqStatus } from '@gamma/progress';
import {
  DetailsGrid,
  DetailsGridRow,
} from 'libs/investigator/pages/detections/src/lib/Components';
import moment from 'moment';
import { Dispatch, SetStateAction, useState } from 'react';
import { LogscaleDetailsModal } from '../LogscaleDetailsModal';

const { connection, http, ssl, file } = i18n.pages.detections.logscaleDetails;

export interface LogscaleDetailsProps {
  alertsUid: string;
  alertsName: string;
  alertsTimestamp: string;
  setDownloadUrl?: Dispatch<SetStateAction<string>>;
}

export interface connectionDetailsParsedData {
  orig_bytes: string;
  local_orig: string;
  local_resp: string;
  resp_bytes: string;
  service: string;
  _write_ts: string;
  'id.resp_p': string;
  'spcap.url': string;
}

export interface httpDetailsParsedData {
  method: string;
  uri: string;
  user_agent: string;
  post_body: string;
}

export interface sslDetailsParsedData {
  subject: string;
  cert_chain_fps: string;
  server_name: string;
  issuer: string;
  validation_status: string;
}

export interface fileDetailsParsedData {
  mime_type: string;
  'rx_hosts[0]': string;
  seen_bytes: string;
}

export const LogscaleDetails = ({
  alertsUid,
  alertsName,
  alertsTimestamp,
  setDownloadUrl,
}: LogscaleDetailsProps) => {
  const [connectionFlag, setConnectionFlag] = useState<boolean>(false);
  const [connectionDetailsParsedData, setConnectionDetailsParsedData] =
    useState<connectionDetailsParsedData>();
  const [moreConnectionDetailsParsedData, setMoreConnectionDetailsParsedData] =
    useState<any>();
  const [httpDetailsParsedData, setHttpDetailsParsedData] =
    useState<httpDetailsParsedData>();
  const [sslDetailsParsedData, setSslDetailsParsedData] =
    useState<sslDetailsParsedData>();
  const [fileDetailsParsedData, setFileDetailsParsedData] =
    useState<fileDetailsParsedData>();
  const {
    isOpen: showMoreConnectionIsOpen,
    onOpen: showMoreConnectionOnOpen,
    onClose: showMoreConnectionOnClose,
  } = useDisclosure();

  const startTime = moment(alertsTimestamp).subtract(1, 'hours').unix();

  const endTime = moment(alertsTimestamp).add(24, 'hours').unix();

  const { loading: connectionDetailsLoading } = useSimpleSearchQuery({
    variables: {
      clientSessionId: localStorage.getItem('session_id_token'),
      queryString: `#path=conn|uid="${alertsUid}"|groupby([_write_ts], function=collect([id.resp_p, service, orig_bytes, resp_bytes, local_orig, local_resp, spcap.url]))`,
      start: startTime.toString(),
      end: endTime.toString(),
    },
    onCompleted: (data) => {
      if (data?.simpleSearchQuery?.events[0]) {
        const connectionData = JSON.parse(data?.simpleSearchQuery?.events[0]);
        setConnectionDetailsParsedData(connectionData);
        setDownloadUrl &&
          setDownloadUrl(
            connectionData?.['spcap.url'] ? connectionData?.['spcap.url'] : '',
          );
      }
    },
  });

  const [simpleSearchQuery, { loading: moreConnectionDetailsLoading }] =
    useLazyQuery(SIMPLE_SEARCH_QUERY, {
      variables: {
        clientSessionId: localStorage.getItem('session_id_token'),
        queryString: `#path=conn|uid="${alertsUid}"`,
        start: startTime.toString(),
        end: endTime.toString(),
      },
      onCompleted: (data) => {
        setMoreConnectionDetailsParsedData(
          data?.simpleSearchQuery?.events[0]
            ? JSON.parse(data?.simpleSearchQuery?.events[0])
            : undefined,
        );
      },
    });

  const { loading: httpDetailsLoading } = useSimpleSearchQuery({
    variables: {
      clientSessionId: localStorage.getItem('session_id_token'),
      queryString: `#path=http|uid="${alertsUid}"|groupby([_write_ts], function=collect([method, uri, user_agent, post_body, status_code, client_header[0], client_header[1], client_header[2], host]))`,
      start: startTime.toString(),
      end: endTime.toString(),
    },
    onCompleted: (data) => {
      setHttpDetailsParsedData(
        data?.simpleSearchQuery?.events[0]
          ? JSON.parse(data?.simpleSearchQuery?.events[0])
          : undefined,
      );
    },
  });

  const { loading: sslDetailsLoading } = useSimpleSearchQuery({
    variables: {
      clientSessionId: localStorage.getItem('session_id_token'),
      queryString: `#path=ssl|uid="${alertsUid}"|groupby([_write_ts], function=collect([subject, cert_chain_fps, server_name, issuer, validation_status]))`,
      start: startTime.toString(),
      end: endTime.toString(),
    },
    onCompleted: (data) => {
      setSslDetailsParsedData(
        data?.simpleSearchQuery?.events[0]
          ? JSON.parse(data?.simpleSearchQuery?.events[0])
          : undefined,
      );
    },
  });

  const { loading: fileDetailsLoading } = useSimpleSearchQuery({
    variables: {
      clientSessionId: localStorage.getItem('session_id_token'),
      queryString: `#path=files|uid="${alertsUid}"|groupby([_write_ts], function=collect([fuid, md5, sha256, mime_type, seen_bytes, rx_hosts[0], rx_hosts[1], rx_hosts[2]]))`,
      start: startTime.toString(),
      end: endTime.toString(),
    },
    onCompleted: (data) => {
      setFileDetailsParsedData(
        data?.simpleSearchQuery?.events[0]
          ? JSON.parse(data?.simpleSearchQuery?.events[0])
          : undefined,
      );
    },
  });

  return (
    <VStack w="100%" alignItems="start">
      <Box w="100%">
        <AccordionPanel title={connection.title}>
          {connectionDetailsLoading ? (
            <GraphQLReqStatus loading={connectionDetailsLoading} />
          ) : (
            <DetailsGrid>
              <DetailsGridRow
                title={connection.protocol}
                data-testid="detections-protocol"
              >
                <Text overflowWrap="anywhere">
                  {connectionDetailsParsedData?.service
                    ? connectionDetailsParsedData.service
                    : '-'}
                </Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={connection.port}
                data-testid="detections-port"
              >
                <Text overflowWrap="anywhere">
                  {connectionDetailsParsedData?.['id.resp_p']
                    ? connectionDetailsParsedData['id.resp_p']
                    : '-'}
                </Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={connection.bytesSent}
                data-testid="detections-bytes-sent"
              >
                <Text overflowWrap="anywhere">
                  {connectionDetailsParsedData?.orig_bytes
                    ? connectionDetailsParsedData.orig_bytes
                    : '-'}
                </Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={connection.bytesReturned}
                data-testid="detections-bytes-returned"
              >
                <Text overflowWrap="anywhere">
                  {connectionDetailsParsedData?.resp_bytes
                    ? connectionDetailsParsedData.resp_bytes
                    : '-'}
                </Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={connection.localOriginator}
                data-testid="detections-local-originator"
              >
                <Text overflowWrap="anywhere">
                  {connectionDetailsParsedData?.local_orig
                    ? connectionDetailsParsedData.local_orig
                    : '-'}
                </Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={connection.localResponder}
                data-testid="detections-local-responder"
              >
                <Text>
                  {connectionDetailsParsedData?.local_resp
                    ? connectionDetailsParsedData.local_resp
                    : '-'}
                </Text>
              </DetailsGridRow>
              {connectionDetailsParsedData && (
                <Link
                  color="text.link"
                  data-testid="connection-see-more-button"
                  onClick={() => {
                    showMoreConnectionOnOpen();
                    setConnectionFlag(true);
                    simpleSearchQuery();
                  }}
                >
                  {connection.seeMore}
                </Link>
              )}
            </DetailsGrid>
          )}
        </AccordionPanel>
      </Box>

      <Box w="100%">
        <AccordionPanel title={http.title}>
          {httpDetailsLoading ? (
            <GraphQLReqStatus loading={httpDetailsLoading} />
          ) : (
            <DetailsGrid>
              <DetailsGridRow title={http.uri} data-testid="detections-uri">
                <Text overflowWrap="anywhere">
                  {httpDetailsParsedData?.uri
                    ? httpDetailsParsedData?.uri
                    : '-'}
                </Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={http.method}
                data-testid="detections-method"
              >
                <Text overflowWrap="anywhere">
                  {httpDetailsParsedData?.method
                    ? httpDetailsParsedData?.method
                    : '-'}
                </Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={http.postBody}
                data-testid="detections-post-body"
              >
                <Text overflowWrap="anywhere">
                  {httpDetailsParsedData?.post_body
                    ? httpDetailsParsedData?.post_body
                    : '-'}
                </Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={http.userAgent}
                data-testid="detections-user-agent"
              >
                <Text overflowWrap="anywhere">
                  {httpDetailsParsedData?.user_agent
                    ? httpDetailsParsedData?.user_agent
                    : '-'}
                </Text>
              </DetailsGridRow>
            </DetailsGrid>
          )}
        </AccordionPanel>
      </Box>
      <Box w="100%">
        <AccordionPanel title={ssl.title}>
          {sslDetailsLoading ? (
            <GraphQLReqStatus loading={sslDetailsLoading} />
          ) : (
            <DetailsGrid>
              <DetailsGridRow title="Subject" data-testid="detections-subject">
                <Text overflowWrap="anywhere">
                  {sslDetailsParsedData?.subject
                    ? sslDetailsParsedData?.subject
                    : '-'}
                </Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={ssl.certChainFps}
                data-testid="detections-cert-chain-fps"
              >
                <Text overflowWrap="anywhere">
                  {sslDetailsParsedData?.cert_chain_fps
                    ? sslDetailsParsedData?.cert_chain_fps
                    : '-'}
                </Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={ssl.serverName}
                data-testid="detections-server-name"
              >
                <Text overflowWrap="anywhere">
                  {sslDetailsParsedData?.server_name
                    ? sslDetailsParsedData?.server_name
                    : '-'}
                </Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={ssl.issuer}
                data-testid="detections-issuer"
              >
                <Text overflowWrap="anywhere">
                  {sslDetailsParsedData?.issuer
                    ? sslDetailsParsedData?.issuer
                    : '-'}
                </Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={ssl.validationStatus}
                data-testid="detections-validation-status"
              >
                <Text overflowWrap="anywhere">
                  {sslDetailsParsedData?.validation_status
                    ? sslDetailsParsedData?.validation_status
                    : '-'}
                </Text>
              </DetailsGridRow>
            </DetailsGrid>
          )}
        </AccordionPanel>
      </Box>
      <Box w="100%">
        <AccordionPanel title={file.title}>
          {fileDetailsLoading ? (
            <GraphQLReqStatus loading={fileDetailsLoading} />
          ) : (
            <DetailsGrid>
              <DetailsGridRow
                title={file.mimeType}
                data-testid="detections-mime-type"
              >
                <Text overflowWrap="anywhere">
                  {fileDetailsParsedData?.mime_type
                    ? fileDetailsParsedData?.mime_type
                    : '-'}
                </Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={file.rxHosts}
                data-testid="detections-rx-hosts"
              >
                <Text overflowWrap="anywhere">
                  {fileDetailsParsedData?.['rx_hosts[0]']
                    ? fileDetailsParsedData?.['rx_hosts[0]']
                    : '-'}
                </Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={file.seenBytes}
                data-testid="detections-seen-bytes"
              >
                <Text overflowWrap="anywhere">
                  {fileDetailsParsedData?.seen_bytes
                    ? fileDetailsParsedData?.seen_bytes
                    : '-'}
                </Text>
              </DetailsGridRow>
            </DetailsGrid>
          )}
        </AccordionPanel>
      </Box>
      <LogscaleDetailsModal
        alertsName={alertsName}
        alertsTimestamp={alertsTimestamp}
        isModalOpen={showMoreConnectionIsOpen}
        onModalClose={showMoreConnectionOnClose}
        data={moreConnectionDetailsParsedData}
        loading={moreConnectionDetailsLoading}
        title={connection.title}
        connectionFlag={connectionFlag}
      />
    </VStack>
  );
};
