import { Center, Square, Stack, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface LicenseTypeIllustrationProps {
  icon: ReactNode;
  title: string;
}
export const LicenseTypeIllustration = ({
  icon,
  title,
}: LicenseTypeIllustrationProps) => {
  return (
    <Stack>
      <Square minH={'100px'}>{icon}</Square>
      <Center>
        <Text textStyle={'h5'} mb={2} whiteSpace="nowrap">
          {title}
        </Text>
      </Center>
    </Stack>
  );
};
