import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Button, Heading, HStack } from '@chakra-ui/react';
import { RenderProps } from 'dayzed';
import React, { useMemo } from 'react';

export const Month_Names = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export interface CalendarNavProps {
  renderProps: RenderProps;
}

export const CalendarNav = React.memo(
  ({
    renderProps: { calendars, getBackProps, getForwardProps },
  }: CalendarNavProps) => {
    const backProps = useMemo(() => {
      const { disabled, ...props } = getBackProps({ calendars });
      return {
        ...props,
        isDisabled: disabled,
      };
    }, [calendars, getBackProps]);
    const forwardProps = useMemo(() => {
      const { disabled, ...props } = getForwardProps({ calendars });
      return {
        ...props,
        isDisabled: disabled,
      };
    }, [calendars, getForwardProps]);
    return (
      <HStack justifyContent="center">
        <Button
          {...backProps}
          variant="ghost"
          bg="transparent"
          border="none"
          size="sm"
          _disabled={{
            bg: 'transparent',
            color: 'text.neutral',
            opacity: 0.4,
          }}
          data-testid="calendar-panel-prev-month"
        >
          <ChevronLeftIcon h="5" w="5" />
        </Button>
        <Heading
          size="sm"
          textAlign="center"
          data-testid="calendar-panel-heading"
        >
          {Month_Names[calendars[0].month]} {calendars[0].year}
        </Heading>
        <Button
          {...forwardProps}
          variant="ghost"
          bg="transparent"
          border="none"
          size="sm"
          _disabled={{
            bg: 'transparent',
            color: 'text.neutral',
            opacity: 0.4,
          }}
          data-testid="calendar-panel-next-month"
        >
          <ChevronRightIcon h="5" w="5" />
        </Button>
      </HStack>
    );
  },
);
