import { gql, OperationVariables, QueryHookOptions, useQuery } from '@apollo/client';

import { PaginatedExcludedEntities } from './types';

export interface IQueryAlertMetadataExcludedEntitiesPaginated {
  queryAlertMetadataExcludedEntitiesPaginated: PaginatedExcludedEntities;
}

export const QUERY_ALERT_METADATA_EXCLUDED_ENTITIES_PAGINATED = gql`
  query queryAlertMetadataExcludedEntitiesPaginated(
    $query: String
    $offset: Int
    $size: Int
    $sort: [SortParameterInput]
  ) {
    queryAlertMetadataExcludedEntitiesPaginated(
      query: $query
      offset: $offset
      size: $size
      sort: $sort
    ) {
      offset
      size
      excluded_entities {
        _score
        content_id
        entity_id
        entity_ip
        entity_ip_range
        entity_name
        entity_type
        tenant
        created_by
        created_by_alias
        created_timestamp
      }
      total_items
    }
  }
`;

export const useQueryAlertMetadataExcludedEntitiesPaginated = (
  options: QueryHookOptions<
    IQueryAlertMetadataExcludedEntitiesPaginated,
    OperationVariables
  >,
) => {
  return useQuery<IQueryAlertMetadataExcludedEntitiesPaginated>(
    QUERY_ALERT_METADATA_EXCLUDED_ENTITIES_PAGINATED,
    options,
  );
};
