import { Divider, HStack, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface TextDividerProps {
  children?: ReactNode;
  color?: string;
  textStyle?: string;
}

export const TextDivider = ({
  children = 'Or',
  color = 'gray.500',
  textStyle = 'body-sm',
}: TextDividerProps) => {
  return (
    <HStack w="full">
      <Divider flex="1" />
      <Text
        w="fit-content"
        color={color}
        textStyle={textStyle}
        textTransform="uppercase"
      >
        {children}
      </Text>
      <Divider flex="1" />
    </HStack>
  );
};
