import { OperationVariables } from '@apollo/client';
import { Center, Stack } from '@chakra-ui/react';
import { DottedProgressBar, DottedProgressBarItem } from '@gamma/display';
import { Dispatch, SetStateAction } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';

import { MutationFunctionType } from '../../../types';
import { ModalSteps } from '../IdpConfigurationModal';
import { ConfigureIDP } from './ConfigureIDP';
import { ConfigureInvestigator } from './ConfigureInvestigator';
import { ConfigureRoles } from './ConfigureRoles';

interface ModalBodyContentProps {
  step: number;
  xmlFile: File[];
  setXmlFile: Dispatch<SetStateAction<File[]>>;
  fileError: string;
  setFileError: Dispatch<SetStateAction<string>>;
  useFormProps: UseFormReturn<FieldValues, any>;
  resetModalForm: () => void;
  showAlert: string;
  configureIdentityProvider: MutationFunctionType;
  data: OperationVariables | undefined;
  isUpdating: boolean;
  useIDPUploadXMLHandlerProps: Record<string, any>;
  parsedData: Record<string, string> | undefined;
  setParsedData: Dispatch<SetStateAction<any>>;
}

type ModalBodyProps = ModalBodyContentProps;

const ModalBodyContent = ({
  step,
  setFileError,
  xmlFile,
  setXmlFile,
  configureIdentityProvider,
  useFormProps,
  resetModalForm,
  showAlert,
  fileError,
  data,
  isUpdating,
  useIDPUploadXMLHandlerProps,
  parsedData,
  setParsedData,
}: ModalBodyContentProps) => {
  switch (step) {
    case ModalSteps.configureIDP:
      return <ConfigureIDP />;
    case ModalSteps.configureInvestigator:
      return (
        <ConfigureInvestigator
          configureIdentityProvider={configureIdentityProvider}
          useFormProps={useFormProps}
          xmlFile={xmlFile}
          showAlert={showAlert}
          fileError={fileError}
          setFileError={setFileError}
          setXmlFile={setXmlFile}
          resetModalForm={resetModalForm}
          data={data}
          isUpdating={isUpdating}
          useIDPUploadXMLHandlerProps={useIDPUploadXMLHandlerProps}
          parsedData={parsedData}
          setParsedData={setParsedData}
        />
      );
    case ModalSteps.configureRoles:
      return <ConfigureRoles />;
    default:
      return null;
  }
};

export const ModalBody = ({
  step,
  setFileError,
  xmlFile,
  setXmlFile,
  useFormProps,
  resetModalForm,
  showAlert,
  configureIdentityProvider,
  fileError,
  data,
  isUpdating,
  useIDPUploadXMLHandlerProps,
  parsedData,
  setParsedData,
}: ModalBodyProps) => {
  return (
    <Stack spacing={6}>
      <Center>
        <DottedProgressBar activeStep={step}>
          <DottedProgressBarItem stepNumber={1}>
            Configure IdP
          </DottedProgressBarItem>
          <DottedProgressBarItem stepNumber={2}>
            Configure Investigator
          </DottedProgressBarItem>
          <DottedProgressBarItem stepNumber={3}>
            Configure Roles
          </DottedProgressBarItem>
        </DottedProgressBar>
      </Center>
      <ModalBodyContent
        configureIdentityProvider={configureIdentityProvider}
        step={step}
        setFileError={setFileError}
        xmlFile={xmlFile}
        setXmlFile={setXmlFile}
        useFormProps={useFormProps}
        resetModalForm={resetModalForm}
        showAlert={showAlert}
        fileError={fileError}
        data={data}
        isUpdating={isUpdating}
        useIDPUploadXMLHandlerProps={useIDPUploadXMLHandlerProps}
        parsedData={parsedData}
        setParsedData={setParsedData}
      />
    </Stack>
  );
};
