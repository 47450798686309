import { Confirm2fa } from './Confirm2fa';
import { Login as Main } from './Login';
import { LoginTrouble } from './LoginTrouble';
import { SSOLoginErrors, SSOLogin } from './SSOLogin';
import { TwoFactorRecovery } from './TwoFactorRecovery';

export const Login = {
  main: Main,
  trouble: LoginTrouble,
  mfa: Confirm2fa,
  twoFactorRecovery: TwoFactorRecovery,
  ssoLogin: SSOLogin,
  ssoLoginErrors: SSOLoginErrors,
};

export * from './ForgotPassword';
export * from './Registration';
