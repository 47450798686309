import { useDisclosure } from '@chakra-ui/react';
import { useCallback, useEffect, memo } from 'react';
import {
  unstable_useBlocker as useBlocker,
  useLocation,
} from 'react-router-dom';

import { AbortModal, AbortModalProps } from '../AbortModal';
import { usePrevious } from '@gamma/hooks';
import { isEqual, omit } from 'lodash';

export interface NavigationConfirmationModalProps extends AbortModalProps {
  when:
    | ((params: { currentPath: string; nextPath: string }) => boolean)
    | boolean;
}

export const NavigationConfirmationModal = memo(
  ({ when, onConfirm, ...rest }: NavigationConfirmationModalProps) => {
    const { pathname: currentPath } = useLocation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const blocker = useBlocker(
      typeof when === 'function'
        ? ({
            currentLocation: { key: currKey, ...currentLocation },
            nextLocation: { key: nextKey, ...nextLocation },
          }) => {
            if (isEqual(currentLocation, nextLocation)) {
              return false;
            } else {
              return when({
                currentPath: currentLocation.pathname,
                nextPath: nextLocation.pathname,
              });
            }
          }
        : when,
    );

    const prevBlocker = usePrevious(blocker);

    useEffect(() => {
      const whenResult =
        typeof when === 'function' && blocker.location
          ? when({
              currentPath,
              nextPath: blocker?.location.pathname,
            })
          : when;

      // Reset the blocker if the user cleans the form
      if (blocker.state === 'blocked' && !whenResult) {
        blocker.reset();
      }
    }, [blocker, when]);

    useEffect(() => {
      if (prevBlocker && prevBlocker.state !== blocker.state) {
        if (blocker.state === 'blocked') {
          onOpen();
        }
      }
    }, [prevBlocker, blocker]);

    const handleClose = useCallback(() => {
      if (blocker && blocker.state === 'blocked') {
        onClose();
        blocker.reset?.();
      }
    }, [blocker]);

    return (
      <AbortModal
        data-testid="navigation-confirmation-modal"
        isOpen={isOpen}
        onClose={handleClose}
        onCancel={handleClose}
        onOpen={onOpen}
        onConfirm={() => {
          onConfirm?.();
          blocker.proceed?.();
        }}
        size="md"
        {...rest}
      />
    );
  },
);
