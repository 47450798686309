import { ComponentStyleConfig, cssVar } from '@chakra-ui/react';

import { bodyStyles } from '../../common';

const STATUSES = {
  info: 'blue',
  warning: 'yellow',
  success: 'green',
  error: 'red',
  loading: 'blue',
};
const $fg = cssVar('alert-fg');
const $bg = cssVar('alert-bg');

export const AlertStyles: Partial<ComponentStyleConfig> = {
  baseStyle: {
    container: {
      borderRadius: 'base',
      boxShadow: 'sm',
      py: 2,
      alignItems: 'center !important',
      bg: $bg.reference,
    },
    title: {
      ...bodyStyles['body-md-bold'],
    },
    description: {
      ...bodyStyles['body-md'],
    },
    icon: {
      alignSelf: 'flex-start',
      color: $fg.reference,
    },
  },
  variants: {
    solid: (props) => {
      const { status = 'info' } = props;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore because I can't type status
      const color = STATUSES[status];
      return {
        container: {
          color: 'gray.50',
          [$fg.variable]: `colors.${color}.100`,
          [$bg.variable]: `colors.${color}.600`,
          _dark: {
            [$fg.variable]: `colors.${color}.100`,
            [$bg.variable]: `colors.${color}.600`,
          },
        },
        icon: {
          color: $fg.reference,
        },
      };
    },
    subtle: (props) => {
      const { status = 'info' } = props;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore because I can't type status
      const color = STATUSES[status];
      return {
        container: {
          [$fg.variable]: `colors.${color}.400`,
          bg: 'layer.0',
          border: 'solid 1px',
          borderColor: `${color}.500`,
        },
        icon: {
          color: $fg.reference,
        },
      };
    },
    'left-accent': (props) => {
      const { status = 'info' } = props;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore because I can't type status
      const color = STATUSES[status];
      return {
        container: {
          [$fg.variable]: `colors.${color}.400`,
          position: 'relative',
          bg: 'layer.0',
          border: 'solid 1px',
          borderColor: `${color}.500`,
          _before: {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            width: 1,
            bg: `${color}.500`,
          },
        },
        icon: {
          color: $fg.reference,
        },
      };
    },
    'top-accent': (props) => {
      const { status = 'info' } = props;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore because I can't type status
      const color = STATUSES[status];
      return {
        container: {
          [$fg.variable]: `colors.${color}.400`,
          position: 'relative',
          bg: 'layer.0',
          border: 'solid 1px',
          borderColor: `${color}.500`,
          _before: {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: 1,
            bg: `${color}.500`,
          },
        },
        icon: {
          color: $fg.reference,
        },
      };
    },
  },
  defaultProps: {
    variant: 'solid',
  },
};
