import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { Detection } from './types';

interface Detections {
  detections: Detection[];
}

export interface IGetDetections {
  getDetections: Detections;
}

export const GET_DETECTIONS = gql`
  query getDetections(
    $detection_ids: [DetectionId!]!
    $dest_paginated_entity_input: PaginatedEntityInput
    $source_paginated_entity_input: PaginatedEntityInput
  ) {
    getDetections(detection_ids: $detection_ids) {
      detections {
        alert_entity {
          entity_id
          entity_name
          entity_type
        }
        alert_info {
          alert_name
          alert_type
          content_id
        }
        assignment_info {
          assigned_at
          assigned_to_username
          assigned_to_user_alias
        }
        created_timestamp
        detection_id
        detection_keys {
          tenant_detection
          tenant_detection_entity
          tenant_entity
        }
        detection_status
        detection_timestamp {
          end
          start
        }
        earliest_start_timestamp
        latest_start_timestamp
        rank {
          is_custom_severity
          severity
        }
        tenant
        tenant_info {
          tenant_id
          tenant_display_name
        }
        total_alert_count
        update_info {
          last_updated_by
          last_updated_timestamp
        }
        escalation_data {
          escalation_status
          escalated_by_user_alias
          escalated_timestamp
          escalation_service_name
        }
        logscale_query
        paginated_destination_entities(
          paginated_entity_input: $dest_paginated_entity_input
        ) {
          offset
          size
          alert_entities {
            entity_id
            entity_name
            entity_type
          }
          total_items
          sort {
            sort_by
            sort_dir
          }
        }
        paginated_source_entities(
          paginated_entity_input: $source_paginated_entity_input
        ) {
          offset
          size
          alert_entities {
            entity_id
            entity_name
            entity_type
          }
          total_items
          sort {
            sort_by
            sort_dir
          }
        }
      }
      offset
      size
      sort {
        sort_by
        sort_dir
      }
      total_items
    }
  }
`;

export const useGetDetections = (
  options: QueryHookOptions<IGetDetections, OperationVariables>,
) => useQuery<IGetDetections>(GET_DETECTIONS, options);
