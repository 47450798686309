import { createIcon } from '@chakra-ui/react';

export const DoubleChevronLeftIcon = createIcon({
  displayName: 'DoubleChevronLeftIcon',
  viewBox: '0 0 24 24',
  d: 'M18.41 7.41 17 6l-6 6 6 6 1.41-1.41L13.83 12l4.58-4.59Zm-6 0L11 6l-6 6 6 6 1.41-1.41L7.83 12l4.58-4.59Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
