import { createIcon } from '@chakra-ui/react';

export const EntityCategoryIcon = createIcon({
  displayName: 'EntityCategoryIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M10.2 3c-.999 0-1.8.801-1.8 1.8v2.7c0 .999.801 1.8 1.8 1.8h.9v1.8H3v1.8h3.6v1.8h-.9c-.999 0-1.8.801-1.8 1.8v2.7c0 .999.801 1.8 1.8 1.8h3.6c.999 0 1.8-.801 1.8-1.8v-2.7c0-.999-.801-1.8-1.8-1.8h-.9v-1.8h7.2v1.8h-.9c-.999 0-1.8.801-1.8 1.8v2.7c0 .999.801 1.8 1.8 1.8h3.6c.999 0 1.8-.801 1.8-1.8v-2.7c0-.999-.801-1.8-1.8-1.8h-.9v-1.8H21v-1.8h-8.1V9.3h.9c.999 0 1.8-.801 1.8-1.8V4.8c0-.999-.801-1.8-1.8-1.8h-3.6Zm0 1.8h3.6v2.7h-3.6V4.8ZM5.7 16.5h3.6v2.7H5.7v-2.7Zm9 0h3.6v2.7h-3.6v-2.7Z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
