import { Box, Flex, Tag, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { CrowdStrikeLogoRed } from '@gamma/icons';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  CrowdStrikeConfiguration,
  useGetEDRConfiguration,
  useGetEDRConfigurationPublic,
} from '@gamma/investigator/queries';
import { Panel } from '@gamma/layout';
import { GraphQLReqStatus, LoadingSpinner } from '@gamma/progress';
import { useContext, useState } from 'react';

import { AppConfigProps } from '../../../IntegrationsConfig';
import { CrowdstrikeSettingsCardModal } from '../CrowdstrikeSettingsCardModal';

const { integrations } = i18n.pages;
const { enabled, available } = integrations;

const { crowdStrike, receiveEntityEnrichment } = integrations.crowdStrike;

export const CrowdstrikeSettingsCard = ({
  getAppConfigurationsLoading,
}: AppConfigProps) => {
  const { userRole } = useContext(AuthContext);

  const [isEnableChanged, setIsEnableChanged] = useState<boolean>(false);
  const [showHoverBackGround, setShowHoverBackGround] =
    useState<boolean>(false);

  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      setIsEnableChanged(false);
    },
  });

  const getEDRConfigQuery =
    userRole === 'admin'
      ? useGetEDRConfiguration
      : useGetEDRConfigurationPublic;

  const { loading, error, data } = getEDRConfigQuery({
    variables: {
      provider_name: 'crowdstrike',
    },
  });

  const edrConfiguration =
    data?.getEDRConfiguration as CrowdStrikeConfiguration;

  if (error) {
    return (
      <GraphQLReqStatus
        error={error}
        loading={false}
        isBackground={true}
      ></GraphQLReqStatus>
    );
  }

  return (
    <>
      <Panel
        w="300px"
        h="200px"
        onClick={onOpen}
        layerStyle="first"
        _hover={{ bgColor: 'blue.alpha500.10' }}
        onMouseEnter={() => setShowHoverBackGround(true)}
        onMouseLeave={() => setShowHoverBackGround(false)}
        cursor={userRole !== 'admin' || loading ? 'not-allowed' : 'pointer'}
      >
        <VStack spacing={loading ? 4 : 8}>
          <Flex
            h="92px"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            {showHoverBackGround ? (
              <VStack>
                <Text textStyle="h4">{crowdStrike}</Text>
                <Text textAlign="center" textStyle="body-md" mt={2}>
                  {receiveEntityEnrichment}
                </Text>
              </VStack>
            ) : (
              <>
                <Box mb={5}>
                  <CrowdStrikeLogoRed />
                </Box>
                <Text textStyle="h4">{crowdStrike}</Text>
              </>
            )}
          </Flex>
          {loading || getAppConfigurationsLoading ? (
            <Box>
              <LoadingSpinner size="sm" />
            </Box>
          ) : (
            <Tag
              backgroundColor={
                edrConfiguration?.enabled ? 'green.600' : 'gray.600'
              }
              justifyContent="center"
              alignItems="center"
            >
              {edrConfiguration?.enabled ? (
                <MuiIcon mr={2}>check_circle</MuiIcon>
              ) : (
                <MuiIcon mr={2}>add_circle</MuiIcon>
              )}
              <Text textStyle="body-sm">
                {edrConfiguration?.enabled ? enabled : available}
              </Text>
            </Tag>
          )}
        </VStack>
      </Panel>
      {userRole === 'admin' && isOpen && !loading && (
        <CrowdstrikeSettingsCardModal
          isOpen={isOpen}
          onClose={onClose}
          edrConfiguration={edrConfiguration}
          isEnableChanged={isEnableChanged}
          setIsEnableChanged={setIsEnableChanged}
        />
      )}
    </>
  );
};
