import { SystemStyleObject, SystemStyleObjectRecord } from '@chakra-ui/react';

/** Typography styles */
export const fontFamilyBase =
  'IBM Plex Sans, Helvetica Neue, Arial, sans-serif';

export const fontFamilyMono =
  'IBM Plex Mono, Menlo, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier, monospace';

export const fontFamilyIcon = 'Material Symbols Sharp';

export const fonts = {
  body: fontFamilyBase,
  heading: fontFamilyBase,
  mono: fontFamilyMono,
  icon: fontFamilyIcon,
};

export const fontSizes = {
  xs: '0.625rem', // 10px
  sm: '0.75rem', // 12px
  md: '0.875rem', // 14px
  lg: '1rem', // 16px
  xl: '1.125rem', // 18px
  h4: '1.25rem', // 20px
  h3: '1.75rem', // 28px
  h2: '2rem', // 32px
  h1: '2.625rem', // 42px
};

export const lineHeights = {
  none: 1,
  normal: 'normal',
  base: '1.25rem', // 20px, (md)
  xs: '0.875rem', // 14px
  sm: '1rem', // 16px
  md: '1.25rem', // 20px, (base)
  lg: '1.5rem', // 24px
  xl: '1.625rem', // 26px
  h4: '1.75rem', // 28px
  h3: '2.25rem', // 36px
  h2: '2.5rem', // 40px
  h1: '3.125rem', // 50px
};

export const letterSpacings = {
  normal: 0,
  wide: '0.16px',
  wider: '0.32px',
};

const createBodyTextStyle = (
  prefix: string,
  base: SystemStyleObject,
): SystemStyleObjectRecord => {
  return {
    [`${prefix}`]: {
      ...base,
      fontWeight: '400',
      fontStyle: 'normal',
    },
    [`${prefix}-bold`]: {
      ...base,
      fontWeight: '600',
      fontStyle: 'normal',
    },
    [`${prefix}-italic`]: {
      ...base,
      fontWeight: '400',
      fontStyle: 'italic',
    },
    [`${prefix}-underline`]: {
      ...base,
      fontWeight: '400',
      textDecoration: 'underline',
    },
  };
};

export const headerStyles: SystemStyleObjectRecord = {
  h1: {
    fontSize: 'h1', // 42px
    lineHeight: 'h1', // 50px
    fontWeight: '300',
    letterSpacing: '0px',
    fontStyle: 'normal',
  },
  h2: {
    fontSize: 'h2', // 32px
    lineHeight: 'h2', // 40px
    fontWeight: '300',
    letterSpacing: '0px',
    fontStyle: 'normal',
  },
  h3: {
    fontSize: 'h3', // 28px
    lineHeight: 'h3', // 36px
    fontWeight: '400',
    letterSpacing: '0px',
    fontStyle: 'normal',
  },
  h4: {
    fontSize: 'h4', // 20px
    lineHeight: 'h4', // 28px
    fontWeight: '400',
    letterSpacing: '0px',
    fontStyle: 'normal',
  },
  h5: {
    fontSize: 'lg', // 16px
    lineHeight: 'lg', // 24px
    fontWeight: '600',
    letterSpacing: '0px',
    fontStyle: 'normal',
  },
  h6: {
    fontSize: 'md', // 14px
    lineHeight: 'md', // 20px
    fontWeight: '600',
    letterSpacing: 'wide',
    fontStyle: 'normal',
  },
};

export const bodyStyles: SystemStyleObjectRecord = {
  ...createBodyTextStyle('body-lg', {
    fontSize: 'lg', // 16px
    lineHeight: 'lg',
    letterSpacing: '0px',
  }),
  ...createBodyTextStyle('body-md', {
    fontSize: 'md', // 14px
    lineHeight: 'md',
    letterSpacing: 'wide',
  }),
  ...createBodyTextStyle('body-sm', {
    fontSize: 'sm', // 12px
    lineHeight: 'sm',
    letterSpacing: 'wider',
  }),
  ...createBodyTextStyle('body-xs', {
    fontSize: 'xs', // 10px
    lineHeight: 'xs',
    letterSpacing: 'wider',
  }),
};

export const textStyles: SystemStyleObjectRecord = {
  ...headerStyles,
  ...bodyStyles,
};
