import { gql } from '@apollo/client';

export const QUERY_DETECTIONS_ENTITIES_SUGGESTIONS = gql`
  query queryDetectionsEntitiesSuggestions(
    $entity_name_prefix: String
    $entity_categories: [String]
    $must_conds: [String]
    $size: Int
  ) {
    queryDetectionsEntitiesSuggestions(
      entity_name_prefix: $entity_name_prefix
      entity_categories: $entity_categories
      must_conds: $must_conds
      size: $size
    ) {
      source_entities
      destination_entities
    }
  }
`;
