import { createIcon } from '@chakra-ui/react';

export const CircleStopIcon = createIcon({
  displayName: 'CircleStopIcon',
  viewBox: '0 0 24 24',
  d: 'M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 2c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8ZM9 9v6h6V9',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
