import { createIcon } from '@chakra-ui/react';

export const CrowdStrikeSmall = createIcon({
  displayName: 'CrowdStrike',
  viewBox: '0 0 20 20',
  path: (
    <path
      fill="#C6C6C6"
      fill-rule="evenodd"
      d="M17.21 14.08c-.344-.034-.956-.12-1.72.266-.765.386-1.066.402-1.442.362.11.202.334.479 1.037.528.704.05 1.04.071.67.947.009-.264-.054-.776-.751-.686-.698.09-.861.718-.113 1.031-.243.049-.759.079-1.127-.886-.255.111-.65.333-1.363-.217.166.06.351.092.556.096-.633-.303-1.239-.865-1.626-1.397.308.23.647.457.99.5-.406-.494-1.343-1.483-2.49-2.498.737.481 1.627 1.241 3.083 1.07 1.456-.17 2.435-.504 4.297.883Zm-6.232-.344c-.912-.396-1.108-.474-2.282-.773-1.174-.298-2.329-.92-3.1-1.888.544.4 1.655 1.201 2.797 1.114-.173-.254-.494-.451-.876-.65.432.103 1.738.437 3.461 2.197ZM5.753 7.898C2.735 6.012 2.136 4.558 2 4c1.896 2.074 3.336 2.885 4.317 3.534 2.014 1.284 2.44 2.148 2.675 2.823-.967-1.364-2.247-1.782-3.239-2.46Zm.375 1.495C3.366 7.933 2.725 6.763 2.6 6.23c1.383 1.474 2.92 2.25 3.82 2.754 1.951 1.046 2.449 1.78 2.696 2.352-.93-1.128-2.222-1.523-2.987-1.944Zm8.939 1.89c-.257.61.038.678.158.724.607.232 2.214.53 2.114 1.21.187.187.881.934.591 1.453-.25-.407-1.395-1.463-2.782-1.68-1.386-.218-2.65.25-3.694-.443-.887-.638-1.03-.88-1.922-2.341-.4-.657-.479-1.254-1.84-2.365-1.361-1.111-2.635-1.704-3.17-3.358 1.17 1.438 2.683 2.811 6.486 3.9 4.764 1.328 4.404 2.059 4.059 2.9Zm2.123 2.086c-.008-.263.028-.416-.693-.525.257.127.488.302.693.525Z"
      clip-rule="evenodd"
    />
  ),
  defaultProps: {
    height: '20px',
    width: '20px',
  },
});
