import { ComponentStyleConfig } from '@chakra-ui/react';

import { textStyles } from '../../common';

export const tagStyles: Partial<ComponentStyleConfig> = {
  baseStyle: (props) => ({
    container: { borderRadius: '17px', px: 2 },
    closeButton: {
      my: -2,
      mr: -2,
      color: `${props.colorScheme}.50`,
      opacity: 1,
      _hover: {
        bgColor: 'whiteAlpha.300',
      },
      _disabled: {
        opacity: 0.5,
      },
      _pressed: {
        bgColor: 'whiteAlpha.400',
      },
    },
  }),
  sizes: {
    sm: {
      container: {
        ...textStyles['body-sm'],
        borderRadius: '17px',
        py: 0.5,
        px: 2,
      },
      label: {
        fontWeight: '500',
      },
      closeButton: {
        fontSize: 'md',
        height: '22px',
        width: '22px',
      },
    },
    md: {
      container: {
        ...textStyles['body-sm'],
        borderRadius: '17px',
        py: 1,
        px: 2,
      },
      label: {
        fontWeight: '500',
      },
      closeButton: {
        fontSize: 'md',
        height: '26px',
        width: '26px',
      },
    },
    lg: {
      container: {
        ...textStyles['body-md'],
        borderRadius: '17px',
        py: 1.5,
        px: 2,
      },
      label: {
        fontWeight: '500',
      },
      closeButton: {
        fontSize: 'lg',
        height: '34px',
        width: '34px',
      },
    },
  },
  variants: {
    solid: (props) => ({
      container: {
        bgColor: `${props.colorScheme}.600`,
        border: 'solid 1px',
        borderColor: 'whiteAlpha.300',
      },
      closeButton: {
        color: `${props.colorScheme}.50`,
      },
    }),
    subtle: (props) => ({
      container: {
        border: 'solid 1px',
        borderColor: 'whiteAlpha.300',
        color: `${props.colorScheme}.50`,
        bgColor: `${props.colorScheme}.800`,
      },
      closeButton: {
        color: `${props.colorScheme}.200`,
      },
    }),
    outline: (props) => ({
      container: {
        border: 'solid 1px',
        borderColor: `${props.colorScheme}.400`,
        boxShadow: 'none',
      },
      closeButton: {
        color: `${props.colorScheme}.200`,
      },
    }),
  },
};
