import { createIcon } from '@chakra-ui/react';

export const EllipsisVerticalIcon = createIcon({
  displayName: 'EllipsisVerticalIcon',
  viewBox: '0 0 24 24',
  d: 'M12 16a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm0-6a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm0-6a2 2 0 1 1 0 4 2 2 0 0 1 0-4Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
