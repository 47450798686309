import { ComponentWithAs, IconProps } from '@chakra-ui/react';
import {
  CloudServerIcon,
  ConnectionIcon,
  DomainIcon,
  SourceIpIcon,
} from '@gamma/icons';

export interface EntityTypeIconProps extends IconProps {
  type: 'ip' | 'cloud' | 'domain' | 'connection' | (string & {});
}

export const EntityTypeIcon = ({ type, ...rest }: EntityTypeIconProps) => {
  let Icon: ComponentWithAs<'svg', IconProps> = SourceIpIcon;
  switch (type.toLowerCase()) {
    case 'ip':
    case 'ip_range':
      Icon = SourceIpIcon;
      break;
    case 'cloud':
      Icon = CloudServerIcon;
      break;
    case 'domain':
      Icon = DomainIcon;
      break;
    case 'connection':
      Icon = ConnectionIcon;
      break;
    default:
      Icon = SourceIpIcon;
      break;
  }
  return <Icon {...rest} data-testid={`entity-type-icon-${type}`} />;
};
