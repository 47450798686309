import {
  Box,
  Divider,
  SimpleGrid,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { RenderProps } from 'dayzed';
import { forwardRef } from 'react';

import { CalendarNav } from './CalendarNav';
import { DayOfMonth } from './DayOfMonth';

export interface DatepickerConfigs {
  dateFormat: string;
  monthNames: string[];
  dayNames: string[];
}

interface CalendarPanelProps {
  renderProps: RenderProps;
  onMouseEnterHighlight?: (date: Date) => void;
  isInRange?: (date: Date) => boolean | null;
}

export const Weekday_Names = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export const CalendarPanel = forwardRef<HTMLDivElement, CalendarPanelProps>(
  ({ renderProps, onMouseEnterHighlight, isInRange }, ref) => {
    const { calendars } = renderProps;
    const dayTextColor = useColorModeValue('blue.600', 'blue.300');

    if (calendars.length <= 0) {
      return null;
    }

    return (
      <Stack
        className="datepicker-calendar"
        direction="column"
        justifyContent="center"
        p={2}
        ref={ref}
        data-testid="calendar-panel"
      >
        <CalendarNav renderProps={renderProps} />
        <SimpleGrid columns={7} textAlign="center">
          {Weekday_Names.map((day) => (
            <Box
              textStyle="body-md-bold"
              color={dayTextColor}
              key={`${calendars[0].month}${calendars[0].year}${day}`}
            >
              {day}
            </Box>
          ))}
          <Box gridColumn="1/span 7">
            <Divider mx="-2" my="1" w="calc(100% + var(--chakra-space-4))" />
          </Box>
          {calendars[0].weeks.map((week, weekIdx) => {
            return week.map((dateObj, index) => {
              const key = `${calendars[0].month}${calendars[0].year}${weekIdx}${index}`;
              if (!dateObj) return <Box key={key} />;
              const { date } = dateObj;
              return (
                <DayOfMonth
                  key={key}
                  dateObj={dateObj}
                  renderProps={renderProps}
                  isInRange={isInRange?.(date)}
                  onMouseEnter={() => {
                    onMouseEnterHighlight?.(date);
                  }}
                />
              );
            });
          })}
        </SimpleGrid>
      </Stack>
    );
  },
);
