import { createIcon } from '@chakra-ui/react';

export const PlusIcon = createIcon({
  displayName: 'PlusIcon',
  viewBox: '0 0 24 24',
  d: 'M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
