import { ApolloError } from '@apollo/client';
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Text,
  useDisclosure,
  usePrevious,
  VStack,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { ROUTES } from '@gamma/investigator/constants';
import { useManualPagination } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  Detection,
  IQueryDetectionsPaginated,
} from '@gamma/investigator/queries';
import { Alert, Drawer, ErrorBoundary } from '@gamma/overlay';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';

import {
  DetectionDrawer,
  DetectionDrawerActions,
  DetectionsListView,
  DetectionsSort,
  DetectionsTableView,
} from '../../../../../Components';
import { formatSort, SantizedDetection } from '../../../../../Detections';
import { IDetectionContext } from '../../../../../Pages';

import { RelatedFilters } from './Components';

const { detectionDetails } = ROUTES;

export const DetectionRelated = () => {
  const { detectionData: mainDetection, fetchOriginalRelatedCount } =
    useOutletContext<IDetectionContext>();

  const {
    alert_entity,
    alert_info,
    earliest_start_timestamp,
    latest_start_timestamp,
  } = mainDetection?.getDetections?.detections?.[0] || {};

  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const active_view = params.get('view');
  const sort_by = params.get('sort_by');
  const related_detection_id = params.get('related_detection_id');

  const { detection_id } = useParams();

  const [isExcluded, setIsExcluded] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<
    Detection | SantizedDetection | undefined
  >();

  const [detectionsData, setDetectionsData] =
    useState<IQueryDetectionsPaginated>();
  const [detectionsError, setDetectionsError] = useState<
    ApolloError | undefined
  >();
  const [detectionsLoading, setDetectionsLoading] = useState<boolean>(true);

  const [detectionData, setDetectionData] = useState<
    Detection | SantizedDetection
  >();

  const [sortByParam, setSortByParam] = useState<string>(
    sort_by || 'latest_start_timestamp.desc',
  );

  const [activeView, setActiveView] = useState<string | null>(
    active_view || 'list',
  );

  useEffect(() => {
    if (related_detection_id) {
      setSelectedItem(
        detectionsData?.queryDetectionsPaginated?.detections?.find(
          (detection: Detection) =>
            detection.detection_id === related_detection_id,
        ) ?? detectionsData?.queryDetectionsPaginated?.detections?.[0],
      );
    }
  }, [detectionsData, related_detection_id]);

  const prevSelectedItem = usePrevious(selectedItem);

  useEffect(() => {
    if (prevSelectedItem !== selectedItem && detection_id) {
      selectedItem &&
        params.set('related_detection_id', selectedItem.detection_id);
      navigate(
        {
          pathname: detectionDetails(detection_id),
          search: params.toString(),
        },
        {
          replace: true,
        },
      );
    }
  }, [detection_id, navigate, params, prevSelectedItem, selectedItem]);

  const [queryVariables, setQueryVariables] = useState<any>();

  const {
    sortBy,
    offset,
    pageSize,
    pageIndex,
    pageCount,
    onFetchData,
    getPageCount,
    getSortsForAPI,
  } = useManualPagination<any>({
    sortBy: formatSort(queryVariables?.sortBy),
  });

  const handleFetchData = useCallback(
    (pageIndex: number, pageSize: number, sortBy?: any) => {
      if (sortBy?.length) {
        if (activeView === 'table') {
          setSortByParam(
            `${sortBy[0]?.id}.${sortBy[0]?.desc ? 'desc' : 'asc'}`,
          );
        }
      }
      onFetchData(pageIndex, pageSize, sortBy);
    },
    [activeView],
  );

  const { controls, description } = i18n.pages.detections;

  return (
    <ErrorBoundary page="Related Detections" styleClass="main">
      <Box data-testid="detections-wrapper">
        <Box m={4}>
          {alert_entity && alert_info && fetchOriginalRelatedCount ? (
            <Alert variant="subtle">
              {i18n.formatString(description, {
                dateStart: (
                  <Text as="span" fontWeight="600">
                    {moment
                      .unix(Number(earliest_start_timestamp))
                      .format('MMMM Do, h:mma')}
                  </Text>
                ),
                dateEnd: (
                  <Text as="span" fontWeight="600">
                    {moment
                      .unix(Number(latest_start_timestamp))
                      .format('MMMM Do, h:mma')}
                  </Text>
                ),
                detectionCount: (
                  <Text as="span" fontWeight="600">
                    {fetchOriginalRelatedCount}
                  </Text>
                ),
                detectionEntity: (
                  <Text as="span" fontWeight="600">
                    {alert_entity?.entity_name}
                  </Text>
                ),
                detectionAlertName: (
                  <Text as="span" fontWeight="600">
                    {alert_info?.alert_name}
                  </Text>
                ),
              })}
            </Alert>
          ) : null}
        </Box>
        <Box data-testid="detections-filters">
          <RelatedFilters
            size={pageSize}
            offset={offset}
            formatSort={formatSort}
            getPageCount={getPageCount}
            getSortsForAPI={getSortsForAPI}
            setQueryVariables={setQueryVariables}
            setDetectionsData={setDetectionsData}
            setDetectionsError={setDetectionsError}
            setDetectionsLoading={setDetectionsLoading}
          />
        </Box>
        <Box>
          <HStack
            m={4}
            divider={activeView === 'list' ? <Box height="24px" /> : <></>}
          >
            <ButtonGroup isAttached>
              <Button
                size="sm"
                variant="solid"
                onClick={() => setActiveView('list')}
                leftIcon={<MuiIcon>view_list</MuiIcon>}
                colorScheme={activeView === 'list' ? 'blue' : 'gray'}
              >
                {controls.list}
              </Button>
              <Button
                size="sm"
                variant="solid"
                leftIcon={<MuiIcon>table</MuiIcon>}
                onClick={() => setActiveView('table')}
                colorScheme={activeView === 'table' ? 'blue' : 'gray'}
              >
                {controls.table}
              </Button>
            </ButtonGroup>
            <DetectionsSort
              params={params}
              isRelated={true}
              navigate={navigate}
              activeView={activeView}
              sortByParam={sortByParam}
            />
          </HStack>
          {activeView === 'list' && (
            <DetectionsListView
              offset={offset}
              params={params}
              isRelated={true}
              navigate={navigate}
              pageSize={pageSize}
              pageIndex={pageIndex}
              pageCount={pageCount}
              formatSort={formatSort}
              selectedItem={selectedItem}
              detectionData={detectionData}
              setIsExcluded={setIsExcluded}
              detectionsData={detectionsData}
              queryVariables={queryVariables}
              getSortsForAPI={getSortsForAPI}
              handleFetchData={handleFetchData}
              setSelectedItem={setSelectedItem}
              setDetectionData={setDetectionData}
              detectionsLoading={detectionsLoading}
            />
          )}
          {activeView === 'table' && (
            <DetectionsTableView
              onOpen={onOpen}
              offset={offset}
              params={params}
              isRelated={true}
              navigate={navigate}
              sort_by={sort_by}
              pageSize={pageSize}
              pageIndex={pageIndex}
              pageCount={pageCount}
              formatSort={formatSort}
              setIsExcluded={setIsExcluded}
              detectionsData={detectionsData}
              queryVariables={queryVariables}
              getSortsForAPI={getSortsForAPI}
              handleFetchData={handleFetchData}
              setSelectedItem={setSelectedItem}
              detectionsLoading={detectionsLoading}
            />
          )}
        </Box>
      </Box>
      {selectedItem && (
        <Drawer
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={() => {
            onClose();
          }}
          data-testid="detections-table-row-drawer"
          title={`${selectedItem?.alert_info?.alert_name} | ${selectedItem?.alert_entity?.entity_name}`}
          body={
            <VStack w="100%">
              {queryVariables && !detectionsLoading && (
                <Box w="100%" my={2}>
                  <DetectionDrawerActions
                    detection={detectionData}
                    setIsExcluded={setIsExcluded}
                    queryDetectionsPaginatedVariables={{
                      query: queryVariables.query,
                      size: queryVariables.size,
                      offset: queryVariables.offset,
                      sort: getSortsForAPI(formatSort(queryVariables.sortBy)),
                    }}
                  />
                </Box>
              )}
              <DetectionDrawer
                isRelated={true}
                item={selectedItem}
                setDetectionData={setDetectionData}
                queryDetectionsPaginatedVariables={{
                  query: queryVariables.query,
                  size: queryVariables.size,
                  offset: queryVariables.offset,
                  sort: getSortsForAPI(formatSort(queryVariables.sortBy)),
                }}
              />
            </VStack>
          }
          footer={<></>}
        ></Drawer>
      )}
    </ErrorBoundary>
  );
};
