import {
  HumioLoaderOverlay,
  HumioUnavailable,
} from '@gamma/investigator/components';
import { LogScaleContext } from '@gamma/investigator/context';
import { useReloadHumioUI } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { Board, Column, Panel } from '@gamma/layout';
import { ErrorBoundary } from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';
import { useContext } from 'react';
import { isSafari } from 'react-device-detect';

export const HumioDashboards = () => {
  const { dashboards } = i18n.pages.humio;

  const {
    iframeKey,
    logScaleDashboardsLoading,
    logScaleDashboardsError,
    logScaleDashboardsData,
    logScaleDashboardParams,
  } = useContext(LogScaleContext);

  const { name, dashboardId, investigatorApp, corelightDashboard } =
    logScaleDashboardParams || {};

  const dashboardDetails = {
    name,
    dashboardId,
    investigatorApp,
    corelightDashboard,
  };

  const { iframeRef, iframeSrc } = useReloadHumioUI({
    iframeType: 'dashboards',
    dashboardDetails,
  });

  localStorage.removeItem('dashboard_link');

  if (logScaleDashboardsLoading) {
    return (
      <ErrorBoundary page="Dashboards" styleClass="main">
        <Panel>
          <GraphQLReqStatus
            isBackground={true}
            loading={logScaleDashboardsLoading}
          />
        </Panel>
      </ErrorBoundary>
    );
  } else if (
    logScaleDashboardsError ||
    !logScaleDashboardsData?.getHumioDashboards?.searchDomain?.dashboards ||
    logScaleDashboardsData?.getHumioDashboards?.searchDomain?.dashboards
      ?.length === 0
  ) {
    return (
      <HumioUnavailable
        unavailable={dashboards.unavailable}
        techSupport={dashboards.techSupport}
      />
    );
  }

  return (
    <ErrorBoundary page="Dashboards" styleClass="main">
      <Board
        data-testid="humio-dashboards"
        height="inherit"
        margin={0}
        width="auto"
      >
        <Column
          mt={1}
          pb={0}
          flexGrow={1}
          display="flex"
          flexBasis="auto"
          overflow="hidden"
          flexDirection="column"
        >
          {isSafari && <HumioLoaderOverlay reloadKey={iframeKey} />}
          {iframeSrc && (
            <iframe
              title="ui"
              width="100%"
              id="humio-ui"
              data-testid="humio-ui"
              height="calc(100% + 64px)"
              style={{
                flexGrow: 1,
                display: 'flex',
                paddingBottom: 0,
                marginTop: '-52px',
              }}
              key={iframeKey}
              ref={iframeRef}
              src={iframeSrc}
            />
          )}
        </Column>
      </Board>
    </ErrorBoundary>
  );
};
