import { Box, Button } from '@chakra-ui/react';
import { AuthModal, IOnMFACompleted } from '@gamma/investigator/components';
import { MODAL_STEPS } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { useContext } from 'react';

import { SensorsMonitoringDashboard } from './Components/SensorsMonitoringDashboard/SensorsMonitoringDashboard';

export const SensorMonitoring = () => {
  const { user, userRole, isOrgTenant } = useContext(AuthContext);
  const { cta } = i18n.pages.system;
  const { title, subTitle, helperTexts } = i18n.pages.sensorMonitoring.modal;
  const { incorrectPassword } = i18n.forms.validation;

  const onMFACompleted = ({ setStep }: IOnMFACompleted) => {
    setStep(MODAL_STEPS.details);
  };

  const isExportNoMfa =
    user?.preferredMFA === 'NOMFA' && user?.attributes['custom:allow_no_mfa'];

  return (
    <>
      {userRole === 'admin' && (
        <Box>
          <AuthModal
            helperTexts={helperTexts}
            title={title}
            subtitle={subTitle}
            onMFACompleted={onMFACompleted}
            errorMessage={incorrectPassword}
            isExportNoMfa={isExportNoMfa}
            isSensorPage={true}
          >
            {!isOrgTenant && (
              <Button
                mb={6}
                variant="solid"
                data-testid="configure-sensor-button"
              >
                {cta}
              </Button>
            )}
          </AuthModal>
        </Box>
      )}
      <SensorsMonitoringDashboard />
    </>
  );
};
