import { createIcon } from '@chakra-ui/react';

export const DoubleChevronUpIcon = createIcon({
  displayName: 'DoubleChevronUpIcon',
  viewBox: '0 0 24 24',
  d: 'M7.41 18.41 6 17l6-6 6 6-1.41 1.41L12 13.83l-4.59 4.58Zm0-6L6 11l6-6 6 6-1.41 1.41L12 7.83l-4.59 4.58Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
