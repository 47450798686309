import { ComponentStyleConfig, cssVar } from '@chakra-ui/react';

const $size = cssVar('close-button-size');

export const closeButtonStyles: ComponentStyleConfig = {
  baseStyle: {
    w: [$size.reference],
    h: [$size.reference],
  },
  sizes: {
    xs: {
      [$size.variable]: 'sizes.5',
      fontSize: 'body-xs',
    },
    sm: {
      [$size.variable]: 'sizes.6',
      fontSize: 'body-sm',
    },
    md: {
      [$size.variable]: 'sizes.6',
      fontSize: 'body-md',
    },
    lg: {
      fontSize: 'body-lg',
    },
    xl: {
      fontSize: 'body-lg',
    },
  },
};
