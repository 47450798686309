import { Button, Center, Flex, Text } from '@chakra-ui/react';
import {
  EntityVsPopChart,
  TopFeatureContributions,
} from '@gamma/investigator/components';
import { useEntityMLContributions } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { Modal } from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';
import { SanitizedMlAlert } from '../../DetectionAlerts';

interface MLAnalysisModalProps {
  item: SanitizedMlAlert;
}

export const MLAnalysisModal = ({ item }: MLAnalysisModalProps) => {
  const { buttons } = i18n.pages.entityDetails;
  const { alerts } = i18n.pages.detections;

  const { isLoading, error, contributions } = useEntityMLContributions({
    pipeline: item?.pipeline,
    models: item?.models,
  });

  if (isLoading || error) {
    return (
      <Center w="100%">
        <GraphQLReqStatus error={error} loading={isLoading} />
      </Center>
    );
  }

  return (
    <span data-testid="ml-alerts-wrapper">
      <Flex flexGrow={1}>
        <Modal
          title={alerts.ml.mlAnalysis}
          size="xl"
          body={
            <Flex data-testid="ml-analysis-content">
              <TopFeatureContributions features={contributions} />
              <EntityVsPopChart alertData={item} features={contributions} />
            </Flex>
          }
        >
          <Button
            variant="solid"
            colorScheme="gray"
            aria-label={buttons.mlAnalysis}
            marginLeft={2}
            data-testid="ml-analysis-button"
          >
            <Text fontSize="small" px={1}>
              {buttons.mlAnalysis}
            </Text>
          </Button>
        </Modal>
      </Flex>
    </span>
  );
};
