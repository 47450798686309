import './box-plot-legend.local.scss';

import { Flex, Text } from '@chakra-ui/react';
import { i18n } from '@gamma/investigator/localization';
import PropTypes from 'prop-types';
import React from 'react';

export class BoxPlotChartLegend extends React.PureComponent {
  render() {
    const { className } = this?.props;

    return (
      <Flex alignItems="center" my={4}>
        {!this?.props?.hideActual && (
          <Flex alignItems="center" ml={3}>
            <Text textStyle="body-md" mr={2}>
              {i18n.charts.d3.histogram.actual}
            </Text>
            <svg height="20px" width="10px">
              <g transform="translate(5, 12)">
                <circle
                  className={`${className}__actual-circle`}
                  r={4}
                  cx={0}
                  cy={-7}
                />
                <line
                  className={`${className}__actual-line`}
                  y1={-3}
                  y2={11}
                  x1={0}
                  x2={0}
                />
              </g>
            </svg>
          </Flex>
        )}
        <Flex alignItems="center" ml={3}>
          <Text textStyle="body-md" mr={2}>
            {i18n.charts.d3.histogram.mean}
          </Text>
          <svg height="20px" width="5px">
            <line
              className={`${className}__mean`}
              y1={0}
              y2={20}
              x1={3}
              x2={3}
            />
          </svg>
        </Flex>
        <Flex
          ml={3}
          alignItems="center"
          className={`${className}__item ${className}__std-dev`}
        >
          <Text textStyle="body-md" mr={2}>
            Std. dev
          </Text>
          <svg height="14px" width="17px">
            <line x1="2" x2="2" y1="2" y2="12" />
            <line x1="2" x2="15" y1="7" y2="7" />
            <line x1="15" x2="15" y1="2" y2="12" />
          </svg>
        </Flex>
        <Flex alignItems="center" ml={3}>
          <Text textStyle="body-md" mr={2}>
            {i18n.charts.d3.histogram['25to75']}
          </Text>
          <svg height="20px" width="25px">
            <rect
              className={`${className}__quarter-bar`}
              height={8}
              rx={4}
              y={6}
              width={20}
              x={3}
              fill={this?.props?.quarterColor}
            />
          </svg>
        </Flex>
        {!this?.props?.hideDistanceFromMean && (
          <Flex alignItems="center" ml={3}>
            <Text textStyle="body-md" mr={2}>
              {i18n.charts.d3.histogram.distanceFromMean}
            </Text>
            <Text textStyle="body-md">x.x</Text>
          </Flex>
        )}
      </Flex>
    );
  }
}

BoxPlotChartLegend.propTypes = {
  hideActual: PropTypes.bool,
  hideDistanceFromMean: PropTypes.bool,
  className: PropTypes.string,
  quarterColor: PropTypes.string,
};

BoxPlotChartLegend.defaultProps = {
  hideActual: false,
  hideDistanceFromMean: false,
  className: 'd3-box-plot-legend',
  quarterColor: '#ff8364',
};
