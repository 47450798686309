import { ILoginForm } from '@gamma/forms';
import { CognitoUserInterface } from '@gamma/shared/types';
import { Auth } from 'aws-amplify';

export const signIn = async ({ username, password }: ILoginForm) => {
  try {
    const user: CognitoUserInterface = await Auth.signIn(username, password);
    return user;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
