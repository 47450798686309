import {
  Box,
  HStack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SimpleGrid,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { darkReadOnlyInputBg, lightReadOnlyInputBg } from '@gamma/theme';
import { FocusEventHandler, ReactChild, ReactNode, forwardRef } from 'react';

import { MuiIcon } from '@gamma/display';
import { Label, LabelProps } from '../Label';

export interface DateTimeInputWrapperProps extends Omit<LabelProps, 'htmlFor'> {
  onFocus?: FocusEventHandler<HTMLDivElement>;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  bg?: string;
  hideErrorMessage?: boolean;
  hideBorder?: boolean;
  /** The element to place inside the input field to the left */
  leftElement?: ReactNode;
  /** The element to place inside the input field to the right */
  rightElement?: ReactNode;
  /** The element to place outside the input to the right */
  afterInput?: ReactChild;
  ['data-testid']?: string;
  inputHeight?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  py?: number | string;
}

export const DateTimeInputWrapper = forwardRef<
  HTMLDivElement,
  DateTimeInputWrapperProps
>(
  (
    {
      name,
      label,
      hideErrorMessage = false,
      hideBorder = false,
      leftElement,
      rightElement,
      afterInput,
      bg,
      error = false,
      isReadOnly,
      onFocus,
      onBlur,
      'data-testid': dataTestId = 'date-time-wrapper',
      size,
      inputHeight = '32px', // md input height
      py = 1.5, // md input padding
      children,
      ...props
    },
    ref,
  ) => {
    const readOnlyBg = useColorModeValue(
      lightReadOnlyInputBg,
      darkReadOnlyInputBg,
    );
    return (
      <Label
        name={name}
        label={label}
        error={hideErrorMessage ? Boolean(error) : error}
        isReadOnly={isReadOnly}
        h="full"
        {...props}
      >
        <SimpleGrid
          columns={2}
          templateColumns="min-content 1fr"
          w="fit-content"
          alignItems="center"
          onFocus={onFocus}
          onBlur={onBlur}
          ref={ref}
        >
          <Tooltip
            isDisabled={!hideErrorMessage || !error}
            label={
              <HStack>
                <MuiIcon>error</MuiIcon>
                <Text>{error}</Text>
              </HStack>
            }
            data-testid={`${dataTestId}-error-tooltip`}
            color="state.error"
          >
            <InputGroup
              borderRadius="base"
              border={hideBorder ? 'none' : 'solid 1px'}
              borderColor={error ? 'state.error' : 'border.1'}
              boxShadow={
                error ? '0 0 0 1px var(--chakra-colors-state-error)' : undefined
              }
              bg={isReadOnly ? readOnlyBg : bg ?? 'layer.1'}
              cursor={isReadOnly ? 'not-allowed' : undefined}
              w="fit-content"
              data-testid={dataTestId}
              alignItems="center"
              pl={leftElement ? 2 : 0}
              pr={rightElement ? 2 : 0}
              height={inputHeight ?? 'full'}
              size={size}
              py={py}
            >
              {leftElement && (
                <InputLeftElement
                  data-testid={`${dataTestId}-left-element`}
                  px={2}
                  position="relative"
                  w="auto"
                >
                  {leftElement}
                </InputLeftElement>
              )}
              {children}
              {rightElement && (
                <InputRightElement
                  px={2}
                  data-testid={`${dataTestId}-right-element`}
                  position="relative"
                  w="auto"
                >
                  {rightElement}
                </InputRightElement>
              )}
            </InputGroup>
          </Tooltip>
          {afterInput && (
            <Box pl={4} data-testid={`${dataTestId}-after-input`}>
              {afterInput}
            </Box>
          )}
        </SimpleGrid>
      </Label>
    );
  },
);
