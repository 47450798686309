import { createIcon } from '@chakra-ui/react';

export const LeftRefreshIcon = createIcon({
  displayName: 'LeftRefreshIcon',
  viewBox: '0 0 24 24',
  d: 'M17.657 6.343A7.958 7.958 0 0 0 12 4.01V.686l-4.95 4.95 4.95 4.95V6.032c1.534-.007 3.069.552 4.243 1.725a6 6 0 1 1-8.486 8.486A5.969 5.969 0 0 1 6.004 12H4.008a7.935 7.935 0 0 0 2.335 5.657A8 8 0 1 0 17.657 6.343Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
