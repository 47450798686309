import { createIcon } from '@chakra-ui/react';

export const AdvancedEvalLicenseStatus = createIcon({
  displayName: 'AdvancedEvalLicenseStatus',
  viewBox: '0 0 96 96',
  path: (
    <>
      <mask id="path-1-inside-1_531_9304" fill="white">
        <path d="M76.4546 80.3463C76.4287 80.3463 76.4125 80.3456 76.4068 80.3453L30.9043 80.3456C30.4662 80.3461 30.0347 80.2393 29.6475 80.0344C29.2603 79.8295 28.9292 79.5328 28.6831 79.1703C28.4371 78.8079 28.2837 78.3906 28.2363 77.9551C28.1889 77.5196 28.2489 77.0791 28.4112 76.6721C30.0622 72.5473 32.2851 62.6511 28.6237 42.7165C24.3002 19.1774 28.1709 10.6703 30.0357 8.10537C30.2852 7.76339 30.6119 7.48507 30.9892 7.29301C31.3664 7.10095 31.7837 7.00056 32.207 7H78.4217C78.8375 6.9996 79.2476 7.09588 79.6198 7.28124C79.9919 7.4666 80.3158 7.73595 80.5659 8.06802C80.8161 8.40009 80.9856 8.78578 81.061 9.19462C81.1365 9.60345 81.1158 10.0242 81.0007 10.4237C79.0727 17.0898 75.1023 34.7529 79.8624 54.7453C83.4711 69.902 81.7363 76.0844 79.6455 78.6018C78.2665 80.2622 76.747 80.3463 76.4546 80.3463Z" />
      </mask>
      <path
        d="M76.4546 80.3463C76.4287 80.3463 76.4125 80.3456 76.4068 80.3453L30.9043 80.3456C30.4662 80.3461 30.0347 80.2393 29.6475 80.0344C29.2603 79.8295 28.9292 79.5328 28.6831 79.1703C28.4371 78.8079 28.2837 78.3906 28.2363 77.9551C28.1889 77.5196 28.2489 77.0791 28.4112 76.6721C30.0622 72.5473 32.2851 62.6511 28.6237 42.7165C24.3002 19.1774 28.1709 10.6703 30.0357 8.10537C30.2852 7.76339 30.6119 7.48507 30.9892 7.29301C31.3664 7.10095 31.7837 7.00056 32.207 7H78.4217C78.8375 6.9996 79.2476 7.09588 79.6198 7.28124C79.9919 7.4666 80.3158 7.73595 80.5659 8.06802C80.8161 8.40009 80.9856 8.78578 81.061 9.19462C81.1365 9.60345 81.1158 10.0242 81.0007 10.4237C79.0727 17.0898 75.1023 34.7529 79.8624 54.7453C83.4711 69.902 81.7363 76.0844 79.6455 78.6018C78.2665 80.2622 76.747 80.3463 76.4546 80.3463Z"
        fill="#262626"
        stroke="#525252"
        stroke-width="4"
        mask="url(#path-1-inside-1_531_9304)"
      />
      <mask id="path-2-inside-2_531_9304" fill="white">
        <path d="M64.8008 89.0003C64.7749 89.0003 64.7586 88.9997 64.753 88.9993L19.2505 88.9997C18.8124 89.0002 18.3809 88.8933 17.9937 88.6884C17.6065 88.4836 17.2753 88.1869 17.0293 87.8244C16.7833 87.4619 16.6299 87.0447 16.5825 86.6091C16.5351 86.1736 16.5951 85.7331 16.7574 85.3262C18.4084 81.2014 20.6313 71.3052 16.9699 51.3706C12.6464 27.8315 16.5171 19.3244 18.3819 16.7594C18.6314 16.4174 18.9581 16.1391 19.3354 15.9471C19.7126 15.755 20.1299 15.6546 20.5532 15.6541H66.7679C67.1837 15.6537 67.5938 15.7499 67.9659 15.9353C68.3381 16.1207 68.662 16.39 68.9121 16.7221C69.1623 17.0541 69.3318 17.4398 69.4072 17.8487C69.4827 18.2575 69.462 18.6783 69.3469 19.0778C67.4189 25.7438 63.4485 43.407 68.2086 63.3993C71.8173 78.5561 70.0825 84.7385 67.9917 87.2559C66.6126 88.9162 65.0932 89.0003 64.8008 89.0003Z" />
      </mask>
      <path
        d="M64.8008 89.0003C64.7749 89.0003 64.7586 88.9997 64.753 88.9993L19.2505 88.9997C18.8124 89.0002 18.3809 88.8933 17.9937 88.6884C17.6065 88.4836 17.2753 88.1869 17.0293 87.8244C16.7833 87.4619 16.6299 87.0447 16.5825 86.6091C16.5351 86.1736 16.5951 85.7331 16.7574 85.3262C18.4084 81.2014 20.6313 71.3052 16.9699 51.3706C12.6464 27.8315 16.5171 19.3244 18.3819 16.7594C18.6314 16.4174 18.9581 16.1391 19.3354 15.9471C19.7126 15.755 20.1299 15.6546 20.5532 15.6541H66.7679C67.1837 15.6537 67.5938 15.7499 67.9659 15.9353C68.3381 16.1207 68.662 16.39 68.9121 16.7221C69.1623 17.0541 69.3318 17.4398 69.4072 17.8487C69.4827 18.2575 69.462 18.6783 69.3469 19.0778C67.4189 25.7438 63.4485 43.407 68.2086 63.3993C71.8173 78.5561 70.0825 84.7385 67.9917 87.2559C66.6126 88.9162 65.0932 89.0003 64.8008 89.0003Z"
        fill="#262626"
        stroke="#525252"
        stroke-width="4"
        mask="url(#path-2-inside-2_531_9304)"
      />
      <path
        d="M58.9073 36.1866H22.6337C22.1496 36.1866 21.6854 35.9943 21.3431 35.652C21.0009 35.3097 20.8086 34.8455 20.8086 34.3615C20.8086 33.8774 21.0009 33.4132 21.3431 33.0709C21.6854 32.7287 22.1496 32.5364 22.6337 32.5364H58.9073C59.3913 32.5364 59.8556 32.7287 60.1978 33.0709C60.5401 33.4132 60.7324 33.8774 60.7324 34.3615C60.7324 34.8455 60.5401 35.3097 60.1978 35.652C59.8556 35.9943 59.3913 36.1866 58.9073 36.1866Z"
        fill="#43BF79"
      />
      <path
        d="M58.9073 43.9429H22.6337C22.1496 43.9429 21.6854 43.7506 21.3431 43.4083C21.0009 43.0661 20.8086 42.6019 20.8086 42.1178C20.8086 41.6338 21.0009 41.1695 21.3431 40.8273C21.6854 40.485 22.1496 40.2927 22.6337 40.2927H58.9073C59.3913 40.2927 59.8556 40.485 60.1978 40.8273C60.5401 41.1695 60.7324 41.6338 60.7324 42.1178C60.7324 42.6019 60.5401 43.0661 60.1978 43.4083C59.8556 43.7506 59.3913 43.9429 58.9073 43.9429Z"
        fill="#8D8D8D"
      />
      <path
        d="M58.9073 51.6992H22.6337C22.1496 51.6992 21.6854 51.507 21.3431 51.1647C21.0009 50.8224 20.8086 50.3582 20.8086 49.8742C20.8086 49.3901 21.0009 48.9259 21.3431 48.5836C21.6854 48.2414 22.1496 48.0491 22.6337 48.0491H58.9073C59.3913 48.0491 59.8556 48.2414 60.1978 48.5836C60.5401 48.9259 60.7324 49.3901 60.7324 49.8742C60.7324 50.3582 60.5401 50.8224 60.1978 51.1647C59.8556 51.507 59.3913 51.6992 58.9073 51.6992Z"
        fill="#8D8D8D"
      />
      <path
        d="M61.6449 59.4566H25.3712C24.8872 59.4566 24.423 59.2643 24.0807 58.922C23.7384 58.5797 23.5461 58.1155 23.5461 57.6315C23.5461 57.1474 23.7384 56.6832 24.0807 56.341C24.423 55.9987 24.8872 55.8064 25.3712 55.8064H61.6449C62.1289 55.8064 62.5931 55.9987 62.9354 56.341C63.2777 56.6832 63.4699 57.1474 63.4699 57.6315C63.4699 58.1155 63.2777 58.5797 62.9354 58.922C62.5931 59.2643 62.1289 59.4566 61.6449 59.4566Z"
        fill="#8D8D8D"
      />
      <path
        d="M61.6449 67.2129H25.3712C24.8872 67.2129 24.423 67.0206 24.0807 66.6784C23.7384 66.3361 23.5461 65.8719 23.5461 65.3878C23.5461 64.9038 23.7384 64.4396 24.0807 64.0973C24.423 63.755 24.8872 63.5627 25.3712 63.5627H61.6449C62.1289 63.5627 62.5931 63.755 62.9354 64.0973C63.2777 64.4396 63.4699 64.9038 63.4699 65.3878C63.4699 65.8719 63.2777 66.3361 62.9354 66.6784C62.5931 67.0206 62.1289 67.2129 61.6449 67.2129Z"
        fill="#8D8D8D"
      />
      <path
        d="M39.7439 28.4292H22.6337C22.1496 28.4292 21.6854 28.2369 21.3431 27.8947C21.0009 27.5524 20.8086 27.0882 20.8086 26.6041C20.8086 26.1201 21.0009 25.6559 21.3431 25.3136C21.6854 24.9713 22.1496 24.7791 22.6337 24.7791H39.7439C40.2279 24.7791 40.6921 24.9713 41.0344 25.3136C41.3767 25.6559 41.569 26.1201 41.569 26.6041C41.569 27.0882 41.3767 27.5524 41.0344 27.8947C40.6921 28.2369 40.2279 28.4292 39.7439 28.4292Z"
        fill="#43BF79"
      />
      <path
        d="M37.5347 79.72C37.903 78.1607 39.6331 77.6496 41.0476 77.7067C41.9413 77.767 42.8278 77.9073 43.6965 78.1259C44.6054 78.3292 45.5055 78.57 46.401 78.8254C48.0433 79.2937 49.6685 79.8601 51.3378 80.2263C52.7275 80.5311 54.2377 80.7099 55.6236 80.2891C56.9557 79.8845 58.0859 78.8914 58.4484 77.5184C58.7899 76.2245 58.321 74.8098 57.0672 74.2185C55.9239 73.6793 54.4796 73.9535 53.9129 75.173C53.2739 76.5479 53.9124 78.0473 54.9241 79.0422C55.4991 79.5822 56.129 80.0606 56.8034 80.4697C57.488 80.9362 58.2499 81.2774 59.0537 81.4776C60.3735 81.7594 61.9456 81.3441 62.6262 80.0832C62.7775 79.7978 62.8685 79.4843 62.8936 79.1622C62.9338 78.7238 62.2492 78.7265 62.2091 79.1622C62.0938 80.4177 60.6234 81.0057 59.5239 80.868C58.7817 80.745 58.0729 80.47 57.4421 80.06C56.8333 79.7051 56.2563 79.298 55.7178 78.8434C54.7852 78.0364 53.911 76.7083 54.5374 75.4489C55.0474 74.4234 56.3769 74.4336 57.1855 75.095C58.1648 75.8959 57.9902 77.3496 57.3443 78.3007C56.6291 79.3538 55.3328 79.7953 54.1107 79.8429C52.7827 79.8946 51.4658 79.5899 50.1966 79.2295C48.6196 78.7817 47.0583 78.2805 45.4737 77.8589C44.5741 77.6195 43.6676 77.4035 42.7518 77.2354C41.8858 77.0446 40.9963 76.983 40.1123 77.0527C38.6842 77.2176 37.2264 78.0489 36.8748 79.5381C36.7736 79.9664 37.4334 80.1492 37.5347 79.72L37.5347 79.72Z"
        fill="#43BF79"
      />
      <path
        d="M62.6812 79.8817C62.5947 79.8096 62.5314 79.7136 62.4994 79.6056C62.4674 79.4977 62.468 79.3826 62.5012 79.2751C62.5345 79.1675 62.5988 79.0721 62.6861 79.0011C62.7735 78.93 62.8799 78.8864 62.9919 78.8757C64.2363 78.7555 65.492 78.8842 66.6861 79.2543L78.8492 83.0107C79.0067 83.0593 79.153 83.1385 79.2798 83.2436C79.4067 83.3488 79.5116 83.4779 79.5885 83.6236C79.6655 83.7694 79.713 83.9288 79.7283 84.0929C79.7436 84.2569 79.7265 84.4224 79.6779 84.5798C79.6292 84.7373 79.5501 84.8836 79.4449 85.0104C79.3397 85.1373 79.2106 85.2422 79.0649 85.3191C78.9192 85.3961 78.7598 85.4436 78.5957 85.4589C78.4317 85.4742 78.2662 85.4571 78.1087 85.4084L65.9456 81.652C64.7508 81.2844 63.6411 80.6825 62.6812 79.8817Z"
        fill="#F4F4F4"
      />
    </>
  ),
  defaultProps: {
    height: '96px',
    width: '96px',
  },
});
