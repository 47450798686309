import { NumberInput, NumberInputField } from '@chakra-ui/react';
import { KeyboardEventHandler, forwardRef } from 'react';

export type DateNumberInputType =
  | 'month'
  | 'day'
  | 'year'
  | 'hour'
  | 'minute'
  | 'second';

export interface DateNumberInputProps {
  value: string;
  type: DateNumberInputType;
  onChange: (string: string, number: number) => void;
  onKeyDown: KeyboardEventHandler<HTMLInputElement>;
  ['data-testid']?: string;
}

interface TypeProps {
  label: string;
  max: number;
  w: string;
  maxWidth: string;
  placeholder: string;
}

export const typePropMap = new Map<DateNumberInputType, TypeProps>([
  [
    'month',
    { label: 'Month', max: 12, w: '3ch', maxWidth: '2rem', placeholder: 'MM' },
  ],
  [
    'day',
    { label: 'Day', max: 31, w: '3ch', maxWidth: '2rem', placeholder: 'DD' },
  ],
  [
    'year',
    {
      label: 'Year',
      max: 9999,
      w: '5ch',
      maxWidth: '3rem',
      placeholder: 'YYYY',
    },
  ],
  [
    'hour',
    { label: 'Hour', max: 23, w: '3ch', maxWidth: '2rem', placeholder: 'HH' },
  ],
  [
    'minute',
    { label: 'Minute', max: 59, w: '3ch', maxWidth: '2rem', placeholder: 'MM' },
  ],
  [
    'second',
    {
      label: 'Second',
      max: 59,
      w: '3ch',
      maxWidth: '2rem',
      placeholder: 'SS',
    },
  ],
]);

export const DateNumberInput = forwardRef<
  HTMLInputElement,
  DateNumberInputProps
>(
  (
    {
      value,
      type,
      onChange,
      onKeyDown,
      'data-testid': dataTestId = 'date-number-input',
    },
    ref,
  ) => {
    const { label, max, w, maxWidth, placeholder } =
      typePropMap.get(type) ?? {};

    return (
      <NumberInput
        inputMode="numeric"
        variant="unstyled"
        aria-label={label}
        step={1}
        min={0}
        max={max}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        maxW={maxWidth}
        data-testid={`${dataTestId}-wrapper`}
      >
        <NumberInputField
          px={0}
          py={0}
          pr={0}
          pl={value ? '0.5ch' : undefined} // keep placeholder from being cut off without adding extra width
          bgColor="transparent"
          placeholder={placeholder}
          ref={ref}
          borderRadius="none"
          w={w}
          _focus={{
            outline: 'solid 1px',
            outlineColor: 'blue.300',
            outlineOffset: '1px',
          }}
          data-testid={dataTestId}
        />
      </NumberInput>
    );
  },
);
