import { chakra, Heading } from '@chakra-ui/react';
import { Panel, PanelHeader } from '@gamma/layout';
import { Component, ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryProps {
  page: string;
  styleClass?: string;
  children?: ReactNode;
}

interface ErrorBoundaryState {
  error: null | Error;
  errorInfo: null | ErrorInfo;
  page: null | string;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      error: null,
      errorInfo: null,
      page: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
      page: this.props.page,
    });
  }

  render() {
    if (this.state.errorInfo && this.props.page === this.state.page) {
      return (
        <Panel>
          <PanelHeader>
            <Heading textStyle="h3" fontWeight="normal">
              Something went wrong in the {this.props.page}.
            </Heading>
          </PanelHeader>
          <chakra.details whiteSpace="pre-wrap">
            <br />
            {this.state.error && this.state.error.toString()}
            <br />
            <br />
            Error is located at:
            {this.state.errorInfo.componentStack}
          </chakra.details>
        </Panel>
      );
    }

    return this.props.children;
  }
}
