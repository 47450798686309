import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { BlogOutput } from './types';

export type IGetCorelightBlogs = {
  getCorelightBlogs: BlogOutput[];
};

export const GET_CORELIGHT_BLOGS = gql`
  query getCorelightBlogs($limit: Int) {
    getCorelightBlogs(limit: $limit) {
      category
      dc_creator
      dc_date
      description
      link
      pub_date
      title
      __typename
    }
  }
`;

export const useGetCorelightBlogs = (
  options: QueryHookOptions<IGetCorelightBlogs, OperationVariables>,
) => {
  return useQuery<IGetCorelightBlogs>(GET_CORELIGHT_BLOGS, {
    ...options,
  });
};
