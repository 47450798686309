import { gql } from '@apollo/client';

export const QUERY_DETECTIONS_FIELD_SUGGESTIONS = gql`
  query queryDetectionsFieldSuggestions(
    $query: String
    $collapse_field: String!
    $size: Int
    $sort: [SortParameterInput]
  ) {
    queryDetectionsFieldSuggestions(
      query: $query
      collapse_field: $collapse_field
      size: $size
      sort: $sort
    )
  }
`;
