import { ComponentStyleConfig } from '@chakra-ui/react';

import { textStyles } from '../../common';

export const badgeStyles: ComponentStyleConfig = {
  baseStyle: (props) => ({
    ...textStyles['body-sm'],
    textTransform: 'uppercase',
    fontWeight: 'semibold',
    borderRadius: 'base',
    px: 1,
  }),
  variants: {
    solid: (props) => ({
      color: `${props.colorScheme}.50`,
      bgColor: `${props.colorScheme}.600`,
    }),
    subtle: (props) => ({
      color: `${props.colorScheme}.50`,
      bgColor: `${props.colorScheme}.800`,
    }),
    outline: (props) => ({
      bgColor: 'transparent',
      border: 'solid 1px',
      borderColor: `${props.colorScheme}.400`,
    }),
  },
};
