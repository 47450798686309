import { ComponentWithAs, IconProps, Text, VStack } from '@chakra-ui/react';
import { Input } from '@gamma/form-fields';
import { ILoginForm } from '@gamma/forms';
import { MFA, MODAL_STEPS } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import { signIn } from '@gamma/investigator/queries';
import { Alert } from '@gamma/overlay';
import { CognitoUserInterface } from '@gamma/shared/types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { IHelperText, SetStepType } from '../types';

interface AuthLoginBodyProps {
  email: string;
  helperTexts: IHelperText;
  loginIcon?: ComponentWithAs<'svg', IconProps>;
  showAlert?: boolean;
  errorMessage: string;
  width?: string;
  setStep: SetStepType;
  setIsButtonLoading: (e: boolean) => void;
  setIsSignInButtonDisabled: (e: boolean) => void;
  setVerifyUser: (verifyUser: CognitoUserInterface) => void;
}

export const AuthLoginBody = ({
  email,
  helperTexts,
  loginIcon: LoginIcon,
  showAlert,
  errorMessage,
  width = '86%',
  setIsButtonLoading,
  setStep,
  setVerifyUser,
  setIsSignInButtonDisabled,
}: AuthLoginBodyProps) => {
  const { register, handleSubmit, watch, setFocus } = useForm<ILoginForm>();
  const [loginError, setLoginError] = useState<string>('');

  const passwordInput = watch('password');

  useEffect(() => {
    setIsSignInButtonDisabled(!passwordInput);
    setLoginError('');
  }, [passwordInput]);

  useEffect(() => {
    setFocus('password');
  }, []);

  const handleSignIn = async (values: ILoginForm) => {
    setIsButtonLoading(true);
    try {
      const user = await signIn(values);
      if (user) {
        setVerifyUser(user);
        if (
          user.preferredMFA === MFA.NOMFA &&
          user?.attributes['custom:allow_no_mfa']
        ) {
          setStep(MODAL_STEPS.details);
        } else {
          setStep(MODAL_STEPS.mfa);
        }
      }
    } catch (error) {
      setLoginError(errorMessage);
    }
    setIsButtonLoading(false);
  };
  return (
    <VStack spacing={4}>
      {LoginIcon && <LoginIcon />}
      <Text alignSelf="flex-start">{helperTexts.login.text}</Text>
      <VStack
        w={width}
        p={4}
        mb="24px"
        borderRadius="4px"
        as="form"
        id="verifyLoginForm"
        onSubmit={handleSubmit(handleSignIn)}
      >
        <Input
          {...register('username')}
          isReadOnly
          isLabelHidden
          label={i18n.forms.labels.username}
          value={email}
        />
        <Input
          {...register('password')}
          data-testid="auth-modal-password"
          type="password"
          isLabelHidden
          label={i18n.forms.labels.password}
          error={showAlert ? '' : loginError}
          placeholder={i18n.forms.labels.password}
        />
      </VStack>
      {showAlert && loginError && (
        <Alert
          data-testid="auth-modal-login-alert"
          status="error"
          title={errorMessage}
        />
      )}
    </VStack>
  );
};
