import { ComponentStyleConfig } from '@chakra-ui/react';
import { getColor, mode, transparentize } from '@chakra-ui/theme-tools';

export const TextareaStyles: ComponentStyleConfig = {
  baseStyle: (props: any) => {
    return {
      bg: mode('gray.50', 'gray.800')(props),
      backgroundColor: mode('gray.50', 'gray.800')(props),
      _readOnly: {
        bg: transparentize(
          mode('white', getColor(props.theme, 'gray.800'))(props),
          0.5,
        )(props),
        cursor: 'not-allowed',
      },
    };
  },
  defaultProps: {
    errorBorderColor: 'red.300',
  },
  variants: {
    outline: (props: any) => {
      return {
        borderColor: mode('gray.100', 'gray.600')(props),
      };
    },
  },
  sizes: {
    xs: {
      px: 2, // 8px
    },
    sm: {
      px: 2,
    },
    md: {
      px: 2,
    },
    lg: {
      px: 3, // 12px
    },
  },
};
