import { createIcon } from '@chakra-ui/react';

export const PauseIcon = createIcon({
  displayName: 'PauseIcon',
  viewBox: '0 0 24 24',
  d: 'M14 19h4V5h-4v14Zm-8 0h4V5H6v14Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
