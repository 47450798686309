import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

import { PaginatedAlertMetadata } from './types';

export const SET_ALERT_ACTIVE_STATUS = gql`
  mutation setAlertActiveStatus($items: [AlertMetadataInput]!) {
    setAlertActiveStatus(items: $items) {
      metadata {
        _score
        title
        severity
        active
        logsource {
          category
        }
        content_id
        tenant_alert
      }
      offset
      size
      total_items
    }
  }
`;

interface ISetAlertActiveStatus {
  setAlertActiveStatus: PaginatedAlertMetadata;
}

export const useSetAlertActiveStatus = (
  options: MutationHookOptions<ISetAlertActiveStatus, OperationVariables>,
) => {
  return useMutation(SET_ALERT_ACTIVE_STATUS, options);
};
