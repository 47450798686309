import { humioDashboard } from '@gamma/investigator/constants';
import axios from 'axios';

export const setHumioSessionId = (
  idToken: string,
  isPOVTenant?: boolean,
): Promise<string | void> => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: isPOVTenant
        ? humioDashboard.pov_session_token_url
        : humioDashboard.session_token_url,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    })
      .then((result) => {
        const resp = result?.data;
        localStorage.setItem('session_id_token', resp?.clientSessionId);
        resolve(resp?.clientSessionId);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
