import { ComponentStyleConfig } from '@chakra-ui/react';
import { getColor, transparentize } from '@chakra-ui/theme-tools';

export const ProgressStyles: ComponentStyleConfig = {
  baseStyle: (props: any) => {
    const bg = `${props.colorScheme}.500`;
    return {
      track: {
        borderRadius: 'base',
        bg: props.bg || transparentize(getColor(props.theme, bg), 0.2)(props),
      },
      filledTrack: {
        bg: bg,
      },
    };
  },
  sizes: {
    md: {
      track: {
        h: 1.5,
      },
    },
  },
};
