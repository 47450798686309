import { expireWarningDays } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import moment from 'moment';

export const expirationDatesLicenseCompare = (
  timestamp: number,
  licenseType?: string,
  expired?: boolean,
): {
  color: string;
  status: 'error' | 'warning' | 'success';
  outputLabel: string;
  daysLeft: number;
} => {
  const time = moment.unix(timestamp);
  const now = moment();
  const daysLeft = time.diff(now, 'days');
  const timeCheck = timestamp !== 0;
  const licenseIsEvaluation = licenseType === 'EVAL';
  const warnEvalExpiration =
    licenseIsEvaluation &&
    daysLeft <= expireWarningDays.EVAL_WARNING &&
    timeCheck;
  const warnExpiration =
    !licenseIsEvaluation &&
    daysLeft > expireWarningDays.FIRST_WARNING &&
    daysLeft <= expireWarningDays.SECOND_WARNING &&
    timeCheck;

  if (expired && timeCheck) {
    return {
      color: 'red',
      status: 'error',
      outputLabel: i18n.pages.licenseDashboard.licenseStatus.expired,
      daysLeft,
    };
  }

  if (warnEvalExpiration || warnExpiration) {
    return {
      color: 'yellow',
      status: 'warning',
      outputLabel: i18n.pages.licenseDashboard.licenseStatus.expiring,
      daysLeft,
    };
  }

  if (
    !licenseIsEvaluation &&
    daysLeft <= expireWarningDays.FIRST_WARNING &&
    timeCheck
  ) {
    return {
      color: 'red',
      status: 'error',
      outputLabel: i18n.pages.licenseDashboard.licenseStatus.expiring,
      daysLeft,
    };
  }

  return {
    color: 'green',
    status: 'success',
    outputLabel: i18n.pages.licenseDashboard.licenseStatus.active,
    daysLeft,
  };
};
