import { createIcon } from '@chakra-ui/react';

export const ArrowUpIcon = createIcon({
  displayName: 'ArrowUpIcon',
  viewBox: '0 0 24 24',
  d: 'M13 20h-2V8l-5.5 5.5-1.42-1.42L12 4.16l7.92 7.92-1.42 1.42L13 8v12Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
