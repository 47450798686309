import { createIcon } from '@chakra-ui/react';

export const BoxHomeIcon = createIcon({
  displayName: 'BoxHomeIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6Zm8.8 7v3h-3v-4H6l6-4 6 4h-1.8v4h-3v-3h-2.4Z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
