import { OperationVariables } from '@apollo/client';
import {
  Badge,
  Button,
  ButtonGroup,
  HStack,
  ListItem,
  Spacer,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { CONSTANTS } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { Panel, PanelHeader } from '@gamma/layout';
import { Alert } from '@gamma/overlay';
import { CognitoUserInterface } from '@gamma/shared/types';
import _ from 'lodash';
import { useContext } from 'react';

import { DisableSsoModal } from './DisableSsoModal';
import { IdpConfigurationModal } from './IdpConfigurationModal';
import { ViewConfigurationModal } from './ViewConfigurationModal';

interface SAMLSingleSignOnProps {
  data: OperationVariables | undefined;
}

export const onCheckConnection = ({
  user,
}: {
  user: CognitoUserInterface | undefined;
}) => {
  const userDomain = _.split(user?.username, '@')[1];
  const {
    SSO: { domain, client_id, redirect_uri },
  } = CONSTANTS;
  const idpLoginPage = `https://${domain}/oauth2/authorize?idp_identifier=${userDomain}&redirect_uri=${redirect_uri}&response_type=code&client_id=${client_id}&scope=aws.cognito.signin.user.admin+email+openid+profile`;
  window.open(
    idpLoginPage,
    'sharer',
    'popup,left=720,top=200,width=548,height=625',
  );
};

export const SAMLSingleSignOn = ({ data }: SAMLSingleSignOnProps) => {
  const describeData = data?.describeIdentityProvider;
  const isSSOEnabled = describeData?.is_sso_enabled;
  const { user } = useContext(AuthContext);
  const { saml, button, badge, disabledText, enabledAlert } = i18n.pages.access;

  return (
    <Panel layerStyle="second">
      <PanelHeader>
        <Text fontWeight="bold">{saml.title}</Text>
        {isSSOEnabled ? (
          <Badge
            variant="solid"
            colorScheme="green"
            data-testid="sso-enabled-badge"
          >
            {badge.enabled}
          </Badge>
        ) : (
          <Badge
            variant="solid"
            colorScheme="gray"
            data-testid="sso-disabled-badge"
          >
            {badge.disabled}
          </Badge>
        )}
      </PanelHeader>
      <VStack alignItems="left" mt={-4} spacing={4}>
        {isSSOEnabled ? (
          <>
            <Alert
              data-testid=""
              status="success"
              variant="subtle"
              title={
                <>
                  <Text>{enabledAlert.listHeading}</Text>
                  <UnorderedList>
                    <ListItem fontWeight="normal">
                      {enabledAlert.listItemAdmin}
                    </ListItem>
                    <ListItem fontWeight="normal">
                      {enabledAlert.listItemAnalystViewer}
                    </ListItem>
                  </UnorderedList>
                </>
              }
            />
            <HStack>
              <ButtonGroup spacing={4}>
                <IdpConfigurationModal data={data} isUpdating>
                  <Button
                    data-testid="sso-edit-button"
                    variant="solid"
                    width="fit-content"
                  >
                    {button.edit}
                  </Button>
                </IdpConfigurationModal>
                <ViewConfigurationModal describeData={describeData}>
                  <Button
                    data-testid="sso-view-button"
                    variant="solid"
                    colorScheme="gray"
                  >
                    {button.view}
                  </Button>
                </ViewConfigurationModal>
                <Button
                  data-testid="sso-check-connection-button"
                  variant="link"
                  colorScheme="blue"
                  onClick={() => onCheckConnection({ user })}
                >
                  {button.check}
                </Button>
              </ButtonGroup>
              <Spacer />
              <DisableSsoModal>
                <Button
                  data-testid="sso-disable-button"
                  colorScheme="red"
                  variant="link"
                >
                  {button.disableSso}
                </Button>
              </DisableSsoModal>
            </HStack>
          </>
        ) : (
          <>
            <Text>{disabledText}</Text>
            <IdpConfigurationModal data={data}>
              <Button variant="solid" width="fit-content">
                {button.enable}
              </Button>
            </IdpConfigurationModal>
          </>
        )}
      </VStack>
    </Panel>
  );
};
