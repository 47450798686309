import { Global } from '@emotion/react';

export interface GlobalFontsProps {
  fontPath?: string;
}

export const Fonts = ({ fontPath = '/static/fonts/' }: GlobalFontsProps) => (
  <Global
    styles={`
		@font-face {
			font-family: 'IBM Plex Sans';
			font-style: italic;
			font-weight: 100;
			src: url('${fontPath}IBMPlexSans/IBMPlexSans-ThinItalic.woff2') format('woff2')
					 url('${fontPath}IBMPlexSans/IBMPlexSans-ThinItalic.woff') format('woff');
		}
		
		@font-face {
			font-family: 'IBM Plex Sans';
			font-style: normal;
			font-weight: 700;
			src: url('${fontPath}IBMPlexSans/IBMPlexSans-Bold.woff') format('woff'),
					 url('${fontPath}IBMPlexSans/IBMPlexSans-Bold.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Sans';
			font-style: italic;
			font-weight: 700;
			src: url('${fontPath}IBMPlexSans/IBMPlexSans-BoldItalic.woff') format('woff'),
					 url('${fontPath}IBMPlexSans/IBMPlexSans-BoldItalic.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Sans';
			font-style: normal;
			font-weight: 200;
			src: url('${fontPath}IBMPlexSans/IBMPlexSans-ExtraLight.woff') format('woff'),
					 url('${fontPath}IBMPlexSans/IBMPlexSans-ExtraLight.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Sans';
			font-style: italic;
			font-weight: 200;
			src: url('${fontPath}IBMPlexSans/IBMPlexSans-ExtraLightItalic.woff') format('woff'),
					 url('${fontPath}IBMPlexSans/IBMPlexSans-ExtraLightItalic.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Sans';
			font-style: italic;
			font-weight: 400;
			src: url('${fontPath}IBMPlexSans/IBMPlexSans-Italic.woff') format('woff'),
					 url('${fontPath}IBMPlexSans/IBMPlexSans-Italic.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Sans';
			font-style: normal;
			font-weight: 300;
			src: url('${fontPath}IBMPlexSans/IBMPlexSans-Light.woff') format('woff'),
					 url('${fontPath}IBMPlexSans/IBMPlexSans-Light.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Sans';
			font-style: italic;
			font-weight: 300;
			src: url('${fontPath}IBMPlexSans/IBMPlexSans-LightItalic.woff') format('woff'),
					 url('${fontPath}IBMPlexSans/IBMPlexSans-LightItalic.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Sans';
			font-style: normal;
			font-weight: 500;
			src: url('${fontPath}IBMPlexSans/IBMPlexSans-Medium.woff') format('woff'),
					 url('${fontPath}IBMPlexSans/IBMPlexSans-Medium.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Sans';
			font-style: italic;
			font-weight: 500;
			src: url('${fontPath}IBMPlexSans/IBMPlexSans-MediumItalic.woff') format('woff'),
					 url('${fontPath}IBMPlexSans/IBMPlexSans-MediumItalic.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Sans';
			font-style: normal;
			font-weight: 400;
			src: url('${fontPath}IBMPlexSans/IBMPlexSans-Regular.woff') format('woff'),
					 url('${fontPath}IBMPlexSans/IBMPlexSans-Regular.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Sans';
			font-style: normal;
			font-weight: 600;
			src: url('${fontPath}IBMPlexSans/IBMPlexSans-SemiBold.woff') format('woff'),
					 url('${fontPath}IBMPlexSans/IBMPlexSans-SemiBold.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Sans';
			font-style: italic;
			font-weight: 600;
			src: url('${fontPath}IBMPlexSans/IBMPlexSans-SemiBoldItalic.woff') format('woff'),
					 url('${fontPath}IBMPlexSans/IBMPlexSans-SemiBoldItalic.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Sans';
			font-style: normal;
			font-weight: 450;
			src: url('${fontPath}IBMPlexSans/IBMPlexSans-Text.woff') format('woff'),
					 url('${fontPath}IBMPlexSans/IBMPlexSans-Text.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Sans';
			font-style: italic;
			font-weight: 450;
			src: url('${fontPath}IBMPlexSans/IBMPlexSans-TextItalic.woff') format('woff'),
					 url('${fontPath}IBMPlexSans/IBMPlexSans-TextItalic.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Sans';
			font-style: normal;
			font-weight: 100;
			src: url('${fontPath}IBMPlexSans/IBMPlexSans-Thin.woff') format('woff'),
					 url('${fontPath}IBMPlexSans/IBMPlexSans-Thin.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Mono';
			font-style: italic;
			font-weight: 100;
			src: url('${fontPath}IBMPlexMono/IBMPlexMono-ThinItalic.woff2') format('woff2')
					 url('${fontPath}IBMPlexMono/IBMPlexMono-ThinItalic.woff') format('woff');
		}
		
		@font-face {
			font-family: 'IBM Plex Mono';
			font-style: normal;
			font-weight: 700;
			src: url('${fontPath}IBMPlexMono/IBMPlexMono-Bold.woff') format('woff'),
					 url('${fontPath}IBMPlexMono/IBMPlexMono-Bold.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Mono';
			font-style: italic;
			font-weight: 700;
			src: url('${fontPath}IBMPlexMono/IBMPlexMono-BoldItalic.woff') format('woff'),
					 url('${fontPath}IBMPlexMono/IBMPlexMono-BoldItalic.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Mono';
			font-style: normal;
			font-weight: 200;
			src: url('${fontPath}IBMPlexMono/IBMPlexMono-ExtraLight.woff') format('woff'),
					 url('${fontPath}IBMPlexMono/IBMPlexMono-ExtraLight.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Mono';
			font-style: italic;
			font-weight: 200;
			src: url('${fontPath}IBMPlexMono/IBMPlexMono-ExtraLightItalic.woff') format('woff'),
					 url('${fontPath}IBMPlexMono/IBMPlexMono-ExtraLightItalic.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Mono';
			font-style: italic;
			font-weight: 400;
			src: url('${fontPath}IBMPlexMono/IBMPlexMono-Italic.woff') format('woff'),
					 url('${fontPath}IBMPlexMono/IBMPlexMono-Italic.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Mono';
			font-style: normal;
			font-weight: 300;
			src: url('${fontPath}IBMPlexMono/IBMPlexMono-Light.woff') format('woff'),
					 url('${fontPath}IBMPlexMono/IBMPlexMono-Light.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Mono';
			font-style: italic;
			font-weight: 300;
			src: url('${fontPath}IBMPlexMono/IBMPlexMono-LightItalic.woff') format('woff'),
					 url('${fontPath}IBMPlexMono/IBMPlexMono-LightItalic.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Mono';
			font-style: normal;
			font-weight: 500;
			src: url('${fontPath}IBMPlexMono/IBMPlexMono-Medium.woff') format('woff'),
					 url('${fontPath}IBMPlexMono/IBMPlexMono-Medium.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Mono';
			font-style: italic;
			font-weight: 500;
			src: url('${fontPath}IBMPlexMono/IBMPlexMono-MediumItalic.woff') format('woff'),
					 url('${fontPath}IBMPlexMono/IBMPlexMono-MediumItalic.woff2') format('woff2');
		}
		@font-face {
			font-family: 'IBM Plex Mono';
			font-style: normal;
			font-weight: 400;
			src: url('${fontPath}IBMPlexMono/IBMPlexMono-Regular.woff') format('woff'),
					 url('${fontPath}IBMPlexMono/IBMPlexMono-Regular.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Mono';
			font-style: normal;
			font-weight: 600;
			src: url('${fontPath}IBMPlexMono/IBMPlexMono-SemiBold.woff') format('woff'),
					 url('${fontPath}IBMPlexMono/IBMPlexMono-SemiBold.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Mono';
			font-style: italic;
			font-weight: 600;
			src: url('${fontPath}IBMPlexMono/IBMPlexMono-SemiBoldItalic.woff') format('woff'),
					 url('${fontPath}IBMPlexMono/IBMPlexMono-SemiBoldItalic.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Mono';
			font-style: normal;
			font-weight: 450;
			src: url('${fontPath}IBMPlexMono/IBMPlexMono-Text.woff') format('woff'),
					 url('${fontPath}IBMPlexMono/IBMPlexMono-Text.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Mono';
			font-style: italic;
			font-weight: 450;
			src: url('${fontPath}IBMPlexMono/IBMPlexMono-TextItalic.woff') format('woff'),
					 url('${fontPath}IBMPlexMono/IBMPlexMono-TextItalic.woff2') format('woff2');
		}
		
		@font-face {
			font-family: 'IBM Plex Mono';
			font-style: normal;
			font-weight: 100;
			src: url('${fontPath}IBMPlexMono/IBMPlexMono-Thin.woff') format('woff'),
					 url('${fontPath}IBMPlexMono/IBMPlexMono-Thin.woff2') format('woff2');
		}

		@font-face {
			font-family: 'Material Symbols Outlined';
			font-style: normal;
			src: url('${fontPath}MUI/MaterialSymbolsOutlined.woff2') format('woff2'),
					 url('${fontPath}MUI/MaterialSymbolsOutlined.woff2') format('woff2-variations');
		}

		@font-face {
			font-family: 'Material Symbols Rounded';
			font-style: normal;
			src: url('${fontPath}MUI/MaterialSymbolsRounded.woff2') format('woff2'),
					 url('${fontPath}MUI/MaterialSymbolsRounded.woff2') format('woff2-variations');
		}

		@font-face {
			font-family: 'Material Symbols Sharp';
			font-style: normal;
			src: url('${fontPath}MUI/MaterialSymbolsSharp.woff2') format('woff2'),
					 url('${fontPath}MUI/MaterialSymbolsSharp.woff2') format('woff2-variations');
		}
		
		`}
  />
);
