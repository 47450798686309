export const formatNumber = (num?: number) =>
  num && num.toLocaleString('en', { useGrouping: true });

export const formatNumberK = (num?: number) => {
  if (!num) {
    return;
  }
  const absNum = Math.abs(num);
  const signNum = Math.sign(num);
  return absNum > 999 ? `${(signNum * (absNum / 1000)).toFixed(1)}k` : num;
};
