import { createIcon } from '@chakra-ui/react';

export const BoxDownloadIcon = createIcon({
  displayName: 'BoxDownloadIcon',
  viewBox: '0 0 24 24',
  d: 'M5 3h14a2 2 0 0 1 2 2v14c0 1.11-.89 2-2 2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2Zm3 14h8v-2H8v2Zm8-7h-2.5V7h-3v3H8l4 4 4-4Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
