import { createIcon } from '@chakra-ui/react';

export const AmazonKinesisLogo = createIcon({
  displayName: 'AmazonKinesisLogo',
  viewBox: '0 0 79 32',
  path: [
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.437451 3.82358C0.994583 0.900509 3.5454 0.00610075 5.85388 0.00610075C7.16987 -0.0378137 8.45681 0.399156 9.47399 1.23529C10.5403 2.26762 10.5296 3.63022 10.5182 5.08144C10.517 5.23175 10.5158 5.38301 10.5158 5.53495V9.29997C10.523 10.1541 10.8491 10.9748 11.4302 11.601C11.5198 11.6909 11.57 11.8126 11.57 11.9395C11.57 12.0664 11.5198 12.1881 11.4302 12.278C11.0891 12.562 10.591 13.0176 10.1493 13.4217C9.93426 13.6184 9.73257 13.8029 9.56893 13.9494C9.4924 14.0178 9.39667 14.0609 9.29476 14.073C9.19284 14.0851 9.08969 14.0655 8.9993 14.0169C8.45508 13.5622 7.9965 13.0139 7.64519 12.3979C7.17108 12.9999 6.55826 13.4782 5.85911 13.7919C5.15995 14.1056 4.39525 14.2453 3.63034 14.1992C1.62167 14.1992 0.00273592 12.9076 0.00273592 10.3143C-0.0281162 9.45502 0.202565 8.60661 0.664275 7.88126C1.12599 7.15591 1.79698 6.58777 2.58853 6.25198C4.06787 5.7527 5.61057 5.46586 7.17051 5.40004V5.05527C7.23949 4.43683 7.12936 3.8116 6.85322 3.25396C6.69463 3.04802 6.48879 2.8833 6.25309 2.77371C6.0174 2.66413 5.75878 2.61291 5.49911 2.62437C5.04732 2.58928 4.59833 2.72089 4.23697 2.99432C3.87562 3.26775 3.62693 3.66408 3.5379 4.10839C3.53652 4.22138 3.49886 4.33094 3.43049 4.4209C3.36212 4.51086 3.26665 4.57648 3.15816 4.60806L0.789715 4.32325H0.749745C0.696137 4.31149 0.645418 4.28914 0.600562 4.25751C0.555707 4.22589 0.517618 4.18562 0.48853 4.13908C0.459442 4.09254 0.439939 4.04066 0.431168 3.98649C0.422397 3.93231 0.424533 3.87692 0.437451 3.82358ZM7.14053 7.42621C5.39628 7.42727 3.51296 7.80146 3.5404 9.89707C3.5404 10.9814 4.08754 11.7309 5.02192 11.7309C5.3774 11.7107 5.72071 11.5943 6.01512 11.3941C6.30952 11.1938 6.54395 10.9173 6.69332 10.5941C7.07872 9.77058 7.23328 8.85786 7.14053 7.95336V7.42621Z"
      fill="currentColor"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.254 3.82358C32.8111 0.900509 35.3619 0.00610075 37.6704 0.00610075V0.00360187C38.9859 -0.0446813 40.2731 0.393857 41.2855 1.23529C42.3709 2.28615 42.3637 3.67926 42.3561 5.1597C42.3554 5.28422 42.3548 5.40935 42.3548 5.53495V9.29997C42.362 10.1541 42.6881 10.9748 43.2692 11.601C43.3542 11.6932 43.4013 11.814 43.4013 11.9395C43.4013 12.0649 43.3542 12.1857 43.2692 12.278C42.9281 12.562 42.43 13.0176 41.9883 13.4217C41.7733 13.6184 41.5716 13.8029 41.4079 13.9494C41.3314 14.0178 41.2357 14.0609 41.1338 14.073C41.0318 14.0851 40.9287 14.0655 40.8383 14.0169C40.2937 13.5626 39.835 13.0143 39.4842 12.3979C39.0101 12.9999 38.3973 13.4782 37.6981 13.7919C36.9989 14.1056 36.2342 14.2453 35.4693 14.1992C33.4607 14.1992 31.8417 12.9076 31.8417 10.3143C31.8109 9.45502 32.0416 8.60661 32.5033 7.88126C32.965 7.15591 33.636 6.58777 34.4275 6.25198C35.8958 5.75296 37.4277 5.46609 38.977 5.40004V5.05527C39.0602 4.43927 38.9648 3.81236 38.7022 3.24896C38.5436 3.04302 38.3378 2.8783 38.1021 2.76871C37.8664 2.65913 37.6078 2.60791 37.3481 2.61938C36.8975 2.58546 36.45 2.71731 36.0898 2.99013C35.7295 3.26295 35.4814 3.65796 35.3919 4.1009C35.3886 4.21818 35.3462 4.33101 35.2716 4.42152C35.1969 4.51203 35.0942 4.57504 34.9797 4.60057L32.6062 4.32325H32.5663C32.5127 4.31149 32.4619 4.28914 32.4171 4.25751C32.3722 4.22589 32.3341 4.18562 32.305 4.13908C32.276 4.09254 32.2565 4.04066 32.2477 3.98649C32.2389 3.93231 32.241 3.87692 32.254 3.82358ZM38.957 7.95336V7.41871L38.9445 7.41122C37.1982 7.41122 35.3569 7.79096 35.3569 9.90957C35.3569 10.9939 35.9041 11.7434 36.8384 11.7434C37.1941 11.724 37.5378 11.6079 37.8323 11.4076C38.1269 11.2073 38.3611 10.9303 38.5098 10.6066C38.8977 9.77941 39.0524 8.862 38.957 7.95336Z"
      fill="currentColor"
    />,
    <path
      d="M17.7726 0.766431C18.3508 0.340136 19.054 0.117552 19.7722 0.133515L19.7772 0.143509C21.2937 0.143509 22.2431 0.883023 22.9926 2.47947C23.2074 1.8885 23.5758 1.36542 24.0599 0.964028C24.5439 0.562637 25.1262 0.297422 25.7467 0.195654C26.3672 0.0938858 27.0037 0.159238 27.5906 0.38499C28.1775 0.61074 28.6937 0.988744 29.0861 1.48013C29.752 2.4247 29.7355 3.74278 29.7201 4.96199C29.7179 5.14233 29.7156 5.3205 29.7156 5.49498V13.6246C29.7125 13.7475 29.6622 13.8645 29.5753 13.9515C29.4884 14.0384 29.3714 14.0886 29.2484 14.0918H26.8175C26.6997 14.0796 26.5901 14.0259 26.5082 13.9403C26.4263 13.8547 26.3774 13.7429 26.3703 13.6246V6.85408C26.4062 6.04321 26.3836 5.23082 26.3029 4.42319C26.2587 4.09174 26.0852 3.79121 25.8203 3.58715C25.5554 3.38309 25.2206 3.29206 24.8888 3.3339C24.5728 3.35275 24.2692 3.46385 24.0156 3.65346C23.7621 3.84307 23.5697 4.10287 23.4623 4.4007C23.2622 5.1945 23.1889 6.01491 23.2449 6.8316V13.6021C23.2417 13.725 23.1915 13.842 23.1046 13.929C23.0176 14.0159 22.9006 14.0662 22.7777 14.0693H20.3468C20.229 14.0571 20.1193 14.0034 20.0374 13.9178C19.9555 13.8323 19.9067 13.7204 19.8996 13.6021V6.8316C19.8996 6.67188 19.903 6.50353 19.9065 6.33054C19.9338 4.97465 19.9668 3.3339 18.4181 3.3339C16.74 3.3339 16.7436 5.19558 16.7465 6.65562C16.7466 6.71499 16.7467 6.7737 16.7467 6.8316V13.6021C16.7435 13.725 16.6933 13.842 16.6064 13.929C16.5194 14.0159 16.4024 14.0662 16.2795 14.0693H13.8486C13.7339 14.0578 13.6267 14.007 13.5452 13.9255C13.4637 13.844 13.4129 13.7368 13.4014 13.6221V0.835554C13.4096 0.714044 13.4638 0.600222 13.5529 0.517173C13.642 0.434125 13.7593 0.388068 13.8811 0.388348H16.1296C16.2457 0.395975 16.3551 0.445541 16.4373 0.527811C16.5196 0.610084 16.5692 0.719454 16.5768 0.835554V2.48946C16.7739 1.79868 17.1944 1.19272 17.7726 0.766431Z"
      fill="currentColor"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.6485 7.20135C55.6485 3.22148 57.6147 0.133515 61.1124 0.133515L61.065 0.123524C64.6951 0.123524 66.6638 3.29393 66.6638 7.31378C66.6638 11.2187 64.4977 14.3267 61.1124 14.3267C57.5773 14.3267 55.6485 11.1812 55.6485 7.20135ZM62.1372 3.09889C61.8423 2.87279 61.4759 2.76023 61.1049 2.78177L61.055 2.76928C59.2961 2.76928 59.2137 5.29761 59.2137 6.8316C59.2137 8.36559 59.2262 11.7509 61.0999 11.7509C62.9737 11.7509 63.0561 9.01765 63.0561 7.39872C63.1015 6.25969 62.9854 5.11997 62.7114 4.01346C62.6359 3.6496 62.4321 3.325 62.1372 3.09889Z"
      fill="currentColor"
    />,
    <path
      d="M71.658 2.71931C72.3425 0.94548 73.2969 0.133515 74.9758 0.133515L74.9533 0.126021C75.5182 0.105073 76.0785 0.234702 76.5768 0.501624C77.075 0.768545 77.4934 1.16316 77.7889 1.64502C78.4185 2.66685 78.4185 4.37572 78.4185 5.62489V13.6571C78.4025 13.7702 78.3466 13.8738 78.261 13.9494C78.1753 14.025 78.0655 14.0675 77.9513 14.0693H75.5379C75.4272 14.0629 75.3222 14.0183 75.2406 13.9431C75.1591 13.868 75.1061 13.7669 75.0907 13.6571V6.71168C75.0907 6.60025 75.0917 6.4849 75.0928 6.36695C75.1049 4.99603 75.1201 3.27394 73.5742 3.27394C73.2814 3.30437 73.0031 3.41723 72.7719 3.59942C72.5406 3.78162 72.3657 4.02568 72.2676 4.30326C71.9607 5.07556 71.8203 5.90389 71.8553 6.73416V13.6096C71.8522 13.7325 71.8019 13.8495 71.715 13.9365C71.6281 14.0234 71.5111 14.0737 71.3881 14.0768H68.9573C68.8385 14.0657 68.7277 14.0125 68.6448 13.9268C68.5618 13.8412 68.5123 13.7286 68.5051 13.6096V0.835554C68.5173 0.723769 68.57 0.620341 68.6534 0.544837C68.7367 0.469332 68.8448 0.42699 68.9573 0.425824H71.2058C71.3086 0.42553 71.4086 0.459156 71.4904 0.521489C71.5721 0.583822 71.631 0.67138 71.658 0.770596V2.71931Z"
      fill="currentColor"
    />,
    <path
      d="M45.4763 0.481855C45.5602 0.397989 45.6739 0.350873 45.7925 0.350873L45.8025 0.368361H53.7973C53.9146 0.375457 54.0244 0.428451 54.1029 0.515881C54.1815 0.603312 54.2224 0.718148 54.217 0.835554V2.38203C54.1171 2.8058 53.9107 3.19706 53.6174 3.51878L49.4701 9.60977C51.0569 9.52348 52.6368 9.87525 54.0371 10.6266C54.1645 10.6903 54.2728 10.7864 54.3513 10.9053C54.4298 11.0241 54.4757 11.1615 54.4843 11.3036V13.2424C54.4849 13.3144 54.4678 13.3856 54.4344 13.4495C54.401 13.5134 54.3525 13.5681 54.293 13.6088C54.2335 13.6495 54.165 13.675 54.0933 13.683C54.0217 13.691 53.9492 13.6812 53.8822 13.6546C52.585 12.9643 51.1381 12.6033 49.6687 12.6033C48.1993 12.6033 46.7524 12.9643 45.4553 13.6546C45.3901 13.6796 45.3197 13.688 45.2505 13.6789C45.1812 13.6699 45.1153 13.6437 45.0588 13.6028C45.0022 13.5618 44.9568 13.5074 44.9265 13.4445C44.8963 13.3816 44.8822 13.3121 44.8856 13.2424V11.3811C44.8778 10.9412 44.9947 10.5081 45.2229 10.1319L49.9548 3.09906H45.7651C45.6477 3.09196 45.5379 3.03897 45.4594 2.95154C45.3808 2.86411 45.3399 2.74927 45.3453 2.63187V0.798078C45.3453 0.679471 45.3924 0.565723 45.4763 0.481855Z"
      fill="currentColor"
    />,
    <path
      d="M38.6223 22.2289C38.6223 20.8798 38.1376 19.9455 36.6236 19.9455C35.292 19.9455 34.5 20.665 34.5 22.0316C34.5 23.721 35.8242 24.1495 37.235 24.606C38.843 25.1264 40.5635 25.6831 40.5635 28.185C40.5635 31.1531 38.3874 32 36.5661 32C33.5681 32 32.6812 30.4535 32.6812 27.7178H34.2651C34.2651 29.1569 34.3551 30.7158 36.6761 30.7158C36.9746 30.7255 37.272 30.6732 37.5493 30.5623C37.8267 30.4514 38.0781 30.2842 38.2877 30.0713C38.4973 29.8585 38.6605 29.6045 38.7671 29.3254C38.8737 29.0464 38.9214 28.7482 38.9071 28.4498C38.9071 26.7505 37.582 26.3058 36.1707 25.8322C34.563 25.2928 32.8436 24.7158 32.8436 22.204C32.8436 21.0172 33.3433 18.6588 36.5911 18.6588C39.037 18.6588 40.1538 19.883 40.1887 22.2389L38.6223 22.2289Z"
      fill="currentColor"
    />,
    <path
      d="M1.96395 18.9011H0.379987L0.37749 31.7577H1.96144V27.3855L3.29307 25.5692L6.77077 31.7577H8.53461L4.32489 24.2626L8.20983 18.9011H6.44599L2.00142 25.1245H1.96395V18.9011Z"
      fill="currentColor"
    />,
    <path
      d="M11.4077 18.9011H9.82376V31.7452H11.4077V18.9011Z"
      fill="currentColor"
    />,
    <path
      d="M13.9435 18.9011H15.9922L20.2394 29.6241H20.2744V18.9011H21.8583V31.7452H19.6823L15.5625 21.347H15.5275V31.7452H13.9435V18.9011Z"
      fill="currentColor"
    />,
    <path
      d="M31.0323 18.9011H24.3942V31.7452H31.2571V30.3411H25.9856V25.7391H30.7325V24.3351H25.9856V20.3052H31.0323V18.9011Z"
      fill="currentColor"
    />,
    <path
      d="M44.1986 18.9011H42.6146V31.7452H44.1986V18.9011Z"
      fill="currentColor"
    />,
    <path
      d="M52.1858 22.2289C52.1858 20.8798 51.7011 19.9455 50.1871 19.9455C48.8555 19.9455 48.0635 20.665 48.0635 22.0316C48.0635 23.721 49.3877 24.1495 50.7985 24.606C52.4066 25.1264 54.127 25.6831 54.127 28.185C54.127 31.1531 51.951 32 50.1297 32C47.1317 32 46.2447 30.4535 46.2447 27.7178H47.8287C47.8287 29.1569 47.9186 30.7158 50.2396 30.7158C50.5382 30.7255 50.8355 30.6732 51.1129 30.5623C51.3903 30.4514 51.6417 30.2842 51.8513 30.0713C52.0608 29.8585 52.2241 29.6045 52.3307 29.3254C52.4373 29.0464 52.4849 28.7482 52.4706 28.4498C52.4706 26.7505 51.1455 26.3058 49.7342 25.8322C48.1266 25.2928 46.4071 24.7158 46.4071 22.204C46.4071 21.0172 46.9068 18.6588 50.1547 18.6588C52.6005 18.6588 53.7173 19.883 53.7523 22.2389L52.1858 22.2289Z"
      fill="currentColor"
    />,
  ],
  defaultProps: {
    height: '32px',
    width: '79px',
  },
});
