import app from './app.json';
import authentication from './authentication.json';
import buttons from './buttons.json';
import charts from './charts.json';
import cookiePolicy from './cookiePolicy.json';
import dateSelectSync from './dateSelectSync.json';
import dictionary from './dictionary.json';
import errors from './errors.json';
import federatedTenant from './federatedTenant.json';
import { forms } from './forms';
import navigation from './navigation.json';
import { pagesi18n } from './pages';
import popper from './popper.json';
import roles from './role.json';
import tables from './tables.json';

export const en = {
  pages: pagesi18n,
  app,
  authentication,
  buttons,
  charts,
  dictionary,
  errors,
  federatedTenant,
  forms,
  navigation,
  popper,
  tables,
  roles,
  cookiePolicy,
  dateSelectSync,
};
