import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { IGetAlertMetadata } from './types';

export const GET_ALERT_METADATA = gql`
  query getAlertMetadata($items: [GetAlertMetadataInput]!) {
    getAlertMetadata(items: $items) {
      metadata {
        date
        related {
          id
          title
          type
        }
        category
        cve_reference
        severity_description
        active
        author
        description
        event_generation_strategy {
          attack_probability_threshold
          method
          predicted_tag_id
          alert_sid
        }
        falsepositives
        fields
        id
        level
        logsource {
          category
          product
          service
        }
        mitre_mappings {
          tactics {
            link
            name
            tag
            techniques {
              link
              name
              tag
              subtechniques {
                link
                name
                tag
              }
            }
          }
        }
        references
        severity
        severity_info {
          custom_severity
          custom_severity_enabled
          default_severity
          last_updated_by_user_alias
          last_updated_by_username
          last_updated_timestamp
        }
        status
        tags
        tenant_alert
        title
        rule
        content_doc {
          significance
          validation
          nextsteps
          queries
        }
        custom_search_rule {
          query_string
          query_filter
          throttle_time
          time_interval
        }
      }
    }
  }
`;

export const useGetAlertMetadata = (
  options: QueryHookOptions<IGetAlertMetadata, OperationVariables>,
) => {
  return useQuery<IGetAlertMetadata>(GET_ALERT_METADATA, options);
};
