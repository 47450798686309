import { ComponentStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

import { textStyles } from '../../common';

export const RadioStyles: Partial<ComponentStyleConfig> = {
  baseStyle: (props) => ({
    control: {
      border: '1px solid',
      bg: 'layer.1',
      boxShadow: '0px 1px 0px rgba(244, 244, 244, 0.1)',
      borderColor: 'border.2',
      position: 'relative',
      _checked: {
        bg: 'blue.500',
        color: 'blue.500',
        borderColor: 'blue.500',

        _before: {
          h: 2,
          w: 2,
          position: 'absolute',
          top: '3px',
          left: '3px',
          bg: 'white',
        },
        _focus: {
          bg: 'blue.500',
        },

        _hover: {
          borderColor: 'inherit',
          bg: mode('gray.300', 'gray.800')(props),
        },
        _invalid: {
          bg: 'red.500',
        },
      },
      _focus: {
        boxShadow: '0px 0px 2px 1.5px #0093ee',
        borderColor: 'blue.500',
        bg: mode('gray.50', 'gray.800')(props),
      },
      _hover: {
        bg: mode('gray.300', 'gray.800')(props),
      },
      _invalid: {
        borderColor: 'red.500',
      },
    },
  }),
  sizes: {
    sm: {
      control: {
        width: 3, // 12px
        height: 3, // 12px
      },
      label: {
        ...textStyles['body-sm'],
      },
    },
    md: {
      control: {
        height: 4, // 16px
        width: 4, // 16px
      },
      label: {
        ...textStyles['body-md'],
      },
    },
    lg: {
      control: {
        width: 5, // 20px
        height: 5, // 20px
      },
      label: {
        ...textStyles['body-md'],
      },
    },
  },
  variants: {
    box: (props) => ({
      // hide the radio circle
      control: {
        display: 'none',
        visibility: 'none',
        overflow: 'hidden',
        h: 0,
        w: 0,
      },
      container: {
        px: 3,
        py: 2,
        borderRadius: 'base',
        bg: mode('gray.50', 'gray.800')(props),
        borderColor: mode('gray.300', 'gray.700')(props),
        opacity: 0.6,
        _disabled: {
          opacity: 0.4,
        },
        _hover: {
          bgColor: mode('gray.50', 'gray.800')(props),
          borderColor: mode('gray.300', 'gray.700')(props),
          opacity: 1,
        },
        _checked: {
          opacity: 1,
        },
      },
      label: {
        ml: 0,
        textTransform: 'none',
      },
    }),
    subLabel: {
      // these styles align the control inline with the first line of text
      control: {
        top: '2px',
      },
      container: {
        alignItems: 'baseline',
      },
    },
  },
  defaultProps: {
    errorBorderColor: 'red.500',
  },
};
