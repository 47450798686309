import { ButtonGroup, IconButton, Text } from '@chakra-ui/react';
import { DataTable } from '@gamma/data-table';
import { MuiIcon } from '@gamma/display';
import { TimestampCell } from '@gamma/investigator/components';
import { i18n } from '@gamma/investigator/localization';
import { User } from '@gamma/investigator/queries';
import _ from 'lodash';
import { useMemo } from 'react';
import { CellProps, Column, Row } from 'react-table';
import { DeleteUserModal } from '../DeleteUserModal';
import { EditUserDrawer } from '../EditUserDrawer';

interface IUsersTableProps {
  users?: User[];
  tableSearch: string;
  onRowSelection?: (rows: Row<User>[]) => void;
}

export const UsersTable = ({
  users = [],
  tableSearch,
  onRowSelection,
}: IUsersTableProps) => {
  const dataTableColumns: Column<User>[] = useMemo(
    () => [
      {
        width: 260,
        Header: i18n.tables.alias,
        accessor: 'alias',
        Cell: (props) => <Text>{props?.value ?? '-'}</Text>,
      },
      {
        Header: i18n.tables.email,
        accessor: 'email',
        Cell: '.............',
      },
      {
        Header: i18n.tables.role,
        accessor: 'group',
        Cell: (props) => <>{_.capitalize(props.value)}</>,
      },
      {
        Header: i18n.tables.status,
        accessor: 'status',
        Cell: (props) => <>{_.capitalize(props.value)}</>,
      },
      {
        Header: i18n.tables.sso,
        accessor: 'is_sso_user',
        Cell: (props) => <Text>{props?.value ? 'Enabled' : 'Disabled'}</Text>,
      },
      {
        Header: i18n.tables.localAuthentication,
        accessor: 'local_auth_enabled',
        Cell: (props) => <Text>{props?.value ? 'Enabled' : 'Disabled'}</Text>,
      },
      {
        Header: i18n.tables.dateCreated,
        accessor: 'created_at',
        disableGlobalFilter: true,
        Cell: (props) => (
          <TimestampCell
            timestamp={props.value}
            isFromNow={false}
            format="ll"
          />
        ),
      },
      {
        Header: i18n.tables.actions,
        Cell: (props: CellProps<User>) => {
          const isDisabled = props.row.original.is_sso_user;
          return (
            <ButtonGroup
              spacing="4"
              size="box-md"
              variant="solid"
              colorScheme="gray"
            >
              <EditUserDrawer data={props.row.original}>
                <IconButton
                  icon={<MuiIcon>edit</MuiIcon>}
                  aria-label="Edit User Button"
                  isDisabled={isDisabled}
                />
              </EditUserDrawer>
              <DeleteUserModal users={[props.row.original.email]}>
                <IconButton
                  icon={<MuiIcon>delete</MuiIcon>}
                  aria-label="Delete User Button"
                  isDisabled={isDisabled}
                />
              </DeleteUserModal>
            </ButtonGroup>
          );
        },
      },
    ],
    [],
  );

  const rowIsSelectable = ({ original }: Row<User>) => !original?.is_sso_user;

  return (
    <div data-testid="users-table">
      <DataTable
        data={users}
        columns={dataTableColumns}
        search={tableSearch}
        onRowSelection={onRowSelection}
        autoResetRowSelection={false}
        isSelectable
        autoResetPage={false}
        autoResetSortBy={false}
        rowIsSelectable={rowIsSelectable}
      />
    </div>
  );
};
