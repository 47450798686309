import { createIcon } from '@chakra-ui/react';

export const TwoFactorAuth = createIcon({
  displayName: 'TwoFactorAuth',
  viewBox: '0 0 64 52',
  path: (
    <svg width="64" height="52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M59.902 43.01a.33.33 0 0 1-.184-.398.156.156 0 0 0-.02-.137.166.166 0 0 0-.27-.01.159.159 0 0 0-.018.033.33.33 0 0 1-.398.184.156.156 0 0 0-.137.02.166.166 0 0 0-.01.27c.01.007.021.013.033.018a.33.33 0 0 1 .184.398.165.165 0 0 0 .204.205.165.165 0 0 0 .104-.091.33.33 0 0 1 .398-.184.164.164 0 0 0 .205-.204.165.165 0 0 0-.091-.104ZM15.502 7.416a.33.33 0 0 1-.184-.402.16.16 0 0 0-.02-.138.166.166 0 0 0-.184-.07.165.165 0 0 0-.104.093.331.331 0 0 1-.398.185.156.156 0 0 0-.137.02.167.167 0 0 0-.068.187.166.166 0 0 0 .091.104.331.331 0 0 1 .184.402.16.16 0 0 0 .02.138.166.166 0 0 0 .184.069.165.165 0 0 0 .104-.092.331.331 0 0 1 .398-.186.156.156 0 0 0 .137-.02.167.167 0 0 0 .068-.186.166.166 0 0 0-.091-.104Z"
        fill="#4299E1"
      />
      <path
        d="M56.2 15.61c.331 0 .6-.27.6-.605a.603.603 0 0 0-.6-.605c-.331 0-.6.271-.6.605 0 .335.269.606.6.606ZM26.2 46.01c.331 0 .6-.27.6-.605a.603.603 0 0 0-.6-.605c-.331 0-.6.271-.6.605 0 .335.269.606.6.606ZM54.2 35.61c.331 0 .6-.27.6-.605a.603.603 0 0 0-.6-.605c-.331 0-.6.271-.6.605 0 .335.269.606.6.606Z"
        fill="#F56565"
      />
      <path
        d="M49.102 39.016a.33.33 0 0 1-.184-.402.168.168 0 0 0-.1-.203.164.164 0 0 0-.208.088.331.331 0 0 1-.398.185.157.157 0 0 0-.137.02.167.167 0 0 0 .023.291.331.331 0 0 1 .184.402.16.16 0 0 0 .02.138.166.166 0 0 0 .184.069.165.165 0 0 0 .104-.092.33.33 0 0 1 .398-.186.156.156 0 0 0 .137-.02.167.167 0 0 0-.023-.29ZM3.102 9.016a.33.33 0 0 1-.184-.402.161.161 0 0 0-.02-.138.166.166 0 0 0-.184-.07.165.165 0 0 0-.104.093.331.331 0 0 1-.398.185.157.157 0 0 0-.137.02.166.166 0 0 0-.068.187.167.167 0 0 0 .091.104.33.33 0 0 1 .184.402.16.16 0 0 0 .02.138.165.165 0 0 0 .184.069.165.165 0 0 0 .104-.092.331.331 0 0 1 .398-.186.157.157 0 0 0 .137-.02.167.167 0 0 0 .068-.186.167.167 0 0 0-.091-.104Z"
        fill="#4299E1"
      />
      <path
        d="M6.2 19.61c.331 0 .6-.27.6-.605a.603.603 0 0 0-.6-.605c-.331 0-.6.271-.6.605 0 .335.269.606.6.606ZM61.8 26.01c.332 0 .6-.27.6-.605a.603.603 0 0 0-.6-.605c-.331 0-.6.271-.6.605 0 .335.269.606.6.606ZM46.306.8h-.212v1.211h.212V.801Z"
        fill="#35FE94"
      />
      <path
        d="M46.8 1.512V1.3h-1.2v.213h1.2ZM43.106 42.8h-.212v1.211h.212v-1.21Z"
        fill="#35FE94"
      />
      <path
        d="M43.6 43.512V43.3h-1.2v.213h1.2ZM50.73 26.407h-.212v1.211h.211v-1.21Z"
        fill="#35FE94"
      />
      <path
        d="M51.224 27.12v-.214h-1.2v.213h1.2ZM56.15 2.824l.36.333.51-.757.18.151-.69.908-.51-.575.15-.06ZM7.35 42.824l.36.333.51-.757.18.151-.69.908-.51-.575.15-.06Z"
        fill="#35FE94"
      />
      <path
        d="M35.718 30.149s1.037 6.959 7.15 9.278H18.105c6.113-2.32 7.15-9.278 7.15-9.278h10.463Z"
        fill="#B9BBBE"
      />
      <path d="M42.868 39.425H18.105v1.134h24.763v-1.134Z" fill="#EDEEEE" />
      <path
        d="M50.923 28.294H10.05V6.327a.529.529 0 0 1 .535-.53h39.805a.535.535 0 0 1 .534.53v21.967Z"
        fill="#D5D6D8"
      />
      <path
        d="M49.929 32.004H11.044a.994.994 0 0 1-.702-.289.984.984 0 0 1-.29-.698v-2.723h40.871v2.72a.983.983 0 0 1-.613.916.995.995 0 0 1-.381.074Z"
        fill="#EDEEEE"
      />
      <path d="M49.107 8.592H12.12V25.78h36.988V8.592Z" fill="#2A4365" />
      <path d="M49.108 7.62H12.07v1.514h37.038V7.62Z" fill="#EDEEEE" />
      <path
        d="M12.875 8.838a.445.445 0 0 0 .447-.444c0-.245-.2-.444-.447-.444a.445.445 0 0 0-.446.444c0 .245.2.444.446.444Z"
        fill="#F56565"
      />
      <path
        d="M13.917 8.838a.445.445 0 0 0 .447-.444c0-.245-.2-.444-.447-.444a.445.445 0 1 0 0 .888Z"
        fill="#F6E05E"
      />
      <path
        d="M14.959 8.838a.445.445 0 0 0 .446-.444.445.445 0 0 0-.893 0c0 .245.2.444.447.444Z"
        fill="#35FE94"
      />
      <path
        d="m57.587 20.057-10.845.015a.495.495 0 0 0-.495.494l.028 20.094c0 .273.223.493.497.493l10.845-.015a.495.495 0 0 0 .495-.494l-.028-20.094a.495.495 0 0 0-.497-.493Z"
        fill="#EDEEEE"
      />
      <path
        d="m56.75 21.549-9.165.012.024 17.156 9.164-.013-.024-17.155Z"
        fill="#2A4365"
      />
      <path
        d="M49.893 32.886h5.24a.295.295 0 0 0 .29-.287.293.293 0 0 0-.034-.145l-2.618-4.864a.293.293 0 0 0-.517 0l-2.619 4.864a.293.293 0 0 0 .258.432Zm2.953-.888h-.667v-.667h.667v.667Zm0-1.111h-.667v-1.333h.667v1.333Z"
        fill="#F56565"
      />
      <path
        d="M49.893 32.886h5.24a.295.295 0 0 0 .29-.287.293.293 0 0 0-.034-.145l-2.618-4.864a.293.293 0 0 0-.517 0l-2.619 4.864a.293.293 0 0 0 .258.432Zm2.953-.888h-.667v-.667h.667v.667Zm0-1.111h-.667v-1.333h.667v1.333Z"
        fill="#0B2959"
      />
      <path
        d="M49.893 32.651h5.24a.295.295 0 0 0 .29-.287.293.293 0 0 0-.034-.145l-2.618-4.865a.294.294 0 0 0-.517 0l-2.619 4.864a.293.293 0 0 0 .258.433Zm2.953-.889h-.667v-.666h.667v.666Zm0-1.11h-.667v-1.334h.667v1.333Z"
        fill="#F56565"
      />
      <path
        d="M52.194 40.673c.404 0 .73-.327.73-.728a.729.729 0 0 0-.732-.726.729.729 0 0 0-.73.728c0 .401.328.726.732.726ZM50.75 20.967a.146.146 0 1 0 0-.29.146.146 0 1 0 0 .291ZM53.626 20.644l-2.116.003a.074.074 0 0 0-.074.074v.07c0 .041.033.075.074.074l2.117-.002a.074.074 0 0 0 .074-.075v-.07a.074.074 0 0 0-.075-.074Z"
        fill="#B9BBBE"
      />
      <path d="M17.106 34.8h-.212v1.21h.212V34.8Z" fill="#35FE94" />
      <path d="M17.6 35.512V35.3h-1.2v.213h1.2Z" fill="#35FE94" />
      <path
        d="M15.4 4.01c.331 0 .6-.27.6-.605a.603.603 0 0 0-.6-.605c-.332 0-.6.271-.6.605 0 .335.268.606.6.606Z"
        fill="#F56565"
      />
      <path
        d="M3.902 31.016a.33.33 0 0 1-.184-.402.16.16 0 0 0-.02-.138.166.166 0 0 0-.184-.07.164.164 0 0 0-.104.093.331.331 0 0 1-.398.185.157.157 0 0 0-.137.02.167.167 0 0 0 .023.291.33.33 0 0 1 .184.402.162.162 0 0 0 .02.138.166.166 0 0 0 .184.069.165.165 0 0 0 .104-.092.33.33 0 0 1 .398-.186.157.157 0 0 0 .137-.02.167.167 0 0 0 .068-.186.166.166 0 0 0-.091-.104Z"
        fill="#4299E1"
      />
      <path
        d="M24.993 23.346h10.593a.594.594 0 0 0 .52-.874l-5.295-9.836a.592.592 0 0 0-1.044 0l-5.296 9.836a.594.594 0 0 0 .522.874Zm5.97-1.797h-1.348v-1.347h1.348v1.347Zm0-2.246h-1.348v-2.695h1.348v2.695Z"
        fill="#F56565"
      />
      <path
        d="M24.993 23.346h10.593a.594.594 0 0 0 .52-.874l-5.295-9.836a.592.592 0 0 0-1.044 0l-5.296 9.836a.594.594 0 0 0 .522.874Zm5.97-1.797h-1.348v-1.347h1.348v1.347Zm0-2.246h-1.348v-2.695h1.348v2.695Z"
        fill="#0B2959"
      />
      <path
        d="M24.993 22.87h10.593a.594.594 0 0 0 .52-.874l-5.295-9.835a.592.592 0 0 0-1.044 0l-5.296 9.835a.595.595 0 0 0 .522.875Zm5.97-1.796h-1.348v-1.348h1.348v1.348Zm0-2.246h-1.348v-2.696h1.348v2.696Z"
        fill="#F56565"
      />
    </svg>
  ),
  defaultProps: {
    height: '52px',
    width: '64px',
  },
});
