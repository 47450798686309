import { Flex, FlexProps, StyleProps, useStyles } from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface CardPageCTAProps extends FlexProps, StyleProps {
  children: ReactNode;
}

export const CardPageCTA = ({ children, ...rest }: CardPageCTAProps) => {
  const styles = useStyles();
  return (
    <Flex __css={styles.cta} {...rest}>
      {children}
    </Flex>
  );
};
