import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

import { IStoreEDRConfiguration } from './types';

export const STORE_EDR_CONFIGURATION = gql`
  mutation storeEDRConfiguration(
    $provider_name: EDRConfigurationType!
    $config: EDRConfigurationInput!
  ) {
    storeEDRConfiguration(provider_name: $provider_name, config: $config) {
      provider_name
      enabled
      polling_seconds
      ... on CrowdStrikeConfiguration {
        provider_name
        polling_seconds
        enabled
        client_id
        client_secret
        base_url
      }
    }
  }
`;

export const useStoreEDRConfiguration = (
  options: MutationHookOptions<IStoreEDRConfiguration, OperationVariables>,
) => useMutation(STORE_EDR_CONFIGURATION, options);
