import { Center, Text } from '@chakra-ui/react';
import { gammaContext } from '@gamma/theme';
import { ReactChild, useContext } from 'react';
import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '../ConfirmationModal';

export interface DeleteModalProps
  extends Omit<ConfirmationModalProps, 'title' | 'body'> {
  /**
   * The optional title for the Delete modal.
   * Replaces the default "delete `entityType`" title
   */
  title?: ReactChild;
  /**
   * The optional body content for the Delete modal.
   * Replaces the default "Are you sure you want to delete `entityName`" content
   */
  body?: ReactChild;
  /**
   * The name of the entity being deleted,
   * used to populate the default body content
   */
  entityName?: string;
  /**
   * The type of the entity(s) being deleted,
   * used to populate the default title content
   */
  entityType?: string;
}

export const DeleteModal = ({
  children,
  title,
  body,
  entityName,
  entityType,
  confirmText,
  confirmProps,
  'data-testid': dataTestId = 'delete-modal',
  ...props
}: DeleteModalProps) => {
  const { i18n } = useContext(gammaContext);

  const defaultHeader = i18n
    .formatString(i18n.modals.delete.header, entityType ?? '')
    .toString();

  const defaultDescription = i18n
    .formatString(i18n.modals.delete.description, entityName ?? '')
    .toString();

  return (
    <ConfirmationModal
      title={title ?? defaultHeader}
      body={
        body ?? (
          <Center>
            <Text data-testid={`${dataTestId}-description`}>
              {defaultDescription}
            </Text>
          </Center>
        )
      }
      confirmText={confirmText ?? i18n.globals.delete}
      confirmProps={{
        ...confirmProps,
        variant: 'solid',
        colorScheme: 'red',
      }}
      data-testid={dataTestId}
      {...props}
    >
      {children}
    </ConfirmationModal>
  );
};
