import { createIcon } from '@chakra-ui/react';

export const DashboardsIcon = createIcon({
  displayName: 'DashboardsIcon',
  viewBox: '0 0 24 24',
  d: 'M13 3v6h8V5a2 2 0 0 0-2-2h-6Zm0 18h6a2 2 0 0 0 2-2v-8h-8v10ZM3 19a2 2 0 0 0 2 2h6v-6H3v4Zm0-6h8V3H5a2 2 0 0 0-2 2v8Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
