import { Button, ButtonGroup, useDisclosure, useToast } from '@chakra-ui/react';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { LIST_USERS, useDeleteUsers } from '@gamma/investigator/queries';
import { Modal } from '@gamma/overlay';
import { ReactNode, useContext } from 'react';

interface DeleteUserModalProps {
  /** The trigger element for a delete button */
  children: ReactNode;
  /** Array of usernames to be deleted */
  users: string[];
}

export const DeleteUserModal = ({ children, users }: DeleteUserModalProps) => {
  const { user } = useContext(AuthContext);
  const { buttons, modals } = i18n.pages.usersAccess;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const showToastMessage = useToast();
  const [deleteUsers, { loading: deleteUserLoading }] = useDeleteUsers({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: LIST_USERS,
        variables: { tenants: [`${user?.attributes['custom:tenant_id']}`] },
      },
    ],
    onCompleted: ({ deleteUsers }) => {
      const { message } = deleteUsers?.body;

      onClose();

      showToastMessage({
        status: 'success',
        title: 'Success',
        description: message,
        isClosable: true,
        position: 'bottom-right',
      });
    },
    onError: (error: Error) => {
      const { message } = error;

      onClose();

      showToastMessage({
        status: 'error',
        title: '',
        description: message,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  const handleDeleteUserClick = () => {
    deleteUsers({
      variables: {
        usernames: users,
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      isCentered
      closeOnEsc={!deleteUserLoading}
      closeOnOverlayClick={!deleteUserLoading}
      isCloseDisabled={deleteUserLoading}
      title={users.length > 1 ? modals.bulkDelete.title : modals.delete.title}
      body={users.length > 1 ? modals.bulkDelete.body : modals.delete.body}
      data-testid="delete-user-modal"
      footer={
        <ButtonGroup spacing={4}>
          <Button
            variant="solid"
            colorScheme="red"
            isLoading={deleteUserLoading}
            onClick={handleDeleteUserClick}
            data-testid="delete-users-button"
          >
            {buttons.delete}
          </Button>
        </ButtonGroup>
      }
    >
      {children}
    </Modal>
  );
};
