import { Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { Checkbox } from '@gamma/form-fields';
import { MouseEvent } from 'react';
import { CellProps, Hooks, Row } from 'react-table';

export interface UseTableSelectionColumnParams<
  DataType extends Record<string, unknown>,
> {
  rowIsSelectable?: (row: Row<DataType>) => boolean | string;
  isSelectable?: boolean;
}

export const useTableSelectionColumn =
  <DataType extends Record<string, unknown>>({
    isSelectable,
    rowIsSelectable = () => true,
  }: UseTableSelectionColumnParams<DataType>) =>
  (hooks: Hooks<DataType>) => {
    if (isSelectable) {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          disableResizing: true,
          minWidth: 40,
          width: 40,
          maxWidth: 40,
          padding: 0,
          Header: (headerProps) => {
            let allNonDisabledRowsChecked = false;
            const nonDisabledRows = headerProps.page.filter((row) =>
              rowIsSelectable?.(row),
            );

            if (nonDisabledRows.length) {
              allNonDisabledRowsChecked = !nonDisabledRows.some(
                (row) => !row.isSelected,
              );
            }

            const { checked, indeterminate, ...rest } =
              headerProps.getToggleAllPageRowsSelectedProps({
                checked: allNonDisabledRowsChecked,
                indeterminate: Boolean(
                  !allNonDisabledRowsChecked &&
                    nonDisabledRows.some((row) => row.isSelected),
                ),
                onChange: () => {
                  headerProps.toggleAllPageRowsSelected(
                    !allNonDisabledRowsChecked,
                  );
                },
              });
            return (
              <Checkbox
                isChecked={checked}
                isIndeterminate={indeterminate}
                data-testid="data-table-select-all"
                isLabelHidden
                {...rest}
              />
            );
          },
          Cell: (cellProps: CellProps<DataType>) => {
            const { checked, indeterminate, ...rest } =
              cellProps.row.getToggleRowSelectedProps();
            const isRowSelectable = rowIsSelectable?.(cellProps.row);
            if (typeof isRowSelectable === 'string') {
              return (
                <Popover trigger="hover" placement="auto">
                  <PopoverTrigger>
                    <Checkbox
                      isChecked={false}
                      isDisabled={true}
                      isLabelHidden
                      {...rest}
                      data-testid="data-table-select-row"
                    />
                  </PopoverTrigger>
                  <PopoverContent px={4} py={3} w="auto">
                    {isRowSelectable}
                  </PopoverContent>
                </Popover>
              );
            }
            return (
              <Checkbox
                isChecked={isRowSelectable && checked}
                isIndeterminate={indeterminate}
                isDisabled={!isRowSelectable}
                isLabelHidden
                {...rest}
                onClick={(e: MouseEvent) => {
                  e.stopPropagation();
                }}
                data-testid="data-table-select-row"
              />
            );
          },
        },
        ...columns,
      ]);
      hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
        // fix the parent group of the selection button to not be resizable
        const selectionGroupHeader = headerGroups[0].headers[0];
        selectionGroupHeader.canResize = false;
      });
    }
  };
