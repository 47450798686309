import { useEffect } from 'react';
import { TableInstance } from 'react-table';
import { useDataTableContext } from '../useDataTableContext';

export type UseDataTableContextSetterOptions = {
  isEnabled?: boolean;
};

/**
 * this hook is used for passing reactive state from the table instance back up
 * to DataTablePanel – from DTP we have access to the ref, but the state it
 * contains is not reactive, as in, doesn't cause re-renders.
 */
export const useDataTableContextSetter = <DataType extends {}>(
  tableInstance: TableInstance<DataType>,
  { isEnabled }: UseDataTableContextSetterOptions = { isEnabled: true },
) => {
  const {
    totalLength: [, setTotalLength],
    curPageLength: [, setCurPageLength],
    filteredLength: [, setFilteredLength],
    columns: [, setColumns],
  } = useDataTableContext<DataType>();

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    setTotalLength(tableInstance.rows.length);
    setCurPageLength(tableInstance.page.length);
    setFilteredLength(tableInstance.sortedRows.length);
  }, [tableInstance.rows, tableInstance.page, tableInstance.sortedRows]);

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    // - Note: this effect isn't exactly going to update whenever columns
    // change. react-table doesn't change the columns reference when state
    // within them changes. If you need that behavior you might need to do some
    // additional tweaking to these deps.
    // - this needs to be a new array when it's set to propertly trigger
    // stateful effects that are using it.
    setColumns([...tableInstance.columns]);
  }, [tableInstance.visibleColumns]);
};
