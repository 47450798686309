import { createIcon } from '@chakra-ui/react';

export const UploadIcon = createIcon({
  displayName: 'UploadIcon',
  viewBox: '0 0 24 24',
  d: 'M9 16v-6H5l7-7 7 7h-4v6H9Zm-4 4v-2h14v2H5Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
