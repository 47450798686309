import { ApolloError } from '@apollo/client';
import {
  Button,
  Flex,
  Heading,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  Portal,
  Text,
  useToast,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { i18n } from '@gamma/investigator/localization';
import { SantizedDetection } from '@gamma/investigator/pages/detections';
import {
  AlertEntity,
  Detection,
  IQueryAlertMetadataExcludedEntitiesPaginated,
  QUERY_ALERT_METADATA_EXCLUDED_ENTITIES_PAGINATED,
  useAddExcludedEntitiesToAlertMetadata,
  useRemoveExcludedEntitiesFromAlertMetadata,
} from '@gamma/investigator/queries';
import { Alert } from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const { excludeEntity } = i18n.pages.entityDetails;

const {
  suppressMsg,
  unSuppressMsg,
  suppress,
  unSuppress,
  willReceiveAlerts,
  willNotReceiveAlerts,
} = excludeEntity;

const {
  success: successToast,
  error: errorToast,
  addEntitySuccessBody,
  removeEntitySuccessBody,
  addEntityErrorBody,
  removeEntityErrorBody,
} = i18n.pages.detections.toasts;

interface SuppressEntityContentProps {
  onClose?: () => void;
  refetchMain?: boolean;
  detectionData?: Detection | SantizedDetection;
  entityInfo?: Pick<AlertEntity, 'entity_name' | 'entity_type'>;
  excludedEntitiesData?: IQueryAlertMetadataExcludedEntitiesPaginated;
  excludedEntitiesError?: ApolloError;
  setIsEntitySupressedPassed?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSuppressed?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SuppressEntityContent = ({
  onClose,
  entityInfo,
  refetchMain,
  setIsLoading,
  detectionData,
  setIsSuppressed,
  excludedEntitiesData,
  excludedEntitiesError,
  setIsEntitySupressedPassed,
}: SuppressEntityContentProps) => {
  const showToastMessage = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  const [suppressedMessage, setSuppressedMessageX] = useState<string>('');
  const [isEntitySuppressed, setIsEntitySupressed] = useState<
    boolean | undefined
  >(false);

  const { alert_entity } = detectionData || {};
  const { entity_name, entity_type } = entityInfo || alert_entity || {};

  const variables = useMemo(() => {
    return {
      query:
        entity_type === 'DOMAIN'
          ? `{"query": {"bool": {"must": [{"match": {"content_id": "${
              detectionData?.alert_info?.content_id
            }"}}, {"term": {"entity_name.keyword": "${entity_name}"}}, {"term": {"entity_type": "${entity_type}"}}${
              detectionData?.tenant_info?.tenant_id ? ',' : ''
            }${
              detectionData?.tenant_info?.tenant_id
                ? `{"terms":{"tenant":["${detectionData?.tenant_info?.tenant_id}"]}}`
                : ''
            }]}}}`
          : `{"query": {"bool": {"must": [{"match": {"content_id": "${
              detectionData?.alert_info?.content_id
            }"}}, {"term": {"entity_type": "${entity_type}"}}, {"bool": {"should": [{"term": {"entity_ip": "${entity_name}"}}, {"range": {"entity_ip_range": {"from": "${entity_name}", "to": "${entity_name}"}}}]}}${
              detectionData?.tenant_info?.tenant_id ? ',' : ''
            }${
              detectionData?.tenant_info?.tenant_id
                ? `{"terms":{"tenant":["${detectionData?.tenant_info?.tenant_id}"]}}`
                : ''
            }]}}}`,
      offset: 0,
      size: 10,
      sort: [
        {
          sort_by: 'entity_name.keyword',
          sort_dir: 'desc',
        },
      ],
    };
  }, [detectionData, entity_name, entity_type]);

  const input = {
    entity_name,
    entity_type,
    tenant: detectionData?.tenant_info?.tenant_id,
    content_id: detectionData?.alert_info?.content_id,
    alert_name: detectionData?.alert_info?.alert_name,
    alert_type: detectionData?.alert_info?.alert_type,
  };

  const [addEntity, { loading: addLoading }] =
    useAddExcludedEntitiesToAlertMetadata({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: QUERY_ALERT_METADATA_EXCLUDED_ENTITIES_PAGINATED,
          variables,
        },
      ],
      onCompleted: () => {
        showToastMessage({
          status: 'success',
          title: successToast,
          description: i18n.formatString(
            addEntitySuccessBody,
            entity_name as string,
          ),
          isClosable: true,
          position: 'bottom-right',
        });
        onClose && onClose();
        if (refetchMain) {
          setSearchParams((params) => {
            params.set('refetch_main', 'true');
            return params;
          });
        }
      },
      onError: () => {
        showToastMessage({
          status: 'error',
          title: errorToast,
          description: i18n.formatString(
            addEntityErrorBody,
            entity_name as string,
          ),
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const [removeEntity, { loading: removeLoading }] =
    useRemoveExcludedEntitiesFromAlertMetadata({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: QUERY_ALERT_METADATA_EXCLUDED_ENTITIES_PAGINATED,
          variables,
        },
      ],
      onCompleted: () => {
        showToastMessage({
          status: 'success',
          title: successToast,
          description: i18n.formatString(
            removeEntitySuccessBody,
            entity_name as string,
          ),
          isClosable: true,
          position: 'bottom-right',
        });
        onClose && onClose();
        if (refetchMain) {
          setSearchParams((params) => {
            params.set('refetch_main', 'true');
            return params;
          });
        }
      },
      onError: (err: any) => {
        console.log('err', err);
        showToastMessage({
          status: 'error',
          title: errorToast,
          description: i18n.formatString(
            removeEntityErrorBody,
            entity_name as string,
          ),
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const handleAddSubmit = () => {
    addEntity({
      variables: {
        items: [input],
      },
    });
  };

  const handleRemoveSubmit = () => {
    removeEntity({
      variables: {
        items: [input],
      },
    });
  };

  const entityIsSuppressed = useMemo(() => {
    return (
      excludedEntitiesData &&
      excludedEntitiesData?.queryAlertMetadataExcludedEntitiesPaginated
        ?.excluded_entities?.length > 0
    );
  }, [excludedEntitiesData]);

  useEffect(() => {
    if (
      entity_name &&
      isEntitySuppressed &&
      detectionData?.alert_info?.alert_name
    ) {
      const message = i18n.formatString(
        unSuppressMsg,
        <Text as="strong">{entity_name ?? ''}</Text>,
        <Text as="strong">{detectionData?.alert_info?.alert_name ?? ''}</Text>,
      );
      setSuppressedMessageX(message as string);
    } else {
      const message = i18n.formatString(
        suppressMsg,
        <Text as="strong">{entity_name ?? ''}</Text>,
        <Text as="strong">{detectionData?.alert_info?.alert_name ?? ''}</Text>,
      );
      setSuppressedMessageX(message as string);
    }
  }, [
    entity_name,
    isEntitySuppressed,
    setIsEntitySupressed,
    excludedEntitiesData,
    detectionData?.alert_info?.alert_name,
  ]);

  useEffect(() => {
    setIsSuppressed && setIsSuppressed(!!entityIsSuppressed);
    setIsEntitySupressed(entityIsSuppressed);
    if (setIsEntitySupressedPassed) {
      setIsEntitySupressedPassed(!!entityIsSuppressed);
    }
  }, [
    entityIsSuppressed,
    setIsEntitySupressed,
    setIsEntitySupressedPassed,
    setIsSuppressed,
  ]);

  useEffect(() => {
    if (setIsLoading) {
      setIsLoading(addLoading || removeLoading);
    }
  }, [setIsLoading, addLoading, removeLoading]);

  if (excludedEntitiesError) {
    return (
      <GraphQLReqStatus
        loading={false}
        isBackground={true}
        error={excludedEntitiesError}
      ></GraphQLReqStatus>
    );
  }

  return (
    <Portal>
      <PopoverContent w="320px" data-testid="suppress-entity-popover-content">
        <PopoverCloseButton top={1}>
          <MuiIcon size="sm">close</MuiIcon>
        </PopoverCloseButton>
        <PopoverHeader>
          <Heading
            textStyle="body-md-bold"
            data-testid="suppress-entity-popover-heading"
          >
            {isEntitySuppressed ? unSuppress : suppress}
          </Heading>
        </PopoverHeader>
        <PopoverBody>
          <Text mb={2}>{suppressedMessage}</Text>
          <Alert variant="subtle" status="warning">
            {isEntitySuppressed ? willReceiveAlerts : willNotReceiveAlerts}
          </Alert>
        </PopoverBody>
        <PopoverFooter alignItems="end">
          <Flex justifyContent="end">
            <Button
              variant="solid"
              colorScheme="blue"
              isLoading={addLoading || removeLoading}
              data-testid="suppress-entity-submit"
              onClick={() => {
                if (isEntitySuppressed) {
                  handleRemoveSubmit?.();
                } else {
                  handleAddSubmit?.();
                }
              }}
            >
              {isEntitySuppressed ? unSuppress : suppress}
            </Button>
          </Flex>
        </PopoverFooter>
      </PopoverContent>
    </Portal>
  );
};
