import { GammaProvider } from '@gamma/theme';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { components } from './app/theme';
import { Root } from './Root';

const theme = {
  components,
  colors: {
    blue: {
      alpha500: {
        10: '#0F62FE10',
      },
    },
  },
  styles: {
    global: {
      iframe: {
        pb: '5',
      },
      '#chakra-toast-manager-bottom-right': {
        marginBottom: '80px',
      },
      '.chakra-popover__popper': {
        zIndex: '1500 !important',
      },
    },
  },
};

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <StrictMode>
    <GammaProvider
      theme={theme}
      fontPath={`${process.env.NX_INVESTIGATOR_BASE_HREF ?? '/'}assets/fonts/`}
    >
      <Root />
    </GammaProvider>
  </StrictMode>,
);
