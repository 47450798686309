import { Skeleton, VStack } from '@chakra-ui/react';
import _ from 'lodash';

export interface SkeletonLoaderProps {
  rowCount?: number;
}

export const SkeletonLoader = ({ rowCount = 5 }: SkeletonLoaderProps) => {
  return (
    <VStack alignItems="start" data-testid="chat-gpt-skeleton-loader">
      {_.range(rowCount).map((_, index) => (
        <Skeleton
          data-testid="chat-gpt-skeleton-loader-row"
          w={`calc(100% - ${Math.floor(Math.random() * 100)}%)`}
          key={index}
          borderRadius="0px"
          height="16px"
        />
      ))}
    </VStack>
  );
};
