import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

import { PaginatedDetections } from './types';

export const ASSIGN_DETECTIONS = gql`
  mutation assignDetections($items: [AssignDetectionsInput!]!) {
    assignDetections(items: $items) {
      detections {
        alert_entity {
          entity_id
          entity_name
          entity_type
        }
        alert_info {
          alert_name
          alert_type
          content_id
        }
        assignment_info {
          assigned_at
          assigned_to_username
          assigned_to_user_alias
        }
        created_timestamp
        detection_id
        detection_status
        detection_timestamp {
          end
          start
        }
        earliest_start_timestamp
        latest_start_timestamp
        mitre_mappings {
          tactics {
            link
            name
            tag
            techniques {
              link
              name
              subtechniques {
                link
                name
                tag
              }
              tag
            }
          }
        }
        rank {
          severity
        }
        total_alert_count
        update_info {
          last_updated_by
          last_updated_by_username
          last_updated_timestamp
        }
        escalation_data {
          escalation_status
          escalated_by_user_alias
          escalated_timestamp
          escalation_service_name
        }
      }
      offset
      size
      sort {
        sort_by
        sort_dir
      }
      total_items
    }
  }
`;

export const useAssignDetections = (
  options: MutationHookOptions<PaginatedDetections, OperationVariables>,
) => useMutation(ASSIGN_DETECTIONS, options);
