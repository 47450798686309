import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import {
  IQueryEscalationConfigurations,
  ServiceNowEscalationConfiguration,
} from './types';

export const QUERY_ESCALATION_CONFIGURATIONS_PUBLIC = gql`
  query queryEscalationConfigurations(
    $tenant: String
    $filter: EscalationConfigurationFilterInput
    $offset: Int
    $size: Int
    $sort: [SortingParameterInput]
  ) {
    queryEscalationConfigurations(
      tenant: $tenant
      filter: $filter
      offset: $offset
      size: $size
      sort: $sort
    ) {
      escalation_configurations {
        enabled
        escalation_configuration_id
        service_name
        ... on ServiceNowEscalationConfiguration {
          enabled
          escalation_configuration_id
          service_name
        }
      }
      filter {
        enabled
        escalation_configuration_ids
        service_names
      }
      offset
      size
      sort {
        sort_by
        sort_dir
      }
      total_items
    }
  }
`;

export const useQueryEscalationConfigurationsPublic = (
  options: QueryHookOptions<
    IQueryEscalationConfigurations<ServiceNowEscalationConfiguration>,
    OperationVariables
  >,
) => {
  return useQuery<
    IQueryEscalationConfigurations<ServiceNowEscalationConfiguration>
  >(QUERY_ESCALATION_CONFIGURATIONS_PUBLIC, options);
};
