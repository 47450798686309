import {
  gql,
  LazyQueryHookOptions,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client';

import { IGetNicHistoryForEndpointDeviceEntity } from './types';

export const GET_NIC_HISTORY_FOR_ENDPOINT_DEVICE_ENTITY = gql`
  query getNicHistoryForEndpointDeviceEntity(
    $tenant: String
    $as_of: Float
    $entity: EntityIdInput
    $offset: Int
    $size: Int
    $max_results: Int
    $use_cache: Boolean
    $sort: [SortParameterInput]
  ) {
    getNicHistoryForEndpointDeviceEntity(
      tenant: $tenant
      as_of: $as_of
      entity: $entity
      offset: $offset
      size: $size
      max_results: $max_results
      use_cache: $use_cache
      sort: $sort
    ) {
      as_of
      max_results
      nic_histories {
        data_source
        ip_address
        mac_address
        obsts
      }
      offset
      size
      total_items
      use_cache
    }
  }
`;

export const useGetNicHistoryForEndpointDeviceEntity = (
  options: LazyQueryHookOptions<
    IGetNicHistoryForEndpointDeviceEntity,
    OperationVariables
  >,
) =>
  useLazyQuery<IGetNicHistoryForEndpointDeviceEntity>(
    GET_NIC_HISTORY_FOR_ENDPOINT_DEVICE_ENTITY,
    options,
  );
