import { createIcon } from '@chakra-ui/react';

export const CheckeredShieldIcon = createIcon({
  displayName: 'CheckeredShieldIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 .923 3 4.951v6.042c0 5.589 3.84 10.815 9 12.084 5.16-1.269 9-6.495 9-12.084V4.951L12 .923Zm0 11.067h7c-.53 4.149-3.28 7.844-7 9.002V12H5V6.26l7-3.132v8.862Z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
