import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

import { UserSuccessOutput } from './types';

export interface IActivateUserAccount {
  activateUserAccount: UserSuccessOutput;
}

export const ACTIVATE_USER_ACCOUNT = gql`
  mutation activateUserAccount {
    activateUserAccount {
      status_code
      body {
        data
        message
        success
      }
    }
  }
`;

export const useActivateUserAccount = (
  options?: MutationHookOptions<IActivateUserAccount, OperationVariables>,
) => {
  return useMutation(ACTIVATE_USER_ACCOUNT, options);
};
