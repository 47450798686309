import { Alert, AlertIcon, Button, Stack, Text } from '@chakra-ui/react';
import { CTAButton } from '@gamma/buttons';
import { TextDivider } from '@gamma/display';
import { ILoginForm, LoginForm } from '@gamma/forms';
import { MFA, ROUTES } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { setRole } from '@gamma/investigator/utilities';
import { ErrorBoundary } from '@gamma/overlay';
import { CardPageBody, CardPageCTA, CardPageHeading } from '@gamma/pages';
import { LoadingSpinner } from '@gamma/progress';
import { AuthState, CognitoUserInterface } from '@gamma/shared/types';
import { Auth } from 'aws-amplify';
import { useContext, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

interface ILogin {
  setPreferredMFA: (e: string) => void;
}

const { home, base } = ROUTES;

export const signIn = async ({ username, password }: ILoginForm) => {
  try {
    const user: CognitoUserInterface = await Auth.signIn(username, password);
    return user;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const Login = ({ setPreferredMFA }: ILogin) => {
  const { authState, user, setAuthState, setUser, setUserRole } =
    useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();

  const getErrorAlert = (error: string) => {
    switch (error) {
      case 'User is disabled.':
        return (
          <Alert status="error" variant="solid" mt={4}>
            <AlertIcon />
            <p>
              This account is disabled please contact&nbsp;
              <Text as="u">
                <a
                  href={'https://corelight.com/support'}
                  target="_blank"
                  rel="noreferrer"
                >
                  support
                </a>
              </Text>
            </p>
          </Alert>
        );
      default:
        return (
          <Alert status="error" variant="solid" mt={4}>
            <AlertIcon />
            {error}
          </Alert>
        );
    }
  };

  const routerLocation = useLocation();

  const handleSignIn = async (values: ILoginForm) => {
    setIsLoading(true);
    try {
      const user = await signIn(values);
      setPreferredMFA(user?.preferredMFA);
      if (user) {
        switch (user.challengeName) {
          case 'NEW_PASSWORD_REQUIRED':
            navigate(
              `/registration?email=${user.username}&alias=${user.challengeParam.userAttributes['custom:alias']}`,
              {
                replace: true,
                state: { user: values },
              },
            );
            break;
          case 'SOFTWARE_TOKEN_MFA':
            navigate(`mfa${routerLocation.search}`);
            setUser?.(user);
            break;
          default:
            setAuthState?.(AuthState.SignedIn);
            setUser?.(user);
            setRole({ user, setUserRole });
            // redirecting directly to root page when preferredMFA is NOMFA
            if (user?.preferredMFA === MFA.NOMFA) {
              navigate(base, { replace: true });
            }
            break;
        }
      }
    } catch (error: any) {
      console.error(error.message);
      setError(error.message);
    }

    setIsLoading(false);
  };

  if (authState === AuthState.SignedIn && user) {
    const urlParams = new URLSearchParams(routerLocation.search);
    const next = urlParams.get('next');
    const url = next ? next : home;
    return <Navigate to={url} replace />;
  }

  const { title, troubleSigningIn, cta, ssoSignIn, orText } = i18n.pages.login;

  return (
    <ErrorBoundary page="Login page" styleClass="main">
      <CardPageHeading>
        {i18n.formatString(title, {
          appName: <strong>{i18n.app.name}</strong>,
        })}
      </CardPageHeading>
      <CardPageBody>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <LoginForm
            onSubmit={[handleSignIn]}
            usernameType="email"
            autoFocusField="username"
            usernameContent={{
              placeholder: i18n.forms.labels.username,
              label: i18n.forms.labels.username,
            }}
            passwordContent={{
              placeholder: i18n.forms.labels.password,
              label: i18n.forms.labels.password,
            }}
            dataTestId="login"
          />
        )}
      </CardPageBody>
      {error && getErrorAlert(error)}
      <CardPageCTA>
        <Stack w="full" spacing={4}>
          <CTAButton
            data-testid="investigator-app-login-button"
            form="loginForm"
            type="submit"
            isLoading={isLoading}
          >
            {cta}
          </CTAButton>
          <TextDivider>{orText}</TextDivider>
          <CTAButton
            data-testid="investigator-oidc-login-button"
            type="button"
            onClick={() =>
              navigate(`${ROUTES.ssoLogin}${routerLocation.search}`)
            }
          >
            {ssoSignIn}
          </CTAButton>

          <Button
            variant="link"
            size="md"
            color="text.link"
            fontWeight="normal"
            onClick={() => navigate('trouble')}
          >
            {troubleSigningIn}
          </Button>
        </Stack>
      </CardPageCTA>
    </ErrorBoundary>
  );
};
