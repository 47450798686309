import { AuthContext } from '@gamma/investigator/context';
import { ErrorBoundary } from '@gamma/overlay';
import { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

export const Admin = () => {
  const navigate = useNavigate();
  const { user, userRole } = useContext(AuthContext);

  useEffect(() => {
    if (!user || userRole !== 'admin') {
      navigate('/404');
    }
  }, [user, userRole]);

  return (
    <ErrorBoundary page="Admin" styleClass="main">
      <Outlet />
    </ErrorBoundary>
  );
};
