import { XMLParser } from 'fast-xml-parser';
import { useState } from 'react';

interface useIDPUploadXMLHandlerProps {
  setFileError?: any;
  setParsedData?: any;
}

export const useIDPUploadXMLHandler = ({
  setFileError,
  setParsedData,
}: useIDPUploadXMLHandlerProps) => {
  const options = {
    ignoreAttributes: false,
  };

  const xmlParser = new XMLParser(options);

  const [xml, setXml] = useState('');

  const readData = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsText(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const convertBase64 = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const uploadFile = async (e: File[]) => {
    const file = e[0];
    const xmlFile = file ? ((await readData(file)) as string) : '';
    const base64Xml = xmlFile ? ((await convertBase64(file)) as string) : ' ';
    const encodedXml = base64Xml.replace('data:text/xml;base64,', '');
    if (xmlFile) {
      autoFill(xmlFile);
    }
    if (encodedXml) {
      setXml(encodedXml);
    }
    return e;
  };

  const parseIDPXml: (xmlString: string) => Record<string, string> = (
    xmlString: string,
  ) => {
    const xml = xmlParser.parse(xmlString);

    const entityId =
      xml?.EntityDescriptor?.['@_entityID'] ??
      xml?.['md:EntityDescriptor']?.['@_entityID'];

    const signonUrl =
      xml?.EntityDescriptor?.IDPSSODescriptor?.SingleSignOnService?.[0]?.[
        '@_Location'
      ] ??
      xml?.['md:EntityDescriptor']?.['md:IDPSSODescriptor']?.[
        'md:SingleSignOnService'
      ]?.[0]?.['@_Location'];

    const certificate =
      xml?.EntityDescriptor?.IDPSSODescriptor?.KeyDescriptor?.KeyInfo?.X509Data
        ?.X509Certificate ??
      xml?.['md:EntityDescriptor']?.['md:IDPSSODescriptor']?.[
        'md:KeyDescriptor'
      ]?.['ds:KeyInfo']?.['ds:X509Data']?.['ds:X509Certificate'];

    return { entity_id: entityId, signon_url: signonUrl, certificate };
  };

  const autoFill = (xmlString: string) => {
    const parsedData = parseIDPXml(xmlString);
    if (
      !parsedData?.entity_id ||
      !parsedData?.signon_url ||
      !parsedData?.certificate
    ) {
      setParsedData({});
      return setFileError('Invalid File');
    }

    setParsedData(parsedData);
  };

  return { parseIDPXml, autoFill, uploadFile, readData, xml };
};
