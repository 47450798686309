import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverTrigger,
  Tooltip,
  useBoolean,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { i18n } from '@gamma/investigator/localization';
import {
  Detection,
  useQueryEscalationConfigurationsPublic,
} from '@gamma/investigator/queries';
import { useEffect, useState } from 'react';

import { IGetDetectionVariables } from '..';
import { DetectionQueryVariables } from '../..';
import {
  CloseDetectionContent,
  SendToContent,
} from '../DetectionActions/PopoverContent';

const { excludeEntity } = i18n.pages.entityDetails;

const { detectionIsClosed } = excludeEntity;

const { close, controls, detectionIsSent, sendTo, SNConfigureAndEnable } =
  i18n.pages.detections;

const { editStatus } = controls;

interface DetectionStatusActionsMenuProps {
  refetchMain?: boolean;
  detectionData?: Detection;
  isStatusHovered?: boolean;
  getDetectionVariables?: IGetDetectionVariables;
  queryDetectionsPaginatedVariables?: DetectionQueryVariables;
}

export const DetectionStatusActionsMenu = ({
  refetchMain,
  detectionData,
  isStatusHovered,
  getDetectionVariables,
  queryDetectionsPaginatedVariables,
}: DetectionStatusActionsMenuProps) => {
  const [isEditing, setIsEditing] = useBoolean();

  const [popoverType, setPopoverType] = useState<null | string>(null);

  const [closeLoading, setCloseLoading] = useState<boolean>(false);
  const [sendToLoading, setSendToLoading] = useState<boolean>(false);

  const [isClosed, setIsClosed] = useState<boolean>(false);
  const [isSentTo, setIsSentTo] = useState<boolean>(false);

  const {
    loading: escalationConfigurationsLoading,
    error: escalationConfigurationsError,
    data: escalationConfigurationsData,
  } = useQueryEscalationConfigurationsPublic({
    skip: !detectionData?.tenant_info?.tenant_id,
    variables: {
      tenant: detectionData?.tenant_info?.tenant_id,
    },
  });

  const serviceNowConfiguration =
    escalationConfigurationsData?.queryEscalationConfigurations
      ?.escalation_configurations?.[0];

  useEffect(() => {
    setIsClosed(detectionData?.detection_status !== 'open');
    setIsSentTo(!!detectionData?.escalation_data?.escalation_status);
  }, [detectionData]);

  return (
    <Box>
      <Popover
        offset={[0, 10]}
        closeOnEsc={true}
        closeOnBlur={true}
        isOpen={isEditing}
        onOpen={() => {
          setIsEditing.on();
        }}
        onClose={() => {
          setIsEditing.off();
          setPopoverType(null);
        }}
        placement="bottom-start"
      >
        {isEditing || isStatusHovered ? (
          <Menu
            onClose={() => {
              if (popoverType === null) {
                setIsEditing.off();
              }
            }}
            closeOnSelect={false}
          >
            <PopoverTrigger>
              <span>
                <Tooltip textStyle="body-md" label={editStatus}>
                  <MenuButton
                    as={IconButton}
                    w="24px"
                    h="24px"
                    size="box-md"
                    variant="solid"
                    colorScheme="gray"
                    data-testid="suppress-entity-popover-content-trigger"
                    aria-label={editStatus}
                    disabled={
                      (isClosed && isSentTo) ||
                      closeLoading ||
                      sendToLoading ||
                      escalationConfigurationsLoading
                    }
                  >
                    <MuiIcon size="sm">edit</MuiIcon>
                  </MenuButton>
                </Tooltip>
              </span>
            </PopoverTrigger>
            {popoverType === null && isEditing && (
              <MenuList>
                <Tooltip
                  offset={[-9, 6]}
                  placement="left-start"
                  shouldWrapChildren={true}
                  label={isClosed ? detectionIsClosed : null}
                >
                  <MenuItem
                    isDisabled={isClosed}
                    data-testid="close-detection-trigger"
                    onClick={() => {
                      setPopoverType('close');
                    }}
                    icon={<MuiIcon>check_circle</MuiIcon>}
                  >
                    {close}
                  </MenuItem>
                </Tooltip>
                <Tooltip
                  offset={[-9, 6]}
                  placement="left-start"
                  shouldWrapChildren={true}
                  label={
                    isSentTo
                      ? detectionIsSent
                      : !sendToLoading &&
                        !escalationConfigurationsLoading &&
                        !serviceNowConfiguration?.enabled
                      ? SNConfigureAndEnable
                      : null
                  }
                >
                  <MenuItem
                    isDisabled={
                      isSentTo ||
                      !serviceNowConfiguration ||
                      !serviceNowConfiguration?.enabled
                    }
                    data-testid="send-to-trigger"
                    onClick={() => {
                      setPopoverType('sendTo');
                    }}
                    icon={<MuiIcon>forward</MuiIcon>}
                  >
                    {sendTo}
                  </MenuItem>
                </Tooltip>
              </MenuList>
            )}
          </Menu>
        ) : null}
        {popoverType === 'close' && isEditing && (
          <CloseDetectionContent
            refetchMain={refetchMain}
            onClose={setIsEditing.off}
            setIsClosed={setIsClosed}
            detectionData={detectionData}
            setIsLoading={setCloseLoading}
            setPopoverType={setPopoverType}
            getDetectionVariables={getDetectionVariables}
            queryDetectionsPaginatedVariables={
              queryDetectionsPaginatedVariables
            }
          />
        )}
        {popoverType === 'sendTo' && isEditing && (
          <SendToContent
            refetchMain={refetchMain}
            onClose={setIsEditing.off}
            setIsSentTo={setIsSentTo}
            detectionData={detectionData}
            setIsLoading={setSendToLoading}
            setPopoverType={setPopoverType}
            serviceNowConfiguration={serviceNowConfiguration}
            getDetectionVariables={getDetectionVariables}
            queryDetectionsPaginatedVariables={
              queryDetectionsPaginatedVariables
            }
          />
        )}
      </Popover>
    </Box>
  );
};
