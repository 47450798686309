import { createIcon } from '@chakra-ui/react';

export const SplitBottomIcon = createIcon({
  displayName: 'SplitBottomIcon',
  viewBox: '0 0 20 20',
  path: [
    <mask id="a" fill="currentColor">
      <path
        fillRule="evenodd"
        d="M16 10.8H4V16h12v-5.2Zm-14-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8.8Z"
        clipRule="evenodd"
      />
    </mask>,
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 10.8H4V16h12v-5.2Zm-14-2V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8.8Z"
      clipRule="evenodd"
    />,
    <path
      fill="currentColor"
      d="M16 10.8h2v-2h-2v2Zm-12 0v-2H2v2h2ZM4 16H2v2h2v-2Zm12 0v2h2v-2h-2Zm0-7.2H4v4h12v-4Zm-14 2V16h4v-5.2H2ZM4 18h12v-4H4v4Zm14-2v-5.2h-4V16h4ZM4 8.8v-4H0v4h4ZM0 4v.8h4V4H0Zm4-4a4 4 0 0 0-4 4h4V0Zm12 0H4v4h12V0Zm4 4a4 4 0 0 0-4-4v4h4Zm0 .8V4h-4v.8h4Zm-4 0v4h4v-4h-4ZM20 16V8.8h-4V16h4Zm-4 4a4 4 0 0 0 4-4h-4v4ZM4 20h12v-4H4v4Zm-4-4a4 4 0 0 0 4 4v-4H0Zm0-7.2V16h4V8.8H0Z"
      mask="url(#a)"
    />,
  ],
  defaultProps: {
    height: '20px',
    width: '20px',
  },
});
