import { Box, Flex, Stack, useColorModeValue } from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface CookiePolicyOverlayProps {
  isOpen: boolean;
  title: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  initialStep?: number;
  isCookieAccepted?: boolean | null;
}

export const CookiePolicyOverlay = ({
  title,
  children,
  footer,
  isOpen,
}: CookiePolicyOverlayProps) => {
  const borderColor = useColorModeValue('gray.50', 'gray.800');

  if (!isOpen) {
    return null;
  }

  return (
    <Box
      data-testid="cookie-policy-overlay"
      zIndex="overlay"
      position="sticky"
      bottom={6}
      left="70px"
      w="fit-content"
      m={2}
    >
      <Box
        p={6}
        bg="layer.2"
        borderRadius="base"
        border="solid 1px"
        borderColor={borderColor}
      >
        <Stack>
          {title}
          <Box overflowX="hidden" maxH="20rem" maxW="60rem">
            {children}
          </Box>
          <Flex justifyContent="flex-end">{footer}</Flex>
        </Stack>
      </Box>
    </Box>
  );
};
