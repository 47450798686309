import { HStack, Text } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { ReactNode } from 'react';

export type NavUserLabelProps = {
  username: string;
  isAdmin: boolean;
  children?: ReactNode;
};

export const NavUserLabel = ({
  username,
  isAdmin,
  children,
}: NavUserLabelProps) => {
  // @ts-ignore
  const isSSOLogin = JSON.parse(window.sessionStorage.getItem('isSSOLogin'));

  return (
    <HStack>
      {isAdmin ? (
        <MuiIcon color="text.primary">manage_accounts</MuiIcon>
      ) : (
        <MuiIcon color="text.primary">person</MuiIcon>
      )}
      <Text
        textStyle="body-md-bold"
        data-testid="gamma-nav-user-label-username"
      >
        {username}
      </Text>
      {children}
      {isSSOLogin && (
        <Text textStyle="body-md" data-testid="gamma-nav-user-label-sso">
          (SSO)
        </Text>
      )}
    </HStack>
  );
};
