import { createIcon } from '@chakra-ui/react';

export const SplitRightIcon = createIcon({
  displayName: 'SplitRightIcon',
  viewBox: '0 0 20 20',
  path: [
    <mask id="a" fill="currentColor">
      <path
        fillRule="evenodd"
        d="M10.8 4H16v12h-5.2V4Zm-2-2H16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2H8.8Z"
        clipRule="evenodd"
      />
    </mask>,
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.8 4H16v12h-5.2V4Zm-2-2H16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2H8.8Z"
      clipRule="evenodd"
    />,
    <path
      fill="currentColor"
      d="M16 4h2V2h-2v2Zm-5.2 0V2h-2v2h2ZM16 16v2h2v-2h-2Zm-5.2 0h-2v2h2v-2ZM16 2h-5.2v4H16V2Zm2 14V4h-4v12h4Zm-7.2 2H16v-4h-5.2v4Zm-2-14v12h4V4h-4ZM16 0H8.8v4H16V0Zm4 4a4 4 0 0 0-4-4v4h4Zm0 12V4h-4v12h4Zm-4 4a4 4 0 0 0 4-4h-4v4Zm-7.2 0H16v-4H8.8v4Zm0-4H5.6v4h3.2v-4ZM4 20h1.6v-4H4v4Zm-4-4a4 4 0 0 0 4 4v-4H0ZM0 4v12h4V4H0Zm4-4a4 4 0 0 0-4 4h4V0Zm1.6 0H4v4h1.6V0Zm0 4h3.2V0H5.6v4Z"
      mask="url(#a)"
    />,
  ],
  defaultProps: {
    height: '20px',
    width: '20px',
  },
});
