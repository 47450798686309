import { createIcon } from '@chakra-ui/react';

export const FileIcon = createIcon({
  displayName: 'FileIcon',
  viewBox: '0 0 24 24',
  d: 'M13 9V3.5L18.5 9H13ZM6 2c-1.11 0-2 .89-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6H6Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
