import { createIcon } from '@chakra-ui/react';

export const PencilIcon = createIcon({
  displayName: 'PencilIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.74 6.34c.347.329.347.906 0 1.253l-1.635 1.626-3.333-3.333 1.635-1.626c.347-.347.924-.347 1.253 0l2.08 2.08ZM4 20v-3.333l9.83-9.838 3.333 3.332L7.333 20H4Z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
