import { useLazyQuery } from '@apollo/client';
import { Button, ButtonGroup, Flex, Link, useToast } from '@chakra-ui/react';
import { URLS } from '@gamma/investigator/constants';
import { CookieContext } from '@gamma/investigator/context';
import { useLogOperationCall } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  GET_USER_COOKIE_ACCEPTANCE,
  useUserUpdateCookieStatus,
} from '@gamma/investigator/queries';
import { getIsCookieExpired } from '@gamma/investigator/utilities';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const { cookiePolicy } = i18n;
const { CORELIGHT_PRIVACY_POLICY } = URLS;

export interface CookiePolicyFooterProps {
  stepNumber: number;
  isCookieStillAccepted: boolean;
  isCookieAccepted?: boolean | null | undefined;
  setStepNumber: (step: number) => void;
}

export const CookiePolicyFooter = ({
  stepNumber,
  setStepNumber,
  isCookieStillAccepted,
  isCookieAccepted,
}: CookiePolicyFooterProps) => {
  const navigate = useNavigate();
  const logOperationCall = useLogOperationCall();

  const [isLoading, setIsLoading] = useState(false);

  const showToastMessage = useToast();

  const [userUpdateCookieStatus] = useUserUpdateCookieStatus();

  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(true);

  const [shouldInitPendo, setShouldInitPendo] = useState<boolean>(
    stepNumber === 1 ? true : isCookieStillAccepted,
  );

  const { getUserCookieAcceptance, setCookieConfig } =
    useContext(CookieContext);

  const { isCookiePolicyExpired, analyticsCookie } = getIsCookieExpired(
    getUserCookieAcceptance,
  );

  const [getUserCookieAcceptancePolicy] = useLazyQuery(
    GET_USER_COOKIE_ACCEPTANCE,
  );

  const newCookieStatus =
    stepNumber === 1 || isCookieStillAccepted ? 'ACCEPTED' : 'DENIED';

  const updates = isCookiePolicyExpired
    ? [
        {
          cookie_type: 'STRICTLY_NECESSARY',
          cookie_status: 'ACCEPTED',
        },
        {
          cookie_type: 'ANALYTICS',
          cookie_status: newCookieStatus,
        },
      ]
    : [
        {
          cookie_type: 'ANALYTICS',
          cookie_status: newCookieStatus,
        },
      ];

  useEffect(() => {
    setIsConfirmButtonDisabled(
      analyticsCookie?.cookies_accepted === isCookieStillAccepted &&
        !isCookiePolicyExpired,
    );
  }, [
    analyticsCookie?.cookies_accepted,
    isCookiePolicyExpired,
    isCookieStillAccepted,
  ]);

  useEffect(() => {
    setShouldInitPendo(stepNumber === 1 ? true : isCookieStillAccepted);
  }, [stepNumber, isCookieStillAccepted]);

  const handleCloseCookiePolicy = async () => {
    setIsLoading(true);
    setIsConfirmButtonDisabled(true);
    userUpdateCookieStatus({
      variables: {
        updates,
      },
      onCompleted: async () => {
        setIsLoading(false);
        const { data } = await getUserCookieAcceptancePolicy();

        setCookieConfig?.((prev) => ({
          ...prev,
          ...data,
          isOpen: false,
        }));

        const cookieStatusInput = { cookie_status: 'disabled' };
        logOperationCall(
          'COOKIE_UPDATE',
          'USER',
          JSON.stringify(cookieStatusInput),
        );

        if (
          getUserCookieAcceptance.length === 2 &&
          isCookieAccepted !== null &&
          isCookieAccepted !== undefined
        ) {
          const params = `analytics=${String(isCookieStillAccepted)}`;

          window.location.href = window.location.href + `?${params}`;
        }
      },
      onError: () => {
        setCookieConfig &&
          setCookieConfig((prev) => ({
            ...prev,
            isOpen: false,
          }));

        showToastMessage({
          status: 'error',
          title: cookiePolicy.toastMessages.error.title,
          description: cookiePolicy.toastMessages.error.description,
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });
  };

  return (
    <>
      {stepNumber === 1 && (
        <Flex justifyContent="space-between" flex="1" width="100%">
          <Button
            data-testid="cookie-policy-corelight-privacy-policy-button"
            textDecor="none"
            variant="link"
            color="text.link"
          >
            <Link
              data-testid="cookie-policy-corelight-privacy-policy-button-link"
              href={CORELIGHT_PRIVACY_POLICY}
              isExternal
            >
              {cookiePolicy.stepOne.buttons.privacyPolicy}
            </Link>
          </Button>
          <ButtonGroup variant="solid">
            <Button
              data-testid="cookie-policy-settings-button"
              colorScheme="gray"
              onClick={() => setStepNumber(2)}
            >
              {cookiePolicy.stepOne.buttons.cookieSettings}
            </Button>
            <Button
              data-testid="cookie-policy-confirm-button"
              colorScheme="blue"
              onClick={handleCloseCookiePolicy}
              isDisabled={isConfirmButtonDisabled}
              isLoading={isConfirmButtonDisabled}
            >
              {cookiePolicy.stepOne.buttons.acceptAllCookies}
            </Button>
          </ButtonGroup>
        </Flex>
      )}
      {stepNumber === 2 && (
        <Button
          data-testid="cookie-policy-confirm-button"
          size="md"
          variant="solid"
          colorScheme="blue"
          onClick={handleCloseCookiePolicy}
          mt={4}
          isDisabled={isConfirmButtonDisabled}
          isLoading={isLoading}
        >
          {cookiePolicy.stepTwo.buttons.confirmAndRefresh}
        </Button>
      )}
    </>
  );
};
