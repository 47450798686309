// setSeverityOnAlerts(items: [SetSeverityOnAlertsInput!]!): PaginatedAlertMetadata
import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

import { ISetSeverityOnAlertsInput } from './types';

export const SET_SEVERITY_ON_ALERTS = gql`
  mutation setSeverityOnAlerts($items: [SetSeverityOnAlertsInput!]!) {
    setSeverityOnAlerts(items: $items) {
      metadata {
        _score
        title
        severity
        active
        logsource {
          category
        }
        content_id
        tenant_alert
      }
      offset
      size
      total_items
    }
  }
`;

export interface ISetSeverityOnAlerts {
  setSeverityOnAlerts: ISetSeverityOnAlertsInput;
}

export interface ISetSeverityOnAlertsVariables extends OperationVariables {
  items: ISetSeverityOnAlertsInput[];
}

export const useSetSeverityOnAlerts = (
  options: MutationHookOptions<
    ISetSeverityOnAlerts,
    ISetSeverityOnAlertsVariables
  >,
) => {
  return useMutation<ISetSeverityOnAlerts, ISetSeverityOnAlertsVariables>(
    SET_SEVERITY_ON_ALERTS,
    options,
  );
};
