import { createIcon } from '@chakra-ui/react';

export const SyslogLogo = createIcon({
  displayName: 'SyslogLogo',
  viewBox: '0 0 69 24',
  path: (
    <text
      className="cl-exporter-logos"
      fillRule="evenodd"
      fontFamily="DINPro-Medium, DINPro"
      fontSize="24"
      fontWeight="400"
      transform="translate(0 -7)"
      fill="currentColor"
    >
      <tspan x="0" y="25">
        Syslog
      </tspan>
    </text>
  ),
  defaultProps: {
    height: '24px',
    width: '69px',
  },
});
