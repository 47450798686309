import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel as ChakraAccordionPanel,
  AccordionProps,
  chakra,
  ExpandedIndex,
} from '@chakra-ui/react';
import { ReactNode, useCallback, useEffect, useState } from 'react';

export interface AccordionPanelProps {
  title: ReactNode;
  defaultExpanded?: boolean;
  expanded?: boolean;
  children?: ReactNode;
  onChange?: AccordionProps['onChange'];
  ['data-testid']?: string;
  w?: string | number;
  layerStyle?: string;
  storageKey?: string;
  reduceMotion?: boolean;
  disableBorders?: boolean;
}

const layerBorderMap: Map<string, string> = new Map([
  ['base', 'border.layer.0'],
  ['first', 'border.layer.1'],
  ['second', 'border.layer.2'],
  ['third', 'border.layer.3'],
]);

export const AccordionPanel = ({
  title,
  defaultExpanded = false,
  expanded,
  onChange,
  children,
  w,
  layerStyle = 'first',
  storageKey,
  reduceMotion = false,
  disableBorders = false,
  'data-testid': dataTestId = 'gamma-accordion-panel',
}: AccordionPanelProps) => {
  const [isMounted, setIsMounted] = useState<boolean>(false);

  const getSidebarExpandedState = () => {
    const storageValue =
      (storageKey && localStorage.getItem(storageKey)) ?? 'true';

    // expanded/defaultExpandd props take precedence over storage value
    if (!isMounted) {
      defaultExpanded ?? storageValue === 'true';
    }
    return expanded ?? storageValue === 'true';
  };
  const [expandedIndex, setExpandedIndex] = useState<ExpandedIndex>(
    getSidebarExpandedState() ? 0 : [],
  );

  const [panelOpen, setPanelOpen] = useState<boolean>(
    getSidebarExpandedState(),
  );

  const handleAccordionClick = useCallback(
    (expandedItem: ExpandedIndex) => {
      setExpandedIndex(expandedItem);
      onChange?.(expandedItem);
      if (storageKey && expanded === undefined) {
        setPanelOpen(!panelOpen);
        localStorage.setItem(storageKey, `${!panelOpen}`);
      }
    },
    [panelOpen],
  );

  useEffect(() => {
    setExpandedIndex(getSidebarExpandedState() ? 0 : []);
  }, [expanded, panelOpen, storageKey]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Accordion
      w={w}
      index={expandedIndex}
      onChange={handleAccordionClick}
      allowToggle
      layerStyle={layerStyle}
      border={0}
      reduceMotion={reduceMotion}
    >
      <AccordionItem
        layerStyle={layerStyle}
        border={!disableBorders ? 'solid 1px' : 0}
        // I don't know why I have to manually apply the border color like this
        // ... but I do so here we are
        borderTopColor={
          !disableBorders ? layerBorderMap.get(layerStyle) : 'initial'
        }
        borderBottomColor={
          !disableBorders ? layerBorderMap.get(layerStyle) : 'initial'
        }
        borderLeftColor={
          !disableBorders ? layerBorderMap.get(layerStyle) : 'initial'
        }
        borderRightColor={
          !disableBorders ? layerBorderMap.get(layerStyle) : 'initial'
        }
        borderRadius="base"
        data-testid={dataTestId}
      >
        <h2>
          <AccordionButton
            px={4}
            py={3}
            justifyContent="space-between"
            borderRadius="base"
            border="none"
            layerStyle={layerStyle}
            _expanded={
              !disableBorders
                ? {
                    borderBottom: 'solid 1px',
                    borderBottomColor: layerBorderMap.get(layerStyle),
                    borderBottomRadius: '0',
                  }
                : {}
            }
            data-testid={`${dataTestId}-button`}
            _hover={{
              bg: 'inherit',
            }}
          >
            <chakra.span data-testid={`${dataTestId}-heading`} textStyle="h5">
              {title}
            </chakra.span>
            <AccordionIcon
              my={-1} // keeps panel header height == panels headers w/o icon
            />
          </AccordionButton>
        </h2>
        <ChakraAccordionPanel p={4} data-testid={`${dataTestId}-content`}>
          {children}
        </ChakraAccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
