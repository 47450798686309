import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { DateObj, useDayzed } from 'dayzed';
import React, { ReactChild, ReactNode, forwardRef, useState } from 'react';

import { CalendarPanel } from './components/CalendarPanel';

export type OnDateSelected = (
  selectedDate: DateObj,
  event: React.SyntheticEvent<Element, Event>,
) => void;

export interface CalendarPopoverProps {
  children: ReactChild;
  initDate?: Date;
  selectedDates?: Date[];
  minDate?: Date;
  maxDate?: Date;
  isRange?: boolean;
  isPortal?: boolean;
  isOpen?: boolean;
  calWidth?: string;
  returnFocusOnClose?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onDateChange: (date: Date[]) => void;
}

interface PortalOptionProps {
  isPortal: boolean;
  children: ReactNode;
}

const PortalOption = ({ isPortal, children }: PortalOptionProps) => {
  if (isPortal) {
    return <Portal>{children}</Portal>;
  }
  return <>{children}</>;
};

export const CalendarPopover = forwardRef<HTMLDivElement, CalendarPopoverProps>(
  (
    {
      children,
      initDate = new Date(),
      selectedDates = [],
      minDate,
      maxDate,
      isRange = false,
      isPortal = true,
      isOpen,
      calWidth = 'full',
      returnFocusOnClose,
      onOpen,
      onClose,
      onDateChange,
    },
    ref,
  ) => {
    const [hoveredDate, setHoveredDate] = useState<Date | null>(null);

    // Calendar level
    const handleMouseLeave = () => {
      setHoveredDate(null);
    };

    // Date level
    const handleMouseEnterHighlight = (date: Date) => {
      if (!Array.isArray(selectedDates) || !selectedDates?.length) {
        return;
      }
      setHoveredDate(date);
    };

    const isInRange = (date: Date) => {
      if (!Array.isArray(selectedDates) || !selectedDates?.length) {
        return false;
      }
      const firstSelected = selectedDates[0];
      if (selectedDates.length === 2) {
        const secondSelected = selectedDates[1];
        return firstSelected < date && secondSelected > date;
      } else {
        return (
          hoveredDate &&
          ((firstSelected < date && hoveredDate >= date) ||
            (date < firstSelected && date >= hoveredDate))
        );
      }
    };

    // dayzed utils
    const handleDateSelected: OnDateSelected = ({ selectable, date }) => {
      if (!selectable) {
        return;
      }
      if (!isRange) {
        onDateChange([date]);
        return;
      }

      const newDates = [...selectedDates];
      if (newDates.length) {
        if (newDates.length === 1) {
          const firstTime = newDates[0];
          if (firstTime < date) {
            newDates.push(date);
          } else {
            newDates.unshift(date);
          }
          onDateChange(newDates);
        } else if (newDates.length === 2) {
          onDateChange([date]);
        }
      } else {
        newDates.push(date);
        onDateChange(newDates);
      }
    };

    const dayzedData = useDayzed({
      onDateSelected: handleDateSelected,
      selected: selectedDates,
      monthsToDisplay: 1,
      date: initDate,
      minDate,
      maxDate,
      showOutsideDays: true,
    });

    return (
      <Popover
        placement="bottom"
        variant="responsive"
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        returnFocusOnClose={returnFocusOnClose}
        isLazy
      >
        <PopoverTrigger>{children}</PopoverTrigger>
        <PortalOption isPortal={isPortal}>
          <PopoverContent width={calWidth} ref={ref}>
            <PopoverBody p="0" onMouseLeave={handleMouseLeave}>
              <CalendarPanel
                renderProps={dayzedData}
                isInRange={isRange ? isInRange : undefined}
                onMouseEnterHighlight={handleMouseEnterHighlight}
              />
            </PopoverBody>
          </PopoverContent>
        </PortalOption>
      </Popover>
    );
  },
);
