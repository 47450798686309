import { createIcon } from '@chakra-ui/react';

export const MonitoringAppLogo = createIcon({
  displayName: 'MonitoringAppLogo',
  viewBox: '0 0 268 24',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.62C10.1448 2.62 8.33129 3.17013 6.78876 4.20081C5.24622 5.2315 4.04397 6.69646 3.33402 8.41043C2.62407 10.1244 2.43831 12.0104 2.80024 13.8299C3.16217 15.6495 4.05553 17.3208 5.36734 18.6327C6.67916 19.9445 8.35052 20.8378 10.1701 21.1998C11.9896 21.5617 13.8756 21.3759 15.5896 20.666C17.3035 19.956 18.7685 18.7538 19.7992 17.2112C20.8299 15.6687 21.38 13.8552 21.38 12H24C24 14.3734 23.2962 16.6935 21.9776 18.6668C20.6591 20.6402 18.7849 22.1783 16.5922 23.0866C14.3995 23.9948 11.9867 24.2324 9.65892 23.7694C7.33115 23.3064 5.19295 22.1635 3.51472 20.4853C1.83649 18.8071 0.693605 16.6689 0.230582 14.3411C-0.232441 12.0133 0.00519943 9.60051 0.913451 7.4078C1.8217 5.21509 3.35977 3.34094 5.33316 2.02236C7.30655 0.703788 9.62663 0 12 0V2.62Z"
        fill="var(--chakra-colors-corelight-logo)"
      />
      <path
        d="M40.1875 10.34H40.1325C39.7658 10.34 39.5 10.2667 39.335 10.12C39.1883 9.955 39.115 9.68917 39.115 9.3225V7.8375C39.115 6.9575 38.675 6.5175 37.795 6.5175H37.41C36.53 6.5175 36.09 6.9575 36.09 7.8375V16.4725C36.09 17.3525 36.53 17.7925 37.41 17.7925H37.8225C38.7025 17.7925 39.1425 17.3525 39.1425 16.4725V14.465C39.1425 14.0983 39.2158 13.8417 39.3625 13.695C39.5275 13.5483 39.7933 13.475 40.16 13.475H40.215C40.5817 13.475 40.8383 13.5483 40.985 13.695C41.1317 13.8417 41.205 14.0983 41.205 14.465V16.39C41.205 18.6267 40.0867 19.745 37.85 19.745H37.355C35.1183 19.745 34 18.6267 34 16.39V7.92C34 5.68333 35.1183 4.565 37.355 4.565H37.8775C40.0775 4.565 41.1775 5.665 41.1775 7.865V9.3225C41.1775 9.68917 41.1042 9.955 40.9575 10.12C40.8108 10.2667 40.5542 10.34 40.1875 10.34Z"
        fill="currentColor"
      />
      <path
        d="M51.0829 7.92V16.39C51.0829 18.6267 49.9645 19.745 47.7279 19.745H47.1229C44.8862 19.745 43.7679 18.6267 43.7679 16.39V7.92C43.7679 5.68333 44.8862 4.565 47.1229 4.565H47.7279C49.9645 4.565 51.0829 5.68333 51.0829 7.92ZM48.9929 16.4725V7.8375C48.9929 6.9575 48.5529 6.5175 47.6729 6.5175H47.1779C46.2979 6.5175 45.8579 6.9575 45.8579 7.8375V16.4725C45.8579 17.3525 46.2979 17.7925 47.1779 17.7925H47.6729C48.5529 17.7925 48.9929 17.3525 48.9929 16.4725Z"
        fill="currentColor"
      />
      <path
        d="M55.8425 5.6925V6.2975C56.4842 5.1425 57.1167 4.565 57.74 4.565C58.2167 4.565 58.455 4.85833 58.455 5.445V5.6925C58.455 5.98583 58.3725 6.22417 58.2075 6.4075C58.0609 6.59083 57.7584 6.80167 57.3 7.04C56.8784 7.26 56.3925 7.59 55.8425 8.03V18.6175C55.8425 19.2958 55.5034 19.635 54.825 19.635H54.77C54.0917 19.635 53.7525 19.2958 53.7525 18.6175V5.6925C53.7525 5.01417 54.0917 4.675 54.77 4.675H54.825C55.5034 4.675 55.8425 5.01417 55.8425 5.6925Z"
        fill="currentColor"
      />
      <path
        d="M67.3921 15.5375V16.39C67.3921 18.6267 66.2738 19.745 64.0371 19.745H63.5971C61.3604 19.745 60.2421 18.6267 60.2421 16.39V7.92C60.2421 5.68333 61.3604 4.565 63.5971 4.565H64.0371C66.2738 4.565 67.3921 5.68333 67.3921 7.92V11.66C67.3921 12.1183 67.2913 12.4483 67.0896 12.65C66.9063 12.8333 66.5854 12.925 66.1271 12.925H62.3321V16.4725C62.3321 17.3525 62.7721 17.7925 63.6521 17.7925H64.0371C64.9171 17.7925 65.3571 17.3525 65.3571 16.4725V15.5375C65.3571 14.9875 65.6321 14.7125 66.1821 14.7125H66.5671C67.1171 14.7125 67.3921 14.9875 67.3921 15.5375ZM63.9821 6.5175H63.6521C62.7721 6.5175 62.3321 6.9575 62.3321 7.8375V10.945H65.3021V7.8375C65.3021 6.9575 64.8621 6.5175 63.9821 6.5175Z"
        fill="currentColor"
      />
      <path
        d="M71.1937 19.635H71.1387C70.4604 19.635 70.1212 19.2958 70.1212 18.6175V1.0175C70.1212 0.339167 70.4604 0 71.1387 0H71.1937C71.8721 0 72.2112 0.339167 72.2112 1.0175V18.6175C72.2112 19.2958 71.8721 19.635 71.1937 19.635Z"
        fill="currentColor"
      />
      <path
        d="M76.2082 19.635H76.1532C75.4749 19.635 75.1357 19.2958 75.1357 18.6175V5.6925C75.1357 5.01417 75.4749 4.675 76.1532 4.675H76.2082C76.8865 4.675 77.2257 5.01417 77.2257 5.6925V18.6175C77.2257 19.2958 76.8865 19.635 76.2082 19.635ZM76.2082 3.3275H76.1532C75.7682 3.3275 75.4932 3.245 75.3282 3.08C75.1632 2.915 75.0807 2.64 75.0807 2.255V1.8425C75.0807 1.4575 75.1632 1.1825 75.3282 1.0175C75.4932 0.8525 75.7682 0.77 76.1532 0.77H76.2082C76.5932 0.77 76.8682 0.8525 77.0332 1.0175C77.1982 1.1825 77.2807 1.4575 77.2807 1.8425V2.255C77.2807 2.64 77.1982 2.915 77.0332 3.08C76.8682 3.245 76.5932 3.3275 76.2082 3.3275Z"
        fill="currentColor"
      />
      <path
        d="M82.0202 19.745C82.0202 20.1483 82.0935 20.4417 82.2402 20.625C82.3868 20.8083 82.671 20.9 83.0927 20.9H84.1652C84.8802 20.9 85.2377 20.515 85.2377 19.745V16.775C84.4677 17.6367 83.6152 18.0675 82.6802 18.0675C81.8002 18.0675 81.131 17.8017 80.6727 17.27C80.2327 16.7383 80.0127 16.0233 80.0127 15.125V7.5075C80.0127 6.60917 80.2327 5.89417 80.6727 5.3625C81.131 4.83083 81.8002 4.565 82.6802 4.565C83.6885 4.565 84.541 4.98667 85.2377 5.83V5.6925C85.2377 5.01417 85.5768 4.675 86.2552 4.675H86.3102C86.9885 4.675 87.3277 5.01417 87.3277 5.6925V19.4975C87.3277 21.6425 86.246 22.715 84.0827 22.715H82.9827C81.0393 22.715 80.0677 21.7617 80.0677 19.855C80.0677 19.525 80.1318 19.2958 80.2602 19.1675C80.4068 19.0392 80.6452 18.975 80.9752 18.975H81.0852C81.7085 18.975 82.0202 19.2317 82.0202 19.745ZM83.3127 16.0875C83.936 16.0875 84.5777 15.7575 85.2377 15.0975V7.59C84.596 6.89333 83.9543 6.545 83.3127 6.545C82.506 6.545 82.1027 6.94833 82.1027 7.755V14.8775C82.1027 15.6842 82.506 16.0875 83.3127 16.0875Z"
        fill="currentColor"
      />
      <path
        d="M92.1672 7.5625V18.6175C92.1672 19.2958 91.8281 19.635 91.1497 19.635H91.0947C90.4164 19.635 90.0772 19.2958 90.0772 18.6175V1.0175C90.0772 0.339167 90.4164 0 91.0947 0H91.1497C91.8281 0 92.1672 0.339167 92.1672 1.0175V5.9675C92.9556 5.0325 93.8356 4.565 94.8072 4.565C96.4939 4.565 97.3372 5.45417 97.3372 7.2325V18.6175C97.3372 19.2958 96.9981 19.635 96.3197 19.635H96.2647C95.5864 19.635 95.2472 19.2958 95.2472 18.6175V7.6725C95.2472 6.92083 94.8897 6.545 94.1747 6.545C93.6064 6.545 92.9372 6.88417 92.1672 7.5625Z"
        fill="currentColor"
      />
      <path
        d="M101.627 2.2H101.682C102.361 2.2 102.7 2.53917 102.7 3.2175V4.73H103.387C104.011 4.73 104.322 5.04167 104.322 5.665V5.72C104.322 6.34333 104.011 6.655 103.387 6.655H102.7V18.6175C102.7 19.2958 102.361 19.635 101.682 19.635H101.627C100.949 19.635 100.61 19.2958 100.61 18.6175V6.655H100.335C99.7116 6.655 99.3999 6.34333 99.3999 5.72V5.665C99.3999 5.04167 99.7116 4.73 100.335 4.73H100.61V3.2175C100.61 2.53917 100.949 2.2 101.627 2.2Z"
        fill="currentColor"
      />
      <path
        d="M131.711 17.5L128.078 8.00781H128.02C128.088 8.75977 128.122 9.65332 128.122 10.6885V17.5H126.972V6.79199H128.847L132.238 15.625H132.297L135.717 6.79199H137.578V17.5H136.333V10.6006C136.333 9.80957 136.367 8.9502 136.435 8.02246H136.376L132.714 17.5H131.711Z"
        fill="currentColor"
      />
      <path
        d="M150.566 12.1313C150.566 13.8452 150.132 15.1929 149.263 16.1743C148.398 17.1558 147.195 17.6465 145.652 17.6465C144.075 17.6465 142.856 17.1655 141.997 16.2036C141.143 15.2368 140.715 13.8745 140.715 12.1167C140.715 10.3735 141.145 9.02344 142.004 8.06641C142.864 7.10449 144.084 6.62354 145.667 6.62354C147.205 6.62354 148.406 7.11182 149.27 8.08838C150.134 9.06494 150.566 10.4126 150.566 12.1313ZM142.034 12.1313C142.034 13.5815 142.341 14.6826 142.957 15.4346C143.577 16.1816 144.475 16.5552 145.652 16.5552C146.838 16.5552 147.734 16.1816 148.34 15.4346C148.945 14.6875 149.248 13.5864 149.248 12.1313C149.248 10.6909 148.945 9.59961 148.34 8.85742C147.739 8.11035 146.848 7.73682 145.667 7.73682C144.48 7.73682 143.577 8.11279 142.957 8.86475C142.341 9.61182 142.034 10.7007 142.034 12.1313Z"
        fill="currentColor"
      />
      <path
        d="M162.076 17.5H160.655L154.803 8.51318H154.744C154.822 9.56787 154.861 10.5347 154.861 11.4136V17.5H153.711V6.79199H155.118L160.955 15.7422H161.014C161.004 15.6104 160.982 15.188 160.948 14.4751C160.914 13.7573 160.901 13.2446 160.911 12.937V6.79199H162.076V17.5Z"
        fill="currentColor"
      />
      <path d="M165.77 17.5V6.79199H167.015V17.5H165.77Z" fill="currentColor" />
      <path
        d="M174.005 17.5H172.76V7.89795H169.369V6.79199H177.396V7.89795H174.005V17.5Z"
        fill="currentColor"
      />
      <path
        d="M189.06 12.1313C189.06 13.8452 188.625 15.1929 187.756 16.1743C186.892 17.1558 185.688 17.6465 184.145 17.6465C182.568 17.6465 181.35 17.1655 180.49 16.2036C179.636 15.2368 179.208 13.8745 179.208 12.1167C179.208 10.3735 179.638 9.02344 180.498 8.06641C181.357 7.10449 182.578 6.62354 184.16 6.62354C185.698 6.62354 186.899 7.11182 187.763 8.08838C188.627 9.06494 189.06 10.4126 189.06 12.1313ZM180.527 12.1313C180.527 13.5815 180.834 14.6826 181.45 15.4346C182.07 16.1816 182.968 16.5552 184.145 16.5552C185.332 16.5552 186.228 16.1816 186.833 15.4346C187.438 14.6875 187.741 13.5864 187.741 12.1313C187.741 10.6909 187.438 9.59961 186.833 8.85742C186.232 8.11035 185.341 7.73682 184.16 7.73682C182.973 7.73682 182.07 8.11279 181.45 8.86475C180.834 9.61182 180.527 10.7007 180.527 12.1313Z"
        fill="currentColor"
      />
      <path
        d="M193.45 13.0469V17.5H192.205V6.79199H195.142C196.455 6.79199 197.424 7.04346 198.049 7.54639C198.679 8.04932 198.994 8.80615 198.994 9.81689C198.994 11.2329 198.276 12.1899 196.841 12.688L199.749 17.5H198.276L195.684 13.0469H193.45ZM193.45 11.9775H195.156C196.035 11.9775 196.68 11.8042 197.09 11.4575C197.5 11.106 197.705 10.5811 197.705 9.88281C197.705 9.1748 197.495 8.66455 197.075 8.35205C196.66 8.03955 195.991 7.8833 195.068 7.8833H193.45V11.9775Z"
        fill="currentColor"
      />
      <path
        d="M202.227 17.5V6.79199H203.472V17.5H202.227Z"
        fill="currentColor"
      />
      <path
        d="M215.531 17.5H214.11L208.258 8.51318H208.199C208.277 9.56787 208.316 10.5347 208.316 11.4136V17.5H207.167V6.79199H208.573L214.41 15.7422H214.469C214.459 15.6104 214.437 15.188 214.403 14.4751C214.369 13.7573 214.356 13.2446 214.366 12.937V6.79199H215.531V17.5Z"
        fill="currentColor"
      />
      <path
        d="M223.935 11.8896H227.575V17.0972C227.008 17.2778 226.432 17.4146 225.846 17.5073C225.26 17.6001 224.582 17.6465 223.81 17.6465C222.189 17.6465 220.927 17.1655 220.023 16.2036C219.12 15.2368 218.668 13.8843 218.668 12.146C218.668 11.0327 218.891 10.0586 219.335 9.22363C219.784 8.38379 220.429 7.74414 221.269 7.30469C222.108 6.86035 223.092 6.63818 224.22 6.63818C225.363 6.63818 226.427 6.84814 227.414 7.26807L226.93 8.3667C225.963 7.95654 225.033 7.75146 224.14 7.75146C222.836 7.75146 221.818 8.13965 221.085 8.91602C220.353 9.69238 219.987 10.769 219.987 12.146C219.987 13.5913 220.338 14.6875 221.042 15.4346C221.75 16.1816 222.787 16.5552 224.154 16.5552C224.896 16.5552 225.622 16.4697 226.33 16.2988V13.0029H223.935V11.8896Z"
        fill="currentColor"
      />
      <path
        d="M242.28 17.5L240.947 14.0942H236.655L235.337 17.5H234.077L238.311 6.74805H239.358L243.569 17.5H242.28ZM240.559 12.9736L239.314 9.65576C239.153 9.23584 238.987 8.7207 238.816 8.11035C238.708 8.5791 238.555 9.09424 238.354 9.65576L237.095 12.9736H240.559Z"
        fill="currentColor"
      />
      <path
        d="M252.581 9.91211C252.581 10.9961 252.21 11.8311 251.468 12.417C250.73 12.998 249.673 13.2886 248.296 13.2886H247.037V17.5H245.792V6.79199H248.567C251.243 6.79199 252.581 7.83203 252.581 9.91211ZM247.037 12.2192H248.157C249.261 12.2192 250.059 12.041 250.552 11.6846C251.045 11.3281 251.292 10.7568 251.292 9.9707C251.292 9.2627 251.06 8.73535 250.596 8.38867C250.132 8.04199 249.41 7.86865 248.428 7.86865H247.037V12.2192Z"
        fill="currentColor"
      />
      <path
        d="M262.369 9.91211C262.369 10.9961 261.998 11.8311 261.256 12.417C260.519 12.998 259.461 13.2886 258.084 13.2886H256.825V17.5H255.58V6.79199H258.355C261.031 6.79199 262.369 7.83203 262.369 9.91211ZM256.825 12.2192H257.945C259.049 12.2192 259.847 12.041 260.34 11.6846C260.833 11.3281 261.08 10.7568 261.08 9.9707C261.08 9.2627 260.848 8.73535 260.384 8.38867C259.92 8.04199 259.198 7.86865 258.216 7.86865H256.825V12.2192Z"
        fill="currentColor"
      />
      <line
        x1="115.322"
        y1="24"
        x2="115.322"
        y2="2.18557e-08"
        stroke="#71767C"
      />
    </>
  ),
  defaultProps: {
    height: '24px',
    width: '268px',
  },
});
