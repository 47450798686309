import { ArrowBackIcon } from '@chakra-ui/icons';
import { IconButton, useStyles } from '@chakra-ui/react';

export interface CardPageBackButtonProps {
  onClick: () => void;
  'data-testid'?: string;
}

export const CardPageBackButton = ({
  onClick,
  'data-testid': dataTestId = 'card-page-back-button',
}: CardPageBackButtonProps) => {
  const styles = useStyles();

  return (
    <IconButton
      {...(styles.backButton as any)}
      onClick={onClick}
      aria-label="go back"
      icon={<ArrowBackIcon {...(styles.backIcon as any)} />}
      variant="solid"
      colorScheme="gray"
      size="box-md"
      data-testid={dataTestId}
    />
  );
};
