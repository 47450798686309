import { Center, Spinner, SpinnerProps } from '@chakra-ui/react';

interface LoadingSpinnerProps {
  color?: string;
  size?: SpinnerProps['size'];
  minH?: string;
  'data-testid'?: string;
}

export const LoadingSpinner = ({
  color = 'state.selected',
  size = 'lg',
  minH = '60px',
  'data-testid': dataTestId = 'gamma-loading-spinner',
}: LoadingSpinnerProps) => {
  return (
    <Center minH={minH} data-testid={dataTestId}>
      <Spinner color={color} size={size} />
    </Center>
  );
};
