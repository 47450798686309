import {
  ChakraProvider,
  ColorModeScript,
  extendTheme,
  useToast,
} from '@chakra-ui/react';
import { Global } from '@emotion/react';
import { createContext, ReactNode, useEffect, useMemo, useRef } from 'react';
import LocalizedStrings, {
  LocalizedStrings as LocalizedStringsType,
} from 'react-localization';

import { Toast } from '@gamma/overlay';
import { gammaI18n, GammaI18nType } from '../i18n';
import { en } from '../i18n/en';
import {
  breakpoints,
  colors,
  fonts,
  fontSizes,
  layerStyles,
  letterSpacings,
  lineHeights,
  shadows,
  textStyles,
} from './common';
import { semanticTokens } from './common/tokens';
import { components } from './components';
import { Fonts } from './GlobalStyles';

export const themeOverrides = extendTheme({
  colors,
  components,
  fonts,
  lineHeights,
  fontSizes,
  letterSpacings,
  textStyles,
  shadows,
  semanticTokens,
  layerStyles,
  breakpoints,
  radii: {
    base: '4px',
  },
  config: {
    initialColorMode: localStorage.getItem('chakra-ui-color-mode') ?? 'dark',
    useSystemColorMode: false,
  },
  styles: {
    global: (props: any) => ({
      html: {
        WebkitFontSmoothing: 'auto',
        MozOsxFontSmoothing: 'auto',
      },
      body: {
        bg: 'layer.0',
        color: 'text.primary',
        height: '100vh',
        overflow: 'hidden',
        fontSize: 'md',
        fontFamily: 'body',
        lineHeight: 'md',
      },
      '.select__menu-portal': {
        zIndex: 'var(--chakra-zIndices-tooltip) !important',
      },
      '.chakra-collapse[style*="height: auto"]': {
        // this allows AccordionPanel contents to overflow when expanded, needed
        // for select menus to render outside of an expanded accordion panel.
        overflow: 'initial !important',
      },
      // this makes monaco editor line numbers not wrap when they're larger than 5
      '.monaco-editor .margin-view-overlays .line-numbers': {
        minWidth: 'fit-content',
      },
    }),
  },
});

interface GammaProviderProps {
  fontPath?: string;
  children: ReactNode;
  theme?: Record<string, any>;
  i18n?: LocalizedStringsType<
    Partial<GammaI18nType> & { [index: string]: any }
  >;
}

export interface GammaContext {
  i18n: LocalizedStringsType<GammaI18nType & { [index: string]: any }>;
}

export const gammaContext = createContext<GammaContext>({
  i18n: gammaI18n,
});

export const GammaProvider = ({
  fontPath = '/static/fonts/',
  children,
  theme: propsTheme = {},
  i18n,
}: GammaProviderProps) => {
  const firstMount = useRef(true);
  const toast = useToast();
  const extendedTheme = extendTheme(themeOverrides, propsTheme);

  const localization = useMemo(() => {
    return new LocalizedStrings({ en: { ...en, ...i18n } });
  }, [i18n]);

  useEffect(() => {
    if (
      !firstMount.current || // prevents strict mode behavior firing this twice.
      !document.fonts.load
    ) {
      return;
    }

    firstMount.current = false;
    const timeout = 1500;
    const maxAttempts = 3;
    let attempts = 0;

    const loadFonts = () => {
      attempts++;

      document.fonts
        .load('20px Material Symbols Sharp')
        .then(() => {
          // to prevent FOUT icons are not rendered until this class is added
          document.body.classList.add('icons-loaded');
        })
        .catch(() => {
          if (attempts <= maxAttempts) {
            setTimeout(loadFonts, timeout * attempts);
          } else {
            toast({
              status: 'error',
              description:
                'Icons cannot be rendered, please refresh the browser to restart icon loading attempts.',
              title: 'Error loading icons',
            });
          }
        });
    };

    loadFonts();
  }, []);

  return (
    <gammaContext.Provider value={{ i18n: localization }}>
      <ChakraProvider
        theme={extendedTheme}
        toastOptions={{ defaultOptions: { render: Toast } }}
      >
        <ColorModeScript
          initialColorMode={extendedTheme.config.initialColorMode}
        />
        <Fonts fontPath={fontPath} />
        <Global
          styles={`
						#root { height: 100%; }
						.mui-icon {
							opacity: 0
						}
						.icons-loaded .mui-icon {
							opacity: 1
						}
					`}
        />

        {children}
      </ChakraProvider>
    </gammaContext.Provider>
  );
};
