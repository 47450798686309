import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Heading,
  Text,
} from '@chakra-ui/react';
import { ROUTES } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import { useGetAssociatedDetectionForAlert } from '@gamma/investigator/queries';
import { GraphQLReqStatus } from '@gamma/progress';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const { detections } = ROUTES;

export const AlertToDetection = () => {
  const {
    alertIdRequired,
    detectionNotFound,
    detectionNotFoundForAlertId,
    viewDetections,
  } = i18n.pages.detections;

  const navigate = useNavigate();

  const { alert_id } = useParams();

  const { loading, error, data } = useGetAssociatedDetectionForAlert({
    skip: !alert_id,
    variables: {
      alert_id,
    },
  });

  useEffect(() => {
    if (data?.getAssociatedDetectionForAlert) {
      navigate({
        pathname: `${detections}/${data?.getAssociatedDetectionForAlert}/details`,
      });
    }
  }, [data, navigate]);

  if (loading || error) {
    return <GraphQLReqStatus loading={loading} error={error} />;
  }

  return (
    <Box h="100vh" position="relative" data-testid="detection-not-found">
      <AbsoluteCenter>
        <Center mb={2}>
          <Heading
            textStyle="h3"
            fontWeight="normal"
            data-testid="error-page-title"
          >
            {detectionNotFound}
          </Heading>
        </Center>
        <Center mb="40px">
          <Text data-testid="error-page-body">
            {alert_id
              ? i18n.formatString(detectionNotFoundForAlertId, alert_id)
              : alertIdRequired}
          </Text>
        </Center>
        <Center>
          <Button
            variant="outline"
            onClick={() => navigate(detections)}
            data-testid="error-page-cta"
          >
            {viewDetections}
          </Button>
        </Center>
      </AbsoluteCenter>
    </Box>
  );
};
