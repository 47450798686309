import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Link,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { InvestigatorLogo } from '@gamma/icons';
import { CONSTANTS, ROUTES } from '@gamma/investigator/constants';
import { AuthContext, CookieContext } from '@gamma/investigator/context';
import { useLogOperationCall, useUserSession } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  IGetUserCookieAcceptance,
  useGetUserCookieAcceptance,
} from '@gamma/investigator/queries';
import { getIsCookieExpired } from '@gamma/investigator/utilities';
import { UIShell } from '@gamma/layout';
import { CognitoUserInterface } from '@gamma/shared/types';
import { ReactNode, useContext, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { CookiePolicy } from '../CookiePolicy';
import { SidebarFooterLinks, SidebarLinks, TopbarLinks } from './Components';
import { AppFooter } from './Components/AppFooter';

const {
  chatGPTToastDescriptionMessage,
  chatGPTToastTitleMessage,
  gptSettings,
} = i18n.pages.integrations.chatGPT;

const { FEDERATION_TYPE } = CONSTANTS;

const { login, home } = ROUTES;

interface WrapperProps {
  children: ReactNode;
  user: CognitoUserInterface | undefined;
  authStateLoading: boolean;
  getUserTcAcceptanceLoading: boolean;
}

export const Wrapper = ({
  children,
  user,
  authStateLoading,
  getUserTcAcceptanceLoading,
}: WrapperProps) => {
  const { isOrgTenant, userLicense, userLicenseLoading, userRole } =
    useContext(AuthContext);

  const { checkUserMFA } = useUserSession();
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const logOperationCall = useLogOperationCall();

  // @ts-ignore
  const isSSOLogin = JSON.parse(window.sessionStorage.getItem('isSSOLogin'));

  const { setCookieConfig, getUserCookieAcceptance } =
    useContext(CookieContext);

  const showToastMessage = useToast();

  const navigate = useNavigate();

  const gptToastID = 'show_gpt_toast';
  const showGPTToast = useToast({
    position: 'bottom-right',
    isClosable: false,
    status: 'warning',
    duration: null,
    id: gptToastID,
    title: chatGPTToastTitleMessage,
    description: (
      <Flex mr={1} flexDir="column" alignItems="flex-start">
        <Text textStyle="body-md">{chatGPTToastDescriptionMessage}</Text>
        {/* need local storage here b/c some renders (after refreshing the page) isAdmin is falsy */}
        {(localStorage.getItem('shouldShowGPTToastGeneralSettingsLink') ===
          'true' ||
          userRole === 'admin') && (
          <Button
            onClick={() => {
              navigate(ROUTES.systemSettingsGeneral);
              localStorage.setItem('shouldShowGPTToast', 'false');
              localStorage.removeItem('shouldShowGPTToastGeneralSettingsLink');
              showGPTToast?.closeAll();
            }}
            variant="link"
          >
            {gptSettings}
          </Button>
        )}
        <Button
          position="absolute"
          top="5px"
          right="5px"
          onClick={() => {
            localStorage.setItem('shouldShowGPTToast', 'false');
            localStorage.removeItem('shouldShowGPTToastGeneralSettingsLink');
            showGPTToast.closeAll();
          }}
        >
          <MuiIcon>close</MuiIcon>
        </Button>
      </Flex>
    ),
  });

  const {
    loading: getUserCookieAcceptanceLoading,
    error: getUserCookieAcceptanceError,
  } = useGetUserCookieAcceptance({
    onCompleted: (data: IGetUserCookieAcceptance) => {
      const { isCookiePolicyExpired } = getIsCookieExpired(
        data?.getUserCookieAcceptance,
      );

      setCookieConfig?.((prev) => ({
        ...prev,
        ...data,
        initialStep: 1,
        isOpen: isCookiePolicyExpired,
        isLoaded: true,
      }));
    },
    onError: () => {
      const { isCookiePolicyExpired } = getIsCookieExpired(
        getUserCookieAcceptance,
      );

      setCookieConfig?.((prev) => ({
        ...prev,
        isOpen: isCookiePolicyExpired,
      }));
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!isSSOLogin) {
      checkUserMFA();
    }
  }, [user, isSSOLogin]);

  const routerLocation = useLocation();

  useEffect(() => {
    const { location } = window;

    if (
      location.href.includes('analytics=true') ||
      location.href.includes('analytics=false')
    ) {
      const toastClick = () => navigate(ROUTES.accountSettings);

      showToastMessage({
        status: 'success',
        title: i18n.cookiePolicy.toastMessages.success.title,
        description: (
          <Box maxW="400px">
            {i18n.cookiePolicy.toastMessages.success.description}
            <Button variant="link" size="xs" onClick={toastClick}>
              {i18n.cookiePolicy.toastMessages.success.link}
            </Button>
          </Box>
        ),
        isClosable: true,
        position: 'bottom-right',
      });
      const urlSearchParams = new URLSearchParams(location.search);
      const cookieStatusInput =
        urlSearchParams.get('analytics') === 'true'
          ? { cookie_status: 'enabled' }
          : urlSearchParams.get('analytics') === 'false'
          ? { cookie_status: 'disabled' }
          : null;

      if (cookieStatusInput?.cookie_status) {
        logOperationCall(
          'COOKIE_UPDATE',
          'USER',
          JSON.stringify(cookieStatusInput),
        );
      }

      const newLocationSearch = location.search.replace(
        /[&?]analytics=(true|false)/,
        '',
      );

      navigate(location.pathname + newLocationSearch);
    }

    const { isCookiePolicyExpired } = getIsCookieExpired(
      getUserCookieAcceptance,
    );

    if (isCookiePolicyExpired) {
      setCookieConfig?.((prev) => ({ ...prev, isOpen: true }));
    }
  }, [window.location.href, routerLocation.pathname]);

  useEffect(() => {
    const pathsThatShouldEnableShowingGPTToastOnFirstVisit = [
      'detections',
      'alert-catalog',
    ];

    const isOnPathWithGPTContent =
      pathsThatShouldEnableShowingGPTToastOnFirstVisit.some((path) =>
        routerLocation.pathname.includes(path),
      );

    // set toast id to be used later so we can prevent duplicate toasts from showing
    if (
      isOnPathWithGPTContent &&
      localStorage.getItem('shouldShowGPTToast') !== 'false'
    ) {
      localStorage.setItem('shouldShowGPTToast', 'true');
    }

    // prevent duplicate toasts from showing with "!showGPTToast?.isActive(gptToastID)"
    if (
      localStorage.getItem('shouldShowGPTToast') === 'true' &&
      !showGPTToast?.isActive(gptToastID)
    ) {
      // persist toast link to general settings page if user is admin
      if (userRole === 'admin') {
        localStorage.setItem('shouldShowGPTToastGeneralSettingsLink', 'true');
      }
      showGPTToast();
    }

    if (!user) {
      showGPTToast.close(gptToastID);
    }
  }, [showGPTToast, routerLocation.pathname, user, userLicense, userRole]);

  if (!authStateLoading) {
    if (!user) return <Navigate to={login} />;

    if (
      (user?.preferredMFA === 'NOMFA' &&
        !user?.attributes['custom:allow_no_mfa'] &&
        !isSSOLogin) ||
      !userLicense ||
      userLicenseLoading ||
      getUserTcAcceptanceLoading
    ) {
      return (
        <Center bg={bgColor} h="100%">
          <Spinner size="lg" />
        </Center>
      );
    }

    return (
      <>
        <UIShell
          sidebar={<SidebarLinks />}
          sidebarFooter={<SidebarFooterLinks />}
          nav={<TopbarLinks />}
          logo={
            <HStack>
              <Link href={home} _focus={{ outline: 'none' }}>
                <InvestigatorLogo
                  data-testid="investigator-logo"
                  height="20px"
                />
              </Link>
              {userLicense?.display_name && (
                <>
                  <Text mr={0.5} as="span" fontWeight="500">
                    —
                  </Text>
                  <Text as="span" fontWeight="500">
                    {userLicense?.display_name}
                  </Text>
                </>
              )}
            </HStack>
          }
          footer={<AppFooter />}
        >
          {children}
        </UIShell>
        {!getUserCookieAcceptanceLoading && !getUserCookieAcceptanceError && (
          <CookiePolicy />
        )}
      </>
    );
  } else {
    return (
      <Center h="100%">
        <Spinner color="corelight.base" size="lg" />
      </Center>
    );
  }
};
