import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { IQueryAlertsPaginated, SuricataAlert } from './types';

export const QUERY_ALERTS_SURICATA_PAGINATED = gql`
  query queryAlertsSuricataPaginated(
    $query: String!
    $offset: Int
    $size: Int
    $sort: [SortParameterInput]
  ) {
    queryAlertsPaginated(
      query: $query
      offset: $offset
      size: $size
      sort: $sort
    ) {
      alerts {
        ... on SuricataAlert {
          alert_id
          alert_entity {
            entity_id
            entity_name
            entity_type
          }
          alert_info {
            alert_name
            alert_type
          }
          alert_keys {
            tenant_alert
            tenant_entity
            tenant_alert_entity
          }
          alert_timestamp {
            observed
          }
          community_id
          destination_ip
          signature_id
          suri_id
          uid
          source_entities
          destination_entities
        }
      }
      offset
      size
      total_items
    }
  }
`;

export const useQueryAlertsSuricataPaginated = (
  options: QueryHookOptions<
    IQueryAlertsPaginated<SuricataAlert>,
    OperationVariables
  >,
) => {
  return useQuery<IQueryAlertsPaginated<SuricataAlert>>(
    QUERY_ALERTS_SURICATA_PAGINATED,
    options,
  );
};
