import { Flex, Text } from '@chakra-ui/react';
import { Illustrations } from '@gamma/icons';
import { i18n } from '@gamma/investigator/localization';
import {
  CardPageBackButton,
  CardPageBody,
  CardPageHeading,
} from '@gamma/pages';
import { useNavigate } from 'react-router-dom';

export const LoginTrouble = () => {
  const navigate = useNavigate();
  const { title, forgotPassword, twoFactorRecovery } = i18n.pages.login.trouble;
  return (
    <>
      <CardPageBackButton
        onClick={() => navigate('/login', { replace: true })}
      />
      <CardPageHeading>{title}</CardPageHeading>
      <CardPageBody>
        <Flex
          as="button"
          bg="layer.1"
          py={3.5}
          px={4}
          mb={4}
          justifyContent="space-between"
          alignItems="center"
          borderRadius="base"
          onClick={() => navigate('password')}
        >
          <Text color="blue.300">{forgotPassword}</Text>
          <Illustrations.LockedIdentity />
        </Flex>
        <Flex
          as="button"
          bg="layer.1"
          py={3.5}
          px={4}
          justifyContent="space-between"
          alignItems="center"
          borderRadius="base"
          onClick={() => navigate('two-factor-recovery')}
        >
          <Text color="blue.300" align="left">
            {twoFactorRecovery}
          </Text>
          <Illustrations.TwoFactorAuth />
        </Flex>
      </CardPageBody>
    </>
  );
};
