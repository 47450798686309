import { CTAButton } from '@gamma/buttons';
import { MFA } from '@gamma/investigator/constants';
import { useUserSession } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { ErrorBoundary } from '@gamma/overlay';
import {
  CardPageContent,
  CardPageCTA,
  CardPageHeading,
  CardPageIllustration,
  CardPageSubtitle,
} from '@gamma/pages';
import { useNavigate } from 'react-router-dom';

interface IComplete {
  preferredMFA: string;
}

export const CompleteRegistration = ({ preferredMFA }: IComplete) => {
  const navigate = useNavigate();
  const { title, subtitle, subtitleAlt, cta } =
    i18n.pages.registration.complete;

  const { signOut } = useUserSession();

  const handleOnSignInClick = async () => {
    if (preferredMFA === MFA.NOMFA) {
      navigate('/', { replace: true });
    } else {
      await signOut();
      navigate('/login', { replace: true });
    }
  };

  return (
    <ErrorBoundary page="Registration Complete Page">
      <CardPageContent>
        <CardPageHeading>{title}</CardPageHeading>
        <CardPageIllustration illustration="Complete" />
        <CardPageSubtitle>
          {preferredMFA === MFA.NOMFA ? subtitleAlt : subtitle}
        </CardPageSubtitle>
      </CardPageContent>
      <CardPageCTA>
        <CTAButton onClick={handleOnSignInClick}>{cta}</CTAButton>
      </CardPageCTA>
    </ErrorBoundary>
  );
};
