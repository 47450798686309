import { createIcon } from '@chakra-ui/react';

export const DoubleChevronRightIcon = createIcon({
  displayName: 'DoubleChevronRightIcon',
  viewBox: '0 0 24 24',
  d: 'M5.59 7.41 7 6l6 6-6 6-1.41-1.41L10.17 12 5.59 7.41Zm6 0L13 6l6 6-6 6-1.41-1.41L16.17 12l-4.58-4.59Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
