import { Box, BoxProps, ResponsiveValue } from '@chakra-ui/react';
import { useBreakpointValue } from '@gamma/hooks';
import { forwardRef } from 'react';

export interface ColumnProps extends BoxProps {
  /** The number of columns 1-12 to set the width of the Column to. Uses chakra's ResponsiveValue type */
  col?: ResponsiveValue<number>;
}

export const Column = forwardRef<HTMLDivElement, ColumnProps>(
  ({ children, col = 12, ...rest }, ref) => {
    const colVal = useBreakpointValue(col) || 12;

    return (
      <Box
        p="2"
        flex={`0 0 ${(colVal / 12) * 100}%`}
        maxW={`${(colVal / 12) * 100}%`}
        ref={ref}
        {...rest}
      >
        {children}
      </Box>
    );
  },
);
