import { createIcon } from '@chakra-ui/react';

export const NotFoundLaptop = createIcon({
  displayName: 'NotFoundLaptop',
  viewBox: '0 0 570 170',
  path: (
    <>
      <path
        d="M28.853 165.427h515.062a1.963 1.963 0 0 1 1.968 1.964v.298a1.962 1.962 0 0 1-1.968 1.964H28.853a1.962 1.962 0 0 1-1.968-1.964v-.298a1.964 1.964 0 0 1 1.968-1.964ZM1.968 165.427h16.618a1.964 1.964 0 0 1 1.968 1.964v.298a1.962 1.962 0 0 1-1.968 1.964H1.968A1.962 1.962 0 0 1 0 167.689v-.298a1.964 1.964 0 0 1 1.968-1.964ZM567.438 170h-14.323c-1.415 0-2.562-1.024-2.562-2.287s1.147-2.286 2.562-2.286h14.323c1.415 0 2.562 1.023 2.562 2.286 0 1.263-1.147 2.287-2.562 2.287Z"
        fill="#222930"
      />
      <path
        d="M170.88 0h228.192a8.01 8.01 0 0 1 5.66 2.34 7.986 7.986 0 0 1 2.345 5.651v134.806a7.984 7.984 0 0 1-2.345 5.65 8.01 8.01 0 0 1-5.66 2.341H170.88a8.013 8.013 0 0 1-5.66-2.341 7.983 7.983 0 0 1-2.344-5.65V7.991c0-2.12.843-4.152 2.344-5.65A8.014 8.014 0 0 1 170.88 0Z"
        fill="#343D44"
      />
      <path d="M400.041 7.875H169.912v133.768h230.129V7.875Z" fill="#4F575E" />
      <path d="M173.177 7.875h-3.265v133.768h3.265V7.875Z" fill="#273038" />
      <path
        d="m259.235 11.135-86.058 85.92v34.814L294.118 11.126l-34.883.009ZM400.041 7.875v33.38L299.488 141.644h-77.907L353.978 9.463V7.876h46.063ZM400.041 52.288V73.52l-68.235 68.122h-21.267l89.502-89.355Z"
        fill="#646A70"
      />
      <path d="M400.041 7.875h-3.265v133.768h3.265V7.875Z" fill="#273038" />
      <path d="M169.912 7.875v3.26h230.129v-3.26H169.912Z" fill="#273038" />
      <path
        d="M141.578 152.42h286.844v5.95a7.05 7.05 0 0 1-2.07 4.99 7.074 7.074 0 0 1-4.998 2.067H148.646a7.074 7.074 0 0 1-4.998-2.067 7.05 7.05 0 0 1-2.07-4.99v-5.95Z"
        fill="#2E363E"
      />
      <path
        d="M412.462 149.544H157.491a2.876 2.876 0 0 0-2.881 2.876h260.732a2.868 2.868 0 0 0-1.778-2.657 2.873 2.873 0 0 0-1.102-.219Z"
        fill="#575E65"
      />
      <path
        d="M289.22 152.447h-29.985l2.226 2.913a3.314 3.314 0 0 0 2.633 1.3h41.813a3.314 3.314 0 0 0 2.632-1.3l2.226-2.913H289.22Z"
        fill="#485057"
      />
      <path
        d="m234.545 49.8-19.12 40.32v6.56h24.24V105h9.28v-8.32h5.04V88.6h-5.04V73.56h-9.28V88.6h-12.96l17.68-38.8h-9.84Zm67.797 39.04V65.96c0-11.68-8.16-16.88-17.36-16.88s-17.36 5.2-17.36 16.96v22.8c0 11.44 8.16 16.88 17.36 16.88s17.36-5.44 17.36-16.88Zm-9.28-22.32v21.76c0 5.92-3.12 9.36-8.08 9.36-5.04 0-8.16-3.44-8.16-9.36V66.52c0-5.92 3.12-9.36 8.16-9.36 4.96 0 8.08 3.44 8.08 9.36Zm41.076-16.72-19.12 40.32v6.56h24.24V105h9.28v-8.32h5.04V88.6h-5.04V73.56h-9.28V88.6h-12.96l17.68-38.8h-9.84Z"
        fill="#35FE94"
      />
    </>
  ),
  defaultProps: {
    height: '170px',
    width: '570px',
  },
});
