import { createIcon } from '@chakra-ui/react';

export const CaretRightIcon = createIcon({
  displayName: 'CaretRightIcon',
  d: 'm10 17 5-5-5-5v10Z',
  viewBox: '0 0 24 24',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
