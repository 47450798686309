import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

import { SuccessOutput } from '../humio/types';

export interface ISendEmail {
  sendEmail: SuccessOutput;
}

export const SEND_EMAIL = gql`
  mutation sendEmail($input: SendEmailInput!) {
    sendEmail(input: $input) {
      status_code
      body {
        data
        message
        success
      }
    }
  }
`;

export const useSendEmail = (
  options?: MutationHookOptions<ISendEmail, OperationVariables>,
) => {
  return useMutation(SEND_EMAIL, options);
};
