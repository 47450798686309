import errorPage from './errorPage.json';
import errors from './errors.json';
import files from './files.json';
import globals from './globals.json';
import modals from './modals.json';
import tables from './tables.json';
import texts from './texts.json';

export const en = {
  modals,
  globals,
  files,
  texts,
  errors,
  tables,
  errorPage,
};
