import { ComponentStyleConfig } from '@chakra-ui/react';
import { cssVar, mode } from '@chakra-ui/theme-tools';

const $width = cssVar('switch-track-width');
const $height = cssVar('switch-track-height');

export const SwitchStyles: ComponentStyleConfig = {
  baseStyle: (props: any) => ({
    track: {
      border: 'solid 1px',
      borderColor: mode('gray.200', 'gray.600')(props),
      bg: 'gray.500',
      boxShadow: 'inset 0px 2px 0px rgba(22, 22, 22, 0.2)',
      _checked: {
        bg: 'green.500',
      },
      alignItems: 'center',
    },
  }),
  variants: {
    box: (props: any) => {
      return {
        track: {
          borderRadius: 5,
          bg: 'gray.500',
          boxShadow: 'inset 0px 2px 0px rgba(22, 22, 22, 0.2)',
          _checked: {
            bg: 'green.500',
          },
        },
        thumb: {
          height: '100%',
          width: '50%',
          bg: "#fff url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='6' height='8' viewBox='0 0 6 8'><path fill='%23D8D8D8' fill-rule='evenodd' d='M0 0h2v8H0V0zm4 0h2v8H4V0z'/></svg>\")",
          borderRadius: 'base',
          backgroundImage:
            "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='6' height='8' viewBox='0 0 6 8'><path fill='%23D8D8D8' fill-rule='evenodd' d='M0 0h2v8H0V0zm4 0h2v8H4V0z'/></svg>\")",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',

          _checked: {
            transform: 'translateX(100%)',
          },
        },
      };
    },
  },
  sizes: {
    xs: {
      container: {
        [$width.variable]: '26px',
        [$height.variable]: 'sizes.4',
      },
      track: {
        w: '22px',
        p: '2px',
        h: 3,
      },
      thumb: {
        w: 3,
        h: 3,
      },
    },
    sm: {
      container: {
        [$width.variable]: '26px',
        [$height.variable]: 'sizes.4',
      },
      track: {
        w: '26px',
        p: '3.2px',
        h: 4,
      },
    },
    md: {
      container: {
        [$width.variable]: '32.5px',
        [$height.variable]: 'sizes.5',
      },
      track: {
        w: '32.5px',
        p: 1,
        h: 5,
      },
    },
    lg: {
      container: {
        [$width.variable]: '45.5px',
        [$height.variable]: 'sizes.7',
      },
      track: {
        w: '45.5px',
        p: '5.6px',
        h: 7,
      },
    },
  },
};
