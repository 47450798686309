import { ComponentStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

import { textStyles } from '../../common';

export const cardPageStyles: ComponentStyleConfig = {
  parts: [
    'background',
    'panel',
    'content',
    'body',
    'heading',
    'backButton',
    'cta',
  ],
  baseStyle: (props: any) => ({
    background: {
      h: 'full',
      bg: mode('gray.300', 'gray.900')(props),
    },
    panel: {
      bg: mode('gray.50', 'gray.800')(props),
      border: 'solid 1px',
      borderColor: mode('gray.50', 'gray.800')(props),
    },
    content: {
      position: 'relative',
      display: 'flex',
      flexDir: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      flex: '1 0 auto',
      w: 'full',
    },
    body: {
      display: 'flex',
      w: 'full',
      flexDir: 'column',
    },
    heading: {
      ...textStyles['h5'],
      fontWeight: 'normal',
      textAlign: 'center',
      mt: 1,
      mb: 5,
    },
    backButton: {
      border: 'none',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    cta: {
      display: 'flex',
      flex: '0 0 70px',
      alignItems: 'flex-end',
    },
  }),
};
