import { Box, Tab as ChakraTab, TabProps, VStack } from '@chakra-ui/react';
import { useDateRangeQueryString } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { useQueryDetectionsPaginatedQuery } from '@gamma/investigator/queries';
import { FlushPanelContent, Panel } from '@gamma/layout';
import { GraphQLReqStatus, LoadingSpinner } from '@gamma/progress';
import { Tab, Tabs } from '@gamma/tabs';
import React, { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';

import { IDetectionContext } from '../../../Detection';
import { DetectionAlerts } from '../RelatedDetections';
import { DetectionRelated } from './DetectionRelated';

const { alerts: alertsText, relatedDetection } = i18n.pages.detections;

interface CustomTabProps extends TabProps {
  title?: string;
  itemCount?: number;
  isLoading?: boolean;
}

const CustomTab = React.forwardRef<HTMLButtonElement, CustomTabProps>(
  ({ title, itemCount, isLoading }, ref) => {
    return (
      <ChakraTab ref={ref}>
        {isLoading && <LoadingSpinner size="sm" />}
        {itemCount !== undefined ? `${itemCount} ` : null}
        {title}
        {itemCount !== undefined && itemCount > 1 ? 's' : ' '}
      </ChakraTab>
    );
  },
);

export interface DetectionDetailsTableProps {
  suricataRuleText: string;
  getAppConfigurationsData: string | null;
}

export const DetectionDetailsTable = ({
  suricataRuleText,
  getAppConfigurationsData,
}: DetectionDetailsTableProps) => {
  const {
    alertsData,
    alertsError,
    alertsLoading,
    detectionData,
    detectionError,
  } = useOutletContext<IDetectionContext>();

  const { start, end } = useDateRangeQueryString();

  const detection = detectionData?.getDetections?.detections[0];

  const relatedQuery = useMemo(
    () =>
      `{"query":{"bool":{"must":[{"range":{"total_alert_count":{"gt":0}}},{"range":{"latest_start_timestamp":{"gte":${start}}}}${
        detection?.tenant_info?.tenant_id ? ',' : ''
      }${
        detection?.tenant_info?.tenant_id
          ? `{"terms":{"tenant":["${detection?.tenant_info?.tenant_id}"]}}`
          : ''
      },{"range":{"earliest_start_timestamp":{"lte":${end}}}},{"bool":{"should":[{"term":{"detection_keys.tenant_detection":"${
        detection?.detection_keys?.tenant_detection
      }"}},{"term":{"detection_keys.tenant_entity":"${
        detection?.detection_keys?.tenant_entity
      }"}}]}}],"must_not":{"term":{"detection_id":"${
        detection?.detection_id
      }"}}}}}`,
    [end, start, detection],
  );

  const { loading, data, error } = useQueryDetectionsPaginatedQuery({
    skip: !start || !end || !detection,
    variables: {
      query: relatedQuery,
    },
  });

  return alertsError || detectionError ? (
    <Panel>
      <GraphQLReqStatus
        loading={false}
        isBackground={true}
        error={alertsError || detectionError}
      />
    </Panel>
  ) : (
    <Panel>
      <FlushPanelContent>
        <VStack
          w="100%"
          spacing={0}
          alignItems="start"
          data-testid="detection-alerts"
        >
          <Box w="100%">
            <Tabs size="lg" variant="line" TabComponent={CustomTab}>
              <Tab
                isLoading={loading}
                itemCount={alertsData?.queryAlertsPaginated?.total_items}
                title={alertsText.alert}
              >
                <DetectionAlerts
                  detectionData={detection}
                  suricataRuleText={suricataRuleText}
                  getAppConfigurationsData={getAppConfigurationsData}
                />
              </Tab>
              <Tab
                isLoading={loading}
                itemCount={data?.queryDetectionsPaginated?.total_items}
                title={relatedDetection}
              >
                <DetectionRelated />
              </Tab>
            </Tabs>
          </Box>
        </VStack>
      </FlushPanelContent>
    </Panel>
  );
};
