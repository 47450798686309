import { createIcon } from '@chakra-ui/react';

export const TechChecklistWarning = createIcon({
  displayName: 'TechChecklistWarning',
  viewBox: '0 0 294 240',
  path: (
    <>
      <g clipPath="url(#a)">
        <path
          d="M262.756 130.05c0 53.771-38.681 98.536-89.814 108.142-.2.039-.403.075-.605.112a109.271 109.271 0 0 1-10.282 1.377c-3.187.278-6.413.417-9.677.419-.203 0-.402 0-.605-.003-3.26-.015-6.485-.171-9.677-.47-.202-.015-.402-.036-.605-.057a108.277 108.277 0 0 1-10.281-1.493C80.375 228.233 42 183.61 42 130.05 42 69.272 91.42 20 152.378 20c60.959 0 110.378 49.272 110.378 110.05Z"
          fill="#283139"
        />
        <path
          d="M151.808 112.161a3.026 3.026 0 0 1-2.794-1.861 3.006 3.006 0 0 1 .655-3.286 3.029 3.029 0 0 1 4.653.457 3.007 3.007 0 0 1-.377 3.806 3.034 3.034 0 0 1-2.137.884Z"
          fill="#00FF8F"
        />
        <path
          d="m277.608 86.84-54.135-.91.01-.603 53.521.899V60.301h.604V86.84ZM193.214 83.935l-.558-.232 28.503-68.025h45.563v.603h-45.16l-28.348 67.654ZM152.11 101.447H15.725V68.583h.605v32.261h135.78v.603ZM141.526 66.935h-90.42V30.15h.605v36.18h89.815v.604ZM90.42 200.804H30.24v-.603h59.574v-55.176h.605v55.779Z"
          fill="#8DABC4"
        />
        <path
          opacity=".3"
          d="M16.027 92.261c8.852 0 16.028-7.154 16.028-15.98 0-8.825-7.176-15.98-16.027-15.98C7.175 60.301 0 67.456 0 76.281c0 8.826 7.176 15.98 16.027 15.98Z"
          fill="#CCC"
        />
        <path
          d="M16.028 85.327c5.01 0 9.072-4.05 9.072-9.046 0-4.995-4.062-9.045-9.072-9.045-5.01 0-9.072 4.05-9.072 9.045 0 4.996 4.061 9.046 9.072 9.046Z"
          fill="#00FF8F"
        />
        <path
          opacity=".3"
          d="M266.722 31.96c8.851 0 16.027-7.155 16.027-15.98 0-8.826-7.176-15.98-16.027-15.98-8.852 0-16.028 7.154-16.028 15.98 0 8.825 7.176 15.98 16.028 15.98Z"
          fill="#CCC"
        />
        <path
          d="M266.722 25.025c5.01 0 9.072-4.05 9.072-9.045 0-4.996-4.062-9.045-9.072-9.045-5.011 0-9.073 4.05-9.073 9.045s4.062 9.045 9.073 9.045Z"
          fill="#00FF8F"
        />
        <path
          opacity=".3"
          d="M277.306 76.281c8.852 0 16.027-7.154 16.027-15.98 0-8.825-7.175-15.98-16.027-15.98s-16.028 7.155-16.028 15.98c0 8.826 7.176 15.98 16.028 15.98Z"
          fill="#CCC"
        />
        <path
          d="M277.306 69.347c5.01 0 9.072-4.05 9.072-9.046 0-4.995-4.062-9.045-9.072-9.045-5.011 0-9.072 4.05-9.072 9.045 0 4.996 4.061 9.046 9.072 9.046Z"
          fill="#00FF8F"
        />
        <path
          opacity=".3"
          d="M30.24 216.482c8.852 0 16.028-7.154 16.028-15.98 0-8.825-7.176-15.979-16.027-15.979-8.852 0-16.028 7.154-16.028 15.979 0 8.826 7.176 15.98 16.028 15.98Z"
          fill="#CCC"
        />
        <path
          d="M30.24 209.548c5.011 0 9.073-4.05 9.073-9.045 0-4.996-4.062-9.046-9.072-9.046-5.01 0-9.072 4.05-9.072 9.046 0 4.995 4.061 9.045 9.072 9.045ZM90.117 148.342a3.023 3.023 0 0 1-2.794-1.862 3.006 3.006 0 0 1 .656-3.285 3.03 3.03 0 0 1 4.652.457 3.005 3.005 0 0 1-.377 3.806 3.034 3.034 0 0 1-2.137.884ZM141.526 69.648a3.03 3.03 0 0 1-2.794-1.861 3.003 3.003 0 0 1 .655-3.286 3.028 3.028 0 0 1 3.296-.653 3.018 3.018 0 0 1 1.867 2.785c-.001.8-.32 1.566-.887 2.131a3.031 3.031 0 0 1-2.137.884ZM223.78 88.342a3.03 3.03 0 0 1-2.794-1.862 3.011 3.011 0 0 1 2.204-4.11 3.03 3.03 0 0 1 3.105 1.281 3.01 3.01 0 0 1-.378 3.807 3.03 3.03 0 0 1-2.137.884ZM192.935 86.834a3.03 3.03 0 0 1-2.794-1.861 3.003 3.003 0 0 1 .655-3.286 3.027 3.027 0 0 1 3.296-.653 3.018 3.018 0 0 1 1.867 2.785c-.001.8-.32 1.566-.887 2.131a3.032 3.032 0 0 1-2.137.884Z"
          fill="#00FF8F"
        />
        <path
          d="M141 159h136.385v32.864h-.605v-32.261H141V159Z"
          fill="#8DABC4"
        />
        <path
          opacity=".3"
          d="M277.082 168.186c-8.851 0-16.027 7.154-16.027 15.98 0 8.825 7.176 15.98 16.027 15.98 8.852 0 16.028-7.155 16.028-15.98 0-8.826-7.176-15.98-16.028-15.98Z"
          fill="#CCC"
        />
        <path
          d="M277.082 175.121c-5.01 0-9.072 4.049-9.072 9.045 0 4.995 4.062 9.045 9.072 9.045 5.011 0 9.072-4.05 9.072-9.045 0-4.996-4.061-9.045-9.072-9.045Z"
          fill="#00FF8F"
        />
        <path
          opacity=".3"
          d="M52.014 46.13c8.852 0 16.027-7.154 16.027-15.98 0-8.825-7.175-15.98-16.027-15.98s-16.028 7.155-16.028 15.98c0 8.826 7.176 15.98 16.028 15.98Z"
          fill="#CCC"
        />
        <path
          d="M52.014 39.196c5.01 0 9.072-4.05 9.072-9.045 0-4.996-4.062-9.046-9.072-9.046-5.01 0-9.072 4.05-9.072 9.046 0 4.995 4.061 9.045 9.072 9.045Z"
          fill="#00FF8F"
        />
        <g clipPath="url(#b)">
          <path
            d="M184.748 95.53v87.852h-65.496V95.53h65.496Z"
            fill="#283139"
          />
          <path
            d="M196 186.065h-88v-34.873h88v34.873ZM196 147.168h-88v-34.872h88v34.872ZM196 108.272h-88V73.4h88v34.872Z"
            fill="#3B454E"
          />
          <path
            d="M196 171.279h-88v-5.365h88v5.365ZM196 132.382h-88v-5.365h88v5.365ZM196 93.486h-88v-5.365h88v5.365ZM178.87 179.023c1.113 0 2.016.901 2.016 2.012a2.014 2.014 0 0 1-2.016 2.012 2.014 2.014 0 0 1-2.015-2.012c0-1.111.902-2.012 2.015-2.012ZM185.924 179.023c1.113 0 2.015.901 2.015 2.012a2.014 2.014 0 0 1-2.015 2.012 2.014 2.014 0 0 1-2.016-2.012c0-1.111.903-2.012 2.016-2.012ZM192.977 179.023c1.113 0 2.015.901 2.015 2.012a2.014 2.014 0 0 1-2.015 2.012 2.014 2.014 0 0 1-2.015-2.012c0-1.111.902-2.012 2.015-2.012ZM178.87 140.798c1.113 0 2.016.9 2.016 2.011a2.014 2.014 0 0 1-2.016 2.012 2.013 2.013 0 1 1 0-4.023ZM185.924 140.798a2.013 2.013 0 1 1 0 4.023c-1.113 0-2.016-.9-2.016-2.012 0-1.111.903-2.011 2.016-2.011ZM192.977 140.798a2.013 2.013 0 1 1 0 4.023 2.013 2.013 0 1 1 0-4.023ZM178.87 102.572c1.113 0 2.016.901 2.016 2.012a2.014 2.014 0 0 1-2.016 2.012 2.014 2.014 0 0 1-2.015-2.012c0-1.111.902-2.012 2.015-2.012ZM185.924 102.572c1.113 0 2.015.901 2.015 2.012a2.014 2.014 0 0 1-2.015 2.012 2.014 2.014 0 0 1-2.016-2.012c0-1.111.903-2.012 2.016-2.012ZM192.977 102.572c1.113 0 2.015.901 2.015 2.012a2.014 2.014 0 0 1-2.015 2.012 2.014 2.014 0 0 1-2.015-2.012c0-1.111.902-2.012 2.015-2.012Z"
            fill="#00FF8F"
          />
        </g>
        <path
          opacity=".1"
          d="M130.594 198.416h43.647a2.448 2.448 0 0 0 2.089-1.196 2.446 2.446 0 0 0 .051-2.408l-21.812-40.527a2.443 2.443 0 0 0-4.302 0l-21.824 40.527a2.437 2.437 0 0 0 .054 2.412 2.44 2.44 0 0 0 2.097 1.192Zm24.601-7.405h-5.555v-5.552h5.555v5.552Zm0-9.255h-5.555v-11.105h5.555v11.105Z"
          fill="#000"
        />
        <path
          d="M130.594 199.754h43.647a2.439 2.439 0 0 0 2.089-1.197 2.445 2.445 0 0 0 .051-2.407l-21.812-40.527a2.446 2.446 0 0 0-3.404-.94 2.443 2.443 0 0 0-.898.94l-21.824 40.527a2.451 2.451 0 0 0 .054 2.412 2.45 2.45 0 0 0 2.097 1.192Zm24.601-7.406h-5.555v-5.551h5.555v5.551Zm0-9.254h-5.555v-11.106h5.555v11.106Z"
          fill="#FBB900"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h293.333v240H0z" />
        </clipPath>
        <clipPath id="b">
          <path
            fill="#fff"
            transform="matrix(1 0 0 -1 108 186)"
            d="M0 0h88v113H0z"
          />
        </clipPath>
      </defs>
    </>
  ),
  defaultProps: {
    height: '240px',
    width: '294px',
  },
});
