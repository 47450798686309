import { createIcon } from '@chakra-ui/react';

export const SplunkHec = createIcon({
  displayName: 'SplunkHec',
  viewBox: '0 0 36 36',
  path: (
    <path
      fill="#64A637"
      d="M3 6.857V0l30.078 14.805v6.546L3 36v-6.546l22.91-11.532L3 6.857Z"
    />
  ),
  defaultProps: {
    height: '36px',
    width: '36px',
  },
});
