import { screen, within } from '@testing-library/react';

export const getSelectInput = async ({
  selectTestId,
  labelText,
}: {
  selectTestId: string;
  labelText: string;
}) => {
  const selectElement = await screen.findByTestId(selectTestId);
  return {
    input: within(selectElement).getByLabelText(labelText),
  };
};
