import { createIcon } from '@chakra-ui/react';
import React from 'react';

export const FullCircleRefreshIcon = createIcon({
  displayName: 'FullCircleRefreshIcon',
  viewBox: '0 0 14 12',
  path: (
    <path
      d="M7 11.9792C5.33333 11.9792 3.90972 11.3958 2.72917 10.2292C1.5625 9.0625 0.979167 7.65278 0.979167 6C0.979167 4.34722 1.5625 2.9375 2.72917 1.77083C3.90972 0.604166 5.33333 0.0208328 7 0.0208328C7.91667 0.0208328 8.76389 0.215277 9.54167 0.604166C10.3194 0.979165 10.9861 1.49305 11.5417 2.14583V0.0208328H13.0208V5.04167H8V3.5625H10.5833C10.1944 2.97917 9.68056 2.51389 9.04167 2.16667C8.41667 1.81944 7.73611 1.64583 7 1.64583C5.77778 1.64583 4.73611 2.06944 3.875 2.91667C3.02778 3.76389 2.60417 4.79167 2.60417 6C2.60417 7.20833 3.02778 8.23611 3.875 9.08333C4.73611 9.93056 5.77778 10.3542 7 10.3542C8.125 10.3542 9.09028 9.98611 9.89583 9.25C10.7014 8.5 11.1667 7.57639 11.2917 6.47917H12.9583C12.8472 8.03472 12.2153 9.34028 11.0625 10.3958C9.92361 11.4514 8.56945 11.9792 7 11.9792Z"
      fill="white"
    />
  ),
  defaultProps: {
    height: '12px',
    width: '14px',
    fill: 'none',
  },
});
