export const layerStyles = {
  base: {
    bg: 'layer.0',
    borderColor: 'border.layer.0',
    // no border by default, but the border color is set so you can add a border should you choose
  },
  first: {
    bg: 'layer.1',
    border: 'solid 1px',
    borderColor: 'border.layer.1',
  },
  second: {
    bg: 'layer.2',
    border: 'solid 1px',
    borderColor: 'border.layer.2',
  },
  third: {
    bg: 'layer.3',
    border: 'solid 1px',
    borderColor: 'border.layer.3',
  },
};
