import { Flex, HStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface SplitLayoutControlsProps {
  actions?: ReactNode;
  'data-testid'?: string;
}

export const SplitLayoutControls = ({
  actions,
  'data-testid': dataTestId = 'gamma-split-layout-controls',
}: SplitLayoutControlsProps) => {
  return (
    <HStack
      px={4}
      pt={4}
      pb={2}
      justify="space-between"
      data-testid={dataTestId}
    >
      {actions && (
        <Flex w="100%" h="full" data-testid={`${dataTestId}-actions`}>
          {actions}
        </Flex>
      )}
    </HStack>
  );
};
