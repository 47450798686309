import { Link, Text } from '@chakra-ui/react';
import { URLS } from '@gamma/investigator/constants';
import { Panel } from '@gamma/layout';

interface HumioUnavailableProps {
  unavailable: string;
  techSupport: string;
}

export const HumioUnavailable = ({
  unavailable,
  techSupport,
}: HumioUnavailableProps) => {
  return (
    <Panel>
      <Text data-testid="no-dashboards-found">
        {unavailable}&nbsp;
        <Link
          isExternal
          color="blue.400"
          href={URLS.CORELIGHT_SUPPORT}
          data-testid="external-support-link"
        >
          {techSupport}
        </Link>
        .
      </Text>
    </Panel>
  );
};
