import { Flex, FlexProps } from '@chakra-ui/react';
import { forwardRef, ReactNode } from 'react';

export type BoardProps = FlexProps;

export const Board = forwardRef<HTMLDivElement, BoardProps>(
  ({ children, ...rest }, ref) => {
    return (
      <Flex
        ref={ref}
        w={`calc(100% + var(--chakra-space-4))`}
        m="-2"
        direction="row"
        flexWrap="wrap"
        {...rest}
      >
        {children}
      </Flex>
    );
  },
);
