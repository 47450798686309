import { Button, ButtonGroup, chakra, useToast } from '@chakra-ui/react';
import { Input } from '@gamma/form-fields';
import { Illustrations } from '@gamma/icons';
import { AuthModal, IOnMFACompleted } from '@gamma/investigator/components';
import { CONSTANTS } from '@gamma/investigator/constants';
import { AuthContext, CookieContext } from '@gamma/investigator/context';
import { useLogOperationCall, useUserSession } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { useUpdateProfile } from '@gamma/investigator/queries';
import {
  Board,
  Column,
  PageHeader,
  Panel,
  PanelHeader,
  PanelHeading,
} from '@gamma/layout';
import { LoadingSpinner } from '@gamma/progress';
import { Auth } from 'aws-amplify';
import { useContext, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { ChangePasswordOutlet } from './ChangePasswordOutlet';

export interface AccountSettingsForm {
  alias: string;
  email: string;
  role: string;
}

const getUserPrefferedMFA = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return await Auth.getPreferredMFA(user);
  } catch (error: any) {
    return new Error(error?.message ?? 'error with getUserPrefferedMFA');
  }
};

export const Account = () => {
  const { user, userAlias, setUserAlias } = useContext(AuthContext);
  const { setCookieConfig, isLoaded, getUserCookieAcceptance } =
    useContext(CookieContext);
  const {
    aliasUpdate: { toasts },
    accSettings,
    change2FA: { heading, helperTexts, error },
    usrProfile,
    update2FA,
  } = i18n.pages.userAccount;

  const isSSOLogin = JSON.parse(
    window.sessionStorage.getItem('isSSOLogin') as string,
  );

  const [isUserMfaEnabled, setIsUserMfaEnabled] = useState<boolean>(true);

  const [updateProfile, { loading: updateProfileLoading }] = useUpdateProfile();

  const { passwordIncorrect } = i18n.forms.validation;
  const { deregisterUserMFA } = useUserSession();
  const logOperationCall = useLogOperationCall();

  useEffect(() => {
    const getAttributes = async () => {
      const prefferedMFA = await getUserPrefferedMFA();

      setIsUserMfaEnabled(
        prefferedMFA === CONSTANTS.MFA_OPTIONS.SOFTWARE_TOKEN_MFA,
      );
    };
    getAttributes();
  }, []);

  const defaultValues = {
    alias: userAlias,
    email: user?.attributes.email,
    role: user?.signInUserSession?.accessToken?.payload['cognito:groups']?.[0],
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm<AccountSettingsForm>({
    mode: 'onChange',
    defaultValues,
  });

  const formData = watch();

  const showToastMessage = useToast();

  const onAliasUpdateSubmit = async (data: FieldValues) => {
    const alias = data?.alias.trim();
    try {
      const updatedResponse = await updateProfile({
        variables: {
          input: {
            alias,
          },
        },
      });
      if (setUserAlias && updatedResponse?.data.updateProfile) {
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        setUserAlias?.(user?.attributes['custom:alias']);
      }
      reset({ alias });
      showToastMessage({
        status: 'success',
        title: 'Success',
        description: toasts.success,
        isClosable: true,
        position: 'bottom-right',
      });
      const updatedAliasInput = { alias: alias };
      logOperationCall(
        'ALIAS_UPDATE',
        'USER',
        JSON.stringify(updatedAliasInput),
      );
    } catch (err) {
      reset();
      showToastMessage({
        status: 'error',
        title: '',
        description: toasts.error,
        isClosable: true,
        position: 'bottom-right',
      });
    }
  };

  const onMFACompleted = async ({
    setOnMFACompletedLoading,
    onClose,
  }: IOnMFACompleted) => {
    setOnMFACompletedLoading(true);
    try {
      logOperationCall('MFA_UPDATE', 'USER');
      await deregisterUserMFA();
    } catch {
      showToastMessage({
        status: 'error',
        title: 'Error',
        description: error,
        isClosable: true,
        position: 'bottom-right',
      });
    }
    setOnMFACompletedLoading(false);
    onClose();
  };

  const noMFAUser = user?.attributes['custom:allow_no_mfa'];

  const handleOpenCookiePolicy = () => {
    setCookieConfig?.((prev) => {
      return {
        ...prev,
        isOpen: !prev.isOpen,
        initialStep: 2,
      };
    });
  };

  const [userHasSetCookies, setUserHasSetCookies] = useState<boolean>(false);

  useEffect(() => {
    if (getUserCookieAcceptance.length === 2) {
      setUserHasSetCookies(true);
    }
  }, [getUserCookieAcceptance]);

  return (
    <Board>
      <Column>
        <PageHeader
          variant="flushed"
          actions={
            <ButtonGroup alignItems="center" variant="solid" colorScheme="gray">
              {!isLoaded ? (
                <LoadingSpinner size="md" />
              ) : (
                userHasSetCookies && (
                  <Button
                    onClick={handleOpenCookiePolicy}
                    disabled={!isLoaded}
                    data-testid="cookie-settings-button"
                  >
                    {i18n.buttons.cookieSettings}
                  </Button>
                )
              )}
              {!isSSOLogin && (
                <ChangePasswordOutlet
                  isUserMfaEnabled={isUserMfaEnabled}
                  email={user?.attributes.email}
                />
              )}
              {!noMFAUser && !isSSOLogin && (
                <AuthModal
                  title={heading}
                  helperTexts={helperTexts}
                  onMFACompleted={onMFACompleted}
                  loginIcon={Illustrations.LockedShield}
                  showAlert={true}
                  errorMessage={passwordIncorrect}
                  width="full"
                >
                  <Button>{update2FA}</Button>
                </AuthModal>
              )}
            </ButtonGroup>
          }
        >
          <chakra.span data-testid="account-settings">
            {accSettings}
          </chakra.span>
        </PageHeader>
        <Panel>
          <PanelHeader divider>
            <PanelHeading data-testid="user-profile">{usrProfile}</PanelHeading>
          </PanelHeader>
          <Board>
            <Column>
              <Board as="form" onSubmit={handleSubmit(onAliasUpdateSubmit)}>
                <Column col={[12, 3]}>
                  <Input
                    data-testid="alias-input"
                    {...register('alias', {
                      required: i18n.forms.validation.aliasRequired,
                      maxLength: {
                        value: 30,
                        message: i18n.forms.validation.maxLength,
                      },
                      pattern: {
                        value: /[^-\s]/,
                        message: i18n.forms.validation.aliasRequired,
                      },
                    })}
                    label={i18n.forms.labels.alias}
                    placeholder="-"
                    autoComplete="off"
                    error={errors.alias?.message}
                    isReadOnly={updateProfileLoading}
                  />
                </Column>
                <Column col={[12, 3]}>
                  <Input
                    data-testid="email-input"
                    {...register('email')}
                    label={i18n.forms.labels.email}
                    isReadOnly
                  />
                </Column>
                <Column col={[12, 3]}>
                  <Input
                    data-testid="role-input"
                    {...register('role')}
                    label={i18n.forms.labels.role}
                    isReadOnly
                  />
                </Column>
                <Column>
                  <Button
                    variant="solid"
                    size="md"
                    type="submit"
                    data-testid="save-button"
                    isLoading={updateProfileLoading}
                    isDisabled={
                      formData.alias === userAlias || Boolean(errors?.alias)
                    }
                  >
                    {i18n.buttons.save}
                  </Button>
                </Column>
              </Board>
            </Column>
          </Board>
        </Panel>
      </Column>
    </Board>
  );
};
