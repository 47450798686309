import { useCallback, useMemo } from 'react';
import { Column, ColumnInstance, HeaderGroup } from 'react-table';

export interface UseAdjustColumnIndexParams<DataType extends {}> {
  isSelectable?: boolean;
  isExpandable?: boolean;
  columns: readonly Column<DataType>[];
}

export const useAdjustColumnIndex = <DataType extends {}>({
  isSelectable,
  isExpandable,
  columns,
}: UseAdjustColumnIndexParams<DataType>) => {
  const columnIndexAdjustment = useMemo(() => {
    let adjustmentAmount = 0;

    if (isExpandable) {
      adjustmentAmount += 1;
    }

    if (isSelectable) {
      adjustmentAmount += 1;
    }

    return adjustmentAmount;
  }, [isExpandable, isSelectable]);

  const getOriginalColumn = useCallback(
    (
      columnIndex: number,
      column: ColumnInstance<DataType> | HeaderGroup<DataType>,
    ) => {
      // get the original column object for the cell
      const originalColumnIndex = columnIndex - columnIndexAdjustment;
      const originalColumn = ['expanded', 'selection'].includes(column.id)
        ? column
        : columns[originalColumnIndex];

      return originalColumn;
    },
    [columnIndexAdjustment, columns],
  );

  return { columnIndexAdjustment, getOriginalColumn };
};
