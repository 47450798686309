import { Stack } from '@chakra-ui/react';
import { FieldValidationListPopover, Input } from '@gamma/form-fields';
import { IPasswordRules } from '@gamma/forms';
import { i18n } from '@gamma/investigator/localization';
import {
  IPasswordChangeForm,
  PasswordChangeFormProps,
} from '@gamma/investigator/queries';
import { formatOTPCode } from '@gamma/util';
import { trim } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

const { content, error, title } = i18n.pages.passwordChange;

export const PasswordChangeForm = ({
  newPasswordContent = {
    placeholder: content.newPassword,
    label: content.newPassword,
  },
  confirmPasswordContent = {
    placeholder: content.confirmPassword,
    label: content.confirmPassword,
  },
  otpCodeContent = {
    placeholder: 'XXX XXX',
    label: content.otpCode,
  },
  currentPasswordContent = {
    placeholder: content.currentPassword,
    label: content.currentPassword,
  },
  onSubmit: [onSubmitSuccess, onSubmitError],
  hasOTP,
  hasCurrentPassword,
  isLabelHidden = true,
  hasValidationPopover = true,
  isPasswordFilled = false,
  onPasswordValidation,
  formError,
  setFormError,
}: PasswordChangeFormProps) => {
  const {
    handleSubmit,
    register,
    getValues,
    trigger,
    setValue,
    setError,
    setFocus,
    formState: { errors },
  } = useForm<IPasswordChangeForm>();

  const [rulesMatch, setRulesMatch] = useState<IPasswordRules>(
    {} as IPasswordRules,
  );

  useEffect(() => {
    switch (trim(formError)) {
      case error.codeMismatch:
        setError(
          'otpCode',
          {
            message: error.otpInvalid,
          },
          { shouldFocus: true },
        );
        break;
      case error.otpInUse:
        setError(
          'otpCode',
          {
            message: error.otpInUse,
          },
          { shouldFocus: true },
        );
        break;
      case error.incorrect:
        setError('currentPassword', {}, { shouldFocus: true });
        setValue('otpCode', '');
        break;
    }
  }, [formError]);

  const handleOTPInputChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setValue('otpCode', formatOTPCode(value));
    setFormError?.('');
  };

  const checkPasswordValidation = (password: string) => {
    const validationRules = {
      minLength: password.length >= 8,
      hasNumber: RegExp(/[0-9]/g).test(password),
      hasSpecialCharacter: RegExp(/[^\w\s]/g).test(password),
      hasUppercase: RegExp(/[A-Z]/g).test(password),
      hasLowercase: RegExp(/[a-z]/g).test(password),
    };
    onPasswordValidation?.(validationRules);
    setRulesMatch(validationRules);
  };

  const handleSubmitSuccess: SubmitHandler<IPasswordChangeForm> = (values) => {
    const { otpCode, ...rest } = values;
    onSubmitSuccess({ otpCode: otpCode?.replace(' ', ''), ...rest });
  };

  const newPasswordInput = (
    <Input
      {...newPasswordContent}
      type="password"
      autoComplete="new-password"
      data-testid="new-password-input"
      {...register('newPassword', {
        required: error.required,
        minLength: { value: 8, message: error.minLength },
        validate: {
          hasNumber: (value: string) =>
            RegExp(/[0-9]/g).test(value) || error.oneDigit,
          hasSpecialCharacter: (value: string) =>
            RegExp(/[^\w\s]/g).test(value) || error.oneChar,
          hasUppercase: (value: string) =>
            RegExp(/[A-Z]/g).test(value) || error.oneUpper,
          hasLowercase: (value: string) =>
            RegExp(/[a-z]/g).test(value) || error.oneLower,
          doesNotMatchCurrentPassword: (value: string) => {
            const currentPassword = getValues('currentPassword');
            return value !== currentPassword || error.pwsSame;
          },
        },
        onChange: ({ target }) => checkPasswordValidation(target.value),
      })}
      error={errors['newPassword']?.message}
      isRequired
      isLabelHidden={isLabelHidden}
    />
  );

  useEffect(() => {
    if (hasCurrentPassword && !isPasswordFilled) {
      setFocus('currentPassword');
    } else if (hasOTP && isPasswordFilled) {
      setFocus('otpCode');
    }
  }, [isPasswordFilled]);

  return (
    <Stack
      as="form"
      id="passwordChangeForm"
      spacing={4}
      onSubmit={handleSubmit(handleSubmitSuccess, onSubmitError)}
    >
      {hasCurrentPassword && !isPasswordFilled && (
        <Input
          {...currentPasswordContent}
          type="password"
          autoComplete="current-password"
          data-testid="current-password-input"
          {...register('currentPassword', { required: error.required })}
          isLabelHidden={isLabelHidden}
          error={errors['currentPassword']?.message}
          isRequired
        />
      )}

      {!isPasswordFilled && (
        <>
          {hasValidationPopover ? (
            <FieldValidationListPopover
              title={title}
              rules={[
                {
                  isValid: rulesMatch.minLength,
                  message: error.minLength,
                },
                {
                  isValid: rulesMatch.hasNumber,
                  message: error.oneDigit,
                },
                {
                  isValid: rulesMatch.hasSpecialCharacter,
                  message: error.oneChar,
                },
                {
                  isValid: rulesMatch.hasUppercase,
                  message: error.oneUpper,
                },
                {
                  isValid: rulesMatch.hasLowercase,
                  message: error.oneLower,
                },
              ]}
            >
              {newPasswordInput}
            </FieldValidationListPopover>
          ) : (
            <>{newPasswordInput}</>
          )}
          <Input
            {...confirmPasswordContent}
            type="password"
            autoComplete="new-password"
            data-testid="confirm-new-password-input"
            {...register('confirmPassword', {
              validate: {
                matchesPassword: (value: string) => {
                  const password = getValues('newPassword');
                  return value === password || error.pwsNoMatch;
                },
              },
              onChange: () => trigger('confirmPassword'),
            })}
            error={errors['confirmPassword']?.message}
            isRequired
            isLabelHidden={isLabelHidden}
          />
        </>
      )}
      {hasOTP && isPasswordFilled && (
        <Input
          {...otpCodeContent}
          autoComplete="one-time-code"
          data-testid="one-time-code-input"
          {...register('otpCode', {
            required: error.required,
            onChange: handleOTPInputChange,
          })}
          isLabelHidden={isLabelHidden}
          error={errors['otpCode']?.message}
          isRequired
        />
      )}
    </Stack>
  );
};
