import {
  Badge,
  Flex,
  HStack,
  Tag,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { SeverityScore } from '@gamma/investigator/components';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { Detection } from '@gamma/investigator/queries';
import _ from 'lodash';
import moment from 'moment';
import { useContext } from 'react';

import { SantizedDetection } from '../../Detections';
import { SendToPill } from '../DetectionPills';
import { DetectionStatusPill } from '../DetectionStatusPill';

interface DetectionRowProps {
  item: Detection | SantizedDetection;
}

const { detection, alerts } = i18n.pages.detections;
const { unassigned, externalTenant } = detection;

export const DetectionRow = ({ item }: DetectionRowProps) => {
  const { isOrgTenant } = useContext(AuthContext);

  const isCustomSeverityScore = item?.rank?.is_custom_severity;

  return (
    <Flex flexGrow={1} cursor="pointer" data-testid="detection-row">
      {item?.rank?.severity !== null && item?.rank?.severity !== undefined && (
        <SeverityScore
          isCustom={isCustomSeverityScore}
          score={item.rank.severity}
        />
      )}
      <Flex
        flexGrow={1}
        direction="column"
        ml={
          item?.rank?.severity !== null && item?.rank?.severity !== undefined
            ? 2
            : 0
        }
      >
        <VStack alignItems="start">
          <HStack spacing={1}>
            <Text
              fontWeight="600"
              textStyle="body-lg"
              data-testid="detection-alert-name"
            >
              {item?.alert_info?.alert_name} | {item?.alert_entity?.entity_name}
            </Text>
            {isOrgTenant && (
              <Badge variant="outline">
                {item?.tenant_info?.tenant_display_name}
              </Badge>
            )}
          </HStack>
          <Text pb={1} textStyle="body-md" color="text.200">
            {i18n.formatString(
              detection.detectionDetails,
              String(item?.total_alert_count),
              _.lowerCase(
                item?.total_alert_count === 1 ? alerts.alert : alerts.alerts,
              ),
              String(
                moment
                  .unix(item?.earliest_start_timestamp)
                  .format('MMMM Do, h:mma'),
              ),
              String(
                moment
                  .unix(item?.latest_start_timestamp)
                  .format('MMMM Do, h:mma'),
              ),
            )}
          </Text>
          <HStack spacing={4}>
            <HStack>
              <DetectionStatusPill
                text={item?.detection_status}
                isStatusOpen={item?.detection_status === 'open'}
              />
              {item?.escalation_data?.escalation_status && (
                <SendToPill escalation_data={item?.escalation_data} />
              )}
            </HStack>
            <Tag
              size="md"
              variant="solid"
              data-testid="detection-alert-assignee"
              colorScheme={
                item?.assignment_info?.assigned_to_user_alias
                  ? 'purple'
                  : 'gray'
              }
            >
              <Tooltip
                label={
                  item?.assignment_info?.assigned_to_user_alias ===
                  'External User'
                    ? externalTenant
                    : ''
                }
              >
                {!item?.assignment_info?.assigned_to_user_alias
                  ? unassigned
                  : item?.assignment_info?.assigned_to_user_alias}
              </Tooltip>
            </Tag>
          </HStack>
        </VStack>
      </Flex>
    </Flex>
  );
};
