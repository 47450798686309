// @ts-nocheck
// yes, yes, I know, "bad TS dev!" 😡 but this is a copy-paste of a JS file
// whos types were NOT very well defined or utilized, and I can't fix them
// and they are causing build failures even though it's totally fine.
import React, { MouseEvent, TouchEvent } from 'react';
import {
  actions,
  ActionType,
  ColumnInstance,
  defaultColumn,
  ensurePluginOrder,
  HeaderGroup,
  Hooks,
  makePropGetter,
  TableInstance,
  TableState,
  useGetLatest,
  useMountedLayoutEffect,
} from 'react-table';

// Default Column
defaultColumn.canResize = true;

// Actions
actions.columnStartResizing = 'columnStartResizing';
actions.columnResizing = 'columnResizing';
actions.columnDoneResizing = 'columnDoneResizing';
actions.resetResize = 'resetResize';

/**
 * ! Duplicate of react-table's `useResizeColumns` hook, with minor adjustments
 * the original hook uses a percentage based delta value for determining the
 * adjusted column width, but this caused jittering with our tables since we use
 * exact pixel values instead.
 *
 * The adjustments made here are to make it work slightly better with typescript
 * as well as to switch to using a pixel value for adjusted column widths
 */
export const useResizeColumns = <DataType extends Record<string, unknown>>(
  hooks: Hooks<DataType>,
) => {
  hooks.getResizerProps = [defaultGetResizerProps];
  hooks.getHeaderProps.push({
    style: {
      position: 'relative',
    },
  });
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
  hooks.useInstanceBeforeDimensions.push(useInstanceBeforeDimensions);
};

const defaultGetResizerProps = <DataType extends Record<string, unknown>>(
  props: unknown,
  {
    instance,
    header,
  }: { instance: TableInstance<DataType>; header: HeaderGroup<DataType> },
) => {
  const { dispatch } = instance;

  const onResizeStart = (
    e: TouchEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>,
    header: HeaderGroup<DataType>,
  ) => {
    // * get the current width of the column when resizing starts
    const parentStartingWidth = (
      e.target as HTMLElement
    ).parentElement?.getBoundingClientRect().width;

    let isTouchEvent = false;
    if (e.type === 'touchstart') {
      // lets not respond to multiple touches (e.g. 2 or 3 fingers)
      if (e.touches && e.touches.length > 1) {
        return;
      }
      isTouchEvent = true;
    }
    const headersToResize = getLeafHeaders(header);
    const headerIdWidths = headersToResize.map((d) => [d.id, d.totalWidth]);

    const clientX = isTouchEvent ? Math.round(e.touches[0].clientX) : e.clientX;

    const dispatchMove = (clientXPos: number) => {
      dispatch({
        type: actions.columnResizing,
        clientX: clientXPos,
        parentStartingWidth,
      });
    };
    const dispatchEnd = () => dispatch({ type: actions.columnDoneResizing });

    const handlersAndEvents = {
      mouse: {
        moveEvent: 'mousemove',
        moveHandler: (e) => {
          dispatchMove(e.clientX);
        },
        upEvent: 'mouseup',
        upHandler: (e) => {
          document.removeEventListener(
            'mousemove',
            handlersAndEvents.mouse.moveHandler,
          );
          document.removeEventListener(
            'mouseup',
            handlersAndEvents.mouse.upHandler,
          );
          dispatchEnd();
        },
      },
      touch: {
        moveEvent: 'touchmove',
        moveHandler: (e) => {
          if (e.cancelable) {
            e.preventDefault();
            e.stopPropagation();
          }
          dispatchMove(e.touches[0].clientX);
          return false;
        },
        upEvent: 'touchend',
        upHandler: (e) => {
          document.removeEventListener(
            handlersAndEvents.touch.moveEvent,
            handlersAndEvents.touch.moveHandler,
          );
          document.removeEventListener(
            handlersAndEvents.touch.upEvent,
            handlersAndEvents.touch.moveHandler,
          );
          dispatchEnd();
        },
      },
    };

    const events = isTouchEvent
      ? handlersAndEvents.touch
      : handlersAndEvents.mouse;
    document.addEventListener(events.moveEvent, events.moveHandler);
    document.addEventListener(events.upEvent, events.upHandler);

    dispatch({
      type: actions.columnStartResizing,
      columnId: header.id,
      columnWidth: header.totalWidth,
      headerIdWidths,
      clientX,
    });
  };

  return [
    props,
    {
      onMouseDown: (e: MouseEvent<HTMLDivElement>) => onResizeStart(e, header),
      onTouchStart: (e: TouchEvent<HTMLDivElement>) => onResizeStart(e, header),
      style: {
        cursor: 'col-resize',
      },
      draggable: false,
      role: 'separator',
    },
  ];
};

useResizeColumns.pluginName = 'useResizeColumns';

function reducer<DataType extends Record<string, unknown>>(
  state: TableState<DataType>,
  action: ActionType,
) {
  if (action.type === actions.init) {
    return {
      ...state,
      columnResizing: {
        columnWidths: {},
      },
    };
  }

  if (action.type === actions.resetResize) {
    return {
      ...state,
      columnResizing: {
        columnWidths: {},
      },
    };
  }

  if (action.type === actions.columnStartResizing) {
    const { clientX, columnId, columnWidth, headerIdWidths = [] } = action;

    return {
      ...state,
      columnResizing: {
        ...state.columnResizing,
        startX: clientX,
        headerIdWidths,
        columnWidth,
        isResizingColumn: columnId,
      },
    };
  }

  if (action.type === actions.columnResizing) {
    const { clientX, parentStartingWidth } = action;
    const { startX = 0, headerIdWidths = [] } = state.columnResizing;

    const deltaX = clientX - startX;

    const newColumnWidths: Record<string, number> = {};

    (headerIdWidths as unknown as [string, number][])?.forEach(
      ([headerId, headerWidth]) => {
        // original, percentage based width adjustment
        // const percentageDeltaX = deltaX / columnWidth;
        // newColumnWidths[headerId] = Math.max(
        //   headerWidth + headerWidth * percentageDeltaX,
        //   0,
        // );
        // edited, actual pixel value width adjustment
        newColumnWidths[headerId] = Math.floor(parentStartingWidth + deltaX);
      },
    );

    return {
      ...state,
      columnResizing: {
        ...state.columnResizing,
        columnWidths: {
          ...state.columnResizing.columnWidths,
          ...newColumnWidths,
        },
      },
    };
  }

  if (action.type === actions.columnDoneResizing) {
    return {
      ...state,
      columnResizing: {
        ...state.columnResizing,
        startX: null,
        isResizingColumn: null,
      },
    };
  }
}

const useInstanceBeforeDimensions = <DataType extends Record<string, unknown>>(
  instance: TableInstance<DataType>,
) => {
  const {
    flatHeaders,
    disableResizing,
    getHooks,
    state: { columnResizing },
  } = instance;

  const getInstance = useGetLatest(instance);

  flatHeaders.forEach((header) => {
    const isResizeDisabled = header.disableResizing || disableResizing;

    header.canResize = !isResizeDisabled;
    header.width =
      columnResizing.columnWidths[header.id] ||
      header.originalWidth ||
      header.width;
    header.isResizing = columnResizing.isResizingColumn === header.id;

    if (!isResizeDisabled) {
      header.getResizerProps = makePropGetter(getHooks().getResizerProps, {
        instance: getInstance(),
        header,
      });
    }
  });
};

function useInstance<DataType extends Record<string, unknown>>(
  instance: TableInstance<DataType>,
) {
  const { plugins, dispatch, autoResetResize = true, columns } = instance;

  ensurePluginOrder(plugins, ['useAbsoluteLayout'], 'useResizeColumns');

  const getAutoResetResize = useGetLatest(autoResetResize);
  useMountedLayoutEffect(() => {
    if (getAutoResetResize()) {
      dispatch({ type: actions.resetResize });
    }
  }, [columns]);

  const resetResizing = React.useCallback(
    () => dispatch({ type: actions.resetResize }),
    [dispatch],
  );

  Object.assign(instance, {
    resetResizing,
  });
}

function getLeafHeaders<DataType extends Record<string, unknown>>(
  header: ColumnInstance<DataType>,
) {
  const leafHeaders: ColumnInstance<DataType>[] = [];
  const recurseHeader = (header: ColumnInstance<DataType>) => {
    if (header.columns && header.columns.length) {
      header.columns.map(recurseHeader);
    }
    leafHeaders.push(header);
  };
  recurseHeader(header);
  return leafHeaders;
}
