import { createIcon } from '@chakra-ui/react';

export const WindowViewIcon = createIcon({
  displayName: 'WindowViewIcon',
  d: 'M8.88647 21.9723L6.88647 21.9723L6.88647 17.2613L2.13647 17.2613L2.13647 15.2777L6.88647 15.2777C7.99104 15.2777 8.88647 16.1658 8.88647 17.2613L8.88647 21.9723ZM2.16418 8.75L2.16418 6.75L6.87518 6.75L6.87518 2L8.85876 2L8.85876 6.75C8.85876 7.85457 7.97068 8.75 6.87518 8.75L2.16418 8.75ZM17.2777 2.0277L15.2777 2.0277L15.2777 6.73871C15.2777 7.83421 16.1731 8.72229 17.2777 8.72229L22.0277 8.72229L22.0277 6.73871L17.2777 6.73871L17.2777 2.0277ZM22 15.25L22 17.25L17.289 17.25L17.289 22L15.3054 22L15.3054 17.25C15.3054 16.1454 16.1935 15.25 17.289 15.25L22 15.25Z',
  viewBox: '0 0 24 24',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
