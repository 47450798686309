import { ApolloError } from '@apollo/client';
import { IGetHumioTenentType } from '@gamma/investigator/queries';
import { createContext, Dispatch, SetStateAction } from 'react';

export interface ILogScaleDashboardParams {
  name?: string;
  dashboardId?: string;
  investigatorApp?: string;
  corelightDashboard?: string;
}

export interface ILogScaleContext {
  logScaleURL?: string;
  logScaleDashboardId?: string;
  logScaleURLParams?: string[] | [];
  logScaleDashboardsLoading?: boolean;
  logScaleDashboardsData?: any;
  logScaleDashboardsError?: ApolloError;
  setLogScaleURL?: Dispatch<SetStateAction<string | undefined>>;
  setLogScaleDashboardId?: Dispatch<SetStateAction<string | undefined>>;
  setLogScaleURLParams?: Dispatch<SetStateAction<string[] | [] | undefined>>;
  setLogScaleDashboardsLoading?: Dispatch<SetStateAction<boolean>>;
  setLogScaleDashboardsData?: Dispatch<
    SetStateAction<IGetHumioTenentType | undefined>
  >;
  setLogScaleDashboardsError?: Dispatch<
    SetStateAction<ApolloError | undefined>
  >;
  iframeKey?: number;
  setIframeKey?: Dispatch<SetStateAction<number>>;
  logScaleDashboardParams?: ILogScaleDashboardParams;
  setLogScaleDashboardParams?: Dispatch<
    SetStateAction<ILogScaleDashboardParams>
  >;
  sessionIdTokenCorelight?: string;
  setSessionIdTokenCorelight?: Dispatch<SetStateAction<string | undefined>>;
}

export const LogScaleContext = createContext<ILogScaleContext>({
  logScaleURL: undefined,
  logScaleDashboardId: undefined,
  logScaleURLParams: undefined,
  logScaleDashboardsLoading: false,
  logScaleDashboardsError: undefined,
  logScaleDashboardsData: undefined,
  iframeKey: undefined,
  setIframeKey: undefined,
  logScaleDashboardParams: undefined,
  setLogScaleDashboardParams: undefined,
  sessionIdTokenCorelight: undefined,
  setSessionIdTokenCorelight: undefined,
});
