import { Button } from '@chakra-ui/react';
import { ChatGPTLoadingIcon } from '@gamma/icons';
import { i18n } from '@gamma/investigator/localization';

export const ChatGPTLoadingQuestionPrompt = ({ text }: { text: string }) => {
  return (
    <Button
      mb={2}
      variant="outline"
      borderRadius="full"
      colorScheme="gray"
      data-testid="chat-gpt-loading-question-prompt"
      leftIcon={<ChatGPTLoadingIcon />}
    >
      {i18n.formatString(
        i18n.pages.integrations.chatGPT.loadingQuestionText,
        text,
      )}
    </Button>
  );
};
