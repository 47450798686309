import { useCallback, useEffect, useRef } from 'react';

export interface UseOutsideCalendarClickParams {
  onClose: () => void;
}

export const useOutsideCalendarClick = <
  InputType extends HTMLInputElement | HTMLButtonElement = HTMLButtonElement,
>({
  onClose,
}: UseOutsideCalendarClickParams) => {
  const calendarRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<InputType>(null);

  const listener = useCallback(
    (event: MouseEvent | TouchEvent) => {
      const calendarContainsTarget =
        !calendarRef.current ||
        calendarRef.current.contains(event.target as Node);
      const inputContainsTarget =
        !inputRef.current || inputRef.current.contains(event.target as Node);
      // Do nothing if clicking ref's element or descendent elements
      if (!calendarContainsTarget && !inputContainsTarget) {
        onClose();
      }
    },
    [calendarRef, inputRef],
  );

  useEffect(() => {
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [listener]);

  return {
    calendarRef,
    inputRef,
  };
};
