import { Link, Text } from '@chakra-ui/react';
import { CTAButton } from '@gamma/buttons';
import { CONSTANTS, ROUTES, URLS } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { useLogOperationCall } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { useAcceptEulaTC, useAcceptHumioTC } from '@gamma/investigator/queries';
import { setHumioSessionId } from '@gamma/investigator/utilities';
import { ErrorBoundary } from '@gamma/overlay';
import {
  CardPageCTA,
  CardPageHeading,
  CardPageIllustration,
} from '@gamma/pages';
import { CognitoUserInterface } from '@gamma/shared/types';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export interface SSOWelcomeProps {
  user?: CognitoUserInterface;
}

export const SSOWelcome = ({ user }: SSOWelcomeProps) => {
  const { isPOVTenant } = useContext(AuthContext);

  const { handleSubmit } = useForm();
  const navigate = useNavigate();
  const logOperationCall = useLogOperationCall();
  const { title, cta, accept, privacyPolicy } = i18n.pages.registration.welcome;
  const { home } = ROUTES;

  const [acceptEulaTC] = useAcceptEulaTC();
  const [acceptHumioTC] = useAcceptHumioTC();

  const onSubmit = async () => {
    if (user?.signInUserSession) {
      //SSO user is landing on this page when getting false in getUserTcAcceptance API
      const id_token = user?.signInUserSession.idToken?.jwtToken;
      try {
        await setHumioSessionId(id_token, isPOVTenant);
      } catch (e) {
        console.error(e);
      }
      acceptEulaTC()
        .then(() => {
          localStorage.removeItem('accept_privacy_policy');
        })
        .catch((err) => {
          console.error(err);
        });
      logOperationCall('PRIVACY_POLICY_ACKNOWLEDGED', 'USER');
      acceptHumioTC({
        variables: {
          humioSessionToken: localStorage.getItem('session_id_token') || '',
        },
      })
        .then(() => {
          localStorage.removeItem('humio_tc_accepted');
        })
        .catch((err) => {
          console.error(err);
        });

      navigate(home);
    } else {
      //SSO user is landing on this page when getting error through idp response
      localStorage.setItem('accept_privacy_policy', 'true');
      localStorage.setItem('humio_tc_accepted', 'true');
      const {
        SSO: { domain, client_id, redirect_uri },
      } = CONSTANTS;
      const sso_domain = window.sessionStorage.getItem('sso_domain');
      const idpLoginPage = `https://${domain}/oauth2/authorize?idp_identifier=${sso_domain}&client_id=${client_id}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=${redirect_uri}`;
      window.location.href = idpLoginPage;
    }
  };

  return (
    <ErrorBoundary page="Registration Welcome Page">
      <form
        data-testid="sso-welcome-card"
        id="eulaForm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <CardPageHeading>
          {i18n.formatString(title, {
            appName: <strong>{i18n.app.name}</strong>,
          })}
        </CardPageHeading>
        <CardPageIllustration illustration="Welcome" />
        <Text color="gray.400">
          {i18n.formatString(accept, {
            privacyPolicy: (
              <Link
                data-testid="privacy-policy"
                href={URLS.CORELIGHT_PRIVACY_POLICY}
                color="text.link"
                textDecoration="underline"
                isExternal
                _focus={{ outline: 'none' }}
              >
                {privacyPolicy}
              </Link>
            ),
          })}
        </Text>
      </form>
      <CardPageCTA>
        <CTAButton data-testid="continue-cta" type="submit" form="eulaForm">
          {cta}
        </CTAButton>
      </CardPageCTA>
    </ErrorBoundary>
  );
};
