import { createIcon } from '@chakra-ui/react';

export const BullsEyeIcon = createIcon({
  displayName: 'BullsEyeIcon',
  viewBox: '0 0 24 24',
  d: 'M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 2a8 8 0 1 1 0 16 8 8 0 0 1 0-16Zm0 2a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm0 2a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm0 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
