import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

import { IDeleteEscalationConfigurations } from './types';

export const DELETE_ESCALATION_CONFIGURATIONS = gql`
  mutation deleteEscalationConfigurations(
    $escalation_configuration_ids: [String!]!
  ) {
    deleteEscalationConfigurations(
      escalation_configuration_ids: $escalation_configuration_ids
    ) {
      enabled
      escalation_configuration_id
      service_name
      ... on ServiceNowEscalationConfiguration {
        username
        password
        enabled
        escalation_configuration_id
        instance_name
        service_name
        table_name
      }
    }
  }
`;

export const useDeleteEscalationConfigurations = (
  options: MutationHookOptions<
    IDeleteEscalationConfigurations,
    OperationVariables
  >,
) => useMutation(DELETE_ESCALATION_CONFIGURATIONS, options);
