import { ForwardedRef, MutableRefObject, RefCallback } from 'react';

export const mergeRefs = <RefType>(
  ...refs: Array<
    | RefCallback<RefType>
    | MutableRefObject<RefType | null>
    | ForwardedRef<RefType>
  >
) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];

  return (inst: RefType) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};
