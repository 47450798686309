import { HStack, Stack, Text, Tooltip } from '@chakra-ui/react';
import { DateSelect, OptionType, Select } from '@gamma/form-fields';
import { dateRangeOptions } from '@gamma/investigator/constants';
import { OrgTenantsContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { useListUsers } from '@gamma/investigator/queries';
import { Column } from '@gamma/layout';
import { MultiValue } from 'chakra-react-select';
import _ from 'lodash';
import moment from 'moment';
import { Dispatch, SetStateAction, useContext } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { DateRange, Filters } from '../types';

const eventCategories: string[] = [
  'ADMIN_USER_ACTIONS',
  'ALERT',
  'DETECTION',
  'ENTITY',
  'EXPORT',
  'INTEGRATION',
  'SYSTEM',
  'USER',
];

const eventCategoriesOptions: OptionType[] = eventCategories.map((event) => ({
  label:
    event === 'ADMIN_USER_ACTIONS'
      ? _.capitalize(_.split(event, '_')[0])
      : _.capitalize(event),
  value: event,
}));

const type: string[] = ['audit', 'error'];

const typeOptions: OptionType[] = type.map((event) => ({
  label: _.capitalize(event),
  value: event,
}));

const removeKey = (object: Filters, key: keyof Filters) => {
  const newObj: Filters = { ...object };
  if (Object.prototype.hasOwnProperty.call(object, key)) {
    delete newObj[key];
  }
  return newObj;
};

const createOptionString = <T extends OptionType>(options: MultiValue<T>) => {
  const values = options.map(({ value }) => value);
  return values.join(', ');
};

const getSelectedValues = <T extends OptionType>(
  values: string,
  options: T[],
) => {
  const splitString = values?.split(', ');
  const optionsArray = splitString?.map((string) => {
    return options?.find((option) => option?.value?.toString() === string);
  });
  const filteredArray = optionsArray?.filter((option) => option);
  return filteredArray as T[];
};

const divStyles: React.CSSProperties = {
  width: '80px',
  overflow: 'hidden',
  wordWrap: 'initial',
};

const userPill = {
  MultiValueLabel: (props: Record<string, any>) => (
    <Tooltip label={props.children} placement="top">
      <div style={divStyles}>{props.children}</div>
    </Tooltip>
  ),
};

interface SecurityAuditToolbarProps {
  setDateRange: Dispatch<SetStateAction<DateRange>>;
  setFilters: Dispatch<SetStateAction<Filters>>;
  filters: Filters;
  getUserLogsLoading: boolean;
}

export const SecurityAuditToolbar = ({
  setDateRange,
  setFilters,
  filters,
  getUserLogsLoading,
}: SecurityAuditToolbarProps) => {
  const { labels } = i18n.forms;
  const { text, ssoLabel } = i18n.pages.securityAudit;
  const { noValueSet } = i18n.pages.system.alertCatalog;
  const { orgTenantsQueryParam } = useContext(OrgTenantsContext);

  const { control, handleSubmit } = useForm({
    mode: 'onSubmit',
  });

  const { data, loading: listUsersLoading } = useListUsers({
    skip: !orgTenantsQueryParam,
    variables: {
      tenants: orgTenantsQueryParam,
    },
  });

  const sortedUserList =
    data?.listUsers
      ?.map((user) => ({
        username: user.username,
        is_sso_user: user.is_sso_user,
      }))
      .sort((a, b) => a?.username.localeCompare(b?.username)) ?? [];

  const userOptionsList =
    sortedUserList?.map((user) => ({
      value: user.username,
      label: user.is_sso_user ? (
        <HStack>
          <Text>{user.username}</Text>
          <Stack>
            <Text
              data-testid="sso-user"
              color="corelight.200"
              fontSize="0.6em"
              mt="-10px"
              ml="-2px"
            >
              {ssoLabel}
            </Text>
          </Stack>
        </HStack>
      ) : (
        user.username
      ),
    })) ?? [];

  const onSubmit = (formData: FieldValues) => {
    if (formData?.dateRange) {
      const startDate = moment(formData.dateRange.startDate)
        .utc()
        .unix()
        .toString();
      const endDate = moment(formData.dateRange.endDate)
        .utc()
        .unix()
        .toString();
      setDateRange({ startDate, endDate });
    }

    let newFilters = filters;
    if (formData.user?.length) {
      newFilters = { ...newFilters, user_id: _.split(formData.user, ', ') };
    } else {
      newFilters = removeKey(newFilters, 'user_id');
    }

    if (formData.eventCategory?.length) {
      newFilters = {
        ...newFilters,
        event_category: _.split(formData.eventCategory, ', '),
      };
    } else {
      newFilters = removeKey(newFilters, 'event_category');
    }

    if (formData.auditType?.length) {
      newFilters = {
        ...newFilters,
        audit_type: _.split(formData.auditType, ', '),
      };
    } else {
      newFilters = removeKey(newFilters, 'audit_type');
    }
    setFilters(newFilters);
  };

  return (
    <Column>
      <HStack as="form" p={4}>
        <Controller
          control={control}
          name="user"
          render={({
            field: { name, onChange, value },
            fieldState: { error },
          }) => (
            <Select
              isMulti
              components={userPill}
              isClearable
              name={name}
              label={labels.user}
              value={getSelectedValues(value, userOptionsList)}
              onChange={(e) => {
                onChange(createOptionString(e));
                handleSubmit(onSubmit)();
              }}
              isDisabled={listUsersLoading || getUserLogsLoading}
              isLoading={listUsersLoading}
              isLabelHidden={true}
              options={userOptionsList}
              leftElement={`${labels.user}:`}
              minWidth="350px"
              width="fit-content"
              tagVariant="solid"
              placeholder={noValueSet}
              error={error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="eventCategory"
          render={({
            field: { name, onChange, value },
            fieldState: { error },
          }) => {
            return (
              <Select
                isMulti
                isClearable
                name={name}
                label={labels.eventCategory}
                value={getSelectedValues(value, eventCategoriesOptions)}
                onChange={(e) => {
                  onChange(createOptionString(e));
                  handleSubmit(onSubmit)();
                }}
                isSearchable={false}
                isDisabled={getUserLogsLoading}
                isLabelHidden={true}
                options={eventCategoriesOptions}
                leftElement={`${labels.category}:`}
                minWidth="270px"
                width="fit-content"
                tagVariant="solid"
                placeholder={noValueSet}
                error={error?.message}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="auditType"
          render={({
            field: { name, onChange, value },
            fieldState: { error },
          }) => {
            return (
              <Select
                isMulti
                name={name}
                label={labels.type}
                options={typeOptions}
                isLabelHidden
                leftElement={`${labels.type}:`}
                placeholder={noValueSet}
                error={error?.message}
                minWidth="200px"
                width="fit-content"
                isSearchable={false}
                isDisabled={getUserLogsLoading}
                isClearable
                tagVariant="solid"
                value={getSelectedValues(value, typeOptions)}
                onChange={(e) => {
                  onChange(createOptionString(e));
                  handleSubmit(onSubmit)();
                }}
              />
            );
          }}
        />
        <HStack>
          <Controller
            control={control}
            name="dateRange"
            render={({ field: { name, onChange } }) => {
              return (
                <DateSelect
                  name={name}
                  label="Select Date"
                  options={dateRangeOptions}
                  isLabelHidden
                  isDisabled={getUserLogsLoading}
                  onChange={(startDate: Date, endDate: Date) => {
                    onChange({ startDate, endDate });
                    handleSubmit(onSubmit)();
                  }}
                  allowCustom={true}
                  defaultValue={[7, 'days']}
                  pickerProps={{
                    maxDate: new Date(),
                    label: '',
                    isLabelHidden: false,
                    tooltip: text.dateRangeHint,
                    name: name,
                    inline: true,
                    minDate: moment().subtract(90, 'days').toDate(),
                  }}
                />
              );
            }}
          />
        </HStack>
      </HStack>
    </Column>
  );
};
