import { useQuery } from '@apollo/client';
import {
  Center,
  Flex,
  PopoverBody,
  PopoverHeader,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  ROUTES,
  graphqlErrorRedirects,
  momentJSDateFormats,
} from '@gamma/investigator/constants';
import { OrgTenantsContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  DetectionTacticSummary,
  DetectionTechniqueSummary,
  GET_TOP_DETECTIONS_RAW_PAGINATED,
  IGetTopDetectionsRawPaginated,
} from '@gamma/investigator/queries';
import { Panel } from '@gamma/layout';
import { GraphQLReqStatus } from '@gamma/progress';
import moment from 'moment';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const { D_MMM_HH_mm } = momentJSDateFormats;

interface DetectionsByCategoryPopoverContentProps {
  start: number;
  end: number;
  tactic: DetectionTacticSummary;
  technique: DetectionTechniqueSummary;
}

export const DetectionsByCategoryPopoverContent = ({
  start,
  end,
  tactic,
  technique,
}: DetectionsByCategoryPopoverContentProps) => {
  const { orgTenantsQueryParam } = useContext(OrgTenantsContext);

  const navigate = useNavigate();

  const { loading, error, data } = useQuery<IGetTopDetectionsRawPaginated>(
    GET_TOP_DETECTIONS_RAW_PAGINATED,
    {
      skip: !start || !end || !orgTenantsQueryParam?.length,
      variables: {
        start,
        end,
        must_conds: [
          `{"terms": {"tenant": ${JSON.stringify(orgTenantsQueryParam)}}}`,
          '{"term": {"detection_status": "open"}}',
          `{"term": {"mitre_techniques": "${tactic.mitre_attack_tactic} :: ${technique.mitre_attack_technique}"}}`,
        ],
      },
    },
  );

  if (loading || error) {
    return (
      <Panel>
        <GraphQLReqStatus
          loading={loading}
          error={error}
          extended={graphqlErrorRedirects}
        />
      </Panel>
    );
  }

  const { uniqueEntities } =
    i18n.pages.securityOverview.panels.detectionsByCategory;

  return (
    <>
      <PopoverHeader fontWeight="normal">
        <Flex flex="0 0 100%" justifyContent="space-between">
          <Text
            data-testid="mitre-technique-popover-header-text"
            textStyle="body-md"
          >
            {technique.mitre_attack_technique}
          </Text>
          <Flex flexDirection="row" alignItems="first baseline" ml={2}>
            <Text
              data-testid="mitre-technique-popover-unique-entities-text"
              mx={1}
              textStyle="body-md"
            >
              {uniqueEntities}:
            </Text>
            <Text
              data-testid="mitre-technique-popover-entity-count"
              textStyle="body-lg"
            >
              {technique.entity_count}
            </Text>
          </Flex>
        </Flex>
      </PopoverHeader>
      <PopoverBody w="100%">
        <Text
          data-testid="mitre-technique-popover-information-text"
          mb={2}
          textStyle="body-sm"
        >
          {i18n.popper.found}{' '}
          <strong>
            {technique.detection_count}{' '}
            {technique.detection_count === 1
              ? i18n.popper.detection
              : i18n.popper.detections}
          </strong>{' '}
          {i18n.popper.from}{' '}
          <strong>
            {moment.unix(technique.first_seen).format(D_MMM_HH_mm)}
          </strong>{' '}
          {i18n.popper.to}{' '}
          <strong>
            {moment.unix(technique.last_seen).format(D_MMM_HH_mm)}
          </strong>
        </Text>
        <Stack
          maxHeight="300px"
          overflowY="scroll"
          overflowX="hidden"
          spacing={2}
        >
          {data?.getTopDetectionsRawPaginated?.summaries?.map(
            (detection, index) => (
              <Flex
                key={index}
                role="button"
                layerStyle="second"
                alignItems="center"
                borderRadius="base"
                onClick={() =>
                  navigate({
                    pathname: ROUTES.detections,
                    search: `start=${start}&end=${end}&alert_category=${detection.alert_name}`,
                  })
                }
              >
                <Center
                  flex="1"
                  flexDirection="row"
                  justifyContent="space-between"
                  borderTopRightRadius="sm"
                  borderBottomRightRadius="sm"
                  px={2}
                  py={1}
                >
                  <Stack spacing={0}>
                    <Text textStyle="body-md" wordBreak="break-word">
                      {detection.alert_name}
                    </Text>
                    <Text
                      data-testid="mitre-attack-technique-summary-link"
                      textStyle="body-sm"
                    >
                      {detection.first_seen &&
                        detection.last_seen &&
                        `${moment
                          .unix(detection.first_seen)
                          .format(D_MMM_HH_mm)} - ${moment
                          .unix(detection.last_seen)
                          .format(D_MMM_HH_mm)}`}
                    </Text>
                  </Stack>
                  <Flex
                    data-testid="mitre-attack-technique-summary-alert-entity-count-text"
                    flexDirection="row"
                  >
                    <Text textStyle="body-md" mx={1}>
                      x
                    </Text>
                    <Text textStyle="body-md">{detection.entity_count}</Text>
                  </Flex>
                </Center>
              </Flex>
            ),
          )}
        </Stack>
      </PopoverBody>
    </>
  );
};
