import { createIcon } from '@chakra-ui/react';

export const SelectAllIcon = createIcon({
  displayName: 'SelectAllIcon',
  viewBox: '0 0 24 24',
  d: 'M18 14V8h2v6a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2c0-1.11.89-2 2-2h8v2H6v12h12ZM8.91 5.08 12 8.17l6.59-6.59L20 3l-8 8-4.5-4.5 1.41-1.42ZM14 18v2H2a2 2 0 0 1-2-2V5h2v13h12Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
