import { ComponentStyleConfig } from '@chakra-ui/react';
import { anatomy } from '@chakra-ui/theme-tools';
import { textStyles } from '../../common';

export const breadcrumbAnatomy = anatomy('breadcrumb')
  .parts('link', 'item', 'container')
  .extend('separator');

export const breadcrumbStyles: ComponentStyleConfig = {
  parts: breadcrumbAnatomy.keys,
  baseStyle: {
    container: {
      '& .chakra-breadcrumb__list': {
        display: 'flex',
      },
    },
    link: {
      ...textStyles['body-md'],
      display: 'flex',
      color: 'text.link',
    },
  },
};
