import { createIcon } from '@chakra-ui/react';

export const SortIconUp = createIcon({
  displayName: 'SortIconUp',
  viewBox: '0 0 16 16',
  d: 'M11.333 5.333h-2L12 2.667l2.667 2.666h-2v8h-1.334v-8Zm-3.333 6H1.333v1.334H8v-1.334ZM4 4.667V3.333H1.333v1.334H4Zm2 2.666H1.333v1.334H6V7.333Z',
  defaultProps: {
    height: '16px',
    width: '16px',
  },
});
