import { Badge, Center, Flex, HStack, Text } from '@chakra-ui/react';
import { AuthContext } from '@gamma/investigator/context';
import { DetectionSummary } from '@gamma/investigator/queries';
import moment from 'moment';
import { useContext, useEffect, useRef } from 'react';

interface EntityDetailCardProps {
  index?: number;
  selected?: boolean;
  entitySummary: DetectionSummary;
  setSelectedIndex?: (index: number) => void;
  onClick?: (entitySummary: DetectionSummary) => void;
}

export const EntityDetailCard = ({
  index,
  selected,
  entitySummary,
  setSelectedIndex,
  onClick,
}: EntityDetailCardProps) => {
  const { isOrgTenant } = useContext(AuthContext);

  const handleClick = (entitySummary: DetectionSummary) => {
    onClick?.(entitySummary);
  };

  const activeCardRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (index && setSelectedIndex && activeCardRef.current) {
      setSelectedIndex(index);
    }
  }, [index, setSelectedIndex]);

  return (
    <Flex
      py={2}
      borderRadius="8px"
      layerStyle="second"
      alignItems="center"
      ref={selected ? activeCardRef : null}
      bg={selected ? 'layer.3' : undefined}
      _hover={{
        cursor: 'default',
        background: '',
      }}
      onClick={() => handleClick(entitySummary)}
    >
      <Center
        px={2}
        flex="1"
        flexDirection="row"
        justifyContent="space-between"
        borderTopRightRadius="sm"
        borderBottomRightRadius="sm"
      >
        <Flex alignItems="center">
          <Flex flexDirection="column" py={1}>
            <Text
              data-testid="alert-detail-card-alert-rollup-name"
              noOfLines={2}
              wordBreak="break-word"
              textStyle="body-md"
              lineHeight={1.1}
            >
              {entitySummary?.entity_name}
            </Text>
          </Flex>
        </Flex>
        <Flex>
          <HStack>
            {isOrgTenant && (
              <Badge variant="outline">
                {entitySummary?.tenant_info?.tenant_display_name}
              </Badge>
            )}
            <Text
              minW="55px"
              data-testid="alert-detail-card-alert-rollup-last-seen"
              textStyle="body-sm"
              textAlign="right"
            >
              {entitySummary?.last_seen &&
                moment.unix(entitySummary?.last_seen).fromNow()}
            </Text>
          </HStack>
        </Flex>
      </Center>
    </Flex>
  );
};
