import { Box, Text } from '@chakra-ui/react';
import { SeverityScore } from '@gamma/investigator/components';
import { ROUTES } from '@gamma/investigator/constants';
import { useDateRangeQueryString } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { stringifyAlertType } from '@gamma/investigator/utilities';
import { RouterLink } from '@gamma/navigation';

import { DetailsGrid, DetailsGridRow } from '..';

export interface DetectionDetailsGridProps {
  alert_info: any;
  rank: any;
  isCustomSeverityScore?: boolean;
}

export const DetectionDetailsGrid = ({
  alert_info,
  rank,
  isCustomSeverityScore,
}: DetectionDetailsGridProps) => {
  const { start, end } = useDateRangeQueryString();

  const { detection } = i18n.pages.detections;

  const { type, severity, alertCategory } = detection;

  const { systemSettingsAlertCatalog } = ROUTES;

  return (
    <DetailsGrid>
      {alert_info?.alert_name && (
        <DetailsGridRow
          data-testid="detection-alert-name"
          title={alertCategory}
        >
          <Box
            __css={{
              wordWrap: 'anywhere',
              _hover: { textDecoration: 'underline' },
            }}
          >
            <RouterLink
              color="text.link"
              __css={{
                wordWrap: 'anywhere',
              }}
              data-testid="pivot-detection-alert-catalog-link"
              to={`${systemSettingsAlertCatalog(
                `/${alert_info?.content_id}?start=${start}&end=${end}`,
              )}`}
            >
              {alert_info?.alert_name}
            </RouterLink>
          </Box>
        </DetailsGridRow>
      )}
      {rank?.severity !== null && rank?.severity !== undefined && (
        <DetailsGridRow
          data-testid="detection-alert-severity-score"
          title={severity}
        >
          <SeverityScore
            isCustom={isCustomSeverityScore}
            score={rank.severity}
          />
        </DetailsGridRow>
      )}
      {alert_info?.alert_type && (
        <DetailsGridRow title={type} data-testid="detection-type">
          <Text>{stringifyAlertType(alert_info?.alert_type)}</Text>
        </DetailsGridRow>
      )}
    </DetailsGrid>
  );
};
