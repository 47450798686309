const colorOrder = [
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'cyan',
  'blue',
  'purple',
  'pink',
] as const;

type ColorName = typeof colorOrder[number];
type ColorGradeValue = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

const colorGradesBySizeMap = new Map<number, ColorGradeValue[]>([
  [1, [500]],
  [2, [500, 400]],
  [3, [600, 500, 400]],
  [4, [600, 500, 400, 300]],
  [5, [700, 600, 500, 400, 300]],
  [6, [700, 600, 500, 400, 300, 200]],
  [7, [800, 700, 600, 500, 400, 300, 200]],
  [8, [800, 700, 600, 500, 400, 300, 200, 100]],
  [9, [900, 800, 700, 600, 500, 400, 300, 200, 100]],
  [10, [900, 800, 700, 600, 500, 400, 300, 200, 100, 50]],
]);

export const chartColorsByArraySize = (size: number) => {
  const multiplesOfNineInSize = Math.ceil(size / 9);
  const clampedMultiples =
    multiplesOfNineInSize > 10 ? 10 : multiplesOfNineInSize;
  const colorGrades = colorGradesBySizeMap.get(clampedMultiples) ?? [500];
  const colorGradeOrder: `${ColorName}.${ColorGradeValue}`[] = [];
  colorOrder.forEach((color) => {
    colorGrades.forEach((grade) => colorGradeOrder.push(`${color}.${grade}`));
  });
  return colorGradeOrder;
};
