import { CTAButton } from '@gamma/buttons';
import { Input } from '@gamma/form-fields';
import { ROUTES } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { useHandleNextURLRedirect } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { setRole } from '@gamma/investigator/utilities';
import { ErrorBoundary } from '@gamma/overlay';
import {
  CardPageBody,
  CardPageCTA,
  CardPageHeading,
  CardPageIllustration,
  CardPageSubtitle,
} from '@gamma/pages';
import { AuthState } from '@gamma/shared/types';
import { formatOTPCode } from '@gamma/util';
import { Auth } from 'aws-amplify';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

// TODO: Replace this with proper UI designs once they're finished

const { base } = ROUTES;

export const Confirm2fa = () => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    setFocus,
    formState: { errors, isDirty },
  } = useForm<{ OTPCode: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user, setAuthState, setUser, setUserRole } = useContext(AuthContext);

  const handleOTPInputChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setValue('OTPCode', formatOTPCode(value));
  };

  const handleRedirectURL = useHandleNextURLRedirect(base);

  const handleSubmission: SubmitHandler<{ OTPCode: string }> = async ({
    OTPCode,
  }) => {
    setIsLoading(true);
    try {
      const verifiedUser = await Auth.confirmSignIn(
        user,
        OTPCode.replace(' ', ''),
        'SOFTWARE_TOKEN_MFA',
      );
      if (verifiedUser) {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          setUser?.(user);
          setRole({ user, setUserRole });
          window.localStorage.setItem(
            'docs_id_token',
            user?.signInUserSession?.idToken?.jwtToken,
          );

          await handleRedirectURL();
          if (localStorage.getItem('next')) {
            localStorage.removeItem('next');
          }
        }
        setAuthState?.(AuthState.SignedIn);
      }
    } catch (e) {
      localStorage.removeItem('docs_id_token');
      setError('OTPCode', {
        message: i18n.forms.validation.otp,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setFocus('OTPCode');
  }, []);

  return (
    <ErrorBoundary page="Registration MFA Confirm OTP Page">
      <CardPageHeading>{i18n.forms.labels.MFA.title}</CardPageHeading>
      <CardPageIllustration illustration="LockedShield" />
      <CardPageSubtitle>{i18n.forms.labels.MFA.instructions}</CardPageSubtitle>
      <CardPageBody>
        <form onSubmit={handleSubmit(handleSubmission)} id="confirmOTPCode">
          <Input
            label={i18n.forms.labels.enterOTP}
            autoComplete="one-time-code"
            placeholder="xxx xxx"
            data-testid="mfa-input"
            {...register('OTPCode', {
              onChange: handleOTPInputChange,
            })}
            error={errors?.OTPCode?.message}
          />
        </form>
      </CardPageBody>
      <CardPageCTA>
        <CTAButton
          type="submit"
          form="confirmOTPCode"
          isDisabled={!isDirty}
          isLoading={isLoading}
          data-testid="submit-mfa-button"
        >
          Submit
        </CTAButton>
      </CardPageCTA>
    </ErrorBoundary>
  );
};
