import { createContext } from 'react';
import { ColumnInstance } from 'react-table';

export type DataTableContextValue<DataType extends {}> = {
  totalLength: [number, (length: number) => void];
  curPageLength: [number, (length: number) => void];
  filteredLength: [number, (length: number) => void];
  columns: [
    ColumnInstance<DataType>[],
    (columns: ColumnInstance<DataType>[]) => void,
  ];
};

const noop = () => {
  return;
};

const defaultDataTableContextValue: DataTableContextValue<{}> = {
  totalLength: [0, noop],
  curPageLength: [0, noop],
  filteredLength: [0, noop],
  columns: [[], noop],
};

export const DataTableContext = createContext<DataTableContextValue<any>>(
  defaultDataTableContextValue,
);
