import { ListItem, UnorderedList } from '@chakra-ui/react';
import { Alert } from '@gamma/overlay';

interface StatusStringsProps {
  statusStrings: string[];
}

export const StatusStrings = ({ statusStrings }: StatusStringsProps) => {
  return (
    <Alert variant="subtle" status="warning">
      {statusStrings?.length <= 1 ? (
        statusStrings[0]
      ) : (
        <UnorderedList>
          {statusStrings.map((string) => (
            <ListItem>{string}</ListItem>
          ))}
        </UnorderedList>
      )}
    </Alert>
  );
};
