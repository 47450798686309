import { ComponentStyleConfig } from '@chakra-ui/react';
import { cssVar } from '@chakra-ui/theme-tools';

const $bg = cssVar('tooltip-bg');
const $border = cssVar('tooltip-border');
const $arrowBg = cssVar('popper-arrow-bg');
const $arrowShadowColor = cssVar('popper-arrow-shadow-color');

export const TooltipStyles: ComponentStyleConfig = {
  baseStyle: (props: any) => {
    return {
      [$bg.variable]: 'colors.layer.2',
      [$border.variable]: 'transparent',
      bg: [$bg.reference],
      [$arrowBg.variable]: [$bg.reference],
      [$arrowShadowColor.variable]: [$border.reference],
      _dark: {
        [$bg.variable]: 'colors.layer.2',
        [$border.variable]: 'transparent',
      },
      color: 'text',
      border: 'none',
      borderColor: [$border.reference],
      borderRadius: 'md',
      padding: 1.5,
      maxW: '403px',
    };
  },
};
