import { createIcon } from '@chakra-ui/react';

export const ContactIcon = createIcon({
  displayName: 'ContactIcon',
  viewBox: '0 0 24 24',
  d: 'm20 8-8 5-8-5V6l8 5 8-5v2Zm0-4H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
