import { Column } from 'react-table';

export const getColumnFlexValue = <DataType extends {}>(
  width: string | number = 150,
  {
    width: colWidth = 'auto',
    minWidth = 0,
    maxWidth = Infinity,
  }: Column<DataType> = {} as Column<DataType>,
) => {
  const widthAsNum = Number.parseInt(width as string, 10);
  // react-table automatically sets all column widths to 150 by default, check
  // if that is the case and if it is, use 'auto' instead
  const isWidthDefault = width === 150 && colWidth === 'auto';
  // keep width between min and max widths
  const maxxedWidthValue = widthAsNum > maxWidth ? maxWidth : width;
  const minMaxxedWidthValue =
    widthAsNum < minWidth ? minWidth : maxxedWidthValue;

  const correctWidthValue = isWidthDefault ? colWidth : minMaxxedWidthValue;

  const isNumber = Number.isInteger(correctWidthValue);
  const flexBasis = isNumber ? `${correctWidthValue}px` : correctWidthValue;
  const flexGrow = isNumber ? '0' : '1';
  return `${flexGrow} 0 ${flexBasis}`;
};
