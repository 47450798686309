import { createIcon } from '@chakra-ui/react';

export const SplitLayoutIcon = createIcon({
  displayName: 'SplitLayout',
  viewBox: '0 0 14 14',
  d: 'M1.558 7.531v1.327h4.76V7.531h-4.76Zm0 2.386v1.327h4.76V9.917h-4.76Zm0-4.775v1.327h4.76V5.142h-4.76Zm0-2.386v1.327h4.76V2.756h-4.76Zm5.928 0h4.956v8.488H7.486V2.756Z',
  defaultProps: {
    height: '14px',
    width: '14px',
  },
});
