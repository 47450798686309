import { createIcon } from '@chakra-ui/react';

export const SourceIpIcon = createIcon({
  displayName: 'SourceIpIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M10.943 21v-2.115l-.096-.015a7.024 7.024 0 0 1-5.717-5.718l-.016-.094H3v-2.116h2.114l.016-.094a7.025 7.025 0 0 1 5.717-5.718l.096-.015V3h2.113v2.115l.096.015a7.028 7.028 0 0 1 5.718 5.718l.015.094H21v2.116h-2.115l-.015.094a7.027 7.027 0 0 1-5.718 5.718l-.096.015V21h-2.113Zm1.056-14.399A5.399 5.399 0 1 0 17.4 12 5.405 5.405 0 0 0 12 6.601Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.717 8.615c-.931 0-1.591.06-2.063.142v6.657h1.512V13c.141.02.32.03.52.03.901 0 1.672-.22 2.193-.713.4-.381.621-.94.621-1.602 0-.66-.292-1.22-.72-1.56-.451-.361-1.123-.54-2.063-.54Zm-.042 3.243c-.22 0-.38-.01-.51-.04V9.827c.11-.03.32-.06.632-.06.76 0 1.191.37 1.191.99 0 .69-.501 1.1-1.313 1.1Z"
        fill="currentColor"
      />
      <path d="M8.8 8.665h1.53v6.748H8.8V8.665Z" fill="currentColor" />
    </>
  ),
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
