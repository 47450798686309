export const stringifyEntityType = (entityType: string | undefined) => {
  if (!entityType) return '';
  switch (entityType) {
    case 'ip':
    case 'IP':
      return 'IP';
    case 'DOMAIN':
      return 'Domain';
    default:
      return '';
  }
};
