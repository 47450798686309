import {
  gql,
  LazyQueryHookOptions,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client';

export const GET_USER_TC_ACCEPTANCE = gql`
  query getUserTcAcceptance {
    getUserTcAcceptance {
      body {
        data
        message
        success
      }
      status_code
    }
  }
`;

export const useGetUserTcAcceptance = (
  options?: LazyQueryHookOptions<any, OperationVariables>,
) => {
  return useLazyQuery(GET_USER_TC_ACCEPTANCE, options);
};
