import {
  HStack,
  IconButton,
  Link,
  ListItem,
  Spacer,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { i18n } from '@gamma/investigator/localization';
import { Panel } from '@gamma/layout';
import { Alert } from '@gamma/overlay';

export const ConfigureRoles = () => {
  const { idpConfigurationModal } = i18n.pages.access;

  return (
    <Stack spacing={6}>
      <Stack>
        <Text>{idpConfigurationModal.configureRoles.heading}</Text>
        <Panel layerStyle="second">
          <HStack>
            <Text fontWeight="bold">
              {idpConfigurationModal.configureRoles.adminUsers}
            </Text>
            <Text>{idpConfigurationModal.configureRoles.admin}</Text>
            <Spacer />
            <IconButton
              aria-label="copy"
              icon={<MuiIcon>content_copy</MuiIcon>}
              color="blue.300"
              onClick={() =>
                navigator.clipboard.writeText(
                  idpConfigurationModal.configureRoles.admin,
                )
              }
            />
          </HStack>
        </Panel>
        <Panel layerStyle="second">
          <HStack>
            <Text fontWeight="bold">
              {idpConfigurationModal.configureRoles.analystUsers}
            </Text>
            <Text>{idpConfigurationModal.configureRoles.analyst}</Text>
            <Spacer />
            <IconButton
              aria-label="copy"
              icon={<MuiIcon>content_copy</MuiIcon>}
              color="blue.300"
              onClick={() =>
                navigator.clipboard.writeText(
                  idpConfigurationModal.configureRoles.analyst,
                )
              }
            />
          </HStack>
        </Panel>
        <Panel layerStyle="second">
          <HStack>
            <Text fontWeight="bold">
              {idpConfigurationModal.configureRoles.viewerUsers}
            </Text>
            <Text>{idpConfigurationModal.configureRoles.viewer}</Text>
            <Spacer />
            <IconButton
              aria-label="copy"
              icon={<MuiIcon>content_copy</MuiIcon>}
              color="blue.300"
              onClick={() =>
                navigator.clipboard.writeText(
                  idpConfigurationModal.configureRoles.viewer,
                )
              }
            />
          </HStack>
        </Panel>
        <Alert
          status="info"
          variant="subtle"
          title={
            <UnorderedList>
              <ListItem fontWeight="normal">
                {idpConfigurationModal.configureRoles.listItemAdmin}
              </ListItem>
              <ListItem fontWeight="normal">
                {idpConfigurationModal.configureRoles.listItemAnalystViewer}
              </ListItem>
            </UnorderedList>
          }
        />
      </Stack>
      <Stack>
        <Text>{idpConfigurationModal.configure.help}</Text>
        <Link
          isExternal
          color="blue.400"
          href="/docs/settings/users/saml-sso-users.html"
          width="fit-content"
        >
          {idpConfigurationModal.configure.helpTextSSO}
        </Link>
      </Stack>
    </Stack>
  );
};
