import { Box, Flex, VStack } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { Select } from '@gamma/form-fields';
import { ROUTES } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import { ReactNode, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { NavigateFunction, useParams } from 'react-router-dom';

const { detections, detectionDetails } = ROUTES;

const { labels } = i18n.pages.detections;
const { sort: sortLabel } = labels;

interface SortOption {
  value: string;
  label: ReactNode;
}

const sortOptions: SortOption[] = [
  {
    label: (
      <Flex alignItems="center" data-testid="sort-timestamp-desc">
        <MuiIcon mr={2}>arrow_downward</MuiIcon>
        <Box>{sortLabel.timeDesc}</Box>
      </Flex>
    ),
    value: 'latest_start_timestamp.desc',
  },
  {
    label: (
      <Flex alignItems="center" data-testid="sort-timestamp-asc">
        <MuiIcon mr={2}>arrow_upward</MuiIcon>
        <Box>{sortLabel.timeAsc}</Box>
      </Flex>
    ),
    value: 'latest_start_timestamp.asc',
  },
  {
    label: (
      <Flex alignItems="center" data-testid="sort-severity-desc">
        <MuiIcon mr={2}>arrow_downward</MuiIcon>
        <Box>{sortLabel.severityDesc}</Box>
      </Flex>
    ),
    value: 'rank.severity.desc',
  },
  {
    label: (
      <Flex alignItems="center" data-testid="sort-severity-asc">
        <MuiIcon mr={2}>arrow_upward</MuiIcon>
        <Box>{sortLabel.severityAsc}</Box>
      </Flex>
    ),
    value: 'rank.severity.asc',
  },
];

interface DetectionsSortFormData {
  sortBy: string;
}

interface DetectionsSortParams {
  isRelated?: boolean;
  sortByParam: string;
  activeView: string | null;
  params: URLSearchParams;
  navigate: NavigateFunction;
}

export const DetectionsSort = ({
  isRelated,
  activeView,
  sortByParam,
  params,
  navigate,
}: DetectionsSortParams) => {
  const defaultFilterValues = {
    sortBy: sortByParam,
  };

  const { detection_id } = useParams();

  const { control, register, setValue, watch } =
    useForm<DetectionsSortFormData>({
      mode: 'onSubmit',
      defaultValues: defaultFilterValues,
    });

  const formData = watch();

  const getSelectedSortValue = (value: string) => {
    return sortOptions.filter((option) => option.value === value);
  };

  useEffect(() => {
    const sortBy = formData?.sortBy;
    if (sortBy) {
      params.set('sort_by', sortBy);
    } else {
      params.delete('sort_by');
    }
    navigate(
      isRelated && detection_id
        ? {
            pathname: detectionDetails(detection_id),
            search: params.toString(),
          }
        : {
            pathname: detections,
            search: params.toString(),
          },
      {
        replace: true,
      },
    );
  }, [formData?.sortBy, navigate]);

  useEffect(() => {
    setValue('sortBy', sortByParam || defaultFilterValues?.sortBy);
  }, [sortByParam]);

  return activeView === 'list' ? (
    <VStack as="form" spacing={3} alignItems="start">
      <Box w="100%">
        {/* @ts-ignore */}
        <Controller
          name={'sortBy'}
          control={control}
          render={({ field: { name, value } }) => {
            return (
              <Select
                size="sm"
                name={name}
                label="Sort"
                isMulti={false}
                isLabelHidden={true}
                options={sortOptions}
                data-testid="detection-sort"
                value={getSelectedSortValue(value)}
                onChange={(value) => value && setValue('sortBy', value.value)}
              />
            );
          }}
        />
      </Box>
    </VStack>
  ) : (
    <></>
  );
};
