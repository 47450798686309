import { createIcon } from '@chakra-ui/react';

export const ChunkyCheckIcon = createIcon({
  displayName: 'ChunkyCheckIcon',
  viewBox: '0 0 24 24',
  d: 'm9 20.42-6.21-6.21 2.83-2.83L9 14.77l9.88-9.89 2.83 2.83L9 20.42Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
