import { ComponentStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

import { textStyles } from '../../common';

export const TabStyles: ComponentStyleConfig = {
  baseStyle: (props: any) => {
    const isVertical = props.orientation === 'vertical';
    return {
      tablist: {
        p: isVertical ? 2.5 : 0,
        w: 'full',
      },
      tab: {
        position: 'relative',
        borderTop: 'none',
        borderColor: 'transparent',
        borderRadius: isVertical ? 'base' : 'none',
        justifyContent: isVertical ? 'start' : 'center',
        _focus: {
          zIndex: 1,
          boxShadow: 'outline',
        },
        _selected: {
          color: 'text.primary',
        },
        _disabled: {
          cursor: 'default',
          opacity: 0.3,
        },
      },
      tabpanel: {
        p: 0,
        height: '100%',
        overflowY: 'auto',
      },
    };
  },
  sizes: {
    sm: (props: any) => {
      return {
        tab: {
          ...textStyles['body-sm'],
          py: 1.5,
          px: 2.5,
        },
      };
    },
    md: (props: any) => {
      const isVertical = props.orientation === 'vertical';
      return {
        tab: {
          px: 3,
          py: isVertical ? 1 : 1.5,
          mb: isVertical ? 2.5 : 0,
          ...textStyles['body-md'],
        },
        tablist: {
          width: isVertical ? '20%' : '100%',
        },
      };
    },
    lg: (props: any) => {
      return {
        tab: {
          px: 4,
          py: 3,
          ...textStyles['body-md'],
        },
      };
    },
  },
  variants: {
    attached: (props) => ({
      root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      },
      tablist: {
        bg: mode('gray.300', 'gray.700')(props),
        borderBottomRadius: 'none',
        '& > :first-of-type': {
          borderLeftRadius: 'base',
          borderBottomRadius: 'none',
        },
        '& > :last-child': {
          borderRightRadius: 'base',
          borderRightColor: 'transparent',
          borderBottomRadius: 'none',
        },
      },
      tab: {
        borderBottomRadius: 'none',
        px: 8,
        borderRight: 'solid 1px',
        borderRightColor: mode('gray.100', 'gray.800')(props),
        _selected: {
          borderColor: 'transparent',
          bg: mode('gray.100', 'gray.800')(props),
        },
        _hover: {
          bg: mode('gray.100', 'gray.800')(props),
          borderColor: 'transparent',
        },
      },
      tabpanels: {
        flexGrow: 1,
      },
      tabpanel: {
        py: 4,
        px: 6,
        bg: mode('gray.100', 'gray.800')(props),
        borderBottomRadius: 'base',
      },
    }),
    'enclosed-colored': (props: any) => {
      const isVertical = props.orientation === 'vertical';
      return {
        tablist: {
          bg: isVertical ? 'transparent' : 'layer.2',
          p: isVertical ? 2.5 : 0,
          borderBottom: 'solid 1px',
          borderBottomColor: isVertical ? 'transparent' : 'border.1',
          mb: 0,
        },
        tab: {
          border: 'none',
          bg: isVertical ? 'layer.1' : 'layer.2',
          borderLeft: isVertical ? 'none' : 'solid 1px',
          borderLeftColor: 'border.1',
          borderRadius: isVertical ? 'base' : 'none',
          justifyContent: isVertical ? 'start' : 'center',
          position: 'relative',
          mb: 0,
          _after: {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '3px',
          },
          _selected: {
            bg: isVertical ? 'layer.2' : 'layer.1',
            color: 'text.primary',
            _after: {
              bg: !isVertical && 'blue.600',
            },
          },
          _hover: {
            bg: 'layer.1',
          },
          _disabled: {
            cursor: 'default',
            opacity: 0.3,
            _hover: {
              bg: 'transparent',
            },
          },
        },
        tabpanel: {
          p: 0,
          height: '100%',
          overflowY: 'auto',
        },
      };
    },
    line: (props) => {
      return {
        tablist: {
          borderBottom: 'solid 1px',
          borderBottomColor: 'border.1',
          w: '100%',
        },
        tab: {
          pb: 2,
          borderBottom: 'none',
          _after: {
            content: '""',
            position: 'absolute',
            bottom: '1px',
            left: 0,
            right: 0,
            height: '3px',
          },
          _selected: {
            borderBottomColor: 'transparent',
            color: 'text.primary',
            _after: {
              bg: 'blue.600',
            },
          },
        },
      };
    },
    'solid-rounded': (props) => {
      return {
        tab: {
          fontWeight: 'normal',
          _selected: {
            bg: 'blue.600',
            color: 'text.primary',
          },
        },
      };
    },
  },
  defaultProps: {
    variant: 'enclosed-colored',
    size: 'md',
  },
};
