import { createIcon } from '@chakra-ui/react';

export const PoliciesIcon = createIcon({
  displayName: 'PoliciesIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.535 5A3.998 3.998 0 0 1 12 3c1.48 0 2.773.804 3.465 2H20a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h4.535ZM13 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-7 4v2h12v-2H6Zm0 4v2h8v-2H6Z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
