import { CONSTANTS } from '@gamma/investigator/constants';
import { UserRole } from '@gamma/investigator/context';
import { CognitoUserInterface } from '@gamma/shared/types';
import { Dispatch, SetStateAction } from 'react';

interface SetRoleProps {
  user: CognitoUserInterface;
  setUserRole: Dispatch<SetStateAction<UserRole>>;
}

export const setRole = ({ user, setUserRole }: SetRoleProps) => {
  const cognitoGroups =
    user?.signInUserSession?.accessToken?.payload['cognito:groups'];

  setUserRole(
    cognitoGroups?.includes(CONSTANTS.ROLE.admin)
      ? 'admin'
      : cognitoGroups?.includes(CONSTANTS.ROLE.analyst)
      ? 'analyst'
      : cognitoGroups?.includes(CONSTANTS.ROLE.viewer)
      ? 'viewer'
      : undefined,
  );
};
