import {
  gql,
  LazyQueryHookOptions,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client';

import { IVerifyEscalationConfiguration } from './types';

export const VERIFY_ESCALATION_CONFIGURATION = gql`
  query verifyEscalationConfiguration(
    $escalation_configuration: EscalationConfigurationInput!
  ) {
    verifyEscalationConfiguration(
      escalation_configuration: $escalation_configuration
    ) {
      verified
    }
  }
`;

export const useVerifyEscalationConfiguration = (
  options: LazyQueryHookOptions<
    IVerifyEscalationConfiguration,
    OperationVariables
  >,
) => useLazyQuery(VERIFY_ESCALATION_CONFIGURATION, options);
