import { CloseButton, Flex, Heading } from '@chakra-ui/react';
import { CookieContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { getIsCookieExpired } from '@gamma/investigator/utilities';
import { useContext, useEffect, useState } from 'react';

import { CookiePolicyBody } from './CookiePolicyBody/CookiePolicyBody';
import { CookiePolicyFooter } from './CookiePolicyFooter/CookiePolicyFooter';
import { CookiePolicyOverlay } from './CookiePolicyOverlay/CookiePolicyOverlay';

const { cookiePolicy } = i18n;

export const CookiePolicy = () => {
  const { getUserCookieAcceptance, isOpen, setCookieConfig, initialStep } =
    useContext(CookieContext);

  const { analyticsCookie, isCookiePolicyExpired } = getIsCookieExpired(
    getUserCookieAcceptance,
  );

  const [stepNumber, setStepNumber] = useState<number>(Number(initialStep));

  const [isCookieStillAccepted, setIsCookieStillAccepted] = useState<boolean>(
    Boolean(analyticsCookie?.cookies_accepted),
  );

  const handleCloseCookiePolicyWizard = () => {
    setCookieConfig?.((prev) => {
      return {
        ...prev,
        isOpen: false,
      };
    });
  };

  useEffect(() => {
    if (isCookiePolicyExpired) {
      return setStepNumber(1);
    }

    setStepNumber(2);
  }, [isCookiePolicyExpired, isOpen]);

  if (!isOpen) return null;

  return (
    <CookiePolicyOverlay
      title={
        <Flex>
          <Heading
            data-testid="cookie-policy-heading"
            fontWeight="normal"
            pb={2}
            textStyle="h4"
          >
            {stepNumber === 1
              ? cookiePolicy.stepOne.title
              : cookiePolicy.stepTwo.title}
          </Heading>
          {stepNumber === 2 && (
            <CloseButton
              position={'absolute'}
              right={4}
              onClick={handleCloseCookiePolicyWizard}
            />
          )}
        </Flex>
      }
      footer={
        <CookiePolicyFooter
          isCookieAccepted={analyticsCookie?.cookies_accepted}
          stepNumber={stepNumber}
          setStepNumber={setStepNumber}
          isCookieStillAccepted={isCookieStillAccepted}
        />
      }
      isOpen={isOpen}
      initialStep={initialStep}
      children={
        <CookiePolicyBody
          isCookieAccepted={Boolean(analyticsCookie?.cookies_accepted)}
          stepNumber={stepNumber}
          setIsCookieStillAccepted={setIsCookieStillAccepted}
          isCookiePolicyExpired={isCookiePolicyExpired}
        />
      }
    />
  );
};
