import { createIcon } from '@chakra-ui/react';

export const StandardLicenseStatus = createIcon({
  displayName: 'StandardEvalLicenseStatus',
  viewBox: '0 0 96 96',
  path: (
    <>
      <path
        d="M54.2891 6.19375L51.0303 7.03413L10.8814 17.3877L7.62263 18.2281C6.04085 18.6379 4.68622 19.6584 3.85594 21.0658C3.02565 22.4731 2.78751 24.1523 3.19376 25.735L17.3246 80.5311C17.7344 82.1129 18.7549 83.4675 20.1622 84.2978C21.5695 85.1281 23.2488 85.3663 24.8315 84.96L24.8399 84.9578L71.4895 72.9278L71.4979 72.9257C73.0797 72.5159 74.4343 71.4953 75.2646 70.088C76.0949 68.6807 76.3331 67.0015 75.9268 65.4188L61.796 10.6227C61.3862 9.04087 60.3657 7.68624 58.9583 6.85595C57.551 6.02565 55.8718 5.7875 54.2891 6.19375Z"
        fill="#525252"
      />
      <path
        d="M54.7322 7.91201L50.8724 8.90738L11.9254 18.951L8.06564 19.9464C6.93922 20.2382 5.97455 20.9649 5.38328 21.9671C4.79201 22.9693 4.62242 24.1651 4.91172 25.2922L19.0425 80.0883C19.3344 81.2147 20.0611 82.1794 21.0633 82.7707C22.0655 83.3619 23.2613 83.5315 24.3884 83.2422L24.3968 83.24L71.0464 71.21L71.0549 71.2079C72.1813 70.916 73.146 70.1893 73.7372 69.1871C74.3285 68.1849 74.4981 66.9891 74.2088 65.862L60.0779 11.0659C59.7861 9.93953 59.0594 8.97487 58.0572 8.3836C57.055 7.79233 55.8592 7.62273 54.7322 7.91201Z"
        fill="#262626"
      />
      <path
        d="M51.8179 25.6564L28.219 31.7421C27.9554 31.8101 27.6757 31.7705 27.4412 31.6322C27.2068 31.4939 27.0369 31.2681 26.9689 31.0046C26.9009 30.741 26.9405 30.4612 27.0788 30.2268C27.2171 29.9923 27.4429 29.8224 27.7064 29.7545L51.3054 23.6688C51.5688 23.6011 51.8484 23.6407 52.0827 23.7791C52.3169 23.9174 52.4866 24.1431 52.5546 24.4065C52.6225 24.6699 52.5831 24.9496 52.445 25.1839C52.3068 25.4183 52.0813 25.5882 51.8179 25.6564Z"
        fill="#8D8D8D"
      />
      <path
        d="M56.7822 27.9532L29.0838 35.0961C28.8202 35.1641 28.5404 35.1246 28.306 34.9862C28.0715 34.8479 27.9016 34.6222 27.8337 34.3586C27.7657 34.095 27.8052 33.8152 27.9435 33.5808C28.0818 33.3463 28.3076 33.1765 28.5712 33.1085L56.2696 25.9656C56.5332 25.8977 56.813 25.9372 57.0474 26.0755C57.2818 26.2138 57.4517 26.4396 57.5197 26.7031C57.5877 26.9667 57.5482 27.2465 57.4098 27.4809C57.2715 27.7154 57.0457 27.8853 56.7822 27.9532Z"
        fill="#8D8D8D"
      />
      <path
        d="M55.6943 40.6879L32.0954 46.7736C31.9648 46.8072 31.829 46.8148 31.6955 46.796C31.5621 46.7771 31.4336 46.7322 31.3176 46.6637C31.2015 46.5952 31.1 46.5045 31.019 46.3968C30.9379 46.2891 30.8789 46.1665 30.8453 46.036C30.8116 45.9055 30.804 45.7697 30.8228 45.6362C30.8417 45.5027 30.8866 45.3743 30.9551 45.2582C31.0236 45.1421 31.1143 45.0407 31.222 44.9597C31.3297 44.8786 31.4523 44.8196 31.5828 44.7859L55.1817 38.7002C55.3122 38.6666 55.4481 38.659 55.5815 38.6778C55.715 38.6967 55.8434 38.7416 55.9595 38.8101C56.0756 38.8786 56.177 38.9693 56.2581 39.077C56.3391 39.1847 56.3981 39.3073 56.4318 39.4378C56.4655 39.5683 56.4731 39.7041 56.4542 39.8376C56.4354 39.971 56.3904 40.0995 56.3219 40.2156C56.2535 40.3316 56.1628 40.4331 56.0551 40.5141C55.9474 40.5952 55.8248 40.6542 55.6943 40.6879Z"
        fill="#8D8D8D"
      />
      <path
        d="M60.6585 42.9849L32.9601 50.1277C32.8296 50.1614 32.6937 50.169 32.5603 50.1502C32.4268 50.1313 32.2984 50.0864 32.1823 50.0179C32.0662 49.9494 31.9648 49.8587 31.8837 49.751C31.8027 49.6433 31.7437 49.5207 31.71 49.3902C31.6763 49.2597 31.6687 49.1238 31.6876 48.9904C31.7064 48.8569 31.7514 48.7285 31.8199 48.6124C31.8883 48.4963 31.979 48.3949 32.0867 48.3138C32.1944 48.2328 32.317 48.1738 32.4475 48.1401L60.1459 40.9972C60.4095 40.9293 60.6893 40.9688 60.9237 41.1071C61.1582 41.2454 61.3281 41.4712 61.396 41.7348C61.464 41.9983 61.4245 42.2781 61.2862 42.5126C61.1479 42.747 60.9221 42.9169 60.6585 42.9849Z"
        fill="#8D8D8D"
      />
      <path
        d="M59.5706 55.7191L35.9717 61.8048C35.7082 61.8726 35.4286 61.8329 35.1943 61.6946C34.96 61.5563 34.7902 61.3306 34.7223 61.0671C34.6543 60.8036 34.6938 60.524 34.832 60.2896C34.9701 60.0552 35.1957 59.8853 35.4591 59.8172L59.058 53.7315C59.3216 53.6635 59.6014 53.703 59.8358 53.8414C60.0703 53.9797 60.2402 54.2054 60.3081 54.469C60.3761 54.7326 60.3366 55.0124 60.1983 55.2468C60.06 55.4813 59.8342 55.6511 59.5706 55.7191Z"
        fill="#8D8D8D"
      />
      <path
        d="M64.5351 58.0161L36.8367 65.159C36.7061 65.1928 36.5702 65.2005 36.4366 65.1817C36.3031 65.163 36.1745 65.1181 36.0584 65.0496C35.9422 64.9811 35.8406 64.8904 35.7595 64.7827C35.6784 64.6749 35.6194 64.5523 35.5857 64.4217C35.552 64.2911 35.5444 64.1552 35.5633 64.0216C35.5822 63.8881 35.6272 63.7596 35.6958 63.6435C35.7643 63.5274 35.8551 63.4259 35.9629 63.3449C36.0708 63.2639 36.1935 63.2049 36.3241 63.1714L64.0225 56.0285C64.2861 55.9605 64.5659 56 64.8003 56.1384C65.0347 56.2767 65.2046 56.5024 65.2726 56.766C65.3406 57.0296 65.301 57.3094 65.1627 57.5438C65.0244 57.7783 64.7986 57.9481 64.5351 58.0161Z"
        fill="#8D8D8D"
      />
      <path
        d="M24.1903 37.982L15.8388 40.1356C15.7125 40.1681 15.5784 40.149 15.466 40.0827C15.3536 40.0164 15.2721 39.9083 15.2394 39.782L13.3224 32.348C13.2899 32.2217 13.3089 32.0876 13.3752 31.9752C13.4415 31.8628 13.5497 31.7813 13.676 31.7486L22.0274 29.5949C22.1538 29.5625 22.2879 29.5815 22.4003 29.6478C22.5126 29.7141 22.5941 29.8223 22.6269 29.9486L24.5439 37.3825C24.5764 37.5089 24.5574 37.643 24.4911 37.7554C24.4248 37.8677 24.3166 37.9492 24.1903 37.982Z"
        fill="#8D8D8D"
      />
      <path
        d="M28.0666 53.0134L19.7152 55.1671C19.5888 55.1996 19.4547 55.1805 19.3423 55.1142C19.23 55.0479 19.1485 54.9398 19.1158 54.8135L17.1987 47.3795C17.1663 47.2531 17.1853 47.1191 17.2516 47.0067C17.3179 46.8943 17.426 46.8128 17.5523 46.7801L25.9038 44.6264C26.0302 44.594 26.1642 44.613 26.2766 44.6793C26.389 44.7456 26.4705 44.8538 26.5032 44.9801L28.4203 52.414C28.4527 52.5404 28.4337 52.6745 28.3674 52.7869C28.3011 52.8992 28.1929 52.9807 28.0666 53.0134Z"
        fill="#8D8D8D"
      />
      <path
        d="M31.943 68.0451L23.5916 70.1987C23.4652 70.2312 23.3311 70.2122 23.2188 70.1459C23.1064 70.0796 23.0249 69.9714 22.9922 69.8451L21.0751 62.4111C21.0427 62.2848 21.0617 62.1507 21.128 62.0383C21.1943 61.9259 21.3024 61.8444 21.4287 61.8117L29.7802 59.6581C29.9066 59.6256 30.0406 59.6446 30.153 59.7109C30.2654 59.7772 30.3469 59.8854 30.3796 60.0117L32.2967 67.4456C32.3291 67.572 32.3101 67.7061 32.2438 67.8185C32.1775 67.9309 32.0693 68.0123 31.943 68.0451Z"
        fill="#8D8D8D"
      />
      <path
        d="M86.8369 20.0841H38.6437C37.0097 20.0859 35.4431 20.7359 34.2877 21.8913C33.1323 23.0467 32.4824 24.6132 32.4805 26.2472V82.8361C32.4824 84.4701 33.1323 86.0366 34.2877 87.192C35.4431 88.3474 37.0097 88.9974 38.6437 88.9992H86.8369C88.4709 88.9974 90.0374 88.3474 91.1928 87.192C92.3482 86.0366 92.9981 84.4701 93 82.8361V26.2472C92.9981 24.6132 92.3482 23.0467 91.1928 21.8913C90.0374 20.7359 88.4709 20.0859 86.8369 20.0841Z"
        fill="#525252"
      />
      <path
        d="M86.8368 21.8583H38.6436C37.4799 21.8596 36.3644 22.3224 35.5416 23.1452C34.7188 23.968 34.2559 25.0836 34.2546 26.2472V82.836C34.2559 83.9996 34.7188 85.1152 35.5416 85.938C36.3644 86.7608 37.4799 87.2236 38.6436 87.2249H86.8368C88.0004 87.2236 89.116 86.7608 89.9388 85.938C90.7616 85.1152 91.2244 83.9996 91.2257 82.836V26.2472C91.2244 25.0836 90.7616 23.968 89.9388 23.1452C89.116 22.3224 88.0004 21.8596 86.8368 21.8583Z"
        fill="#262626"
      />
      <path
        d="M79.5839 52.9537H55.2129C55.078 52.9538 54.9444 52.9274 54.8198 52.8759C54.6951 52.8243 54.5819 52.7488 54.4864 52.6535C54.391 52.5581 54.3153 52.4449 54.2637 52.3204C54.212 52.1958 54.1854 52.0622 54.1854 51.9273C54.1854 51.7925 54.212 51.6589 54.2637 51.5343C54.3153 51.4097 54.391 51.2965 54.4864 51.2012C54.5819 51.1059 54.6951 51.0303 54.8198 50.9788C54.9444 50.9273 55.078 50.9009 55.2129 50.901H79.5839C79.8559 50.9013 80.1166 51.0096 80.3089 51.202C80.5011 51.3945 80.6091 51.6553 80.6091 51.9273C80.6091 52.1993 80.5011 52.4602 80.3089 52.6526C80.1166 52.8451 79.8559 52.9534 79.5839 52.9537Z"
        fill="#8D8D8D"
      />
      <path
        d="M83.8175 56.4174H55.2129C55.078 56.4176 54.9444 56.3911 54.8198 56.3396C54.6951 56.2881 54.5819 56.2125 54.4864 56.1172C54.391 56.0219 54.3153 55.9087 54.2637 55.7841C54.212 55.6595 54.1854 55.5259 54.1854 55.3911C54.1854 55.2562 54.212 55.1226 54.2637 54.998C54.3153 54.8735 54.391 54.7603 54.4864 54.6649C54.5819 54.5696 54.6951 54.4941 54.8198 54.4425C54.9444 54.391 55.078 54.3646 55.2129 54.3647H83.8175C84.0897 54.3647 84.3507 54.4729 84.5432 54.6653C84.7357 54.8578 84.8438 55.1189 84.8438 55.3911C84.8438 55.6633 84.7357 55.9243 84.5432 56.1168C84.3507 56.3093 84.0897 56.4174 83.8175 56.4174Z"
        fill="#8D8D8D"
      />
      <path
        d="M79.5839 68.4769H55.2129C55.078 68.477 54.9444 68.4506 54.8198 68.3991C54.6951 68.3475 54.5819 68.272 54.4864 68.1767C54.391 68.0813 54.3153 67.9681 54.2637 67.8435C54.212 67.719 54.1854 67.5854 54.1854 67.4505C54.1854 67.3156 54.212 67.1821 54.2637 67.0575C54.3153 66.9329 54.391 66.8197 54.4864 66.7244C54.5819 66.6291 54.6951 66.5535 54.8198 66.502C54.9444 66.4505 55.078 66.424 55.2129 66.4242H79.5839C79.8561 66.4242 80.1171 66.5323 80.3096 66.7248C80.5021 66.9173 80.6102 67.1783 80.6102 67.4505C80.6102 67.7227 80.5021 67.9838 80.3096 68.1762C80.1171 68.3687 79.8561 68.4769 79.5839 68.4769Z"
        fill="#8D8D8D"
      />
      <path
        d="M83.8175 71.9407H55.2129C55.078 71.9409 54.9444 71.9144 54.8198 71.8629C54.6951 71.8114 54.5819 71.7358 54.4864 71.6405C54.391 71.5452 54.3153 71.432 54.2637 71.3074C54.212 71.1828 54.1854 71.0493 54.1854 70.9144C54.1854 70.7795 54.212 70.646 54.2637 70.5214C54.3153 70.3968 54.391 70.2836 54.4864 70.1883C54.5819 70.0929 54.6951 70.0174 54.8198 69.9659C54.9444 69.9143 55.078 69.8879 55.2129 69.8881H83.8175C83.9524 69.8879 84.0859 69.9143 84.2106 69.9659C84.3352 70.0174 84.4485 70.0929 84.5439 70.1883C84.6394 70.2836 84.7151 70.3968 84.7667 70.5214C84.8184 70.646 84.845 70.7795 84.845 70.9144C84.845 71.0493 84.8184 71.1828 84.7667 71.3074C84.7151 71.432 84.6394 71.5452 84.5439 71.6405C84.4485 71.7358 84.3352 71.8114 84.2106 71.8629C84.0859 71.9144 83.9524 71.9409 83.8175 71.9407Z"
        fill="#8D8D8D"
      />
      <path
        d="M49.7536 57.9899H41.129C40.9985 57.9897 40.8734 57.9378 40.7811 57.8456C40.6889 57.7533 40.637 57.6282 40.6368 57.4978V49.8206C40.637 49.6901 40.6889 49.565 40.7811 49.4728C40.8734 49.3805 40.9985 49.3286 41.129 49.3285H49.7536C49.8841 49.3286 50.0092 49.3805 50.1014 49.4728C50.1937 49.565 50.2456 49.6901 50.2458 49.8206V57.4978C50.2456 57.6282 50.1937 57.7533 50.1014 57.8456C50.0092 57.9378 49.8841 57.9897 49.7536 57.9899Z"
        fill="#8D8D8D"
      />
      <path
        d="M49.7536 73.5131H41.129C40.9985 73.5129 40.8734 73.461 40.7811 73.3688C40.6889 73.2765 40.637 73.1514 40.6368 73.021V65.3438C40.637 65.2133 40.6889 65.0882 40.7811 64.996C40.8734 64.9037 40.9985 64.8518 41.129 64.8517H49.7536C49.8841 64.8518 50.0092 64.9037 50.1014 64.996C50.1937 65.0882 50.2456 65.2133 50.2458 65.3438V73.021C50.2456 73.1514 50.1937 73.2765 50.1014 73.3688C50.0092 73.461 49.8841 73.5129 49.7536 73.5131Z"
        fill="#8D8D8D"
      />
      <path
        d="M79.6107 35.6734H61.643C61.3708 35.6734 61.1097 35.5653 60.9172 35.3728C60.7248 35.1803 60.6166 34.9193 60.6166 34.6471C60.6166 34.3749 60.7248 34.1138 60.9172 33.9213C61.1097 33.7289 61.3708 33.6207 61.643 33.6207H79.6107C79.8829 33.6207 80.1439 33.7289 80.3364 33.9213C80.5289 34.1138 80.637 34.3749 80.637 34.6471C80.637 34.9193 80.5289 35.1803 80.3364 35.3728C80.1439 35.5653 79.8829 35.6734 79.6107 35.6734Z"
        fill="#8D8D8D"
      />
      <path
        d="M83.8443 39.1372H61.643C61.5082 39.1372 61.3747 39.1107 61.2502 39.0591C61.1257 39.0075 61.0125 38.9319 60.9172 38.8366C60.8219 38.7413 60.7463 38.6282 60.6948 38.5037C60.6432 38.3792 60.6166 38.2457 60.6166 38.1109C60.6166 37.9761 60.6432 37.8427 60.6948 37.7182C60.7463 37.5936 60.8219 37.4805 60.9172 37.3852C61.0125 37.2899 61.1257 37.2143 61.2502 37.1627C61.3747 37.1111 61.5082 37.0846 61.643 37.0846H83.8443C84.1165 37.0846 84.3775 37.1927 84.57 37.3852C84.7625 37.5777 84.8706 37.8387 84.8706 38.1109C84.8706 38.3831 84.7625 38.6442 84.57 38.8366C84.3775 39.0291 84.1165 39.1372 83.8443 39.1372Z"
        fill="#8D8D8D"
      />
      <path
        d="M57.6666 43.3348H41.1019C40.9714 43.3346 40.8463 43.2827 40.754 43.1905C40.6618 43.0982 40.6099 42.9731 40.6097 42.8427V29.9149C40.6099 29.7844 40.6618 29.6593 40.754 29.567C40.8463 29.4748 40.9714 29.4229 41.1019 29.4227H57.6666C57.797 29.4229 57.9221 29.4748 58.0144 29.567C58.1066 29.6593 58.1585 29.7844 58.1587 29.9149V42.8427C58.1585 42.9731 58.1066 43.0982 58.0144 43.1905C57.9221 43.2827 57.797 43.3346 57.6666 43.3348Z"
        fill="#43BF79"
      />
    </>
  ),
  defaultProps: {
    height: '96px',
    width: '96px',
  },
});
