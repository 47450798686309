import {
  Box,
  PlacementWithLogical,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { DetectionSummary } from '@gamma/investigator/queries';
import { ReactNode } from 'react';

import { TopDetectionEntitiesTablePopperContent } from './TopDetectionEntitiesTablePopperContent';

export const TopDetectionEntitiesTablePopper =
  (placement: PlacementWithLogical = 'right-start') =>
  ({ children, ...rest }: DetectionSummary & { children: ReactNode }) => {
    return (
      <Popover isLazy trigger="hover" openDelay={300} placement={placement}>
        <PopoverTrigger>
          <Box data-testid="top-entities-table-popper-trigger" role="button">
            {children}
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            w="500px"
            _focus={{
              outline: 'none',
              boxShadow: 'none',
            }}
          >
            <TopDetectionEntitiesTablePopperContent {...rest} />
          </PopoverContent>
        </Portal>
      </Popover>
    );
  };
