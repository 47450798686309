import { ComponentStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import {
  darkReadOnlyInputBg,
  lightReadOnlyInputBg,
  textStyles,
} from '../../common';

export const NumberInputStyles: ComponentStyleConfig = {
  baseStyle: (props: any) => {
    return {
      field: {
        bg: 'layer.1',
        backgroundColor: 'layer.1',
        borderRadius: 'base',
        _readOnly: {
          bg: mode(lightReadOnlyInputBg, darkReadOnlyInputBg)(props),
          cursor: 'not-allowed',
        },
      },
      stepperGroup: {
        py: 0.5,
        w: 7,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
      },
      stepper: {
        color: mode('gray.900', 'gray.50')(props),
        bg: mode('blackAlpha.200', 'whiteAlpha.200')(props),
        borderStart: 'none',
        w: '22px',
        flex: '0 !important',
        borderRadius: '2px !important',
        _last: {
          borderTop: 'none',
        },
      },
    };
  },
  defaultProps: {
    errorBorderColor: 'red.300',
  },
  variants: {
    outline: (props: any) => {
      return {
        field: {
          borderColor: 'border.1',
        },
        stepper: {
          borderColor: 'border.1',
        },
      };
    },
  },
  sizes: {
    xs: {
      stepper: {
        ...textStyles['body-sm'],
        h: 2,
      },
      field: {
        ...textStyles['body-sm'],
        px: 2, // 8px
        py: 1, // 4px
        height: '24px',
      },
      addon: {
        ...textStyles['body-sm'],
      },
    },
    sm: {
      stepper: {
        ...textStyles['body-sm'],
        h: 2.5,
      },
      field: {
        ...textStyles['body-sm'],
        px: 2, // 8px
        py: 1.5, // 6px
        height: '28px',
      },
      addon: {
        ...textStyles['body-sm'],
      },
    },
    md: {
      stepper: {
        ...textStyles['body-md'],
        h: 3,
      },
      field: {
        ...textStyles['body-md'],
        px: 2, // 8px
        py: 1.5, // 6px
        height: '32px',
      },
      addon: {
        ...textStyles['body-md'],
      },
    },
    lg: {
      stepper: {
        ...textStyles['body-md'],
        h: 4,
      },
      field: {
        ...textStyles['body-md'],
        px: 3, // 12px
        py: 2.5, // 10px
        height: '40px',
      },
      addon: {
        ...textStyles['body-md'],
      },
    },
  },
};
