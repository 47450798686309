import { OperationVariables } from '@apollo/client';
import { Button, Divider, Link, Stack, Text } from '@chakra-ui/react';
import { FileUploadBox } from '@gamma/form-fields';
import { i18n } from '@gamma/investigator/localization';
import { Alert } from '@gamma/overlay';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';

import { Panel, PanelHeading } from '@gamma/layout';
import { MutationFunctionType } from '../../../types';
import { attributeMapping } from '../IdpConfigurationModal';

interface ConfigureInvestigatorProps {
  useFormProps: UseFormReturn<FieldValues, any>;
  xmlFile: File[];
  showAlert: string;
  fileError: string;
  setFileError: Dispatch<SetStateAction<string>>;
  setXmlFile: Dispatch<SetStateAction<File[]>>;
  resetModalForm: any;
  configureIdentityProvider: MutationFunctionType;
  data: OperationVariables | undefined;
  isUpdating: boolean;
  useIDPUploadXMLHandlerProps: Record<string, any>;
  parsedData: Record<string, string> | undefined;
  setParsedData: Dispatch<SetStateAction<any>>;
}

export const ConfigureInvestigator = ({
  useFormProps,
  xmlFile,
  showAlert,
  configureIdentityProvider,
  fileError,
  setFileError,
  setXmlFile,
  resetModalForm,
  data,
  isUpdating,
  useIDPUploadXMLHandlerProps,
  parsedData,
  setParsedData,
}: ConfigureInvestigatorProps) => {
  const { idpConfigurationModal, form } = i18n.pages.access;
  const { handleSubmit } = useFormProps;
  const describeData = data?.describeIdentityProvider;
  const isConfigured = !!describeData?.provider_details;
  const { uploadFile, autoFill, xml } = useIDPUploadXMLHandlerProps;
  const [showValidationMore, setShowValidationMore] = useState<boolean>(false);

  useEffect(() => {
    if (describeData?.provider_details) {
      const xmlString = JSON.parse(describeData.provider_details);
      autoFill(xmlString);
      setXmlFile([]);
      setFileError('');
    }
  }, [describeData]);

  const updateInvalidState = (e: File[], error: string) => {
    setFileError(error);
    setXmlFile(e);
    setParsedData({});
  };

  const onUploadChange = async (e: File[]) => {
    if (e.length) {
      if (e[0].type !== 'text/xml') {
        updateInvalidState(e, 'File type should be xml');
      } else if (e[0].size > 32 * 1024) {
        updateInvalidState(e, 'File size should not be more than 32Kb');
      } else if (e[0].type === 'text/xml' && e[0].size <= 32 * 1024) {
        setFileError('');
        const file = await uploadFile(e);
        setXmlFile(file);
      }
    } else {
      resetModalForm();
    }
  };

  const onSubmit = async () => {
    if (isUpdating || isConfigured) {
      return null;
    }

    const variables = {
      attribute_mapping: attributeMapping,
      provider_details: xml,
      provider_type: 'xml',
    };

    configureIdentityProvider({
      variables,
    });
  };

  return (
    <Stack spacing={6}>
      <Stack
        spacing={3}
        as="form"
        id="idpConfigurationForm"
        onSubmit={handleSubmit(onSubmit)}
      >
        {showAlert && <Alert status="error" title={showAlert} />}
        <FileUploadBox
          name="upload_file"
          label={form.uploadBox}
          uploadText={form.uploadBox}
          isLabelHidden
          accept="text/xml"
          onChange={onUploadChange}
          value={xmlFile}
          fieldError={fileError}
        />
        {parsedData?.signon_url && (
          <>
            <Divider />
            <Panel layerStyle="second">
              <PanelHeading>{form.ssoUrl.label}</PanelHeading>
              <Text>{parsedData?.signon_url}</Text>
            </Panel>
            <Panel layerStyle="second">
              <PanelHeading>{form.entityId.label}</PanelHeading>
              <Text>{parsedData?.entity_id}</Text>
            </Panel>
            <Panel layerStyle="second">
              <PanelHeading>{form.certificate.label}</PanelHeading>
              <Text noOfLines={showValidationMore ? undefined : 3}>
                {parsedData?.certificate}
              </Text>
              {parsedData?.certificate.length > 128 && (
                <Button
                  variant="link"
                  textDecor={'none'}
                  size="sm"
                  colorScheme="blue"
                  mt={1}
                  onClick={() => setShowValidationMore(!showValidationMore)}
                >
                  {showValidationMore ? 'show less' : 'show more'}
                </Button>
              )}
            </Panel>
          </>
        )}
      </Stack>
      <Stack>
        <Text>{idpConfigurationModal.configure.help}</Text>
        <Link
          isExternal
          color="blue.400"
          href="/docs/settings/users/saml-sso-users.html"
          width="fit-content"
        >
          {idpConfigurationModal.configure.helpTextSSO}
        </Link>
      </Stack>
    </Stack>
  );
};
