import { Box, Center } from '@chakra-ui/react';
import { DataList } from '@gamma/data-table';
import { ROUTES } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import { Detection, IQueryDetectionsPaginated } from '@gamma/investigator/queries';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { NavigateFunction, useParams } from 'react-router-dom';
import { SortingRule } from 'react-table';

import { SantizedDetection } from '../../Detections';
import { DetectionDrawer, DetectionDrawerActions } from '../DetectionDrawer';
import { DetectionRow } from '../DetectionRow';

const { detections, detectionDetails } = ROUTES;

const { controls } = i18n.pages.detections;

interface DetectionsListViewProps {
  offset: number;
  pageSize: number;
  pageIndex: number;
  pageCount: number;
  queryVariables: any;
  isRelated?: boolean;
  params: URLSearchParams;
  navigate: NavigateFunction;
  detectionsLoading: boolean;
  detectionsData?: IQueryDetectionsPaginated;
  selectedItem?: Detection | SantizedDetection;
  detectionData?: Detection | SantizedDetection;
  setIsExcluded: Dispatch<SetStateAction<boolean>>;
  formatSort: (value: string) => { id: string; desc: boolean }[];
  handleFetchData: (pageIndex: number, pageSize: number, sortBy?: any) => void;
  setDetectionData: Dispatch<
    SetStateAction<Detection | SantizedDetection | undefined>
  >;
  setSelectedItem: Dispatch<
    SetStateAction<Detection | SantizedDetection | undefined>
  >;
  getSortsForAPI: (
    sortBy: SortingRule<Detection>[],
  ) => { sort_by: string; sort_dir: string }[];
}

export const DetectionsListView = ({
  params,
  navigate,
  isRelated,
  offset,
  pageSize,
  pageIndex,
  pageCount,
  formatSort,
  selectedItem,
  setIsExcluded,
  getSortsForAPI,
  detectionData,
  detectionsData,
  queryVariables,
  handleFetchData,
  setSelectedItem,
  setDetectionData,
  detectionsLoading,
}: DetectionsListViewProps) => {
  const active_view = params.get('view');

  const { detection_id } = useParams();

  useEffect(() => {
    if (active_view !== 'list') {
      params.set('view', 'list');
      navigate(
        isRelated && detection_id
          ? {
              pathname: detectionDetails(detection_id),
              search: params.toString(),
            }
          : {
              pathname: detections,
              search: params.toString(),
            },
        {
          replace: true,
        },
      );
    }
  }, [active_view]);

  return (
    <Box
      borderTop="1px solid"
      borderColor="border.layer.2"
      data-testid="data-list-wrapper"
    >
      <DataList
        storageKey="investigator-pages-detections-list"
        isPaginationManual
        splitWidth="493px"
        isLoading={detectionsLoading}
        pageCount={pageCount}
        RenderItem={DetectionRow}
        selectedItem={selectedItem}
        onFetchData={handleFetchData}
        onItemSelection={setSelectedItem}
        initialState={{
          pageSize,
          pageIndex,
        }}
        emptyBodyContent={
          detectionsData?.queryDetectionsPaginated?.detections?.length ===
            0 && <Center m={4}>{controls.noResults}</Center>
        }
        autoResetPage={offset / pageSize >= pageCount}
        itemCount={detectionsData?.queryDetectionsPaginated?.total_items}
        data={detectionsData?.queryDetectionsPaginated?.detections ?? []}
        matchFn={(item, selected) =>
          item.detection_id === selected?.detection_id
        }
        RenderSplit={(item) =>
          selectedItem && (
            <DetectionDrawer
              {...item}
              isRelated={isRelated}
              setDetectionData={setDetectionData}
              queryDetectionsPaginatedVariables={{
                query: queryVariables.query,
                size: queryVariables.size,
                offset: queryVariables.offset,
                sort: getSortsForAPI(formatSort(queryVariables.sortBy)),
              }}
            />
          )
        }
        actions={
          queryVariables &&
          !detectionsLoading &&
          selectedItem && (
            <DetectionDrawerActions
              detection={detectionData}
              setIsExcluded={setIsExcluded}
              queryDetectionsPaginatedVariables={{
                query: queryVariables.query,
                size: queryVariables.size,
                offset: queryVariables.offset,
                sort: getSortsForAPI(formatSort(queryVariables.sortBy)),
              }}
            />
          )
        }
      />
    </Box>
  );
};
