import { createIcon } from '@chakra-ui/react';

export const FullscreenIcon = createIcon({
  displayName: 'FullscreenIcon',
  path: [
    <path
      d="M15.25 4L20 4V8.75H22V4C22 2.89543 21.1046 2 20 2L15.25 2V4Z"
      fill="currentColor"
    />,
    <path
      d="M8.75 4V2L4 2C2.89543 2 2 2.89543 2 4L2 8.75L4 8.75V4L8.75 4Z"
      fill="currentColor"
    />,
    <path
      d="M4 15.25H2L2 20C2 21.1046 2.89543 22 4 22H8.75L8.75 20H4V15.25Z"
      fill="currentColor"
    />,
    <path
      d="M15.25 20H20V15.25H22V20C22 21.1046 21.1046 22 20 22H15.25V20Z"
      fill="currentColor"
    />,
  ],
  viewBox: '0 0 24 24',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
