import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';
import { GeneralSettings } from './types';

export interface IGetGeneralSettings {
  getGeneralSettings: GeneralSettings;
}

export const GET_GENERAL_SETTINGS = gql`
  query getGeneralSettings {
    getGeneralSettings {
      tenant_display_name_info {
        display_name
        updated_on
        updated_by
      }
    }
  }
`;

export const useGetGeneralSettings = (
  options: QueryHookOptions<IGetGeneralSettings, OperationVariables>,
) => {
  return useQuery<IGetGeneralSettings>(GET_GENERAL_SETTINGS, options);
};
