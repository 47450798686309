import { CONSTANTS } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import axios from 'axios';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

export const useSSOLoginHandler = () => {
  const { search } = useLocation();
  const { setUser } = useContext(AuthContext);

  const params = new URLSearchParams(search);

  const ssoErrors = i18n.pages.login.ssoSignInErrors.errorPage;

  const checkSsoError = () => {
    const error = params.get('error_description');
    if (error?.includes('Already found an entry for username')) {
      return { type: 'newUser', message: '' };
    } else if (error?.includes('Access is denied.')) {
      return { type: 'ssoError', message: ssoErrors.roleError };
    } else if (error?.includes('Invalid SAML response')) {
      return { type: 'ssoError', message: ssoErrors.generalError };
    } else {
      return { type: '', message: '' };
    }
  };

  const getSessionData = async () => {
    if (params.get('code')) {
      const {
        SSO: { domain, client_id, redirect_uri },
      } = CONSTANTS;

      const requestData = {
        grant_type: 'authorization_code',
        client_id: client_id,
        code: params.get('code'),
        redirect_uri: redirect_uri,
      };
      const client_secret = '';

      const data = await axios({
        method: 'post',
        url: `https://${domain}/oauth2/token`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: requestData,
      })
        .then(function (response) {
          const AccessToken = new AmazonCognitoIdentity.CognitoAccessToken({
            AccessToken: response.data.access_token ?? '',
          });

          const IdToken = new AmazonCognitoIdentity.CognitoIdToken({
            IdToken: response.data.id_token ?? '',
          });

          const RefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
            RefreshToken: response.data.refresh_token ?? '',
          });

          return {
            IdToken: IdToken,
            AccessToken: AccessToken,
            RefreshToken: RefreshToken,
          };
        })
        .catch(function (error) {
          return null;
        });
      return data;
    }
    return null;
  };

  const idpLogin = async (sessionData: any) => {
    if (sessionData?.AccessToken && sessionData?.IdToken) {
      const userPool = new AmazonCognitoIdentity.CognitoUserPool({
        UserPoolId:
          (window as any).__env__?.cognito.cognito_user_pool ??
          process.env.REACT_APP_COGNITO_USER_POOL,
        ClientId:
          (window as any).__env__?.cognito.userPoolWebClientId ??
          process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
      });

      const session = new AmazonCognitoIdentity.CognitoUserSession(sessionData);

      const userData = {
        Username: sessionData.AccessToken.payload.username,
        Pool: userPool,
      };

      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

      cognitoUser.setSignInUserSession(session);
      const userAttributes: any = {};
      cognitoUser.getUserAttributes((err, result: any) => {
        if (err) {
          console.log('getUserAttributes error', err);
          return;
        }
        result.forEach((element: { Name: string; Value: string }) => {
          userAttributes[element['Name']] = element['Value'];
        });
      });

      sessionStorage.setItem('isSSOLogin', 'true');

      const cognitoUserWithAttrs: any = {
        ...cognitoUser,
        attributes: { ...userAttributes, isSSOTestLogin: true },
        preferredMFA: 'SSO',
      };
      setUser?.(cognitoUserWithAttrs);
    }
  };

  return { idpLogin, getSessionData, checkSsoError };
};
