const getStringEnds = (string: string, maxLength: number) => {
  const halfLength = maxLength / 2;

  const prefix = string.slice(0, halfLength);
  const suffix = string.slice(string.length - halfLength);

  return { prefix, suffix };
};

export const truncateFileName = (
  selectedFileName: string,
  maxLength: number,
) => {
  if (selectedFileName.length < maxLength) {
    return selectedFileName;
  }

  const parts = selectedFileName.split('.');

  if (parts.length > 1) {
    const fileType = parts.pop();
    const fileName = parts.join('.');
    const adjustedMax = maxLength - (fileType?.length ?? 0);

    const { prefix, suffix } = getStringEnds(fileName, adjustedMax);

    return `${prefix}...${suffix}.${fileType}`;
  } else {
    const { prefix, suffix } = getStringEnds(selectedFileName, maxLength);

    return `${prefix}...${suffix}`;
  }
};
