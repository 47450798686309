import axios, { AxiosRequestConfig } from 'axios';

export const HUMIO_SET_THEME = (theme: string) => {
  return `
      mutation {
        updateSettings(uiTheme: ${theme}, featureAnnouncementsToDismiss: [PuffinRebranding, Interactions, FetchMoreOnFieldsPanel, FieldInteractions], isResizableQueryFieldMessageDismissed: true) {
          __typename
        }
      }
    `;
};

const humio = async (theme: string, isPOVTenant?: boolean) => {
  return {
    method: 'post',
    url: isPOVTenant
      ? process.env.NX_POV_HUMIO_BASE_HREF ?? '/search-internal/graphql'
      : process.env.NX_HUMIO_BASE_HREF ?? '/search/graphql',
    data: JSON.stringify({
      query: HUMIO_SET_THEME(theme),
    }),
    headers: {
      Authorization: `Bearer ${localStorage.getItem('session_id_token') || ''}`,
      'Content-Type': 'application/json',
    },
  };
};

const capitalize = (text: string) => {
  if (text.length) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return '';
};

export const setHumioTheme = async (theme?: string, isPOVTenant?: boolean) => {
  theme = theme ? capitalize(theme) : 'Dark';

  const humioConfig = await humio(theme, isPOVTenant);

  await axios({
    ...(humioConfig as AxiosRequestConfig),
  })
    .then(function (response) {
      // functionality can be added later
    })
    .catch(function (e) {
      console.error('set Humio theme error: ', e);
    });
};
