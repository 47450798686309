import { createIcon } from '@chakra-ui/react';

export const AccountManagementIcon = createIcon({
  displayName: 'AccountManagementIcon',
  viewBox: '0 0 24 24',
  d: 'M10 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm0 2a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm7 6c-.16 0-.24.08-.24.24l-.26 1.26c-.22.18-.54.34-.78.5l-1.28-.5c-.08 0-.24 0-.32.1l-.96 1.76c-.08.08-.08.24.08.32l1.04.82v1l-1.04.82c-.08.08-.16.24-.08.32l.96 1.76c.08.1.24.1.32.1l1.28-.5c.24.16.56.32.78.5l.26 1.26c0 .16.08.24.24.24h2c.08 0 .24-.08.24-.24l.16-1.26c.32-.18.64-.34.88-.5l1.22.5c.14 0 .3 0 .3-.1l1.04-1.76c.08-.08 0-.24-.08-.32l-1.04-.82v-1l1.04-.82c.08-.08.16-.24.08-.32L21.8 13.6c0-.1-.16-.1-.3-.1l-1.22.5c-.24-.16-.56-.32-.88-.5l-.16-1.26c0-.16-.16-.24-.24-.24h-2Zm-7 1c-2.67 0-8 1.33-8 4v3h9.67c-.28-.59-.48-1.23-.58-1.9H3.9V17c0-.64 3.13-2.1 6.1-2.1.43 0 .87.04 1.3.1.2-.64.47-1.24.82-1.79-.78-.13-1.52-.21-2.12-.21Zm8.04 2.5c.8 0 1.46.66 1.46 1.54 0 .8-.66 1.46-1.46 1.46-.88 0-1.54-.66-1.54-1.46 0-.88.66-1.54 1.54-1.54Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
