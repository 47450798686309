import { createIcon } from '@chakra-ui/react';

export const EyeOpenIcon = createIcon({
  displayName: 'EyeOpenIcon',
  viewBox: '0 0 24 24',
  d: 'M12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0 8a5 5 0 1 1 0-10 5 5 0 0 1 0 10Zm0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
