import { ApolloError } from '@apollo/client';
import { Box, Button, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { Select } from '@gamma/form-fields';
import { ROUTES } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import {
  AlertEntity,
  Detection,
  ExcludedEntity,
} from '@gamma/investigator/queries';
import { AccordionPanel } from '@gamma/layout';
import { Popover } from '@gamma/overlay';
import { SingleValue } from 'chakra-react-select';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface DetectionEntityPanelProps {
  refetchMain?: boolean;
  entityInfo: AlertEntity;
  entities?: AlertEntity[];
  detectionInfo?: Detection;
  excludeEntitiesData?: ExcludedEntity[];
  excludeEntitiesError?: ApolloError;
  setActiveEntity?: React.Dispatch<React.SetStateAction<AlertEntity | null>>;
  queryVariables?: {
    query: string;
    offset: number;
    size: number;
    sort: {
      sort_by: string;
      sort_dir: string;
    }[];
  };
}

interface EntityOption {
  value: string;
  label: string;
}

const { systemSettingsIntegrationsConfig } = ROUTES;

const { entityEDR } = i18n.pages.detections;
const { entity, entityType } = i18n.pages.entityDetails.excludeEntity;

export const DetectionEntityPanel = ({
  entities,
  entityInfo,
  setActiveEntity,
}: DetectionEntityPanelProps) => {
  const navigate = useNavigate();

  const { entity_type } = entityInfo;

  const entityOptions: EntityOption[] = entities?.length
    ? entities?.map((entity: AlertEntity) => {
        return {
          value: entity.entity_id,
          label: entity.entity_name,
        };
      })
    : [
        {
          value: '',
          label: '',
        },
      ];

  const [selectedOption, setSelectedOption] = useState<EntityOption | null>(
    null,
  );

  useEffect(() => {
    setSelectedOption({
      value: entityInfo?.entity_id || '',
      label: entityInfo?.entity_name || '',
    });
  }, [entityInfo]);

  const handleEntityChange = (event: SingleValue<EntityOption>) => {
    const value = event?.value;
    setSelectedOption(event);
    setActiveEntity?.(
      entities?.find((entity) => entity.entity_id === value) || null,
    );
  };

  return (
    <Box w="100%" data-testid="detection-drawer-entity-panel">
      <AccordionPanel
        w="100%"
        layerStyle="first"
        reduceMotion={true}
        data-testid="entity-panel-heading"
        storageKey="detection-entity-panel"
        title={
          <HStack alignItems="center">
            <Box>{entity}</Box>
            <Box h="20px">
              <Popover
                isLazy
                width="320px"
                trigger="hover"
                placement="right"
                title={entityEDR.entityEnrichment}
                body={entityEDR.getEntityEnrichment}
                footer={
                  <Button
                    size="md"
                    variant="solid"
                    colorScheme="blue"
                    rightIcon={<MuiIcon>arrow_forward</MuiIcon>}
                    onClick={() => {
                      navigate({
                        pathname: systemSettingsIntegrationsConfig,
                      });
                    }}
                  >
                    {entityEDR.integrationPage}
                  </Button>
                }
              >
                <MuiIcon color="text.secondary">info</MuiIcon>
              </Popover>
            </Box>
          </HStack>
        }
        expanded={true}
      >
        <VStack spacing={2.5} alignItems="start">
          {entities?.length && entities?.length > 0 && setActiveEntity && (
            <Flex alignItems="center" w="100%">
              <Box w="120px" mr={2}>
                <Text
                  color="text.300"
                  data-testid="entity-panel-entity-type-label"
                  textStyle="body-md"
                >
                  {entity}
                </Text>
              </Box>
              <Box flexGrow={1}>
                {entities.length === 1 ? (
                  <Text>{entities[0].entity_name}</Text>
                ) : (
                  <Select
                    size="sm"
                    label="Entity"
                    name={'entity'}
                    isMulti={false}
                    isLabelHidden={true}
                    options={entityOptions}
                    data-testid="detection-entity"
                    onChange={(event) => {
                      handleEntityChange(event);
                    }}
                    value={selectedOption}
                  />
                )}
              </Box>
            </Flex>
          )}
          <Flex alignItems="center" w="100%">
            <Box w="120px" mr={2}>
              <Text
                color="text.300"
                data-testid="entity-panel-entity-type-label"
                textStyle="body-md"
              >
                {entityType}
              </Text>
            </Box>
            <Box flexGrow={1}>
              <Text
                data-testid="entity-panel-entity-type-value"
                textStyle="body-md"
              >
                {entity_type}
              </Text>
            </Box>
          </Flex>
        </VStack>
      </AccordionPanel>
    </Box>
  );
};
