import { createIcon } from '@chakra-ui/react';

export const RefreshFailedIcon = createIcon({
  displayName: 'RefreshFailedIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <circle cx="18" cy="18" r="5" fill="#fff" />
      <path
        d="M18 13c-2.756 0-5 2.244-5 5s2.244 5 5 5 5-2.244 5-5-2.244-5-5-5Zm2.778 6.994-.784.784L18 18.783l-1.994 1.995-.784-.784L17.217 18l-1.995-1.994.784-.784L18 17.217l1.994-1.995.784.784L18.783 18l1.995 1.994Z"
        fill="#F56565"
      />
      <path
        d="M7.757 7.757A6 6 0 0 1 12 6v3l4-4-4-4v3a8 8 0 0 0-8 8c0 1.57.46 3.03 1.24 4.26L6.7 14.8A5.9 5.9 0 0 1 6 12a6 6 0 0 1 1.757-4.243ZM12 23v-3c.114 0 .227-.002.34-.007A5.971 5.971 0 0 1 12 18v-3l-4 4 4 4ZM18 12c.698 0 1.369.12 1.993.34a7.93 7.93 0 0 0-1.233-4.6L17.3 9.2c.45.83.7 1.8.7 2.8Z"
        fill="currentColor"
      />
    </>
  ),
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
