import { gql, MutationHookOptions, OperationVariables, useMutation } from '@apollo/client';

export interface EditPrivilegeVariables extends OperationVariables {
  input: {
    chatgpt?: boolean;
    detection_auto_close_duration?: number;
  };
}

export const UPDATE_PRIVILEGES = gql`
  mutation updatePrivileges($input: EditPrivilegeInput!) {
    updatePrivileges(input: $input) {
      body {
        data
        message
        success
      }
      status_code
    }
  }
`;

export const useUpdatePrivileges = (
  options: MutationHookOptions<any, EditPrivilegeVariables>,
) => useMutation<any, EditPrivilegeVariables>(UPDATE_PRIVILEGES, options);
