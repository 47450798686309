import { useDisclosure } from '@chakra-ui/react';
import { SnippetBox } from '@gamma/display';
import { i18n } from '@gamma/investigator/localization';
import { Modal } from '@gamma/overlay';
import { ReactNode, useState } from 'react';

const suricataModal = i18n.pages.alertDetails.suricata.modal;
interface SuricataRuleEditorModalProps {
  value: string;
  children: ReactNode;
}

export const SuricataRuleEditorModal = ({
  value,
  children,
}: SuricataRuleEditorModalProps) => {
  const [content, setContent] = useState(value);
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Modal
      title={suricataModal.title}
      data-testid="code-editor-modal"
      size={'xl'}
      isCentered={true}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      body={<SnippetBox snippet={content} isCopyable={true} />}
    >
      {children}
    </Modal>
  );
};
