import {
  Box,
  Flex,
  Stack,
  StylesProvider,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import {
  DragDropContext,
  DropResult,
  Droppable,
  DroppableProvided,
} from '@hello-pangea/dnd';
import { isUndefined } from 'lodash';
import {
  ComponentType,
  MouseEvent,
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import { Row, SortingRule, TableInstance } from 'react-table';

import {
  DataTableColumnHeaders,
  DataTablePageSize,
  DataTablePagination,
  DataTableRowProps,
  DraggableDTRow,
  GlobalFilter,
  GlobalFilterProps,
  MemodDraggableDTRow,
} from './components';
import {
  UseDataTableParams,
  useAdjustColumnIndex,
  useDataTable,
  useDataTableContextSetter,
} from './hooks';

export interface DataTableProps<DataType extends {}>
  extends UseDataTableParams<DataType> {
  /**
   * Flag whether the table rows should be memod
   * @default true
   */
  isMemod?: boolean;
  /** Flag whether the table rows should be divided by lines */
  isLined?: boolean;
  /**
   * Flag whether the table rows are drag and droppable or not
   * @default false
   */
  UNSAFE_isDraggable?: boolean;
  /** Content to place between the table and pagination controls */
  footer?: ReactNode;
  /** The component type to wrap each table row with */
  rowWrapper?: ComponentType<DataType & { children: ReactNode }>;
  /** The variant of table to render */
  variant?: 'light' | 'lighter';
  layerStyle?: string;
  /** Toggles visibility of column headers */
  hideColumnHeaders?: boolean;
  /** Toggles ellipsis text overflow behavior in cells, defaults to true */
  truncateCells?: boolean;
  /** The function triggered whenever a row is clicked */
  onRowClick?: (row: Row<DataType>, event: MouseEvent<HTMLDivElement>) => void;
  /** The function triggered whenever a column is sorted */
  onColumnSort?: (columnSort: SortingRule<DataType>) => void;
  /** The function triggered when a row is dropped after being dragged */
  onDragEnd?: (result: DropResult) => void;
  /** The function triggered when the page size is changed */
  onPageSizeChange?: (pageSize: DataTablePageSize) => void;
  /** The function triggered when the page index is changed */
  onPageIndexChange?: (pageIndex: number) => void;
  /** The content to display when there is no data in the table */
  emptyBodyContent?: ReactNode;
  /** Adds built in global search input */
  isSearchable?: boolean;
  /** Condenses table view*/
  isCondensed?: boolean;
  /** Search input props */
  searchInputProps?: Partial<GlobalFilterProps>;
  /** Used to manually pass in the globalFilterValue */
  globalFilterValue?: any;
  /** Used to manually pass in the other filters, e.g. column filters */
  filters?: Array<{ id: string; value: any }>;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  hideBorder?: boolean;
  itemCount?: number;
  'data-testid'?: string;
  /** prevents loading hiddenColumns from local storage if this is false */
  isColumnVisibilityTogglable?: boolean;
  /** Sets the rowActions on (the right side of) a data table row */
  RowActions?: ComponentType<DataType>;
  /** Sets the rowActions on (the right side of) a data table row when expanded */
  ExpandedRowActions?: ComponentType<DataType>;
  /**
   * DataTablePanel wraps it's DataTable in a context provider in order to
   * bubble up state from the tableInstance. However, there are cases where a
   * DataTablePanel wraps a DataTable that contains another DataTable, which
   * would cause the innermost table's context setting actions to incorrectly
   * set state on the outermost DataTablePanel's context, because the inner
   * DataTable does not have a context provider to catch the action.
   * NOTE: Unless you've got a specific use case where you're building a
   * DataTable wrapper that uses the same context shape, you'd never want to set
   * this manually, and only DataTablePanel will use this prop to control its
   * DataTable
   */
  hasContextProvider?: boolean;
  /**
   * An array of strings to scope a memoization check to. If !empty the row
   * memoizer will only check for changes made to these accessors.
   */
  memoProps?: Array<string>;
}

export interface IDataTableRefInstance<
  DataType extends Record<string, unknown>,
> {
  table?: TableInstance<DataType>;
  selectedRows: Row<DataType>[];
}

const _DataTable = <DataType extends {}>(
  //#region props
  {
    columns,
    defaultColumn,
    data,
    pageCount: manualPageCount,
    pageSize: manualPageSize,
    isMemod = true,
    isLined,
    isSelectable,
    isExpandable = false,
    isSearchable,
    isPaginable = true,
    isSortable = true,
    isPaginationManual,
    isSortingManual,
    isFilterManual,
    isSortCaseSensitive,
    isCondensed = false,
    UNSAFE_isDraggable = false,
    autoResetRowSelection = true,
    autoResetPage = true,
    autoResetSortBy = true,
    autoResetGlobalFilter = false,
    autoResetFilters = true,
    autoResetExpanded = false,
    rowWrapper: RowWrapper = ({ children }) => <>{children}</>,
    footer,
    variant,
    layerStyle = 'first',
    initialState,
    search,
    hideColumnHeaders = false,
    truncateCells = true,
    rowIsSelectable,
    rowIsExpandable,
    onRowSelection,
    onRowClick,
    onColumnSort,
    onDragEnd = () => null,
    onSearchFilter,
    onPageSizeChange,
    onPageIndexChange,
    onPageChange,
    onFetchData,
    onColumnFilter,
    renderExpandedRow,
    emptyBodyContent,
    'data-testid': dataTestId = 'gamma-data-table',
    globalFilter,
    globalFilterValue: propsGlobalFilterValue,
    filters,
    searchInputProps,
    storageKey,
    size,
    itemCount,
    isColumnVisibilityTogglable,
    RowActions,
    ExpandedRowActions,
    hasContextProvider = false,
    memoProps,
  }: DataTableProps<DataType>,
  ref:
    | ((instance: TableInstance<DataType>) => void)
    | React.RefObject<TableInstance<DataType>>
    | null
    | undefined,
  //#endregion
) => {
  const tableInstance = useDataTable({
    columns,
    defaultColumn,
    data,
    pageCount: manualPageCount,
    pageSize: manualPageSize,
    isSelectable,
    isExpandable,
    isPaginable,
    isSortable,
    isPaginationManual,
    isSortingManual,
    isFilterManual,
    isSortCaseSensitive,
    autoResetRowSelection,
    autoResetPage,
    autoResetSortBy,
    autoResetExpanded,
    autoResetGlobalFilter,
    autoResetFilters,
    initialState,
    search,
    rowIsSelectable,
    rowIsExpandable,
    onRowSelection,
    onSearchFilter,
    onFetchData,
    onColumnFilter,
    onPageChange,
    renderExpandedRow,
    globalFilter,
    storageKey,
    isColumnVisibilityTogglable,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    rows,
    state: {
      pageIndex,
      pageSize,
      globalFilter: globalFilterValue,
      columnResizing,
      hiddenColumns,
    },
    setGlobalFilter,
  } = tableInstance;

  /**
   * gives parent access to table methods
   *
   * is NOT for getting the table state as refs don't trigger rerenders.
   * (ie: `ref.current.selectedFlatRows` won't update until you manually rerender)
   *
   * technically we should be trying to stay away from imperative ref handlers
   * if we can, but I can't think of a better way to do access the table methods
   * from the DT's parent 🤷‍♀️
   */
  useImperativeHandle(ref, () => tableInstance);

  const styles = useMultiStyleConfig('DataTable', {
    variant,
    isLined,
    isCondensed,
    isDraggable: UNSAFE_isDraggable,
    isRowClickable: onRowClick !== undefined,
    layerStyle,
    size,
  });

  const { getOriginalColumn } = useAdjustColumnIndex({
    isSelectable,
    isExpandable: isExpandable && renderExpandedRow !== undefined,
    columns,
  });

  useEffect(() => {
    if (
      !isUndefined(propsGlobalFilterValue) &&
      globalFilterValue !== propsGlobalFilterValue
    ) {
      setGlobalFilter(propsGlobalFilterValue);
    }
  }, [globalFilterValue, propsGlobalFilterValue]);

  useEffect(() => {
    filters && tableInstance.current?.setAllFilters(filters);
  }, [filters]);

  const tableProps = useMemo(() => {
    const { style, ...props } = getTableProps();
    //@ts-ignore minWidth is very much a style prop, thank you TS
    const { minWidth, ...styles } = style;
    return {
      ...props,
      style: styles,
    };
  }, [getTableProps]);

  useDataTableContextSetter<DataType>(tableInstance, {
    isEnabled: hasContextProvider,
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StylesProvider value={styles}>
        <Flex
          {...tableProps}
          __css={{ ...styles.body }}
          overflow={UNSAFE_isDraggable ? 'hidden' : undefined}
          data-testid={dataTestId}
        >
          {isSearchable && (
            <Flex px={4} py={3}>
              <Box w="full" maxW={360}>
                <GlobalFilter
                  filterValue={globalFilterValue}
                  setFilter={setGlobalFilter}
                  isLabelHidden
                  {...searchInputProps}
                />
              </Box>
            </Flex>
          )}
          {!hideColumnHeaders && (
            <DataTableColumnHeaders
              headerGroups={headerGroups}
              onColumnSort={onColumnSort}
              getOriginalColumn={getOriginalColumn}
              data-testid={dataTestId}
            />
          )}
          {data.length === 0 ? (
            <Box px={6} py={4}>
              {emptyBodyContent}
            </Box>
          ) : (
            <Droppable droppableId="droppable">
              {(provided: DroppableProvided) => (
                <Flex
                  {...getTableBodyProps()}
                  flexDir="column"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  data-testid={`${dataTestId}-body`}
                >
                  {page.map((row, index) => {
                    prepareRow(row);
                    const rowProps: DataTableRowProps<DataType> = {
                      /**
                       * have to pass styles to the row because the style context
                       * can break if RowWrapper has it's own style provider,
                       * like Popover does
                       */
                      index,
                      row,
                      getOriginalColumn,
                      truncateCells,
                      onClick: onRowClick,
                      rowIsExpandable,
                      RenderExpandedRow: renderExpandedRow,
                      UNSAFE_isDraggable,
                      hiddenColumns,
                      Wrapper: RowWrapper,
                    };
                    return !isMemod ? (
                      <DraggableDTRow
                        {...rowProps}
                        columnResizing={columnResizing}
                        isExpanded={row.isExpanded}
                        isSelected={row.isSelected}
                        values={row.values}
                        key={row.id}
                        RowActions={RowActions}
                        ExpandedRowActions={ExpandedRowActions}
                        size={size}
                      />
                    ) : (
                      <MemodDraggableDTRow
                        {...rowProps}
                        columnResizing={columnResizing}
                        isExpanded={row.isExpanded}
                        isSelected={row.isSelected}
                        values={row.values}
                        key={row.id}
                        RowActions={RowActions}
                        ExpandedRowActions={ExpandedRowActions}
                        size={size}
                        memoProps={memoProps}
                      />
                    );
                  })}
                  {provided.placeholder}
                </Flex>
              )}
            </Droppable>
          )}
        </Flex>
        {footer && (
          <Stack __css={styles.footer} data-testid={`${dataTestId}-footer`}>
            <Flex>{footer}</Flex>
          </Stack>
        )}
        {isPaginable && (
          <DataTablePagination
            pageSize={pageSize}
            pageIndex={pageIndex}
            pageCount={pageCount}
            itemCount={itemCount ?? rows.length}
            setPageSize={setPageSize}
            onPageSizeChange={onPageSizeChange}
            gotoPage={gotoPage}
            onPageIndexChange={onPageIndexChange}
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            canNextPage={canNextPage}
            nextPage={nextPage}
          />
        )}
      </StylesProvider>
    </DragDropContext>
  );
};

export const DataTable = forwardRef(_DataTable) as <DataType extends {}>(
  props: DataTableProps<DataType> & {
    ref?:
      | ((instance: TableInstance<DataType>) => void)
      | React.RefObject<TableInstance<DataType>>
      | null
      | undefined;
  },
) => ReturnType<typeof _DataTable>;
