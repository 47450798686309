import { Box, BoxProps, chakra } from '@chakra-ui/react';
import { CopyIconButton } from '@gamma/buttons';
import { ReactNode } from 'react';
import { dedent } from 'ts-dedent';

export interface SnippetBoxProps extends BoxProps {
  snippet: string;
  isCopyable?: boolean;
  copyTooltip?: string;
  toastOnCopy?: boolean;
  'data-testid'?: string;
  beforeSnippet?: ReactNode;
}

export const SnippetBox = ({
  snippet,
  isCopyable = true,
  toastOnCopy,
  copyTooltip,
  'data-testid': dataTestId = 'snippet-box',
  beforeSnippet,
  whiteSpace = 'pre-wrap',
  ...rest
}: SnippetBoxProps) => {
  return (
    <Box
      px={6}
      py={4}
      w="100%"
      bg="layer.0"
      fontFamily="mono"
      position="relative"
      borderRadius="base"
      data-testid={dataTestId}
      {...rest}
    >
      {isCopyable && (
        <Box top={2} right={2} position="absolute">
          <CopyIconButton
            variant="solid"
            colorScheme="gray"
            value={dedent(snippet)}
            tooltip={copyTooltip}
            toastOnCopy={toastOnCopy}
            data-testid={`${dataTestId}-copy-button`}
          />
        </Box>
      )}
      {beforeSnippet}
      <chakra.pre whiteSpace={whiteSpace}>{dedent(snippet)}</chakra.pre>
    </Box>
  );
};
