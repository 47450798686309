import { createIcon } from '@chakra-ui/react';

export const CaretDownIcon = createIcon({
  displayName: 'CaretDownIcon',
  d: 'm7 10 5 5 5-5H7Z',
  viewBox: '0 0 24 24',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
