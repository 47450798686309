import { useQuery } from '@apollo/client';
import { graphqlErrorRedirects } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { useLogOperationCall } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { GET_LICENSE, IGetLicenseInfo } from '@gamma/investigator/queries';
import { expirationDatesLicenseCompare } from '@gamma/investigator/utilities';
import { AccordionPanel, Board, Panel } from '@gamma/layout';
import { ErrorBoundary } from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';
import { useContext, useMemo } from 'react';

import { LicenseInformation } from './Components/LicenseInformation/LicenseInformation';
import { LicenseOverview } from './Components/LicenseOverview/LicenseOverview';

export const LicenseDashboard = () => {
  const { setUserLicense } = useContext(AuthContext);
  const logOperationCall = useLogOperationCall();
  const { loading, error, data } = useQuery<IGetLicenseInfo>(GET_LICENSE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.getLicenseInfo) {
        setUserLicense?.(data.getLicenseInfo);
      }
      logOperationCall('LICENSE_VIEWED', 'ADMIN_USER_ACTIONS');
    },
  });

  const { title } = i18n.pages.licenseDashboard;
  const {
    end = 0,
    license_type = '',
    expired = false,
  } = data?.getLicenseInfo ?? {};

  const expirationMeta = useMemo(
    () => expirationDatesLicenseCompare(end, license_type, expired),
    [end, license_type, expired],
  );

  if (loading || error) {
    return (
      <Panel>
        <GraphQLReqStatus
          loading={loading}
          error={error}
          extended={graphqlErrorRedirects}
        />
      </Panel>
    );
  }

  return (
    <ErrorBoundary page="View License" styleClass="main">
      <AccordionPanel
        title={title}
        reduceMotion={true}
        storageKey="license-information-panel"
        data-testid="license-information-page"
      >
        <Board>
          <LicenseOverview expirationMeta={expirationMeta} data={data} />
          <LicenseInformation data={data} />
        </Board>
      </AccordionPanel>
    </ErrorBoundary>
  );
};
