import { createIcon } from '@chakra-ui/react';

export const CornerSearchIcon = createIcon({
  displayName: 'CornerSearchIcon',
  viewBox: '0 0 24 24',
  d: 'M17 22v-2h3v-3h2v3.5c0 .39-.16.74-.46 1.04-.3.3-.65.46-1.04.46H17ZM7 22H3.5c-.39 0-.74-.16-1.04-.46-.3-.3-.46-.65-.46-1.04V17h2v3h3v2ZM17 2h3.5c.39 0 .74.16 1.04.46.3.3.46.65.46 1.04V7h-2V4h-3V2ZM7 2v2H4v3H2V3.5c0-.39.16-.74.46-1.04.3-.3.65-.46 1.04-.46H7Zm3.5 4C13 6 15 8 15 10.5c0 .88-.25 1.7-.69 2.4l3.26 3.26-1.41 1.41-3.26-3.26c-.7.44-1.52.69-2.4.69C8 15 6 13 6 10.5S8 6 10.5 6Zm0 2a2.5 2.5 0 0 0 0 5 2.5 2.5 0 0 0 0-5Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
