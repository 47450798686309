import { createIcon } from '@chakra-ui/react';

export const LockedIdentity = createIcon({
  displayName: 'LockedIdentity',
  viewBox: '0 0 64 52',
  path: (
    <>
      <path
        d="M59.902 43.01a.33.33 0 0 1-.184-.398.156.156 0 0 0-.02-.137.166.166 0 0 0-.27-.01.159.159 0 0 0-.018.033.33.33 0 0 1-.398.184.156.156 0 0 0-.137.02.166.166 0 0 0-.01.27c.01.007.021.013.033.018a.33.33 0 0 1 .184.398.165.165 0 0 0 .204.205.165.165 0 0 0 .104-.091.33.33 0 0 1 .398-.184.164.164 0 0 0 .205-.204.165.165 0 0 0-.091-.104ZM29.902 4.216a.33.33 0 0 1-.184-.402.16.16 0 0 0-.02-.138.166.166 0 0 0-.184-.07.165.165 0 0 0-.104.093.331.331 0 0 1-.398.185.156.156 0 0 0-.137.02.167.167 0 0 0 .023.291.33.33 0 0 1 .184.402.167.167 0 0 0 .1.203.164.164 0 0 0 .208-.088.331.331 0 0 1 .398-.186.156.156 0 0 0 .137-.02.167.167 0 0 0 .068-.186.167.167 0 0 0-.091-.104Z"
        fill="#128EE3"
      />
      <path
        d="M56.2 15.61c.331 0 .6-.27.6-.605a.603.603 0 0 0-.6-.605c-.331 0-.6.271-.6.605 0 .335.269.606.6.606ZM28.6 43.21c.331 0 .6-.27.6-.605a.603.603 0 0 0-.6-.605c-.331 0-.6.271-.6.605 0 .335.269.606.6.606ZM54.2 35.61c.331 0 .6-.27.6-.605a.603.603 0 0 0-.6-.605c-.331 0-.6.271-.6.605 0 .335.269.606.6.606Z"
        fill="#F56565"
      />
      <path
        d="M49.502 41.816a.33.33 0 0 1-.184-.402.168.168 0 0 0-.1-.203.164.164 0 0 0-.208.088.331.331 0 0 1-.398.185.164.164 0 0 0-.202.102.168.168 0 0 0 .056.19.16.16 0 0 0 .032.02.33.33 0 0 1 .184.401.16.16 0 0 0 .02.138.166.166 0 0 0 .184.069.165.165 0 0 0 .104-.092.331.331 0 0 1 .398-.186.156.156 0 0 0 .137-.02.167.167 0 0 0-.023-.29ZM3.102 9.016a.33.33 0 0 1-.184-.402.161.161 0 0 0-.02-.138.166.166 0 0 0-.184-.07.165.165 0 0 0-.104.093.331.331 0 0 1-.398.185.157.157 0 0 0-.137.02.166.166 0 0 0-.068.187.167.167 0 0 0 .091.104.331.331 0 0 1 .184.402.16.16 0 0 0 .02.138.165.165 0 0 0 .184.069.165.165 0 0 0 .104-.092.331.331 0 0 1 .398-.186.157.157 0 0 0 .137-.02.167.167 0 0 0 .068-.186.167.167 0 0 0-.091-.104Z"
        fill="#128EE3"
      />
      <path
        d="M4.6 19.61c.331 0 .6-.27.6-.605a.603.603 0 0 0-.6-.605c-.331 0-.6.271-.6.605 0 .335.269.606.6.606ZM61.8 26.01c.332 0 .6-.27.6-.605a.603.603 0 0 0-.6-.605c-.331 0-.6.271-.6.605 0 .335.269.606.6.606ZM46.306.8h-.212v1.211h.212V.801Z"
        fill="#35FE94"
      />
      <path
        d="M46.8 1.512V1.3h-1.2v.213h1.2ZM42.706 43.6h-.212v1.21h.212V43.6Z"
        fill="#35FE94"
      />
      <path
        d="M43.2 44.312V44.1H42v.213h1.2ZM50.73 26.408h-.212v1.21h.211v-1.21Z"
        fill="#35FE94"
      />
      <path
        d="M51.224 27.12v-.214h-1.2v.214h1.2ZM56.15 2.824l.36.333.51-.757.18.151-.69.908-.51-.575.15-.06ZM6.95 44.024l.36.333.51-.757.18.151-.69.909-.51-.576.15-.06Z"
        fill="#35FE94"
      />
      <path
        d="M46.94 6.653 6.214 12.536 9.537 35.55l40.728-5.884L46.94 6.653Z"
        fill="#EFF0F0"
      />
      <path
        d="M45.839 9.227 7.885 14.709l2.773 19.2 37.955-5.482-2.774-19.2Z"
        fill="#273038"
      />
      <path
        d="M46.755 5.323 6 11.21l.355 2.456L47.11 7.78l-.355-2.456Z"
        fill="#2A4365"
      />
      <path
        d="M7.706 12.586a.386.386 0 1 0 0-.772.386.386 0 0 0 0 .772ZM9.155 12.376a.386.386 0 1 0 0-.771.386.386 0 0 0 0 .771ZM10.605 12.167a.386.386 0 1 0 0-.771.386.386 0 0 0 0 .771Z"
        fill="#FDFDFE"
      />
      <path d="M58 15.6H16.8v23.2H58V15.6Z" fill="#EFF0F0" />
      <path d="M56.6 17.826H18.25v19.4h38.348v-19.4Z" fill="#273038" />
      <rect
        x="11.506"
        y="26.88"
        width="6.649"
        height="8.121"
        rx="2.4"
        stroke="#37DB80"
        strokeWidth="1.2"
      />
      <path d="M58 14H16.8v2.4H58V14Z" fill="#2A4365" />
      <path
        d="M18.376 15.699a.386.386 0 1 0 0-.772.386.386 0 0 0 0 .772ZM19.84 15.699a.386.386 0 1 0 0-.772.386.386 0 0 0 0 .772ZM21.305 15.699a.386.386 0 1 0 0-.772.386.386 0 0 0 0 .772Z"
        fill="#FDFDFE"
      />
      <g opacity=".4" fill="#D5D6D8">
        <path d="M47.453 20.376h-6.507v.489h6.507v-.49ZM50.994 21.811H40.946v.49h10.048v-.49ZM49.24 23.179h-8.19v.489h8.19v-.49ZM45.727 24.679h-4.78v.489h4.78v-.49ZM48.16 26.144h-7.214v.489h7.215v-.489ZM38.778 20.222h-.797v.797h.797v-.797ZM38.778 21.657h-.797v.797h.797v-.797ZM38.778 23.025h-.797v.797h.797v-.797ZM38.778 24.525h-.797v.797h.797v-.797ZM38.778 25.99h-.797v.797h.797v-.797ZM47.453 27.619h-6.507v.489h6.507v-.49ZM50.994 29.055H40.946v.489h10.048v-.49ZM49.24 30.421h-8.19v.49h8.19v-.49ZM45.727 31.922h-4.78v.489h4.78v-.49ZM48.16 33.387h-7.214v.489h7.215v-.49ZM38.778 27.465h-.797v.796h.797v-.796ZM38.778 28.9h-.797v.797h.797V28.9ZM38.778 30.268h-.797v.796h.797v-.796ZM38.778 31.768h-.797v.796h.797v-.796ZM38.778 33.233h-.797v.797h.797v-.797Z" />
      </g>
      <path
        opacity=".4"
        d="M30.795 31.455h-6.506v.489h6.506v-.49ZM31.606 32.725h-8.188v.49h8.188v-.49Z"
        fill="#F8F8F9"
      />
      <path
        opacity=".2"
        d="M31.77 25.286a4.249 4.249 0 0 1-6.262 3.74h-.001a4.248 4.248 0 1 1 6.263-3.74Z"
        fill="#F8F8F9"
      />
      <path
        d="M27.544 25.08a1.155 1.155 0 1 0 0-2.31 1.155 1.155 0 0 0 0 2.31ZM29.515 27.466a5.953 5.953 0 0 1-3.986-.016l.86-1.699h2.31l.816 1.715Z"
        fill="#FDFDFE"
      />
      <path
        d="M18.365 42.959h-7.07a2.366 2.366 0 0 1-1.662-.683 2.32 2.32 0 0 1-.69-1.647v-6.868c0-.423.17-.828.472-1.127a1.62 1.62 0 0 1 1.138-.468h8.554c.427 0 .836.169 1.138.468.302.299.471.704.472 1.127v6.868a2.32 2.32 0 0 1-.69 1.647 2.366 2.366 0 0 1-1.662.683Z"
        fill="#35FE94"
      />
      <path
        d="M16.302 36.092a1.47 1.47 0 1 0-2.14 1.31v2.302h1.337v-2.302a1.47 1.47 0 0 0 .803-1.31Z"
        fill="#222930"
      />
      <path
        d="M15.4 4.01c.331 0 .6-.27.6-.605a.603.603 0 0 0-.6-.605c-.332 0-.6.271-.6.605 0 .335.268.606.6.606Z"
        fill="#F56565"
      />
      <path
        d="M3.902 31.016a.33.33 0 0 1-.184-.402.16.16 0 0 0-.02-.138.166.166 0 0 0-.184-.07.164.164 0 0 0-.104.093.331.331 0 0 1-.398.185.157.157 0 0 0-.137.02.167.167 0 0 0 .023.291.33.33 0 0 1 .184.402.162.162 0 0 0 .02.138.166.166 0 0 0 .184.069.165.165 0 0 0 .104-.092.33.33 0 0 1 .398-.186.157.157 0 0 0 .137-.02.167.167 0 0 0 .068-.186.166.166 0 0 0-.091-.104Z"
        fill="#128EE3"
      />
    </>
  ),
  defaultProps: {
    height: '52px',
    width: '64px',
  },
});
