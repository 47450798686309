import { createIcon } from '@chakra-ui/react';

export const CircleCheckIcon = createIcon({
  displayName: 'CircleCheckIcon',
  viewBox: '0 0 24 24',
  d: 'M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2Zm-2 15-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
