import { createIcon } from '@chakra-ui/react';

export const OTPCode = createIcon({
  displayName: 'OTPCode',
  viewBox: '0 0 160 128',
  path: (
    <>
      <path
        d="M129.754 102.525a.821.821 0 0 1-.459-.995.415.415 0 0 0-.251-.504.41.41 0 0 0-.519.22.821.821 0 0 1-.995.459.415.415 0 0 0-.504.251.41.41 0 0 0 .22.519.821.821 0 0 1 .459.995.415.415 0 0 0 .251.504.41.41 0 0 0 .519-.22.821.821 0 0 1 .995-.459.415.415 0 0 0 .504-.251.41.41 0 0 0-.22-.519ZM75.754 29.539a.838.838 0 0 1-.46-1.005.409.409 0 0 0-.25-.508.41.41 0 0 0-.519.222.827.827 0 0 1-.41.423.817.817 0 0 1-.585.04.39.39 0 0 0-.343.049.416.416 0 0 0-.17.466.4.4 0 0 0 .229.261.838.838 0 0 1 .46 1.005.409.409 0 0 0 .25.51.41.41 0 0 0 .519-.223.817.817 0 0 1 .996-.463.39.39 0 0 0 .342-.049.416.416 0 0 0 .17-.466.4.4 0 0 0-.229-.261Z"
        fill="#4299E1"
      />
      <path
        d="M140.5 39.027c.828 0 1.5-.678 1.5-1.514 0-.835-.672-1.513-1.5-1.513s-1.5.678-1.5 1.514c0 .835.672 1.513 1.5 1.513ZM74.5 95.027c.828 0 1.5-.678 1.5-1.514 0-.835-.672-1.513-1.5-1.513s-1.5.678-1.5 1.513c0 .836.672 1.514 1.5 1.514ZM142.5 88.027c.828 0 1.5-.678 1.5-1.514 0-.835-.672-1.513-1.5-1.513s-1.5.678-1.5 1.513c0 .836.672 1.514 1.5 1.514Z"
        fill="#F56565"
      />
      <path
        d="M114.754 90.539a.83.83 0 0 1-.459-1.005.419.419 0 0 0-.251-.508.409.409 0 0 0-.519.222.823.823 0 0 1-.995.463.4.4 0 0 0-.343.049.419.419 0 0 0-.023.682.415.415 0 0 0 .082.046.83.83 0 0 1 .459 1.005.419.419 0 0 0 .251.509.409.409 0 0 0 .519-.223.823.823 0 0 1 .995-.463.4.4 0 0 0 .343-.049.418.418 0 0 0 .023-.682.42.42 0 0 0-.082-.046ZM54.755 103.539a.827.827 0 0 1-.42-.414.84.84 0 0 1-.04-.591.42.42 0 0 0-.252-.508.411.411 0 0 0-.518.222.822.822 0 0 1-.996.463.406.406 0 0 0-.342.049.418.418 0 0 0 .059.728.827.827 0 0 1 .419.414.84.84 0 0 1 .04.591.42.42 0 0 0 .252.508.411.411 0 0 0 .518-.222.817.817 0 0 1 .996-.463c.029.01.06.016.09.018a.413.413 0 0 0 .413-.272.419.419 0 0 0-.22-.523Z"
        fill="#4299E1"
      />
      <path
        d="M11.5 49.027c.828 0 1.5-.678 1.5-1.514 0-.835-.672-1.513-1.5-1.513s-1.5.678-1.5 1.514c0 .835.672 1.513 1.5 1.513ZM154.5 65.027c.828 0 1.5-.678 1.5-1.514 0-.835-.672-1.513-1.5-1.513s-1.5.678-1.5 1.514c0 .835.672 1.513 1.5 1.513ZM105.765 38h-.53v3.027h.53V38Z"
        fill="#35FE94"
      />
      <path
        d="M107 39.78v-.534h-3v.535h3ZM95.765 94h-.53v3.027h.53V94Z"
        fill="#35FE94"
      />
      <path
        d="M97 95.78v-.534h-3v.535h3ZM126.823 66.019h-.529v3.026h.529V66.02Z"
        fill="#35FE94"
      />
      <path
        d="M128.059 67.8v-.535h-3v.534h3ZM123.375 28.06l.9.832L125.55 27l.45.378-1.725 2.27L123 28.212l.375-.151ZM34.375 90.06l.9.832L36.55 89l.45.378-1.725 2.27L34 90.212l.375-.151Z"
        fill="#35FE94"
      />
      <path
        d="M37.5 31.027c.828 0 1.5-.678 1.5-1.514 0-.835-.672-1.513-1.5-1.513s-1.5.678-1.5 1.514c0 .835.672 1.513 1.5 1.513Z"
        fill="#F56565"
      />
      <path
        d="M9.754 77.539a.838.838 0 0 1-.46-1.005.404.404 0 0 0-.047-.345.414.414 0 0 0-.462-.172.413.413 0 0 0-.26.23.828.828 0 0 1-.41.424.816.816 0 0 1-.585.04.39.39 0 0 0-.343.049.416.416 0 0 0-.17.466.417.417 0 0 0 .229.262.838.838 0 0 1 .46 1.005.402.402 0 0 0 .047.345.414.414 0 0 0 .463.172.412.412 0 0 0 .259-.23.817.817 0 0 1 .996-.463.395.395 0 0 0 .342-.05.417.417 0 0 0 .17-.466.417.417 0 0 0-.229-.262Z"
        fill="#4299E1"
      />
      <path
        d="M130.442 49H29.558a3.527 3.527 0 0 0-2.514 1.065A3.675 3.675 0 0 0 26 52.632v23.736a3.675 3.675 0 0 0 1.044 2.567A3.527 3.527 0 0 0 29.558 80h100.884a3.526 3.526 0 0 0 2.514-1.065A3.674 3.674 0 0 0 134 76.368V52.632a3.674 3.674 0 0 0-1.044-2.567A3.526 3.526 0 0 0 130.442 49Z"
        fill="#273038"
      />
      <path
        d="M130.442 49H29.558a3.527 3.527 0 0 0-2.514 1.065A3.675 3.675 0 0 0 26 52.632v23.736a3.675 3.675 0 0 0 1.044 2.567A3.527 3.527 0 0 0 29.558 80h100.884a3.526 3.526 0 0 0 2.514-1.065A3.674 3.674 0 0 0 134 76.368V52.632a3.674 3.674 0 0 0-1.044-2.567A3.526 3.526 0 0 0 130.442 49Zm3.139 27.368a3.244 3.244 0 0 1-.92 2.265c-.589.6-1.387.939-2.219.94H29.558a3.111 3.111 0 0 1-2.219-.94 3.242 3.242 0 0 1-.92-2.265V52.632c0-.85.332-1.664.92-2.265a3.111 3.111 0 0 1 2.22-.94h100.883c.832.001 1.63.34 2.219.94.588.6.919 1.415.92 2.265v23.736Z"
        fill="#646A70"
      />
      <rect x="38" y="60" width="9" height="9" rx="4.5" fill="#35FE94" />
      <rect x="87" y="60" width="9" height="9" rx="4.5" fill="#35FE94" />
      <rect x="51" y="60" width="9" height="9" rx="4.5" fill="#35FE94" />
      <rect x="100" y="60" width="9" height="9" rx="4.5" fill="#35FE94" />
      <rect x="64" y="60" width="9" height="9" rx="4.5" fill="#35FE94" />
      <rect x="113" y="60" width="9" height="9" rx="4.5" fill="#35FE94" />
    </>
  ),
  defaultProps: {
    height: '128px',
    width: '160px',
  },
});
