import './box-plot.local.scss';

import * as d3 from 'd3';
import * as PropTypes from 'prop-types';

import { getPostiveValue, GraphHelper, smartNumber } from '../helpers';
import { boxPlotTooltipFormat } from './constants';

export class BoxPlotChart extends GraphHelper {
  constructor(props) {
    super(props);

    this.xScale = d3.scaleLinear();
    this.getScaleDomain = this.getScaleDomain.bind(this);
    this.buildGraph = this.buildGraph.bind(this);
    this.buildTooltip = this.buildTooltip.bind(this);
    this.updateSvgDimensions = this.updateSvgDimensions.bind(this);
    this.updateGraphData = this.updateGraphData.bind(this);
    this.updateTooltipData = this.updateTooltipData.bind(this);
    this.updateTooltipPosition = this.updateTooltipPosition.bind(this);
  }

  getScaleDomain() {
    const { mean } = this?.props?.data;
    const actual = this?.props?.hideEntityValues
      ? this?.props?.data?.min
      : this?.props?.data?.actual;

    const min = Math.min(actual, this?.props?.data?.min);
    const max = Math.max(actual, this?.props?.data?.max);

    const maxDistance = Math.max(mean - min, max - mean);

    return [mean - maxDistance, mean + maxDistance];
  }

  buildGraph() {
    this.svg = d3.select(this.containerRef).select('svg');

    super.buildGraph();

    this.svg
      .on('mouseenter', this.updateTooltipPosition)
      .on('mouseleave', () => this.tooltip.attr('hidden', true));
  }

  buildTooltip() {
    super.buildTooltip();

    const { className } = this?.props;
    const body = this.tooltip.select('.d3-tooltip__body');

    this.tooltip
      .select('.d3-tooltip__body')
      .selectAll('div')
      .data(
        boxPlotTooltipFormat.filter(
          (row) => !(row.text === 'Actual' && this?.props?.hideEntityValues),
        ),
      )
      .enter()
      .append('div')
      .attr(
        'class',
        (d) =>
          `${className}-tooltip__row ${className}-tooltip__row--${d.className}`,
      )
      .each((data) => {
        const row = body.select(
          `.${className}-tooltip__row--${data.className}`,
        );

        row
          .append('div')
          .attr('class', `${className}-tooltip__label`)
          .text(data.text && `${data.text}:`);

        row.append('div').attr('class', `${className}-tooltip__value`);
      });
  }

  updateSvgDimensions() {
    super.updateSvgDimensions();

    this?.updateGraphData();
  }

  updateGraphData() {
    const { className, data } = this.props;
    const { height, width } = this.svgDimensions;
    const range = {
      min: 50,
      max: width - 50,
    };
    const domain = this.getScaleDomain();

    this.xScale.range([range.min, range.max]).domain(domain);

    const entityValuesGroup = this.svg.select(`.${className}__entity-values`);
    entityValuesGroup
      .select(`.${className}__actual`)
      .attr('x', 40)
      .attr('y', height / 2 - 16);

    entityValuesGroup
      .select(`.${className}__difference`)
      .attr('x', width - 5)
      .attr('y', height / 2 - 16);

    const rangeGroup = this.svg.select(`.${className}__range-group`);
    rangeGroup
      .select(`.${className}__min`)
      .attr('x', this.xScale(data.min))
      .attr('y', height / 2);

    rangeGroup
      .select(`.${className}__max`)
      .attr('x', this.xScale(data.max))
      .attr('y', height / 2);

    const barGroup = this.svg.select(`.${className}__bar-group`);
    barGroup
      .select(`.${className}__background-bar`)
      .attr('y', height / 2 - 4)
      .attr(
        'width',
        getPostiveValue(this.xScale(domain[1]) - this.xScale(domain[0])),
      )
      .attr('x', this.xScale(domain[0]));
    barGroup
      .select(`.${className}__range-bar`)
      .attr('y', height / 2 - 4)
      .attr(
        'width',
        getPostiveValue(this.xScale(data.max) - this.xScale(data.min)),
      )
      .attr('x', this.xScale(data.min));

    barGroup
      .select(`.${className}__quarter-bar`)
      .attr('y', height / 2 - 4)
      .attr('fill', data.color)
      .attr(
        'width',
        getPostiveValue(this.xScale(data.pct_75) - this.xScale(data.pct_25)),
      )
      .attr('x', this.xScale(data.pct_25));

    const meanGroup = this.svg.select(`.${className}__mean-group`);
    meanGroup
      .select(`.${className}__mean`)
      .attr('x1', this.xScale(data.mean))
      .attr('x2', this.xScale(data.mean))
      .attr('y2', height);

    const standardDeviationGroup = this.svg.select(
      `.${className}__standard-deviation-group`,
    );
    standardDeviationGroup
      .select(`.${className}__standard-deviation-min`)
      .attr('x1', this.xScale(data.mean - data.std))
      .attr('x2', this.xScale(data.mean - data.std))
      .attr('y1', height / 2 - 5)
      .attr('y2', height / 2 + 5);

    standardDeviationGroup
      .select(`.${className}__standard-deviation-connect`)
      .attr('x1', this.xScale(data.mean - data.std))
      .attr('x2', this.xScale(data.mean + data.std))
      .attr('y1', height / 2)
      .attr('y2', height / 2);

    standardDeviationGroup
      .select(`.${className}__standard-deviation-max`)
      .attr('x1', this.xScale(data.mean + data.std))
      .attr('x2', this.xScale(data.mean + data.std))
      .attr('y1', height / 2 - 5)
      .attr('y2', height / 2 + 5);

    if (!this?.props?.hideEntityValues) {
      const actualGroup = this.svg.select(`.${className}__actual-group`);
      actualGroup.attr(
        'transform',
        `translate(${this.xScale(data.actual)},${height / 2 - 4})`,
      );

      const differenceGroup = this.svg.select(
        `.${className}__difference-group`,
      );
      differenceGroup.attr(
        'transform',
        `translate(${width},${height / 2 - 4})`,
      );
    }

    this.updateTooltipData();
  }

  updateTooltipData() {
    const { className, data } = this.props;

    this.tooltip.attr('data-testid', `${this?.props?.id}-tooltip`);

    this.tooltip.select(`.${className}-tooltip__row--title`).text(data.display_name || data.name);

    this.tooltip
      .select(
        `.${className}-tooltip__row--quarterly .${className}-tooltip__value`,
      )
      .text(`${smartNumber(data.pct_25)} to ${smartNumber(data.pct_75)}`);

    this.tooltip
      .select(
        `.${className}-tooltip__row--percentile .${className}-tooltip__value`,
      )
      .text(`${smartNumber(data.pct_5)} to ${smartNumber(data.pct_95)}`);

    if (!this?.props?.hideEntityValues) {
      this.tooltip
        .select(
          `.${className}-tooltip__row--actual .${className}-tooltip__value`,
        )
        .text(smartNumber(data.actual));
    }

    this.tooltip
      .select(`.${className}-tooltip__row--mean .${className}-tooltip__value`)
      .text(smartNumber(data.mean));

    this.tooltip
      .select(
        `.${className}-tooltip__row--deviation .${className}-tooltip__value`,
      )
      .text(smartNumber(data.std));

    this.tooltip
      .select(`.${className}-tooltip__row--min .${className}-tooltip__value`)
      .text(smartNumber(data.min));

    this.tooltip
      .select(`.${className}-tooltip__row--max .${className}-tooltip__value`)
      .text(smartNumber(data.max));
  }

  updateTooltipPosition() {
    const position = this.containerRef.getBoundingClientRect();

    let tooltipPosition = `left: ${position.left + position.width / 2}px;`;

    if (this?.props?.tooltipPosition === 'top') {
      tooltipPosition += `top: ${position.top - 10}px`;
    } else {
      tooltipPosition += `top: ${position.top + position.height / 2}px`;
    }

    this.tooltip.attr('style', tooltipPosition).attr('hidden', null);
  }

  render() {
    const { className, data, hideEntityValues } = this.props;
    return (
      <div
        data-d3-chart
        id={this?.props?.id}
        data-testid={this?.props?.id}
        className={`${className} ${
          hideEntityValues ? `${className}--hide-entity-values` : ''
        }`}
        ref={this.setContainerRefHandler}
      >
        <svg>
          <g className={`${className}__bar-group`}>
            <rect
              className={`${className}__background-bar`}
              height={8}
              rx={4}
              y={-4}
            />
            <rect
              className={`${className}__range-bar`}
              height={8}
              rx={4}
              y={-4}
            />
            <rect
              className={`${className}__quarter-bar`}
              height={8}
              rx={4}
              y={-4}
            />
          </g>
          {!hideEntityValues && (
            <g className={`${className}__entity-values`}>
              <text className={`${className}__actual`} dy={20}>
                {smartNumber(data.actual, 1)}
              </text>
              <text
                className={`${className}__difference ${
                  Math.abs(data.difference) > 1
                    ? `${className}__difference--alert`
                    : ''
                }`}
                dy={20}
              >
                {smartNumber(data.difference)}
              </text>
            </g>
          )}
          <g className={`${className}__range-group`}>
            <text className={`${className}__min`} dy={20}>
              {smartNumber(data.min, 1)}
            </text>
            <text className={`${className}__max`} dy={20}>
              {smartNumber(data.max, 1)}
            </text>
          </g>
          <g className={`${className}__standard-deviation-group`}>
            <line className={`${className}__standard-deviation-min`} />
            <line className={`${className}__standard-deviation-connect`} />
            <line className={`${className}__standard-deviation-max`} />
          </g>
          <g className={`${className}__mean-group`}>
            <line className={`${className}__mean`} y1={0} />
          </g>
          {!hideEntityValues && (
            <g
              className={`${className}__actual-group ${
                Math.abs(data.difference) > 1
                  ? `${className}__actual-group--alert`
                  : ''
              }`}
            >
              <circle
                className={`${className}__actual-circle`}
                r={4}
                cx={0}
                cy={-7}
              />
              <line
                className={`${className}__actual-line`}
                y1={-3}
                y2={11}
                x1={0}
                x2={0}
              />
            </g>
          )}
        </svg>
      </div>
    );
  }
}

BoxPlotChart.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    std: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    mean: PropTypes.number.isRequired,
    pct_25: PropTypes.number.isRequired,
    pct_75: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    difference: PropTypes.number,
    actual: PropTypes.number,
  }).isRequired,
  id: PropTypes.string.isRequired,
  hideEntityValues: PropTypes.bool,
  className: PropTypes.string,
};

BoxPlotChart.defaultProps = {
  hideEntityValues: false,
  className: 'd3-box-plot',
};
