import {
  ButtonProps,
  ComponentWithAs,
  IconProps,
  useDisclosure,
} from '@chakra-ui/react';
import { MODAL_STEPS, ROUTES, URLS } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { ConfirmationModal } from '@gamma/overlay';
import { ReactNode, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AuthModalBody } from './AuthModalBody';
import { IHelperText, OnMFACompletedType } from './types';

interface AuthModalProps {
  children: ReactNode;
  title: string;
  subtitle?: string;
  helperTexts: IHelperText;
  allowModal?: boolean;
  loginIcon?: ComponentWithAs<'svg', IconProps>;
  showAlert?: boolean;
  errorMessage: string;
  width?: string;
  isExportNoMfa?: boolean;
  onMFACompleted: OnMFACompletedType;
  isSensorPage?: boolean;
}

export const AuthModal = ({
  allowModal = true,
  children,
  title,
  subtitle,
  onMFACompleted,
  helperTexts,
  loginIcon,
  showAlert,
  errorMessage,
  width,
  isExportNoMfa,
  isSensorPage,
}: AuthModalProps) => {
  const { gotoUserGuide } = i18n.pages.sensorMonitoring.modal;
  const [step, setStep] = useState<string>(
    isSensorPage === true ? MODAL_STEPS.warning : MODAL_STEPS.login,
  );
  const [isSignInButtonDisabled, setIsSignInButtonDisabled] =
    useState<boolean>(true);
  const [isViewInfoButtonDisabled, setIsViewInfoButtonDisabled] =
    useState<boolean>(true);
  const [onMFACompletedLoading, setOnMFACompletedLoading] =
    useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

  const { user } = useContext(AuthContext);

  const location = useLocation();

  const { isOpen, onClose, onOpen } = useDisclosure({
    onOpen: () => {
      setStep(isSensorPage === true ? MODAL_STEPS.warning : MODAL_STEPS.login);
      setIsSignInButtonDisabled(true);
      setIsViewInfoButtonDisabled(true);
    },
  });

  const mfaStepSettings = {
    confirmText: helperTexts.mfa.confirm,
    onConfirm: () =>
      onMFACompleted({ setStep, onClose, setOnMFACompletedLoading }),
  };
  const redirect = () => {
    window.open(URLS.INVESTIGATOR_DOCS, '_blank');
  };

  const warningStepSettings = {
    onConfirm: () => setStep(MODAL_STEPS.details),
    confirmText: helperTexts?.warning?.confirm,
  };

  const detailsStepSettings = {
    confirmText: i18n.buttons.close,
    cancelText: gotoUserGuide,
    isClosedOnCancel: false,
    onCancel: () => redirect(),
    onConfirm: () => onClose(),
    cancelProps: {
      variant: 'link',
      color: 'blue.400',
      size: 'sm',
      'data-href': URLS.INVESTIGATOR_DOCS,
    },
  };

  let confirmProperties: ButtonProps = {};
  const args = () => {
    switch (step) {
      case MODAL_STEPS.mfa:
        return {
          ...mfaStepSettings,
          confirmProps: {
            isLoading: onMFACompletedLoading,
            isDisabled: isViewInfoButtonDisabled,
          },
        };
      case MODAL_STEPS.warning:
        return {
          ...warningStepSettings,
        };
      case MODAL_STEPS.details:
        if (
          location.pathname !== ROUTES.systemSettingsIntegrationsAlertExports
        ) {
          return detailsStepSettings;
        }

        if (
          location.pathname === ROUTES.systemSettingsIntegrationsAlertExports
        ) {
          return mfaStepSettings;
        }

        break;
      case MODAL_STEPS.login:
        confirmProperties = {
          isLoading: isButtonLoading,
          form: 'verifyLoginForm',
          isDisabled: isSignInButtonDisabled,
          type: 'submit',
        };
        return {
          confirmProps: confirmProperties,
        };
      default:
        return {};
    }
  };

  return (
    <ConfirmationModal
      title={title}
      subtitle={subtitle}
      body={
        <AuthModalBody
          email={user?.attributes.email}
          step={step}
          helperTexts={helperTexts}
          setStep={setStep}
          isButtonLoading={isButtonLoading}
          setIsSignInButtonDisabled={setIsSignInButtonDisabled}
          setIsViewInfoButtonDisabled={setIsViewInfoButtonDisabled}
          setIsButtonLoading={setIsButtonLoading}
          loginIcon={loginIcon}
          showAlert={showAlert}
          errorMessage={errorMessage}
          width={width}
          isExportNoMfa={isExportNoMfa}
        />
      }
      isOpen={isOpen}
      isClosedOnConfirm={false}
      onClose={onClose}
      onOpen={onOpen}
      {...args()}
      size="md"
    >
      {children}
    </ConfirmationModal>
  );
};
