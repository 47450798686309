import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { IQueryAlertsPaginated, SuricataAlert } from './types';

export const QUERY_ALERTS_SURICATA_PAYLOAD = gql`
  query queryAlertsSuricataPaginated(
    $query: String!
    $offset: Int
    $size: Int
    $sort: [SortParameterInput]
  ) {
    queryAlertsPaginated(
      query: $query
      offset: $offset
      size: $size
      sort: $sort
    ) {
      alerts {
        ... on SuricataAlert {
          uid
          payload
          payload_summary
        }
      }
      offset
      size
      total_items
    }
  }
`;

export const QUERY_ALERTS_SURICATA_PAYLOAD_LLM_DISABLED = gql`
  query queryAlertsSuricataPaginated(
    $query: String!
    $offset: Int
    $size: Int
    $sort: [SortParameterInput]
  ) {
    queryAlertsPaginated(
      query: $query
      offset: $offset
      size: $size
      sort: $sort
    ) {
      alerts {
        ... on SuricataAlert {
          uid
          payload
        }
      }
      offset
      size
      total_items
    }
  }
`;
export const useQueryAlertsSuricataPayload = (
  options: QueryHookOptions<
    IQueryAlertsPaginated<SuricataAlert>,
    OperationVariables
  >,
) => {
  return useQuery<IQueryAlertsPaginated<SuricataAlert>>(
    QUERY_ALERTS_SURICATA_PAYLOAD,
    options,
  );
};
