import moment from 'moment-timezone';
import { useMemo } from 'react';

export interface TimestampCellProps {
  timestamp: number;
  isFromNow?: boolean;
  format?: string;
}

export const TimestampCell = ({
  timestamp,
  isFromNow = true,
  format,
}: TimestampCellProps) => {
  const time = moment.unix(timestamp);
  const timeFromNow = time.fromNow();

  const formattedTime = time.format(format);
  return (
    <span data-testid="format-date">
      {isFromNow ? timeFromNow : formattedTime}
    </span>
  );
};
