import { ComponentStyleConfig } from '@chakra-ui/react';
import { transparentize } from '@chakra-ui/theme-tools';

import { textStyles } from '../../common';

export const DrawerStyles: ComponentStyleConfig = {
  baseStyle: (props: any) => ({
    dialog: {
      bg: 'layer.1',
    },
    overlay: {
      bg: transparentize('gray.900', 0.45)(props),
    },
    closeButton: {
      position: 'absolute',
      top: 4,
    },
    header: {
      px: 4,
      pt: 4,
      pb: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'start',
      ...textStyles['h5'],
      position: 'sticky',
      top: 0,
      zIndex: 'sticky',
    },
    body: {
      px: 4,
      pt: 4,
      pb: 0,
    },
    footer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      px: 4,
      py: 4,
    },
  }),
  sizes: {
    xs: {
      dialog: {
        maxW: '320px',
      },
    },
    sm: {
      dialog: {
        maxW: '448px',
      },
    },
    md: {
      dialog: {
        maxW: '512px',
      },
    },
    lg: {
      dialog: {
        maxW: '672px',
      },
    },
  },
  defaultProps: {
    size: 'sm',
  },
  variants: {
    interactive: {
      dialogContainer: {
        w: 'auto',
      },
    },
  },
};
