import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { ModelSummary } from './types';

export interface IGetModelSummaries {
  getModelSummaries: ModelSummary[];
}

export const GET_MODEL_SUMMARIES = gql`
  query getModelSummaries($model_ids: [String!]) {
    getModelSummaries(model_ids: $model_ids) {
      algorithm_params {
        algorithm
        compute_norm_values
        data_strategy
        eliminate_outliers
        label_threshold
        learners
        remove_correlated
        remove_sparse
        tune_training_set
      }
      feature_details {
        feature_histograms {
          bins {
            count
            cut
          }
          name
        }
        feature_minmax {
          max
          mean
          min
          name
          pct_10
          pct_25
          pct_5
          pct_50
          pct_75
          pct_90
          pct_95
          std
        }
        feature_count
        model_id
      }
      del_features
      feature_importance {
        importance
        name
      }
      labels {
        tag {
          count
          name
        }
        used_global_labels {
          normal
          unknown
        }
        used_local_labels {
          normal
          unknown
        }
      }
      model_description
      model_id
      model_parameters {
        mode
      }
      model_trained_by
      model_trained_on
      performance_curves {
        pr_curve_thresholds
        pr_curve_x
        pr_curve_y
        roc_curve_thresholds
        roc_curve_x
        roc_curve_y
      }
      preprocessing_filters {
        predict {
          filter
        }
        train {
          filter
          tag_id
        }
      }
      performance_stats {
        auc
        cost
        estimated_daily_alerts
        pdr
        pr_auc
      }
      satisfactory_auc
      satisfactory_tpr
      successful_training
      tag_ids
      training_set_dates {
        end_date
        start_date
      }
      training_set_size
      used_features
    }
  }
`;

export const useGetModelSummaries = (
  options: QueryHookOptions<IGetModelSummaries, OperationVariables>,
) => useQuery<IGetModelSummaries>(GET_MODEL_SUMMARIES, options);
