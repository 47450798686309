import {
  gql,
  LazyQueryHookOptions,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client';

import { IVerifyEDRConfiguration } from './types';

export const VERIFY_EDR_CONFIGURATION = gql`
  query verifyEDRConfiguration(
    $provider_name: EDRConfigurationType!
    $config: EDRConfigurationInput!
  ) {
    verifyEDRConfiguration(provider_name: $provider_name, config: $config) {
      verified
    }
  }
`;

export const useVerifyEDRConfiguration = (
  options: LazyQueryHookOptions<IVerifyEDRConfiguration, OperationVariables>,
) => useLazyQuery<IVerifyEDRConfiguration>(VERIFY_EDR_CONFIGURATION, options);
