import { initializePendo } from '@gamma/investigator/utilities';
import { ReactNode, useContext, useEffect, useState } from 'react';

import { AuthContext } from '../AuthProvider';
import { CookieContext } from './CookieContext';

interface CookieProviderProps {
  children?: ReactNode;
}

export const CookieProvider = ({ children }: CookieProviderProps) => {
  const { user, userLicense } = useContext(AuthContext);

  const [cookieConfig, setCookieConfig] = useState<CookieContext>({
    isOpen: false,
    initialStep: 1,
    getUserCookieAcceptance: [],
  });

  useEffect(() => {
    initializePendo({
      user,
      userLicense,
      isCookieAccepted: cookieConfig?.getUserCookieAcceptance?.find(
        (cookie) => cookie?.cookie_type === 'ANALYTICS',
      )?.cookies_accepted,
    });
  }, [user, userLicense, cookieConfig]);

  return (
    <CookieContext.Provider
      value={{
        ...cookieConfig,
        setCookieConfig,
      }}
    >
      {children}
    </CookieContext.Provider>
  );
};
