import { createIcon } from '@chakra-ui/react';

export const SunIcon = createIcon({
  displayName: 'SunIcon',
  viewBox: '0 0 24 24',
  d: 'm3.55 18.54 1.41 1.41 1.8-1.79-1.42-1.42-1.79 1.8ZM11 22.45h2V19.5h-2v2.95ZM12 5.5a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm8 7h3v-2h-3v2Zm-2.76 5.66 1.8 1.79 1.41-1.41-1.79-1.8-1.42 1.42Zm3.21-13.7-1.41-1.41-1.8 1.79 1.42 1.42 1.79-1.8ZM13 .55h-2V3.5h2V.55ZM4 10.5H1v2h3v-2Zm2.76-5.66-1.8-1.79-1.41 1.41 1.79 1.8 1.42-1.42Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
