import { Box, Flex, FlexProps } from '@chakra-ui/react';
import { forwardRef } from 'react';
import { PanelDivider } from '../PanelDivider';

export interface PanelHeaderProps extends FlexProps {
  /**
   * The flag specifying if the Panel header should have a divider between its
   * content and the panel content
   */
  divider?: boolean;
}

export const PanelHeader = forwardRef<HTMLDivElement, PanelHeaderProps>(
  ({ children, divider, ...rest }, ref) => {
    return (
      <Box
        pb={divider ? 0 : 3}
        px={4}
        pt={3}
        mt={-4}
        mb={4}
        mx={-4}
        borderTopLeftRadius="base"
        borderTopRightRadius="base"
        ref={ref}
        {...rest}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          w="full"
        >
          {children}
        </Flex>
        {divider && (
          <PanelDivider data-testid="panel-header-divider" mb="0" mt="3" />
        )}
      </Box>
    );
  },
);
