import { ComponentStyleConfig } from '@chakra-ui/react';
import { textStyles } from '../../common';

export const FormLabelStyles: ComponentStyleConfig = {
  baseStyle: (props) => ({
    display: 'flex',
    alignItems: 'center',
    color: 'text.heading',
    mb: 0,
    ml: 0,
    marginEnd: 0,
    ...textStyles['body-md'],
  }),
  variants: {
    inline: {
      mb: 0,
      ml: 2,
    },
    small: {
      fontWeight: '300',
    },
  },
};
