import { createIcon } from '@chakra-ui/react';

export const FunnelIcon = createIcon({
  displayName: 'FunnelIcon',
  viewBox: '0 0 24 24',
  d: 'M14 12v7.88c.04.3-.06.62-.29.83a.996.996 0 0 1-1.41 0l-2.01-2.01a.989.989 0 0 1-.29-.83V12h-.03L4.21 4.62a1 1 0 0 1 .17-1.4c.19-.14.4-.22.62-.22h14c.22 0 .43.08.62.22a1 1 0 0 1 .17 1.4L14.03 12H14Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
