import { useEffect } from 'react';

import { usePrevious } from '../usePrevious';

export const useControlledProp = <T = unknown>(
  controlledProp: T | undefined,
  truthyCallback: (value: T) => void,
  falsyCallback?: (value?: T) => void,
) => {
  const prevValue = usePrevious(controlledProp);

  useEffect(() => {
    if (prevValue !== undefined && prevValue !== controlledProp) {
      if (controlledProp) {
        truthyCallback(controlledProp);
      } else {
        falsyCallback?.(controlledProp);
      }
    }
  }, [controlledProp, prevValue, truthyCallback, falsyCallback]);
};
