import { Complete } from './Complete';
import { ForgotPasswordOutlet } from './ForgotPassword';
import { ResetPassword } from './ResetPassword';
import { SetUser } from './SetUser';

export const ForgotPassword = {
  main: ForgotPasswordOutlet,
  setUser: SetUser,
  resetPassword: ResetPassword,
  complete: Complete,
};

export * from './Complete';
export * from './ForgotPassword';
export * from './ResetPassword';
export * from './SetUser';
