import { Flex, IconButton, Link, Spinner, Text, useToast, VStack } from '@chakra-ui/react';
import { Input, Switch } from '@gamma/form-fields';
import { TrashIcon } from '@gamma/icons';
import { URLS } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { ExporterTLSInfoInput, GET_EXPORTERS, SplunkHecLogsExporter, useSaveExporter } from '@gamma/investigator/queries';
import { Alert } from '@gamma/overlay';
import _ from 'lodash';
import { FormEvent, RefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useSetExporterFormState } from '../../../Hooks';

const {
  INVESTIGATOR_DOCS_ALERT_EXPORT,
  SPLUNK_DOCS_ENABLE_HTTP_EVENT_COLLECTOR,
} = URLS;

interface SplunkHECFormProps {
  isUpdating: boolean;
  deleteLoading: boolean;
  onDeleteOpen: () => void;
  clearInProgress: () => void;
  activeExporter: SplunkHecLogsExporter | null;
  setIsUpdating: (isUpdating: boolean) => void;
  setIsSaveLoading: (isSaveLoading: boolean) => void;
  setIsSaveDisabled: (isSaveDisabled: boolean) => void;
  setIsFormUnchanged: (isFormUnchanged: boolean) => void;
  setIsCancelDisabled: (isCancelDisabled: boolean) => void;
  setFormRef: (formRef: RefObject<HTMLFormElement> | null) => void;
}

interface AlertExportData {
  name: string;
  enabled: boolean;
  hec_url: string;
  token: string;
  index: string;
  tls: ExporterTLSInfoInput;
}

export const SplunkHECForm = ({
  isUpdating,
  setFormRef,
  onDeleteOpen,
  deleteLoading,
  setIsUpdating,
  activeExporter,
  clearInProgress,
  setIsSaveLoading,
  setIsSaveDisabled,
  setIsFormUnchanged,
  setIsCancelDisabled,
}: SplunkHECFormProps) => {
  const { register, handleSubmit, setValue, control, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      enabled: false,
      hec_url: '',
      token: '',
      index: '',
      tls: {
        verify_certificate: false,
      },
    },
  });

  const { enabled: enabledLabel, disabled: disabledLabel } =
    i18n.pages.integrations;

  const { forms, splunkHECForm, userGuide, firewallRules, tooltips, toast } =
    i18n.pages.alertExport;

  const { splunkInstructions, splunkDocumentation } = splunkHECForm;

  const { saveSuccessDesc, saveSuccessTitle, saveFailDesc, saveFailTitle } =
    toast;

  const navigate = useNavigate();
  const showToastMessage = useToast();
  const { user } = useContext(AuthContext);
  const tenant = user?.attributes['custom:tenant_id'];

  const formData = watch();

  const formRef = useRef<HTMLFormElement>(null);

  const [exportConfig, setExportConfig] = useState<
    AlertExportData | SplunkHecLogsExporter | null
  >(activeExporter);
  const [shouldDisableSave, setShouldDisableSave] = useState(true);

  const { name, enabled, hec_url, token, tls, index } = exportConfig || {};

  const fillForm = useCallback(() => {
    setValue('name', name || '');
    setValue('enabled', enabled || false);
    setValue('hec_url', hec_url || '');
    setValue('token', token || '');
    setValue('index', index || '');
    setValue('tls', tls || { verify_certificate: false });
  }, [setValue, name, enabled, hec_url, token, index, tls]);

  useEffect(() => {
    setExportConfig(activeExporter);
  }, [activeExporter]);

  useEffect(() => {
    fillForm();
  }, [activeExporter, exportConfig, fillForm]);

  const [saveExporter, { loading: saveLoading, error: saveError }] =
    useSaveExporter({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_EXPORTERS,
        },
      ],
      onCompleted: (data) => {
        const { saveExporter } = data;
        const { exporter_id } = saveExporter;
        setShouldDisableSave(true);
        setIsUpdating(false);
        showToastMessage({
          status: 'success',
          title: saveSuccessTitle,
          description: saveSuccessDesc,
          isClosable: true,
          position: 'bottom-right',
        });
        clearInProgress();
        navigate(exporter_id);
      },
      onError: (error) => {
        setShouldDisableSave(false);
        setIsUpdating(false);
        showToastMessage({
          status: 'error',
          title: saveFailTitle,
          description: saveFailDesc,
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const formIsPopulated = Object.values(formData).every(
    (value) => value !== '',
  );

  const formIsUnchanged = _.isEqual(formData, {
    name,
    enabled,
    hec_url,
    token,
    index,
    tls,
  });

  const isSaveDisabled = formIsUnchanged || !formIsPopulated || saveLoading;

  // only need to do this once and update the state else where like after a save or delete network request.
  useEffect(() => {
    setShouldDisableSave(isSaveDisabled);
  }, []);

  const submitAlertExport = async (data: AlertExportData) => {
    setShouldDisableSave(false);
    setIsUpdating(true);
    setExportConfig(data);
    const { name, enabled, hec_url, token, index, tls } = data;
    const input = {
      tenant,
      name,
      enabled,
      exporter: 'splunk_hec_logs',
      splunk_hec_logs: {
        hec_url,
        token,
        index,
        tls: {
          verify_certificate: tls.verify_certificate,
        },
      },
      exporter_id: activeExporter?.exporter_id || null,
    };
    saveExporter({
      variables: {
        input,
      },
    });
  };

  const handleVerifySSLChange = async (
    event: FormEvent<HTMLInputElement>,
    onChange: (val: boolean) => void,
  ) => {
    const isChecked = (event.target as HTMLInputElement).checked;
    onChange(isChecked);
    setValue('tls', { ...tls, verify_certificate: isChecked });
  };

  useSetExporterFormState({
    formRef,
    isUpdating,
    saveLoading,
    deleteLoading,
    isSaveDisabled,
    formIsUnchanged,
    shouldDisableSave,
    setFormRef,
    setIsSaveLoading,
    setIsSaveDisabled,
    setIsFormUnchanged,
    setIsCancelDisabled,
  });

  return (
    <>
      <VStack my={2} spacing={4} alignItems="start">
        <Text textStyle="body-md">
          {splunkInstructions}{' '}
          <Link
            isExternal
            color="blue.400"
            textDecoration="none"
            href={SPLUNK_DOCS_ENABLE_HTTP_EVENT_COLLECTOR}
          >
            {splunkDocumentation}
          </Link>
          .
        </Text>
        <Alert variant="subtle">
          <Text textStyle="body-md">
            {firewallRules}{' '}
            <Link
              isExternal
              color="blue.400"
              textDecoration="none"
              href={`${INVESTIGATOR_DOCS_ALERT_EXPORT}#splunk-hec`}
            >
              {userGuide}
            </Link>
            .
          </Text>
        </Alert>
      </VStack>
      <VStack
        as="form"
        spacing={4}
        ref={formRef}
        align="flex-start"
        id="alertExportForm"
        onSubmit={handleSubmit(submitAlertExport)}
      >
        <Controller
          name="enabled"
          control={control}
          render={({ field: { onChange, name, value } }) => (
            <Switch
              inline
              name={name}
              isChecked={value}
              onChange={onChange}
              data-testid="exportSwitch"
              isDisabled={isUpdating}
              label={formData.enabled ? enabledLabel : disabledLabel}
            />
          )}
        />
        <Flex w="100%">
          <Input
            {...register('name', { required: true })}
            isRequired
            label={forms.exporterName}
            isDisabled={isUpdating}
          />
        </Flex>
        <Flex w="100%">
          <Input
            {...register('hec_url', { required: true })}
            isRequired
            label={forms.url}
            isDisabled={isUpdating}
          />
        </Flex>
        <Flex w="100%">
          <Input
            {...register('token', { required: true })}
            isRequired
            label={forms.token}
            isDisabled={isUpdating}
          />
        </Flex>
        <Flex w="100%">
          <Input
            {...register('index', { required: true })}
            isRequired
            label={forms.index}
            isDisabled={isUpdating}
          />
        </Flex>
        <Flex w="100%" alignItems="end" justifyContent="space-between">
          <Controller
            name="tls"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <Switch
                name={name}
                label={forms.verifySsl}
                tooltip={tooltips.verifySsl}
                isChecked={value.verify_certificate}
                onChange={(event: FormEvent<HTMLInputElement>) =>
                  handleVerifySSLChange(event, (val) =>
                    onChange({ verify_certificate: val }),
                  )
                }
                data-testid="sslSwitch"
                isDisabled={isUpdating}
              />
            )}
          />
          {!activeExporter?.inProgress && (
            <IconButton
              size="sm"
              variant="solid"
              colorScheme="red"
              aria-label={forms.deleteBtn}
              isDisabled={saveLoading || deleteLoading}
              data-testid="alert-exporter-delete-button"
              onClick={() => {
                setIsUpdating(true);
                onDeleteOpen();
              }}
              icon={
                deleteLoading ? (
                  <Spinner size={'xs'} />
                ) : (
                  <TrashIcon boxSize={5} />
                )
              }
            />
          )}
        </Flex>
      </VStack>
    </>
  );
};
