import { createIcon } from '@chakra-ui/react';

export const StandardEvalLicenseStatus = createIcon({
  displayName: 'StandardLicenseStatus',
  viewBox: '0 0 96 96',
  path: (
    <>
      <mask
        id="path-1-outside-1_531_9069"
        maskUnits="userSpaceOnUse"
        x="61.762"
        y="34.1465"
        width="34"
        height="17"
        fill="black"
      >
        <rect fill="white" x="61.762" y="34.1465" width="34" height="17" />
        <path d="M91.7205 48.3933H65.7592C65.6069 48.3916 65.4554 48.3724 65.3075 48.3361C64.8661 48.23 64.4734 47.9782 64.1929 47.6212C63.9125 47.2643 63.7606 46.8232 63.762 46.3692V38.1688C63.7628 37.6324 63.9764 37.1182 64.3559 36.7391C64.7353 36.36 65.2497 36.1468 65.7861 36.1465L91.7205 36.1465C92.0454 36.1456 92.3655 36.224 92.6532 36.3748C92.7678 36.4342 92.8762 36.5049 92.9767 36.5859C93.2168 36.7738 93.4108 37.0141 93.5439 37.2884C93.677 37.5628 93.7456 37.8639 93.7446 38.1688V46.3692C93.744 46.9059 93.5306 47.4204 93.1511 47.7998C92.7716 48.1793 92.2571 48.3927 91.7205 48.3933Z" />
      </mask>
      <path
        d="M91.7205 48.3933H65.7592C65.6069 48.3916 65.4554 48.3724 65.3075 48.3361C64.8661 48.23 64.4734 47.9782 64.1929 47.6212C63.9125 47.2643 63.7606 46.8232 63.762 46.3692V38.1688C63.7628 37.6324 63.9764 37.1182 64.3559 36.7391C64.7353 36.36 65.2497 36.1468 65.7861 36.1465L91.7205 36.1465C92.0454 36.1456 92.3655 36.224 92.6532 36.3748C92.7678 36.4342 92.8762 36.5049 92.9767 36.5859C93.2168 36.7738 93.4108 37.0141 93.5439 37.2884C93.677 37.5628 93.7456 37.8639 93.7446 38.1688V46.3692C93.744 46.9059 93.5306 47.4204 93.1511 47.7998C92.7716 48.1793 92.2571 48.3927 91.7205 48.3933Z"
        fill="#262626"
      />
      <path
        d="M91.7205 48.3933H65.7592C65.6069 48.3916 65.4554 48.3724 65.3075 48.3361C64.8661 48.23 64.4734 47.9782 64.1929 47.6212C63.9125 47.2643 63.7606 46.8232 63.762 46.3692V38.1688C63.7628 37.6324 63.9764 37.1182 64.3559 36.7391C64.7353 36.36 65.2497 36.1468 65.7861 36.1465L91.7205 36.1465C92.0454 36.1456 92.3655 36.224 92.6532 36.3748C92.7678 36.4342 92.8762 36.5049 92.9767 36.5859C93.2168 36.7738 93.4108 37.0141 93.5439 37.2884C93.677 37.5628 93.7456 37.8639 93.7446 38.1688V46.3692C93.744 46.9059 93.5306 47.4204 93.1511 47.7998C92.7716 48.1793 92.2571 48.3927 91.7205 48.3933Z"
        stroke="#525252"
        stroke-width="4"
        mask="url(#path-1-outside-1_531_9069)"
      />
      <path
        d="M71.8913 45.27H67.6155C67.3869 45.2698 67.1678 45.1745 67.0062 45.0051C66.8446 44.8357 66.7537 44.606 66.7534 44.3664V40.1736C66.7537 39.9341 66.8446 39.7044 67.0062 39.535C67.1678 39.3656 67.3869 39.2703 67.6155 39.27H71.8913C72.1199 39.2703 72.339 39.3656 72.5006 39.535C72.6623 39.7044 72.7532 39.9341 72.7534 40.1736V44.3664C72.7532 44.606 72.6623 44.8357 72.5006 45.0051C72.339 45.1745 72.1199 45.2698 71.8913 45.27Z"
        fill="#43BF79"
      />
      <path
        d="M80.8913 45.27H76.6155C76.3869 45.2698 76.1678 45.1745 76.0062 45.0051C75.8446 44.8357 75.7537 44.606 75.7534 44.3664V40.1736C75.7537 39.9341 75.8446 39.7044 76.0062 39.535C76.1678 39.3656 76.3869 39.2703 76.6155 39.27H80.8913C81.1199 39.2703 81.339 39.3656 81.5006 39.535C81.6623 39.7044 81.7532 39.9341 81.7534 40.1736V44.3664C81.7532 44.606 81.6623 44.8357 81.5006 45.0051C81.339 45.1745 81.1199 45.2698 80.8913 45.27Z"
        fill="#8D8D8D"
      />
      <path
        d="M89.8913 45.27H85.6155C85.3869 45.2698 85.1678 45.1745 85.0062 45.0051C84.8446 44.8357 84.7537 44.606 84.7534 44.3664V40.1736C84.7537 39.9341 84.8446 39.7044 85.0062 39.535C85.1678 39.3656 85.3869 39.2703 85.6155 39.27H89.8913C90.1199 39.2703 90.339 39.3656 90.5006 39.535C90.6623 39.7044 90.7532 39.9341 90.7534 40.1736V44.3664C90.7532 44.606 90.6623 44.8357 90.5006 45.0051C90.339 45.1745 90.1199 45.2698 89.8913 45.27Z"
        fill="#8D8D8D"
      />
      <mask
        id="path-5-outside-2_531_9069"
        maskUnits="userSpaceOnUse"
        x="1"
        y="39.8843"
        width="48"
        height="19"
        fill="black"
      >
        <rect fill="white" x="1" y="39.8843" width="48" height="19" />
        <path d="M41.9045 56.2269H7.12351C6.03027 56.2257 4.98215 55.7909 4.20911 55.0178C3.43606 54.2448 3.00123 53.1967 3 52.1034V46.0078C3.00124 44.9145 3.43608 43.8664 4.20912 43.0934C4.98216 42.3204 6.03027 41.8855 7.12351 41.8843H41.9045C42.9977 41.8855 44.0458 42.3204 44.8189 43.0934C45.5919 43.8664 46.0267 44.9145 46.028 46.0078V52.1034C46.0267 53.1967 45.5919 54.2448 44.8189 55.0178C44.0458 55.7909 42.9977 56.2257 41.9045 56.2269Z" />
      </mask>
      <path
        d="M41.9045 56.2269H7.12351C6.03027 56.2257 4.98215 55.7909 4.20911 55.0178C3.43606 54.2448 3.00123 53.1967 3 52.1034V46.0078C3.00124 44.9145 3.43608 43.8664 4.20912 43.0934C4.98216 42.3204 6.03027 41.8855 7.12351 41.8843H41.9045C42.9977 41.8855 44.0458 42.3204 44.8189 43.0934C45.5919 43.8664 46.0267 44.9145 46.028 46.0078V52.1034C46.0267 53.1967 45.5919 54.2448 44.8189 55.0178C44.0458 55.7909 42.9977 56.2257 41.9045 56.2269Z"
        fill="#262626"
      />
      <path
        d="M41.9045 56.2269H7.12351C6.03027 56.2257 4.98215 55.7909 4.20911 55.0178C3.43606 54.2448 3.00123 53.1967 3 52.1034V46.0078C3.00124 44.9145 3.43608 43.8664 4.20912 43.0934C4.98216 42.3204 6.03027 41.8855 7.12351 41.8843H41.9045C42.9977 41.8855 44.0458 42.3204 44.8189 43.0934C45.5919 43.8664 46.0267 44.9145 46.028 46.0078V52.1034C46.0267 53.1967 45.5919 54.2448 44.8189 55.0178C44.0458 55.7909 42.9977 56.2257 41.9045 56.2269Z"
        stroke="#525252"
        stroke-width="4"
        mask="url(#path-5-outside-2_531_9069)"
      />
      <path
        d="M17.3363 52.7989H11.7448C11.4459 52.7986 11.1593 52.6797 10.948 52.4684C10.7366 52.257 10.6178 51.9705 10.6174 51.6716V46.4408C10.6178 46.1419 10.7366 45.8554 10.948 45.644C11.1593 45.4327 11.4459 45.3138 11.7448 45.3135H17.3363C17.6352 45.3138 17.9217 45.4327 18.1331 45.644C18.3444 45.8554 18.4633 46.1419 18.4636 46.4408V51.6716C18.4633 51.9705 18.3444 52.257 18.1331 52.4684C17.9217 52.6797 17.6352 52.7986 17.3363 52.7989Z"
        fill="#43BF79"
      />
      <path
        d="M27.3097 52.7989H21.7182C21.4193 52.7986 21.1327 52.6797 20.9214 52.4684C20.71 52.257 20.5912 51.9705 20.5908 51.6716V46.4408C20.5912 46.1419 20.71 45.8554 20.9214 45.644C21.1327 45.4327 21.4193 45.3138 21.7182 45.3135H27.3097C27.6086 45.3138 27.8951 45.4327 28.1065 45.644C28.3178 45.8554 28.4367 46.1419 28.437 46.4408V51.6716C28.4367 51.9705 28.3178 52.257 28.1065 52.4684C27.8951 52.6797 27.6086 52.7986 27.3097 52.7989Z"
        fill="#8D8D8D"
      />
      <path
        d="M37.2836 52.7989H31.692C31.3931 52.7986 31.1066 52.6797 30.8953 52.4684C30.6839 52.257 30.565 51.9705 30.5647 51.6716V46.4408C30.565 46.1419 30.6839 45.8554 30.8953 45.644C31.1066 45.4327 31.3931 45.3138 31.692 45.3135H37.2836C37.5825 45.3138 37.869 45.4327 38.0804 45.644C38.2917 45.8554 38.4106 46.1419 38.4109 46.4408V51.6716C38.4106 51.9705 38.2917 52.257 38.0803 52.4684C37.869 52.6797 37.5825 52.7986 37.2836 52.7989Z"
        fill="#8D8D8D"
      />
      <mask
        id="path-9-outside-3_531_9069"
        maskUnits="userSpaceOnUse"
        x="17.2397"
        y="14"
        width="58"
        height="30"
        fill="black"
      >
        <rect fill="white" x="17.2397" y="14" width="58" height="30" />
        <path d="M68.7219 41.0996H23.3633C22.27 41.0984 21.2219 40.6636 20.4489 39.8905C19.6758 39.1175 19.241 38.0694 19.2397 36.9761V20.1235C19.241 19.0303 19.6758 17.9822 20.4489 17.2091C21.2219 16.4361 22.27 16.0012 23.3633 16H68.7219C69.8152 16.0012 70.8633 16.4361 71.6363 17.2091C72.4093 17.9822 72.8442 19.0303 72.8454 20.1235V36.9761C72.8442 38.0694 72.4094 39.1175 71.6363 39.8905C70.8633 40.6636 69.8152 41.0984 68.7219 41.0996Z" />
      </mask>
      <path
        d="M68.7219 41.0996H23.3633C22.27 41.0984 21.2219 40.6636 20.4489 39.8905C19.6758 39.1175 19.241 38.0694 19.2397 36.9761V20.1235C19.241 19.0303 19.6758 17.9822 20.4489 17.2091C21.2219 16.4361 22.27 16.0012 23.3633 16H68.7219C69.8152 16.0012 70.8633 16.4361 71.6363 17.2091C72.4093 17.9822 72.8442 19.0303 72.8454 20.1235V36.9761C72.8442 38.0694 72.4094 39.1175 71.6363 39.8905C70.8633 40.6636 69.8152 41.0984 68.7219 41.0996Z"
        fill="#262626"
      />
      <path
        d="M68.7219 41.0996H23.3633C22.27 41.0984 21.2219 40.6636 20.4489 39.8905C19.6758 39.1175 19.241 38.0694 19.2397 36.9761V20.1235C19.241 19.0303 19.6758 17.9822 20.4489 17.2091C21.2219 16.4361 22.27 16.0012 23.3633 16H68.7219C69.8152 16.0012 70.8633 16.4361 71.6363 17.2091C72.4093 17.9822 72.8442 19.0303 72.8454 20.1235V36.9761C72.8442 38.0694 72.4094 39.1175 71.6363 39.8905C70.8633 40.6636 69.8152 41.0984 68.7219 41.0996Z"
        stroke="#525252"
        stroke-width="4"
        mask="url(#path-9-outside-3_531_9069)"
      />
      <path
        d="M48.2542 25.9484H41.0829C40.8927 25.9484 40.7103 25.8729 40.5758 25.7384C40.4413 25.6039 40.3657 25.4215 40.3657 25.2313C40.3657 25.0411 40.4413 24.8587 40.5758 24.7242C40.7103 24.5897 40.8927 24.5142 41.0829 24.5142H48.2542C48.4444 24.5142 48.6268 24.5897 48.7613 24.7242C48.8958 24.8587 48.9713 25.0411 48.9713 25.2313C48.9713 25.4215 48.8958 25.6039 48.7613 25.7384C48.6268 25.8729 48.4444 25.9484 48.2542 25.9484Z"
        fill="#8D8D8D"
      />
      <path
        d="M63.6724 29.8928H40.9034C40.7132 29.8928 40.5308 29.8172 40.3963 29.6827C40.2618 29.5482 40.1863 29.3658 40.1863 29.1756C40.1863 28.9854 40.2618 28.803 40.3963 28.6685C40.5308 28.5341 40.7132 28.4585 40.9034 28.4585H63.6724C63.8626 28.4585 64.045 28.5341 64.1795 28.6685C64.314 28.803 64.3895 28.9854 64.3895 29.1756C64.3895 29.3658 64.314 29.5482 64.1795 29.6827C64.045 29.8172 63.8626 29.8928 63.6724 29.8928Z"
        fill="#8D8D8D"
      />
      <path
        d="M32.5662 32.0109C35.2217 32.0109 37.3744 29.8582 37.3744 27.2027C37.3744 24.5472 35.2217 22.3945 32.5662 22.3945C29.9108 22.3945 27.7581 24.5472 27.7581 27.2027C27.7581 29.8582 29.9108 32.0109 32.5662 32.0109Z"
        fill="#43BF79"
      />
      <mask
        id="path-13-outside-4_531_9069"
        maskUnits="userSpaceOnUse"
        x="37.3943"
        y="53.104"
        width="58"
        height="30"
        fill="black"
      >
        <rect fill="white" x="37.3943" y="53.104" width="58" height="30" />
        <path d="M88.8765 80.2037H43.5178C42.4246 80.2024 41.3764 79.7676 40.6034 78.9945C39.8304 78.2215 39.3955 77.1734 39.3943 76.0801V59.2275C39.3955 58.1343 39.8304 57.0862 40.6034 56.3131C41.3764 55.5401 42.4246 55.1052 43.5178 55.104H88.8765C89.9697 55.1052 91.0178 55.5401 91.7908 56.3131C92.5639 57.0862 92.9987 58.1343 93 59.2275V76.0801C92.9987 77.1734 92.5639 78.2215 91.7909 78.9945C91.0178 79.7676 89.9697 80.2024 88.8765 80.2037Z" />
      </mask>
      <path
        d="M88.8765 80.2037H43.5178C42.4246 80.2024 41.3764 79.7676 40.6034 78.9945C39.8304 78.2215 39.3955 77.1734 39.3943 76.0801V59.2275C39.3955 58.1343 39.8304 57.0862 40.6034 56.3131C41.3764 55.5401 42.4246 55.1052 43.5178 55.104H88.8765C89.9697 55.1052 91.0178 55.5401 91.7908 56.3131C92.5639 57.0862 92.9987 58.1343 93 59.2275V76.0801C92.9987 77.1734 92.5639 78.2215 91.7909 78.9945C91.0178 79.7676 89.9697 80.2024 88.8765 80.2037Z"
        fill="#262626"
      />
      <path
        d="M88.8765 80.2037H43.5178C42.4246 80.2024 41.3764 79.7676 40.6034 78.9945C39.8304 78.2215 39.3955 77.1734 39.3943 76.0801V59.2275C39.3955 58.1343 39.8304 57.0862 40.6034 56.3131C41.3764 55.5401 42.4246 55.1052 43.5178 55.104H88.8765C89.9697 55.1052 91.0178 55.5401 91.7908 56.3131C92.5639 57.0862 92.9987 58.1343 93 59.2275V76.0801C92.9987 77.1734 92.5639 78.2215 91.7909 78.9945C91.0178 79.7676 89.9697 80.2024 88.8765 80.2037Z"
        stroke="#525252"
        stroke-width="4"
        mask="url(#path-13-outside-4_531_9069)"
      />
      <path
        d="M61.1843 61.7808H53.5654C53.4697 61.781 53.375 61.7623 53.2866 61.7258C53.1982 61.6893 53.1179 61.6357 53.0502 61.5681C52.9825 61.5006 52.9288 61.4203 52.8922 61.332C52.8555 61.2436 52.8367 61.1489 52.8367 61.0533C52.8367 60.9576 52.8555 60.8629 52.8922 60.7746C52.9288 60.6862 52.9825 60.606 53.0502 60.5384C53.1179 60.4708 53.1982 60.4172 53.2866 60.3807C53.375 60.3442 53.4697 60.3255 53.5654 60.3257H61.1843C61.3771 60.326 61.5619 60.4028 61.6981 60.5392C61.8343 60.6756 61.9108 60.8605 61.9108 61.0533C61.9108 61.246 61.8343 61.4309 61.6981 61.5673C61.5619 61.7037 61.3771 61.7805 61.1843 61.7808Z"
        fill="#43BF79"
      />
      <path
        d="M78.2635 64.7562H53.5655C53.3725 64.7562 53.1874 64.6795 53.051 64.5431C52.9145 64.4066 52.8379 64.2216 52.8379 64.0286C52.8379 63.8356 52.9145 63.6506 53.051 63.5141C53.1874 63.3777 53.3725 63.301 53.5655 63.301H78.2635C78.4565 63.301 78.6416 63.3777 78.778 63.5141C78.9145 63.6506 78.9911 63.8356 78.9911 64.0286C78.9911 64.2216 78.9145 64.4066 78.778 64.5431C78.6416 64.6795 78.4565 64.7562 78.2635 64.7562Z"
        fill="#8D8D8D"
      />
      <path
        d="M78.2635 68.172H53.5655C53.4699 68.172 53.3753 68.1531 53.287 68.1166C53.1988 68.08 53.1185 68.0264 53.051 67.9588C52.9834 67.8913 52.9298 67.8111 52.8933 67.7228C52.8567 67.6345 52.8379 67.5399 52.8379 67.4444C52.8379 67.3488 52.8567 67.2542 52.8933 67.1659C52.9298 67.0777 52.9834 66.9975 53.051 66.9299C53.1185 66.8623 53.1988 66.8087 53.287 66.7722C53.3753 66.7356 53.4699 66.7168 53.5655 66.7168H78.2635C78.4565 66.7168 78.6416 66.7935 78.778 66.9299C78.9145 67.0663 78.9911 67.2514 78.9911 67.4444C78.9911 67.6373 78.9145 67.8224 78.778 67.9588C78.6416 68.0953 78.4565 68.172 78.2635 68.172Z"
        fill="#8D8D8D"
      />
      <path
        d="M78.2635 71.588H53.5654C53.4697 71.5881 53.375 71.5694 53.2866 71.5329C53.1982 71.4964 53.1179 71.4428 53.0502 71.3753C52.9825 71.3077 52.9288 71.2274 52.8922 71.1391C52.8555 71.0507 52.8367 70.956 52.8367 70.8604C52.8367 70.7647 52.8555 70.67 52.8922 70.5817C52.9288 70.4933 52.9825 70.4131 53.0502 70.3455C53.1179 70.2779 53.1982 70.2244 53.2866 70.1879C53.375 70.1514 53.4697 70.1327 53.5654 70.1328H78.2635C78.4562 70.1331 78.641 70.2099 78.7772 70.3463C78.9134 70.4827 78.9899 70.6676 78.9899 70.8604C78.9899 71.0532 78.9134 71.238 78.7772 71.3745C78.641 71.5109 78.4562 71.5877 78.2635 71.588Z"
        fill="#8D8D8D"
      />
      <path
        d="M78.2635 75.0037H53.5655C53.3725 75.0037 53.1874 74.9271 53.051 74.7906C52.9145 74.6542 52.8379 74.4691 52.8379 74.2762C52.8379 74.0832 52.9145 73.8981 53.051 73.7617C53.1874 73.6252 53.3725 73.5486 53.5655 73.5486H78.2635C78.4565 73.5486 78.6416 73.6252 78.778 73.7617C78.9145 73.8981 78.9911 74.0832 78.9911 74.2762C78.9911 74.4691 78.9145 74.6542 78.778 74.7906C78.6416 74.9271 78.4565 75.0037 78.2635 75.0037Z"
        fill="#8D8D8D"
      />
    </>
  ),
  defaultProps: {
    height: '96px',
    width: '96px',
  },
});
