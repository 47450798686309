import { useToast } from '@chakra-ui/react';
import { OrgTenantsContext } from '@gamma/investigator/context';
import { useDateRangeQueryString, useManualPagination } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { AlertMetadata, useQueryAlertMetadataPaginated } from '@gamma/investigator/queries';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

export interface QueryAlertMetadataPaginatedVariables {
  start: number;
  end: number;
  query: string | null;
  offset: number;
  size: number;
  sort: { sort_by: string; sort_dir: string }[];
}

const { incorrectSyntax, unexpectedError } =
  i18n.pages.system.alertCatalog.toast;

export const AlertCatalogOutlet = () => {
  const { start, end } = useDateRangeQueryString();
  const { orgTenantsQueryParam } = useContext(OrgTenantsContext);

  const showToastMessage = useToast();

  const [query, setQuery] = useState<string | null>(null);
  const [queryTerm, setQueryTerm] = useState<string | null>(null);
  const [prevQueryTerm, setPrevQueryTerm] = useState<string | null>(null);

  const {
    offset,
    sortBy,
    pageSize,
    pageIndex,
    pageCount,
    onFetchData,
    getPageCount,
    getSortsForAPI,
  } = useManualPagination<AlertMetadata>({
    sortBy: [{ id: 'severity', desc: true }],
  });

  const sorts = useMemo(
    () => [
      ...getSortsForAPI(sortBy),
      { sort_by: '_score', sort_dir: 'desc' }, // always include _score sort
    ],
    [getSortsForAPI, prevQueryTerm, query, queryTerm, sortBy],
  );

  const variables: QueryAlertMetadataPaginatedVariables = useMemo(() => {
    return {
      start,
      end,
      query,
      offset,
      size: pageSize,
      sort: sorts,
    };
  }, [end, offset, pageSize, query, sorts, start]);

  const { loading, error, data } = useQueryAlertMetadataPaginated({
    variables,
    fetchPolicy: 'network-only',
    skip:
      variables.query === null ||
      (orgTenantsQueryParam && orgTenantsQueryParam?.length > 1),
    onCompleted: ({ queryAlertMetadataPaginated }) => {
      getPageCount(queryAlertMetadataPaginated);
    },
    onError: (error) => {
      const syntaxIncorrect = error.message === incorrectSyntax;

      showToastMessage({
        status: 'error',
        title: 'Error',
        description: syntaxIncorrect ? incorrectSyntax : unexpectedError,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  useEffect(() => {
    setPrevQueryTerm(null);
  }, [sortBy]);

  return (
    <Outlet
      context={{
        data,
        error,
        sortBy,
        loading,
        pageSize,
        pageIndex,
        pageCount,
        variables,
        onFetchData,
        hiddenCols: ['content_id', '_score'],
        setQuery,
        queryTerm,
        setQueryTerm,
        setPrevQueryTerm,
      }}
    />
  );
};
