import { Link } from '@chakra-ui/react';
import { URLS } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import { Board, Column } from '@gamma/layout';
import { Alert, ErrorBoundary } from '@gamma/overlay';

import { IntegrationsAlertExports } from '../IntegrationsAlertExports';

export const LockedAlertExport = () => {
  const { alert } = i18n.pages.alertExport;

  return (
    <ErrorBoundary page="AlertExport" styleClass="main">
      <Board overflow="hidden" data-testid="locked-alert-export">
        <Column>
          <Alert
            status="info"
            title={alert.locked}
            children={
              <Link
                href={URLS.INVESTIGATOR_DOCS}
                textDecoration="underline"
                isExternal
                position="absolute"
                right="6"
                top="2"
              >
                {alert.link}
              </Link>
            }
          />
        </Column>
        <Column opacity={0.5} tabIndex={-1} pointerEvents="none">
          <IntegrationsAlertExports locked={true} />
        </Column>
      </Board>
    </ErrorBoundary>
  );
};
