import { createIcon } from '@chakra-ui/react';

export const DoubleChevronDownIcon = createIcon({
  displayName: 'DoubleChevronDownIcon',
  viewBox: '0 0 24 24',
  d: 'M16.59 5.59 18 7l-6 6-6-6 1.41-1.41L12 10.17l4.59-4.58Zm0 6L18 13l-6 6-6-6 1.41-1.41L12 16.17l4.59-4.58Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
