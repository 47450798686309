import { gql, OperationVariables, QueryHookOptions, useQuery } from '@apollo/client';

import { GetKinesisConnectionDetails } from './types';

export interface IFetchKinesisConnectionDetails {
  getKinesisConnectionDetails: GetKinesisConnectionDetails;
}

export const GET_KINESIS_CONNECTION_DETAILS = gql`
  query getKinesisConnectionDetails {
    getKinesisConnectionDetails {
      access_key
      aws_region
      secret_key
      stream_name
    }
  }
`;

export const useGetKinesisConnectionDetails = (
  options: QueryHookOptions<IFetchKinesisConnectionDetails, OperationVariables>,
) =>
  useQuery<IFetchKinesisConnectionDetails>(
    GET_KINESIS_CONNECTION_DETAILS,
    options,
  );
