import {
  gql,
  LazyQueryHookOptions,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client';

import { LicenseInfo } from './types';

export interface IGetLicenseInfo {
  getLicenseInfo: LicenseInfo;
}

export const GET_LICENSE = gql`
  query getLicenseInfo {
    getLicenseInfo {
      end
      expired
      federation_type
      gbps
      input_region
      license_type
      members {
        display_name
        tenant_id
      }
      org
      primary_contact {
        email
        name
      }
      retention_days
      start
      tenant
      tenant_location
      tenant_type
      display_name
    }
  }
`;

export const useGetLicenseInfo = (
  options?: LazyQueryHookOptions<IGetLicenseInfo, OperationVariables>,
) => {
  return useLazyQuery(GET_LICENSE, options);
};
