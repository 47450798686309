import { ILoginForm } from '@gamma/forms';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export interface IRegistrationContext {
  userEmail: string;
  setUserEmail: (value: string) => void;
  userAlias: string;
  setUserAlias: (value: string) => void;
  user: ILoginForm;
  routeAccessAllowed?: boolean;
  setRouteAccessAllowed: (value: boolean) => void;
}

export const RegistrationOutlet = () => {
  const { state } = useLocation();
  const userCredentials = useRef<ILoginForm>();
  const [userEmail, setUserEmail] = useState<string>();
  const [userAlias, setUserAlias] = useState<string>();
  const [routeAccessAllowed, setRouteAccessAllowed] = useState<string>((state as any)?.routeAccessAllowed);
  const navigate = useNavigate();

  useEffect(() => {
    const stateUser = (state as any)?.user;
    if (stateUser && userCredentials.current?.username != stateUser.username) {
      userCredentials.current = stateUser;
    } else if (!stateUser && !userCredentials.current) {
      /**
       * redirect the user to the login screen if they access registration
       * pages directly via url
       */
      if (!routeAccessAllowed) {
        navigate('/login', { replace: true });
      }
    }
  }, [state]);

  return (
    <Outlet
      context={{
        userEmail,
        setUserEmail,
        userAlias,
        setUserAlias,
        user: userCredentials.current,
        routeAccessAllowed,
        setRouteAccessAllowed,
      }}
    />
  );
};

export default RegistrationOutlet;
