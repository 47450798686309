import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Grid,
  GridItem,
  HStack,
  Tooltip,
  TooltipProps,
} from '@chakra-ui/react';
import { forwardRef, ReactNode } from 'react';
import { MuiIcon } from '@gamma/display';

export interface CheckboxProps extends Omit<ChakraCheckboxProps, 'isInvalid'> {
  tooltip?: TooltipProps['label'];
  error?: ReactNode;
  warning?: ReactNode;
  hint?: ReactNode;
  size?: ChakraCheckboxProps['size'];
  /** Node following the Input */
  afterInput?: ReactNode;
  isLabelHidden?: boolean;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      tooltip,
      isReadOnly,
      isDisabled,
      error,
      warning,
      hint,
      id,
      onClick,
      afterInput,
      children,
      isLabelHidden,
      ...rest
    },
    ref,
  ) => {
    return (
      <FormControl
        isReadOnly={isReadOnly}
        isInvalid={Boolean(error)}
        isDisabled={isDisabled}
        id={id}
        w="auto"
        onClick={onClick}
      >
        <Grid templateColumns="1fr min-content" alignItems="center">
          <GridItem display="flex">
            <ChakraCheckbox
              id={id}
              isReadOnly={isReadOnly}
              isInvalid={Boolean(error)}
              isDisabled={isDisabled}
              ref={ref}
              {...rest}
            >
              {!isLabelHidden && (
                <HStack spacing={3}>
                  <span>{children}</span>
                  {tooltip && (
                    <Tooltip
                      hasArrow
                      label={tooltip}
                      bg="layer.0"
                      color="text.base"
                      placement="top"
                      p={3}
                      shouldWrapChildren
                    >
                      <MuiIcon size="sm">help</MuiIcon>
                    </Tooltip>
                  )}
                </HStack>
              )}
            </ChakraCheckbox>
          </GridItem>
          {afterInput && <GridItem pl={4}>{afterInput}</GridItem>}
        </Grid>
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
        {warning && (
          <FormHelperText color="yellow.400">{warning}</FormHelperText>
        )}
        {hint && <FormHelperText>{hint}</FormHelperText>}
      </FormControl>
    );
  },
);
