import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

export const ENABLE_DISABLE_USER_POOL_APP_CLIENT_IDP = gql`
  mutation enableDisableUserPoolAppClientIdp($enable: Boolean!) {
    enableDisableUserPoolAppClientIdp(enable: $enable) {
      body {
        data
        message
        success
      }
      status_code
    }
  }
`;

export const useEnableDisableUserPoolAppClientIdp = (
  options: MutationHookOptions<any, OperationVariables>,
) => useMutation(ENABLE_DISABLE_USER_POOL_APP_CLIENT_IDP, options);
