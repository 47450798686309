import { createIcon } from '@chakra-ui/react';

export const CaretUpIcon = createIcon({
  displayName: 'CaretUpIcon',
  d: 'm7 15 5-5 5 5H7Z',
  viewBox: '0 0 24 24',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
