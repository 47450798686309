import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

export interface LogItem {
  message: string;
  timestamp: string;
  tenant: string;
  tenant_info: {
    tenant_id: string;
    tenant_display_name: string;
  };
}

export interface IUserLogs {
  log: LogItem[];
  total_items: number;
}

export interface IGetUserLogs {
  getUserLogs: IUserLogs;
}

export const GET_USER_LOGS = gql`
  query getUserLogs($input: GetUserLogsInput!) {
    getUserLogs(input: $input) {
      log {
        message
        timestamp
        tenant
        tenant_info {
          tenant_id
          tenant_display_name
        }
      }
      offset
      size
      total_items
    }
  }
`;

export const useGetUserLogs = (
  options: QueryHookOptions<IGetUserLogs, OperationVariables>,
) => {
  return useQuery<IGetUserLogs>(GET_USER_LOGS, options);
};
