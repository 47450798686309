import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

import { UserSuccessOutput } from './types';

export interface IUpdateUserCookieStatusInput {
  userUpdateCookieStatus: UserSuccessOutput;
}

export const USER_UPDATE_COOKIE_STATUS = gql`
  mutation userUpdateCookieStatus($updates: [UpdateUserCookieStatusInput]) {
    userUpdateCookieStatus(updates: $updates) {
      body {
        message
        success
      }
      status_code
    }
  }
`;

export const useUserUpdateCookieStatus = (
  options?: MutationHookOptions<
    IUpdateUserCookieStatusInput,
    OperationVariables
  >,
) => {
  return useMutation(USER_UPDATE_COOKIE_STATUS, options);
};
