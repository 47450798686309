import { Box, Divider, StylesProvider, useMultiStyleConfig } from '@chakra-ui/react';
import { useControlledProp } from '@gamma/hooks';
import { FlushPanelContent, SplitLayout, SplitLayoutProps } from '@gamma/layout';
import { LoadingSpinner } from '@gamma/progress';
import { ComponentType, Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { DataListInitialState, useDataListPagination } from '../../hooks';
import { DataTablePagination } from '../DataTablePagination';

export interface DataListProps<T extends {}>
  extends Partial<Omit<SplitLayoutProps, 'left' | 'right'>> {
  /** Array of data items to be displayed. */
  data: T[];
  /** The component used to render each data item. */
  RenderItem: ComponentType<{ item: T }>;
  /** Optional component used to render the split view when an item is selected. */
  RenderSplit?: ComponentType<{ item: T }>;
  /** Whether to reset the page index to 0 when the data changes. Defaults to true. */
  autoResetPage?: boolean;
  /** The total number of pages when pagination is manually handled. */
  pageCount?: number;
  /** The total number of items in the dataset. */
  itemCount?: number;
  /** Whether the data is currently being loaded. */
  isLoading?: boolean;
  /** Whether pagination is manually handled. */
  isPaginationManual?: boolean;
  /** Optional content to be displayed when the data array is empty. */
  isRenderSplitDrawer?: boolean;
  /** Optional automatically render split content in open drawer at base -> lg */
  emptyBodyContent?: ReactNode;
  /** The currently selected item. */
  selectedItem?: T;
  /** The function used to determine if an item is selected */
  matchFn?: (item: T, selected: T | null) => boolean;
  /** Callback function to fetch data when the page index or page size changes. */
  onFetchData?: (pageIndex: number, pageSize: number) => void;
  /** Callback function when an item is selected. */
  onItemSelection?: (item: T) => void;
  layerStyle?: string;
  storageKey?: string;
  /** pageIndex and pageSize values for initial render. */
  initialState?: DataListInitialState;
}

export const DataList = <T extends {}>({
  data,
  itemCount,
  RenderItem,
  RenderSplit,
  autoResetPage = true,
  pageCount: manualPageCount,
  isLoading,
  isPaginationManual,
  isRenderSplitDrawer,
  emptyBodyContent,
  matchFn,
  onSplit,
  onFetchData,
  onItemSelection,
  layerStyle = 'first',
  selectedItem: propsSelectedItem,
  'data-testid': dataTestId,
  initialState,
  ...layoutProps
}: DataListProps<T>) => {
  const [selectedItem, setSelectedItem] = useState<T | null>(
    propsSelectedItem ?? null,
  );

  useControlledProp(propsSelectedItem, (value) => {
    setSelectedItem(value);
  });

  const {
    pageIndex,
    setPageIndex,
    pageSize,
    setPageSize,
    canPrevPage,
    canNextPage,
    nextPage,
    prevPage,
    goToPage,
    pageCount,
  } = useDataListPagination({
    initialState,
    dataLength: data.length,
    pageCount: manualPageCount,
    autoResetPage,
    onFetchData,
  });

  // Calculate the data for the current page based on data length, page index, and page size
  const currentPageData = useMemo(() => {
    if (isPaginationManual) {
      return data;
    }
    const offset = pageIndex * pageSize;
    return data.slice(offset, offset + pageSize);
  }, [data, isPaginationManual, pageIndex, pageSize]);

  const styles = useMultiStyleConfig('DataList', {
    layerStyle,
  });

  const selectedItemIndex = useMemo(() => {
    return currentPageData.findIndex((item) => matchFn?.(item, selectedItem));
  }, [currentPageData, matchFn, selectedItem]);

  const isSelectedItemOnCurrentPage = useMemo(() => {
    return selectedItemIndex > -1;
  }, [selectedItemIndex]);

  const handleItemSelection = useCallback(
    (item: T) => {
      setSelectedItem(item);
      onItemSelection?.(item);
    },
    [onItemSelection],
  );

  // update the selected item if the current page data changes && the selected item is not on the current page
  useEffect(() => {
    if (propsSelectedItem && isSelectedItemOnCurrentPage) {
      setSelectedItem(propsSelectedItem);
    } else if (currentPageData.length > 0 && !isSelectedItemOnCurrentPage) {
      const updatedSelectedItem = currentPageData.find((item) =>
        matchFn?.(item, selectedItem ?? ({} as T)),
      );
      if (updatedSelectedItem) {
        setSelectedItem(updatedSelectedItem);
      } else {
        handleItemSelection(currentPageData[0]);
      }
    }
  }, [
    currentPageData,
    isSelectedItemOnCurrentPage,
    propsSelectedItem,
    handleItemSelection,
    matchFn,
    selectedItem,
  ]);

  return (
    <SplitLayout
      isRenderSplitDrawer={isRenderSplitDrawer}
      {...layoutProps}
      left={
        <FlushPanelContent
          w="calc(100% + var(--chakra-space-12))"
          h="calc(100% + var(--chakra-space-8))"
        >
          <StylesProvider value={styles}>
            <Box __css={styles.container}>
              <Box __css={styles.body} data-testid="gamma-data-list-body">
                {isLoading ? (
                  <LoadingSpinner />
                ) : data.length === 0 ? (
                  emptyBodyContent
                ) : (
                  <>
                    {currentPageData.map((item, index) => (
                      <Fragment key={index}>
                        <Box
                          key={`${pageSize * pageIndex + index}`}
                          position="relative"
                          __css={
                            isSelectedItemOnCurrentPage &&
                            matchFn?.(item, selectedItem)
                              ? styles.selectedItem
                              : styles.item
                          }
                          onClick={() => handleItemSelection(item)}
                        >
                          <RenderItem item={item} />
                        </Box>
                        {index !== pageSize - 1 && (
                          <Divider __css={styles.divider} />
                        )}
                      </Fragment>
                    ))}
                  </>
                )}
              </Box>
              <DataTablePagination
                pageSize={pageSize}
                pageIndex={pageIndex}
                pageCount={pageCount}
                itemCount={itemCount ?? data.length}
                setPageSize={setPageSize}
                gotoPage={goToPage}
                canPreviousPage={canPrevPage}
                previousPage={prevPage}
                canNextPage={canNextPage}
                nextPage={nextPage}
              />
            </Box>
          </StylesProvider>
        </FlushPanelContent>
      }
      right={selectedItem && RenderSplit && <RenderSplit item={selectedItem} />}
    />
  );
};
