import { LicenseInfo } from '@gamma/investigator/queries';
import { CognitoUserInterface } from '@gamma/shared/types';

export interface InitializePendoProps {
  user?: CognitoUserInterface;
  isCookieAccepted?: boolean | null;
  userLicense?: LicenseInfo;
}

const pendoStorage: Record<string, any> = {};

export const initializePendo = ({
  user,
  isCookieAccepted,
  userLicense,
}: InitializePendoProps) => {
  if (
    isCookieAccepted &&
    user &&
    !user?.challengeName &&
    window.pendo &&
    userLicense?.license_type
  ) {
    // Inject Pendo script tag into DOM
    const pendoScript = document.createElement('script');

    pendoScript.src = 'pendo.js';

    document.head.appendChild(pendoScript);

    window?.pendo?.initialize({
      visitor: {
        id: user.attributes.sub,
        role: user.signInUserSession.accessToken.payload['cognito:groups']?.[0],
        user_type: user?.attributes?.email.includes('corelight')
          ? 'internal'
          : 'external',
        license_type: userLicense?.license_type?.toLowerCase(),
      },
      account: {
        id: user.attributes['custom:tenant_id'],
      },
    });

    if (window?.pendo?.startGuides) {
      window?.pendo?.startGuides();
    }

    // store pendo in local storage to be reapplied later if user re-enables w/o a page reload
    Object.keys(pendoStorage).forEach((item) => {
      localStorage.setItem(item, pendoStorage[item]);
    });
  } else {
    if (
      user &&
      isCookieAccepted === false &&
      window?.pendo?.stopSendingEvents &&
      window?.pendo?.flushNow &&
      window?.pendo?.clearSession
    ) {
      window?.pendo?.stopSendingEvents();
      window?.pendo?.flushNow();
      window?.pendo?.clearSession();
    }

    // purge remaining local storage of pendo
    Object.keys(localStorage).forEach((item) => {
      if (item.includes('pendo')) {
        pendoStorage[item] = localStorage.getItem(item);
        localStorage.removeItem(item);
      }
    });

    const pendoElements = document.querySelectorAll('[src^="pendo"]');
    pendoElements.forEach((element) => {
      element?.parentNode?.removeChild(element);
    });

    // Remove Pendo script tags from DOM
    const pendoScripts = document.querySelectorAll(
      'script[src^="https://cdn.pendo.io/"]',
    );
    pendoScripts.forEach((script) => {
      script?.parentNode?.removeChild(script);
    });
  }
};
