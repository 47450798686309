import { createIcon } from '@chakra-ui/react';

export const QuadHamburgerIcon = createIcon({
  displayName: 'QuadHamburgerIcon',
  viewBox: '0 0 16 16',
  d: 'M14 3H2v1h12V3ZM14 12H2v1h12v-1ZM14 6H2v1h12V6ZM14 9H2v1h12V9Z',
  defaultProps: {
    height: '16px',
    width: '16px',
  },
});
