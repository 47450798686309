import { ComponentStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

import { textStyles } from '../../common';

export const tableStyles: ComponentStyleConfig = {
  baseStyle: {
    th: {
      textTransform: 'none',
    },
  },
  sizes: {
    sm: {
      th: {
        py: 2,
      },
      td: {
        py: 3,
      },
    },
    md: {
      th: {
        ...textStyles['body-md-bold'],
      },
    },
  },
  variants: {
    license: (props: any) => ({
      tr: {
        backgroundColor: mode('gray.50', 'gray.800')(props),
        borderBottom: '1px solid',
        borderColor: 'gray.700',
        ':last-child': {
          borderBottom: 'none',
        },
      },
      thead: {
        tr: {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
      },
    }),
  },
};
