import { Center, Flex, HStack, Text, Tooltip } from '@chakra-ui/react';
import { ChatGPTIcon } from '@gamma/icons';
import { i18n } from '@gamma/investigator/localization';

const { askGPT, chatGPTToolTip } = i18n.pages.integrations.chatGPT;

export const ChatGPTToolTip = ({ boxSize }: { boxSize?: number }) => {
  return (
    <Flex data-testid="chat-gpt-tool-tip" alignItems="center">
      <Tooltip
        borderColor="yellow.200"
        border="1px solid"
        placement="bottom-end"
        label={
          <HStack
            data-testid="chat-gpt-tool-tip-icon-label"
            alignItems="flex-start"
          >
            <ChatGPTIcon boxSize={5} />
            <Text>{chatGPTToolTip}</Text>
          </HStack>
        }
      >
        <Center data-testid="chat-gpt-tool-tip-icon-description">
          <ChatGPTIcon boxSize={boxSize ?? 5} />
        </Center>
      </Tooltip>
    </Flex>
  );
};
