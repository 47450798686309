import { createIcon } from '@chakra-ui/react';

export const CirclePauseIcon = createIcon({
  displayName: 'CirclePauseIcon',
  viewBox: '0 0 24 24',
  d: 'M13 16V8h2v8h-2Zm-4 0V8h2v8H9Zm3-14a10 10 0 1 1 0 20 10 10 0 0 1 0-20Zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
