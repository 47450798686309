import { useLocation, useNavigate } from 'react-router-dom';

export const useHandleNextURLRedirect = (url: string) => {
  const navigate = useNavigate();
  const routerLocation = useLocation();

  const nextFromLocalStorage = localStorage.getItem('next');
  const nextFromURLParams =
    new URLSearchParams(routerLocation.search).get('next') ?? '';

  if (!nextFromLocalStorage && !nextFromURLParams) {
    return () =>
      navigate(url, {
        replace: true,
      });
  } else {
    const url = nextFromLocalStorage ?? nextFromURLParams;

    const decodedURL = decodeURIComponent(url);

    return () => {
      navigate(decodedURL, {
        replace: true,
      });
    };
  }
};
