import { createIcon } from '@chakra-ui/react';

export const RefreshExclamationIcon = createIcon({
  displayName: 'RefreshExclamationIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path d="m17 13 5.5 9.5h-11L17 13Z" fill="#222930" />
      <path
        d="M17.5 19h-1v-2.5h1V19Zm0 2h-1v-1h1v1Zm-6 1.5h11L17 13l-5.5 9.5Z"
        fill="#ECC94B"
      />
      <path
        d="M7.757 7.757A6 6 0 0 1 12 6v3l4-4-4-4v3a8 8 0 0 0-8 8c0 1.57.46 3.03 1.24 4.26L6.7 14.8A5.9 5.9 0 0 1 6 12a6 6 0 0 1 1.757-4.243ZM10.768 21.768 8 19l4-4v3c.337 0 .67-.028.998-.084l-2.23 3.852ZM17.963 12.667A5.9 5.9 0 0 0 17.3 9.2l1.46-1.46A7.93 7.93 0 0 1 20 12c0 1.076-.217 2.13-.627 3.104l-1.41-2.437Z"
        fill="currentColor"
      />
    </>
  ),
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
