import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { ListGroups } from './types';

export interface IListGroupsData {
  listGroups: ListGroups[];
}

export const LIST_GROUPS = gql`
  query listGroups {
    listGroups {
      group_name
    }
  }
`;
export const useListGroups = (
  options: QueryHookOptions<IListGroupsData, OperationVariables>,
) => {
  return useQuery<IListGroupsData>(LIST_GROUPS, options);
};
