import { CTAButton } from '@gamma/buttons';
import { Input } from '@gamma/form-fields';
import { REGEX } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import {
  CardPageBackButton,
  CardPageBody,
  CardPageCTA,
  CardPageHeading,
  CardPageIllustration,
  CardPageSubtitle,
} from '@gamma/pages';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useOutletContext } from 'react-router';

import { IForgotPasswordContext } from '../ForgotPassword';

export const SetUser = () => {
  const navigate = useNavigate();
  const { setUserEmail } = useOutletContext<IForgotPasswordContext>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm<{ email: string }>();

  const handleUserEmailSubmit = async ({ email }: { email: string }) => {
    try {
      setIsLoading(true);
      await Auth.forgotPassword(email);
      setUserEmail(email);
      navigate('./reset', { replace: true });
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const { title, subtitle, cta } = i18n.pages.forgotPassword.setUser;

  useEffect(() => {
    setFocus('email');
  }, []);

  return (
    <>
      <CardPageBackButton onClick={() => navigate('/login/trouble')} />
      <CardPageHeading>{title}</CardPageHeading>
      <CardPageIllustration
        illustration={'LockedIdentity'}
        height="128px"
        width="160px"
      />
      <CardPageSubtitle>{subtitle}</CardPageSubtitle>
      <CardPageBody>
        <form onSubmit={handleSubmit(handleUserEmailSubmit)} id="userEmailForm">
          <Input
            data-testid="input_email"
            label={i18n.forms.labels.email}
            placeholder={i18n.forms.labels.email}
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: REGEX.EMAIL,
                message: 'Email is invalid',
              },
            })}
            error={errors.email?.message}
          />
        </form>
      </CardPageBody>
      <CardPageCTA>
        <CTAButton type="submit" isLoading={isLoading} form="userEmailForm">
          {cta}
        </CTAButton>
      </CardPageCTA>
    </>
  );
};
