import { Select } from '@gamma/form-fields';
import { ROUTES } from '@gamma/investigator/constants';
import { AuthContext, OrgTenantsContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { useContext, useEffect, useMemo } from 'react';
import { NavigateFunction, useParams } from 'react-router-dom';

const { allTenants, selectTenant } = i18n.federatedTenant;

const {
  adminUsers,
  adminAccess,
  alertCatalog,
  systemSettingsGeneral,
  systemSettingsSensorMonitoring,
  systemSettingsIntegrationsConfig,
  systemSettingsIntegrationsAlertExports,
} = ROUTES;

export interface FederatedTenantSelectProps {
  pathname: string;
  params: URLSearchParams;
  navigate: NavigateFunction;
}

export const FederatedTenantSelect = ({
  params,
  navigate,
  pathname,
}: FederatedTenantSelectProps) => {
  const { isOrgTenant, userLicense } = useContext(AuthContext);
  const { selectedOrgTenants, setSelectedOrgTenants } =
    useContext(OrgTenantsContext);

  const tenant = useMemo(() => params.get('tenant'), [params]);

  const { content_id } = useParams();

  const orgAllOptionDisabled = pathname.includes(alertCatalog);

  const orgSelectDisabled =
    [
      adminUsers,
      adminAccess,
      systemSettingsGeneral,
      systemSettingsSensorMonitoring,
      systemSettingsIntegrationsConfig,
      systemSettingsIntegrationsAlertExports,
    ].includes(pathname) ||
    content_id ||
    !isOrgTenant;

  const allOption = { label: allTenants, value: 'all' };

  const orgMemberOptions = userLicense?.members
    ? [
        ...(!orgAllOptionDisabled ? [allOption] : []),
        ...userLicense?.members.map((member) => ({
          label: member.display_name,
          value: member.tenant_id,
        })),
      ]
    : [];

  const matchedParamTenant = orgMemberOptions.find(
    (member) => member.value === tenant,
  );

  const tenantMemberAll = { display_name: allTenants, tenant_id: 'all' };

  useEffect(() => {
    if (isOrgTenant) {
      if (!selectedOrgTenants) {
        if (matchedParamTenant) {
          setSelectedOrgTenants?.([
            {
              tenant_id: matchedParamTenant.value,
              display_name: matchedParamTenant.label,
            },
          ]);
        } else {
          if (!orgAllOptionDisabled) {
            setSelectedOrgTenants?.([tenantMemberAll]);
          } else if (orgMemberOptions.length > 0) {
            setSelectedOrgTenants?.([
              {
                tenant_id: orgMemberOptions?.[0]?.value,
                display_name: orgMemberOptions?.[0]?.label,
              },
            ]);
          }
        }
      } else if (
        tenant === 'all' &&
        orgAllOptionDisabled &&
        orgMemberOptions?.length > 0
      ) {
        setSelectedOrgTenants?.([
          {
            tenant_id: orgMemberOptions?.[0]?.value,
            display_name: orgMemberOptions?.[0]?.label,
          },
        ]);
      }
    } else {
      if (!selectedOrgTenants && userLicense?.tenant) {
        setSelectedOrgTenants?.([
          {
            tenant_id: userLicense?.tenant,
            display_name: userLicense?.display_name,
          },
        ]);
      }
    }
  }, [
    params,
    tenant,
    isOrgTenant,
    userLicense,
    tenantMemberAll,
    orgSelectDisabled,
    matchedParamTenant,
    selectedOrgTenants,
    setSelectedOrgTenants,
  ]);

  useEffect(() => {
    if (
      isOrgTenant &&
      !orgSelectDisabled &&
      selectedOrgTenants &&
      selectedOrgTenants?.length > 0
    ) {
      if (tenant !== selectedOrgTenants?.[0]?.tenant_id) {
        params.set('tenant', selectedOrgTenants?.[0]?.tenant_id);
        navigate({
          pathname,
          search: params.toString(),
        });
      }
    }
  }, [
    params,
    tenant,
    navigate,
    pathname,
    orgMemberOptions,
    orgSelectDisabled,
    selectedOrgTenants,
  ]);

  return !orgSelectDisabled && orgMemberOptions.length > 0 ? (
    <Select
      width="250px"
      name="tenant"
      label={selectTenant}
      isMulti={false}
      isLabelHidden={true}
      options={orgMemberOptions}
      data-testid="org-tenant-select"
      value={
        selectedOrgTenants &&
        orgMemberOptions.find(
          ({ value }) =>
            (value as string).toLowerCase() ===
            selectedOrgTenants?.[0]?.tenant_id?.toLowerCase(),
        )
      }
      onChange={(selected) => {
        selected &&
          setSelectedOrgTenants &&
          setSelectedOrgTenants([
            { display_name: selected.label, tenant_id: selected.value },
          ]);
      }}
    ></Select>
  ) : (
    <></>
  );
};
