import { KeyboardEvent, KeyboardEventHandler, RefObject } from 'react';

export type InputFocusOrderRefs = {
  prev?: RefObject<HTMLInputElement>;
  curr: RefObject<HTMLInputElement>;
  next?: RefObject<HTMLInputElement>;
};

export const handleDateTimeInputKeydown =
  (
    refs?: InputFocusOrderRefs,
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>,
  ) =>
  (event: KeyboardEvent<HTMLInputElement>) => {
    const { prev, curr, next } = refs ?? {};

    if (event.key === 'ArrowRight' && next?.current) {
      event.preventDefault();
      next.current.focus();
      next.current.select();
    }

    if (event.key === 'ArrowLeft' && prev?.current) {
      event.preventDefault();
      prev.current.focus();
      prev.current.select();
    }

    if (
      event.key === 'Backspace' &&
      curr?.current?.value === '' &&
      prev?.current
    ) {
      event.preventDefault();
      prev.current.focus();
      prev.current.select();
    }

    onKeyDown?.(event);
  };
