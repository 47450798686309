import { Button, useToast } from '@chakra-ui/react';
import { CTAButton } from '@gamma/buttons';
import { IPasswordResetForm, PasswordResetForm } from '@gamma/forms';
import { i18n } from '@gamma/investigator/localization';
import {
  CardPageBackButton,
  CardPageBody,
  CardPageCTA,
  CardPageHeading,
  CardPageIllustration,
  CardPageSubtitle,
} from '@gamma/pages';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { IForgotPasswordContext } from '../ForgotPassword';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const showToastMessage = useToast();
  const { userEmail, setUserEmail } =
    useOutletContext<IForgotPasswordContext>();
  const [loading, setLoading] = useState<boolean>(false);
  const [resendOTPLoading, setResendOTPLoading] = useState<boolean>(false);
  const [showResendOtp, setShowResendOtp] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const paramEmail = searchParams.get('email');

  const {
    title,
    subtitle,
    cta,
    incorrectOTP,
    expiredOTP,
    newOTPSent,
    resendOTPCta,
  } = i18n.pages.forgotPassword.resetPassword;

  useEffect(() => {
    if (!paramEmail && !userEmail) {
      navigate('/login', { replace: true });
    }

    if (paramEmail) {
      setUserEmail(paramEmail.split('?')[0]);
    }
  }, []);

  const handleSubmitSuccess = async ({
    newPassword,
    otpCode = '',
  }: IPasswordResetForm) => {
    try {
      setLoading(true);
      await Auth.forgotPasswordSubmit(userEmail, otpCode, newPassword);
      setLoading(false);
      navigate('../complete', { replace: true });
    } catch (e: any) {
      setLoading(false);
      switch (e.name) {
        case 'CodeMismatchException':
          showToastMessage({
            status: 'error',
            title: 'Error',
            description: incorrectOTP,
            isClosable: true,
            position: 'bottom-right',
          });
          break;
        case 'ExpiredCodeException':
          setShowResendOtp(true);
          break;
        default:
          showToastMessage({
            status: 'error',
            title: 'Error',
            description: e.message,
            isClosable: true,
            position: 'bottom-right',
          });
          break;
      }
      console.error(e);
    }
  };

  const handleResetPassword = async () => {
    try {
      if (userEmail) {
        setResendOTPLoading(true);
        await Auth.forgotPassword(userEmail);
        showToastMessage({
          status: 'success',
          title: 'Success',
          description: newOTPSent,
          isClosable: true,
          position: 'bottom-right',
        });
        setShowResendOtp(false);
      }
    } catch (e: any) {
      console.error(e);
      showToastMessage({
        status: 'error',
        title: 'Error',
        description: e?.message,
        isClosable: true,
        position: 'bottom-right',
      });
    }
    setResendOTPLoading(false);
  };

  return (
    <>
      <CardPageBackButton onClick={() => navigate('/login/trouble')} />
      <CardPageHeading>{title}</CardPageHeading>
      <CardPageIllustration illustration={'ResetPassword'} />
      <CardPageSubtitle>
        {i18n.formatString(subtitle, {
          email: <strong>{userEmail}</strong>,
          break: (
            <>
              <br />
              <br />
            </>
          ),
        })}
      </CardPageSubtitle>
      <CardPageBody>
        <PasswordResetForm
          onSubmit={[handleSubmitSuccess]}
          newPasswordContent={{
            placeholder: i18n.forms.labels.newPassword,
            label: i18n.forms.labels.newPassword,
          }}
          confirmPasswordContent={{
            placeholder: i18n.forms.labels.confirmPassword,
            label: i18n.forms.labels.confirmPassword,
          }}
          otpCodeContent={{
            placeholder: i18n.forms.labels.enterOTP,
            label: i18n.forms.labels.enterOTP,
            error: showResendOtp ? expiredOTP : '',
          }}
          autoFocusField="otpCode"
          hasOTP
        />
        {showResendOtp && (
          <Button
            mt={4}
            data-testid="resend-otp-cta"
            variant="outline"
            isLoading={resendOTPLoading}
            onClick={handleResetPassword}
          >
            {resendOTPCta}
          </Button>
        )}
      </CardPageBody>
      <CardPageCTA>
        <CTAButton isLoading={loading} type="submit" form="passwordResetForm">
          {cta}
        </CTAButton>
      </CardPageCTA>
    </>
  );
};
