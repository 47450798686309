import { ComponentStyleConfig } from '@chakra-ui/react';

import { textStyles } from '../../common';

export const accordionStyles: Partial<ComponentStyleConfig> = {
  baseStyle: (props: any) => {
    const layerMap: Map<string, string> = new Map([
      ['base', 'layer.1'],
      ['first', 'layer.1'],
      ['second', 'layer.2'],
      ['third', 'layer.1'],
    ]);
    return {
      container: {
        bgColor: 'layer.0',
        _hover: {
          bgColor: layerMap.get(props.layerStyle ?? 'base'),
        },
      },
      button: {
        ...textStyles['body-md'],
      },
    };
  },
  sizes: {
    sm: {
      button: {
        py: 2, // 8px
        px: 4, // 16px
        minH: 8, // 32px
        ...textStyles['body-sm'],
      },
    },
    md: {
      button: {
        py: 2, // 8px
        px: 4, // 16px
        minH: 9, // 36px
        ...textStyles['body-md'],
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};
