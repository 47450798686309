import { OrgTenantsContext } from '@gamma/investigator/context';
import { useManualPagination } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { useGetUserLogs } from '@gamma/investigator/queries';
import {
  Board,
  Column,
  FlushPanelContent,
  Panel,
  PanelHeader,
  PanelHeading,
} from '@gamma/layout';
import moment from 'moment';
import { useContext, useMemo, useState } from 'react';

import { SecurityAuditTable } from './SecurityAuditTable';
import { SecurityAuditToolbar } from './SecurityAuditToolbar';
import { DateRange, Filters } from './types';

export interface Logs {
  event_category: string;
  message: string;
  timestamp: number;
  user_id: string;
  audit_type: string;
  tenant: string;
  tenant_display_name: string;
}

export const SecurityAudit = () => {
  const { orgTenantsQueryParam } = useContext(OrgTenantsContext);
  const date = new Date();

  const startDate = moment(date.setDate(date.getDate() - 7))
    .utc()
    .unix()
    .toString();
  const endDate = moment(Date.now()).utc().unix().toString();

  const { title } = i18n.pages.securityAudit;
  const [filters, setFilters] = useState<Filters>({});
  const [dateRange, setDateRange] = useState<DateRange>({ startDate, endDate });

  const {
    offset,
    sortBy,
    pageSize,
    pageIndex,
    pageCount,
    onFetchData,
    getPageCount,
    getSortsForAPI,
  } = useManualPagination({
    sortBy: [{ id: 'timestamp', desc: true }],
  });

  const sorts = useMemo(
    () => [...getSortsForAPI(sortBy)],
    [getSortsForAPI, sortBy],
  );

  const variables = useMemo(() => {
    return {
      input: {
        filters,
        ...dateRange,
        offset,
        size: pageSize,
        sort: sorts,
        tenants: orgTenantsQueryParam,
      },
    };
  }, [filters, dateRange, offset, pageSize, sorts, orgTenantsQueryParam]);

  const { data, loading, error } = useGetUserLogs({
    variables,
    fetchPolicy: 'network-only',
    onCompleted: (res: any) => {
      getPageCount(res?.getUserLogs);
    },
  });

  const logs: Logs[] = useMemo(
    () =>
      data?.getUserLogs?.log.map((item) => {
        const log = JSON.parse(item?.message);
        return {
          ...log,
          tenant_display_name: item?.tenant_info?.tenant_display_name,
        };
      }) ?? [],
    [data?.getUserLogs?.log],
  );

  return (
    <Board data-testid="audit-logs-page">
      <Column>
        <Panel>
          <SecurityAuditToolbar
            getUserLogsLoading={loading}
            setDateRange={setDateRange}
            setFilters={setFilters}
            filters={filters}
          />
        </Panel>
      </Column>
      <Column>
        <Panel>
          <PanelHeader divider>
            <PanelHeading>{title}</PanelHeading>
          </PanelHeader>
          <FlushPanelContent>
            <SecurityAuditTable
              loading={loading}
              error={error}
              logs={logs}
              pageCount={pageCount}
              pageIndex={pageIndex}
              sortBy={sortBy}
              onFetchData={onFetchData}
              pageSize={pageSize}
              itemCount={data?.getUserLogs?.total_items || 0}
            />
          </FlushPanelContent>
        </Panel>
      </Column>
    </Board>
  );
};
