import { createIcon } from '@chakra-ui/react';

export const ArrowBackIcon = createIcon({
  displayName: 'ArrowBackIcon',
  viewBox: '0 0 24 24',
  d: 'M20 11v2H8l5.5 5.5-1.42 1.42L4.16 12l7.92-7.92L13.5 5.5 8 11h12Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
