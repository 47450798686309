import { ApolloError, useApolloClient } from '@apollo/client';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
  VStack,
  usePrevious,
} from '@chakra-ui/react';
import { IPill, MuiIcon, PillBox } from '@gamma/display';
import { AsyncSelect, Checkbox, Select } from '@gamma/form-fields';
import { SeverityScore } from '@gamma/investigator/components';
import { ROUTES } from '@gamma/investigator/constants';
import { AuthContext, OrgTenantsContext } from '@gamma/investigator/context';
import {
  useDateRangeContext,
  useDateRangeQueryString,
} from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  Detection,
  IQueryDetectionsPaginated,
  QUERY_DETECTIONS_ENTITIES_SUGGESTIONS,
  QUERY_DETECTIONS_FIELD_SUGGESTIONS,
  User,
  useListUsers,
  useQueryDetectionsPaginated,
} from '@gamma/investigator/queries';
import { debounce, isEqual, uniqWith } from 'lodash';
import moment from 'moment';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { SortingRule } from 'react-table';

interface SelectOption {
  label: string;
  value: string;
}

type EntityType = 'source' | 'destination';

interface DetectionsFormData {
  assignee: SelectOption[];
  alertCategory: SelectOption[];
  source: SelectOption[];
  destination: SelectOption[];
  openStatus: boolean | string;
  closedStatus: boolean | string;
  severityRange: null | string;
}

const { controls, detection, reset } = i18n.pages.detections;

const {
  filters,
  closed,
  alertCategory,
  searchCategory,
  searchAssignee,
  source: sourceText,
  destination: destinationText,
  searchSource,
  searchDestination,
  noResults,
  adjustSearch,
  severityScore,
} = controls;

const { open, status: statusLabel, assignee: assigneeLabel } = detection;

const { detections } = ROUTES;

const OPEN_STATUS = '{"detection_status":{"value":"open"}}';
const CLOSED_STATUS = '{"detection_status":{"value":"closed"}}';

const defaultFilterValues: DetectionsFormData = {
  openStatus: OPEN_STATUS,
  closedStatus: false,
  assignee: [],
  alertCategory: [],
  source: [],
  destination: [],
  severityRange: null,
};

interface DetectionsFiltersProps {
  size: number;
  offset: number;
  getPageCount: any;
  getSortsForAPI: (
    sortBy: SortingRule<Detection>[],
  ) => { sort_by: string; sort_dir: string }[];
  formatSort: (value: string) => { id: string; desc: boolean }[];
  setQueryVariables: React.Dispatch<React.SetStateAction<any>>;
  setDetectionsData: React.Dispatch<
    React.SetStateAction<IQueryDetectionsPaginated | undefined>
  >;
  setDetectionsError: React.Dispatch<
    React.SetStateAction<ApolloError | undefined>
  >;
  setDetectionsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DetectionsFilters = memo(
  ({
    size,
    offset,
    formatSort,
    getPageCount,
    getSortsForAPI,
    setQueryVariables,
    setDetectionsData,
    setDetectionsError,
    setDetectionsLoading,
  }: DetectionsFiltersProps) => {
    const client = useApolloClient();
    const { start, end } = useDateRangeQueryString();

    const { user } = useContext(AuthContext);

    const { orgTenantsQueryParam } = useContext(OrgTenantsContext);
    const hasOrgTenants =
      orgTenantsQueryParam && orgTenantsQueryParam?.length > 0;

    const { search } = useLocation();
    const navigate = useNavigate();
    const params = useMemo(() => new URLSearchParams(search), [search]);

    const [searchParams, setSearchParams] = useSearchParams();

    const { dateRange } = useDateRangeContext();
    const contextEnd = moment().unix();
    const contextStart = dateRange
      ? moment().subtract(dateRange[0], dateRange[1]).unix()
      : undefined;

    const alert_category = params.get('alert_category');
    const source = params.get('source_name');
    const destination = params.get('destination_name');
    const open_status = params.get('open_status');
    const closed_status = params.get('closed_status');
    const assignee = params.get('assignee');
    const sort_by = params.get('sort_by');
    const date_start = params.get('start');
    const date_end = params.get('end');
    const severity_range = params.get('severity_range');
    const refetch_main = params.get('refetch_main');

    const [categorySuggestions, setCategorySuggestions] = useState(
      JSON.parse(localStorage.getItem('category_suggestions') || '[]'),
    );
    const [sourceSuggestions, setSourceSuggestions] = useState(
      JSON.parse(localStorage.getItem('source_suggestions') || '[]'),
    );
    const [destinationSuggestions, setDestinationSuggestions] = useState(
      JSON.parse(localStorage.getItem('destination_suggestions') || '[]'),
    );

    const { control, register, setValue, watch } = useForm<DetectionsFormData>({
      mode: 'onSubmit',
      defaultValues: defaultFilterValues,
    });

    const formData = watch();

    const {
      loading: listUsersLoading,
      error: listUsersError,
      data: { listUsers = [] } = {},
    } = useListUsers({
      skip: !user?.attributes['custom:tenant_id'],
      variables: {
        tenants: [`${user?.attributes['custom:tenant_id']}`],
        groups: ['admin', 'analyst'],
      },
    });

    useEffect(() => {
      if (alert_category) {
        setValue(
          'alertCategory',
          alert_category
            ? alert_category
                ?.split('&alert_category=')
                ?.map((category: string) => ({
                  label: category,
                  value: category,
                }))
            : [],
        );
      }
      if (source) {
        setValue(
          'source',
          source
            ? source.split('&source_name=')?.map((entity: string) => ({
                label: entity,
                value: entity,
              }))
            : [],
        );
      }
      if (destination) {
        setValue(
          'destination',
          destination
            ? destination
                .split('&destination_name=')
                ?.map((entity: string) => ({
                  label: entity,
                  value: entity,
                }))
            : [],
        );
      }
      if (open_status === 'true') {
        setValue('openStatus', OPEN_STATUS);
      }
      if (closed_status === 'true') {
        setValue('closedStatus', CLOSED_STATUS);
      }
      if (severity_range) {
        setValue('severityRange', severity_range);
      }
    }, [
      sort_by,
      alert_category,
      closed_status,
      source,
      destination,
      open_status,
      setValue,
      severity_range,
    ]);

    useEffect(() => {
      if (!date_end || !date_start) {
        params.set('start', String(contextStart));
        params.set('end', String(contextEnd));
        navigate(
          {
            pathname: detections,
            search: params.toString(),
          },
          {
            replace: true,
          },
        );
      }
    }, [contextEnd, contextStart, date_end, date_start, navigate]);

    useEffect(() => {
      const categories = formData?.alertCategory
        ?.map((category) => category.value)
        .join('&alert_category=');
      if (categories) {
        params.set('alert_category', categories);
      } else {
        params.delete('alert_category');
      }
      navigate(
        {
          pathname: detections,
          search: params.toString(),
        },
        {
          replace: true,
        },
      );
    }, [formData?.alertCategory, navigate]);

    useEffect(() => {
      const entities = formData?.source
        ?.map((entity) => entity.value)
        .join('&source_name=');
      if (entities) {
        params.set('source_name', entities);
      } else {
        params.delete('source_name');
      }
      navigate(
        {
          pathname: detections,
          search: params.toString(),
        },
        {
          replace: true,
        },
      );
    }, [formData?.source, navigate]);

    useEffect(() => {
      const entities = formData?.destination
        ?.map((entity) => entity.value)
        .join('&destination_name=');
      if (entities) {
        params.set('destination_name', entities);
      } else {
        params.delete('destination_name');
      }
      navigate(
        {
          pathname: detections,
          search: params.toString(),
        },
        {
          replace: true,
        },
      );
    }, [formData?.destination, navigate]);

    useEffect(() => {
      if (assignee && listUsers?.length > 0) {
        const paramAssignees = assignee?.split('&assignee=');
        const assignees = listUsers?.filter((user) =>
          paramAssignees.includes(user.username) ? user : null,
        );
        if (assignees.length > 0) {
          setValue(
            'assignee',
            assignees?.map((assignee: User) => ({
              label: assignee?.alias,
              value: assignee?.username,
            })),
          );
        }
      }
    }, [assignee, listUsers, setValue]);

    useEffect(() => {
      const assignees = formData?.assignee
        ?.map((assignee) => assignee.value)
        .join('&assignee=');
      if (assignees) {
        params.set('assignee', assignees);
      } else {
        if (!listUsersLoading) {
          params.delete('assignee');
        }
      }
      navigate(
        {
          pathname: detections,
          search: params.toString(),
        },
        {
          replace: true,
        },
      );
    }, [formData?.assignee, navigate]);

    useEffect(() => {
      const openStatus = formData?.openStatus;
      if (openStatus) {
        params.set('open_status', 'true');
      } else {
        params.delete('open_status');
      }
      const closedStatus = formData?.closedStatus;
      if (closedStatus) {
        params.set('closed_status', 'true');
      } else {
        params.delete('closed_status');
      }
      navigate(
        {
          pathname: detections,
          search: params.toString(),
        },
        {
          replace: true,
        },
      );
    }, [formData?.openStatus, formData?.closedStatus, navigate]);

    const setSeverityRangeParams = useCallback(
      debounce((severityRange: string) => {
        params.set('severity_range', severityRange);
        navigate(
          {
            pathname: detections,
            search: params.toString(),
          },
          {
            replace: true,
          },
        );
      }, 200),
      [],
    );

    useEffect(() => {
      const severityRange = formData?.severityRange;
      if (severityRange) {
        setSeverityRangeParams(severityRange);
      } else {
        params.delete('severity_range');
        navigate(
          {
            pathname: detections,
            search: params.toString(),
          },
          {
            replace: true,
          },
        );
      }
    }, [formData?.severityRange, navigate]);

    const [pills, setPills] = useState<IPill[] | []>([]);
    const [previousFormData, setPreviousFormData] =
      useState<DetectionsFormData | null>(null);

    const buildPills = useCallback(() => {
      const newPills: IPill[] = [];

      Object.keys(formData).forEach((key) => {
        if (key === 'openStatus') {
          if (formData[key]) {
            const pill: IPill = {
              label: open,
              value: key,
            };
            newPills.push(pill);
          }
        }

        if (key === 'closedStatus') {
          if (formData[key]) {
            const pill: IPill = {
              label: closed,
              value: key,
            };
            newPills.push(pill);
          }
        }

        if (key === 'assignee' || key === 'alertCategory') {
          if (formData[key].length > 0) {
            formData[key]?.forEach((assignee) => {
              const pill: IPill = {
                label: assignee.label,
                value: assignee.value,
              };
              newPills.push(pill);
            });
          }
        }
        if (key === 'source' || key === 'destination') {
          if (formData[key].length > 0) {
            formData[key]?.forEach((entity) => {
              const pill: IPill = {
                label: (
                  <Text textTransform="capitalize">
                    {`${key}: ${entity.label}`}
                  </Text>
                ),
                value: entity.value,
              };
              newPills.push(pill);
            });
          }
        }
        if (key === 'severityRange') {
          const formDataValue = formData[key];
          if (
            formDataValue ||
            formDataValue !== defaultFilterValues.severityRange
          ) {
            if (typeof formDataValue === 'string') {
              const range = JSON.parse(formDataValue);
              const pill: IPill = {
                label: `${range[0]}-${range[1]}`,
                value: key,
              };
              newPills.push(pill);
            }
          }
        }

        setPills(newPills);
      });
    }, [formData, pills]);

    const handleOnPillClose = (pill: IPill, pillIndex: number) => {
      const { value } = pill;

      if (value.includes('openStatus')) {
        // set to false because defaultFilterValues.openStatus has it enabled
        setValue('openStatus', false);
      }

      if (value.includes('closedStatus')) {
        setValue('closedStatus', defaultFilterValues.closedStatus);
      }

      if (value.includes('severityRange')) {
        setValue('severityRange', defaultFilterValues.severityRange);
      }

      const isInAssigneeList = formData.assignee.some(
        (assignee) => assignee.value === value,
      );

      const isInAlertCategoryList = formData.alertCategory.some(
        (category) => category.value === value,
      );

      const isInSourceList = formData.source.some(
        (entity) => entity.value === value,
      );

      const isInDestinationList = formData.destination.some(
        (entity) => entity.value === value,
      );

      if (isInAssigneeList) {
        const newAssigneeList = formData.assignee.filter(
          (assignee) => assignee.value !== value,
        );
        setValue('assignee', newAssigneeList);
      }

      if (isInAlertCategoryList) {
        const newAlertCategoryList = formData.alertCategory.filter(
          (category) => category.value !== value,
        );
        setValue('alertCategory', newAlertCategoryList);
      }

      if (isInSourceList) {
        const newEntityList = formData.source.filter(
          (entity) => entity.value !== value,
        );
        setValue('source', newEntityList);
      }
      if (isInDestinationList) {
        const newEntityList = formData.destination.filter(
          (entity) => entity.value !== value,
        );
        setValue('destination', newEntityList);
      }
    };

    const onFilterClear = () => {
      setValue('openStatus', defaultFilterValues.openStatus);
      setValue('closedStatus', defaultFilterValues.closedStatus);
      setValue('alertCategory', defaultFilterValues.alertCategory);
      setValue('source', defaultFilterValues.source);
      setValue('destination', defaultFilterValues.destination);
      setValue('assignee', defaultFilterValues.assignee);
      setValue('severityRange', defaultFilterValues.severityRange);
      buildPills();
    };

    const filtersChanged = () => {
      return !isEqual({ ...formData }, { ...defaultFilterValues });
    };

    useEffect(() => {
      if (!isEqual(previousFormData, formData)) {
        setPreviousFormData(formData);
        buildPills();
      }
    }, [formData, previousFormData, buildPills]);

    const wrapQuery = (conditions: string) =>
      `{"query":{"bool":{"must":[${conditions}]}}}`;

    const wrapSuggestionsQuery = (conditions: string) =>
      `{"query":{"bool":{"must":[${conditions}]}}}`;

    const statusQueryFilters = Object.keys(formData).reduce(
      (acc: string[], curr: string) => {
        if (!formData[curr as keyof DetectionsFormData]) {
          return acc;
        }

        if (curr === 'openStatus') {
          acc.push(OPEN_STATUS);
        }

        if (curr === 'closedStatus') {
          acc.push(CLOSED_STATUS);
        }

        return acc;
      },
      [],
    );

    const assigneeList = formData.assignee.map((assignee) => assignee?.value);

    const assignees = [...assigneeList].filter((assignee) => !!assignee);

    const severityRange = formData.severityRange
      ? JSON.parse(formData.severityRange)
      : [];

    const statusQueryFiltersData =
      statusQueryFilters.length > 0
        ? `{"bool":{"should":[${statusQueryFilters
            .map((status) => `{"term":${status}}`)
            .join(',')}]}}`
        : '';

    const severityRangeData =
      severityRange.length > 0
        ? `{"bool":{"should":[{"range":{"rank.severity":{"gte":${severityRange[0]},"lte":${severityRange[1]}}}}]}}`
        : '';

    const assigneesData =
      assignees.length > 0
        ? `{"bool":{"should":[${assignees.map(
            (assignee) =>
              `{"term":{"assignment_info.assigned_to_username.keyword":{"value":"${assignee}"}}}`,
          )}]}}`
        : '';
    const hasOrgTenantsData = hasOrgTenants
      ? `{"terms":{"tenant":${JSON.stringify(orgTenantsQueryParam)}}}`
      : '';

    const sharedConditionsArray = [
      `{"range":{"total_alert_count":{"gt":0}}}`,
      `{"range":{"earliest_start_timestamp":{"lte":${end}}}}`,
      `{"range":{"latest_start_timestamp":{"gte":${start}}}}`,
      statusQueryFiltersData,
      severityRangeData,
      assigneesData,
      hasOrgTenantsData,
    ];

    const sharedConditions = `{"range":{"total_alert_count":{"gt":0}}},{"range":{"earliest_start_timestamp":{"lte":${end}}}},{"range":{"latest_start_timestamp":{"gte":${start}}}}${
      statusQueryFilters.length > 0 || severityRange.length > 0 ? ',' : ''
    }${
      statusQueryFilters.length > 0
        ? `{"bool":{"should":[${statusQueryFilters
            .map((status) => `{"term":${status}}`)
            .join(',')}]}}`
        : ''
    }${statusQueryFilters.length > 0 && severityRange.length > 0 ? ',' : ''}${
      severityRange.length > 0
        ? `{"bool":{"should":[{"range":{"rank.severity":{"gte":${severityRange[0]},"lte":${severityRange[1]}}}}]}}`
        : ''
    }${assignees.length > 0 ? ',' : ''}${
      assignees.length > 0
        ? `{"bool":{"should":[${assignees.map(
            (assignee) =>
              `{"term":{"assignment_info.assigned_to_username.keyword":{"value":"${assignee}"}}}`,
          )}]}}`
        : ''
    }${hasOrgTenants ? ',' : ''}${
      hasOrgTenants
        ? `{"terms":{"tenant":${JSON.stringify(orgTenantsQueryParam)}}}`
        : ''
    }`;

    const alertCategoryData =
      formData.alertCategory.length > 0
        ? `{"bool":{"should":[${formData.alertCategory
            ?.map((category) =>
              category?.value !== ''
                ? `{"term":{"alert_info.alert_name.keyword":{"value":"${category?.value}"}}}`
                : '',
            )
            .join(',')}]}}`
        : '';

    const sourceData =
      formData.source.length > 0
        ? `{"terms":{"_search_keys.source_entities.keyword":${JSON.stringify(
            formData.source.map((entity) => entity.value),
          )}}}`
        : '';

    const destinationData =
      formData.destination.length > 0
        ? `{"terms":{"_search_keys.destination_entities.keyword":${JSON.stringify(
            formData.destination.map((entity) => entity.value),
          )}}}`
        : '';

    const conditions = `${sharedConditions}${
      alertCategoryData && ','
    }${alertCategoryData}${sourceData && ','}${sourceData}${
      destinationData && ','
    }${destinationData}`;

    const [query, setQuery] = useState<string>('');

    useEffect(() => {
      if (hasOrgTenants) {
        setQuery(wrapQuery(conditions));
      }
    }, [formData, hasOrgTenants]);

    const variables = useMemo(() => {
      if (hasOrgTenants) {
        setQueryVariables({
          query,
          size,
          offset,
          sortBy: sort_by ?? '',
        });
      }
      return {
        query,
        size,
        offset,
        sortBy: sort_by ?? '',
      };
    }, [query, size, offset, sort_by, hasOrgTenants, setQueryVariables]);

    const [fetchData, { called, loading, data, error, refetch }] =
      useQueryDetectionsPaginated({
        variables: {
          query: variables.query,
          size: variables.size,
          offset: variables.offset,
          sort: getSortsForAPI(formatSort(variables.sortBy)),
        },
        onCompleted: (data) => {
          setDetectionsData(data);
          getPageCount(data?.queryDetectionsPaginated);
        },
      });

    useEffect(() => {
      if (end && start && hasOrgTenants && variables?.query) {
        fetchData();
      }
    }, [end, start, variables, fetchData, hasOrgTenants]);

    useEffect(() => {
      setDetectionsError(error);
      setDetectionsLoading(loading);
    }, [error, loading, setDetectionsError, setDetectionsLoading]);

    const getEntitiesSuggestions = async (
      inputValue: string,
      entityCategory: EntityType = 'source',
    ) => {
      const additionalConditions =
        entityCategory === 'source' ? destinationData : sourceData;
      const { data } = await client.query({
        fetchPolicy: 'network-only',
        query: QUERY_DETECTIONS_ENTITIES_SUGGESTIONS,
        variables: {
          entity_name_prefix: inputValue,
          entity_categories: [entityCategory],
          must_conds: [
            ...sharedConditionsArray,
            additionalConditions,
            alertCategoryData,
          ].filter((condition) => condition),
          size: 10,
        },
      });
      const suggestions = data?.queryDetectionsEntitiesSuggestions[
        `${entityCategory}_entities`
      ].map((suggestion: string) => {
        return {
          label: suggestion,
          value: suggestion,
        };
      });
      return suggestions;
    };

    const getSuggestions = async (inputValue: string, matchValue: string) => {
      const { data } = await client.query({
        fetchPolicy: 'network-only',
        query: QUERY_DETECTIONS_FIELD_SUGGESTIONS,
        variables: {
          query: wrapSuggestionsQuery(
            `${sharedConditions},{"match_phrase":{"${matchValue}":{"query":"${inputValue}","analyzer":"standard"}}}`,
          ),
          collapse_field: `${matchValue}.keyword`,
          size: 10,
          sort: [
            {
              sort_by: `${matchValue}.keyword`,
              sort_dir: 'asc',
            },
          ],
        },
      });
      const suggestions = data?.queryDetectionsFieldSuggestions?.map(
        (suggestion: string) => {
          return {
            label: suggestion,
            value: suggestion,
          };
        },
      );
      return suggestions;
    };

    const [filtersOpen, setFiltersOpen] = useState<boolean>(
      localStorage.getItem('detections-filters-open') === 'true' || false,
    );

    const handleToggleFilters = () => {
      localStorage.setItem('detections-filters-open', String(!filtersOpen));
      setFiltersOpen(!filtersOpen);
    };

    useEffect(() => {
      if (refetch_main) {
        if (start && end && variables?.query) {
          refetch();
        }
        setSearchParams((params) => {
          params.delete('refetch_main');
          return params;
        });
      }
    }, [refetch, refetch_main, setSearchParams, start, end, variables]);

    const prevData = usePrevious(data);
    useEffect(() => {
      if (data !== prevData) {
        setDetectionsData(data);
      }
    }, [data, prevData, setDetectionsData]);

    return (
      <Accordion
        allowToggle
        onChange={handleToggleFilters}
        defaultIndex={filtersOpen ? 0 : undefined}
      >
        <AccordionItem
          layerStyle="first"
          borderRadius="base"
          borderColor="border.layer.1"
        >
          <h2>
            <AccordionButton
              px={4}
              py={3}
              _hover={{
                bg: 'inherit',
              }}
            >
              <Box as="span" flex="1" textAlign="left" textStyle="h5">
                {filters}
              </Box>
              <AccordionIcon my={-1} />
            </AccordionButton>
          </h2>
          <Flex px={4} pb={4} w="100%">
            <PillBox
              pills={pills}
              layerStyle="first"
              colorScheme="gray"
              clearButtonText={reset}
              onClearAll={onFilterClear}
              panelCustomStyles={{
                border: 0,
                padding: 0,
                minHeight: '26px',
              }}
              onPillClose={(pill, index) => handleOnPillClose(pill, index)}
              shouldShowClearAllButton={filtersChanged()}
            />
          </Flex>
          <AccordionPanel p={0}>
            <VStack pb={4} as="form" spacing={3} alignItems="start">
              <HStack
                pt={2}
                px={4}
                w="100%"
                spacing={4}
                borderTop="1px solid"
                alignItems="start"
                borderColor="border.layer.1"
              >
                <Box w="275px">
                  <VStack alignItems="start">
                    <Text>{severityScore}</Text>
                    <Flex w="100%">
                      <Controller
                        name="severityRange"
                        control={control}
                        render={({ field: { name, value, onChange } }) => {
                          return (
                            <RangeSlider
                              min={1}
                              max={10}
                              step={1}
                              name={name}
                              value={value ? JSON.parse(value) : [1, 10]}
                              onChange={async (selectedValues) => {
                                await onChange(JSON.stringify(selectedValues));
                              }}
                            >
                              <RangeSliderTrack bg="text.secondary">
                                <RangeSliderFilledTrack bg="blue.500" />
                              </RangeSliderTrack>
                              <RangeSliderThumb
                                bg="blue.500"
                                boxSize={3.5}
                                index={0}
                              />
                              <RangeSliderThumb
                                bg="blue.500"
                                boxSize={3.5}
                                index={1}
                              />
                            </RangeSlider>
                          );
                        }}
                      />
                    </Flex>
                    <Flex w="100%" justifyContent="space-between">
                      <SeverityScore
                        score={1}
                        size="sm"
                        isButton={false}
                        customStyles={{
                          padding: 0,
                          width: '20px',
                          height: '20px',
                        }}
                      >
                        1
                      </SeverityScore>
                      <SeverityScore
                        score={5}
                        size="sm"
                        isButton={false}
                        customStyles={{
                          padding: 0,
                          width: '20px',
                          height: '20px',
                          marginRight: '32px',
                        }}
                      >
                        5
                      </SeverityScore>
                      <SeverityScore
                        score={10}
                        size="sm"
                        isButton={false}
                        customStyles={{
                          padding: 0,
                          width: '20px',
                          height: '20px',
                        }}
                      >
                        10
                      </SeverityScore>
                    </Flex>
                  </VStack>
                </Box>
                <VStack alignItems="start">
                  <Text>{statusLabel}</Text>
                  <HStack>
                    <Controller
                      name="openStatus"
                      control={control}
                      render={() => (
                        <Checkbox
                          {...register('openStatus')}
                          data-testid="detection-status-open"
                          value={OPEN_STATUS}
                          isChecked={!!formData.openStatus}
                        >
                          <Text>{open}</Text>
                        </Checkbox>
                      )}
                    />
                    <Controller
                      name="closedStatus"
                      control={control}
                      render={() => (
                        <Checkbox
                          {...register('closedStatus')}
                          data-testid="detection-status-closed"
                          value={CLOSED_STATUS}
                          isChecked={!!formData.closedStatus}
                        >
                          <Text>{closed}</Text>
                        </Checkbox>
                      )}
                    />
                  </HStack>
                </VStack>
              </HStack>
              <HStack px={4} spacing={4}>
                <Box w="275px">
                  <Controller
                    name={'alertCategory'}
                    control={control}
                    render={({ field: { name, value } }) => {
                      return (
                        <AsyncSelect
                          data-testid="detection-alert-category-async-select"
                          name={name}
                          cacheOptions
                          isMenuPortal={true}
                          value={value}
                          leftElement={<MuiIcon size="sm">search</MuiIcon>}
                          onChange={(selectedValue) => {
                            const hasAlreadySelectedValue = value.some(
                              (item) =>
                                item.label ===
                                (selectedValue as SelectOption)?.label,
                            );

                            const hasAlertCategory =
                              (selectedValue as SelectOption)?.value !== ''
                                ? selectedValue
                                : null;

                            if (hasAlertCategory && !hasAlreadySelectedValue) {
                              setValue('alertCategory', [
                                ...value,
                                selectedValue,
                              ] as SelectOption[]);
                            }
                            const suggestions =
                              categorySuggestions[0]?.options?.length > 0
                                ? uniqWith(
                                    [
                                      selectedValue,
                                      ...categorySuggestions[0]?.options,
                                    ],
                                    isEqual,
                                  )
                                : [selectedValue];
                            if (suggestions.length > 10) {
                              suggestions.pop();
                            }
                            setCategorySuggestions([
                              {
                                label: 'Recent Searches',
                                options: suggestions,
                              },
                            ]);
                            localStorage.setItem(
                              'category_suggestions',
                              JSON.stringify([
                                {
                                  label: 'Recent Searches',
                                  options: suggestions,
                                },
                              ]),
                            );
                          }}
                          label={alertCategory}
                          placeholder={searchCategory}
                          loadOptions={async (event) => {
                            const loadedOptions = await getSuggestions(
                              event.length > 20 ? event.slice(0, 20) : event,
                              'alert_info.alert_name',
                            );
                            return loadedOptions;
                          }}
                          defaultOptions={categorySuggestions}
                          noOptionsMessage={
                            <Box px={2.5} textAlign="left">
                              <Text
                                mb={2.5}
                                textStyle="body-md"
                                fontWeight={600}
                              >
                                {noResults}
                              </Text>
                              <Text textStyle="body-md">{adjustSearch}</Text>
                            </Box>
                          }
                          components={{
                            DropdownIndicator: null,
                          }}
                        />
                      );
                    }}
                  />
                </Box>
                <Box w="275px">
                  <Controller
                    name={'source'}
                    control={control}
                    render={({ field: { name, value } }) => {
                      return (
                        <AsyncSelect
                          data-testid="detection-alert-source-async-select"
                          name={name}
                          cacheOptions
                          isMenuPortal
                          value={value}
                          leftElement={<MuiIcon size="sm">search</MuiIcon>}
                          width="100%"
                          onChange={(selectedValue) => {
                            const hasAlreadySelectedValue = value.some(
                              (entity) =>
                                entity.label ===
                                (selectedValue as SelectOption).label,
                            );
                            const hasEntity =
                              (selectedValue as SelectOption)?.value !== ''
                                ? selectedValue
                                : null;
                            if (hasEntity && !hasAlreadySelectedValue) {
                              setValue('source', [
                                ...value,
                                selectedValue,
                              ] as SelectOption[]);
                            }
                            const suggestions =
                              sourceSuggestions[0]?.options?.length > 0
                                ? uniqWith(
                                    [
                                      selectedValue,
                                      ...sourceSuggestions[0]?.options,
                                    ],
                                    isEqual,
                                  )
                                : [selectedValue];
                            if (suggestions.length > 10) {
                              suggestions.pop();
                            }
                            setSourceSuggestions([
                              {
                                label: 'Recent Searches',
                                options: suggestions,
                              },
                            ]);
                            localStorage.setItem(
                              'source_suggestions',
                              JSON.stringify([
                                {
                                  label: 'Recent Searches',
                                  options: suggestions,
                                },
                              ]),
                            );
                          }}
                          label={sourceText}
                          placeholder={searchSource}
                          loadOptions={async (event) => {
                            const loadedOptions = await getEntitiesSuggestions(
                              event.length > 20 ? event.slice(0, 20) : event,
                              'source',
                            );
                            return loadedOptions;
                          }}
                          defaultOptions={sourceSuggestions}
                          noOptionsMessage={
                            <Box px={2.5} textAlign="left">
                              <Text
                                mb={2.5}
                                textStyle="body-md"
                                fontWeight={600}
                              >
                                {noResults}
                              </Text>
                              <Text textStyle="body-md">{adjustSearch}</Text>
                            </Box>
                          }
                          components={{
                            DropdownIndicator: null,
                          }}
                        />
                      );
                    }}
                  />
                </Box>
                <Box w="275px">
                  <Controller
                    name={'destination'}
                    control={control}
                    render={({ field: { name, value } }) => {
                      return (
                        <AsyncSelect
                          data-testid="detection-alert-destination-async-select"
                          name={name}
                          cacheOptions
                          isMenuPortal
                          value={value}
                          leftElement={<MuiIcon size="sm">search</MuiIcon>}
                          width="100%"
                          onChange={(selectedValue) => {
                            const hasAlreadySelectedValue = value.some(
                              (entity) =>
                                entity.label ===
                                (selectedValue as SelectOption).label,
                            );
                            const hasEntity =
                              (selectedValue as SelectOption)?.value !== ''
                                ? selectedValue
                                : null;
                            if (hasEntity && !hasAlreadySelectedValue) {
                              setValue('destination', [
                                ...value,
                                selectedValue,
                              ] as SelectOption[]);
                            }
                            const suggestions =
                              destinationSuggestions[0]?.options?.length > 0
                                ? uniqWith(
                                    [
                                      selectedValue,
                                      ...destinationSuggestions[0]?.options,
                                    ],
                                    isEqual,
                                  )
                                : [selectedValue];
                            if (suggestions.length > 10) {
                              suggestions.pop();
                            }
                            setDestinationSuggestions([
                              {
                                label: 'Recent Searches',
                                options: suggestions,
                              },
                            ]);
                            localStorage.setItem(
                              'destination_suggestions',
                              JSON.stringify([
                                {
                                  label: 'Recent Searches',
                                  options: suggestions,
                                },
                              ]),
                            );
                          }}
                          label={destinationText}
                          placeholder={searchDestination}
                          loadOptions={async (event) => {
                            const loadedOptions = await getEntitiesSuggestions(
                              event.length > 20 ? event.slice(0, 20) : event,
                              'destination',
                            );
                            return loadedOptions;
                          }}
                          defaultOptions={destinationSuggestions}
                          noOptionsMessage={
                            <Box px={2.5} textAlign="left">
                              <Text
                                mb={2.5}
                                textStyle="body-md"
                                fontWeight={600}
                              >
                                {noResults}
                              </Text>
                              <Text textStyle="body-md">{adjustSearch}</Text>
                            </Box>
                          }
                          components={{
                            DropdownIndicator: null,
                          }}
                        />
                      );
                    }}
                  />
                </Box>
                <Box w="275px">
                  <Controller
                    name={'assignee'}
                    control={control}
                    render={({ field: { name, value } }) => {
                      return (
                        <Select
                          data-testid="detection-assignee-select"
                          name={name}
                          isLoading={listUsersLoading}
                          value={value}
                          leftElement={<MuiIcon size="sm">search</MuiIcon>}
                          options={
                            listUsers?.length > 0
                              ? listUsers
                                  .filter((user) => !!user.alias)
                                  .filter((user) => user.status !== 'invited')
                                  .sort((a, b) =>
                                    a?.alias.localeCompare(b?.alias),
                                  )
                                  .map((user) => {
                                    return {
                                      label: user?.alias,
                                      value: user?.username,
                                    };
                                  })
                              : []
                          }
                          isMenuPortal
                          isSearchable
                          noOptionsMessage={
                            <Box px={2.5} textAlign="left">
                              <Text textStyle="body-md" fontWeight={600}>
                                {noResults}
                              </Text>
                            </Box>
                          }
                          label={assigneeLabel}
                          onChange={(selectedValue) => {
                            const hasAlreadySelectedValue = value.some(
                              (item) =>
                                item.value ===
                                (selectedValue as SelectOption).value,
                            );
                            const hasSelectedValue =
                              (selectedValue as SelectOption)?.value !== ''
                                ? selectedValue
                                : null;

                            if (hasSelectedValue && !hasAlreadySelectedValue) {
                              setValue('assignee', [
                                ...value,
                                selectedValue,
                              ] as SelectOption[]);
                            }
                          }}
                          placeholder={searchAssignee}
                          components={{
                            DropdownIndicator: null,
                          }}
                        />
                      );
                    }}
                  />
                </Box>
              </HStack>
            </VStack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  },
);
