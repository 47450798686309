import { createIcon } from '@chakra-ui/react';

export const ConnectionIcon = createIcon({
  displayName: 'ConnectionIcon',
  viewBox: '0 0 24 24',
  d: 'M21 7a4 4 0 0 1-5.91 3.516l-4.574 4.575a4 4 0 1 1-1.365-1.464l4.476-4.476A4 4 0 1 1 21 7Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
