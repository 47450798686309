import { ComponentStyleConfig } from '@chakra-ui/react';
import { getColor, mode, transparentize } from '@chakra-ui/theme-tools';
import { textStyles } from '../../common';

export const InputStyles: ComponentStyleConfig = {
  baseStyle: (props: any) => {
    return {
      field: {
        bg: 'layer.1',
        backgroundColor: 'layer.1',
        borderRadius: 'base',
        boxShadow: mode(
          'inset 0px 2px 5px var(--chakra-colors-blackAlpha-50)',
          'inset 0px 2px 5px rgba(22, 22, 22, 0.5)',
        )(props),
        _hover: {
          bg: mode('gray.50', '#2a2a2a')(props),
          boxShadow: mode(
            'inset 0px 2px 5px var(--chakra-colors-blackAlpha-200)',
            'inset 0px 2px 5px rgba(22, 22, 22, 0.5)',
          )(props),
        },
        _readOnly: {
          bg: transparentize(
            mode('white', getColor(props.theme, 'gray.800'))(props),
            0.5,
          )(props),
          cursor: props.cursor ?? 'not-allowed',
        },
      },
    };
  },
  defaultProps: {
    errorBorderColor: 'red.500',
  },
  variants: {
    outline: (props: any) => {
      return {
        field: {
          borderColor: 'border.1',
          borderRadius: 'base',
          _invalid: {
            borderColor: 'state.error',
            boxShadow: `0 0 0 1px var(--chakra-colors-state-error)`,
          },
        },
        addon: {
          border: 'solid 1px',
          borderColor: 'border.1',
        },
      };
    },
  },
  sizes: {
    xs: {
      field: {
        ...textStyles['body-sm'],
        px: 2, // 8px
        py: 1, // 4px
        height: '24px',
      },
      addon: {
        ...textStyles['body-sm'],
        height: '24px',
      },
    },
    sm: {
      field: {
        ...textStyles['body-sm'],
        px: 2, // 8px
        py: 1.5, // 6px
        height: '28px',
      },
      addon: {
        ...textStyles['body-sm'],
        px: 2, // 8px
        py: 1.5, // 6px
        height: '28px',
      },
    },
    md: {
      field: {
        ...textStyles['body-md'],
        px: 2, // 8px
        py: 1.5, // 6px
        height: '32px',
      },
      addon: {
        ...textStyles['body-md'],
        px: 2, // 8px
        py: 1.5, // 6px
        height: '32px',
      },
    },
    lg: {
      field: {
        ...textStyles['body-md'],
        px: 3, // 12px
        py: 2.5, // 10px
        height: '40px',
      },
      addon: {
        ...textStyles['body-md'],
        px: 3, // 12px
        py: 2.5, // 10px
        height: '40px',
      },
    },
  },
};
