import { ComponentStyleConfig } from '@chakra-ui/react';

import { textStyles } from '../../common';

export const FormErrorStyles: ComponentStyleConfig = {
  baseStyle: {
    text: {
      color: 'state.error',
      ...textStyles['body-sm'],
      mt: 0,
    },
  },
};
