import { createIcon } from '@chakra-ui/react';

export const SingleGearIcon = createIcon({
  displayName: 'GearsIcon',
  viewBox: '0 0 24 24',
  path: (
    <path d="M10.0001 12.9167C9.22656 12.9167 8.4847 12.6094 7.93772 12.0624C7.39073 11.5154 7.08344 10.7735 7.08344 9.99999C7.08344 9.22644 7.39073 8.48458 7.93772 7.9376C8.4847 7.39061 9.22656 7.08332 10.0001 7.08332C10.7737 7.08332 11.5155 7.39061 12.0625 7.9376C12.6095 8.48458 12.9168 9.22644 12.9168 9.99999C12.9168 10.7735 12.6095 11.5154 12.0625 12.0624C11.5155 12.6094 10.7737 12.9167 10.0001 12.9167ZM16.1918 10.8083C16.2251 10.5417 16.2501 10.275 16.2501 9.99999C16.2501 9.72499 16.2251 9.44999 16.1918 9.16666L17.9501 7.80832C18.1084 7.68332 18.1501 7.45832 18.0501 7.27499L16.3834 4.39166C16.2834 4.20832 16.0584 4.13332 15.8751 4.20832L13.8001 5.04166C13.3668 4.71666 12.9168 4.43332 12.3918 4.22499L12.0834 2.01666C12.0501 1.81666 11.8751 1.66666 11.6668 1.66666H8.33344C8.12511 1.66666 7.95011 1.81666 7.91678 2.01666L7.60844 4.22499C7.08344 4.43332 6.63344 4.71666 6.20011 5.04166L4.12511 4.20832C3.94178 4.13332 3.71678 4.20832 3.61678 4.39166L1.95011 7.27499C1.84178 7.45832 1.89178 7.68332 2.05011 7.80832L3.80844 9.16666C3.77511 9.44999 3.75011 9.72499 3.75011 9.99999C3.75011 10.275 3.77511 10.5417 3.80844 10.8083L2.05011 12.1917C1.89178 12.3167 1.84178 12.5417 1.95011 12.725L3.61678 15.6083C3.71678 15.7917 3.94178 15.8583 4.12511 15.7917L6.20011 14.95C6.63344 15.2833 7.08344 15.5667 7.60844 15.775L7.91678 17.9833C7.95011 18.1833 8.12511 18.3333 8.33344 18.3333H11.6668C11.8751 18.3333 12.0501 18.1833 12.0834 17.9833L12.3918 15.775C12.9168 15.5583 13.3668 15.2833 13.8001 14.95L15.8751 15.7917C16.0584 15.8583 16.2834 15.7917 16.3834 15.6083L18.0501 12.725C18.1501 12.5417 18.1084 12.3167 17.9501 12.1917L16.1918 10.8083Z" />
  ),
  defaultProps: {
    height: '20px',
    width: '20px',
    viewBox: '0 0 20 20',
    fill: 'currentColor',
  },
});
