import { TenantMember } from '@gamma/investigator/queries';
import { createContext, Dispatch, SetStateAction } from 'react';

export interface IOrgTenantsContext {
  selectedOrgTenants: TenantMember[] | undefined;
  setSelectedOrgTenants?: Dispatch<SetStateAction<TenantMember[] | undefined>>;
  orgTenantsQueryParam: string[] | undefined;
}

export const OrgTenantsContext = createContext<IOrgTenantsContext>({
  selectedOrgTenants: undefined,
  setSelectedOrgTenants: () => false,
  orgTenantsQueryParam: undefined,
});
