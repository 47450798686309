import {
  Divider,
  Flex,
  HStack,
  IconButton,
  Stack,
  Text,
  chakra,
  useStyles,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { FileMetadata } from '../FileUploadBox';

export type FileListItemProps = {
  file: FileMetadata;
  ['data-testid']?: string;
  size?: 'sm' | 'md' | 'lg';
  index?: number;
  handleDelete: (file: File) => void;
};

export const FileListItem = ({
  file,
  'data-testid': dataTestId = 'file-upload-box',
  size,
  index = 0,
  handleDelete,
}: FileListItemProps) => {
  const styles = useStyles();
  const hasError = (file.validation?.length ?? 0) > 0;

  return (
    <chakra.li
      data-testid={`${dataTestId}-files-list-item`}
      __css={styles.listItem}
      borderColor={hasError ? 'state.error' : 'border.1'}
    >
      <Stack spacing={1}>
        <Flex justifyContent="space-between">
          <HStack>
            <MuiIcon
              data-testid="uploaded-file-icon"
              color={hasError ? 'state.error' : 'state.success'}
            >
              {hasError ? 'warning' : 'check_circle'}
            </MuiIcon>
            <Text
              data-testid="uploaded-file-name"
              color="text.secondary"
              title={file.name}
            >
              {file.shortName}
            </Text>
          </HStack>
          <IconButton
            onClick={() => handleDelete(file.file)}
            size="box"
            aria-label={`Delete File - ${file.name}`}
            aria-describedby={
              hasError ? `${file.name}-${index}-error-message` : undefined
            }
            icon={<MuiIcon color="text.secondary">close</MuiIcon>}
            data-testid="uploaded-file-delete-button"
          />
        </Flex>
        {hasError && (
          <>
            <Divider
              marginInlineStart={-2}
              marginInlineEnd={-2}
              w="calc(100% + 16px)"
            />
            {file.validation?.map((errorMessage, i) => (
              <Text
                key={i}
                color="red.300"
                data-testid="file-error-text"
                ml={7}
                id={`${file.name}-${index}-error-message`}
                role="alert"
              >
                {errorMessage}
              </Text>
            ))}
          </>
        )}
      </Stack>
    </chakra.li>
  );
};
