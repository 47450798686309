import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { IGetEDRConfiguration } from './types';

export const GET_EDR_CONFIGURATION = gql`
  query getEDRConfiguration(
    $provider_name: EDRConfigurationType!
    $tenant: String
  ) {
    getEDRConfiguration(provider_name: $provider_name, tenant: $tenant) {
      edr_action_enabled
      provider_name
      enabled
      polling_seconds
      ... on CrowdStrikeConfiguration {
        provider_name
        polling_seconds
        enabled
        edr_action_enabled
        client_id
        client_secret
        base_url
      }
    }
  }
`;

export const useGetEDRConfiguration = (
  options: QueryHookOptions<IGetEDRConfiguration, OperationVariables>,
) => useQuery<IGetEDRConfiguration>(GET_EDR_CONFIGURATION, options);
