import {
  Flex,
  FlexProps,
  StyleProps,
  StylesProvider,
  useStyles,
} from '@chakra-ui/react';
import {
  Children,
  isValidElement,
  ReactNode,
  useContext,
  useEffect,
} from 'react';

import { CardPageContext } from '../../CardPage';
import { CardPageIllustration } from '../CardPageIllustration';

export interface CardPageContentProps extends FlexProps, StyleProps {
  children: ReactNode;
  ['data-testid']?: string;
}

export const CardPageContent = ({
  children,
  'data-testid': dataTestId,
  ...rest
}: CardPageContentProps) => {
  const styles = useStyles();
  const { setHasIllustration } = useContext(CardPageContext);

  useEffect(() => {
    const childMap = Children.map(children, (child) => {
      if (isValidElement(child) && typeof child.type !== 'string') {
        return child.type.name === CardPageIllustration.name;
      }
      return false;
    }) as boolean[];
    setHasIllustration(childMap.some((value) => value));
  }, [children]);

  return (
    <Flex __css={styles.content} data-testid={dataTestId} {...rest}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Flex>
  );
};
