import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

import {
  IDetectionStatus,
  IDetectionUpdateInfoInput,
  ISetStatusOnDetections,
} from './types';

export const SET_DETECTION_STATUS = gql`
  mutation setStatusOnDetections($items: [SetStatusOnDetectionsInput!]!) {
    setStatusOnDetections(items: $items) {
      detections {
        alert_entity {
          entity_id
          entity_name
          entity_type
        }
        alert_info {
          alert_name
          alert_type
          content_id
        }
        assignment_info {
          assigned_to_username
          assigned_at
        }
        created_timestamp
        detection_id
        detection_status
        detection_timestamp {
          end
          start
        }
        earliest_start_timestamp
        latest_start_timestamp
        rank {
          severity
        }
        total_alert_count
        update_info {
          last_updated_by
          last_updated_timestamp
        }
        escalation_data {
          escalation_status
          escalated_by_user_alias
          escalated_timestamp
          escalation_service_name
        }
      }
      offset
      size
      total_items
    }
  }
`;

export interface ISetStatusOnDetectionsVariables extends OperationVariables {
  detection_id?: string;
  detection_status?: IDetectionStatus;
  tenant?: string;
  update_info?: IDetectionUpdateInfoInput;
}

export const useSetStatusOnDetections = (
  options: MutationHookOptions<
    ISetStatusOnDetections,
    ISetStatusOnDetectionsVariables
  >,
) => {
  return useMutation(SET_DETECTION_STATUS, options);
};
