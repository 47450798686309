import { ComponentStyleConfig } from '@chakra-ui/react';

export const MuiIconStyles: ComponentStyleConfig = {
  baseStyle: {
    fontFamily: 'icon',
    fontWeight: 500,
    fontVariationSettings: "'GRAD' -25, 'opsz' 20",
    textRendering: 'optimizeLegibility',
    MozOsxFontSmoothing: 'grayscale',
    WebkitFontSmoothing: 'antialiased',
    fontSmoothing: 'antialiased',
    display: 'inline-block',
    lineHeight: '1',
    overflow: 'visible',
    whiteSpace: 'pre',
    cursor: 'inherit',
    textDecoration: 'none !important',
  },
  sizes: {
    xs: {
      fontSize: '14px',
      width: '14px',
      height: '14px',
    },
    sm: {
      fontSize: '16px',
      width: '16px',
      height: '16px',
    },
    md: {
      fontSize: '20px',
      width: '20px',
      height: '20px',
    },
    lg: {
      fontSize: '24px',
      width: '24px',
      height: '24px',
    },
  },
};
