import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { DetectionTacticSummary } from './types';

export interface IGetDetectionMitreSummary {
  getDetectionMitreSummary: DetectionTacticSummary[];
}

export const GET_DETECTION_MITRE_SUMMARY = gql`
  query getDetectionMitreSummary(
    $start: Float
    $end: Float
    $must_conds: [String]
  ) {
    getDetectionMitreSummary(
      start: $start
      end: $end
      must_conds: $must_conds
    ) {
      mitre_attack_techniques {
        detection_count
        entity_count
        first_seen
        last_seen
        mitre_attack_tactic
        mitre_attack_tactic_priority
        mitre_attack_technique
        tenant
        total_detections
      }
      detection_count
      entity_count
      first_seen
      last_seen
      mitre_attack_tactic
      mitre_attack_tactic_priority
      tenant
      total_detections
    }
  }
`;

export const useGetDetectionMitreSummary = (
  options: QueryHookOptions<IGetDetectionMitreSummary, OperationVariables>,
) => useQuery<IGetDetectionMitreSummary>(GET_DETECTION_MITRE_SUMMARY, options);
