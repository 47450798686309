import { createIcon } from '@chakra-ui/react';

export const BundlesIcon = createIcon({
  displayName: 'BundlesIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H4Zm9.804 4.722L12 8l-5 2 1.804.722 5-2ZM10.15 11.26 12 12l5-2-1.85-.74-5 2ZM12 16l-5-2v-3l5 2 5-2v3l-5 2Z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
