import { Button } from '@chakra-ui/react';

import { ChatGPTToolTip } from '../ChatGPTToolTip';

export const ChatGPTQuestionPrompt = ({
  text,
  onClick,
  isDisabled,
  hasError,
}: {
  text: string;
  isDisabled?: boolean;
  hasError?: boolean;
  onClick?: (question: string, hasError: boolean) => void;
}) => {
  return (
    <Button
      {...(onClick &&
        !isDisabled && {
          onClick: () => onClick?.(text, Boolean(hasError)),
        })}
      px={2}
      height="auto"
      variant="pill"
      w="fit-content"
      colorScheme="gray"
      whiteSpace="initial"
      data-testid="chat-gpt-question-prompt"
      size="sm"
      leftIcon={<ChatGPTToolTip boxSize={4} />}
    >
      {text}
    </Button>
  );
};
