import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { TenantDomain } from './types';

export type IGetHumioTenentType = {
  getHumioDashboards: TenantDomain;
};

export interface IGetHumioTenentVariables extends OperationVariables {
  humioSessionToken: string;
}

export const TENENT_DASHBOARDS = gql`
  query getHumioDashboards($humioSessionToken: String!) {
    getHumioDashboards(humioSessionToken: $humioSessionToken) {
      searchDomain {
        dashboards {
          name
          id
        }
      }
    }
  }
`;

export const useGetHumioDashboards = (
  options?: QueryHookOptions<IGetHumioTenentType, IGetHumioTenentVariables>,
) => {
  return useQuery<IGetHumioTenentType, IGetHumioTenentVariables>(
    TENENT_DASHBOARDS,
    options,
  );
};
