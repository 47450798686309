import { createIcon } from '@chakra-ui/react';

export const ChevronDownIcon = createIcon({
  displayName: 'ChevronDownIcon',
  d: 'M7.41 8.58 12 13.17l4.59-4.59L18 10l-6 6-6-6 1.41-1.42Z',
  viewBox: '0 0 24 24',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
