import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { PaginatedDetectionSummaries } from './types';

export interface IGetTopDetectionEntitiesPaginated {
  getTopDetectionEntitiesPaginated: PaginatedDetectionSummaries;
}

export const GET_TOP_DETECTION_ENTITIES_PAGINATED = gql`
  query getTopDetectionEntitiesPaginated(
    $start: Float!
    $end: Float!
    $must_conds: [String]
    $offset: Int
    $size: Int
    $sort: [SortParameterInput]
  ) {
    getTopDetectionEntitiesPaginated(
      start: $start
      end: $end
      must_conds: $must_conds
      offset: $offset
      size: $size
      sort: $sort
    ) {
      offset
      size
      summaries {
        detection_count
        total_detections
        entity_name
        entity_category
        entity_type
        severity
        tenant
        tenant_entity
        tenant_info {
          tenant_id
          tenant_display_name
        }
        first_seen
        last_seen
        is_custom_severity
      }
      total_items
    }
  }
`;

export const useGetTopDetectionEntitiesPaginated = (
  options: QueryHookOptions<
    IGetTopDetectionEntitiesPaginated,
    OperationVariables
  >,
) => {
  return useQuery<IGetTopDetectionEntitiesPaginated>(
    GET_TOP_DETECTION_ENTITIES_PAGINATED,
    options,
  );
};
