import { useQuery } from '@apollo/client';
import {
  Button,
  Flex,
  HStack,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { Input, OptionType, Select } from '@gamma/form-fields';
import { ROUTES } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  IListGroupsData,
  LIST_GROUPS,
  LIST_USERS,
  useEditUser,
  User,
} from '@gamma/investigator/queries';
import { setRole } from '@gamma/investigator/utilities';
import { Alert, Drawer } from '@gamma/overlay';
import { Auth } from 'aws-amplify';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { DeleteUserModal } from '../DeleteUserModal';

interface IInput {
  username: string;
  group: string;
  enabled?: boolean;
}

interface EditUserDrawer {
  children: any;
  data: User;
}

export const EditUserDrawer = ({ children, data }: EditUserDrawer) => {
  const navigate = useNavigate();
  const { user, setUserRole } = useContext(AuthContext);
  const { home } = ROUTES;
  const [hide, setHide] = useState(true);
  const [disableSaveCta, setDisableSaveCta] = useState(true);
  const [updateUserRole, setUpdateUserRole] = useState<boolean>(false);

  const { roles } = i18n;

  const {
    buttons,
    form,
    toasts: { edit: editToast },
    alert,
  } = i18n.pages.usersAccess;

  const group = {
    value: data.group,
    label: _.capitalize(data.group),
  };
  const status = {
    value: data.status,
    label: _.capitalize(data.status),
  };

  const { handleSubmit, control, reset, watch } = useForm();

  const [currentGroup, currentStatus] = watch([
    form.fields.role,
    form.fields.status,
  ]);

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const showToastMessage = useToast();

  const listGroupOptions: OptionType[] = [];

  let statusList: OptionType[] = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ];

  if (data.status === 'invited') {
    statusList = [
      { value: 'inactive', label: 'Inactive' },
      { value: 'invited', label: 'Invited' },
    ];
  }

  const onEditUpdateSuccess = async () => {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    if (user) {
      setRole({ user, setUserRole });
      navigate(home);
    }
  };

  const [editUser, { loading: editLoading }] = useEditUser({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: LIST_USERS,
        variables: { tenants: [data.tenant] },
      },
    ],
    onCompleted: ({ editUser }) => {
      if (updateUserRole) {
        onEditUpdateSuccess();
      }
      showToastMessage({
        status: 'success',
        title: editToast.success.title,
        description: editToast.success.description,
        isClosable: true,
        position: 'bottom-right',
      });
      onEditClose();
    },
    onError: (error: Error) => {
      showToastMessage({
        status: 'error',
        title: '',
        description: editToast.error.description,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  const { data: listGroupData } = useQuery<IListGroupsData>(LIST_GROUPS);

  listGroupData?.listGroups?.forEach((groupData) => {
    listGroupOptions.push({
      value: groupData.group_name,
      label: _.capitalize(groupData.group_name),
    });
  });

  useEffect(() => {
    setDisableSaveCta(
      (!currentStatus?.value && !currentGroup?.value) ||
        (currentStatus?.value === data.status &&
          currentGroup?.value === data.group),
    );
  }, [currentGroup, currentStatus]);

  const onSubmit = (formData: FieldValues) => {
    const input: IInput = {
      username: data.username,
      group: formData.role.value,
    };
    if (currentStatus?.value !== 'invited') {
      input['enabled'] = formData.status.value === 'active';
    }
    if (
      data.username === user?.username &&
      data.group !== formData.role.value
    ) {
      setUpdateUserRole(true);
    }
    editUser({
      variables: {
        input,
      },
    });
  };

  return (
    <Drawer
      isOpen={isEditOpen}
      onOpen={onEditOpen}
      onClose={() => {
        reset();
        onEditClose();
        setUpdateUserRole(false);
      }}
      isCloseDisabled={editLoading}
      closeOnEsc={!editLoading}
      closeOnOverlayClick={!editLoading}
      title={form.title.edit}
      body={
        <Stack
          h="100%"
          as="form"
          spacing={4}
          justifyContent="space-between"
          onSubmit={handleSubmit(onSubmit)}
        >
          <VStack
            w="full"
            alignItems="start"
            css={{ ':first-child > div': { width: '100%' } }}
          >
            <Input
              name={form.fields.alias}
              label={form.fields.alias}
              value={data.alias}
              isReadOnly
            />
            <Input
              isReadOnly
              value={data.email}
              name={form.fields.email}
              label={form.fields.email}
              type={hide ? 'password' : 'text'}
              rightElement={
                <Button variant="unstyled">
                  <MuiIcon
                    cursor="pointer"
                    aria-label={`${hide ? 'Show' : 'Hide'} Email`}
                    onClick={() => setHide(!hide)}
                  >
                    {hide ? 'visibility' : 'visibility_off'}
                  </MuiIcon>
                </Button>
              }
            />
            <Controller
              control={control}
              name={form.fields.role}
              defaultValue={group}
              render={({ field: { onChange, name, value } }) => {
                return (
                  <Select
                    label={name}
                    name={name}
                    value={value}
                    options={listGroupOptions}
                    onChange={onChange}
                    isSearchable={false}
                    isDisabled={editLoading}
                    tooltip={
                      <VStack w="320px" alignItems="start">
                        <Text textStyle="body-md-bold">Roles</Text>
                        <UnorderedList>
                          <ListItem>
                            <Text as="span" textStyle="body-md-bold">
                              {roles.admin}:{' '}
                            </Text>
                            <Text as="span" textStyle="body-md-normal">
                              {roles.descriptions.admin}
                            </Text>
                          </ListItem>
                          <ListItem>
                            <Text as="span" textStyle="body-md-bold">
                              {roles.analyst}:{' '}
                            </Text>
                            <Text as="span" textStyle="body-md-normal">
                              {roles.descriptions.analyst}
                            </Text>
                          </ListItem>
                          <ListItem>
                            <Text as="span" textStyle="body-md-bold">
                              {roles.viewer}:{' '}
                            </Text>
                            <Text as="span" textStyle="body-md-normal">
                              {roles.descriptions.viewer}
                            </Text>
                          </ListItem>
                        </UnorderedList>
                      </VStack>
                    }
                  />
                );
              }}
            />
            <Controller
              control={control}
              name={form.fields.status}
              defaultValue={status}
              render={({ field: { onChange, name, value } }) => {
                return (
                  <Select
                    label={name}
                    name={name}
                    value={value}
                    options={statusList}
                    onChange={onChange}
                    isSearchable={false}
                    isDisabled={editLoading}
                  />
                );
              }}
            />
            {data.username === user?.username && (
              <Alert status="warning" variant="subtle" title={alert} />
            )}
          </VStack>
          <Flex py={4} w="100%" justify="space-between">
            <DeleteUserModal users={[data.username]}>
              <Button
                w="auto"
                variant="ghost"
                colorScheme="red"
                isDisabled={editLoading}
              >
                {buttons.deleteUser}
              </Button>
            </DeleteUserModal>
            <HStack>
              <Button w="auto" onClick={onEditClose} isDisabled={editLoading}>
                {buttons.cancel}
              </Button>
              <Button
                w="auto"
                type="submit"
                variant="solid"
                colorScheme="blue"
                isDisabled={disableSaveCta}
                isLoading={editLoading}
                data-testid="edit-user-button"
              >
                {buttons.save}
              </Button>
            </HStack>
          </Flex>
        </Stack>
      }
    >
      {children}
    </Drawer>
  );
};
