import { i18n } from '@gamma/investigator/localization';
import { useDescribeIdentityProvider } from '@gamma/investigator/queries';
import {
  Board,
  Column,
  Panel,
  PanelDivider,
  PanelHeading,
} from '@gamma/layout';
import { ErrorBoundary } from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';

import { SAMLSingleSignOn } from './SAMLSingleSignOn';

const { title } = i18n.pages.access;

export const Access = () => {
  const { data, loading, error } = useDescribeIdentityProvider({
    fetchPolicy: 'cache-and-network',
  });

  if (loading || error) {
    return (
      <ErrorBoundary page="Access" styleClass="main">
        <Panel>
          <GraphQLReqStatus loading={loading} error={error} />
        </Panel>
      </ErrorBoundary>
    );
  }

  return (
    <Board data-testid="access-page">
      <Column>
        <Panel>
          <PanelHeading>{title}</PanelHeading>
          <PanelDivider />
          <SAMLSingleSignOn data={data} />
        </Panel>
      </Column>
    </Board>
  );
};
