import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export type IGetConfiguredSensorStatusType = {
  getConfiguredSensorStatus: {
    configured: boolean;
  };
};

export const GET_CONFIGURED_SENSOR_STATUS = gql`
  query getConfiguredSensorStatus {
    getConfiguredSensorStatus {
      configured
    }
  }
`;

export const useGetConfiguredSensorStatus = (
  options?: QueryHookOptions<IGetConfiguredSensorStatusType>,
) => {
  return useQuery(GET_CONFIGURED_SENSOR_STATUS, options);
};
