import { createIcon } from '@chakra-ui/react';

export const SettingsSliderIcon = createIcon({
  displayName: 'SettingsSliderIcon',
  viewBox: '0 0 24 24',
  d: 'M8 13c-1.86 0-3.41 1.28-3.86 3H2v2h2.14c.45 1.72 2 3 3.86 3 1.86 0 3.41-1.28 3.86-3H22v-2H11.86c-.45-1.72-2-3-3.86-3Zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2ZM19.86 6c-.45-1.72-2-3-3.86-3-1.86 0-3.41 1.28-3.86 3H2v2h10.14c.45 1.72 2 3 3.86 3 1.86 0 3.41-1.28 3.86-3H22V6h-2.14ZM16 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
