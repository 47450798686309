import { ComponentStyleConfig, CSSObject } from '@chakra-ui/react';

import { textStyles } from '../common';

export const HeadingStyles: ComponentStyleConfig = {
  baseStyle: (props) => {
    const textStyle = textStyles[props.textStyle || ''];
    return {
      fontWeight: (textStyle as CSSObject)?.fontWeight,
    };
  },
  defaultProps: {
    size: '',
  },
};
