import { ApolloError } from '@apollo/client';
import { PlacementWithLogical } from '@chakra-ui/react';
import { ColumnFilterPopover, DataTable } from '@gamma/data-table';
import { SeverityScore } from '@gamma/investigator/components';
import { graphqlErrorRedirects, ROUTES } from '@gamma/investigator/constants';
import { OrgTenantsContext } from '@gamma/investigator/context';
import { useDateRangeQueryString, useIsNewSearch, useManualPagination } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { DetectionSummary, useGetTopDetectionsRawPaginated } from '@gamma/investigator/queries';
import { escapeSpecialChars } from '@gamma/investigator/utilities';
import { FlushPanelContent, Panel } from '@gamma/layout';
import { GraphQLReqStatus } from '@gamma/progress';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Accessor, Column } from 'react-table';

import { AlertCategoriesDetectionsTablePopper } from './AlertCategoriesDetectionsTablePopper';

export type TopAlertsTableFilters = { id: string; value: string }[];

interface AlertCategoriesDetectionsTableProps {
  hiddenCols?: Accessor<DetectionSummary>[] | any[];
  rowPopperPlacement?: PlacementWithLogical;
}

const dataTableColumns: Column<DetectionSummary>[] = [
  {
    width: 100,
    Header: i18n.tables.score,
    accessor: 'severity',
    Cell: (props) => {
      return (
        <SeverityScore
          isCustom={props?.data?.[props?.row?.index]?.is_custom_severity}
          score={props.value}
        />
      );
    },
  },
  {
    Header: i18n.tables.categories,
    accessor: 'alert_name',
    disableSortBy: true,
    Filter: ColumnFilterPopover,
  },
  {
    width: 135,
    Header: i18n.tables.detectionsHeading,
    accessor: 'total_detections',
  },
];

const mustCondition = (
  filters: { id: string; value: string }[],
  orgTenantsQueryParam?: string[],
) => {
  const formattedQuery = escapeSpecialChars({
    chars: filters?.[0]?.value,
  });

  return [
    `{"regexp":{"alert_info.alert_name.keyword":{"value":".*${formattedQuery}.*","case_insensitive":true}}}`,
    `{"terms": {"tenant": ${JSON.stringify(orgTenantsQueryParam)}}}`,
    '{"term": {"detection_status": "open"}}',
  ];
};

export const AlertCategoriesDetectionsTableFooter = ({
  loading,
  error,
  data,
}: {
  loading: boolean;
  error: ApolloError | undefined;
  data: DetectionSummary[];
}) => {
  if (loading || error) {
    return (
      <Panel>
        <GraphQLReqStatus
          loading={loading}
          error={error}
          extended={graphqlErrorRedirects}
        />
      </Panel>
    );
  }
  if (!data.length) {
    return <Panel>{i18n.pages.alerts.na}</Panel>;
  } else {
    return null;
  }
};

export const AlertCategoriesDetectionsTable = (
  props: AlertCategoriesDetectionsTableProps,
) => {
  const { orgTenantsQueryParam } = useContext(OrgTenantsContext);

  const { hiddenCols = [], rowPopperPlacement = 'right-start' } = props;
  const navigate = useNavigate();

  const { start, end } = useDateRangeQueryString();
  const [initialFetch, setInitialFetch] = useState<boolean>(false);
  const [alertCategoriesTableData, setAlertCategoriesTableData] = useState<
    DetectionSummary[]
  >([]);
  const [filters, setFilters] = useState<
    { id: string; value: string }[] | null
  >(null);

  const {
    sortBy,
    offset,
    pageSize,
    pageIndex,
    pageCount,
    onFetchData,
    getPageCount,
    getSortsForAPI,
  } = useManualPagination<DetectionSummary>({
    initialFetch,
    sortBy: [{ id: 'severity', desc: true }],
  });

  useEffect(() => {
    setInitialFetch(true);
  }, [start, end]);

  const filteredDataTableColumns = useMemo(
    () =>
      dataTableColumns.filter(
        (col) => !hiddenCols.includes(col.accessor) && col,
      ),
    [hiddenCols],
  );

  const { isNewSearch } = useIsNewSearch({
    filters,
  });

  const noFilterVariables = {
    start,
    end,
    offset: isNewSearch ? 0 : offset,
    size: pageSize,
    sort: getSortsForAPI(sortBy),
    must_conds: [
      `{"terms": {"tenant": ${JSON.stringify(orgTenantsQueryParam)}}}`,
      '{"term": {"detection_status": "open"}}',
    ],
  };

  const variables = filters?.length
    ? {
        ...noFilterVariables,
        must_conds: mustCondition(filters, orgTenantsQueryParam),
      }
    : noFilterVariables;

  const { data, loading, error, refetch } = useGetTopDetectionsRawPaginated({
    notifyOnNetworkStatusChange: true,
    skip: !start || !end || !orgTenantsQueryParam?.length,
    variables,
    onCompleted: ({ getTopDetectionsRawPaginated }) => {
      setInitialFetch(false);
      getPageCount(getTopDetectionsRawPaginated);
      setAlertCategoriesTableData(getTopDetectionsRawPaginated.summaries);
    },
  });

  const handleColFilter = (filters: TopAlertsTableFilters) => {
    setFilters(filters ?? null);
    if (filters.length) {
      refetch({
        ...noFilterVariables,
        offset: 0,
        must_conds: mustCondition(filters, orgTenantsQueryParam),
      });
    }
  };

  const tableData =
    !loading && !error && alertCategoriesTableData.length
      ? alertCategoriesTableData
      : [];

  return (
    <Panel>
      <FlushPanelContent>
        <div data-testid="top-alert-categories-table">
          <DataTable
            isMemod={false}
            hideColumnHeaders={loading}
            isPaginable={Boolean(!loading && !error && tableData.length)}
            isFilterManual={true}
            onColumnFilter={handleColFilter}
            data={tableData}
            pageSize={pageSize}
            pageCount={pageCount}
            autoResetPage={false}
            autoResetSortBy={false}
            isPaginationManual={true}
            columns={filteredDataTableColumns}
            onFetchData={onFetchData}
            rowWrapper={AlertCategoriesDetectionsTablePopper(
              rowPopperPlacement,
            )}
            itemCount={data?.getTopDetectionsRawPaginated?.total_items || 0}
            onRowClick={({ original }) => {
              navigate({
                pathname: ROUTES.detections,
                search: `start=${start}&end=${end}&alert_category=${original.alert_name}`,
              });
            }}
            initialState={{
              pageIndex,
              sortBy,
            }}
            footer={AlertCategoriesDetectionsTableFooter({
              loading,
              error,
              data: alertCategoriesTableData,
            })}
          />
        </div>
      </FlushPanelContent>
    </Panel>
  );
};
