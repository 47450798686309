import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { Exporter } from './types';

type IGetExporters = {
  getExporters: Exporter[];
};

export const GET_EXPORTERS = gql`
  query getExporters {
    getExporters {
      name
      enabled
      exporter
      exporter_id

      ... on SplunkHecLogsExporter {
        hec_url
        index
        tenant
        token
        tls {
          verify_certificate
        }
      }

      ... on ElasticsearchExporter {
        auth {
          # these may be added in the future
          # access_key_id
          # assume_role
          # profile
          # region
          # secret_access_key
          # strategy
          password
          user
        }
        bulk {
          index
        }
        endpoint
        tls {
          verify_certificate
        }
      }

      ... on HttpExporter {
        http_auth: auth {
          basic {
            username
            password
          }
          bearer {
            token
          }
          strategy
        }
        headers {
          name
          value
        }
        tls {
          verify_certificate
        }
        uri
      }

      ... on LogscaleExporter {
        tenant
        url
        token
        tls {
          verify_certificate
        }
        index
        __typename
      }
    }
  }
`;

export const useGetExporters = (
  options: QueryHookOptions<IGetExporters, OperationVariables>,
) => {
  return useQuery<IGetExporters>(GET_EXPORTERS, {
    ...options,
  });
};
