import { ComponentStyleConfig } from '@chakra-ui/react';
import { transparentize } from '@chakra-ui/theme-tools';

import { textStyles } from '../../common';

export const ModalStyles: ComponentStyleConfig = {
  baseStyle: (props: any) => ({
    overlay: {
      bg: transparentize('gray.900', 0.45)(props),
    },
    dialog: {
      bg: 'layer.1',
      boxShadow: '0px 1px 5px rgba(23, 24, 25, 0.59692)',
      borderRadius: 'base',
      maxW: '100vw',
      overflow: 'auto',
    },
    closeButton: {
      position: 'absolute',
      top: 4,
    },
    header: {
      px: 4,
      pt: 4,
      pb: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      ...textStyles['h5'],
    },
    body: {
      px: 4,
      py: 4,
    },
    footer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      px: 4,
      py: 4,
    },
  }),
  sizes: {
    xs: {
      dialog: {
        w: '320px',
        maxW: '100vw',
      },
    },
    sm: {
      dialog: {
        w: '384px',
        maxW: '100vw',
      },
    },
    md: {
      dialog: {
        w: '448px',
        maxW: '100vw',
      },
    },
    lg: {
      dialog: {
        w: '512px',
        maxW: '100vw',
      },
    },
    xl: {
      dialog: {
        w: '1100px',
        maxW: '100vw',
      },
    },
  },
};
