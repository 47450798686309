import { createIcon } from '@chakra-ui/react';

export const InternalErrorLight = createIcon({
  displayName: 'InternalErrorLight',
  viewBox: '0 0 570 170',
  path: (
    <>
      <path
        d="M28.853 165.427h515.063a1.963 1.963 0 0 1 1.967 1.964v.298a1.962 1.962 0 0 1-1.967 1.964H28.853a1.962 1.962 0 0 1-1.968-1.964v-.298a1.963 1.963 0 0 1 1.968-1.964ZM1.968 165.427h16.618a1.964 1.964 0 0 1 1.968 1.964v.298a1.962 1.962 0 0 1-1.968 1.964H1.968A1.962 1.962 0 0 1 0 167.689v-.298a1.964 1.964 0 0 1 1.968-1.964ZM567.438 170h-14.323c-1.415 0-2.563-1.024-2.563-2.287s1.148-2.286 2.563-2.286h14.323c1.415 0 2.562 1.023 2.562 2.286 0 1.263-1.147 2.287-2.562 2.287ZM170.88 0h228.192a8.01 8.01 0 0 1 5.66 2.34 7.986 7.986 0 0 1 2.345 5.651v134.806a7.984 7.984 0 0 1-2.345 5.65 8.01 8.01 0 0 1-5.66 2.341H170.88a8.01 8.01 0 0 1-5.659-2.341 7.98 7.98 0 0 1-2.345-5.65V7.991c0-2.12.843-4.152 2.345-5.65A8.01 8.01 0 0 1 170.88 0Z"
        fill="#D5D6D8"
      />
      <path d="M400.041 7.875H169.912v133.768h230.129V7.875Z" fill="#EDEEEE" />
      <path d="M173.177 7.875h-3.265v133.768h3.265V7.875Z" fill="#FBFCFC" />
      <path
        d="m259.235 11.135-86.058 85.92v34.814L294.118 11.127l-34.883.008ZM400.041 7.875v33.38L299.488 141.643h-77.907L353.978 9.464v-1.59h46.063ZM400.041 52.288V73.52l-68.235 68.122h-21.267l89.502-89.355Z"
        fill="#F6F6F7"
      />
      <path d="M400.041 7.875h-3.265v133.768h3.265V7.875Z" fill="#FBFCFC" />
      <path d="M169.912 7.875v3.26h230.129v-3.26H169.912Z" fill="#FBFCFC" />
      <path
        d="M141.578 152.42h286.844v5.95a7.051 7.051 0 0 1-2.07 4.99 7.076 7.076 0 0 1-4.998 2.067H148.646a7.074 7.074 0 0 1-4.998-2.067 7.051 7.051 0 0 1-2.07-4.99v-5.95Z"
        fill="#B9BBBE"
      />
      <path
        d="M412.462 149.544H157.491a2.876 2.876 0 0 0-2.881 2.876h260.733a2.87 2.87 0 0 0-.844-2.034 2.874 2.874 0 0 0-2.037-.842ZM289.22 152.447h-29.985l2.226 2.913a3.314 3.314 0 0 0 2.632 1.3h41.813a3.314 3.314 0 0 0 2.633-1.3l2.226-2.913H289.22Z"
        fill="#EFF0F0"
      />
      <path
        d="M235.185 75.64c5.44 0 7.68 4 7.68 11.6 0 7.28-2.96 10.4-7.68 10.4-4 0-6.56-2.32-7.52-6.32h-9.12c.8 8.8 7.84 14.4 16.8 14.4 11.04 0 16.8-6.72 16.8-18.8 0-12.8-5.36-19.92-14.56-19.92-4.24 0-6.8 1.52-8.48 3.2V58.52h20.88V49.8h-29.92v29.92h8.4c1.36-2.32 3.44-4.08 6.72-4.08Zm67.157 13.2V65.96c0-11.68-8.16-16.88-17.36-16.88s-17.36 5.2-17.36 16.96v22.8c0 11.44 8.16 16.88 17.36 16.88s17.36-5.44 17.36-16.88Zm-9.28-22.32v21.76c0 5.92-3.12 9.36-8.08 9.36-5.04 0-8.16-3.44-8.16-9.36V66.52c0-5.92 3.12-9.36 8.16-9.36 4.96 0 8.08 3.44 8.08 9.36Zm59.076 22.32V65.96c0-11.68-8.16-16.88-17.36-16.88s-17.36 5.2-17.36 16.96v22.8c0 11.44 8.16 16.88 17.36 16.88s17.36-5.44 17.36-16.88Zm-9.28-22.32v21.76c0 5.92-3.12 9.36-8.08 9.36-5.04 0-8.16-3.44-8.16-9.36V66.52c0-5.92 3.12-9.36 8.16-9.36 4.96 0 8.08 3.44 8.08 9.36Z"
        fill="#37DB80"
      />
    </>
  ),
  defaultProps: {
    height: '170px',
    width: '570px',
  },
});
