import { Select as ChakraSelect } from 'chakra-react-select';
import React, { forwardRef } from 'react';
import { SelectInstance } from 'react-select';

import { LabelProps, SelectWrapper } from '../Components';
import { OptionType, UseSelectParams, useSelect } from '../hooks';

export interface SelectProps<
  IsMulti extends boolean,
  Option extends OptionType = OptionType,
> extends Omit<LabelProps, 'children' | 'width'>,
    UseSelectParams<IsMulti, Option> {
  clearValue?: () => void;
}

const _Select = <
  IsMulti extends boolean,
  Option extends OptionType = OptionType,
>(
  props: SelectProps<IsMulti, Option>,
  ref: React.ForwardedRef<SelectInstance<Option, IsMulti>>,
) => {
  const { selectProps, wrapperProps } = useSelect<
    IsMulti,
    Option,
    typeof props
  >(props);

  return (
    <SelectWrapper {...wrapperProps}>
      {/* @ts-ignore */}
      <ChakraSelect {...selectProps} ref={ref} />
    </SelectWrapper>
  );
};

export const Select = forwardRef(_Select) as <
  IsMulti extends boolean,
  Option extends OptionType = OptionType,
>(
  props: SelectProps<IsMulti, Option> & {
    ref?:
      | ((instance: SelectInstance<Option, IsMulti>) => void)
      | React.RefObject<SelectInstance<Option, IsMulti>>
      | null
      | undefined;
  },
) => ReturnType<typeof _Select>;
