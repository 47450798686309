import { gql, MutationHookOptions, OperationVariables, useMutation } from '@apollo/client';

export interface IAcceptHumioTcVariables extends OperationVariables {
  humioSessionToken: string;
}

export const ACCEPT_HUMIO_TC = gql`
  mutation acceptHumioTC($humioSessionToken: String!) {
    acceptHumioTC(humioSessionToken: $humioSessionToken) {
      status_code
      body {
        data
        message
        success
      }
    }
  }
`;

export const useAcceptHumioTC = (
  options?: MutationHookOptions<any, IAcceptHumioTcVariables>,
) => {
  return useMutation<any, IAcceptHumioTcVariables>(ACCEPT_HUMIO_TC, options);
};
