import { CopyIcon } from '@chakra-ui/icons';
import { IconButton, useClipboard, useToast } from '@chakra-ui/react';
import { i18n } from '@gamma/investigator/localization';

const { copiedUrl } = i18n.pages.detections;

const generateCopyURL = ({ detectionId }: { detectionId: string }) => {
  const params = new URLSearchParams(window.location.search);
  const domain_url = new URL(window.location.href);

  const protocol = domain_url.protocol;
  const hostname = domain_url.hostname;
  const port = window.location.port ? ':' + window.location.port : '';
  const pathname = window.location.pathname;
  const detailsPath = `/${detectionId}/details`;

  return `${protocol}//${hostname}${port}${pathname}${detailsPath}?${params.toString()}`;
};

export interface DetectionCopyURLProps {
  detectionId: string;
}

export const DetectionCopyURL = ({ detectionId }: DetectionCopyURLProps) => {
  const toast = useToast();

  const copyURL = generateCopyURL({
    detectionId,
  });

  const { onCopy } = useClipboard(copyURL);

  const handleCopy = () => {
    onCopy();
    toast({
      position: 'top',
      status: 'success',
      description: copiedUrl,
    });
  };

  return (
    <IconButton
      variant="solid"
      colorScheme="gray"
      aria-label="copy split"
      icon={<CopyIcon boxSize={4} />}
      size="box-sm"
      onClick={handleCopy}
      data-testid="data-list-controls-copy-button"
    />
  );
};
