import { createIcon } from '@chakra-ui/react';

export const AdvancedLicenseStatus = createIcon({
  displayName: 'AdvancedLicenseStatus',
  viewBox: '0 0 96 96',
  path: (
    <>
      <mask id="path-1-inside-1_539_9936" fill="white">
        <path d="M78.4546 76.3463C78.4287 76.3463 78.4125 76.3456 78.4068 76.3453L32.9043 76.3456C32.4662 76.3461 32.0347 76.2393 31.6475 76.0344C31.2603 75.8295 30.9292 75.5328 30.6831 75.1703C30.4371 74.8079 30.2837 74.3906 30.2363 73.9551C30.1889 73.5196 30.2489 73.0791 30.4112 72.6721C32.0622 68.5473 34.2851 58.6511 30.6237 38.7165C26.3002 15.1774 30.1709 6.67034 32.0357 4.10537C32.2852 3.76339 32.6119 3.48507 32.9892 3.29301C33.3664 3.10095 33.7837 3.00056 34.207 3H80.4217C80.8375 2.9996 81.2476 3.09588 81.6198 3.28124C81.9919 3.4666 82.3158 3.73595 82.5659 4.06802C82.8161 4.40009 82.9856 4.78578 83.061 5.19462C83.1365 5.60345 83.1158 6.02423 83.0007 6.42372C81.0727 13.0898 77.1023 30.7529 81.8624 50.7453C85.4711 65.902 83.7363 72.0844 81.6455 74.6018C80.2665 76.2622 78.747 76.3463 78.4546 76.3463Z" />
      </mask>
      <path
        d="M78.4546 76.3463C78.4287 76.3463 78.4125 76.3456 78.4068 76.3453L32.9043 76.3456C32.4662 76.3461 32.0347 76.2393 31.6475 76.0344C31.2603 75.8295 30.9292 75.5328 30.6831 75.1703C30.4371 74.8079 30.2837 74.3906 30.2363 73.9551C30.1889 73.5196 30.2489 73.0791 30.4112 72.6721C32.0622 68.5473 34.2851 58.6511 30.6237 38.7165C26.3002 15.1774 30.1709 6.67034 32.0357 4.10537C32.2852 3.76339 32.6119 3.48507 32.9892 3.29301C33.3664 3.10095 33.7837 3.00056 34.207 3H80.4217C80.8375 2.9996 81.2476 3.09588 81.6198 3.28124C81.9919 3.4666 82.3158 3.73595 82.5659 4.06802C82.8161 4.40009 82.9856 4.78578 83.061 5.19462C83.1365 5.60345 83.1158 6.02423 83.0007 6.42372C81.0727 13.0898 77.1023 30.7529 81.8624 50.7453C85.4711 65.902 83.7363 72.0844 81.6455 74.6018C80.2665 76.2622 78.747 76.3463 78.4546 76.3463Z"
        fill="#262626"
        stroke="#525252"
        stroke-width="4"
        mask="url(#path-1-inside-1_539_9936)"
      />
      <mask id="path-2-inside-2_539_9936" fill="white">
        <path d="M70.1277 84.6734C70.1018 84.6734 70.0856 84.6728 70.0799 84.6724L24.5774 84.6728C24.1393 84.6733 23.7078 84.5664 23.3206 84.3615C22.9334 84.1566 22.6023 83.86 22.3562 83.4975C22.1102 83.135 21.9568 82.7178 21.9094 82.2822C21.862 81.8467 21.922 81.4062 22.0843 80.9993C23.7353 76.8745 25.9582 66.9783 22.2968 47.0437C17.9733 23.5046 21.844 14.9975 23.7088 12.4325C23.9583 12.0905 24.285 11.8122 24.6623 11.6202C25.0395 11.4281 25.4568 11.3277 25.8801 11.3271H72.0948C72.5106 11.3267 72.9207 11.423 73.2928 11.6084C73.665 11.7937 73.9889 12.0631 74.239 12.3952C74.4892 12.7272 74.6587 13.1129 74.7341 13.5218C74.8096 13.9306 74.7889 14.3514 74.6738 14.7509C72.7458 21.4169 68.7754 39.0801 73.5355 59.0724C77.1442 74.2292 75.4094 80.4116 73.3186 82.929C71.9396 84.5893 70.4201 84.6734 70.1277 84.6734Z" />
      </mask>
      <path
        d="M70.1277 84.6734C70.1018 84.6734 70.0856 84.6728 70.0799 84.6724L24.5774 84.6728C24.1393 84.6733 23.7078 84.5664 23.3206 84.3615C22.9334 84.1566 22.6023 83.86 22.3562 83.4975C22.1102 83.135 21.9568 82.7178 21.9094 82.2822C21.862 81.8467 21.922 81.4062 22.0843 80.9993C23.7353 76.8745 25.9582 66.9783 22.2968 47.0437C17.9733 23.5046 21.844 14.9975 23.7088 12.4325C23.9583 12.0905 24.285 11.8122 24.6623 11.6202C25.0395 11.4281 25.4568 11.3277 25.8801 11.3271H72.0948C72.5106 11.3267 72.9207 11.423 73.2928 11.6084C73.665 11.7937 73.9889 12.0631 74.239 12.3952C74.4892 12.7272 74.6587 13.1129 74.7341 13.5218C74.8096 13.9306 74.7889 14.3514 74.6738 14.7509C72.7458 21.4169 68.7754 39.0801 73.5355 59.0724C77.1442 74.2292 75.4094 80.4116 73.3186 82.929C71.9396 84.5893 70.4201 84.6734 70.1277 84.6734Z"
        fill="#262626"
        stroke="#525252"
        stroke-width="4"
        mask="url(#path-2-inside-2_539_9936)"
      />
      <mask id="path-3-inside-3_539_9936" fill="white">
        <path d="M61.8008 93.0003C61.7749 93.0003 61.7586 92.9997 61.753 92.9993L16.2505 92.9997C15.8124 93.0002 15.3809 92.8933 14.9937 92.6884C14.6065 92.4836 14.2753 92.1869 14.0293 91.8244C13.7833 91.4619 13.6299 91.0447 13.5825 90.6091C13.5351 90.1736 13.5951 89.7331 13.7574 89.3262C15.4084 85.2014 17.6313 75.3052 13.9699 55.3706C9.64639 31.8315 13.5171 23.3244 15.3819 20.7594C15.6314 20.4174 15.9581 20.1391 16.3354 19.9471C16.7126 19.755 17.1299 19.6546 17.5532 19.6541H63.7679C64.1837 19.6537 64.5938 19.7499 64.9659 19.9353C65.3381 20.1207 65.662 20.39 65.9121 20.7221C66.1623 21.0541 66.3318 21.4398 66.4072 21.8487C66.4827 22.2575 66.462 22.6783 66.3469 23.0778C64.4189 29.7438 60.4485 47.407 65.2086 67.3993C68.8173 82.5561 67.0825 88.7385 64.9917 91.2559C63.6126 92.9162 62.0932 93.0003 61.8008 93.0003Z" />
      </mask>
      <path
        d="M61.8008 93.0003C61.7749 93.0003 61.7586 92.9997 61.753 92.9993L16.2505 92.9997C15.8124 93.0002 15.3809 92.8933 14.9937 92.6884C14.6065 92.4836 14.2753 92.1869 14.0293 91.8244C13.7833 91.4619 13.6299 91.0447 13.5825 90.6091C13.5351 90.1736 13.5951 89.7331 13.7574 89.3262C15.4084 85.2014 17.6313 75.3052 13.9699 55.3706C9.64639 31.8315 13.5171 23.3244 15.3819 20.7594C15.6314 20.4174 15.9581 20.1391 16.3354 19.9471C16.7126 19.755 17.1299 19.6546 17.5532 19.6541H63.7679C64.1837 19.6537 64.5938 19.7499 64.9659 19.9353C65.3381 20.1207 65.662 20.39 65.9121 20.7221C66.1623 21.0541 66.3318 21.4398 66.4072 21.8487C66.4827 22.2575 66.462 22.6783 66.3469 23.0778C64.4189 29.7438 60.4485 47.407 65.2086 67.3993C68.8173 82.5561 67.0825 88.7385 64.9917 91.2559C63.6126 92.9162 62.0932 93.0003 61.8008 93.0003Z"
        fill="#262626"
        stroke="#525252"
        stroke-width="4"
        mask="url(#path-3-inside-3_539_9936)"
      />
      <path
        d="M55.9073 40.1866H19.6337C19.1496 40.1866 18.6854 39.9943 18.3431 39.652C18.0009 39.3097 17.8086 38.8455 17.8086 38.3615C17.8086 37.8774 18.0009 37.4132 18.3431 37.0709C18.6854 36.7287 19.1496 36.5364 19.6337 36.5364H55.9073C56.3913 36.5364 56.8556 36.7287 57.1978 37.0709C57.5401 37.4132 57.7324 37.8774 57.7324 38.3615C57.7324 38.8455 57.5401 39.3097 57.1978 39.652C56.8556 39.9943 56.3913 40.1866 55.9073 40.1866Z"
        fill="#43BF79"
      />
      <path
        d="M55.9073 47.9429H19.6337C19.1496 47.9429 18.6854 47.7506 18.3431 47.4083C18.0009 47.0661 17.8086 46.6019 17.8086 46.1178C17.8086 45.6338 18.0009 45.1695 18.3431 44.8273C18.6854 44.485 19.1496 44.2927 19.6337 44.2927H55.9073C56.3913 44.2927 56.8556 44.485 57.1978 44.8273C57.5401 45.1695 57.7324 45.6338 57.7324 46.1178C57.7324 46.6019 57.5401 47.0661 57.1978 47.4083C56.8556 47.7506 56.3913 47.9429 55.9073 47.9429Z"
        fill="#8D8D8D"
      />
      <path
        d="M55.9073 55.6992H19.6337C19.1496 55.6992 18.6854 55.507 18.3431 55.1647C18.0009 54.8224 17.8086 54.3582 17.8086 53.8742C17.8086 53.3901 18.0009 52.9259 18.3431 52.5836C18.6854 52.2414 19.1496 52.0491 19.6337 52.0491H55.9073C56.3913 52.0491 56.8556 52.2414 57.1978 52.5836C57.5401 52.9259 57.7324 53.3901 57.7324 53.8742C57.7324 54.3582 57.5401 54.8224 57.1978 55.1647C56.8556 55.507 56.3913 55.6992 55.9073 55.6992Z"
        fill="#8D8D8D"
      />
      <path
        d="M58.6449 63.4566H22.3712C21.8872 63.4566 21.423 63.2643 21.0807 62.922C20.7384 62.5797 20.5461 62.1155 20.5461 61.6315C20.5461 61.1474 20.7384 60.6832 21.0807 60.341C21.423 59.9987 21.8872 59.8064 22.3712 59.8064H58.6449C59.1289 59.8064 59.5931 59.9987 59.9354 60.341C60.2777 60.6832 60.4699 61.1474 60.4699 61.6315C60.4699 62.1155 60.2777 62.5797 59.9354 62.922C59.5931 63.2643 59.1289 63.4566 58.6449 63.4566Z"
        fill="#8D8D8D"
      />
      <path
        d="M58.6449 71.2129H22.3712C21.8872 71.2129 21.423 71.0206 21.0807 70.6784C20.7384 70.3361 20.5461 69.8719 20.5461 69.3878C20.5461 68.9038 20.7384 68.4396 21.0807 68.0973C21.423 67.755 21.8872 67.5627 22.3712 67.5627H58.6449C59.1289 67.5627 59.5931 67.755 59.9354 68.0973C60.2777 68.4396 60.4699 68.9038 60.4699 69.3878C60.4699 69.8719 60.2777 70.3361 59.9354 70.6784C59.5931 71.0206 59.1289 71.2129 58.6449 71.2129Z"
        fill="#8D8D8D"
      />
      <path
        d="M36.7439 32.4292H19.6337C19.1496 32.4292 18.6854 32.2369 18.3431 31.8947C18.0009 31.5524 17.8086 31.0882 17.8086 30.6041C17.8086 30.1201 18.0009 29.6559 18.3431 29.3136C18.6854 28.9713 19.1496 28.7791 19.6337 28.7791H36.7439C37.2279 28.7791 37.6921 28.9713 38.0344 29.3136C38.3767 29.6559 38.569 30.1201 38.569 30.6041C38.569 31.0882 38.3767 31.5524 38.0344 31.8947C37.6921 32.2369 37.2279 32.4292 36.7439 32.4292Z"
        fill="#43BF79"
      />
      <path
        d="M57.8 82.25L69.2 70.85L67.1 68.8L57.95 77.95L52.9 72.9L50.7 75.1L57.8 82.25ZM60 96C55.3333 94.8333 51.5 92.125 48.5 87.875C45.5 83.625 44 78.9667 44 73.9V62L60 56L76 62V73.9C76 78.9667 74.5 83.625 71.5 87.875C68.5 92.125 64.6667 94.8333 60 96Z"
        fill="#23A15A"
      />
      <path
        d="M57.8 82.25L69.2 70.85L67.1 68.8L57.95 77.95L52.9 72.9L50.7 75.1L57.8 82.25Z"
        fill="#D5E7DD"
      />
    </>
  ),
  defaultProps: {
    height: '96px',
    width: '96px',
  },
});
