import { createIcon } from '@chakra-ui/react';

export const LockedShield = createIcon({
  displayName: 'LockedShield',
  viewBox: '0 0 160 128',
  path: (
    <>
      <path
        opacity=".7"
        d="M80.5 12 44 34.687S44 81.477 80.5 107C117 81.478 117 34.687 117 34.687L80.5 12Z"
        fill="#00FF8F"
      />
      <path
        d="M87 51.204a5.98 5.98 0 0 0-.806-2.993 6.333 6.333 0 0 0-2.22-2.25 6.77 6.77 0 0 0-6.262-.36 6.438 6.438 0 0 0-2.488 1.978 6.04 6.04 0 0 0-1.177 2.88 5.942 5.942 0 0 0 .425 3.064 6.23 6.23 0 0 0 1.922 2.487L74 72h13l-2.394-15.99a6.242 6.242 0 0 0 1.766-2.145c.414-.831.629-1.74.628-2.661Z"
        fill="url(#a)"
      />
      <path
        d="M86 50.515a5.523 5.523 0 0 0-2.56-4.662 5.491 5.491 0 0 0-7.404 1.44 5.52 5.52 0 0 0 .99 7.494L75 69h11l-2.026-14.213A5.499 5.499 0 0 0 86 50.515Z"
        fill="#fff"
      />
      <path
        opacity=".08"
        d="m80.622 12-.622.388v94.169c.207.148.413.296.622.443C117 81.478 117 34.687 117 34.687L80.622 12Z"
        fill="#00FF8F"
      />
      <path
        d="M38.755 18.539a.838.838 0 0 1-.46-1.005.409.409 0 0 0-.251-.508.41.41 0 0 0-.519.222.827.827 0 0 1-.41.423.817.817 0 0 1-.585.04.39.39 0 0 0-.343.049.416.416 0 0 0-.17.466.4.4 0 0 0 .229.261.838.838 0 0 1 .46 1.005.409.409 0 0 0 .25.51.41.41 0 0 0 .519-.223.817.817 0 0 1 .996-.463.39.39 0 0 0 .342-.049.416.416 0 0 0 .17-.466.4.4 0 0 0-.229-.261ZM14.755 73.539a.838.838 0 0 1-.46-1.005.409.409 0 0 0-.251-.508.41.41 0 0 0-.519.222.827.827 0 0 1-.41.423.816.816 0 0 1-.585.04.39.39 0 0 0-.343.049.416.416 0 0 0-.17.466.4.4 0 0 0 .229.261.838.838 0 0 1 .46 1.005.407.407 0 0 0 .047.346.414.414 0 0 0 .463.172.413.413 0 0 0 .259-.23.817.817 0 0 1 .996-.463.395.395 0 0 0 .342-.05.417.417 0 0 0-.059-.728Z"
        fill="#0093EE"
      />
      <path
        d="M133.168 44.372c.828 0 1.5-.678 1.5-1.514 0-.835-.672-1.513-1.5-1.513s-1.5.678-1.5 1.513c0 .836.672 1.514 1.5 1.514ZM61.5 4.027c.828 0 1.5-.678 1.5-1.514C63 1.678 62.328 1 61.5 1S60 1.678 60 2.513c0 .836.672 1.514 1.5 1.514ZM53.5 108.027c.828 0 1.5-.678 1.5-1.514 0-.835-.672-1.513-1.5-1.513s-1.5.678-1.5 1.513c0 .836.672 1.514 1.5 1.514ZM135.5 89.027c.828 0 1.5-.678 1.5-1.514 0-.835-.672-1.513-1.5-1.513s-1.5.678-1.5 1.513c0 .836.672 1.514 1.5 1.514Z"
        fill="#F75F72"
      />
      <path
        d="M126.096 13.235a.828.828 0 0 1-.42-.415.842.842 0 0 1-.04-.59.419.419 0 0 0-.251-.509.408.408 0 0 0-.519.222.823.823 0 0 1-.995.463.414.414 0 0 0-.513.516.42.42 0 0 0 .147.215.415.415 0 0 0 .082.046.83.83 0 0 1 .459 1.005.419.419 0 0 0 .251.509.411.411 0 0 0 .519-.222.827.827 0 0 1 .41-.423.816.816 0 0 1 .585-.04.412.412 0 0 0 .505-.254.426.426 0 0 0-.139-.477.443.443 0 0 0-.081-.046ZM122.754 97.539a.83.83 0 0 1-.459-1.005.419.419 0 0 0-.251-.508.409.409 0 0 0-.519.222.823.823 0 0 1-.995.463.4.4 0 0 0-.343.049.419.419 0 0 0-.023.682.415.415 0 0 0 .082.046.83.83 0 0 1 .459 1.005.419.419 0 0 0 .251.509.409.409 0 0 0 .519-.223.823.823 0 0 1 .995-.463.4.4 0 0 0 .343-.049.418.418 0 0 0 .023-.682.42.42 0 0 0-.082-.046ZM14.755 26.539a.838.838 0 0 1-.46-1.005.409.409 0 0 0-.251-.508.41.41 0 0 0-.519.222.827.827 0 0 1-.41.423.817.817 0 0 1-.585.04.39.39 0 0 0-.343.049.416.416 0 0 0-.17.466.4.4 0 0 0 .229.261.838.838 0 0 1 .46 1.005.409.409 0 0 0 .25.51.41.41 0 0 0 .519-.223.817.817 0 0 1 .996-.463.39.39 0 0 0 .342-.049.416.416 0 0 0 .17-.466.4.4 0 0 0-.229-.261Z"
        fill="#0093EE"
      />
      <path
        d="M22.5 56.027c.828 0 1.5-.678 1.5-1.514 0-.835-.672-1.513-1.5-1.513s-1.5.678-1.5 1.514c0 .835.672 1.513 1.5 1.513ZM150.517 57.376c.829 0 1.5-.678 1.5-1.514s-.671-1.513-1.5-1.513c-.828 0-1.5.677-1.5 1.513 0 .836.672 1.514 1.5 1.514ZM106.004 0h-.529v3.027h.529V0Z"
        fill="#00FF8F"
      />
      <path
        d="M107.24 1.78v-.534h-3v.534h3ZM101.765 109h-.53v3.027h.53V109Z"
        fill="#00FF8F"
      />
      <path
        d="M103 110.78v-.534h-3v.534h3ZM25.765 89h-.53v3.027h.53V89Z"
        fill="#00FF8F"
      />
      <path
        d="M27 90.78v-.534h-3v.535h3ZM126.823 66.019h-.529v3.026h.529V66.02Z"
        fill="#00FF8F"
      />
      <path
        d="M128.059 67.799v-.534h-3v.534h3ZM140.391 20.315l.9.832 1.275-1.892.45.379-1.725 2.27-1.275-1.438.375-.151Z"
        fill="#00FF8F"
      />
      <defs>
        <linearGradient
          id="a"
          x1="80.5"
          y1="72"
          x2="67.006"
          y2="58.901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity=".25" />
          <stop offset=".535" stopColor="gray" stopOpacity=".12" />
          <stop offset="1" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
      </defs>
    </>
  ),
  defaultProps: {
    height: '128px',
    width: '160px',
  },
});
