import {
  Center,
  Link,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { CTAButton } from '@gamma/buttons';
import { URLS } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import { getFormattedAlias } from '@gamma/investigator/utilities';
import { ErrorBoundary } from '@gamma/overlay';
import {
  CardPageCTA,
  CardPageHeading,
  CardPageIllustration,
  CardPageSubtitle,
} from '@gamma/pages';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';

import { IRegistrationContext } from '../Registration';

export const Welcome = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const navigate = useNavigate();
  const { userEmail, setUserEmail, userAlias, setUserAlias } =
    useOutletContext<IRegistrationContext>();
  const [searchParams] = useSearchParams();
  const { handleSubmit } = useForm();

  useEffect(() => {
    const email = searchParams.get('email') ?? '';
    setUserEmail(email);
    const alias = searchParams.get('alias') ?? '';
    setUserAlias(alias);
  }, [searchParams]);

  const { title, subtitle, cta, accept, privacyPolicy } =
    i18n.pages.registration.welcome;

  const onSubmit = async () => {
    navigate('setpassword', {
      replace: true,
    });
  };

  return (
    <ErrorBoundary page="Registration Welcome Page">
      <form
        data-testid="welcome-card"
        id="eulaForm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <CardPageHeading>
          {i18n.formatString(title, {
            appName: <strong>{i18n.app.name}</strong>,
          })}
        </CardPageHeading>
        <CardPageIllustration illustration="Welcome" />
        <CardPageSubtitle textStyle="body-md">
          {i18n.formatString(subtitle, {
            alias: (
              <Tooltip
                label={userAlias}
                shouldWrapChildren
                textStyle="body-md"
                placement="bottom-end"
                p="4px 8px"
              >
                <Center>
                  <Text
                    bg={bgColor}
                    textStyle="body-md-bold"
                    p="2px 8px"
                    borderRadius="sm"
                    data-testid="user-alias"
                  >
                    {getFormattedAlias(userAlias, 20, 16)}
                  </Text>
                </Center>
              </Tooltip>
            ),
            break: <br />,
          })}
        </CardPageSubtitle>
        <Text color="gray.400">
          {i18n.formatString(accept, {
            privacyPolicy: (
              <Link
                data-testid="privacy-policy"
                href={URLS.CORELIGHT_PRIVACY_POLICY}
                color="text.link"
                textDecoration="underline"
                isExternal
                _focus={{ outline: 'none' }}
              >
                {privacyPolicy}
              </Link>
            ),
          })}
        </Text>
      </form>
      <CardPageCTA>
        <CTAButton
          data-testid="welcome-continue-cta"
          type="submit"
          form="eulaForm"
        >
          {cta}
        </CTAButton>
      </CardPageCTA>
    </ErrorBoundary>
  );
};
