import { LazyQueryResult, OperationVariables } from '@apollo/client';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Link as ChakraLink,
  Stack,
  Tag,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { DataTable } from '@gamma/data-table';
import { MuiIcon } from '@gamma/display';
import { BullsEyeIcon } from '@gamma/icons';
import {
  AlertMetaStatusSwitch,
  CustomSeverityScoreModal,
  EntityTypeIcon,
  FederatedTenantSelect,
  SeverityScore,
  SuricataAlertDetailsLayout,
  SuricataRuleEditorModal,
} from '@gamma/investigator/components';
import { graphqlErrorRedirects, ROUTES } from '@gamma/investigator/constants';
import { AuthContext, OrgTenantsContext } from '@gamma/investigator/context';
import { useDateRangeQueryString, useManualPagination } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  ExcludedEntity,
  IGetPrivileges,
  IQueryAlertMetadataExcludedEntitiesPaginated,
  useGetAlertMetadata,
  useGetAlertMetadataSuricataRuleDescription,
  useGetPrivileges,
  useQueryAlertMetadataExcludedEntitiesPaginated,
} from '@gamma/investigator/queries';
import { stringifyAlertType, stringifyEntityType } from '@gamma/investigator/utilities';
import { Board, Column, FlushPanelContent, PageHeader, Panel, PanelHeader, PanelHeading } from '@gamma/layout';
import { ErrorBoundary } from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';
import _ from 'lodash';
import moment from 'moment';
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { CellProps, Column as TableColumn, TableState } from 'react-table';
import dedent from 'ts-dedent';

import { QueryAlertMetadataPaginatedVariables } from '../AlertCatalogOutlet';
import { AddEntityDrawer, DeleteModalRow, ExcludedEntitiesSearch } from './Components';

export interface IActiveRow {
  active?: boolean;
  content_id?: string;
  title?: string;
  logsource?: any;
}

export interface detailsProps {
  label: string;
  input: string | undefined;
  stringify?: boolean;
  lineBreak?: boolean[];
  truncate?: boolean;
}

export interface IAlertCatalogOutletContext {
  variables: QueryAlertMetadataPaginatedVariables;
}

export interface QueryVariables {
  query: string;
  offset: number;
  size: number;
  sort: { sort_by: string; sort_dir: string }[];
}

interface ExcludedEntitiesTableSearchProps {
  content_id: string | undefined;
  setQuery: Dispatch<SetStateAction<string | null>>;
  setOffsetReset: Dispatch<SetStateAction<boolean>>;
}

const ExcludedEntitiesTableSearch = ({
  setQuery,
  content_id,
  setOffsetReset,
}: ExcludedEntitiesTableSearchProps) =>
  useMemo(
    () => (
      <ExcludedEntitiesSearch
        setOffsetReset={setOffsetReset}
        content_id={content_id}
        setQuery={setQuery}
      />
    ),
    [content_id, setQuery, setOffsetReset],
  );

export const AlertCatalogDetails = () => {
  const { variables } = useOutletContext<IAlertCatalogOutletContext>();

  const { user, userRole } = useContext(AuthContext);
  const { orgTenantsQueryParam } = useContext(OrgTenantsContext);

  const { content_id } = useParams();

  const { search, pathname } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const tenant = params.get('tenant');

  const { start, end } = useDateRangeQueryString();

  const navigate = useNavigate();
  const disclosure = useDisclosure();
  const severityDisclosure = useDisclosure();

  const [totalExcludedEntities, setTotalExcludedEntities] = useState<
    number | undefined
  >(undefined);

  const [query, setQuery] = useState<string | null>('');
  const [offsetReset, setOffsetReset] = useState<boolean>(false);
  const [alertDescription, setAlertDescription] = useState<string>('');

  const { navigation } = i18n;
  const { mitreAttackTechniques } = i18n.pages.system;
  const { ruleModal } = i18n.pages.system;

  const {
    addNew,
    alertDetails,
    isActive,
    info,
    tactics,
    type,
    viewAllDetections,
    excludedEntitiesHeading,
    categoryStatus,
    customScore,
    corelightScore,
    modifiedBy,
    editScore,
    modifiedTime,
    details,
    properties,
    notApplicable,
  } = i18n.pages.system.alertCatalog;

  const { detections, systemSettingsAlertCatalog } = ROUTES;

  const {
    sortBy,
    offset,
    pageSize,
    pageIndex,
    pageCount,
    onFetchData,
    getPageCount,
    getSortsForAPI,
    formatNestedSorts,
  } = useManualPagination<ExcludedEntity>({
    sortBy: [{ id: 'entity_name', desc: true }],
    offsetReset,
    setOffsetReset,
  });

  const [queryTenant, setQueryTenant] = useState<string | undefined>();

  const sortedEntityName = formatNestedSorts(sortBy);

  const queryVariables = useMemo(
    () => ({
      query: query
        ? `{"query": {"bool": {"must": [${query}, {"terms":{"tenant":["${queryTenant}"]}}]}}}`
        : '',
      offset: offsetReset ? 0 : offset,
      size: pageSize,
      sort: getSortsForAPI(sortedEntityName),
    }),
    [
      getSortsForAPI,
      offset,
      pageSize,
      query,
      queryTenant,
      sortedEntityName,
      offsetReset,
    ],
  );

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
    refetch: refetchExcludedEntitiesPaginated,
  } = useQueryAlertMetadataExcludedEntitiesPaginated({
    skip: !query || !content_id || !queryTenant,
    variables: queryVariables,
    fetchPolicy: 'network-only',
    onCompleted: ({
      queryAlertMetadataExcludedEntitiesPaginated,
    }: IQueryAlertMetadataExcludedEntitiesPaginated) => {
      getPageCount(queryAlertMetadataExcludedEntitiesPaginated);
    },
  });

  const { loading, error, data, called, refetch } = useGetAlertMetadata({
    skip: !content_id || !queryTenant,
    variables: {
      items: [
        {
          content_id,
          tenant: queryTenant,
        },
      ],
    },
  });

  const {
    active,
    description,
    logsource,
    custom_search_rule,
    mitre_mappings,
    severity,
    title,
    tenant_alert,
    rule,
    cve_reference,
    category,
    severity_description,
    severity_info,
    event_generation_strategy,
  } = data?.getAlertMetadata?.metadata[0] || {};

  const {
    data: suricataAlertDescriptionData,
    loading: suricataAlertDescriptionLoading,
    error: suricataAlertDescriptionError,
  } = useGetAlertMetadataSuricataRuleDescription({
    skip:
      loading ||
      !content_id ||
      !queryTenant ||
      logsource?.category !== 'suricata_corelight',
    variables: {
      items: [
        {
          content_id,
          tenant: queryTenant,
        },
      ],
    },
  });

  useEffect(() => {
    if (orgTenantsQueryParam && orgTenantsQueryParam?.length > 1) {
      if (tenant && orgTenantsQueryParam?.includes(tenant)) {
        setQueryTenant(tenant);
      } else {
        setQueryTenant(user?.attributes['custom:tenant_id']);
      }
    } else if (orgTenantsQueryParam && orgTenantsQueryParam?.length === 1) {
      setQueryTenant(orgTenantsQueryParam?.[0]);
    }
  }, [user, tenant, orgTenantsQueryParam]);

  const [isGPTEnabled, setIsGPTEnabled] = useState<boolean>(false);
  const [getPrivileges, { loading: getPrivilegesLoading }] = useGetPrivileges();

  useEffect(() => {
    getPrivileges().then(
      (res: LazyQueryResult<IGetPrivileges, OperationVariables>) => {
        setIsGPTEnabled(
          JSON.parse(res?.data?.getPrivileges ?? '')?.chatgpt ?? '',
        );
      },
    );
  }, [getPrivileges, getPrivilegesLoading]);

  useEffect(() => {
    setAlertDescription(
      suricataAlertDescriptionData?.getAlertMetadata?.metadata?.[0]
        ?.chatbot_chats?.suri_describe_rule ??
        description ??
        '',
    );
  }, [suricataAlertDescriptionData]);

  const dataTableColumns: TableColumn<ExcludedEntity>[] = useMemo(
    () => [
      {
        width: 60,
        align: 'right',
        Header: i18n.tables.type,
        accessor: 'entity_type',
        Cell: (props: CellProps<ExcludedEntity>) => {
          return (
            <EntityTypeIcon
              type={`${stringifyEntityType(props?.value)}`}
              boxSize={5}
              m={-1}
            />
          );
        },
        disableSortBy: true,
      },
      {
        width: 135,
        align: 'right',
        Header: i18n.tables.entity,
        accessor: 'entity_name',
      },
      {
        align: 'right',
        Header: 'Added By',
        accessor: 'created_by_alias',
      },
      {
        align: 'right',
        Header: 'Date Added',
        accessor: 'created_timestamp',
        Cell: (props: CellProps<ExcludedEntity>) => (
          <>{props.value ? moment(props.value * 1000).format('LL') : ''}</>
        ),
      },
      ...(userRole !== 'viewer'
        ? ([
            {
              align: 'right',
              Header: i18n.tables.actions,
              accessor: 'content_id',
              Cell: (props: CellProps<ExcludedEntity>) => {
                const { original } = props.cell.row;
                return (
                  <DeleteModalRow
                    original={original}
                    tenant={queryTenant}
                    content_id={content_id}
                    title={title as string}
                    queryVariables={queryVariables}
                    type={logsource?.category as string}
                    refetchExcludedEntitiesPaginated={
                      refetchExcludedEntitiesPaginated
                    }
                  />
                );
              },
              disableSortBy: true,
            },
          ] as TableColumn<ExcludedEntity>[])
        : []),
    ],
    [
      content_id,
      queryVariables,
      totalExcludedEntities,
      title,
      logsource?.category,
    ],
  );

  const suricataSummary =
    i18n.pages.alertDetails.suricata.summary.cards.alertSummary;

  const [alertDetailsInfo, setAlertDetailsInfo] = useState<detailsProps[]>([]);

  useEffect(() => {
    const details: detailsProps[] = [
      {
        label: suricataSummary.type,
        input: logsource?.category,
        stringify: true,
      },
      {
        label: suricataSummary.category,
        input: category,
      },
      {
        label: suricataSummary.signatureId,
        input: event_generation_strategy?.alert_sid,
      },
      {
        label: suricataSummary.cve_reference,
        input: cve_reference,
      },
      {
        label: suricataSummary.message,
        input: title,
        lineBreak: [false, true],
      },
      {
        label: suricataSummary.description,
        input:
          !suricataAlertDescriptionError &&
          !suricataAlertDescriptionLoading &&
          isGPTEnabled
            ? suricataAlertDescriptionData?.getAlertMetadata?.metadata?.[0]
                ?.chatbot_chats?.suri_describe_rule
            : description ?? '',
        truncate: true,
      },
    ];

    setAlertDetailsInfo(details);
  }, [suricataAlertDescriptionLoading, alertDetails]);

  const modalData = {
    alert_type: logsource?.category ? logsource?.category : '',
    alert_name: title ? title : '',
    alert_severity: severity,
    signature_id: event_generation_strategy?.alert_sid,
    cve_reference,
    category,
    severity: severity_description,
    message: title ? title : '',
    description: description ?? alertDescription,
    custom_severity: severity_info?.custom_severity,
  };

  const [activeRow, setActiveRow] = useState<IActiveRow>({
    active: undefined,
    content_id: undefined,
    title: undefined,
    logsource: undefined,
  });

  useEffect(() => {
    const { active, title, logsource } =
      data?.getAlertMetadata?.metadata?.[0] || {};
    setActiveRow({
      active,
      content_id,
      title,
      logsource,
    });
  }, [data?.getAlertMetadata?.metadata[0], content_id, setActiveRow]);

  const scoreToShow = severity_info?.custom_severity ?? severity;

  const tacticsLength = mitre_mappings?.tactics?.reduce(
    (count, current) => (current?.name !== 'TACTIC' ? count + 1 : count + 0),
    0,
  );

  const techniquesLength = mitre_mappings?.tactics?.reduce(
    (count, current) =>
      current?.name !== 'TACTIC'
        ? count +
          current?.techniques?.reduce(
            (count, current) =>
              current?.name !== 'TECHNIQUE' ? count + 1 : count + 0,
            0,
          )
        : count + 0,
    0,
  );

  const excludedEntitiesPaginated =
    queryData?.queryAlertMetadataExcludedEntitiesPaginated;

  const excludedEntities = excludedEntitiesPaginated?.excluded_entities || [];

  const alert = data?.getAlertMetadata?.metadata?.[0];

  useEffect(() => {
    if (content_id && queryTenant) {
      refetch();
    }
  }, [refetch, content_id, queryTenant]);

  useEffect(() => {
    setTotalExcludedEntities(excludedEntitiesPaginated?.total_items);
  }, [loading, setTotalExcludedEntities, excludedEntitiesPaginated]);

  const isSuricata = logsource?.category === 'suricata_corelight';
  const checkRule = isSuricata && !!rule;

  const ModalWithEditorCommon = () => (
    <Box mt={2}>
      <SuricataRuleEditorModal value={dedent`${rule}`}>
        {
          <Button
            variant="solid"
            colorScheme="gray"
            w="100%"
            leftIcon={<MuiIcon>data_object</MuiIcon>}
          >
            {ruleModal.button}
          </Button>
        }
      </SuricataRuleEditorModal>
    </Box>
  );

  const isCustom = severity_info?.custom_severity;

  const lastUpdatedTimeStamp = moment(
    Number(severity_info?.last_updated_timestamp) * 1000,
  ).format('MMM DD, YYYY h:mm A');

  if (loading || error) {
    return (
      <GraphQLReqStatus
        loading={loading}
        error={error}
        extended={graphqlErrorRedirects}
      />
    );
  }

  return (
    <ErrorBoundary page="AlertDetails" styleClass="main">
      <PageHeader
        prevPage={systemSettingsAlertCatalog(`?${params.toString()}`)}
        breadcrumbs={[
          {
            label: (
              <Flex alignItems="center" justifyContent="center">
                <Text>{navigation.backToAlertCatalog}</Text>
              </Flex>
            ),
            link: systemSettingsAlertCatalog(`?${params.toString()}`),
          },
        ]}
      >
        <FederatedTenantSelect
          params={params}
          navigate={navigate}
          pathname={pathname}
        />
        <Flex mt={-6} mb={6} justifyContent="space-between">
          <Flex alignItems="center">
            {!loading && scoreToShow && (
              <Box mr={3}>
                <SeverityScore
                  score={scoreToShow}
                  size="xl"
                  isCustom={Boolean(isCustom)}
                />
              </Box>
            )}
            <Text as="h1">{alert?.title}</Text>
          </Flex>
        </Flex>
        <ButtonGroup spacing={2} w="100%" variant="solid" colorScheme="gray">
          {userRole !== 'viewer' && (
            <Button
              data-testid="edit-score-button"
              onClick={severityDisclosure.onOpen}
              leftIcon={<MuiIcon>edit</MuiIcon>}
            >
              {editScore}
            </Button>
          )}
          <Button
            data-testid="view-all-detections-button"
            onClick={() =>
              navigate({
                pathname: detections,
                search: `${
                  start && end ? `start=${start}&end=${end}&` : ''
                }alert_category=${title}`,
              })
            }
            leftIcon={<BullsEyeIcon boxSize={5} />}
          >
            {viewAllDetections}
          </Button>
        </ButtonGroup>
      </PageHeader>
      <Board data-testid="alert-details-page">
        <Column col={{ base: 12, lg: 4.5 }}>
          <Stack spacing={4}>
            <Panel>
              <PanelHeader divider>
                <PanelHeading>{properties}</PanelHeading>
              </PanelHeader>
              <Panel layerStyle="second">
                <VStack
                  w="100%"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  spacing={2}
                >
                  <Flex alignItems="center">
                    <Text
                      data-testid="category-status-text"
                      textAlign="right"
                      mr={3}
                      w="128px"
                    >
                      {categoryStatus}:
                    </Text>
                    <Flex alignItems="center">
                      {queryTenant && (
                        <AlertMetaStatusSwitch
                          tenant={queryTenant}
                          variables={variables}
                          activeRow={activeRow}
                          setActiveRow={setActiveRow}
                        />
                      )}
                      <Text
                        data-testid="alert-status-text"
                        textStyle="body-md"
                        ml={2}
                      >
                        {activeRow?.active
                          ? isActive.active
                          : isActive.inactive}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex alignItems="center">
                    <Text
                      textAlign="right"
                      mr={3}
                      w="128px"
                      textStyle="body-md"
                    >
                      {corelightScore}
                    </Text>
                    {severity_info?.default_severity ? (
                      <SeverityScore
                        dataTestId="corelight-severity-score"
                        score={severity_info?.default_severity ?? 0}
                      />
                    ) : (
                      <Text>{notApplicable}</Text>
                    )}
                  </Flex>
                  <Flex alignItems="center">
                    <Text
                      textAlign="right"
                      mr={3}
                      w="128px"
                      textStyle="body-md"
                    >
                      {customScore}
                    </Text>
                    {severity_info?.custom_severity ? (
                      <SeverityScore
                        dataTestId="custom-severity-score"
                        isCustom={true}
                        score={severity_info?.custom_severity}
                      />
                    ) : (
                      <Text>{notApplicable}</Text>
                    )}
                  </Flex>
                  <Flex alignItems="center">
                    <Text
                      textAlign="right"
                      mr={3}
                      w="128px"
                      textStyle="body-md"
                      data-testid="modified-by-text"
                    >
                      {modifiedBy}
                    </Text>
                    {severity_info?.last_updated_by_user_alias ? (
                      <Tag
                        variant="solid"
                        colorScheme="purple"
                        color="purple.50"
                        data-testid="modified-by-value"
                      >
                        <MuiIcon mr={2} size="sm">
                          account_circle
                        </MuiIcon>
                        {severity_info?.last_updated_by_user_alias}
                      </Tag>
                    ) : (
                      <Text>{notApplicable}</Text>
                    )}
                  </Flex>
                  <Flex alignItems="center">
                    <Text
                      textAlign="right"
                      mr={3}
                      w="128px"
                      textStyle="body-md"
                      data-testid="modified-by-time-text"
                    >
                      {modifiedTime}:
                    </Text>
                    {severity_info?.last_updated_timestamp ? (
                      <Text data-testid="modified-by-time-value">
                        {lastUpdatedTimeStamp}
                      </Text>
                    ) : (
                      <Text data-testid="modified-by-time-value">
                        {notApplicable}
                      </Text>
                    )}
                  </Flex>
                </VStack>
              </Panel>
            </Panel>
            <Panel>
              <PanelHeader divider>
                <PanelHeading>{details}</PanelHeading>
              </PanelHeader>
              <Stack spacing={4}>
                <Panel
                  layerStyle="second"
                  data-testid="alert-details-drawer-body"
                >
                  <VStack
                    w="100%"
                    alignItems="stretch"
                    justifyContent="flex-start"
                    spacing={2}
                  >
                    {!isSuricata && logsource?.category && (
                      <Flex alignItems="center">
                        <Box flex="0 0 64px" mr={3}>
                          <Text textAlign="right" textStyle="body-md">
                            {type}
                          </Text>
                        </Box>
                        <Text>{stringifyAlertType(logsource?.category)}</Text>
                      </Flex>
                    )}
                    {!isSuricata && !!tacticsLength && (
                      <Flex alignItems="center">
                        <Box flex="0 0 64px" mr={3}>
                          <Text textAlign="right" textStyle="body-md">
                            {tactics}
                          </Text>
                        </Box>
                        {mitre_mappings?.tactics.map((tactic, index) => (
                          <Text textStyle="body-md" key={index}>
                            {`${
                              index > 0 && index < tacticsLength ? ', ' : ''
                            }${tactic.name}`}
                          </Text>
                        ))}
                      </Flex>
                    )}
                    {!isSuricata && description && (
                      <Flex alignItems="flex-start">
                        <Box flex="0 0 64px" mr={3}>
                          <Text textAlign="right" textStyle="body-md">
                            {info}
                          </Text>
                        </Box>
                        <Text textStyle="body-md">{description}</Text>
                      </Flex>
                    )}
                    {isSuricata &&
                      alertDetailsInfo.map((detail, index) => {
                        return (
                          <SuricataAlertDetailsLayout
                            linesToTruncate={4}
                            modalData={modalData}
                            key={index}
                            label={detail?.label}
                            input={detail.input}
                            options={{
                              lineBreak: detail.lineBreak,
                              stringify: detail.stringify,
                              truncate: detail.truncate,
                            }}
                            isLoadingGPT={suricataAlertDescriptionLoading}
                            isLoadingSuricataDescription={
                              suricataAlertDescriptionLoading
                            }
                            isGPTEnabled={isGPTEnabled}
                          />
                        );
                      })}
                    {checkRule && (
                      <Flex direction="column">
                        <ModalWithEditorCommon />
                      </Flex>
                    )}
                  </VStack>
                </Panel>
                {!!techniquesLength && (
                  <Panel layerStyle="second">
                    <Flex
                      mb="4"
                      direction="column"
                      justifyContent="space-between"
                    >
                      <Flex direction="column">
                        <Flex mb={2}>
                          <Text>{mitreAttackTechniques}</Text>
                        </Flex>
                      </Flex>
                      <Flex direction="column">
                        {mitre_mappings?.tactics.map(
                          (tactic) =>
                            !!techniquesLength &&
                            tactic?.techniques?.map((technique, index) => (
                              <Flex mb={2} alignItems="center" key={index}>
                                {technique?.tag && (
                                  <Text
                                    mr={2}
                                    textStyle="body-md"
                                    textTransform="uppercase"
                                  >
                                    {technique.tag.split('.')[1]}
                                  </Text>
                                )}
                                {technique?.link && technique?.name ? (
                                  <ChakraLink
                                    color="text.link"
                                    isExternal={true}
                                    href={technique.link}
                                  >
                                    {technique.name}
                                  </ChakraLink>
                                ) : (
                                  technique.name && `${technique.name}`
                                )}
                              </Flex>
                            )),
                        )}
                      </Flex>
                      {checkRule && <ModalWithEditorCommon />}
                    </Flex>
                  </Panel>
                )}
              </Stack>
            </Panel>
          </Stack>
        </Column>
        <Column col={{ base: 12, lg: 7.5 }}>
          <Stack spacing={4}>
            <ExcludedEntitiesTableSearch
              setOffsetReset={setOffsetReset}
              setQuery={setQuery}
              content_id={content_id}
            />
            <Panel>
              <PanelHeader divider>
                <PanelHeading>
                  {totalExcludedEntities} {excludedEntitiesHeading}
                </PanelHeading>
                {userRole !== 'viewer' && (
                  <Button
                    size="md"
                    variant="solid"
                    data-testid="alert-catalog-details-add-excluded-entity"
                    onClick={disclosure.onOpen}
                    leftIcon={<MuiIcon>add</MuiIcon>}
                    my={-1}
                  >
                    {addNew}
                  </Button>
                )}
              </PanelHeader>
              {queryLoading || queryError ? (
                <GraphQLReqStatus
                  loading={queryLoading}
                  error={queryError}
                  extended={graphqlErrorRedirects}
                />
              ) : (
                <FlushPanelContent>
                  {excludedEntities.length || totalExcludedEntities ? (
                    <DataTable
                      pageSize={pageSize}
                      pageCount={pageCount}
                      autoResetPage={false}
                      autoResetSortBy={false}
                      isPaginationManual={true}
                      data={excludedEntities}
                      columns={dataTableColumns}
                      onFetchData={onFetchData}
                      itemCount={totalExcludedEntities}
                      initialState={
                        {
                          pageIndex: !query || offsetReset ? 0 : pageIndex,
                          sortBy,
                        } as Partial<TableState<ExcludedEntity>>
                      }
                    />
                  ) : (
                    <Column>
                      <Panel>{i18n.pages.alerts.na}</Panel>
                    </Column>
                  )}
                </FlushPanelContent>
              )}
            </Panel>
          </Stack>
        </Column>
      </Board>
      {userRole !== 'viewer' && title && content_id && (
        <AddEntityDrawer
          title={title}
          tenant={queryTenant}
          disclosure={disclosure}
          content_id={content_id}
          queryVariables={queryVariables}
          type={logsource?.category as string}
          refetchExcludedEntitiesPaginated={refetchExcludedEntitiesPaginated}
        />
      )}
      {userRole !== 'viewer' && _.isBoolean(active) && alert && (
        <CustomSeverityScoreModal
          alert={alert}
          severityDisclosure={severityDisclosure}
        />
      )}
    </ErrorBoundary>
  );
};
