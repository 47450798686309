import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

export const GET_ASSOCIATED_DETECTION_FOR_ALERT = gql`
  query getAssociatedDetectionForAlert($alert_id: String!) {
    getAssociatedDetectionForAlert(alert_id: $alert_id)
  }
`;

export const useGetAssociatedDetectionForAlert = (
  options: QueryHookOptions<OperationVariables>,
) => useQuery(GET_ASSOCIATED_DETECTION_FOR_ALERT, options);
