import { createIcon } from '@chakra-ui/react';

export const ProofPointLogo = createIcon({
  displayName: 'ProofPointLogo',
  viewBox: '0 0 519.18 96.99',
  path: (
    <g>
      <path d="M499.39 75.36V64.28h-3.83c-4.16 0-5.14-.62-5.14-3.52V30.33h9V20h-9V5H475v15h-7.66v10.33H475V60c0 11.49 2.74 15.63 15.54 15.63 1.64 0 4.6-.21 8.87-.31M410.91 74.94h15.54v-32.6c0-7.25 4.71-11.59 11.93-11.59 6.67 0 9 3.52 9 11v33.19h15.54V37.47c0-12.63-6.35-18.94-19.15-18.94-7.34 0-13.24 2.69-17.62 8V20h-15.24z"></path>
      <path d="M385.84 0.52H401.38V13.87H385.84z"></path>
      <path d="M385.84 19.98H401.38V74.94H385.84z"></path>
      <path d="M336.71 47.41c0-11 5-17 13.68-17s13.57 6 13.57 17-4.96 17.08-13.58 17.08-13.68-6.11-13.68-17.08m-15.76 0c0 18.32 11.16 29.39 29.44 29.39s29.44-11.07 29.44-29.39S368.66 18 350.38 18s-29.44 11.07-29.44 29.4M300.49 47.82c0 10.25-4.92 16.25-12.59 16.25-8.21 0-13-5.8-13-16.25 0-11.28 4.49-17.08 12.7-17.08s12.91 5.9 12.91 17.08M259.77 97h15.1V68.11c3.83 5.69 9.74 8.69 17.18 8.69 13.57 0 23.86-11.49 23.86-29.6 0-17.6-10-29.09-24.08-29.09-7.55 0-12.8 2.9-17.29 9.32V20h-14.77zM228.22 74.94h15.21V30.33h9.63V20h-9.74v-3.85c0-3.52 1.42-4.14 5.69-4.14h4.05V.31C250.33.1 247.93 0 245.63 0c-11.93 0-17.4 4.56-17.4 14.91V20h-8.32v10.33h8.32zM175.81 47.41c0-11 5-17 13.68-17s13.57 6 13.57 17-4.92 17.08-13.57 17.08-13.68-6.11-13.68-17.08m-15.76 0c0 18.32 11.16 29.39 29.44 29.39s29.44-11.07 29.44-29.39S207.76 18 189.48 18 160 29.09 160 47.41M113.38 47.41c0-11 5-17 13.68-17s13.57 6 13.57 17-4.92 17.08-13.57 17.08-13.68-6.11-13.68-17.08m-15.76 0c0 18.32 11.16 29.39 29.44 29.39s29.44-11.07 29.44-29.39S145.34 18 127.06 18 97.62 29.09 97.62 47.41M62.38 74.94h15.54V45.85c0-8.28 4.38-12.42 12.8-12.42h5.14v-14.9a17.07 17.07 0 00-2.3-.1c-7.44 0-12.7 3.41-16.75 11V20H62.38zM40.71 47.82c0 10.25-4.92 16.25-12.59 16.25-8.21 0-13-5.8-13-16.25 0-11.28 4.49-17.08 12.7-17.08s12.91 5.9 12.91 17.08M0 97h15.1V68.11c3.83 5.69 9.74 8.69 17.18 8.69 13.57 0 23.86-11.49 23.86-29.6 0-17.6-10-29.09-24.08-29.09-7.55 0-12.8 2.9-17.29 9.32V20H0zM519.18 70.4a6.07 6.07 0 11-6-5.9 5.93 5.93 0 016 5.9zm-10.62 0a4.57 4.57 0 004.61 4.72 4.52 4.52 0 004.5-4.68 4.56 4.56 0 10-9.11 0zm3.64 3.1h-1.37v-5.9a11.93 11.93 0 012.27-.18 3.68 3.68 0 012.05.43 1.64 1.64 0 01.58 1.3 1.51 1.51 0 01-1.22 1.37v.07a1.74 1.74 0 011.07 1.41 4.52 4.52 0 00.42 1.5h-1.48a5 5 0 01-.47-1.44c-.11-.65-.47-.94-1.22-.94h-.65zm0-3.35h.65c.76 0 1.37-.25 1.37-.86s-.4-.9-1.26-.9a3.26 3.26 0 00-.76.07z"></path>
    </g>
  ),
  defaultProps: {
    height: '96.99px',
    width: '519.18px',
  },
});
