import {
  Button,
  ButtonGroup,
  ButtonProps,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { gammaContext } from '@gamma/theme';
import { ReactChild, useContext } from 'react';
import { Modal, ModalProps } from '../Modal';

export interface ConfirmationModalProps extends Omit<ModalProps, 'footer'> {
  /** The text to use for the confirmation button */
  confirmText?: ReactChild;
  /** The props to pass to the confirmation button */
  confirmProps?: ButtonProps;
  /** The tooltip content for the confirmation button */
  confirmTooltip?: ReactChild;
  /** The text to use for the cancellation button */
  cancelText?: ReactChild;
  /** The props to pass to the cancellation button */
  cancelProps?: ButtonProps;
  /** The tooltip content for the cancellation button */
  cancelTooltip?: ReactChild;
  /** If `true` the confirmation button will be hidden */
  isConfirmHidden?: boolean;
  /** If `true` the cancellation button will be hidden */
  isCancelHidden?: boolean;
  /** If `true` the footer buttons will be centered */
  isFooterCentered?: boolean;
  /**
   * If `false` the modal will not close on confirm click regardless of
   * `onConfirm`s return value
   */
  isClosedOnConfirm?: boolean;
  /**
   * If `false` the modal will not close on confirm click regardless of
   * `onCancel`s return value
   */
  isClosedOnCancel?: boolean;
  /**
   * The callback triggered when the confirmation button is clicked
   * If false is returned, the modal will stay open
   */
  onConfirm?: () => void | boolean | Promise<boolean | void | undefined>;
  /**
   * The callback triggered when the cancellation button is clicked
   * If false is returned, the modal will stay open
   */
  onCancel?: () => void | boolean | Promise<boolean | void | undefined>;
}

export const ConfirmationModal = ({
  children,
  onConfirm,
  onCancel,
  onClose: propsOnClose,
  onOpen: propsOnOpen,
  isOpen: propsIsOpen,
  confirmProps = {},
  confirmTooltip,
  confirmText,
  cancelProps = {},
  cancelTooltip,
  cancelText,
  isConfirmHidden = false,
  isCancelHidden = false,
  isFooterCentered = false,
  isClosedOnConfirm = true,
  isClosedOnCancel = true,
  size = 'xl',
  'data-testid': dataTestId = 'confirmation-modal',
  ...props
}: ConfirmationModalProps) => {
  const { i18n } = useContext(gammaContext);
  const { isOpen, onOpen, onClose } = useDisclosure({
    isOpen: propsIsOpen,
    onOpen: propsOnOpen,
    onClose: propsOnClose,
  });

  const handleCancellation = async () => {
    const shouldClose = await onCancel?.();
    if (isClosedOnCancel || shouldClose === true) {
      onClose();
    }
  };

  const handleConfirmation = async () => {
    const shouldClose = await onConfirm?.();

    if (isClosedOnConfirm || shouldClose === true) {
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      data-testid={dataTestId}
      size={size}
      footer={
        <ButtonGroup
          spacing={2}
          justifyContent={'flex-end'}
          w="full"
          data-testid={`${dataTestId}-footer-buttons`}
        >
          {isCancelHidden ? (
            <span />
          ) : (
            <Tooltip
              data-testid={`${dataTestId}-cancel-tooltip`}
              label={cancelTooltip}
              placement="top"
              hasArrow
              isDisabled={!cancelTooltip}
            >
              <Button
                variant="outline"
                colorScheme="gray"
                onClick={handleCancellation}
                data-testid={`${dataTestId}-cancel-button`}
                {...cancelProps}
              >
                {cancelText || i18n.globals.cancel}
              </Button>
            </Tooltip>
          )}
          {!isConfirmHidden && (
            <Tooltip
              data-testid={`${dataTestId}-confirm-tooltip`}
              label={confirmTooltip}
              placement="top"
              hasArrow
              isDisabled={!confirmTooltip}
              shouldWrapChildren={confirmProps.isDisabled}
            >
              <Button
                variant="solid"
                colorScheme="blue"
                data-testid={`${dataTestId}-confirm-button`}
                {...(onConfirm ? { onClick: handleConfirmation } : {})}
                {...confirmProps}
              >
                {confirmText || i18n.globals.confirm}
              </Button>
            </Tooltip>
          )}
        </ButtonGroup>
      }
      isCentered
      {...props}
    >
      {children}
    </Modal>
  );
};
