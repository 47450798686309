import { createIcon } from '@chakra-ui/react';

export const SplunkLogo = createIcon({
  displayName: 'SplunkLogo',
  viewBox: '0 0 75 23',
  path: (
    <path
      fillRule="nonzero"
      d="M6.784 7.727s-.61-.65-1.834-.65c-.727 0-1.449.369-1.433 1.143.033 1.628 4.424 2.933 4.421 5.59 0 .498.276 3.603-4.314 3.834-.088.005-1.827.104-3.624-1.351l.96-1.663c1.741 1.466 2.333 1.156 2.473 1.15 1.543-.06 1.524-1.189 1.517-1.499C4.906 12.343.53 11.42.625 8.466.698 6.204 2.77 5.294 4.46 5.294c1.824 0 3.236.924 3.236.924l-.912 1.509zM17.108 5.298c-3.392-.107-4.333 2.009-4.333 2.009l.015-1.768H9.706v17.402h3.084l-.03-7.386s1.249 1.86 3.539 1.86c4.372 0 5.76-3.968 5.76-6.188 0-2.455-1.141-5.808-4.95-5.929zM15.758 15.6c-2.073 0-3.001-1.897-3.001-4.237s.99-4.238 3-4.238c2.2 0 3.001 1.897 3.001 4.238 0 2.152-.971 4.237-3 4.237zM22.941 0h2.647v17.647h-2.647zM30.312 13.152s-.203 2.466 2.205 2.451c.211-.001 2.436.16 2.513-2.977l-.004-6.45h2.915v11.207h-2.91l-.005-1.503c-1.596 1.868-3.595 1.766-3.699 1.766-3.974.002-3.974-3.619-3.974-3.619v-7.85h2.959v6.975zM48.217 9.79s.204-2.467-2.204-2.452c-.212.001-2.437-.16-2.514 2.977l.005 6.45h-2.916V5.558H43.5l.005 1.503c1.595-1.868 3.595-1.766 3.698-1.766 3.974-.001 3.974 3.619 3.974 3.619v7.85h-2.958V9.79zM55.999 17.447h-3.058V0H56v10.262h.32l4.168-4.864 2.312 1.02-3.94 4.3 4.67 5.955-2.875.974-4.558-6.453-.097-.004zM75 12.952l-9.706 4.695v-2.05l7.577-3.685-7.577-3.592V6.176L75 10.965z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    height: '23px',
    width: '75px',
  },
});
