import { ComponentStyleConfig } from '@chakra-ui/react';
import { getColor, mode, transparentize } from '@chakra-ui/theme-tools';

const bgLayerMap: Map<string, string> = new Map([
  ['base', 'layer.0'],
  ['first', 'layer.1'],
  ['second', 'layer.2'],
  ['third', 'layer.3'],
]);

const lightBgLayerMap: Map<string, string> = new Map([
  ['base', 'layer.1'],
  ['first', 'layer.2'],
  ['second', 'layer.3'],
  ['third', 'layer.2'],
]);

const borderLayerMap: Map<string, string> = new Map([
  ['base', 'border.layer.0'],
  ['first', 'border.layer.1'],
  ['second', 'border.layer.2'],
  ['third', 'border.layer.3'],
]);

export const dataListStyles: ComponentStyleConfig = {
  parts: [
    'container',
    'header',
    'body',
    'divider',
    'item',
    'selectedItem',
    'pagination',
    'paginationButton',
  ],
  baseStyle: (props) => {
    const { layerStyle = 'first' } = props;
    const bg = bgLayerMap.get(layerStyle);
    const lightBg = lightBgLayerMap.get(layerStyle);
    const border = borderLayerMap.get(layerStyle);
    return {
      container: {
        bg: bg,
        h: '100%',
        w: '100%',
        display: 'flex',
        flexDir: 'column',
        justifyContent: 'space-between',
      },
      header: {
        bg: bg,
        bgColor: bg,
        py: 3.5,
        px: 4,
      },
      body: {
        display: 'flex',
        flexDir: 'column',
        h: 'calc(100% - var(--chakra-space-12))',
        overflowX: 'hidden',
        overflowY: 'auto',
      },
      divider: {
        borderColor: border,
      },
      item: {
        px: 5, // not correct, but resolves alignment issue with the page size selection
        py: 3,
        w: 'full',
        textAlign: 'left',
        cursor: 'pointer',
        position: 'relative',
        _hover: {
          bg: transparentize(getColor(props.theme, 'blue.500'), 0.1)(props),
        },
      },
      selectedItem: {
        px: 5, // not correct, but resolves alignment issue with the page size selection
        py: 3,
        bg: 'layer.2',
        w: 'full',
        textAlign: 'left',
        cursor: 'pointer',
        position: 'relative',
        _hover: {
          bg: transparentize(getColor(props.theme, 'blue.500'), 0.1)(props),
        },
        _after: {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          width: '3px',
          bg: 'blue.500',
        },
      },
      pagination: {
        position: 'sticky',
        boxShadow: `0px -5px 10px -5px var(--chakra-colors-${mode(
          'gray-50',
          'gray-900',
        )(props)})`,
        display: 'flex',
        px: 4,
        py: 2,
        alignItems: 'center',
        justifyContent: 'space-between',
        ...(props.isLined && {
          borderTop: 'solid 1px',
          borderColor: mode('gray.50', 'gray.800')(props),
        }),
        height: 12,
      },
      paginationButton: {
        mr: 'auto',
        bg: 'transparent !important',
        border: 'solid 1px',
        borderRadius: 'base',
        borderColor: 'transparent',
        color: 'blue.300',
        _disabled: {
          color: 'gray.600',
        },
        _focusVisible: {
          outline: 'none',
          borderColor: 'blue.500',
          boxShadow: '0px 0px 0px 1.5px #0093ee',
        },
      },
    };
  },
};
