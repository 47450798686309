import { createIcon } from '@chakra-ui/react';

export const CircleTriangleInfoIcon = createIcon({
  displayName: 'CircleTriangleInfoIcon',
  viewBox: '0 0 24 24',
  d: 'M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm-.546-12.447v2.894h1.091V9.553h-1.09Zm0 4.052v1.158h1.091v-1.158h-1.09ZM18 16.5H6l6-11 6 11Z"',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
