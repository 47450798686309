import { Tag, Tooltip } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface PillWrapperProps {
  label?: string;
  children?: ReactNode;
}

export const PillWrapper = ({ children, label }: PillWrapperProps) => {
  return (
    <Tooltip label={label}>
      <Tag size="md" variant="outline" colorScheme={'orange'}>
        {children}
      </Tag>
    </Tooltip>
  );
};
