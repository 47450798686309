import { createIcon } from '@chakra-ui/react';

export const CircleCloseIcon = createIcon({
  displayName: 'CircleCloseIcon',
  viewBox: '0 0 24 24',
  d: 'M12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Zm0-18C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2Zm2.59 6L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
