import { useState } from 'react';
import { Outlet } from 'react-router';

export interface IForgotPasswordContext {
  userEmail: string;
  setUserEmail: (email: string) => void;
}

export const ForgotPasswordOutlet = () => {
  const [userEmail, setUserEmail] = useState<string>();

  return (
    <Outlet context={{ userEmail, setUserEmail } as IForgotPasswordContext} />
  );
};
