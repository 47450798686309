import { usePrevious } from '@gamma/hooks';
import { useEffect, useState } from 'react';

export const useIsNewSearch = ({
  filters,
}: {
  filters: { id: string; value: string }[] | null;
}) => {
  const [isNewSearch, setIsNewSearch] = useState(false);

  const prevFilters = usePrevious(filters);

  useEffect(() => {
    if (!filters?.length) {
      setIsNewSearch(false);
    } else {
      const hasNoMatch = filters?.every(
        (filter, index) => prevFilters?.[index]?.value !== filter?.value,
      );

      setIsNewSearch(hasNoMatch);
    }
  }, [filters, prevFilters]);

  return { isNewSearch };
};
