import { createIcon } from '@chakra-ui/react';

export const BoxCloudIcon = createIcon({
  displayName: 'BoxCloudIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H4Zm4 12h8.5a2.5 2.5 0 0 0 0-5H16a4 4 0 0 0-4-4c-1.87 0-3.42 1.28-3.86 3H8a3 3 0 0 0 0 6Z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
