import { createIcon } from '@chakra-ui/react';

export const RefreshIcon = createIcon({
  displayName: 'RefreshIcon',
  viewBox: '0 0 24 24',
  d: 'M12 6a6 6 0 0 0-6 6c0 1 .25 1.97.7 2.8l-1.46 1.46A7.93 7.93 0 0 1 4 12a8 8 0 0 1 8-8V1l4 4-4 4V6Zm0 14v3l-4-4 4-4v3a6 6 0 0 0 6-6c0-1-.25-1.97-.7-2.8l1.46-1.46A7.93 7.93 0 0 1 20 12a8 8 0 0 1-8 8Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
