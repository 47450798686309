import { Box, Button, useStyleConfig } from '@chakra-ui/react';
import { RoundedChevronIcon } from '@gamma/icons';
import { ReactNode } from 'react';

export interface SeverityScoreProps {
  score: number;
  isCustom?: boolean;
  isSelected?: boolean;
  isActive?: boolean;
  dataTestId?: string;
  size?: string;
  children?: ReactNode;
  customStyles?: React.CSSProperties;
  isButton?: boolean;
}

export const SeverityScore = ({
  score,
  isCustom,
  isActive,
  isSelected,
  size,
  children,
  dataTestId,
  customStyles,
  isButton = true,
}: SeverityScoreProps) => {
  const scoreColor =
    score > 6 && score < 11
      ? 'red'
      : score > 3 && score < 8
      ? 'yellow'
      : score > 0 && score < 4
      ? 'teal'
      : '';

  const scoreBoxTestId =
    score > 6 && score < 11
      ? 'high'
      : score > 3 && score < 8
      ? 'med'
      : score > 0 && score < 4
      ? 'low'
      : score < 1 || score > 10
      ? 'out-of-range'
      : 'unknown';

  const styles = useStyleConfig('SeverityBox', {
    colorScheme: scoreColor,
    size,
    isActive,
    isSelected,
  });

  const contents = (
    <>
      {children ?? score}
      {score >= 1 && score <= 10 && isCustom && (
        <RoundedChevronIcon
          position="absolute"
          bottom="-18px"
          right="-18px"
          color={`${styles.color}`}
        />
      )}
    </>
  );

  return score !== null && score !== undefined ? (
    isButton ? (
      <Button
        __css={styles}
        style={customStyles}
        data-testid={dataTestId ?? `rank-severity-${scoreBoxTestId}`}
      >
        {contents}
      </Button>
    ) : (
      <Box
        __css={styles}
        style={customStyles}
        data-testid={dataTestId ?? `rank-severity-${scoreBoxTestId}`}
      >
        {contents}
      </Box>
    )
  ) : null;
};
