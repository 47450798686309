import { Heading, useStyles } from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface CardPageHeadingProps {
  children: ReactNode;
  'data-testid'?: string;
}

export const CardPageHeading = ({
  children,
  'data-testid': dataTestId = 'card-page-heading',
}: CardPageHeadingProps) => {
  const styles = useStyles();
  return (
    <Heading {...(styles.heading as any)} data-testid={dataTestId}>
      {children}
    </Heading>
  );
};
