import { createIcon } from '@chakra-ui/react';

export const SortIconDown = createIcon({
  displayName: 'SortIconDown',
  viewBox: '0 0 16 16',
  d: 'M12.667 10.667h2L12 13.333l-2.667-2.666h2v-8h1.334v8ZM8 4.667H1.333V3.333H8v1.334Zm-4 6.666v1.334H1.333v-1.334H4Zm2-2.666H1.333V7.333H6v1.334Z',
  defaultProps: {
    height: '16px',
    width: '16px',
  },
});
