import { createIcon } from '@chakra-ui/react';

export const CircleTrashIcon = createIcon({
  displayName: 'CircleTrashIcon',
  viewBox: '0 0 24 24',
  d: 'M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20Zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm4 6v7a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-7h8Zm-2.5-4 1 1H17v2H7V7h2.5l1-1h3Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
