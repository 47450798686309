import { Button } from '@chakra-ui/react';
import { transparentize } from '@chakra-ui/theme-tools';
import { DateObj, RenderProps } from 'dayzed';
import React from 'react';

export interface DayOfMonthProps {
  renderProps: RenderProps;
  isInRange?: boolean | null;
  dateObj: DateObj;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const DayOfMonth = ({
  dateObj,
  isInRange,
  renderProps,
  onMouseEnter,
}: DayOfMonthProps) => {
  const { date, selected, selectable, today } = dateObj;
  const { getDateProps } = renderProps;
  const variant = selected || isInRange ? 'primary' : 'outline';
  // bg = !selectable ? customBtnProps?.disabledBg || 'red.200' : bg;
  const halfGap = 0.125; //default Chakra-gap-space-1 is 0.25rem

  return (
    <Button
      {...getDateProps({
        dateObj,
        disabled: !selectable,
        onMouseEnter: onMouseEnter,
      })}
      data-testid={
        selected ? 'calendar-panel-selected-day' : 'calendar-panel-day'
      }
      isDisabled={!selectable}
      variant={variant}
      _hover={{
        bg: transparentize('blue.200', 0.3),
      }}
      size="sm"
      // this intends to fill the visual gap from Grid to improve the UX
      // so the button active area is actually larger than when it's seen
      _after={{
        content: "''",
        position: 'absolute',
        top: `-${halfGap}rem`,
        left: `-${halfGap}rem`,
        bottom: `-${halfGap}rem`,
        right: `-${halfGap}rem`,
        borderWidth: `${halfGap}rem`,
        borderColor: 'transparent',
      }}
      borderColor={today ? 'blue.400' : 'transparent !important'}
    >
      {date.getDate()}
    </Button>
  );
};
