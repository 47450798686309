import { createIcon } from '@chakra-ui/react';

export const RoundedChevronIcon = createIcon({
  displayName: 'RoundedChevron',
  viewBox: '0 0 24 24',
  d: 'M5 0L0 5H3C4.10457 5 5 4.10457 5 3V0Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
