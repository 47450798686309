import { Button, ButtonGroup, Center } from '@chakra-ui/react';
import { i18n } from '@gamma/investigator/localization';
import { Modal } from '@gamma/overlay';

import { LicenseComparisonTable } from '@gamma/shared/license-comparison-table';

const { compareLicense } = i18n.pages.licenseDashboard.licenseStatus.buttons;
const { licenseDetails } = i18n.pages.licenseDashboard;

interface LicenseComparisonProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}

export const LicenseComparisonModal = ({
  isOpen,
  onClose,
  onOpen,
}: LicenseComparisonProps) => {
  return (
    <Modal
      title={licenseDetails}
      data-testid="license-comparison-modal"
      size="xl"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      body={<LicenseComparisonTable />}
      footer={
        <ButtonGroup spacing={4}>
          <Button variant="solid" colorScheme="blue" onClick={onClose}>
            {i18n.buttons.close}
          </Button>
        </ButtonGroup>
      }
    >
      <Center>
        <Button
          color={'text.link'}
          textStyle={'body-md'}
          alignSelf={'flex-end'}
        >
          {compareLicense}
        </Button>
      </Center>
    </Modal>
  );
};
