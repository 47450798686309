import { createIcon } from '@chakra-ui/react';

export const CheckIcon = createIcon({
  displayName: 'CheckIcon',
  viewBox: '0 0 24 24',
  d: 'M21 7 9 19l-5.5-5.5 1.41-1.41L9 16.17 19.59 5.59 21 7Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
