import {
  Button,
  HStack,
  ListItem,
  Spinner,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Checkbox, Input, Select } from '@gamma/form-fields';
import { REGEX } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  LIST_USERS,
  useCreateUser,
  useListGroups,
} from '@gamma/investigator/queries';
import { Drawer } from '@gamma/overlay';
import { mergeRefs } from '@gamma/util';
import _ from 'lodash';
import { ReactNode, useContext, useMemo, useRef } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';

interface CreateUserDrawerProps {
  children: ReactNode;
  isOpen?: boolean;
}

export const CreateUserDrawer = ({
  children,
  isOpen: propsIsOpen,
}: CreateUserDrawerProps) => {
  const { roles } = i18n;
  const { buttons, form, toasts } = i18n.pages.usersAccess;
  const aliasRef = useRef<HTMLInputElement | null>(null);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setFocus,
    formState: { errors },
    reset,
  } = useForm();

  // Destructured ref to share it's usage.
  const { ref, ...registerAlias } = register(form.fields.alias, {
    required: form.errors.required,
    maxLength: {
      value: 30,
      message: i18n.forms.validation.maxLength,
    },
    pattern: {
      value: /[^-\s]/,
      message: i18n.forms.validation.aliasRequired,
    },
  });
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: propsIsOpen,
  });
  const showToastMessage = useToast();

  // Add User API
  const { user } = useContext(AuthContext);
  const [createUser, { loading: createUserLoading, reset: resetMutation }] =
    useCreateUser({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: LIST_USERS,
          variables: { tenants: [`${user?.attributes['custom:tenant_id']}`] },
        },
      ],
      onCompleted: () => {
        showToastMessage({
          status: 'success',
          title: toasts.create.success.title,
          description: getValues('is_disabled')
            ? toasts.create.success.descriptionDisabled
            : toasts.create.success.description,
          isClosable: true,
          position: 'bottom-right',
        });
        onClose();
        reset();
      },
      onError: (error: Error) => {
        // Note: Below check is based on error message returned from cognito.
        const userAlreadyExists =
          error.message === 'This username already exists';
        if (userAlreadyExists) {
          setFocus('email');
          setError('email', {
            message: toasts.create.error.userAlreadyExistsDescription,
          });
        }

        const ssoDomain =
          error.message ===
          'SSO enabled. User cannot be created for this domain.';
        if (ssoDomain) {
          setFocus('email');
          setError('email', {
            message: error.message,
          });
        }

        showToastMessage({
          status: 'error',
          title: toasts.create.error.title,
          description: userAlreadyExists
            ? toasts.create.error.userAlreadyExistsDescription
            : error.message,
          isClosable: true,
          position: 'bottom-right',
        });
        resetMutation();
      },
    });

  const { data: listGroupData, loading: listGroupsLoading } = useListGroups({
    onError: (error: Error) => {
      showToastMessage({
        status: 'error',
        title: toasts.listGroups.error.title,
        description: toasts.listGroups.error.description,
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  const onSubmit = async (userData: FieldValues) => {
    const user = {
      ...userData,
      group: userData.group.value,
      alias: userData.alias.trim(),
    };
    await createUser({
      variables: { input: user },
    });
  };

  const listGroupOptions = useMemo(() => {
    return listGroupData?.listGroups?.map((data: { group_name: string }) => {
      return {
        value: data.group_name,
        label: _.capitalize(data.group_name),
      };
    });
  }, [listGroupData?.listGroups]);

  if (listGroupsLoading) {
    return <Spinner />;
  }

  return (
    <Drawer
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={() => {
        onClose();
        reset();
      }}
      initialFocusRef={aliasRef}
      closeOnEsc={!createUserLoading}
      closeOnOverlayClick={!createUserLoading}
      isCloseDisabled={createUserLoading}
      title={form.title.create}
      data-testid="create-user-drawer"
      body={
        <Stack
          h="100%"
          as="form"
          spacing={4}
          justifyContent="space-between"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <VStack
            w="full"
            alignItems="start"
            css={{ ':first-child > div': { width: '100%' } }}
          >
            <Input
              data-testid="input_alias"
              {...registerAlias}
              ref={mergeRefs(ref, aliasRef)}
              label={form.fields.alias}
              placeholder={form.fields.alias}
              type="username"
              isRequired
              error={errors.alias?.message?.toString()}
            />
            <Input
              data-testid="input_email"
              {...register(form.fields.email, {
                required: form.errors.required,
                pattern: {
                  value: REGEX.EMAIL,
                  message: form.errors.invalid,
                },
              })}
              label={form.fields.email}
              placeholder={form.fields.email}
              type={form.fields.email}
              isRequired
              error={errors.email?.message?.toString()}
            />
            {/* @ts-ignore */}
            <Controller
              name="group"
              control={control}
              rules={{ required: form.errors.required }}
              render={({ field: { onChange, name, value } }) => (
                <Select
                  name={name}
                  value={value}
                  onChange={onChange}
                  isRequired
                  label={form.fields.role}
                  data-testid="role-select"
                  options={listGroupOptions || []}
                  error={errors.group?.message?.toString()}
                  tooltip={
                    <VStack w="320px" alignItems="start">
                      <Text textStyle="body-md-bold">Roles</Text>
                      <UnorderedList>
                        <ListItem>
                          <Text as="span" textStyle="body-md-bold">
                            {roles.admin}:{' '}
                          </Text>
                          <Text as="span" textStyle="body-md-normal">
                            {roles.descriptions.admin}
                          </Text>
                        </ListItem>
                        <ListItem>
                          <Text as="span" textStyle="body-md-bold">
                            {roles.analyst}:{' '}
                          </Text>
                          <Text as="span" textStyle="body-md-normal">
                            {roles.descriptions.analyst}
                          </Text>
                        </ListItem>
                        <ListItem>
                          <Text as="span" textStyle="body-md-bold">
                            {roles.viewer}:{' '}
                          </Text>
                          <Text as="span" textStyle="body-md-normal">
                            {roles.descriptions.viewer}
                          </Text>
                        </ListItem>
                      </UnorderedList>
                    </VStack>
                  }
                />
              )}
            />
            <Checkbox {...register('is_disabled')} tooltip={form.inactiveUser}>
              <Text
                color="text.primary"
                textTransform="none"
                data-testid="form-checkbox"
              >
                {form.checkbox}
              </Text>
            </Checkbox>
          </VStack>
          <HStack py={4} w="100%" justifyContent="end">
            <Button
              w="auto"
              onClick={onClose}
              variant="solid"
              colorScheme="gray"
              isDisabled={createUserLoading}
            >
              {buttons.cancel}
            </Button>
            <Button
              w="auto"
              data-testid="input_button"
              isLoading={createUserLoading}
              variant="solid"
              colorScheme="blue"
              type="submit"
            >
              {buttons.create}
            </Button>
          </HStack>
        </Stack>
      }
    >
      {children}
    </Drawer>
  );
};
