import { ComponentStyleConfig } from '@chakra-ui/react';
import { textStyles } from '../../common';

export const FileUploadBoxStyles: ComponentStyleConfig = {
  parts: ['dropzone', 'listItem'],
  baseStyle: (props) => {
    const cursor = props.isDisabled
      ? 'not-allowed'
      : props.isLoading
      ? 'initial'
      : 'pointer';

    return {
      dropzone: {
        display: 'flex',
        bg: 'layer.1',
        border: 'dashed 1px',
        borderColor: props.isFocused ? 'transparent' : 'border.1',
        borderRadius: 'base',
        color: 'blue.300',
        boxShadow: props.isFocused ? 'outline' : undefined,
        cursor,
        opacity: props.isDisabled ? 0.5 : 1,
        ...textStyles['body-md'],
      },
      listItem: {
        py: 1.5,
        px: 2,
        bg: 'layer.1',
        borderRadius: 'base',
        border: 'solid 1px',
        borderColor: 'border.1',
      },
    };
  },
  sizes: {
    sm: {
      dropzone: {
        gap: 1,
        py: 1.5,
        px: 2,
      },
    },
    md: {
      dropzone: {
        minHeight: 24,
        gap: 2,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        py: 2,
        px: 2,
      },
    },
    lg: {
      dropzone: {
        minHeight: '128px',
        gap: 2,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        py: 2,
        px: 2,
      },
    },
  },
};
