import { ApexOptions } from 'apexcharts';
import _ from 'lodash';
import moment from 'moment';
import { SeriesItem } from './seriesData';

export const intColor = (int: number, opacity?: string) =>
  _.inRange(int, 4, 7)
    ? opacity
      ? `rgba(210, 161, 6, ${opacity})`
      : '#D2A106'
    : _.inRange(int, 7, 11)
    ? opacity
      ? `rgba(255, 131, 137, ${opacity})`
      : '#FF8389'
    : opacity
    ? 'rgba(8, 189, 186, .1)'
    : '#08BDBA';

let activeChart: string;

export const chartOptions = ({
  end,
  start,
  maxRows,
  titleText,
  chartIndex,
  chartGroup,
  seriesData,
  showToolbar,
  chartWrapper,
  renderCustomizations,
  setSelectedDetectionId,
}: {
  end: number;
  start: number;
  maxRows: number;
  chartIndex: number;
  chartGroup: string;
  titleText: string;
  showToolbar: boolean;
  chartWrapper: React.RefObject<HTMLDivElement>;
  seriesData: {
    row_count: number;
    series: SeriesItem[];
  };
  renderCustomizations: (chartWrapper: React.RefObject<HTMLDivElement>) => void;
  setSelectedDetectionId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}): ApexOptions => {
  return {
    title: {
      floating: chartIndex !== 0 ? true : false,
      text: `${titleText} (${
        seriesData.row_count <= maxRows ? seriesData.row_count : maxRows
      }/${seriesData.row_count})`,
      style: {
        color: '#FFFFFF',
        fontSize: '14px',
        fontWeight: 600,
      },
    },
    tooltip: {
      shared: false,
      cssClass: `chart-${chartIndex}-tooltip`,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        var data =
          w?.globals?.initialSeries?.[seriesIndex]?.data?.[dataPointIndex];
        return activeChart === `chart-${chartIndex}`
          ? `<div class="detection-tooltip">${data?.alert_name}<br />${
              data?.entity_name
            }<br />${moment(data?.y?.[0]).format('lll')}<br />${moment(
              data?.y?.[1],
            ).format('lll')}</div>`
          : '';
      },
    },
    colors: seriesData.series
      .filter((series) => series.name !== '')
      .map((series) =>
        series?.name
          ? intColor(series.name.split(' ')[0] as unknown as number, '0.1')
          : '',
      ),
    states: {
      normal: {
        filter: { type: 'none', value: 0 },
      },
      hover: {
        filter: { type: 'lighten', value: 0.5 },
      },
      active: {
        filter: { type: 'darken', value: 0.2 },
        allowMultipleDataPointsSelection: false,
      },
    },
    chart: {
      group: chartGroup,
      id: `chart-${chartIndex}`,
      events: {
        animationEnd: function (chartContext, options) {},
        beforeMount: function (chartContext, config) {},
        mounted: function (chartContext, config) {},
        updated: function (chartContext, config) {},
        mouseMove: function (event, chartContext, opts) {
          activeChart = chartContext?.w?.globals?.chartID;
        },
        mouseLeave: function (event, chartContext, opts) {},
        click: (chart, options) => {
          renderCustomizations(chartWrapper);
        },
        legendClick: function (chartContext, seriesIndex, opts) {},
        markerClick: function (event, chartContext, opts) {},
        xAxisLabelClick: function (event, chartContext, opts) {},
        selection: function (chartContext, { xaxis, yaxis }) {},
        dataPointSelection: (e, chart, options) => {
          setSelectedDetectionId(
            chart.opts.series[options.seriesIndex].data[options.dataPointIndex]
              ?.detection_id,
          );
        },
        dataPointMouseEnter: function (event, chartContext, opts) {},
        dataPointMouseLeave: function (event, chartContext, opts) {},
        beforeZoom: function (chartContext, { xaxis }) {},
        beforeResetZoom: function (chartContext, opts) {},
        zoomed: function (chartContext, { xaxis, yaxis }) {
          renderCustomizations(chartWrapper);
        },
        scrolled: function (chartContext, { xaxis }) {
          renderCustomizations(chartWrapper);
        },
      },
      type: 'rangeBar',
      toolbar: {
        autoSelected: 'pan',
        show: true,
        offsetX: showToolbar ? 0 : -10000,
        offsetY: 0,
        tools: {
          download: false,
          pan: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M408.781 128.007C386.356 127.578 368 146.36 368 168.79V256h-8V79.79c0-22.43-18.356-41.212-40.781-40.783C297.488 39.423 280 57.169 280 79v177h-8V40.79C272 18.36 253.644-.422 231.219.007 209.488.423 192 18.169 192 40v216h-8V80.79c0-22.43-18.356-41.212-40.781-40.783C121.488 40.423 104 58.169 104 80v235.992l-31.648-43.519c-12.993-17.866-38.009-21.817-55.877-8.823-17.865 12.994-21.815 38.01-8.822 55.877l125.601 172.705A48 48 0 0 0 172.073 512h197.59c22.274 0 41.622-15.324 46.724-37.006l26.508-112.66a192.011 192.011 0 0 0 5.104-43.975V168c.001-21.831-17.487-39.577-39.218-39.993z"/></svg>`,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '50%',
        rangeBarGroupRows: true,
      },
    },
    yaxis: {
      labels: {
        minWidth: 250,
        maxWidth: 250,
        align: 'left',
        style: {
          colors: '#FFFFFF',
          fontSize: '12px',
        },
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        show: false,
        style: {
          colors: '#FFFFFF',
        },
      },
      axisBorder: {
        color: '#525252',
      },
      axisTicks: {
        show: false,
        color: '#525252',
      },
      min: start,
      max: end,
    },
    stroke: {
      width: 1,
    },
    fill: {
      type: 'solid',
      opacity: 0.6,
    },
    legend: {
      show: false,
      position: 'left',
      horizontalAlign: 'left',
      showForNullSeries: false,
      labels: {
        colors: '#FFFFFF',
      },
    },
    grid: {
      show: false,
      borderColor: '#525252',
    },
    series: seriesData.series as unknown as ApexAxisChartSeries,
  };
};
