import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

import { EscalatedDetection } from './types';

export const ESCALATE_DETECTIONS = gql`
  mutation escalateDetections(
    $escalate_detections_info: [EscalateDetectionInput!]!
  ) {
    escalateDetections(escalate_detections_info: $escalate_detections_info) {
      detection_id
      escalated
      escalation_configuration_id
      message
    }
  }
`;

interface IEscalateDetections {
  escalateDetections: EscalatedDetection[];
}

export const useEscalateDetections = (
  options: MutationHookOptions<IEscalateDetections, OperationVariables>,
) => {
  return useMutation(ESCALATE_DETECTIONS, options);
};
