import { Drawer, DrawerBody, DrawerContent, Flex, useDisclosure } from '@chakra-ui/react';
import { useBreakpointValue, useControlledProp } from '@gamma/hooks';
import { ReactNode, useEffect } from 'react';

import { SplitLayoutControls } from './Components';
import { SplitLayoutContext, SplitLayoutProvider } from './SplitLayoutContext';

export interface SplitLayoutProps extends SplitLayoutContext {
  left: ReactNode;
  right: ReactNode;
  storageKey?: string;
  splitWidth?: string | number;
  actions?: ReactNode;
  onSplit?: () => void;
  isRenderSplitDrawer?: boolean;
  'data-testid'?: string;
}

export const SplitLayout = ({
  left,
  right,
  splitWidth = '33%',
  storageKey,
  actions,
  onSplit,
  isRenderSplitDrawer,
  'data-testid': dataTestId = 'gamma-split-layout',
}: SplitLayoutProps) => {
  const isDrawer =
    useBreakpointValue(
      { base: true, xl: false },
      //@ts-ignore
      { ssr: false },
    ) && isRenderSplitDrawer;
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: true,
    onOpen: () => {
      onSplit?.();
    },
  });

  useEffect(() => {
    if (!right) {
      onClose();
    } else {
      // force open if there is content, otherwise may not trigger
      onOpen();
    }
  }, [right, onClose, onOpen]);

  useControlledProp(onOpen, onClose);

  return (
    <SplitLayoutProvider storageKey={storageKey} isDrawer={isDrawer}>
      <Flex flexDir={'row'} w="full" h="full" data-testid={dataTestId}>
        <Flex
          flex="1"
          p={4}
          data-testid={`${dataTestId}-left`}
          overflowY="auto"
        >
          {left}
        </Flex>
        {isDrawer ? (
          <Drawer
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
            variant="interactive"
            useInert={false}
          >
            <DrawerContent
              // w="auto"
              pt={0}
              position="relative"
              bg="layer.1"
              w={splitWidth}
              h="auto"
            >
              <SplitLayoutControls
                actions={actions}
                data-testid={`${dataTestId}-controls`}
              />
              <DrawerBody py={2} px={4} data-testid={`${dataTestId}-overlay`}>
                {right}
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        ) : (
          isOpen && (
            <Flex
              bg="layer.1"
              flexDir="column"
              w={splitWidth}
              h="auto"
              overflowY="auto"
              data-testid={`${dataTestId}-inline`}
              position="relative"
              borderLeft="solid 1px"
              borderColor="border.layer.1"
            >
              {actions && <SplitLayoutControls actions={actions} />}
              <Flex py={2} px={4}>
                {right}
              </Flex>
            </Flex>
          )
        )}
      </Flex>
    </SplitLayoutProvider>
  );
};
