import { Button, ButtonProps, StyleProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface CTAButtonProps extends ButtonProps, StyleProps {
  children: ReactNode;
  ['data-testid']?: string;
}

export const CTAButton = ({
  children,
  'data-testid': dataTestId,
  ...rest
}: CTAButtonProps) => {
  return (
    <Button
      data-testid={dataTestId}
      variant="solid"
      size="md"
      w="full"
      {...rest}
    >
      {children}
    </Button>
  );
};
