import { Badge, Box, Center, Flex, HStack, Text } from '@chakra-ui/react';
import { AuthContext } from '@gamma/investigator/context';
import { EntityDetectionTimelineItem } from '@gamma/investigator/queries';
import moment from 'moment';
import { useContext, useEffect, useRef } from 'react';

import { SeverityScore } from '../SeverityScore';

interface DetectionDetailCardProps {
  index?: number;
  selected?: boolean;
  condensed?: boolean;
  alertRollup: EntityDetectionTimelineItem;
  setSelectedIndex?: (index: number) => void;
  onClick?: (alertRollup: EntityDetectionTimelineItem) => void;
}

export const DetectionDetailCard = ({
  index,
  selected,
  condensed,
  alertRollup,
  setSelectedIndex,
  onClick,
}: DetectionDetailCardProps) => {
  const { isOrgTenant } = useContext(AuthContext);

  const handleClick = (alertRollup: EntityDetectionTimelineItem) => {
    onClick?.(alertRollup);
  };

  const activeCardRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (index && setSelectedIndex && activeCardRef.current) {
      setSelectedIndex(index);
    }
  }, [index, setSelectedIndex]);

  const isCustomSeverityScore = alertRollup?.is_custom_severity;

  return (
    <Flex
      py={2}
      borderRadius="8px"
      layerStyle="second"
      alignItems="center"
      ref={selected ? activeCardRef : null}
      bg={selected ? 'layer.3' : undefined}
      _hover={{
        cursor: !condensed ? 'pointer' : 'default',
        background: !condensed ? 'layer.3' : '',
      }}
      onClick={() => handleClick(alertRollup)}
    >
      <Center
        px={2}
        flex="1"
        flexDirection="row"
        justifyContent="space-between"
        borderTopRightRadius="sm"
        borderBottomRightRadius="sm"
      >
        <Flex alignItems="center">
          <Box mr={2}>
            <SeverityScore
              isCustom={isCustomSeverityScore}
              score={alertRollup?.severity}
            />
          </Box>
          <Flex flexDirection="column" py={1}>
            <Text
              data-testid="alert-detail-card-alert-rollup-name"
              noOfLines={2}
              wordBreak="break-word"
              textStyle="body-md"
              lineHeight={1.1}
            >
              {alertRollup?.alert_name}
            </Text>
            {!condensed && (
              <Text
                data-testid="alert-detail-card-not-condensed-alert-rollup-seen"
                textStyle="body-md"
              >
                {alertRollup?.first_seen &&
                  alertRollup?.last_seen &&
                  `${moment
                    .unix(alertRollup?.first_seen)
                    .format('D MMM HH:mm')} - ${moment
                    .unix(alertRollup?.last_seen)
                    .format('D MMM HH:mm')}`}
              </Text>
            )}
          </Flex>
        </Flex>
        {condensed && (
          <Flex>
            <HStack>
              {isOrgTenant && (
                <Badge variant="outline">
                  {alertRollup?.tenant_info?.tenant_display_name}
                </Badge>
              )}
              <Text
                minW="55px"
                data-testid="alert-detail-card-alert-rollup-last-seen"
                textStyle="body-sm"
                textAlign="right"
              >
                {alertRollup?.last_seen &&
                  moment.unix(alertRollup?.last_seen).fromNow()}
              </Text>
            </HStack>
          </Flex>
        )}
        {!condensed && (
          <Flex flexDirection="row">
            <Text
              data-testid="alert-detail-card-not-condensed-x"
              textStyle="body-md"
              mx={1}
            >
              x
            </Text>
            <Text
              data-testid="alert-detail-card-not-condensed-alert-rollup-total-alerts"
              textStyle="body-md"
            >
              {alertRollup?.total_detections}
            </Text>
          </Flex>
        )}
      </Center>
    </Flex>
  );
};
