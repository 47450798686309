import { Flex, HStack, Link, Text } from '@chakra-ui/react';
import { Switch } from '@gamma/form-fields';
import { URLS } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import { Board, Column, Panel, PanelHeader, PanelHeading } from '@gamma/layout';
import {
  BaseSyntheticEvent,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';

const { cookiePolicy } = i18n;

const { CORELIGHT_PRIVACY_POLICY } = URLS;

export interface CookiePolicyBodyProps {
  stepNumber: number;
  isCookieAccepted?: boolean;
  isCookiePolicyExpired: boolean;
  setIsCookieStillAccepted: Dispatch<SetStateAction<boolean>>;
}

export const CookiePolicyBody = ({
  stepNumber,
  isCookieAccepted,
  isCookiePolicyExpired,
  setIsCookieStillAccepted,
}: CookiePolicyBodyProps) => {
  const switchRef: MutableRefObject<any> = useRef();

  const updateCookieStatus = (e: BaseSyntheticEvent) => {
    setIsCookieStillAccepted(e.target.checked);
  };

  useEffect(() => {
    setIsCookieStillAccepted(Boolean(switchRef?.current?.checked));
  }, [stepNumber]);

  return (
    <>
      {stepNumber === 1 && (
        <Text data-testid="cookie-policy-description" pb={2}>
          {cookiePolicy.stepOne.description}
        </Text>
      )}
      {stepNumber === 2 && (
        <Board>
          <Column>
            <Panel>
              <PanelHeader divider>
                <PanelHeading data-testid="cookie-policy-privacy-panel-header">
                  {cookiePolicy.stepTwo.privacyPanel.header}
                </PanelHeading>
              </PanelHeader>
              <HStack>
                <Text
                  data-testid="cookie-policy-privacy-panel-description"
                  textStyle="body-md"
                >
                  {cookiePolicy.stepTwo.privacyPanel.description}
                  {
                    <Link
                      data-testid="cookie-policy-privacy-panel-description-link"
                      href={CORELIGHT_PRIVACY_POLICY}
                      isExternal
                      color="text.link"
                    >
                      {cookiePolicy.stepTwo.privacyPanel.link}
                    </Link>
                  }
                </Text>
              </HStack>
            </Panel>
          </Column>
          <Column>
            <Panel>
              <PanelHeader divider>
                <Flex
                  w="100%"
                  justifyContent="space-between"
                  alignItems="baseline"
                >
                  <PanelHeading data-testid="cookie-policy-necessary-cookie-header">
                    {cookiePolicy.stepTwo.necessaryCookiesPanel.header}
                  </PanelHeading>
                  <HStack>
                    <Text
                      data-testid="cookie-policy-necessary-cookie-switch-description"
                      textStyle="body-md"
                    >
                      {
                        cookiePolicy.stepTwo.necessaryCookiesPanel.switch
                          .alwaysActive
                      }
                    </Text>
                    <Switch
                      data-testid="cookie-policy-necessary-cookie-switch"
                      name={
                        cookiePolicy.stepTwo.necessaryCookiesPanel.switch.name
                      }
                      label={
                        cookiePolicy.stepTwo.necessaryCookiesPanel.switch.label
                      }
                      isLabelHidden
                      isChecked
                      isDisabled
                      isReadOnly
                    />
                  </HStack>
                </Flex>
              </PanelHeader>
              <Text
                data-testid="cookie-policy-necessary-cookie-description"
                textStyle="body-md"
              >
                {cookiePolicy.stepTwo.necessaryCookiesPanel.description}
              </Text>
            </Panel>
          </Column>
          <Column>
            <Panel>
              <PanelHeader divider>
                <Flex
                  justifyContent="space-between"
                  alignItems="baseline"
                  w="100%"
                >
                  <PanelHeading data-testid="cookie-policy-analytics-cookie-header">
                    {cookiePolicy.stepTwo.analyticalCookiesPanel.header}
                  </PanelHeading>
                  <Switch
                    data-testid="cookie-policy-analytics-cookie-switch"
                    ref={switchRef}
                    name={
                      cookiePolicy.stepTwo.analyticalCookiesPanel.switch.name
                    }
                    label={
                      cookiePolicy.stepTwo.analyticalCookiesPanel.switch.label
                    }
                    isLabelHidden
                    defaultChecked={isCookieAccepted || isCookiePolicyExpired}
                    onChange={updateCookieStatus}
                  />
                </Flex>
              </PanelHeader>
              <Text
                data-testid="cookie-policy-analytics-cookie-description"
                textStyle="body-md"
              >
                {cookiePolicy.stepTwo.analyticalCookiesPanel.description}
              </Text>
            </Panel>
          </Column>
        </Board>
      )}
    </>
  );
};
