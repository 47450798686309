import { StyleProps, Text, TextProps, useStyles } from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface CardPageSubtitleProps extends TextProps, StyleProps {
  children: ReactNode;
  'data-testid'?: string;
}

export const CardPageSubtitle = ({
  children,
  'data-testid': dataTestId = 'card-page-subtitle',
  ...rest
}: CardPageSubtitleProps) => {
  const styles = useStyles();
  return (
    <Text
      textStyle="body-md"
      textAlign="center"
      mb={5}
      data-testid={dataTestId}
      {...rest}
    >
      {children}
    </Text>
  );
};
