import * as d3 from 'd3';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export class GraphHelper extends React.Component {
  constructor(props) {
    super(props);

    this.svgDimensions = {
      width: 143,
      height: 150,
      radius: 0,
    }
  
    this.containerRef = null;
    this.tooltip = null;
    this.svg = null;

    this.setContainerRefHandler = this.setContainerRefHandler.bind(this);
    this.buildGraph = this.buildGraph.bind(this);
    this.buildTooltip = this.buildTooltip.bind(this);
    this.updateSvgDimensions = this.updateSvgDimensions.bind(this);
    this.updateGraphData = this.updateGraphData.bind(this);
    this.updateTooltip = this.updateTooltip.bind(this);
    this.resetGraphData = this.resetGraphData.bind(this);
    this.resetTooltip = this.resetTooltip.bind(this);
  }

  componentDidMount() {
    this.buildGraph();
    this.updateSvgDimensions();
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.data, nextProps.data);
  }

  componentDidUpdate() {
    this.resetGraphData();
    this.updateGraphData();
  }

  componentWillUnmount() {
    this.resetTooltip();
  }

  setContainerRefHandler(ref) {
    this.containerRef = ref;
  }

  buildGraph() {
    if (!this.svg) {
      this.svg = d3.select(this.containerRef).append('svg');
    }

    this.buildTooltip();
  }

  buildTooltip() {
    this.tooltip = d3
      .select('body')
      .append('div')
      .attr('id', `${this.props.id}-tooltip`)
      .attr('data-testid', `${this.props.id}-tooltip`)
      .attr(
        'class',
        `d3-tooltip d3-tooltip--${this.props.tooltipPosition} ${this.props.className}__tooltip`,
      )
      .attr('hidden', true);

    this.tooltip.append('div').attr('class', 'd3-tooltip__body');
  }

  updateSvgDimensions(extraHeight = 0) {
    if (!this.containerRef) {
      return;
    }
    this.resetGraphData();

    const containerRect = this.containerRef.getBoundingClientRect();
    this.svgDimensions.width = containerRect.width;
    this.svgDimensions.height = containerRect.height + extraHeight;
    this.svg
      .attr('width', containerRect.width)
      .attr('height', containerRect.height + extraHeight);
  }

  updateGraphData() {
    this.svg.attr('class', 'loaded');
  }

  updateTooltip(element, tooltip) {
    const position = element.getBoundingClientRect();

    let tooltipPosition = `width: ${position.width}px; left: ${
      position.left - position.width
    }px;`;

    if (this.props.tooltipPosition === 'top') {
      tooltipPosition += `top: ${position.top - 10}px`;
    } else {
      tooltipPosition += `top: ${position.top + position.height}px`;
    }

    let tooltipHTML = '';

    if (tooltip.header) {
      tooltipHTML = `<div class="d3-tooltip__header">${tooltip.header}</div>`;
    }

    tooltipHTML += `<div class="d3-tooltip__content">${tooltip.content}</div>`;

    this.tooltip
      .attr('style', tooltipPosition)
      .attr('hidden', null)
      .select('.d3-tooltip__body')
      .html(tooltipHTML);
  }

  resetGraphData() {
    if (this.tooltip) {
      this.tooltip.attr('hidden', true);
    }
  }

  resetTooltip() {
    if (this.tooltip) {
      this.tooltip.remove();
    }
  }
}

GraphHelper.propTypes = {
  data: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  tooltipPosition: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  className: PropTypes.string,
};

GraphHelper.defaultProps = {
  tooltipPosition: 'top',
  className: '',
};
