import { createIcon } from '@chakra-ui/react';

export const BoxUploadIcon = createIcon({
  displayName: 'BoxUploadIcon',
  viewBox: '0 0 24 24',
  d: 'M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2ZM12 7l-5 5h3v4h4v-4h3l-5-5Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
