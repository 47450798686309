import { pick } from 'lodash';

import { LabelProps } from '../../Components';

export function getLabelProps<T extends Record<string, unknown>>(props: T) {
  const labelProps = pick(props, [
    'name',
    'children',
    'label',
    'hint',
    'error',
    'warning',
    'inline',
    'isRequired',
    'isDisabled',
    'isReadOnly',
    'isLabelHidden',
    'width',
    'afterLabel',
    'htmlFor',
    'id',
    'tooltip'
  ]);
  return labelProps as LabelProps;
}
