import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useLazyQuery,
} from '@apollo/client';
import { IGetAlertMetadata } from './types';

export const GET_ALERT_METADATA_SURICATA_ALERT_EXPLAIN = gql`
  query getAlertMetadata($items: [GetAlertMetadataInput]!) {
    getAlertMetadata(items: $items) {
      metadata {
        chatbot_chats {
          suri_alert_explain
        }
      }
    }
  }
`;

export const useGetAlertMetadataSuricataAlertExplain = (
  options: QueryHookOptions<IGetAlertMetadata, OperationVariables>,
) =>
  useLazyQuery<IGetAlertMetadata>(
    GET_ALERT_METADATA_SURICATA_ALERT_EXPLAIN,
    options,
  );
