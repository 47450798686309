import { createIcon } from '@chakra-ui/react';

export const AlertCategoryIcon = createIcon({
  displayName: 'AlertCategoryIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M7.5 16.775c.2 0 .375-.07.525-.212a.708.708 0 0 0 .225-.538.72.72 0 0 0-.225-.525.72.72 0 0 0-.525-.225.72.72 0 0 0-.525.225.72.72 0 0 0-.225.525c0 .217.075.396.225.538a.74.74 0 0 0 .525.212Zm0-4.025a.72.72 0 0 0 .525-.225A.72.72 0 0 0 8.25 12a.72.72 0 0 0-.225-.525.72.72 0 0 0-.525-.225.72.72 0 0 0-.525.225.72.72 0 0 0-.225.525c0 .2.075.375.225.525a.72.72 0 0 0 .525.225Zm0-4.05c.2 0 .375-.07.525-.212a.708.708 0 0 0 .225-.538.72.72 0 0 0-.225-.525A.72.72 0 0 0 7.5 7.2a.72.72 0 0 0-.525.225.72.72 0 0 0-.225.525c0 .217.075.396.225.538A.74.74 0 0 0 7.5 8.7Zm3.3 8.075h6.1v-1.5h-6.1v1.5Zm0-4.025h6.1v-1.5h-6.1v1.5Zm0-4.05h6.1V7.2h-6.1v1.5ZM4.625 21.35c-.533 0-.996-.196-1.387-.588a1.897 1.897 0 0 1-.588-1.387V4.625c0-.55.196-1.02.588-1.413a1.897 1.897 0 0 1 1.387-.587h14.75c.55 0 1.02.196 1.413.587.391.392.587.863.587 1.413v14.75c0 .533-.196.996-.587 1.387a1.926 1.926 0 0 1-1.413.588H4.625Zm0-1.975h14.75V4.625H4.625v14.75Zm0-14.75v14.75-14.75Z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
