import { createIcon } from '@chakra-ui/react';

export const StarShieldIcon = createIcon({
  displayName: 'StarShieldIcon',
  viewBox: '0 0 24 24',
  d: 'M12 1 3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4Zm3.08 15L12 14.15 8.93 16l.81-3.5-2.71-2.34 3.58-.31L12 6.55l1.39 3.29 3.58.31-2.71 2.35.82 3.5Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
