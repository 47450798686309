import { createIcon } from '@chakra-ui/react';

export const ResetPassword = createIcon({
  displayName: 'ResetPassword',
  viewBox: '0 0 160 128',
  path: (
    <>
      <path
        d="M75.754 29.539a.838.838 0 0 1-.46-1.005.409.409 0 0 0-.25-.508.41.41 0 0 0-.519.222.827.827 0 0 1-.41.423.817.817 0 0 1-.585.04.39.39 0 0 0-.343.049.416.416 0 0 0-.17.466.4.4 0 0 0 .229.261.838.838 0 0 1 .46 1.005.409.409 0 0 0 .25.51.41.41 0 0 0 .519-.223.817.817 0 0 1 .996-.463.39.39 0 0 0 .342-.049.416.416 0 0 0 .17-.466.4.4 0 0 0-.229-.261Z"
        fill="#128EE3"
      />
      <path
        d="M127.5 41.027c.828 0 1.5-.678 1.5-1.514 0-.835-.672-1.513-1.5-1.513s-1.5.678-1.5 1.514c0 .835.672 1.513 1.5 1.513ZM81.5 93.027c.828 0 1.5-.678 1.5-1.514 0-.835-.672-1.513-1.5-1.513s-1.5.678-1.5 1.513c0 .836.672 1.514 1.5 1.514ZM129.5 87.027c.828 0 1.5-.678 1.5-1.514 0-.835-.672-1.513-1.5-1.513s-1.5.678-1.5 1.513c0 .836.672 1.514 1.5 1.514Z"
        fill="#F56565"
      />
      <path
        d="M130.754 106.539a.829.829 0 0 1-.419-.414.843.843 0 0 1-.04-.591.419.419 0 0 0-.251-.508.41.41 0 0 0-.519.222.825.825 0 0 1-.995.463.409.409 0 0 0-.504.254.416.416 0 0 0 .22.523.829.829 0 0 1 .419.414.842.842 0 0 1 .04.591.419.419 0 0 0 .251.508.41.41 0 0 0 .519-.222.825.825 0 0 1 .995-.463c.03.01.061.016.092.018a.416.416 0 0 0 .192-.795ZM55.755 93.539a.838.838 0 0 1-.46-1.005.409.409 0 0 0-.251-.508.41.41 0 0 0-.519.222.827.827 0 0 1-.41.423.817.817 0 0 1-.585.04.39.39 0 0 0-.343.049.416.416 0 0 0-.17.466.4.4 0 0 0 .229.261.838.838 0 0 1 .46 1.005.409.409 0 0 0 .25.51.41.41 0 0 0 .519-.223.817.817 0 0 1 .996-.463.39.39 0 0 0 .342-.049.416.416 0 0 0 .17-.466.4.4 0 0 0-.229-.261Z"
        fill="#128EE3"
      />
      <path
        d="M27.5 32.027c.828 0 1.5-.678 1.5-1.514 0-.835-.672-1.513-1.5-1.513s-1.5.678-1.5 1.514c0 .835.672 1.513 1.5 1.513ZM30.5 108.027c.828 0 1.5-.678 1.5-1.514 0-.835-.672-1.513-1.5-1.513s-1.5.678-1.5 1.513c0 .836.672 1.514 1.5 1.514ZM105.765 30h-.53v3.027h.53V30Z"
        fill="#35FE94"
      />
      <path
        d="M107 31.78v-.534h-3v.535h3ZM103.765 102h-.53v3.027h.53V102Z"
        fill="#35FE94"
      />
      <path
        d="M105 103.781v-.535h-3v.535h3ZM126.823 66.019h-.529v3.026h.529V66.02Z"
        fill="#35FE94"
      />
      <path
        d="M128.059 67.8v-.535h-3v.534h3ZM23.375 52.06l.9.832L25.55 51l.45.378-1.725 2.27L23 52.212l.375-.151ZM34.375 90.06l.9.832L36.55 89l.45.378-1.725 2.27L34 90.212l.375-.151Z"
        fill="#35FE94"
      />
      <path
        d="M54.5 40.027c.828 0 1.5-.678 1.5-1.514 0-.835-.672-1.513-1.5-1.513s-1.5.678-1.5 1.514c0 .835.672 1.513 1.5 1.513Z"
        fill="#F56565"
      />
      <path
        d="M19.755 74.539a.838.838 0 0 1-.46-1.005.409.409 0 0 0-.251-.508.41.41 0 0 0-.519.222.827.827 0 0 1-.41.423.816.816 0 0 1-.585.04.39.39 0 0 0-.343.049.416.416 0 0 0-.17.466.4.4 0 0 0 .229.261.838.838 0 0 1 .46 1.005.407.407 0 0 0 .047.346.414.414 0 0 0 .462.172.413.413 0 0 0 .26-.23.817.817 0 0 1 .996-.463.395.395 0 0 0 .342-.05.417.417 0 0 0-.058-.728Z"
        fill="#128EE3"
      />
      <rect
        x="43.947"
        y="49.649"
        width="72.134"
        height="28.851"
        rx="3.5"
        fill="#273038"
        stroke="#646A70"
      />
      <path d="M58 70a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z" fill="#35FE94" />
      <rect x="69" y="58" width="38" height="4" rx="2" fill="#35FE94" />
      <rect x="69" y="66" width="38" height="4" rx="2" fill="#35FE94" />
      <circle cx="58" cy="63" r="2" fill="#222930" />
      <rect x="57" y="63" width="2" height="4" rx="1" fill="#222930" />
    </>
  ),
  defaultProps: {
    height: '128px',
    width: '160px',
  },
});
