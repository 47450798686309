import { AuthContext } from '@gamma/investigator/context';
import { ReactElement, useContext } from 'react';

interface PrivilegedAccessWrapperProps {
  feature: string;
  component: ReactElement;
  lockedComponent: ReactElement;
}

export const PrivilegedAccessWrapper = ({
  feature,
  component,
  lockedComponent,
}: PrivilegedAccessWrapperProps) => {
  const { userPrivileges, userPrivilegesLoading } = useContext(AuthContext);

  if (!userPrivilegesLoading && !userPrivileges?.[feature]) {
    return lockedComponent;
  }
  return component;
};
