import {
  Button,
  Center,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Illustrations } from '@gamma/icons';
import { ErrorBoundary } from '@gamma/overlay';
import { gammaContext } from '@gamma/theme';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export type errorCode = 404 | 500;
export interface ErrorPageProps {
  code: errorCode;
}

export const ErrorCodeIllustrationMap = new Map<errorCode, any>([
  [404, [Illustrations.NotFoundLaptopLight, Illustrations.NotFoundLaptop]],
  [500, [Illustrations.InternalErrorLight, Illustrations.InternalError]],
]);

export const ErrorPage = ({ code }: ErrorPageProps) => {
  const navigate = useNavigate();
  const { i18n } = useContext(gammaContext);
  const { e404, e500 } = i18n.errorPage;
  const { title, body, cta } = code === 404 ? e404 : e500;
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const [light, dark] = ErrorCodeIllustrationMap.get(code);
  const IllustrationName = useColorModeValue(light, dark);

  return (
    <ErrorBoundary page={String(code)}>
      <Center data-testid="error-page" flexDirection="column" height="full">
        <Center mb="64px">
          <IllustrationName />
        </Center>
        <Center mb={2}>
          <Heading
            textStyle="h3"
            fontWeight="normal"
            data-testid="error-page-title"
          >
            {title}
          </Heading>
        </Center>
        <Center mb="40px">
          <Text color={textColor} data-testid="error-page-body">
            {body}
          </Text>
        </Center>
        <Center>
          <Button
            variant="outline"
            onClick={() => navigate('/')}
            data-testid="error-page-cta"
          >
            {cta}
          </Button>
        </Center>
      </Center>
    </ErrorBoundary>
  );
};
