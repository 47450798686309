import { createIcon } from '@chakra-ui/react';

export const CheckOnlyComplete = createIcon({
  displayName: 'CheckOnlyComplete',
  viewBox: '0 0 262 209',
  path: (
    <>
      {' '}
      <path
        d="M212.51 38.49a1.35 1.35 0 0 1-.686-.67c-.139-.3-.162-.64-.065-.955a.669.669 0 0 0-.08-.559.675.675 0 0 0-1.181.095 1.349 1.349 0 0 1-1.631.75.645.645 0 0 0-.56.079.672.672 0 0 0 .095 1.178 1.348 1.348 0 0 1 .752 1.626.634.634 0 0 0 .079.559.677.677 0 0 0 1.182-.096 1.352 1.352 0 0 1 1.63-.75.658.658 0 0 0 .15.03.679.679 0 0 0 .676-.44.674.674 0 0 0-.228-.772.648.648 0 0 0-.133-.074ZM73.51 153.513a1.355 1.355 0 0 1-.751-1.64.661.661 0 0 0-.08-.564.678.678 0 0 0-.757-.281.674.674 0 0 0-.35.243.631.631 0 0 0-.074.134 1.352 1.352 0 0 1-1.63.756.636.636 0 0 0-.561.08.68.68 0 0 0 .095 1.188 1.364 1.364 0 0 1 .752 1.641.653.653 0 0 0 .08.564.676.676 0 0 0 1.106.037.65.65 0 0 0 .075-.133c.132-.306.371-.551.672-.691.3-.139.642-.163.958-.065a.672.672 0 0 0 .825-.415.684.684 0 0 0-.227-.779.67.67 0 0 0-.133-.075Z"
        fill="#4299E1"
      />
      <path
        d="M180.944 65.356a2.464 2.464 0 0 0 2.456-2.47c0-1.366-1.1-2.472-2.456-2.472a2.464 2.464 0 0 0-2.457 2.471c0 1.365 1.1 2.471 2.457 2.471ZM105.618 117.606a2.464 2.464 0 0 0 2.457-2.471c0-1.365-1.1-2.471-2.457-2.471a2.464 2.464 0 0 0-2.456 2.471c0 1.365 1.1 2.471 2.456 2.471ZM221.881 145.364a2.463 2.463 0 0 0 2.456-2.471 2.463 2.463 0 0 0-2.456-2.471 2.464 2.464 0 0 0-2.456 2.471c0 1.365 1.1 2.471 2.456 2.471ZM40.456 67.942a2.464 2.464 0 0 0 2.456-2.47c0-1.366-1.1-2.472-2.456-2.472A2.464 2.464 0 0 0 38 65.471c0 1.365 1.1 2.471 2.456 2.471Z"
        fill="#F56565"
      />
      <path
        d="M156.51 158.513a1.357 1.357 0 0 1-.751-1.641.677.677 0 0 0-.412-.83.672.672 0 0 0-.849.363 1.354 1.354 0 0 1-.672.69c-.301.14-.642.163-.959.066a.636.636 0 0 0-.56.08.677.677 0 0 0-.28.761.68.68 0 0 0 .242.352c.041.03.086.056.133.075.304.133.548.374.687.677.138.302.161.645.065.964a.644.644 0 0 0 .079.564.677.677 0 0 0 1.107.037.669.669 0 0 0 .075-.133 1.344 1.344 0 0 1 1.63-.757.644.644 0 0 0 .561-.079.685.685 0 0 0 .037-1.114.669.669 0 0 0-.133-.075Z"
        fill="#4299E1"
      />
      <path
        d="M59.769 96.38a2.464 2.464 0 0 0 2.456-2.471c0-1.365-1.1-2.472-2.456-2.472a2.464 2.464 0 0 0-2.456 2.472c0 1.364 1.1 2.47 2.456 2.47ZM231.706 70.255a2.464 2.464 0 0 0 2.457-2.471c0-1.365-1.1-2.472-2.457-2.472a2.464 2.464 0 0 0-2.456 2.472c0 1.364 1.1 2.47 2.456 2.47ZM160.09 31.023h-.867v4.943h.867v-4.943Z"
        fill="#35FE94"
      />
      <path
        d="M162.112 33.93v-.871H157.2v.872h4.912ZM114.889 169h-.867v4.942h.867V169Z"
        fill="#35FE94"
      />
      <path
        d="M116.912 171.907v-.872H112v.872h4.912ZM207.673 107.796h-.867v4.942h.867v-4.942Z"
        fill="#35FE94"
      />
      <path
        d="M209.695 110.703v-.872h-4.912v.872h4.912ZM185.614 160.73l1.474 1.359 2.088-3.089.737.618-2.825 3.707-2.088-2.348.614-.247ZM79.614 44.73l1.474 1.359L83.176 43l.736.618-2.824 3.707L79 44.977l.614-.247Z"
        fill="#35FE94"
      />
      <path
        d="M110.456 19.942a2.463 2.463 0 0 0 2.456-2.47A2.463 2.463 0 0 0 110.456 15 2.464 2.464 0 0 0 108 17.471c0 1.365 1.1 2.471 2.456 2.471Z"
        fill="#F56565"
      />
      <path
        d="M30.51 127.513a1.355 1.355 0 0 1-.751-1.64.661.661 0 0 0-.08-.564.678.678 0 0 0-.757-.281.674.674 0 0 0-.35.243.631.631 0 0 0-.074.134 1.352 1.352 0 0 1-1.63.756.636.636 0 0 0-.561.08.68.68 0 0 0 .095 1.188 1.364 1.364 0 0 1 .752 1.641.653.653 0 0 0 .08.564.676.676 0 0 0 1.106.038.684.684 0 0 0 .075-.134c.132-.305.371-.551.672-.691.3-.139.642-.163.958-.065a.672.672 0 0 0 .825-.415.684.684 0 0 0-.227-.779.67.67 0 0 0-.133-.075Z"
        fill="#4299E1"
      />
      <path
        d="M130.814 151.594a47.364 47.364 0 0 1-26.215-7.909 46.984 46.984 0 0 1-17.38-21.062 46.693 46.693 0 0 1-2.684-27.115 46.853 46.853 0 0 1 12.913-24.03 47.265 47.265 0 0 1 24.16-12.843 47.424 47.424 0 0 1 27.263 2.67 47.126 47.126 0 0 1 21.176 17.285A46.75 46.75 0 0 1 178 104.664a46.854 46.854 0 0 1-13.836 33.169 47.37 47.37 0 0 1-33.35 13.761Z"
        fill="#273038"
      />
      <path
        d="M130.814 151.594a47.364 47.364 0 0 1-26.215-7.909 46.984 46.984 0 0 1-17.38-21.062 46.693 46.693 0 0 1-2.684-27.115 46.853 46.853 0 0 1 12.913-24.03 47.265 47.265 0 0 1 24.16-12.843 47.424 47.424 0 0 1 27.263 2.67 47.126 47.126 0 0 1 21.176 17.285A46.75 46.75 0 0 1 178 104.664a46.854 46.854 0 0 1-13.836 33.169 47.37 47.37 0 0 1-33.35 13.761Zm0-92.243a45.734 45.734 0 0 0-25.311 7.637 45.365 45.365 0 0 0-16.78 20.335 45.086 45.086 0 0 0-2.593 26.181 45.238 45.238 0 0 0 12.469 23.2 45.63 45.63 0 0 0 23.327 12.401 45.786 45.786 0 0 0 26.322-2.578 45.514 45.514 0 0 0 20.447-16.689 45.139 45.139 0 0 0 7.678-25.174 45.241 45.241 0 0 0-13.359-32.026 45.736 45.736 0 0 0-32.2-13.287Z"
        fill="#646A70"
      />
      <path
        d="M125.551 125.169a6.089 6.089 0 0 1-3.652-1.206l-.065-.049-13.753-10.472a6.078 6.078 0 0 1-2.341-4.026 6.067 6.067 0 0 1 2.999-6.058 6.132 6.132 0 0 1 6.774.437l8.908 6.793 21.05-27.304a6.095 6.095 0 0 1 1.792-1.564 6.136 6.136 0 0 1 6.774.44l-.13.176.134-.174a6.06 6.06 0 0 1 1.131 8.52l-24.761 32.116a6.134 6.134 0 0 1-4.86 2.371Z"
        fill="#35FE94"
      />
    </>
  ),
  defaultProps: {
    height: '209px',
    width: '262px',
  },
});
