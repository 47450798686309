import { Flex, HStack, Tag, Text, Tooltip, VStack } from '@chakra-ui/react';
import { SeverityScore } from '@gamma/investigator/components';
import { i18n } from '@gamma/investigator/localization';
import { Detection } from '@gamma/investigator/queries';
import _ from 'lodash';
import moment from 'moment';

import { DetectionStatusPill } from '../DetectionStatusPill';

interface DetectionRowProps {
  item: Detection;
}

const { detection, alerts } = i18n.pages.detections;
const { unassigned, externalTenant } = detection;

export const DetectionRowRelatedEntity = ({ item }: DetectionRowProps) => {
  return (
    <Flex flexGrow={1} cursor="pointer" data-testid="detection-row">
      {item?.rank?.severity !== null && item?.rank?.severity !== undefined && (
        <SeverityScore
          isCustom={item.rank.is_custom_severity}
          score={item.rank.severity}
        />
      )}
      <Flex
        flexGrow={1}
        direction="column"
        ml={
          item?.rank?.severity !== null && item?.rank?.severity !== undefined
            ? 2
            : 0
        }
      >
        <VStack alignItems="start">
          <HStack spacing={1}>
            <Text
              textStyle="body-lg"
              fontWeight="600"
              data-testid="detection-alert-name"
            >
              {item?.alert_entity?.entity_name}
            </Text>
          </HStack>
          <Text pb={1} textStyle="body-md" color="text.200">
            {i18n.formatString(
              detection.detectionDetails,
              String(item?.total_alert_count),
              _.lowerCase(
                item?.total_alert_count === 1 ? alerts.alert : alerts.alerts,
              ),
              String(
                moment
                  .unix(item?.earliest_start_timestamp)
                  .format('MMMM Do, h:mma'),
              ),
              String(
                moment
                  .unix(item?.latest_start_timestamp)
                  .format('MMMM Do, h:mma'),
              ),
            )}
          </Text>
          <HStack>
            <DetectionStatusPill
              text={item?.detection_status}
              isStatusOpen={item?.detection_status === 'open'}
            />
            <Tag
              size="md"
              variant="solid"
              data-testid="detection-alert-assignee"
              colorScheme={
                item?.assignment_info?.assigned_to_user_alias
                  ? 'purple'
                  : 'gray'
              }
            >
              <Tooltip
                label={
                  item?.assignment_info?.assigned_to_user_alias ===
                  'External User'
                    ? externalTenant
                    : ''
                }
              >
                {!item?.assignment_info?.assigned_to_user_alias
                  ? unassigned
                  : item?.assignment_info?.assigned_to_user_alias}
              </Tooltip>
            </Tag>
          </HStack>
        </VStack>
      </Flex>
    </Flex>
  );
};
