import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

export const UPDATE_PROFILE = gql`
  mutation updateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      body {
        data
        message
        success
      }
      status_code
    }
  }
`;

export const useUpdateProfile = (
  options?: MutationHookOptions<any, OperationVariables>,
) => {
  return useMutation(UPDATE_PROFILE, options);
};
