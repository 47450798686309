import { Button, Flex, HStack, Link, Text, VStack } from '@chakra-ui/react';
import { IconButton } from '@gamma/buttons';
import { MuiIcon } from '@gamma/display';
import { Input, OptionType, Select } from '@gamma/form-fields';
import { usePrevious } from '@gamma/hooks';
import { REGEX, ROUTES, URLS } from '@gamma/investigator/constants';
import { OrgTenantsContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  SetAlertStatusBySearchTermFiltersInput,
  SetAlertStatusScore,
  SetAlertStatusType,
} from '@gamma/investigator/queries';
import { Column, Panel } from '@gamma/layout';
import { chakraComponents, MultiValue, OptionProps } from 'chakra-react-select';
import { isEqual } from 'lodash';
import { Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

const { alertCatalog } = ROUTES;

interface StatusOption {
  value: number;
  label: string;
}

interface ScoreOption {
  value: string;
  label: string;
  min: number;
  max: number;
  severity: ReactNode;
}

interface TypeOption {
  value: string;
  label: string;
}

interface AlertCatalogForm {
  wildcard: string;
  active: string;
  severity: string;
  category: string;
}

interface AlertCatalogSearchProps {
  loading: boolean;
  queryTerm: string | null;
  setQuery: (query: string | null) => void;
  setQueryTerm: Dispatch<SetStateAction<string | null>>;
  setBulkActive: Dispatch<SetStateAction<boolean | null>>;
  setPrevQueryTerm: Dispatch<SetStateAction<string | null>>;
  setHasSelectedAll: Dispatch<SetStateAction<boolean>>;
  setAlertStatusBySearchTermFilters: Dispatch<
    SetStateAction<SetAlertStatusBySearchTermFiltersInput>
  >;
}

const {
  isActive,
  category,
  risk,
  score,
  search: searchText,
  status,
  type,
  noValueSet,
  learnMore,
  searchHelpText,
} = i18n.pages.system.alertCatalog;

export const AlertCatalogSearch = ({
  setQuery,
  setQueryTerm,
  setPrevQueryTerm,
  queryTerm,
  setHasSelectedAll,
  setAlertStatusBySearchTermFilters,
}: AlertCatalogSearchProps) => {
  const navigate = useNavigate();

  const { orgTenantsQueryParam } = useContext(OrgTenantsContext);
  const hasOrgTenants =
    orgTenantsQueryParam && orgTenantsQueryParam?.length > 0;

  const [searchParams, setSearchParams] = useSearchParams();

  const prevQueryTerm = usePrevious(queryTerm);
  setPrevQueryTerm?.(prevQueryTerm || null);
  const { COLON, HYPHEN, DOUBLE_QUOTES, FORWARD_SLASH } = REGEX;

  const defaultValues = useMemo(() => {
    return {
      wildcard: '',
      active: '',
      severity: '',
      category: '',
    };
  }, [searchParams]);

  const { control, register, handleSubmit, setValue, watch } =
    useForm<AlertCatalogForm>({
      mode: 'onSubmit',
      defaultValues,
    });

  useEffect(() => {
    const wildcard = searchParams.get('wildcard');
    const active = searchParams.get('active');
    const severity = searchParams.get('severity');
    const category = searchParams.get('category');

    if (wildcard) {
      setValue?.('wildcard', decodeURIComponent(wildcard));
    } else {
      setValue?.('wildcard', defaultValues.wildcard);
    }
    if (active) {
      setValue?.('active', JSON.parse(active)?.join(', '));
      setAlertStatusBySearchTermFilters((prev) => ({
        ...prev,
        active: JSON.parse(active)?.map((value: number) => !!value),
      }));
    } else {
      setValue?.('active', defaultValues.active);
    }
    if (severity) {
      setValue?.('severity', JSON.parse(severity)?.join(', '));
      setAlertStatusBySearchTermFilters((prev) => ({
        ...prev,
        severity: JSON.parse(severity),
      }));
    } else {
      setValue?.('severity', defaultValues.severity);
    }
    if (category) {
      setValue?.('category', JSON.parse(category)?.join(', '));
      setAlertStatusBySearchTermFilters((prev) => ({
        ...prev,
        types: JSON.parse(category),
      }));
    } else {
      setValue?.('category', defaultValues.category);
    }
    handleSubmit(handleSearchSubmit)();
  }, [searchParams]);

  const formData = watch();

  useEffect(() => {
    if (formData?.active) {
      setSearchParams((params) => {
        params.set('active', `[${formData?.active}]`);
        return params;
      });
    }
    navigate(
      {
        pathname: alertCatalog,
        search: searchParams.toString(),
      },
      {
        replace: true,
      },
    );
  }, [formData?.active, navigate]);

  useEffect(() => {
    if (formData?.severity) {
      setSearchParams((params) => {
        params.set('severity', JSON.stringify(formData?.severity?.split(', ')));
        return params;
      });
    }
    navigate(
      {
        pathname: alertCatalog,
        search: searchParams.toString(),
      },
      {
        replace: true,
      },
    );
  }, [formData?.severity, navigate]);

  useEffect(() => {
    if (formData?.category) {
      setSearchParams((params) => {
        params.set('category', JSON.stringify(formData?.category?.split(', ')));
        return params;
      });
    }
    navigate(
      {
        pathname: alertCatalog,
        search: searchParams.toString(),
      },
      {
        replace: true,
      },
    );
  }, [formData?.category, navigate]);

  const handleSearchSubmit = (formData: AlertCatalogForm) => {
    setHasSelectedAll?.(false);

    let queries = '';

    let query = formData?.wildcard;

    const firstChar = query.substring(0, 1);
    const lastChar = query.substring(query.length - 1);

    const isRegexSearch = firstChar === '/' && lastChar === '/';

    if (!isRegexSearch) {
      if (query.includes(':')) {
        query = query?.replace(COLON, '\\:');
      }
      if (query.includes('-')) {
        query = query?.replace(HYPHEN, '\\-');
      }
      if (query.includes('/')) {
        query = query?.replace(FORWARD_SLASH, '\\\\/');
      }
      if (query.includes('"')) {
        query = query?.replace(DOUBLE_QUOTES, '\\"');
      }
    }

    if (formData?.wildcard) {
      queries += `{"query_string": {"query": "${query}", "fields": ["title"], "default_operator": "AND"}}`;
      setQueryTerm?.(formData?.wildcard);
      setSearchParams((params) => {
        params.set('wildcard', encodeURIComponent(formData?.wildcard));
        return params;
      });
    } else {
      setQueryTerm?.(null);
      searchParams.delete('wildcard');
    }

    if (formData?.active?.length) {
      const queryString = generateQueryString(
        formData?.active,
        statusOptions,
        (option) => `{"match": { "active": "${!!option?.value}"}}`,
      );

      const activeQuery = `${queries.length ? ',' : ''}${queryString}`;
      queries += activeQuery;
    }

    if (formData?.category?.length) {
      const queryString = generateQueryString(
        formData?.category,
        typeOptions,
        (option) => `{"term": { "logsource.category": "${option?.value}"}}`,
      );
      const activeQuery = `${queries.length ? ',' : ''}${queryString}`;
      queries += activeQuery;
    }

    if (formData?.severity?.length) {
      const queryString = generateQueryString(
        formData?.severity,
        scoreOptions,
        (option) =>
          `{"range": { "severity": { "gte": ${option?.min}, "lte": ${option?.max}}}}`,
      );
      const activeQuery = `${queries.length ? ',' : ''}${queryString}`;
      queries += activeQuery;
    }

    if (queries.length) {
      setQuery?.(
        `{"query": {"bool": {"must": [${queries}${
          hasOrgTenants && orgTenantsQueryParam?.length === 1 ? ',' : ''
        }${
          hasOrgTenants && orgTenantsQueryParam?.length === 1
            ? `{"terms":{"tenant":${JSON.stringify(orgTenantsQueryParam)}}}`
            : ''
        }]}}}`,
      );
    } else {
      setQuery?.(
        hasOrgTenants && orgTenantsQueryParam?.length === 1
          ? `{"query": {"bool": {"must": [{"terms":{"tenant":${JSON.stringify(
              orgTenantsQueryParam,
            )}}}]}}}`
          : null,
      );
    }

    navigate(
      {
        pathname: alertCatalog,
        search: searchParams.toString(),
      },
      {
        replace: true,
      },
    );
  };

  useEffect(() => {
    if (isEqual(formData, defaultValues)) {
      setQuery?.(
        hasOrgTenants && orgTenantsQueryParam?.length === 1
          ? `{"query": {"bool": {"must": [{"terms":{"tenant":${JSON.stringify(
              orgTenantsQueryParam,
            )}}}]}}}`
          : null,
      );
      setQueryTerm?.(null);
    }
  }, [
    defaultValues,
    formData,
    setQuery,
    setQueryTerm,
    hasOrgTenants,
    orgTenantsQueryParam,
  ]);

  return (
    <Column data-testid="alert-catalog-search">
      <Panel>
        <VStack spacing={4} alignItems="start">
          <HStack
            w="100%"
            as="form"
            borderRadius="base"
            onSubmit={handleSubmit(handleSearchSubmit)}
          >
            <Input
              label="Search"
              isLabelHidden
              {...register('wildcard', {
                onChange: (e) => {
                  if (e.target.value === '') {
                    searchParams.delete('wildcard');
                    navigate(
                      {
                        pathname: alertCatalog,
                        search: searchParams.toString(),
                      },
                      {
                        replace: true,
                      },
                    );
                  }
                },
              })}
              leftElement={<MuiIcon>search</MuiIcon>}
              data-testid="search-term-input"
              placeholder={i18n.pages.system.alertCatalog.alertName}
              rightElement={
                <IconButton
                  data-testid="search-term-clear"
                  aria-label="close"
                  onClick={() => {
                    setValue?.('wildcard', '');
                    handleSubmit(handleSearchSubmit)();
                  }}
                  variant="link"
                  colorScheme="gray"
                >
                  <MuiIcon>close</MuiIcon>
                </IconButton>
              }
            />
            <Button
              type="submit"
              variant="solid"
              alignSelf="flex-start"
              data-testid="search-term-submit"
            >
              {searchText}
            </Button>
          </HStack>
          <HStack>
            <Text>{searchHelpText}</Text>
            <Link color="text.link" isExternal href={URLS.ALERT_CATALOG_SEARCH}>
              {learnMore}
            </Link>
          </HStack>
          <Flex
            w="100%"
            zIndex={2}
            position="relative"
            alignItems="center"
            justifyContent="space-between"
          >
            <HStack>
              {/* @ts-ignore */}
              <Controller
                control={control}
                name={'active'}
                render={({ field: { onChange, name, value } }) => {
                  return (
                    <Select
                      isMulti
                      name={name}
                      label={name}
                      data-testid="active-select"
                      value={getSelectedValues(value, statusOptions)}
                      onChange={(values) => {
                        onChange(createOptionString(values));
                        handleSubmit(handleSearchSubmit)();
                        const active = values.map((value) => !!value.value);
                        setAlertStatusBySearchTermFilters((prev) => ({
                          ...prev,
                          active,
                        }));
                        if (active.length === 0) {
                          searchParams.delete('active');
                        }
                      }}
                      isSearchable={false}
                      isDisabled={false}
                      isLabelHidden={true}
                      options={statusOptions}
                      leftElement={status}
                      minWidth="200px"
                      width="fit-content"
                      tagVariant="solid"
                      placeholder={noValueSet}
                      components={customOption}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name={'severity'}
                render={({ field: { onChange, name, value } }) => {
                  return (
                    <Select
                      isMulti
                      name={name}
                      label={name}
                      data-testid="severity-select"
                      value={getSelectedValues(value, scoreOptions)}
                      onChange={(values) => {
                        onChange(createOptionString(values));
                        handleSubmit(handleSearchSubmit)();
                        const severity = values.map(
                          (value) => value.value as SetAlertStatusScore,
                        );
                        setAlertStatusBySearchTermFilters((prev) => ({
                          ...prev,
                          severity,
                        }));
                        if (severity.length === 0) {
                          searchParams.delete('severity');
                        }
                      }}
                      isSearchable={false}
                      isDisabled={false}
                      isLabelHidden={true}
                      options={scoreOptions}
                      leftElement={score}
                      minWidth="200px"
                      width="fit-content"
                      tagVariant="solid"
                      placeholder={noValueSet}
                      components={customOption}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name={'category'}
                render={({ field: { onChange, name, value } }) => {
                  return (
                    <Select
                      isMulti
                      name={name}
                      label={name}
                      data-testid="category-select"
                      value={getSelectedValues(value, typeOptions)}
                      onChange={(values) => {
                        onChange(createOptionString(values));
                        handleSubmit(handleSearchSubmit)();
                        const types = values.map(
                          (value) => value.value as SetAlertStatusType,
                        );
                        setAlertStatusBySearchTermFilters((prev) => ({
                          ...prev,
                          types,
                        }));
                        if (types.length === 0) {
                          searchParams.delete('category');
                        }
                      }}
                      isSearchable={false}
                      isDisabled={false}
                      isLabelHidden={true}
                      options={typeOptions}
                      leftElement={type}
                      minWidth="200px"
                      width="fit-content"
                      tagVariant="solid"
                      placeholder={noValueSet}
                      components={customOption}
                    />
                  );
                }}
              />
            </HStack>
          </Flex>
        </VStack>
      </Panel>
    </Column>
  );
};

const CustomSelectOption = <
  IsMulti extends boolean,
  Option extends OptionType,
>({
  children,
  ...rest
}: OptionProps<Option, IsMulti, any>) => (
  <chakraComponents.Option {...rest}>
    {rest.data.severity} {children}
  </chakraComponents.Option>
);

const customOption = {
  Option: CustomSelectOption,
};

const createOptionString = <T extends OptionType>(options: MultiValue<T>) => {
  const values = options.map(({ value }) => value);
  return values.join(', ');
};

const getSelectedValues = <T extends OptionType>(
  values: string,
  options: T[],
) => {
  const splitString = values.split(', ');
  const optionsArray = splitString.map((string) => {
    return options.find((option) => option.value.toString() === string);
  });
  const filteredArray = optionsArray.filter((option) => option);
  return filteredArray as T[];
};

const generateQueryString = <T extends OptionType>(
  value: string,
  options: T[],
  callback: (option: T) => string,
) => {
  let queryValues = '';
  const selectedOptions = getSelectedValues(value, options);

  selectedOptions.forEach((option, index) => {
    queryValues += selectedOptions.length > 1 && index === 0 ? `[` : '';
    queryValues += selectedOptions.length > 1 && index !== 0 ? ',' : '';
    queryValues += callback(option);
    queryValues +=
      selectedOptions.length > 1 && index === selectedOptions.length - 1
        ? `]`
        : '';
  });
  const activeQuery = `{"bool": {"should": ${queryValues}}}`;
  return activeQuery;
};

const statusOptions: StatusOption[] = [
  { value: 1, label: isActive.active },
  { value: 0, label: isActive.inactive },
];

const scoreOptions: ScoreOption[] = [
  {
    value: 'high',
    label: risk.high,
    min: 7,
    max: 10,
    severity: (
      <Text
        w="52px"
        textStyle="body-md"
        color="red.400"
        data-testid="score-option-high"
      >
        {i18n.formatString(i18n.pages.system.alertCatalog.range, 7, 10)}
      </Text>
    ),
  },
  {
    value: 'medium',
    label: risk.medium,
    min: 4,
    max: 6,
    severity: (
      <Text
        w="52px"
        textStyle="body-md"
        color="yellow.400"
        data-testid="score-option-medium"
      >
        {i18n.formatString(i18n.pages.system.alertCatalog.range, 4, 6)}
      </Text>
    ),
  },
  {
    value: 'low',
    label: risk.low,
    min: 1,
    max: 3,
    severity: (
      <Text
        w="52px"
        textStyle="body-md"
        color="cyan.400"
        data-testid="score-option-low"
      >
        {i18n.formatString(i18n.pages.system.alertCatalog.range, 1, 3)}
      </Text>
    ),
  },
];

const typeOptions: TypeOption[] = [
  { value: 'ml_results', label: category.ml },
  { value: 'notice', label: category.notice },
  { value: 'custom_search_rule', label: category.searchBased },
  { value: 'suricata_corelight', label: category.suricata },
];
