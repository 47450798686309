import { ComponentStyleConfig, Tooltip } from '@chakra-ui/react';

export const components: Record<string, ComponentStyleConfig> = {
  DataList: {
    baseStyle: ({ colorMode }: { colorMode: 'light' | 'dark' }) => ({
      header: {
        bg: colorMode === 'dark' ? 'gray.800' : 'gray.50',
      },
      divider: {
        opacity: 1,
        borderColor: 'border.layer.2',
      },
    }),
  },
  Radio: {
    baseStyle: {
      label: {
        textTransform: 'none',
      },
    },
  },
  Modal: {
    sizes: {
      xl: {
        dialog: {
          minWidth: '800px',
          maxWidth: '100vw',
        },
      },
    },
  },
  SeverityBox: {
    baseStyle: (props: any) => {
      return {
        border: 'solid 1px',
        borderRadius: 'base',
        bg: `${props.colorScheme}.800`,
        borderColor: `${props.colorScheme}.600`,
        color: `${props.colorScheme}.200`,
        ...(props.isSelected
          ? { outline: 'solid 1px', outlineColor: 'blue.600' }
          : {}),
        ...(props.isActive ? { opacity: 0.5, cursor: 'not-allowed' } : {}),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        px: 2,
        h: 'fit-content',
        position: 'relative',
        whiteSpace: 'nowrap',
      };
    },
    sizes: {
      xl: {
        h: 10,
        w: 10,
        fontSize: 'h4',
        lineHeight: 1,
      },
      lg: {
        h: 7,
        w: 7,
        fontSize: 'lg',
        lineHeight: 1,
      },
      sm: {
        h: 6,
        w: 10,
        fontSize: 'sm',
        lineHeight: 1,
      },
    },
    defaultProps: {
      size: 'lg',
    },
  },
};

Tooltip.defaultProps = {
  hasArrow: true,
};
