export const shadows = {
  outline: '0px 0px 0px 3px rgba(63, 153, 225, 0.6)',
  inner: 'inset 0px 2px 4px rgba(0, 0, 0, 0.06)',
  xs: '0px 0px 0px 1px rgba(0, 0, 0, 0.05)',
  sm: '0px 1px 2px rgba(0, 0, 0, 0.05)',
  base: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
  md: '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
  lg: '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
  xl: '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
  '2xl': '0px 25px 50px -12px rgba(0, 0, 0, 0.25)',
  'dark-lg':
    '0px 18px 28px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31)',
};
