import { IconProps } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';
import { Illustrations } from '@gamma/icons';
import QRCode from 'qrcode.react';

export interface CardPageIllustrationProps {
  illustration?: keyof typeof Illustrations;
  qrCodeLink?: string;
  height?: IconProps['height'];
  width?: IconProps['width'];
  'data-testid'?: string;
}

export const CardPageIllustration = ({
  illustration,
  qrCodeLink,
  height,
  width,
  'data-testid': dataTestId = 'card-page-illustration',
}: CardPageIllustrationProps) => {
  const IllustrationElement = illustration && Illustrations[illustration];

  // have to do this stupid prop stuff because if height/width are undefined
  // then apparently chakra icons don't use their default props anymore
  const heightProp = height ? { height } : {};
  const widthProp = width ? { width } : {};
  const sizeProps = {
    ...heightProp,
    ...widthProp,
  };

  return qrCodeLink ? (
    <Box p="2" borderRadius="base" bg="white" mb={4}>
      <QRCode
        value={qrCodeLink}
        size={128}
        bgColor={'#ffffff'}
        fgColor={'#000000'}
        level={'L'}
        includeMargin={false}
        renderAs={'svg'}
        data-testid={dataTestId}
      />
    </Box>
  ) : (
    <>
      {IllustrationElement && (
        <IllustrationElement {...sizeProps} mb={4} data-testid={dataTestId} />
      )}
    </>
  );
};
