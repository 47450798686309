import { createIcon } from '@chakra-ui/react';

export const ChevronUpIcon = createIcon({
  displayName: 'ChevronUpIcon',
  d: 'M7.41 15.41 12 10.83l4.59 4.58L18 14l-6-6-6 6 1.41 1.41Z',
  viewBox: '0 0 24 24',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
