import { MuiIcon } from '@gamma/display';
import { Input, InputProps } from '@gamma/form-fields';
import { useControlledProp } from '@gamma/hooks';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAsyncDebounce } from 'react-table';

export interface GlobalFilterProps
  extends Partial<Omit<InputProps, 'name' | 'onChange'>> {
  name?: string;
  filterValue: string;
  setFilter: (value?: string) => void;
  onChange?: (value?: string) => void;
}

export const GlobalFilter = ({
  name = 'data-table-search',
  filterValue,
  setFilter,
  label = 'Search',
  placeholder,
  isLabelHidden,
  onChange,
  ...rest
}: GlobalFilterProps) => {
  const [value, setValue] = useState(filterValue);

  const debouncedOnChange = useAsyncDebounce((value) => {
    onChange?.(value);
    setFilter(value || '');
  }, 200);

  useEffect(() => {
    debouncedOnChange(value);
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    debouncedOnChange(e.target.value);
  };

  useControlledProp(filterValue, (filterVal) => {
    handleChange({
      target: { value: filterVal },
    } as ChangeEvent<HTMLInputElement>);
  });

  return (
    <Input
      name={name}
      data-testid={'gamma-datatable-global-filter-search'}
      label={label}
      placeholder={placeholder ?? label}
      isLabelHidden={isLabelHidden}
      leftElement={<MuiIcon>search</MuiIcon>}
      value={value || ''}
      onChange={handleChange}
      spellCheck={false}
      {...rest}
    />
  );
};
