import { createIcon } from '@chakra-ui/react';

export const CircleUserIcon = createIcon({
  displayName: 'CircleUserIcon',
  viewBox: '0 0 24 24',
  d: 'M12 19.2c-2.5 0-4.71-1.28-6-3.2.03-2 4-3.1 6-3.1s5.97 1.1 6 3.1a7.232 7.232 0 0 1-6 3.2ZM12 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0-3a10 10 0 1 0 10 10c0-5.53-4.5-10-10-10Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
