import { createIcon } from '@chakra-ui/react';

export const FilledTerminalIcon = createIcon({
  displayName: 'FilledTerminalIcon',
  d: 'M4 2C2.89543 2 2 2.89543 2 4L2 20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2L4 2ZM5.10794 15.1758L8.28373 12L5.10794 8.82424L6.52794 7.41424L11.1137 12L6.52794 16.5858L5.10794 15.1758ZM12.3921 14.5233L18.8921 14.5233V16.5858H12.3921L12.3921 14.5233Z"',
  viewBox: '0 0 24 24',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
