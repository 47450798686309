import { createIcon } from '@chakra-ui/react';

export const TriangleExclamationIcon = createIcon({
  displayName: 'TriangleExclamationIcon',
  viewBox: '0 0 24 24',
  d: 'M13 14h-2V9h2v5Zm0 4h-2v-2h2v2ZM1 21h22L12 2 1 21Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
