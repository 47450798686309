import { dateRangeOptions, REGEX, ROUTES } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import {
  useDateRangeQueryString,
  useSSOLoginHandler,
} from '@gamma/investigator/hooks';
import { useGetUserTcAcceptance } from '@gamma/investigator/queries';
import { setRole } from '@gamma/investigator/utilities';
import { AuthState } from '@gamma/shared/types';
import { Auth, Hub } from 'aws-amplify';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const { privacyPolicy, login, base } = ROUTES;

export const ConditionalRedirects = ({
  setSearch,
  isSSOLogin,
  setDateRange,
  defaultRangeIndex,
  setDefaultRangeIndex,
  setAuthStateLoading,
  setTcAcceptanceLoading,
}: any) => {
  const navigate = useNavigate();

  const { setAuthState, setUser, setUserRole } = useContext(AuthContext);

  const [searchParams] = useSearchParams();

  const routerLocation = useLocation();
  const { search, pathname } = routerLocation;

  const now = moment();

  const { start, end } = useDateRangeQueryString();
  const paramsRange = end - start;

  useEffect(() => {
    setDateRange(dateRangeOptions[defaultRangeIndex]);
  }, [defaultRangeIndex]);

  const { checkSsoError, getSessionData, idpLogin } = useSSOLoginHandler();

  const [getUserTcAcceptance, { loading: tcAcceptanceLoading }] =
    useGetUserTcAcceptance();

  const getNavPath = () => {
    const paramEmail = searchParams.get('email') ?? '';
    const isEmailValid = REGEX.EMAIL.test(paramEmail);
    return isEmailValid
      ? `/login/trouble/password/reset?${searchParams.toString()}`
      : '/login';
  };

  const checkUserLoggedIn = async () => {
    const sessionData = await getSessionData();
    if (sessionData) {
      idpLogin(sessionData);
    }
    try {
      const user = await Auth.currentAuthenticatedUser();

      if (user) {
        // setting the isSSOLogin true if identities field is present in the payload - FYI identities will be present only for sso user
        const isSSOLogin =
          user?.signInUserSession?.idToken?.payload.hasOwnProperty(
            'identities',
          );
        window.sessionStorage.setItem('isSSOLogin', isSSOLogin);

        setUser(user);
        setAuthState(AuthState.SignedIn);
        setRole({ user, setUserRole });
        window.localStorage.setItem(
          'docs_id_token',
          user?.signInUserSession?.idToken?.jwtToken,
        );
        if (pathname.startsWith(login)) {
          navigate(base);
        }
      }
    } catch (e) {
      console.error(e);
      localStorage.removeItem('docs_id_token');
      localStorage.removeItem('id_token');
      setUser(undefined);
      setAuthState(undefined);

      const navPath = getNavPath();
      navigate(
        `${navPath}?next=${encodeURIComponent(
          routerLocation.pathname + routerLocation.search,
        )}`,
        {
          replace: true,
        },
      );
    }
    console.log('check auth state');
    setAuthStateLoading(false);
  };

  useEffect(() => {
    const ssoUserError = checkSsoError();
    if (ssoUserError.type === 'newUser') {
      return navigate('/privacy-policy', { replace: true });
    } else if (ssoUserError.type === 'ssoError') {
      return navigate('/sso-error', {
        replace: true,
        state: { message: ssoUserError.message },
      });
    }
    checkUserLoggedIn();
  }, []);

  useEffect(() => {
    Hub.listen('auth', (data) => {
      console.log('auth event', data.payload.event);
      switch (data.payload.event) {
        case 'tokenRefresh_failure':
          checkUserLoggedIn();
          break;
        default:
          break;
      }
    });
  }, []);

  // redirect to next value in local storage if it exists
  useEffect(() => {
    const nextFromLocalStorage = localStorage.getItem('next');
    const isSSOLogin = sessionStorage.getItem('isSSOLogin');
    if (nextFromLocalStorage && isSSOLogin) {
      navigate(nextFromLocalStorage, { replace: true });
      localStorage.removeItem('next');
    }
  }, [routerLocation.pathname, navigate]);

  useEffect(() => {
    if (isSSOLogin && !localStorage.getItem('accept_privacy_policy')) {
      getUserTcAcceptance().then(({ data }) => {
        const isEulaAccepted = data?.getUserTcAcceptance?.body?.data;
        localStorage.setItem('isEulaAccepted', isEulaAccepted? "true" :"false" );
      });
    }
  }, [isSSOLogin]);

  useEffect(() => {
    if (start && end) {
      let optionIndex = 0;
      let lowest = Infinity;
      dateRangeOptions.forEach(([amount, type], index) => {
        const optionRange = now.unix() - now.subtract(amount, type).unix();
        const diffBetweenParamsAndOption = Math.abs(paramsRange - optionRange);
        if (diffBetweenParamsAndOption < lowest) {
          optionIndex = index;
          lowest = diffBetweenParamsAndOption;
        }
      });
      setDefaultRangeIndex(optionIndex);
    }
  }, [paramsRange, now, start, end]);

  useEffect(() => {
    setSearch(search);
  }, [search]);

  useEffect(() => {
    setTcAcceptanceLoading(tcAcceptanceLoading);
  }, [tcAcceptanceLoading]);
  return <></>;
};
