import { OperationVariables } from '@apollo/client';
import { ButtonProps, useDisclosure, useToast } from '@chakra-ui/react';
import { useIDPUploadXMLHandler } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  DESCRIBE_IDENTITY_PROVIDER,
  useConfigureIdentityProvider,
  useEnableDisableUserPoolAppClientIdp,
  useUpdateIdentityProvider,
} from '@gamma/investigator/queries';
import { ConfirmationModal } from '@gamma/overlay';
import { ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ModalBody } from './IdpConfigurationModalBody';

interface IdpConfigurationModalProps {
  children: ReactNode;
  data: OperationVariables | undefined;
  isUpdating?: boolean;
}

export const attributeMapping = JSON.stringify({
  email: 'email_address',
  'custom:group': 'group',
});

export enum ModalSteps {
  configureIDP = 1,
  configureInvestigator = 2,
  configureRoles = 3,
}

export const IdpConfigurationModal = ({
  children,
  data,
  isUpdating = false,
}: IdpConfigurationModalProps) => {
  const { idpConfigurationModal, button } = i18n.pages.access;

  const useFormProps = useForm();

  const [step, setStep] = useState(ModalSteps.configureIDP);
  const [xmlFile, setXmlFile] = useState<File[]>([]);
  const [showAlert, setShowAlert] = useState<string>('');
  const [fileError, setFileError] = useState<string>('');
  const [parsedData, setParsedData] = useState<Record<string, string>>();

  const { onOpen, onClose, isOpen } = useDisclosure({
    onClose: () => {
      setStep(ModalSteps.configureIDP);
      resetModalForm();
    },
    onOpen: () => {
      if (isUpdating) {
        setStep(ModalSteps.configureIDP);
      }
    },
  });

  const useIDPUploadXMLHandlerProps = useIDPUploadXMLHandler({
    setFileError,
    setParsedData,
  });

  const variables = {
    attribute_mapping: attributeMapping,
    provider_details: useIDPUploadXMLHandlerProps.xml,
    provider_type: 'xml',
  };

  const [enableDisableIdpClient, { loading: enableSsoLoading }] =
    useEnableDisableUserPoolAppClientIdp({
      fetchPolicy: 'network-only',
      awaitRefetchQueries: true,
      refetchQueries: [DESCRIBE_IDENTITY_PROVIDER, 'describeIdentityProvider'],
      onCompleted: () => {
        showToastMessage({
          status: 'success',
          title: idpConfigurationModal.configure.toast.title,
          description: idpConfigurationModal.configure.toast.description,
          isClosable: true,
          position: 'bottom-right',
        });
        onClose();
      },
      onError: (error) => {
        showToastMessage({
          status: 'error',
          title: idpConfigurationModal.configure.toast.errorTitle,
          description: error?.message,
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const [configureIdentityProvider, { loading: configIdpLoading }] =
    useConfigureIdentityProvider({
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data?.configureIdentityProvider) {
          setStep(ModalSteps.configureRoles);
        } else {
          showToastMessage({
            status: 'error',
            title: idpConfigurationModal.configure.toast.errorTitle,
            description: idpConfigurationModal.configure.toast.errorDescription,
            isClosable: true,
            position: 'bottom-right',
          });
        }
      },
      onError: (error) => {
        resetModalForm();
        setShowAlert(error?.message);

        showToastMessage({
          status: 'error',
          title: idpConfigurationModal.configure.toast.errorTitle,
          description: error?.message,
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const [updateIdentityProvider, { loading: updateIdpLoading }] =
    useUpdateIdentityProvider({
      fetchPolicy: 'network-only',
      refetchQueries: isUpdating
        ? [DESCRIBE_IDENTITY_PROVIDER, 'describeIdentityProvider']
        : [],
      onCompleted: (data) => {
        if (data?.updateIdentityProvider?.body?.message) {
          showToastMessage({
            status: 'success',
            title: idpConfigurationModal.update.toast.title,
            description: data.updateIdentityProvider.body.message,
            isClosable: true,
            position: 'bottom-right',
          });

          if (isUpdating) {
            onClose();
            return;
          }

          if (isConfigured) {
            setStep(ModalSteps.configureRoles);
            return;
          }
        }
      },
      onError: (error) => {
        showToastMessage({
          status: 'error',
          title: idpConfigurationModal.update.toast.errorTitle,
          description: error.message,
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const loading = configIdpLoading || enableSsoLoading;

  const resetModalForm = () => {
    setParsedData({});
    setXmlFile([]);
    setShowAlert('');
    setFileError('');
  };

  const isConfigured = !!data?.describeIdentityProvider?.provider_details;

  const showToastMessage = useToast();
  let confirmProperties: ButtonProps = {};
  let cancelProperties: ButtonProps = {};

  const configInvCnfDisable = (): boolean => {
    if (isUpdating) {
      return !xmlFile.length || Boolean(fileError);
    }

    return !xmlFile.length || Boolean(fileError);
  };

  const args = () => {
    switch (step) {
      case ModalSteps.configureIDP:
        confirmProperties = {
          onClick: (e) => {
            e.preventDefault();
            setStep(ModalSteps.configureInvestigator);
          },
        };

        return {
          confirmProps: confirmProperties,
          confirmText: button.next,
        };

      case ModalSteps.configureInvestigator:
        confirmProperties = {
          type: 'submit',
          form: 'idpConfigurationForm',
          onClick: () => {
            if (isUpdating) {
              setStep(ModalSteps.configureRoles);
              return;
            }

            if (isConfigured) {
              if (xmlFile.length) {
                updateIdentityProvider({ variables });
              } else {
                setStep(ModalSteps.configureRoles);
              }
            }
          },
          isDisabled: configInvCnfDisable(),
          isLoading: configIdpLoading || updateIdpLoading,
        };

        cancelProperties = { isDisabled: configIdpLoading };

        return {
          confirmProps: confirmProperties,
          cancelProps: cancelProperties,
          isClosedOnCancel: false,
          confirmText: button.next,
          cancelText: button.back,
          onCancel: () => setStep(ModalSteps.configureIDP),
        };

      case ModalSteps.configureRoles:
        confirmProperties = {
          isLoading: enableSsoLoading || updateIdpLoading,
        };

        cancelProperties = {
          isDisabled: enableSsoLoading,
        };

        return {
          confirmProps: confirmProperties,
          cancelProps: cancelProperties,
          isClosedOnCancel: false,
          confirmText: isUpdating ? button.update : button.enable,
          cancelText: button.back,
          onConfirm: () => {
            if (isUpdating) {
              updateIdentityProvider({ variables });
              return;
            }

            enableDisableIdpClient({ variables: { enable: true } });
          },
          onCancel: () => {
            setStep(ModalSteps.configureInvestigator);
          },
        };

      default:
        return {};
    }
  };

  return (
    <ConfirmationModal
      title={
        isUpdating
          ? idpConfigurationModal.update.title
          : idpConfigurationModal.configure.title
      }
      size="lg"
      body={
        <ModalBody
          step={step}
          setFileError={setFileError}
          xmlFile={xmlFile}
          setXmlFile={setXmlFile}
          useFormProps={useFormProps}
          resetModalForm={resetModalForm}
          showAlert={showAlert}
          configureIdentityProvider={configureIdentityProvider}
          useIDPUploadXMLHandlerProps={useIDPUploadXMLHandlerProps}
          fileError={fileError}
          data={data}
          isUpdating={isUpdating}
          parsedData={parsedData}
          setParsedData={setParsedData}
        />
      }
      onClose={onClose}
      onOpen={onOpen}
      isOpen={isOpen}
      isCentered
      closeOnEsc={!loading}
      closeOnOverlayClick={!loading}
      isCloseDisabled={loading}
      isClosedOnConfirm={false}
      {...args()}
    >
      {children}
    </ConfirmationModal>
  );
};
