import { createIcon } from '@chakra-ui/react';

export const ClipboardIcon = createIcon({
  displayName: 'ClipboardIcon',
  viewBox: '0 0 24 24',
  d: 'M17 9H7V7h10v2Zm0 4H7v-2h10v2Zm-3 4H7v-2h7v2ZM12 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm7 0h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
