import {
  Button,
  Divider,
  HStack,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { MuiIcon } from '@gamma/display';
import { CalendarPopover, Label, LabelProps } from '../Components';
import { DateInput, DateInputProps } from '../DateInput';
import { useOutsideCalendarClick } from '../hooks';
import { getLabelProps } from '../util';

export interface DateRangePickerProps
  extends Omit<LabelProps, 'children' | 'htmlFor'> {
  initDate?: Date;
  selectedDates?: Date[];
  minDate?: Date;
  maxDate?: Date;
  bg?: string;
  /**
   * Flag to show clear dates button
   */
  isClearable?: boolean;
  /**
   *  Operations to perform on clear button click
   */
  onClear?: () => void;
  /**
   * Disables date range picker component
   */
  isDisabled?: boolean;
  orientation?: 'vertical' | 'horizontal';
  onDateChange: (date: Date[]) => void;
  isPortal?: boolean;
  ['data-testid']?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

interface DateRangePickerWrapperProps {
  children: ReactNode;
  orientation?: 'vertical' | 'horizontal';
}

const DateRangePickerWrapper = ({
  children,
  orientation = 'horizontal',
}: DateRangePickerWrapperProps) =>
  orientation === 'vertical' ? (
    <VStack
      w="100%"
      spacing={0}
      alignItems="end"
      divider={<Divider borderColor="border.1" />}
    >
      {children}
    </VStack>
  ) : (
    <HStack
      divider={<Divider orientation="vertical" borderColor="border.1" />}
      spacing={0}
      my="-1px" // parent border offset
    >
      {children}
    </HStack>
  );

export const DateRangePicker = ({
  name,
  initDate,
  selectedDates: propsSelectedDates,
  isPortal = true,
  minDate = new Date('January 1, 2000'), // keeps onDateChange from triggering when an inputted year is < 1000
  maxDate,
  bg,
  orientation = 'horizontal',
  onDateChange,
  isDisabled,
  isClearable,
  onClear,
  size,
  'data-testid': dataTestId = 'date-range-picker',
  ...other
}: DateRangePickerProps) => {
  const labelProps = getLabelProps(other as unknown as Record<string, unknown>);
  const [selectedDates, setSelectedDates] = useState<Date[]>(
    propsSelectedDates ?? [],
  );

  const isHorizontal = useMemo(() => {
    return orientation === 'horizontal';
  }, [orientation]);

  const calendarPopup = useDisclosure();

  useEffect(() => {
    if (!calendarPopup.isOpen && propsSelectedDates) {
      setSelectedDates(propsSelectedDates);
    }
  }, [propsSelectedDates, calendarPopup.isOpen]);

  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const clearBgColor_HOVER = useColorModeValue('gray.100', 'gray.800');
  const textColor = useColorModeValue('gray.900', 'gray.50');

  const { calendarRef, inputRef } = useOutsideCalendarClick({
    onClose: calendarPopup.onClose,
  });

  const handleDateChange = (dates: Date[], isInput = false) => {
    const hasEndDate = Boolean(dates[1]);
    if (hasEndDate) {
      onDateChange?.(dates.filter((date) => date));
      !isInput && calendarPopup.onClose();
    }
    setSelectedDates(dates.filter((date) => date));
  };

  const commonInputProps: Partial<DateInputProps> = useMemo(
    () => ({
      onBlur: (event) => {
        const nextFocusIsCalendar = calendarRef.current?.contains(
          event.relatedTarget,
        );
        if (!nextFocusIsCalendar) {
          calendarPopup.onClose();
        }
      },
      onKeyDown: (event) => {
        if ([' ', 'ArrowDown'].includes(event.key)) {
          calendarPopup.onOpen();
        }
        if (event.key === 'Escape') {
          calendarPopup.onClose();
        }
      },
      isLabelHidden: true,
      hideErrorMessage: true,
      hideBorder: true,
      bg: 'transparent',
      size,
      inputHeight: 'auto',
      py: 0,
    }),
    [calendarRef, calendarPopup, size],
  );

  const handleClearClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.stopPropagation();
      setSelectedDates([]);
      onClear?.();
    },
    [onClear],
  );

  return (
    <Label {...labelProps} name={name}>
      <CalendarPopover
        selectedDates={selectedDates}
        onDateChange={handleDateChange}
        isRange
        ref={calendarRef}
        minDate={minDate}
        maxDate={maxDate}
        returnFocusOnClose={false}
        initDate={initDate}
        isPortal={isPortal}
        {...calendarPopup}
      >
        <Button
          w="100%"
          h="auto"
          minH="full"
          justifyContent="start"
          px={isHorizontal ? 3 : 0}
          py="1px"
          tabIndex={-1}
          ref={inputRef}
          onClick={(event) => {
            if (calendarPopup.isOpen) {
              event.preventDefault();
            }
          }}
          isDisabled={isDisabled}
          bg={bg ?? bgColor}
          border="solid 1px"
          borderColor="border.1"
          borderRadius="base"
          minW={!isHorizontal ? 'auto' : 'max-content'}
          maxW={!isHorizontal ? 'auto' : 'max-content'}
          data-testid={`${dataTestId}-trigger`}
        >
          <DateRangePickerWrapper orientation={orientation}>
            <DateInput
              name={`${name}-from`}
              label={`Start Date`}
              value={selectedDates[0]}
              isDisabled={isDisabled}
              onChange={(date) => {
                handleDateChange([date, selectedDates[1]], true);
              }}
              minDate={minDate}
              maxDate={selectedDates[1] ?? maxDate}
              data-testid={`${dataTestId}-from`}
              leftElement="Start"
              {...commonInputProps}
            />
            <DateInput
              label={`End Date`}
              name={`${name}-to`}
              value={selectedDates[1]}
              isDisabled={isDisabled}
              onChange={(date) => {
                handleDateChange([selectedDates[0], date], true);
              }}
              minDate={selectedDates[0] ?? maxDate}
              maxDate={maxDate}
              data-testid={`${dataTestId}-to`}
              leftElement="End"
              {...commonInputProps}
            />
            {isClearable && selectedDates.length && (
              <Button
                borderRadius={6}
                size="box"
                ml={3}
                isDisabled={isDisabled}
                onClick={handleClearClick}
                _hover={{ bg: clearBgColor_HOVER }}
                _focus={{ outline: 'none' }}
              >
                <MuiIcon>close</MuiIcon>
              </Button>
            )}
          </DateRangePickerWrapper>
        </Button>
      </CalendarPopover>
    </Label>
  );
};
