import { useMergeRefs, useTab } from '@chakra-ui/react';
import { FocusEvent, useCallback, useEffect, useRef } from 'react';

export interface IUseScrollTabIntoViewParams {
  tabProps: ReturnType<typeof useTab>;
}

export const useScrollTabIntoView = ({
  tabProps,
}: IUseScrollTabIntoViewParams) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const isSelected = !!tabProps['aria-selected'];

  useEffect(() => {
    if (isSelected && buttonRef.current?.scrollIntoView) {
      buttonRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [isSelected]);

  const mergedRefs = useMergeRefs(tabProps.ref, buttonRef);

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLButtonElement>) => {
      tabProps.onFocus?.(e);
      e.target.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    },
    [tabProps],
  );

  const props = {
    ...tabProps,
    onFocus: handleFocus,
    ref: mergedRefs,
  };

  return props;
};
