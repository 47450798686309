import { Button, ButtonGroup, HStack, Text, useToast, VStack } from '@chakra-ui/react';
import { Switch } from '@gamma/form-fields';
import { i18n } from '@gamma/investigator/localization';
import { GET_PRIVILEGES, useUpdatePrivileges } from '@gamma/investigator/queries';
import { Alert, Modal } from '@gamma/overlay';
import { LoadingSpinner } from '@gamma/progress';
import { Controller, useForm } from 'react-hook-form';

const { integrations } = i18n.pages;
const { enabled, disabled, success, save, cancel } = integrations;

const {
  warning,
  warningDetails,
  whenYouEnableGPT,
  chatGPTOnChangeToastDescription,
} = integrations.chatGPT;

interface ChatGPTSettingsCardModalProps {
  isOpen: boolean;
  isGPTEnabled: boolean;
  onClose: () => void;
}

export const ChatGPTSettingsCardModal = ({
  isOpen,
  isGPTEnabled,
  onClose,
}: ChatGPTSettingsCardModalProps) => {
  const showToast = useToast();

  const {
    handleSubmit,
    control,
    watch,
    formState: { isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      chatgpt: isGPTEnabled,
    },
  });

  const formData = watch();

  const [updatePrivileges, { loading: updatePrivilegesLoading }] =
    useUpdatePrivileges({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_PRIVILEGES,
        },
      ],
      variables: {
        input: {
          chatgpt: formData.chatgpt,
        },
      },
      onCompleted: async () => {
        const toastDescription = i18n.formatString(
          chatGPTOnChangeToastDescription,
          formData.chatgpt ? enabled : disabled,
        );

        showToast({
          title: success,
          description: toastDescription,
          status: 'success',
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const handleSubmitGPTStatus = async () => {
    await updatePrivileges();
    onClose();
  };

  return (
    <Modal
      size="lg"
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      title="GPT 3.5 Integration"
      closeOnEsc={!updatePrivilegesLoading}
      isCloseDisabled={updatePrivilegesLoading}
      closeOnOverlayClick={!updatePrivilegesLoading}
      body={
        <VStack
          as="form"
          spacing={4}
          alignItems="flex-start"
          onSubmit={handleSubmit(handleSubmitGPTStatus)}
        >
          <Text>{whenYouEnableGPT}</Text>
          <HStack spacing={2} alignItems="center">
            {updatePrivilegesLoading ? (
              <LoadingSpinner size="sm" />
            ) : (
              <Controller
                name="chatgpt"
                control={control}
                render={({ field: { onChange, name, value } }) => (
                  <Switch
                    inline
                    name={name}
                    isChecked={value}
                    onChange={onChange}
                    data-testid="exportSwitch"
                    isDisabled={updatePrivilegesLoading}
                    label={formData.chatgpt ? enabled : disabled}
                  />
                )}
              />
            )}
          </HStack>
          <Alert variant="subtle" status="warning">
            <Text textStyle="h6" display="block">
              {warning}
            </Text>
            <Text>{warningDetails}</Text>
          </Alert>
          <ButtonGroup pb={2} w="100%" justifyContent="end">
            <Button
              variant="solid"
              colorScheme="gray"
              onClick={onClose}
              isDisabled={updatePrivilegesLoading}
            >
              {cancel}
            </Button>
            <Button
              type="submit"
              variant="solid"
              isDisabled={!isDirty}
              isLoading={updatePrivilegesLoading}
            >
              {save}
            </Button>
          </ButtonGroup>
        </VStack>
      }
    />
  );
};
