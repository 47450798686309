export const colors = {
  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },
  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },
  gray: {
    50: '#f4f4f4',
    100: '#e0e0e0',
    200: '#c6c6c6',
    300: '#a8a8a8',
    400: '#8d8d8d',
    500: '#6f6f6f',
    600: '#525252',
    700: '#393939',
    800: '#262626',
    900: '#161616',
  },
  corelight: {
    logo: '#00AB49',
    50: '#DCFAE9',
    100: '#A8F0C6',
    200: '#6EDB9B',
    300: '#43BF79',
    400: '#23A15A',
    500: '#198047',
    600: '#0F6134',
    700: '#044220',
    800: '#022E13',
    900: '#071A0B',
  },
  blue: {
    50: '#edf5ff',
    100: '#d0e2ff',
    200: '#a6c8ff',
    300: '#78a9ff',
    400: '#4589ff',
    500: '#0f62fe',
    600: '#0043ce',
    700: '#002d9c',
    800: '#001d6c',
    900: '#001141',
  },
  red: {
    50: '#fff1f1',
    100: '#ffd7d9',
    200: '#ffb3b8',
    300: '#ff8389',
    400: '#fa4d56',
    500: '#da1e28',
    600: '#a2191f',
    700: '#750e13',
    800: '#520408',
    900: '#2d0709',
  },
  green: {
    50: '#defbe6',
    100: '#a7f0ba',
    200: '#6fdc8c',
    300: '#42be65',
    400: '#24a148',
    500: '#198038',
    600: '#0e6027',
    700: '#044317',
    800: '#022d0d',
    900: '#071908',
  },
  yellow: {
    50: '#fcf4d6',
    100: '#fddc69',
    200: '#f1c21b',
    300: '#d2a106',
    400: '#b28600',
    500: '#8e6a00',
    600: '#684e00',
    700: '#483700',
    800: '#302400',
    900: '#1c1500',
  },
  teal: {
    50: '#d9fbfb',
    100: '#9ef0f0',
    200: '#3ddbd9',
    300: '#08bdba',
    400: '#009d9a',
    500: '#007d79',
    600: '#005d5d',
    700: '#004144',
    800: '#022b30',
    900: '#081a1c',
  },
  orange: {
    50: '#fff2e8',
    100: '#ffd9be',
    200: '#ffb784',
    300: '#ff832b',
    400: '#eb6200',
    500: '#ba4e00',
    600: '#8a3800',
    700: '#5e2900',
    800: '#3e1a00',
    900: '#231000',
  },
  purple: {
    50: '#f6f2ff',
    100: '#e8daff',
    200: '#d4bbff',
    300: '#be95ff',
    400: '#a56eff',
    500: '#8a3ffc',
    600: '#6929c4',
    700: '#491d8b',
    800: '#31135e',
    900: '#1c0f30',
  },
  pink: {
    50: '#fff0f7',
    100: '#ffd6e8',
    200: '#ffafd2',
    300: '#ff7eb6',
    400: '#ee5396',
    500: '#d02670',
    600: '#9f1853',
    700: '#740937',
    800: '#510224',
    900: '#2a0a18',
  },
};
