import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

export const EDIT_USER = gql`
  mutation editUser($input: EditUserInput!) {
    editUser(input: $input) {
      status_code
      body {
        data
        message
        success
      }
    }
  }
`;

export const useEditUser = (
  options: MutationHookOptions<any, OperationVariables>,
) => {
  return useMutation(EDIT_USER, options);
};
