import { DateSelectOption } from '@gamma/form-fields';
import { dateRangeOptions } from '@gamma/investigator/constants';
import { ReactNode, useState } from 'react';

import { DateRangeContext } from './DateRangeContext';

interface DateRangeProviderProps {
  children?: ReactNode;
}

export const DateRangeProvider = ({ children }: DateRangeProviderProps) => {
  const [defaultRangeIndex, setDefaultRangeIndex] = useState<number>(5);
  const [dateRange, setDateRange] = useState<DateSelectOption | undefined>(
    dateRangeOptions[defaultRangeIndex],
  );

  const [showCustomDateRangePicker, setShowCustomDateRangePicker] =
    useState<boolean>(false);

  return (
    <DateRangeContext.Provider
      value={{
        dateRange,
        setDateRange,
        defaultRangeIndex,
        setDefaultRangeIndex,
        showCustomDateRangePicker,
        setShowCustomDateRangePicker,
      }}
    >
      {children}
    </DateRangeContext.Provider>
  );
};
