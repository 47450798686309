import { createIcon } from '@chakra-ui/react';

export const CircleMinusIcon = createIcon({
  displayName: 'CircleMinusIcon',
  viewBox: '0 0 24 24',
  d: 'M12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Zm0-18a10 10 0 1 0 0 20 10 10 0 0 0 0-20ZM7 13h10v-2H7',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
