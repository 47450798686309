import { createIcon } from '@chakra-ui/react';

export const OpenExternalIcon = createIcon({
  displayName: 'OpenExternalIcon',
  viewBox: '0 0 24 24',
  d: 'M14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7Zm5 16H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7h-2v7Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
