import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

export const DESCRIBE_IDENTITY_PROVIDER = gql`
  query describeIdentityProvider {
    describeIdentityProvider {
      attribute_mapping
      created_at
      idp_identifiers
      last_modified
      is_sso_enabled
      provider_details
    }
  }
`;

export const useDescribeIdentityProvider = (
  options: QueryHookOptions<OperationVariables>,
) => useQuery(DESCRIBE_IDENTITY_PROVIDER, options);
