import { createIcon } from '@chakra-ui/react';

export const CrowdStrike = createIcon({
  displayName: 'CrowdStrike',
  viewBox: '0 0 36 36',
  path: (
    <path
      fill="#FC0000"
      fill-rule="evenodd"
      d="M34.208 27.188c-.776-.077-2.151-.27-3.872.599-1.72.868-2.395.904-3.24.814.248.455.75 1.076 2.332 1.189 1.581.112 2.337.159 1.505 2.128.02-.594-.121-1.745-1.689-1.543-1.57.202-1.936 1.615-.253 2.32-.548.11-1.707.176-2.535-1.993-.574.25-1.46.75-3.066-.489.374.135.79.207 1.252.216-1.425-.68-2.786-1.945-3.657-3.14.691.515 1.454 1.027 2.226 1.123-.913-1.11-3.02-3.335-5.599-5.618 1.658 1.083 3.658 2.792 6.933 2.409 3.275-.386 5.475-1.135 9.663 1.985Zm-14.017-.772c-2.052-.89-2.492-1.068-5.132-1.738-2.64-.67-5.237-2.068-6.973-4.246 1.225.897 3.723 2.7 6.292 2.505-.39-.572-1.11-1.016-1.97-1.465.971.234 3.908.985 7.783 4.944Zm-11.75-13.13C1.652 9.044.304 5.776 0 4.52c4.264 4.663 7.503 6.489 9.708 7.947 4.53 2.889 5.486 4.831 6.015 6.35-2.173-3.069-5.05-4.008-7.283-5.531Zm.843 3.362c-6.211-3.284-7.653-5.913-7.936-7.11 3.109 3.315 6.565 5.06 8.59 6.192 4.388 2.353 5.507 4.004 6.063 5.29-2.09-2.537-4.997-3.426-6.717-4.372ZM29.385 20.9c-.576 1.37.088 1.523.357 1.626 1.366.522 4.98 1.193 4.753 2.72.422.422 1.983 2.102 1.33 3.268-.56-.915-3.138-3.29-6.256-3.779-3.117-.489-5.96.565-8.308-.994-1.994-1.435-2.314-1.978-4.322-5.266-.902-1.476-1.077-2.82-4.138-5.318-3.062-2.499-5.926-3.831-7.128-7.552 2.63 3.234 6.033 6.323 14.586 8.77 10.712 2.987 9.902 4.632 9.126 6.525Zm4.776 4.69c-.018-.593.063-.936-1.56-1.182.58.285 1.1.68 1.56 1.182Z"
      clip-rule="evenodd"
    />
  ),
  defaultProps: {
    height: '36px',
    width: '36px',
  },
});
