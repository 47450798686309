import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

export const DELETE_IDENTITY_PROVIDER = gql`
  mutation deleteIdentityProvider($provider_name: String) {
    deleteIdentityProvider(provider_name: $provider_name) {
      body {
        data
        message
        success
      }
      status_code
    }
  }
`;

export const useDeleteIdentityProvider = (
  options: MutationHookOptions<any, OperationVariables>,
) => useMutation(DELETE_IDENTITY_PROVIDER, options);
