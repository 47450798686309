import { isPlainObject, merge } from 'lodash';

export const flattenTokens = (tokens: { [key: string]: any }) => {
  const result: { [key: string]: any } = {};

  const searchObjectForStrings = (
    currentNode: object,
    pathKeys: string[] = [],
  ) => {
    for (const [key, value] of Object.entries(currentNode)) {
      if (typeof value === 'string') {
        // we're done, we should flatten out this part.
        const path = pathKeys.join('.');
        const appendedProperty = { [path]: { [key]: value } };
        merge(result, appendedProperty);
      } else if (isPlainObject(value)) {
        // we keep digging through until we find strings
        const newPathKeys = [...pathKeys, key];
        searchObjectForStrings(value, newPathKeys);
      }
    }
  };

  searchObjectForStrings(tokens);

  return result;
};
