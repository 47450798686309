import { createIcon } from '@chakra-ui/react';

export const StopIcon = createIcon({
  displayName: 'StopIcon',
  viewBox: '0 0 24 24',
  d: 'M18 18H6V6h12v12Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
