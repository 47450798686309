import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { PaginatedDetectionSummaries } from './types';

export interface IGetTopDetectionsRawPaginated {
  getTopDetectionsRawPaginated: PaginatedDetectionSummaries;
}

export const GET_TOP_DETECTIONS_RAW_PAGINATED = gql`
  query getTopDetectionsRawPaginated(
    $start: Float!
    $end: Float!
    $must_conds: [String]
    $offset: Int
    $size: Int
    $sort: [SortParameterInput]
  ) {
    getTopDetectionsRawPaginated(
      start: $start
      end: $end
      must_conds: $must_conds
      offset: $offset
      size: $size
      sort: $sort
    ) {
      summaries {
        alert_name
        alert_type
        content_id
        detection_count
        entity_count
        entity_id
        entity_name
        entity_type
        first_seen
        last_seen
        severity
        tenant
        tenant_detection
        tenant_entity
        tenant_info {
          tenant_id
          tenant_display_name
        }
        total_detections
        is_custom_severity
      }
      total_items
      offset
      size
      sort {
        sort_by
        sort_dir
      }
    }
  }
`;

export const useGetTopDetectionsRawPaginated = (
  options: QueryHookOptions<IGetTopDetectionsRawPaginated, OperationVariables>,
) =>
  useQuery<IGetTopDetectionsRawPaginated>(
    GET_TOP_DETECTIONS_RAW_PAGINATED,
    options,
  );
