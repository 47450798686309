import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

import {
  FieldValidationList,
  FieldValidationListProps,
} from '../FieldValidationList';

export interface FieldValidationListPopoverProps
  extends FieldValidationListProps {
  children: ReactNode;
  title: ReactNode;
}

export const FieldValidationListPopover = ({
  rules,
  title,
  children,
}: FieldValidationListPopoverProps) => {
  return (
    <Box>
      <Popover trigger="hover" matchWidth>
        <PopoverTrigger>{children}</PopoverTrigger>
        <PopoverContent w="full">
          <PopoverBody>
            <Text color="gray.600">{title}</Text>
            <FieldValidationList rules={rules} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
