import { createIcon } from '@chakra-ui/react';

export const ClipboardInfoIcon = createIcon({
  displayName: 'ClipboardInfoIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path d="m17 13 5.5 9.5h-11L17 13Z" fill="#222930" />
      <path
        d="M17.5 21h-1v-2.5h1V21Zm0-3.5h-1v-1h1v1Zm-6 5h11L17 13l-5.5 9.5Z"
        fill="#ECC94B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.845 13H7v-2h10v.004l4 6.91V5a2 2 0 0 0-2-2h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h6.213l2.316-4H7v-2h7v1.186L15.845 13ZM7 7v2h10V7H7Zm5.707-3.707a1 1 0 1 0-1.414 1.414 1 1 0 0 0 1.414-1.414Z"
        fill="currentColor"
      />
    </>
  ),
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
