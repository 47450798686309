import {
  Switch as ChakraSwitch,
  SwitchProps as ChakraSwitchProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';
import { Label, LabelProps } from '../Components';

export interface SwitchProps extends Omit<LabelProps, 'children'> {
  /** The name of the input */
  name: string;
  /** The size of the switch */
  size?: ChakraSwitchProps['size'];
  /** The variant name specifying how the switch should render */
  variant?: string;
  /** The default checked state for the switch */
  defaultChecked?: boolean;
  /** Specifying whether or not the switch is enabled */
  isChecked?: boolean;
  /** The function triggered whenever the switch is toggled */
  onChange?: ChakraSwitchProps['onChange'];
  /** The function triggered whenever the switch is focused */
  onFocus?: ChakraSwitchProps['onFocus'];
  /** The function triggered whenever the switch is blurred */
  onBlur?: ChakraSwitchProps['onBlur'];
  'aria-expanded'?: boolean;
  'aria-controls'?: string;
  'data-testid'?: string;
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  (
    {
      name,
      isChecked,
      defaultChecked,
      onChange,
      onFocus,
      onBlur,
      variant,
      size,
      'aria-expanded': ariaExpanded,
      'aria-controls': ariaControls,
      'data-testid': dataTestId = 'gamma-switch',
      ...rest
    },
    ref,
  ) => (
    <Label name={name} htmlFor={name} {...rest}>
      <ChakraSwitch
        id={name}
        size={size}
        name={name}
        variant={variant}
        isChecked={isChecked}
        defaultChecked={defaultChecked}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref}
        aria-expanded={ariaExpanded}
        aria-controls={ariaControls}
        data-testid={dataTestId}
      />
    </Label>
  ),
);
