import { Box } from '@chakra-ui/react';
import {
  Detection,
  DetectionAlertGroupSummary,
  DetectionAlertSummary,
  useGetDetectionTimelineSummary,
} from '@gamma/investigator/queries';
import { GraphQLReqStatus } from '@gamma/progress';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';

import { RelatedDetectionsAlerts } from './Components/RelatedDetectionsAlerts';
import { ApexDetectionsChart } from './Components/RelatedDetectionsChart';

interface RelatedDetectionsProps {
  detection: Detection;
  suricataRuleText: string;
  onPreviewOpen: () => void;
  selectedDetection?: Detection;
  setSelectedDetectionId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  getAppConfigurationsData: string | null;
}

export const RelatedDetections = ({
  detection,
  onPreviewOpen,
  suricataRuleText,
  selectedDetection,
  setSelectedDetectionId,
  getAppConfigurationsData,
}: RelatedDetectionsProps) => {
  const {
    tenant,
    detection_id,
    earliest_start_timestamp,
    latest_start_timestamp,
  } = detection || {};

  const start = earliest_start_timestamp
    ? moment(earliest_start_timestamp * 1000)
        .subtract(2, 'weeks')
        .unix()
    : undefined;
  const end = latest_start_timestamp
    ? moment(latest_start_timestamp * 1000).unix()
    : undefined;

  const maxRows = 5;

  const [interval, setInterval] = useState<number>(86400);

  const { loading, error, data } = useGetDetectionTimelineSummary({
    fetchPolicy: 'network-only',
    skip: !tenant || !detection_id || !start || !end || !interval,
    variables: {
      end,
      start,
      interval,
      detection_id,
      top_n: maxRows,
      detection_tenant: tenant,
      severity: ['low', 'medium', 'high'],
    },
  });

  const {
    getCurrentDetectionTimelineSummary,
    getDetectionDestinationTimelineSummary,
    getDetectionSourceDestinationTimelineSummary,
    getDetectionSourceTimelineSummary,
  } = data || {};

  const currentDetectionDetections = useMemo(
    () =>
      getCurrentDetectionTimelineSummary?.summary
        ?.map((summary: DetectionAlertGroupSummary) => summary.detections)
        .flat()
        .sort((a, b) =>
          a.latest_start_timestamp < b.latest_start_timestamp ? 1 : -1,
        ),
    [getCurrentDetectionTimelineSummary],
  );

  const detectionDestinationDetections = useMemo(
    () =>
      getDetectionDestinationTimelineSummary?.summary
        ?.map((summary: DetectionAlertGroupSummary) => summary.detections)
        .flat()
        .sort((a, b) =>
          a.latest_start_timestamp < b.latest_start_timestamp ? 1 : -1,
        ),
    [getDetectionDestinationTimelineSummary],
  );

  const detectionSourceDestinationDetections = useMemo(
    () =>
      getDetectionSourceDestinationTimelineSummary?.summary
        ?.map((summary: DetectionAlertGroupSummary) => summary.detections)
        .flat()
        .sort((a, b) =>
          a.latest_start_timestamp < b.latest_start_timestamp ? 1 : -1,
        ),
    [getDetectionSourceDestinationTimelineSummary],
  );

  const detectionSourceDetections = useMemo(
    () =>
      getDetectionSourceTimelineSummary?.summary
        ?.map((summary: DetectionAlertGroupSummary) => summary.detections)
        .flat()
        .sort((a, b) =>
          a.latest_start_timestamp < b.latest_start_timestamp ? 1 : -1,
        ),
    [getDetectionSourceTimelineSummary],
  );

  const [chartDetections, setChartDetections] = useState<any>({});

  useEffect(() => {
    setChartDetections({
      currentDetectionDetections,
      detectionSourceDetections,
      detectionDestinationDetections,
      detectionSourceDestinationDetections,
    });
  }, [
    currentDetectionDetections,
    detectionSourceDetections,
    detectionDestinationDetections,
    detectionSourceDestinationDetections,
  ]);

  const isCurrentDetectionOpen = useMemo(
    () =>
      !!chartDetections?.currentDetectionDetections?.find(
        (detection: DetectionAlertSummary) =>
          detection.detection_id === selectedDetection?.detection_id,
      ),
    [chartDetections, selectedDetection],
  );

  const isDetectionDestinationOpen = useMemo(
    () =>
      !!chartDetections?.detectionDestinationDetections?.find(
        (detection: DetectionAlertSummary) =>
          detection.detection_id === selectedDetection?.detection_id,
      ),
    [chartDetections, selectedDetection],
  );

  const isDetectionSourceOpen = useMemo(
    () =>
      !!chartDetections?.detectionSourceDetections?.find(
        (detection: DetectionAlertSummary) =>
          detection.detection_id === selectedDetection?.detection_id,
      ),
    [chartDetections, selectedDetection],
  );

  const isDetectionSourceDestinationOpen = useMemo(
    () =>
      !!chartDetections?.detectionSourceDestinationDetections?.find(
        (detection: DetectionAlertSummary) =>
          detection.detection_id === selectedDetection?.detection_id,
      ),
    [chartDetections, selectedDetection],
  );

  const [chartPanelsExpanded, setChartPanelsExpanded] = useState<any>({});

  useEffect(() => {
    setChartPanelsExpanded({
      isCurrentDetectionOpen,
      isDetectionDestinationOpen,
      isDetectionSourceOpen,
      isDetectionSourceDestinationOpen,
    });
  }, [
    isCurrentDetectionOpen,
    isDetectionDestinationOpen,
    isDetectionSourceOpen,
    isDetectionSourceDestinationOpen,
  ]);

  return loading || error ? (
    <GraphQLReqStatus error={error} loading={!!loading} />
  ) : (
    <Box>
      <ApexDetectionsChart
        end={end}
        start={start}
        maxRows={maxRows}
        chartsData={data}
        interval={interval}
        setInterval={setInterval}
        setSelectedDetectionId={setSelectedDetectionId}
      />
      <RelatedDetectionsAlerts
        detection={detection}
        onPreviewOpen={onPreviewOpen}
        chartDetections={chartDetections}
        suricataRuleText={suricataRuleText}
        selectedDetection={selectedDetection}
        chartPanelsExpanded={chartPanelsExpanded}
        setSelectedDetectionId={setSelectedDetectionId}
        getAppConfigurationsData={getAppConfigurationsData}
      />
    </Box>
  );
};
