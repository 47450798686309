import { createIcon } from '@chakra-ui/react';

export const HomeIcon = createIcon({
  displayName: 'HomeIcon',
  viewBox: '0 0 24 24',
  d: 'M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8h5Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
