import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { User } from './types';

export interface IListUsersData {
  listUsers: User[];
}

export const LIST_USERS = gql`
  query listUsers($tenants: [String!]!, $group: String, $groups: [String!]) {
    listUsers(tenants: $tenants, group: $group, groups: $groups) {
      username
      alias
      tenant
      email
      is_sso_user
      created_at
      enabled
      status
      group
      local_auth_enabled
    }
  }
`;

export const useListUsers = (
  options: QueryHookOptions<IListUsersData, OperationVariables>,
) => useQuery<IListUsersData>(LIST_USERS, options);
