import { Heading, HeadingProps } from '@chakra-ui/layout';

export type PanelHeadingProps = HeadingProps;

export const PanelHeading = ({ children, ...rest }: PanelHeadingProps) => {
  return (
    <Heading {...rest} as="h5" textStyle="h5">
      {children}
    </Heading>
  );
};
