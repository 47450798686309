import { createIcon } from '@chakra-ui/react';

export const RadarIcon = createIcon({
  displayName: 'RadarIcon',
  viewBox: '0 0 24 24',
  d: 'm19.07 4.93-1.41 1.41A8.014 8.014 0 0 1 20 12a8 8 0 0 1-16 0c0-4.08 3.05-7.44 7-7.93v2.02a5.998 5.998 0 1 0 5.243 10.153A6 6 0 0 0 18 12c0-1.66-.67-3.16-1.76-4.24l-1.41 1.41A4 4 0 1 1 8 12c0-1.86 1.28-3.41 3-3.86v2.14c-.6.35-1 .98-1 1.72a2 2 0 0 0 4 0c0-.74-.4-1.38-1-1.72V2h-1a10 10 0 1 0 7.07 2.93Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
