import { AsyncSelect as ChakraAsyncSelect } from 'chakra-react-select';
import DOMPurify from 'dompurify';
import { ForwardedRef, forwardRef, useRef } from 'react';
import { GroupBase, OptionsOrGroups, SelectInstance } from 'react-select';
import { LabelProps, SelectWrapper } from '../../Components';
import { OptionType, UseSelectParams, useSelect } from '../../hooks';

export interface AsyncSelectProps<
  IsMulti extends boolean,
  Option extends OptionType = OptionType,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends Omit<LabelProps, 'children' | 'width'>,
    Omit<UseSelectParams<IsMulti, Option>, 'options'> {
  /**
   * The default set of options to show before the user starts searching. When
   * set to `true`, the results for loadOptions('') will be autoloaded.
   */
  defaultOptions?: boolean | Option[];
  /**
   * If cacheOptions is truthy, then the loaded data will be cached. The cache
   * will remain until `cacheOptions` changes value.
   */
  cacheOptions?: boolean;
  /**
   * Function that returns a promise, which is the set of options to be used
   * once the promise resolves.
   */
  loadOptions: (
    inputValue: string,
  ) => Promise<OptionsOrGroups<Option, Group>> | OptionsOrGroups<Option, Group>;
  /**
   * Will cause the select to be displayed in the loading state, even if the
   * Async select is not currently waiting for loadOptions to resolve
   */
  isLoading?: boolean;
  /**
   * the amount of time to use as a timeout for loading values based on the user search input
   * @default 500
   */
  searchTimeout?: number;
}

const _AsyncSelect = <
  IsMulti extends boolean,
  Option extends OptionType = OptionType,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: AsyncSelectProps<IsMulti, Option, Group>,
  ref: ForwardedRef<SelectInstance<Option, IsMulti>>,
) => {
  const {
    selectProps: { options, ...selProps },
    wrapperProps,
  } = useSelect<IsMulti, Option, typeof props & { options: Option[] }>({
    ...props,
    options: [],
  });

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleLoadOptions = async (value: string) => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
    return new Promise<OptionsOrGroups<Option, Group>>((resolve) => {
      timeoutRef.current = setTimeout(async () => {
        resolve(await props.loadOptions(DOMPurify.sanitize(value)));
      }, props.searchTimeout ?? 500);
    });
  };

  return (
    <SelectWrapper {...wrapperProps}>
      <ChakraAsyncSelect
        {...selProps}
        loadOptions={handleLoadOptions}
        // @ts-ignore
        ref={ref}
      />
    </SelectWrapper>
  );
};

export const AsyncSelect = forwardRef(_AsyncSelect) as <
  IsMulti extends boolean,
  Option extends OptionType = OptionType,
>(
  props: AsyncSelectProps<IsMulti, Option> & {
    ref?:
      | ((instance: SelectInstance<Option, IsMulti>) => void)
      | React.RefObject<SelectInstance<Option, IsMulti>>
      | null
      | undefined;
  },
) => ReturnType<typeof _AsyncSelect>;
