import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

import { IDeleteEDRConfiguration } from './types';

export const DELETE_EDR_CONFIGURATION = gql`
  mutation deleteEDRConfiguration($provider_name: EDRConfigurationType!) {
    deleteEDRConfiguration(provider_name: $provider_name) {
      provider_name
      enabled
      polling_seconds
      ... on CrowdStrikeConfiguration {
        provider_name
        polling_seconds
        enabled
        client_id
        client_secret
        base_url
      }
    }
  }
`;

export const useDeleteEDRConfiguration = (
  options: MutationHookOptions<IDeleteEDRConfiguration, OperationVariables>,
) => useMutation(DELETE_EDR_CONFIGURATION, options);
