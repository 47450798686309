import { createIcon } from '@chakra-ui/react';

export const CalendarIconEnd = createIcon({
  displayName: 'CalendarIconEnd',
  viewBox: '0 0 24 24',
  d: 'M19 3c1.11 0 2 .89 2 2v14c0 1.11-.89 2-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h1V1h2v2h8V1h2v2h1Zm0 16V9H5v10h14Zm0-12V5H5v2h14Zm-4 4h2v6h-2v-6Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
