import {
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { ChevronDownIcon } from '@gamma/icons';
import { ROUTES } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import { RouterLink } from '@gamma/navigation';

const { buttons } = i18n.pages.entityDetails;
const { logscaleDetails } = i18n.pages.detections;
const { logSearch } = ROUTES;

interface LogscaleMenuButtonProps {
  searchQueryString: string;
  investigateModalOnOpen?: () => void;
  showContent?: boolean;
}

export const LogscaleMenuButton = ({
  searchQueryString,
  investigateModalOnOpen,
  showContent,
}: LogscaleMenuButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Popover
        isOpen={isOpen}
        offset={[0, 10]}
        onClose={onClose}
        placement="bottom-start"
      >
        <Menu>
          <PopoverTrigger>
            <span>
              <Tooltip
                label={buttons.investigateLogs}
                placement="bottom-end"
                isDisabled={showContent}
              >
                <MenuButton
                  as={Button}
                  variant="solid"
                  colorScheme="gray"
                  rightIcon={showContent ? <ChevronDownIcon /> : undefined}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  aria-label={buttons.investigateLogs}
                  data-testid="investigate-button"
                >
                  <HStack>
                    <MuiIcon>frame_inspect</MuiIcon>
                    {showContent && (
                      <Text fontSize="small">{buttons.investigateLogs}</Text>
                    )}
                  </HStack>
                </MenuButton>
              </Tooltip>
            </span>
          </PopoverTrigger>
          <Portal>
            <MenuList zIndex="popover">
              <VStack spacing={0} alignItems="start">
                {investigateModalOnOpen && (
                  <MenuItem
                    data-testid="view-related-logs"
                    onClick={(e) => {
                      e.stopPropagation();
                      investigateModalOnOpen();
                    }}
                  >
                    {logscaleDetails.viewLogs}
                  </MenuItem>
                )}
                <MenuItem
                  data-testid="view-in-log-search"
                  as={RouterLink}
                  to={`${logSearch}${searchQueryString}`}
                  target="_blank"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Text>{logscaleDetails.viewLogSearch}</Text>
                  <IconButton
                    size="box-sm"
                    icon={<MuiIcon>open_in_new</MuiIcon>}
                    aria-label={logscaleDetails.viewLogSearch}
                  />
                </MenuItem>
              </VStack>
            </MenuList>
          </Portal>
        </Menu>
      </Popover>
    </>
  );
};
