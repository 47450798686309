import { ComponentStyleConfig } from '@chakra-ui/react';
import { textStyles } from '../../common';

export const FormControlStyles: ComponentStyleConfig = {
  baseStyle: (props: any) => ({
    container: {
      flexWrap: 'wrap',
      alignItem: 'center',
    },
    helperText: {
      color: 'text.primary',
      ...textStyles['body-sm'],
      mt: 0,
    },
  }),
};
