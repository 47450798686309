import { createIcon } from '@chakra-ui/react';

export const Complete = createIcon({
  displayName: 'Complete',
  viewBox: '0 0 200 160',
  path: (
    <>
      <path
        d="M163.748 24.108a1.033 1.033 0 0 1-.574-1.245.495.495 0 0 0-.06-.428.52.52 0 0 0-.578-.213.515.515 0 0 0-.324.286 1.032 1.032 0 0 1-1.245.574.495.495 0 0 0-.428.06.514.514 0 0 0-.213.578.513.513 0 0 0 .286.324 1.03 1.03 0 0 1 .574 1.245.512.512 0 0 0 .314.63.518.518 0 0 0 .591-.174.497.497 0 0 0 .058-.101 1.027 1.027 0 0 1 1.244-.574.5.5 0 0 0 .428-.06.513.513 0 0 0 .213-.578.517.517 0 0 0-.184-.267.516.516 0 0 0-.102-.057ZM56.115 117.522a1.047 1.047 0 0 1-.574-1.256.498.498 0 0 0-.06-.432.523.523 0 0 0-.254-.204.521.521 0 0 0-.324-.011.521.521 0 0 0-.267.186.514.514 0 0 0-.057.103 1.03 1.03 0 0 1-1.245.579.496.496 0 0 0-.114-.023.513.513 0 0 0-.516.34.525.525 0 0 0 .173.596.497.497 0 0 0 .102.058 1.046 1.046 0 0 1 .574 1.256.498.498 0 0 0 .06.431.519.519 0 0 0 .578.216.517.517 0 0 0 .324-.289 1.028 1.028 0 0 1 1.245-.579c.037.012.075.02.114.023a.52.52 0 0 0 .241-.994Z"
        fill="#0093EE"
      />
      <path
        d="M138.125 50.034A1.883 1.883 0 0 0 140 48.142a1.883 1.883 0 0 0-1.875-1.892 1.883 1.883 0 0 0-1.875 1.892c0 1.045.839 1.892 1.875 1.892ZM80.625 90.034a1.883 1.883 0 0 0 1.875-1.892c0-1.045-.84-1.892-1.875-1.892a1.883 1.883 0 0 0-1.875 1.892c0 1.045.84 1.892 1.875 1.892ZM169.375 111.284a1.883 1.883 0 0 0 1.875-1.892 1.883 1.883 0 0 0-1.875-1.892 1.883 1.883 0 0 0-1.875 1.892c0 1.045.839 1.892 1.875 1.892ZM20.959 58.138a1.883 1.883 0 0 0 1.875-1.892c0-1.045-.84-1.892-1.875-1.892a1.883 1.883 0 0 0-1.875 1.892c0 1.045.84 1.892 1.875 1.892Z"
        fill="#F75F72"
      />
      <path
        d="M118.71 111.397a1.032 1.032 0 0 1-.524-.517 1.05 1.05 0 0 1-.05-.738.507.507 0 0 0 .023-.116.52.52 0 0 0-.661-.531.514.514 0 0 0-.324.288 1.038 1.038 0 0 1-.513.529c-.23.107-.49.125-.732.05a.49.49 0 0 0-.428.062.514.514 0 0 0-.202.255.521.521 0 0 0 .275.655 1.045 1.045 0 0 1 .574 1.255.507.507 0 0 0-.023.116.52.52 0 0 0 .661.531.514.514 0 0 0 .324-.288c.101-.234.284-.422.513-.529.23-.107.49-.125.732-.05a.49.49 0 0 0 .428-.062.522.522 0 0 0-.073-.91Z"
        fill="#0093EE"
      />
      <path
        d="M45.625 73.784a1.883 1.883 0 0 0 1.875-1.892c0-1.045-.84-1.892-1.875-1.892a1.883 1.883 0 0 0-1.875 1.892c0 1.045.84 1.892 1.875 1.892ZM176.875 53.784a1.883 1.883 0 0 0 1.875-1.892A1.883 1.883 0 0 0 176.875 50 1.883 1.883 0 0 0 175 51.892c0 1.045.839 1.892 1.875 1.892ZM122.206 23.75h-.662v3.784h.662V23.75Z"
        fill="#00FF8F"
      />
      <path
        d="M123.75 25.976v-.668H120v.668h3.75ZM87.702 129.378h-.662v3.784h.662v-3.784Z"
        fill="#00FF8F"
      />
      <path
        d="M89.246 131.604v-.668h-3.75v.668h3.75ZM158.529 82.523h-.662v3.784h.662v-3.784Z"
        fill="#00FF8F"
      />
      <path
        d="M160.073 84.749v-.668h-3.75v.668h3.75ZM141.69 123.047l1.125 1.04 1.594-2.365.562.473-2.156 2.838-1.594-1.797.469-.189ZM50.85 21.994l1.126 1.04 1.593-2.364.563.473-2.156 2.838-1.594-1.798.469-.189Z"
        fill="#00FF8F"
      />
      <path
        d="M93.125 12.534A1.883 1.883 0 0 0 95 10.642c0-1.045-.84-1.892-1.875-1.892a1.883 1.883 0 0 0-1.875 1.892c0 1.045.84 1.892 1.875 1.892Z"
        fill="#F75F72"
      />
      <path
        d="M23.29 97.618a1.034 1.034 0 0 1-.524-.518 1.048 1.048 0 0 1-.05-.738.507.507 0 0 0-.06-.432.517.517 0 0 0-.578-.215.517.517 0 0 0-.324.288c-.101.234-.284.422-.513.53-.23.106-.49.124-.732.05a.485.485 0 0 0-.428.06.52.52 0 0 0-.213.584.496.496 0 0 0 .286.327c.232.102.418.286.524.517.106.232.124.495.05.739a.508.508 0 0 0 .06.431.517.517 0 0 0 .578.215.516.516 0 0 0 .325-.288 1.022 1.022 0 0 1 1.245-.579.494.494 0 0 0 .427-.061.52.52 0 0 0 .213-.584.493.493 0 0 0-.286-.327Z"
        fill="#0093EE"
      />
      <path
        d="M156.332 45.933H43.668a3.984 3.984 0 0 0-2.808 1.163 3.964 3.964 0 0 0-1.165 2.8v43.365a3.964 3.964 0 0 0 1.165 2.801 3.984 3.984 0 0 0 2.808 1.163h112.664a3.985 3.985 0 0 0 2.808-1.163 3.966 3.966 0 0 0 1.166-2.8V49.895a3.966 3.966 0 0 0-1.166-2.8 3.985 3.985 0 0 0-2.808-1.163Z"
        fill="#283139"
      />
      <path
        d="M156.332 45.933H43.668a3.984 3.984 0 0 0-2.808 1.163 3.964 3.964 0 0 0-1.165 2.8v43.365a3.964 3.964 0 0 0 1.165 2.801 3.984 3.984 0 0 0 2.808 1.163h112.664a3.985 3.985 0 0 0 2.808-1.163 3.966 3.966 0 0 0 1.166-2.8V49.895a3.966 3.966 0 0 0-1.166-2.8 3.985 3.985 0 0 0-2.808-1.163Zm3.506 47.328a3.497 3.497 0 0 1-1.028 2.472 3.516 3.516 0 0 1-2.478 1.026H43.668a3.515 3.515 0 0 1-2.477-1.026 3.497 3.497 0 0 1-1.029-2.472V49.896a3.497 3.497 0 0 1 1.029-2.471 3.515 3.515 0 0 1 2.477-1.026h112.664c.929.001 1.821.37 2.478 1.026a3.497 3.497 0 0 1 1.028 2.471v43.365Z"
        fill="#6C7984"
      />
      <path
        d="M64.124 85.101c-2.681 0-5.302-.793-7.532-2.279a13.53 13.53 0 0 1-4.993-6.068 13.49 13.49 0 0 1 2.939-14.737 13.589 13.589 0 0 1 14.774-2.931 13.549 13.549 0 0 1 6.084 4.98 13.498 13.498 0 0 1 2.285 7.513 13.52 13.52 0 0 1-3.975 9.557 13.59 13.59 0 0 1-9.582 3.965Zm0-26.578c-2.589 0-5.12.765-7.272 2.2a13.063 13.063 0 0 0-4.821 5.86 13.025 13.025 0 0 0 2.837 14.228 13.121 13.121 0 0 0 14.265 2.83 13.082 13.082 0 0 0 5.874-4.809 13.032 13.032 0 0 0 2.206-7.253 13.054 13.054 0 0 0-3.838-9.228 13.121 13.121 0 0 0-9.251-3.828Z"
        fill="#6C7984"
      />
      <path
        d="M146.278 62.953H92.284c-.755 0-1.48.292-2.024.814a2.91 2.91 0 0 0-.893 1.986.928.928 0 0 0-.005.114c.001.772.31 1.513.857 2.06a2.93 2.93 0 0 0 2.065.854h53.994c.775 0 1.518-.307 2.066-.853a2.91 2.91 0 0 0 0-4.122 2.925 2.925 0 0 0-2.066-.853ZM146.278 74.377H92.284c-.755 0-1.48.292-2.024.814a2.91 2.91 0 0 0-.893 1.986.928.928 0 0 0-.005.114c.001.773.31 1.513.857 2.06a2.93 2.93 0 0 0 2.065.854h53.994c.775 0 1.518-.307 2.066-.853a2.91 2.91 0 0 0 0-4.122 2.926 2.926 0 0 0-2.066-.853ZM62.612 77.487c-.379 0-.747-.121-1.05-.347l-.018-.014-3.951-3.018a1.752 1.752 0 0 1-.629-1.842 1.749 1.749 0 0 1 1.466-1.284 1.762 1.762 0 0 1 1.298.346l2.56 1.958 6.047-7.868a1.752 1.752 0 0 1 1.847-.626c.222.06.431.162.614.302l-.037.051.038-.05a1.752 1.752 0 0 1 .325 2.455l-7.114 9.254a1.755 1.755 0 0 1-1.396.683Z"
        fill="#07D279"
      />
    </>
  ),
  defaultProps: {
    height: '160px',
    width: '200px',
  },
});
