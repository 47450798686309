import { VStack } from '@chakra-ui/react';
import { PageHeader } from '@gamma/investigator/components';
import { ROUTES } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { Board, Column } from '@gamma/layout';
import { Alert } from '@gamma/overlay';
import { NavTab, NavTabs } from '@gamma/tabs';
import { useContext } from 'react';
import { Outlet } from 'react-router-dom';

const { system } = i18n.navigation;
const { analystContactAdmin } = i18n.pages.integrations;

export const Integrations = () => {
  const tabs: NavTab[] = [
    {
      title: system.integrations,
      to: ROUTES.systemSettingsIntegrationsConfig,
      isDisabled: false,
    },
    {
      title: system.alertExports,
      to: ROUTES.systemSettingsIntegrationsAlertExports,
      isDisabled: false,
    },
  ];

  const { userRole } = useContext(AuthContext);

  return (
    <Board>
      <Column>
        <PageHeader showDateSelection={false}>{system.integrations}</PageHeader>
        <VStack>
          {userRole !== 'admin' && (
            <Alert status="warning">{analystContactAdmin}</Alert>
          )}
          <NavTabs tabs={tabs} isFitted={false} variant="line" basePath="/" />
        </VStack>
      </Column>
      <Column
        flexGrow={1}
        display="flex"
        flexBasis="auto"
        flexDirection="column"
      >
        <Outlet />
      </Column>
    </Board>
  );
};
