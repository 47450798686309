import { createIcon } from '@chakra-ui/react';

export const ArrowForwardIcon = createIcon({
  displayName: 'ArrowForwardIcon',
  viewBox: '0 0 24 24',
  d: 'M4 13v-2h12l-5.5-5.5 1.42-1.42L19.84 12l-7.92 7.92-1.42-1.42L16 13H4Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
