import {
  Divider,
  Flex,
  HStack,
  IconButton,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { Navbar, Sidebar } from '@gamma/navigation';
import { gammaContext } from '@gamma/theme';
import moment from 'moment';
import {
  ReactNode,
  RefObject,
  createContext,
  createRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

export interface UIShellProps {
  /** The component to render as the AppHeader logo */
  logo?: ReactNode;
  /** The Navbar items to use in the AppHeader navigation */
  nav?: ReactNode;
  /** The Sidebar items to use in the Sidebar navigation. If not set, no sidebar will render */
  sidebar?: ReactNode;
  /** ReactNode that sits above the collapse button of the sidebar */
  sidebarFooter?: ReactNode;
  /** App Footer */
  footer?: ReactNode;
  /** The copyright text to put in the app footer */
  copyrightText?: ReactNode;
  /** The flag for controlling sidebar pinning behavior */
  autoCloseSidebar?: boolean;
  /** The app content */
  children: ReactNode;
}

const storageKey = 'gamma-sidebar-expanded';

const getSidebarExpandedState = () => {
  const storageValue = localStorage.getItem(storageKey);
  const noSavedValue = storageValue === null;
  if (noSavedValue) {
    localStorage.setItem(storageKey, 'true');
  }
  return noSavedValue || storageValue === 'true';
};

export const ScrollContext = createContext<{
  scrollRef: RefObject<HTMLDivElement>;
}>({ scrollRef: createRef<HTMLDivElement>() });

export const UIShell = ({
  logo,
  nav,
  sidebar,
  children,
  sidebarFooter,
  footer,
  copyrightText = <DefaultCopy />,
  autoCloseSidebar = true,
}: UIShellProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLButtonElement>(null);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(
    autoCloseSidebar ? false : getSidebarExpandedState(),
  );

  useEffect(() => {
    if (!autoCloseSidebar) {
      const storedExpandedState = getSidebarExpandedState();
      setSidebarOpen(Boolean(storedExpandedState));
    }
  }, [autoCloseSidebar]);

  const handleSidebarToggle = useCallback(() => {
    setSidebarOpen(!sidebarOpen);
    localStorage.setItem(storageKey, `${!sidebarOpen}`);
  }, [sidebarOpen]);

  useOutsideClick({
    enabled: autoCloseSidebar,
    ref: sidebarRef,
    handler: (event) => {
      if (
        (event.target as HTMLElement) !== toggleRef.current &&
        !toggleRef.current?.contains(event.target as HTMLElement)
      ) {
        setSidebarOpen(false);
      }
    },
  });

  return (
    <Flex direction="column" wrap="nowrap" h="100%">
      <Flex
        alignItems="center"
        px="2"
        py="2"
        bg="layer.1"
        borderBottom="solid 1px"
        borderColor="border.layer.1"
        w="100%"
        h="12"
        minH="12"
        data-testid="gamma-appheader"
        position="sticky"
        top="0"
        zIndex={2}
        ref={headerRef}
        boxShadow="sm"
      >
        <HStack spacing={2} h={5}>
          <IconButton
            variant="ghost"
            colorScheme="gray"
            size="box-md"
            aria-label={`${sidebarOpen ? 'Close' : 'Open'} Menu`}
            icon={<MuiIcon>{sidebarOpen ? 'close' : 'menu'}</MuiIcon>}
            onClick={handleSidebarToggle}
            ref={toggleRef}
          />
          {logo}
        </HStack>
        <Navbar>{nav}</Navbar>
      </Flex>
      <Flex
        flex="1 1 auto"
        height={`calc(100vh - ${headerRef.current?.offsetHeight})`}
        overflow="hidden"
        zIndex={1}
      >
        {sidebar && (
          <Sidebar
            footer={sidebarFooter}
            contentRef={scrollRef}
            isOpen={sidebarOpen}
            autoCloseSidebar={autoCloseSidebar}
            setIsOpen={setSidebarOpen}
            ref={sidebarRef}
          >
            {sidebar}
          </Sidebar>
        )}
        <ScrollContext.Provider value={{ scrollRef }}>
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            h="100%"
            flex="1 1 auto"
            overflowX="hidden"
            overflowY="auto"
            ref={scrollRef}
            tabIndex={0}
            _focus={{ outline: 'none' }}
            zIndex={1}
            p={6}
          >
            {children}
            {footer && (
              <Flex
                mt={5}
                alignItems="center"
                w="100%"
                h="max-content"
                data-testid="gamma-appfooter"
                ref={footerRef}
                as="footer"
              >
                <Text textStyle="body-md">{copyrightText}</Text>
                <Divider
                  borderColor={'gray.500'}
                  mx="4"
                  h={4}
                  orientation="vertical"
                />
                <Navbar style={{ justifyContent: 'flex-start' }}>
                  {footer}
                </Navbar>
              </Flex>
            )}
          </Flex>
        </ScrollContext.Provider>
      </Flex>
    </Flex>
  );
};

const DefaultCopy = () => {
  const currentYear = moment().format('YYYY');
  const { i18n } = useContext(gammaContext);
  return <>&copy; {i18n.formatString(i18n.texts.copyright, currentYear)}</>;
};
