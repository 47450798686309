import { createIcon } from '@chakra-ui/react';

export const CircleExclamationIcon = createIcon({
  displayName: 'CircleExclamationIcon',
  viewBox: '0 0 24 24',
  d: 'M13 13h-2V7h2v6Zm0 4h-2v-2h2v2ZM12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
