import {
  Box,
  Button,
  Center,
  chakra,
  HStack,
  IconButton,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { Input } from '@gamma/form-fields';
import { ROUTES } from '@gamma/investigator/constants';
import { LogScaleContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { useGetHumioDashboards } from '@gamma/investigator/queries';
import { PopUpMenuLinksProps } from '@gamma/navigation';
import { GraphQLReqStatus } from '@gamma/progress';
import { useContext, useEffect, useState } from 'react';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';

const {
  moreDashboard,
  dashboardsMenu,
  dashboards,
  findDashboard,
  noResultsFound,
} = i18n.navigation;
const { dashboards: dashboardsRoute } = ROUTES;
const StyledLink = chakra(Link);

export const MoreDashboards = () => {
  const {
    iframeKey,
    setIframeKey,
    sessionIdTokenCorelight,
    setLogScaleDashboardsLoading,
    setLogScaleDashboardsError,
    setLogScaleDashboardsData,
    setLogScaleDashboardParams,
  } = useContext(LogScaleContext);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { loading, error, data } = useGetHumioDashboards({
    skip: !sessionIdTokenCorelight,
    variables: {
      humioSessionToken: sessionIdTokenCorelight || '',
    },
  });
  const [dashboardMenuLinks, setDashboardMenuLinks] = useState<
    PopUpMenuLinksProps[] | undefined
  >(undefined);

  const {
    isOpen: isPopUpMenuOpen,
    onOpen: onOpenPopUpMenu,
    onClose: onClosePopUpMenu,
  } = useDisclosure();

  useEffect(() => {
    if (data?.getHumioDashboards) {
      setDashboardMenuLinks([
        { to: dashboardsRoute(), label: dashboardsMenu, isHidden: true },
        ...[...data?.getHumioDashboards?.searchDomain?.dashboards]
          ?.sort((a, b) => (a.name < b.name ? -1 : 1))
          ?.map((link) => {
            return {
              to: dashboardsRoute(
                `/${encodeURIComponent(link.name)}/${link.id}${
                  link?.name.split(' ')[0] === 'Data' ||
                  link?.name.split(' ')[0] === 'Security'
                    ? '/corelight/investigator-humio-app/'
                    : ''
                }`,
              ),
              label: link.name,
            };
          }),
      ]);
    }
  }, [data]);

  useEffect(() => {
    setLogScaleDashboardsLoading && setLogScaleDashboardsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (error) {
      setLogScaleDashboardsError && setLogScaleDashboardsError(error);
    }
  }, [error]);

  useEffect(() => {
    if (data?.getHumioDashboards) {
      setLogScaleDashboardsData && setLogScaleDashboardsData(data);
    }
  }, [data]);
  const [searchText, setSearchText] = useState<string>('');

  const [filteredDashboardMenuLinks, setFilteredDashboardMenuLinks] = useState<
    PopUpMenuLinksProps[] | undefined
  >(undefined);

  const [selectedDashboardLink, setSelectedDashboardLink] = useState<
    string | undefined
  >();

  const clearSearch = () => {
    setSearchText('');
    setFilteredDashboardMenuLinks(dashboardMenuLinks);
  };

  useEffect(() => {
    setFilteredDashboardMenuLinks(dashboardMenuLinks);
  }, [dashboardMenuLinks]);

  useEffect(() => {
    setFilteredDashboardMenuLinks(
      dashboardMenuLinks?.filter((dashboardLink) =>
        dashboardLink.label.toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
  }, [searchText]);

  const localDashboardLink = localStorage.getItem('dashboard_link');

  useEffect(() => {
    if (selectedDashboardLink || localDashboardLink) {
      const dashboardLink = selectedDashboardLink
        ? selectedDashboardLink
        : localDashboardLink;
      const params = dashboardLink
        ?.split('/')
        ?.filter(
          (segment) =>
            segment !== '' &&
            segment !== 'overview' &&
            segment !== 'more-dashboards',
        );
      setLogScaleDashboardParams &&
        setLogScaleDashboardParams({
          name: params?.[0],
          dashboardId: params?.[1],
          investigatorApp: params?.[2],
          corelightDashboard: params?.[3],
        });
      navigate(dashboardsRoute());
    }
  }, [iframeKey, selectedDashboardLink, localDashboardLink]);

  const handleLinkOnClick = (link: string) => {
    setSelectedDashboardLink(link);
    setIframeKey && iframeKey !== undefined && setIframeKey(iframeKey + 1);
  };

  return (
    <Popover
      trigger="hover"
      placement="bottom-start"
      onOpen={onOpenPopUpMenu}
      closeOnBlur={false}
      data-testid="more-dashboards-menu"
    >
      <PopoverTrigger>
        <Text>{moreDashboard}</Text>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <Menu isOpen={isPopUpMenuOpen}>
            <VStack
              w="300px"
              h="500px"
              spacing={0}
              overflowY="scroll"
              alignItems="stretch"
              _last={{ pb: 1 }}
              _first={{ pt: 0 }}
            >
              <HStack
                p={2}
                alignItems="start"
                borderBottomWidth="1px"
                borderBottomColor="border.1"
                backgroundColor="layer.2"
              >
                <Box>
                  <Input
                    size="sm"
                    name="search"
                    value={searchText}
                    isLabelHidden={true}
                    label={findDashboard}
                    placeholder={findDashboard}
                    leftElement={<MuiIcon>search</MuiIcon>}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    rightElement={
                      <IconButton
                        data-testid="search-term-clear"
                        aria-label="close"
                        onClick={() => {
                          clearSearch();
                        }}
                        variant="link"
                        colorScheme="gray"
                      >
                        <MuiIcon>close</MuiIcon>
                      </IconButton>
                    }
                  />
                </Box>
                <Box>
                  <Button
                    px={2}
                    size="sm"
                    w="112px"
                    variant="solid"
                    rightIcon={<MuiIcon>arrow_forward</MuiIcon>}
                    onClick={() => {
                      setSelectedDashboardLink(dashboardsRoute());
                      setIframeKey &&
                        iframeKey !== undefined &&
                        setIframeKey(iframeKey + 1);
                    }}
                  >
                    {dashboards}
                  </Button>
                </Box>
              </HStack>
              <PopoverBody>
                {loading ? (
                  <Center w="100%">
                    <GraphQLReqStatus loading={loading} error={error} />
                  </Center>
                ) : filteredDashboardMenuLinks &&
                  filteredDashboardMenuLinks.length > 0 ? (
                  filteredDashboardMenuLinks.map(
                    (
                      {
                        to,
                        label,
                        rootPath,
                        menuItemProps,
                        isHidden = false,
                        hasDivider = false,
                      },
                      index,
                    ) => {
                      const currentPathIncludesRootPath =
                        rootPath && pathname?.includes(rootPath);

                      const currentPathMatchesToPath = matchPath(to, pathname);

                      const isPathActive =
                        currentPathMatchesToPath || currentPathIncludesRootPath;

                      return (
                        <StyledLink
                          borderRadius={5}
                          onClick={(e) => {
                            e.preventDefault();
                            handleLinkOnClick(e.currentTarget.pathname);
                          }}
                          onContextMenu={(e) => {
                            localStorage.setItem(
                              'dashboard_link',
                              e.currentTarget.pathname,
                            );
                          }}
                          key={index}
                          to={to}
                        >
                          {hasDivider && <MenuDivider />}
                          <Box>
                            <MenuItem
                              color={
                                isPathActive ? 'state.selected' : 'inherit'
                              }
                              {...menuItemProps}
                              bgColor={'transparent'}
                              display={isHidden ? 'none' : 'flex'}
                            >
                              <Text>{label}</Text>
                            </MenuItem>
                          </Box>
                        </StyledLink>
                      );
                    },
                  )
                ) : (
                  <Box p={4}>
                    <Text textStyle="h6" color="gray.500">
                      {noResultsFound}
                    </Text>
                  </Box>
                )}
              </PopoverBody>
            </VStack>
          </Menu>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
