import { DateSelectOption } from '@gamma/form-fields';
import { createContext, Dispatch, SetStateAction } from 'react';

export interface IDateRangeContext {
  dateRange: DateSelectOption | undefined;
  setDateRange?: (dateRange?: DateSelectOption) => void;
  showCustomDateRangePicker?: boolean;
  setShowCustomDateRangePicker?: Dispatch<SetStateAction<boolean>>;
  defaultRangeIndex?: number;
  setDefaultRangeIndex?: Dispatch<SetStateAction<number>>;
}

export const DateRangeContext = createContext<IDateRangeContext>({
  dateRange: undefined,
});
