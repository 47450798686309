import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { FeatureMetadata } from './types';

export interface IGetFeatureMetadata {
  getFeatureMetadata: FeatureMetadata[];
}

export const GET_FEATURE_METADATA = gql`
  query getFeatureMetadata($input: FeatureMetaDataGetInput!) {
    getFeatureMetadata(input: $input) {
      category
      data_type
      definition
      description
      display_name
      name
      pipeline
    }
  }
`;

export const useGetFeatureMetadata = (
  options: QueryHookOptions<IGetFeatureMetadata, OperationVariables>,
) => useQuery<IGetFeatureMetadata>(GET_FEATURE_METADATA, options);
