import { Badge, Box, HStack } from '@chakra-ui/react';
import { SeverityScore, StatusStrings } from '@gamma/investigator/components';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { IGetDetectionVariables } from '@gamma/investigator/pages/detections';
import {
  Detection,
  DetectionRank,
  GET_DETECTIONS,
  IGetAlertMetadata,
  QUERY_DETECTIONS_PAGINATED,
  useQueryAlertMetadataExcludedEntitiesPaginated,
} from '@gamma/investigator/queries';
import { PageHeader, PageHeaderProps } from '@gamma/layout';
import moment from 'moment';
import { useContext, useMemo } from 'react';

import { DetectionQueryVariables } from '../../Detections';
import { treatEscalationName } from '../../Utils';
import { ActionsMenuButton } from '../DetectionActions';

export interface IDetectionDetailsHeaderProps
  extends Pick<PageHeaderProps, 'breadcrumbs' | 'prevPage'> {
  getDetectionVariables?: IGetDetectionVariables;
  detectionData?: Detection;
  relatedEntitiesVariables?: DetectionQueryVariables;
  relatedDetectionsVariables?: DetectionQueryVariables;
  queryDetectionsPaginatedVariables?: DetectionQueryVariables;
  isCustomSeverityScore?: boolean;
  setIsExcluded?: React.Dispatch<React.SetStateAction<boolean>>;
  metadataLoading?: boolean;
  metadataData?: IGetAlertMetadata;
}

const { suppressedMsg } = i18n.pages.entityDetails.excludeEntity;

const { detection: detectionText } = i18n.pages.detections;

export const DetectionDetailsHeader = ({
  detectionData,
  setIsExcluded,
  getDetectionVariables,
  relatedEntitiesVariables,
  relatedDetectionsVariables,
  queryDetectionsPaginatedVariables,
  isCustomSeverityScore,
  metadataLoading,
  metadataData,
  ...rest
}: IDetectionDetailsHeaderProps) => {
  const { userRole, isOrgTenant } = useContext(AuthContext);

  const excludedEntitiesQueryVariables = {
    query:
      detectionData?.alert_entity?.entity_type === 'DOMAIN'
        ? `{"query": {"bool": {"must": [{"match": {"content_id": "${
            detectionData?.alert_info?.content_id
          }"}}, {"term": {"entity_name.keyword": "${
            detectionData?.alert_entity?.entity_name
          }"}}, {"term": {"entity_type": "${
            detectionData?.alert_entity?.entity_type
          }"}}${detectionData?.tenant_info?.tenant_id ? ',' : ''}${
            detectionData?.tenant_info?.tenant_id
              ? `{"terms":{"tenant":["${detectionData?.tenant_info?.tenant_id}"]}}`
              : ''
          }]}}}`
        : `{"query": {"bool": {"must": [{"match": {"content_id": "${
            detectionData?.alert_info?.content_id
          }"}}, {"term": {"entity_type": "${
            detectionData?.alert_entity?.entity_type
          }"}}, {"bool": {"should": [{"term": {"entity_ip": "${
            detectionData?.alert_entity?.entity_name
          }"}}, {"range": {"entity_ip_range": {"from": "${
            detectionData?.alert_entity?.entity_name
          }", "to": "${detectionData?.alert_entity?.entity_name}"}}}]}}${
            detectionData?.tenant_info?.tenant_id ? ',' : ''
          }${
            detectionData?.tenant_info?.tenant_id
              ? `{"terms":{"tenant":["${detectionData?.tenant_info?.tenant_id}"]}}`
              : ''
          }]}}}`,
    offset: 0,
    size: 10,
    sort: [
      {
        sort_by: 'entity_name.keyword',
        sort_dir: 'desc',
      },
    ],
  };

  const { rank } = useMemo(() => {
    const excludedEntityInfo = {
      rank: null as DetectionRank | null,
      latest_start_timestamp: null as number | null,
    };

    if (detectionData?.rank && detectionData?.latest_start_timestamp) {
      excludedEntityInfo.rank = detectionData?.rank;
      excludedEntityInfo.latest_start_timestamp =
        detectionData?.latest_start_timestamp;
    }

    return excludedEntityInfo;
  }, [detectionData?.rank, detectionData?.latest_start_timestamp]);

  const {
    loading: excludedEntitiesLoading,
    data: excludedEntitiesData,
    error: excludedEntitiesError,
  } = useQueryAlertMetadataExcludedEntitiesPaginated({
    skip:
      !detectionData?.tenant_info?.tenant_id ||
      !detectionData?.alert_info?.content_id ||
      !detectionData?.alert_entity?.entity_name ||
      !detectionData?.alert_entity?.entity_type,
    variables: excludedEntitiesQueryVariables,
  });

  const excludedEntities =
    excludedEntitiesData?.queryAlertMetadataExcludedEntitiesPaginated
      ?.excluded_entities;

  if (setIsExcluded && excludedEntities) {
    setIsExcluded(excludedEntities && excludedEntities?.length > 0);
  }

  const refetchQueries = [
    ...(getDetectionVariables
      ? [
          {
            query: GET_DETECTIONS,
            variables: getDetectionVariables,
          },
        ]
      : []),
    ...(relatedEntitiesVariables
      ? [
          {
            query: QUERY_DETECTIONS_PAGINATED,
            variables: relatedEntitiesVariables,
          },
        ]
      : []),
    ...(relatedDetectionsVariables
      ? [
          {
            query: QUERY_DETECTIONS_PAGINATED,
            variables: relatedDetectionsVariables,
          },
        ]
      : []),
    ...(queryDetectionsPaginatedVariables
      ? [
          {
            query: QUERY_DETECTIONS_PAGINATED,
            variables: queryDetectionsPaginatedVariables,
          },
        ]
      : []),
  ];

  const excludedEntitiesMessage =
    excludedEntities && excludedEntities?.length > 0
      ? `${i18n.formatString(
          suppressedMsg,
          detectionData?.alert_entity?.entity_name ?? '',
          excludedEntities[0]?.created_by_alias,
          moment(excludedEntities[0]?.created_timestamp * 1000).format('lll'),
        )}`
      : null;

  const treatedEscalationName =
    treatEscalationName(
      detectionData?.escalation_data?.escalation_service_name,
    ) || '';

  const escalatedMessage = detectionData?.escalation_data?.escalation_status
    ? `${i18n.formatString(
        detectionText.sentToMsg,
        treatedEscalationName,
        detectionData?.escalation_data?.escalated_by_user_alias,
        moment(
          detectionData?.escalation_data?.escalated_timestamp * 1000,
        ).format('lll'),
      )}`
    : null;

  const statusStrings = [];
  excludedEntitiesMessage !== null &&
    statusStrings.push(excludedEntitiesMessage);
  escalatedMessage !== null && statusStrings.push(escalatedMessage);

  return (
    <PageHeader {...rest} size="lg" variant="stacked-flushed">
      <HStack
        mt={-2.5}
        justifyContent="space-between"
        data-testid="detection-details-header"
      >
        <HStack spacing={3} alignItems="center">
          <SeverityScore
            isCustom={isCustomSeverityScore}
            score={rank?.severity as number}
          />
          {detectionData?.detection_id &&
            detectionData?.alert_info?.alert_name && (
              <Box
                __css={{
                  wordWrap: 'anywhere',
                }}
                data-testid="detection-alert-name"
              >
                {`${detectionData?.alert_info?.alert_name} | ${detectionData?.alert_entity?.entity_name}`}
              </Box>
            )}
          {isOrgTenant && (
            <Badge variant="outline">
              {detectionData?.tenant_info?.tenant_display_name}
            </Badge>
          )}
        </HStack>
        <ActionsMenuButton
          detectionData={detectionData}
          refetchQueries={refetchQueries}
          excludedEntitiesData={excludedEntitiesData}
          excludedEntitiesError={excludedEntitiesError}
          excludedEntitiesLoading={excludedEntitiesLoading}
          getDetectionVariables={getDetectionVariables}
          userRole={userRole}
          queryDetectionsPaginatedVariables={queryDetectionsPaginatedVariables}
        />
      </HStack>
      {statusStrings.length > 0 && (
        <Box paddingTop="2">
          <StatusStrings statusStrings={statusStrings} />
        </Box>
      )}
    </PageHeader>
  );
};
