import { createIcon } from '@chakra-ui/react';

export const ExportIcon = createIcon({
  displayName: 'ExportIcon',
  viewBox: '0 0 24 24',
  d: 'M9 12h9.8l-2.5-2.5 1.4-1.4 4.9 4.9-4.9 4.9-1.4-1.4 2.5-2.5H9v-2Zm12 5.4V20H3V6h18v2.6l2 2V4c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2v-4.6l-2 2Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
