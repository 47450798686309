import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Center,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FieldValidationList } from '@gamma/form-fields';
import { IPasswordRules } from '@gamma/forms';
import { Illustrations } from '@gamma/icons';
import { graphqlErrorRedirects } from '@gamma/investigator/constants';
import { useLogOperationCall } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { IPasswordChangeForm, useSendEmail } from '@gamma/investigator/queries';
import { Panel } from '@gamma/layout';
import { Modal } from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';
import { Auth } from 'aws-amplify';
import { useState } from 'react';

import { PasswordChangeForm } from './Components';

export interface PasswordResetFormProps {
  isUserMfaEnabled?: boolean;
  email: string;
}

export const ChangePasswordOutlet = ({
  isUserMfaEnabled = false,
  email,
}: PasswordResetFormProps) => {
  const [isPasswordFilled, setIsPasswordFilled] = useState<boolean>(false);
  const [pwdUpdateLoading, setPwdUpdateLoading] = useState<boolean>(false);

  const [resetPwdCompleted, setResetPwdCompleted] = useState<boolean>(true);
  const [incorrectPwdError, setIncorrectPwdError] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>('');

  const [rulesMatch, setRulesMatch] = useState<IPasswordRules>(
    {} as IPasswordRules,
  );

  const { isOpen, onClose, onOpen } = useDisclosure({
    onClose: () => {
      setRulesMatch({} as IPasswordRules);
      setFormError('');
    },
    onOpen: () => {
      setResetPwdCompleted(false);
      setIsPasswordFilled(false);
      setIncorrectPwdError(false);
    },
  });

  const logOperationCall = useLogOperationCall();
  const [sendEmail, { loading, error }] = useSendEmail();

  const handlePasswordValidation = (rules: IPasswordRules) => {
    setRulesMatch(rules);
  };

  const handlePasswordSubmitSuccess = async ({
    newPassword,
    currentPassword = '',
    otpCode = '',
  }: IPasswordChangeForm) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (isUserMfaEnabled) {
        setIsPasswordFilled(true);

        if (otpCode.length) {
          setPwdUpdateLoading(true);
          const verifyUserToken = await Auth.verifyTotpToken(user, otpCode);

          if (verifyUserToken) {
            await Auth.changePassword(user, currentPassword, newPassword);
            setResetPwdCompleted(true);
            setPwdUpdateLoading(false);
            logOperationCall('PASSWORD_CHANGE', 'USER');
            sendEmail({
              variables: {
                input: {
                  email,
                  action: 'passwordUpdate',
                },
              },
            });
          }
        }
      } else {
        setPwdUpdateLoading(true);
        await Auth.changePassword(user, currentPassword, newPassword);
        setResetPwdCompleted(true);
        setPwdUpdateLoading(false);
        sendEmail({
          variables: {
            input: {
              email,
              action: 'passwordUpdate',
            },
          },
        });
      }
    } catch (error: any) {
      setFormError(error?.message);
      if (error?.message === 'Incorrect username or password.') {
        setIsPasswordFilled(false);
        setIncorrectPwdError(true);
        logOperationCall('INCORRECT_PASSWORD', 'SYSTEM');
      }
      setPwdUpdateLoading(false);
    }
  };

  return (
    <>
      <Modal
        title={i18n.pages.userAccount.resetPassword.heading}
        subtitle={
          isPasswordFilled && !resetPwdCompleted
            ? i18n.pages.userAccount.resetPassword['2FaSubHeading']
            : ''
        }
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        closeOnEsc={!pwdUpdateLoading}
        closeOnOverlayClick={!pwdUpdateLoading}
        isCloseDisabled={pwdUpdateLoading}
        body={
          <Stack spacing={6}>
            {!resetPwdCompleted && (
              <PasswordChangeForm
                hasOTP={isUserMfaEnabled}
                onSubmit={[handlePasswordSubmitSuccess]}
                isLabelHidden={false}
                hasCurrentPassword
                hasValidationPopover={false}
                onPasswordValidation={handlePasswordValidation}
                isPasswordFilled={isPasswordFilled}
                formError={formError}
                setFormError={setFormError}
              />
            )}
            {!isPasswordFilled && !resetPwdCompleted && (
              <Stack spacing={4}>
                <Text textStyle="body-lg">
                  {i18n.pages.userAccount.resetPassword.pwdPolicy}
                </Text>
                <Panel layerStyle="second">
                  <FieldValidationList
                    rules={[
                      {
                        isValid: rulesMatch.minLength,
                        message: 'At least 8 characters long',
                      },
                      {
                        isValid: rulesMatch.hasNumber,
                        message: 'At least one digit',
                      },
                      {
                        isValid: rulesMatch.hasSpecialCharacter,
                        message: 'At least one special character',
                      },
                      {
                        isValid: rulesMatch.hasUppercase,
                        message: 'At least one upper case character',
                      },
                      {
                        isValid: rulesMatch.hasLowercase,
                        message: 'At least one lower case character',
                      },
                    ]}
                  />
                </Panel>
              </Stack>
            )}
            {resetPwdCompleted && (
              <>
                <Center>
                  <Illustrations.Complete />
                </Center>
                <Text textStyle="body-lg" mb={4} textAlign="center">
                  {i18n.pages.userAccount.resetPassword.completed}
                </Text>
              </>
            )}
            {incorrectPwdError && !isPasswordFilled && !resetPwdCompleted && (
              <Alert status="error" variant="solid" mt={4}>
                <AlertIcon />
                {i18n.pages.userAccount.resetPassword.incorrectCurPwd}
              </Alert>
            )}
          </Stack>
        }
        footer={
          <ButtonGroup spacing={4}>
            {!resetPwdCompleted && (
              <>
                <Button variant="outline" colorScheme="gray" onClick={onClose}>
                  {i18n.buttons.cancel}
                </Button>
                <Button
                  variant="solid"
                  type="submit"
                  form="passwordChangeForm"
                  isLoading={pwdUpdateLoading}
                >
                  {isUserMfaEnabled && !isPasswordFilled
                    ? i18n.buttons.confirm2Fa
                    : i18n.buttons.confirm}
                </Button>
              </>
            )}
            {resetPwdCompleted && (
              <Button variant="solid" colorScheme="blue" onClick={onClose}>
                {i18n.buttons.close}
              </Button>
            )}
          </ButtonGroup>
        }
      >
        <Button data-testid="change-password-button" size="md">
          {i18n.pages.userAccount.changePwd}
        </Button>
      </Modal>
      {(loading || error) && (
        <GraphQLReqStatus
          loading={loading}
          error={error}
          isBackground={true}
          extended={graphqlErrorRedirects}
        />
      )}
    </>
  );
};
