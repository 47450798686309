import { createIcon } from '@chakra-ui/react';

export const BoldCloseIcon = createIcon({
  displayName: 'BoldCloseIcon',
  viewBox: '0 0 24 24',
  d: 'M20 6.91 17.09 4 12 9.09 6.91 4 4 6.91 9.09 12 4 17.09 6.91 20 12 14.91 17.09 20 20 17.09 14.91 12 20 6.91Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
