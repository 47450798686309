const toFixed = (number, decimals) => {
  // eslint-disable-next-line
  const regex = new RegExp('^-?\\d+(?:.\\d{0,' + (decimals || -1) + '})?');
  return number.toString().match(regex) ? number.toString().match(regex)[0] : 0;
};

const formatNumber = (number, pow, decimals = 2) =>
  toFixed(number / 10 ** pow, decimals);

const getMeaningfulNumber = (number, decimals = 2) => {
  if (+toFixed(Math.abs(number), decimals) > 0.1) {
    return +toFixed(number, decimals);
  }

  return +(getMeaningfulNumber(number * 10, decimals) / 10).toPrecision(4);
};

export const smartNumber = (number, decimals) => {
  if (typeof number !== 'number' || number === 0) {
    return number;
  }
  if (Math.abs(number) < 10 ** 3) {
    if (typeof decimals === 'number') {
      return getMeaningfulNumber(number, decimals);
    }
    return getMeaningfulNumber(number);
  }

  if (Math.abs(number) < 10 ** 6) {
    if (typeof decimals === 'number') {
      return `${formatNumber(number, 3, decimals)} K`;
    }
    return `${formatNumber(number, 3)} K`;
  }

  if (Math.abs(number) < 10 ** 9) {
    if (typeof decimals === 'number') {
      return `${formatNumber(number, 6, decimals)} M`;
    }
    return `${formatNumber(number, 6)} M`;
  }

  if (typeof decimals === 'number') {
    return `${formatNumber(number, 9, decimals)} B`;
  }
  return `${formatNumber(number, 9)} B`;
};
