import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

export const DELETE_USERS = gql`
  mutation deleteUsers($usernames: [String!]!) {
    deleteUsers(usernames: $usernames) {
      status_code
      body {
        data
        message
        success
      }
    }
  }
`;

export const useDeleteUsers = (
  options: MutationHookOptions<any, OperationVariables>,
) => {
  return useMutation(DELETE_USERS, options);
};
