import { createIcon } from '@chakra-ui/react';

export const ThumbDownIcon = createIcon({
  displayName: 'ThumbDownIcon',
  viewBox: '0 0 20 20',
  path: (
    <path
      d="M4.99992 2.5H14.1666V13.3333L8.33325 19.1667L7.29159 18.125C7.19436 18.0278 7.1145 17.8958 7.052 17.7292C6.9895 17.5625 6.95825 17.4028 6.95825 17.25V16.9583L7.87492 13.3333H2.49992C2.05547 13.3333 1.66659 13.1667 1.33325 12.8333C0.999919 12.5 0.833252 12.1111 0.833252 11.6667V10C0.833252 9.90278 0.847141 9.79861 0.874919 9.6875C0.902696 9.57639 0.930474 9.47222 0.958252 9.375L3.45825 3.5C3.58325 3.22222 3.79159 2.98611 4.08325 2.79167C4.37492 2.59722 4.68047 2.5 4.99992 2.5ZM12.4999 4.16667H4.99992L2.49992 10V11.6667H9.99992L8.87492 16.25L12.4999 12.625V4.16667ZM14.1666 13.3333V11.6667H16.6666V4.16667H14.1666V2.5H18.3333V13.3333H14.1666Z"
      fill="white"
    />
  ),
  defaultProps: {
    width: '20px',
    height: '20px',
    fill: 'none',
  },
});
