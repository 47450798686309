import { Icon, useColorModeValue, useTheme } from '@chakra-ui/react';
import { getColor } from '@chakra-ui/theme-tools';
import { ComponentProps } from 'react';

export const AdminIcon = ({ css, ...rest }: ComponentProps<typeof Icon>) => {
  const theme = useTheme();
  const bgColor = useColorModeValue(
    getColor(theme, 'gray.50'),
    getColor(theme, 'gray.900'),
  );
  const avatarColor = useColorModeValue(
    getColor(theme, 'gray.900'),
    getColor(theme, 'gray.50'),
  );
  return (
    <Icon viewBox="0 0 28 28" height="28px" width="28px" {...rest}>
      <path
        d="M12.6667 8C11.9595 8 11.2812 8.28095 10.7811 8.78105C10.281 9.28115 10 9.95942 10 10.6667C10 11.3739 10.281 12.0522 10.7811 12.5523C11.2812 13.0524 11.9595 13.3333 12.6667 13.3333C13.374 13.3333 14.0522 13.0524 14.5523 12.5523C15.0524 12.0522 15.3334 11.3739 15.3334 10.6667C15.3334 9.95942 15.0524 9.28115 14.5523 8.78105C14.0522 8.28095 13.374 8 12.6667 8ZM12.6667 14.6667C9.72004 14.6667 7.33337 15.86 7.33337 17.3333V18.6667H13.6667C13.4467 18.1386 13.3334 17.5721 13.3334 17C13.3362 16.2095 13.5552 15.4349 13.9667 14.76C13.5467 14.7067 13.12 14.6667 12.6667 14.6667Z"
        fill={avatarColor}
      />
      <path
        fill="#43BF79"
        d="M20.5084 17.7959C20.5696 17.7959 20.5696 17.8571 20.5084 17.9184L19.8961 18.9592C19.8349 19.0204 19.7737 19.0204 19.7124 19.0204L18.9777 18.7755C18.7941 18.898 18.6716 18.9592 18.4879 19.0816L18.3655 19.8776C18.3655 19.9388 18.3043 20 18.2431 20H17.0186C16.9573 20 16.8961 19.9388 16.8349 19.8776L16.7124 19.0816C16.5288 19.0204 16.3451 18.898 16.2226 18.7755L15.488 19.0816C15.4267 19.0816 15.3655 19.0816 15.3043 19.0204L14.692 17.9796C14.6308 17.9184 14.692 17.8571 14.7533 17.7959L15.4267 17.3061V16.6939L14.7533 16.2041C14.692 16.1429 14.692 16.0816 14.692 16.0204L15.3043 14.9796C15.3655 14.9184 15.4267 14.9184 15.488 14.9184L16.2226 15.2245C16.4063 15.102 16.5288 15.0408 16.7124 14.9184L16.8349 14.1224C16.8349 14.0612 16.8961 14 17.0186 14H18.2431C18.3043 14 18.3655 14.0612 18.3655 14.1224L18.4879 14.9184C18.6716 14.9796 18.8553 15.102 19.039 15.2245L19.7737 14.9184C19.8349 14.9184 19.9573 14.9184 19.9573 14.9796L20.5696 16.0204C20.6308 16.0816 20.5696 16.1429 20.5084 16.2041L19.8349 16.6939V17.3061L20.5084 17.7959ZM18.4879 16.9388C18.4879 16.449 18.0594 16.0204 17.5696 16.0204C17.0798 16.0204 16.6512 16.449 16.6512 16.9388C16.6512 17.4286 17.0798 17.8571 17.5696 17.8571C18.0594 17.8571 18.4879 17.4286 18.4879 16.9388Z"
      />
    </Icon>
  );
};
