import { omit } from 'lodash';

import { LabelProps } from '../../Components';

export function omitLabelProps<T extends Record<string, unknown>>(
  props: T,
): Omit<T, keyof LabelProps> {
  const ownProps = omit(props, [
    'label',
    'name',
    'hint',
    'error',
    'warning',
    'inline',
    'isRequired',
    'isDisabled',
    'isReadOnly',
    'isLabelHidden',
    'width',
    'afterLabel',
    'htmlFor',
    'id',
    'tooltip',
    'children',
    'h',
  ]);

  return ownProps;
}
