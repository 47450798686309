import { TenantMember } from '@gamma/investigator/queries';
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { AuthContext } from '../AuthProvider';
import { OrgTenantsContext } from './OrgTenantsContext';

interface OrgTenantsProviderProps {
  children?: ReactNode;
}

export const OrgTenantsProvider = ({ children }: OrgTenantsProviderProps) => {
  const { userLicense } = useContext(AuthContext);

  const [selectedOrgTenants, setSelectedOrgTenants] = useState<
    TenantMember[] | undefined
  >();

  const [orgTenantsQueryParam, setOrgTenantsQueryParam] = useState<
    string[] | undefined
  >();

  useEffect(() => {
    if (
      userLicense?.members &&
      userLicense?.members?.length > 0 &&
      selectedOrgTenants?.[0]?.tenant_id === 'all'
    ) {
      setOrgTenantsQueryParam(
        userLicense?.members?.map((member) => member.tenant_id),
      );
    } else if (
      userLicense?.tenant &&
      !(userLicense?.members?.length > 0) &&
      selectedOrgTenants?.[0]?.tenant_id === 'all'
    ) {
      setOrgTenantsQueryParam([userLicense?.tenant]);
    } else {
      setOrgTenantsQueryParam(
        selectedOrgTenants?.map((tenant) => tenant.tenant_id),
      );
    }
  }, [userLicense, selectedOrgTenants, setOrgTenantsQueryParam]);

  const orgTenantsContext = useMemo(() => {
    return {
      selectedOrgTenants,
      setSelectedOrgTenants,
      orgTenantsQueryParam,
    };
  }, [selectedOrgTenants, setSelectedOrgTenants, orgTenantsQueryParam]);

  return (
    <OrgTenantsContext.Provider value={{ ...orgTenantsContext }}>
      {children}
    </OrgTenantsContext.Provider>
  );
};
