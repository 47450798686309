import { Center, HStack, Text, VStack } from '@chakra-ui/react';
import { TimestampCell } from '@gamma/investigator/components';
import { i18n } from '@gamma/investigator/localization';
import { Board, Column, Panel, PanelHeader, PanelHeading } from '@gamma/layout';
import { GraphQLReqStatus } from '@gamma/progress';
import moment from 'moment';

import { SanitizedSearchBasedAlert } from '../..';

const { alerts, detection } = i18n.pages.detections;

interface AlertRowSearchBasedProps {
  item: SanitizedSearchBasedAlert;
}

interface AlertRowSplitSearchBasedProps {
  item: SanitizedSearchBasedAlert;
  loading: boolean | undefined;
}

export const AlertRowSearchBased = ({ item }: AlertRowSearchBasedProps) => {
  return (
    <HStack spacing={4}>
      <VStack alignItems="start">
        <TimestampCell
          timestamp={item?.alert_timestamp?.observed}
          isFromNow={false}
        />
        <HStack color="text.200">
          <Text textStyle="body-md">{item?.alert_info?.alert_name}</Text>
          <Text textStyle="body-md">|</Text>
          <Text textStyle="body-md">{item?.alert_entity?.entity_name}</Text>
        </HStack>
      </VStack>
    </HStack>
  );
};

export const AlertRowSplitSearchBased = ({
  item,
  loading,
}: AlertRowSplitSearchBasedProps) => {
  const time = moment.unix(item?.alert_timestamp?.observed);
  const formattedTime = time.format();

  if (loading) {
    return (
      <Center w="100%">
        <GraphQLReqStatus loading={loading} />
      </Center>
    );
  }

  return (
    <Board>
      <Column>
        <Panel data-testid="search-based-alerts-wrapper">
          <PanelHeader divider data-testid="search-based-alert-heading">
            <PanelHeading>{detection.details}</PanelHeading>
          </PanelHeader>
          <VStack alignItems="start">
            <VStack spacing={4} alignItems="start">
              <VStack spacing={1} alignItems="start">
                <HStack spacing={1} alignItems="start">
                  <Text textStyle="body-md" color="text.200">
                    {alerts.category}:
                  </Text>
                  <Text>{item?.alert_info?.alert_name}</Text>
                </HStack>
                <HStack spacing={1} alignItems="start">
                  <Text textStyle="body-md" color="text.200">
                    {alerts.timestamp}:
                  </Text>
                  <Text>{formattedTime}</Text>
                </HStack>
              </VStack>
            </VStack>
          </VStack>
        </Panel>
      </Column>
    </Board>
  );
};
