import { createIcon } from '@chakra-ui/react';

export const HumioLoader = createIcon({
  displayName: 'HumioLoader',
  viewBox: '0 0 64 64',
  path: (
    <>
      <circle cx="32" cy="32" r="32" fill="#626A78" />
      <circle cx="32" cy="32" r="29" fill="#fff" />
      <path
        d="M49.31 26.564c.197-.742.328-1.507.328-2.315 0-.83-.153-1.616-.35-2.381v-8.322l-.96.11c-3.19.349-6.379.589-9.568.72A17.33 17.33 0 0 0 32.01 13c-2.402 0-4.673.48-6.748 1.376-3.19-.153-6.4-.371-9.567-.72l-.962-.11v8.322c-.196.765-.349 1.55-.349 2.38 0 .81.131 1.573.328 2.316-1.486.83-2.643 2.621-2.709 5.264-.13 5.854 3.8 11.467 3.8 11.467.35-1.135 1.006-2.315 1.748-3.516a17.326 17.326 0 0 0 5.745 5.439c-.306 0-.568.174-.743.415-.175-.262-.437-.437-.764-.437h-.24a.951.951 0 0 0-.94.96v3.015c0 .524.415.961.94.961h.24c.327 0 .59-.174.764-.437.175.263.437.437.765.437h.24c.328 0 .59-.174.764-.415.175.24.437.415.743.415h.24a.95.95 0 0 0 .94-.96v-2.6c1.812.633 3.735 1.005 5.744 1.005 2.01 0 3.954-.372 5.745-1.005v2.6a.95.95 0 0 0 .939.96h.24c.328 0 .59-.174.765-.415.174.24.437.415.764.415h.24c.328 0 .59-.174.765-.437.175.24.437.437.764.437h.24c.525 0 .94-.437.94-.96v-3.015a.951.951 0 0 0-.94-.961h-.24c-.327 0-.59.175-.764.437-.175-.24-.437-.415-.743-.415a17.326 17.326 0 0 0 5.745-5.44c.742 1.202 1.398 2.382 1.747 3.517 0 0 3.932-5.613 3.8-11.467-.065-2.643-1.222-4.434-2.686-5.264Z"
        fill="#fff"
      />
      <path
        d="M49.31 26.564c.197-.742.328-1.507.328-2.315 0-.83-.153-1.616-.35-2.381v-8.322l-.96.11c-3.19.349-6.379.589-9.568.72A17.33 17.33 0 0 0 32.01 13c-2.402 0-4.673.48-6.748 1.376-3.19-.153-6.4-.371-9.567-.72l-.962-.11v8.322c-.196.765-.349 1.55-.349 2.38 0 .81.131 1.573.328 2.316-1.486.83-2.643 2.621-2.709 5.264-.13 5.854 3.8 11.467 3.8 11.467.35-1.135 1.006-2.315 1.748-3.516a17.326 17.326 0 0 0 5.745 5.439c-.306 0-.568.174-.743.415-.175-.262-.437-.437-.764-.437h-.24a.951.951 0 0 0-.94.96v3.015c0 .524.415.961.94.961h.24c.327 0 .59-.174.764-.437.175.263.437.437.765.437h.24c.328 0 .59-.174.764-.415.175.24.437.415.743.415h.24a.95.95 0 0 0 .94-.96v-2.6c1.812.633 3.735 1.005 5.744 1.005 2.01 0 3.954-.372 5.745-1.005v2.6a.95.95 0 0 0 .939.96h.24c.328 0 .59-.174.765-.415.174.24.437.415.764.415h.24c.328 0 .59-.174.765-.437.175.24.437.437.764.437h.24c.525 0 .94-.437.94-.96v-3.015a.951.951 0 0 0-.94-.961h-.24c-.327 0-.59.175-.764.437-.175-.24-.437-.415-.743-.415a17.326 17.326 0 0 0 5.745-5.44c.742 1.202 1.398 2.382 1.747 3.517 0 0 3.932-5.613 3.8-11.467-.065-2.643-1.222-4.434-2.686-5.264Zm-17.3 8.825-3.385-3.386a9.397 9.397 0 0 0 3.386-3.932 9.398 9.398 0 0 0 3.386 3.932l-3.386 3.386Z"
        fill="#636B78"
      />
      <circle cx="23.5" cy="24.5" r="7.5" fill="#fff" />
      <circle cx="40.5" cy="24.5" r="7.5" fill="#fff" />
      <circle cx="23" cy="25" r="3" fill="#626A78" className="loading_eye" />
      <circle cx="40" cy="25" r="3" fill="#626A78" className="loading_eye" />
    </>
  ),
  defaultProps: {
    height: '64px',
    width: '64px',
  },
});
