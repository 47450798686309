import { usePrevious } from '@gamma/hooks';
import { isEqual, unset } from 'lodash';
import { ComponentType, useEffect, useMemo, useRef } from 'react';
import {
  Column,
  Filters,
  Row,
  SortByFn,
  SortingRule,
  TableState,
  useExpanded,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';

import { DataTablePageSize } from '../../components';
import { useResizeColumns } from '../useResizeColumns';
import { useTableExpansionColumn } from '../useTableExpansionColumn';
import { useTableSelectionColumn } from '../useTableSelectionColumn';

export interface UseDataTableParams<DataType extends Record<string, unknown>> {
  /** A readonly array of column metadata for the table */
  columns: readonly Column<DataType>[];
  /** The default values for table column */
  defaultColumn?: Partial<Column<DataType>>;
  /** A readonly array of data for the table */
  data: readonly DataType[];
  /** Flag whether the table rows are selectable or not */
  isSelectable?: boolean;
  /** Flag whether the table selected rows should reset on pagination change */
  autoResetRowSelection?: boolean;
  /** Flag whether the table selected rows should reset filters */
  autoResetFilters?: boolean;
  /**
   * Flag whether the table columns are sortable or not
   * @default true
   */
  isSortable?: boolean;
  /**
   * Flag whether the table has pagination or not
   * @default true
   */
  isPaginable?: boolean;
  /**
   * Flag whether the table rows are expandable or not
   * @default false
   */
  isExpandable?: boolean;
  /**
   * Flag whether the table's pagination is controlled manually or not
   * @default false
   */
  isPaginationManual?: boolean;
  /**
   * Flag whether the table's sorting is controlled manually or not
   * @default false
   */
  isSortingManual?: boolean;
  /**
   * Flag whether the table's column filtering is controlled manually or not
   * @default false
   */
  isFilterManual?: boolean;
  /**
   * Flag whether the table column sorting is case sensitive or not
   * @default false
   */
  isSortCaseSensitive?: boolean;
  /**
   * Flag whether the table should reset the page to 1 when the data changes
   * @default true
   */
  autoResetPage?: boolean;
  /**
   * Flag whether the table should reset the sort by when the data changes
   * @default true
   */
  autoResetSortBy?: boolean;
  /**
   * Flag whether the table should reset the global filter when the data changes
   * @default false
   */
  autoResetExpanded?: boolean;
  /**
   * Flag whether the table should reset expanded row states when data changes
   * @default false
   */
  autoResetGlobalFilter?: boolean;
  /**
   * The amount of pages to show when the table pagination is manually controlled
   * @default 1
   */
  pageCount?: number;
  /**
   * How many rows to show per page
   * @deprecated use initialState instead to avoid issues with state persistence
   */
  pageSize?: DataTablePageSize;
  /** Text to filter the table's data */
  search?: string;
  /** The initial state of the table, passed to the `useTable` hook */
  initialState?: Partial<TableState<DataType>>;
  /**
   * The name where the table state should be saved to local storage
   * if defined, the table will persist its state through multiple sessions from local storage
   */
  storageKey?: string;
  /**
   * The function to test whether a row is selectable or not, disables any rows
   * that return a truthy value.
   * Return a string if you want the checkbox to have a tooltip on hover
   */
  rowIsSelectable?: (row: Row<DataType>) => boolean | string;
  /**
   * The function to test whether a row is expandable or not, disables any rows
   * that return a truthy value.
   * Return a string if you want the button to have a tooltip on hover
   */
  rowIsExpandable?: (row: Row<DataType>) => boolean | string;
  /** The function triggered whenever a row is selected */
  onRowSelection?: (rows: Row<DataType>[]) => void;
  /** The function triggered when the search prop is updated */
  onSearchFilter?: (rows: Row<DataType>[]) => void;
  /** The function triggered when a column is filtered */
  onColumnFilter?: (filters: Filters<DataType>, rows: Row<DataType>[]) => void;
  /** The function triggered when the page is changed */
  onPageChange?: (rows: Row<DataType>[]) => void;
  /**
   * The function triggered during manual pagination/sorting when the page,
   * page size, or sorting is changed
   */
  onFetchData?: (
    pageIndex: number,
    pageSize: number,
    sortBy: SortingRule<DataType>[],
  ) => void;
  /** The content to render when a row is expanded */
  renderExpandedRow?: ComponentType<Row<DataType>>;
  /** Allows for customized filter behavior */
  globalFilter?:
    | string
    | ((
        rows: Array<Row<DataType>>,
        columnIds: Array<Column['id']>,
        globalFilterValue: any,
      ) => Row<DataType>[]);
  /** prevents loading hiddenColumns from local storage if this is false */
  isColumnVisibilityTogglable?: boolean;
}

export const useDataTable = <DataType extends Record<string, unknown>>({
  columns,
  defaultColumn,
  data,
  pageCount: manualPageCount,
  pageSize: manualPageSize,
  isSelectable,
  isExpandable = false,
  isPaginable = true,
  isSortable = true,
  isPaginationManual,
  isSortingManual,
  isFilterManual,
  isSortCaseSensitive,
  autoResetRowSelection = true,
  autoResetPage = true,
  autoResetSortBy = true,
  autoResetGlobalFilter = false,
  autoResetFilters = true,
  initialState: propsInitialState,
  search,
  storageKey,
  rowIsSelectable,
  rowIsExpandable,
  onRowSelection,
  onSearchFilter,
  onPageChange,
  onFetchData,
  onColumnFilter,
  renderExpandedRow,
  globalFilter,
  isColumnVisibilityTogglable,
}: UseDataTableParams<DataType>) => {
  const mountedRef = useRef<boolean>(false); // if true, we're after first mount
  const capsInsensitiveSorting: Record<
    string,
    SortByFn<DataType>
  > = isSortCaseSensitive
    ? {}
    : {
        alphanumeric: (row1, row2, columnName) => {
          const rowOneColumn = row1.values[columnName];
          const rowTwoColumn = row2.values[columnName];
          if (isNaN(rowOneColumn)) {
            return String(rowOneColumn)?.toUpperCase() >
              String(rowTwoColumn)?.toUpperCase()
              ? 1
              : -1;
          }
          return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
        },
      };

  const initialState = useMemo(() => {
    if (!mountedRef.current && storageKey) {
      // if we're mounting for the first time and if props.storageKey is defined
      const savedState = localStorage.getItem(storageKey);
      if (savedState) {
        // ... and if saved state was found in localStorage
        const result = JSON.parse(savedState);

        if (!isColumnVisibilityTogglable) {
          // if visibility is not togglable, dont set hiddenColumns
          unset(result, 'hiddenColumns');
        }

        return result;
      }
    }

    // since the table can be controlled with initial state we want to make sure
    // that any rerenders use the initial state from props instead of the saved state
    return propsInitialState;
  }, [storageKey, propsInitialState]);

  const table = useTable(
    {
      columns,
      defaultColumn,
      data,
      autoResetPage,
      autoResetSortBy,
      autoResetGlobalFilter,
      autoResetFilters,
      autoResetSelectedRows: autoResetRowSelection,
      manualPagination: isPaginationManual || !isPaginable,
      manualSortBy: isSortingManual,
      manualFilters: isFilterManual,
      ...(isPaginationManual && { pageCount: manualPageCount }),
      disableSortBy: !isSortable,
      initialState: {
        ...initialState,
        pageSize: initialState?.pageSize ?? manualPageSize ?? 10,
      },
      sortTypes: {
        ...capsInsensitiveSorting,
      },
      globalFilter,
    },
    useFlexLayout,
    useFilters,
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useTableSelectionColumn({ rowIsSelectable, isSelectable }),
    useTableExpansionColumn({
      rowIsExpandable,
      renderExpandedRow,
      isExpandable,
    }),
  );

  const {
    page,
    pageCount,
    gotoPage,
    setPageSize,
    toggleAllRowsSelected,
    selectedFlatRows,
    state: {
      hiddenColumns,
      pageIndex,
      pageSize,
      globalFilter: globalFilterValue,
      sortBy,
      filters,
    },
    setGlobalFilter,
    rows,
  } = table;

  // save table state to local storage anytime it changes
  useEffect(() => {
    if (mountedRef.current && storageKey) {
      // if we're re-rendering
      const jsonState = JSON.stringify({
        hiddenColumns,
        pageIndex,
        pageSize,
        sortBy,
        globalFilter: globalFilterValue,
        filters,
      });
      localStorage.setItem(storageKey, jsonState);
    }
  }, [
    filters,
    globalFilterValue,
    pageIndex,
    pageSize,
    sortBy,
    storageKey,
    hiddenColumns,
  ]);

  const oldPage = usePrevious(page);
  const oldRows = usePrevious(selectedFlatRows);
  const oldSearch = usePrevious(globalFilterValue);
  const oldFilters = usePrevious(filters);
  const oldPageIndex = usePrevious(pageIndex);
  const oldPageSize = usePrevious(pageSize);
  const oldSortBy = usePrevious(sortBy);

  useEffect(() => {
    if (
      pageIndex !== oldPageIndex ||
      sortBy !== oldSortBy ||
      pageSize !== oldPageSize
    ) {
      onFetchData?.(pageIndex, pageSize, sortBy);
    }
  }, [
    onFetchData,
    pageIndex,
    pageSize,
    sortBy,
    oldPageIndex,
    oldPageSize,
    oldSortBy,
  ]);

  useEffect(() => {
    if (globalFilterValue !== oldSearch) {
      onSearchFilter?.(rows);
    }
  }, [globalFilterValue, rows, onSearchFilter]);

  // Untoggle all rows if current page changes or if source data changes.
  useEffect(() => {
    if (
      !isEqual(oldPage, page) &&
      selectedFlatRows.length &&
      autoResetRowSelection
    ) {
      toggleAllRowsSelected(false);
    }
  }, [
    oldPage,
    page,
    selectedFlatRows.length,
    toggleAllRowsSelected,
    autoResetRowSelection,
  ]);

  // Pass row selection state to parent
  useEffect(() => {
    // If we have a different array due to the source data
    // changing, but nothing is selected and nothing was
    // selected previously, abort early.
    if (oldRows?.length === 0 && selectedFlatRows?.length === 0) {
      return;
    }

    if (oldRows !== undefined && !isEqual(oldRows, selectedFlatRows)) {
      // Have to filter out the disabled rows manually
      // as the useRowSelect plugin does not allow for
      // specifying disabled rows.
      const filteredFlatRows = selectedFlatRows.filter((row) => {
        const isRowSelectable = rowIsSelectable?.(row) ?? true;
        return isRowSelectable && typeof isRowSelectable !== 'string';
      });
      onRowSelection?.(filteredFlatRows);
    }
  }, [onRowSelection, oldRows, selectedFlatRows, rowIsSelectable]);

  useEffect(() => {
    setGlobalFilter(search);
  }, [search, setGlobalFilter]);

  useEffect(() => {
    if (pageIndex + 1 > pageCount) {
      gotoPage(0);
    }
  }, [pageIndex, pageCount, gotoPage]);

  // update the page size when prop is changed
  useEffect(() => {
    if (manualPageSize) {
      setPageSize(manualPageSize);
    }
  }, [manualPageSize, setPageSize]);

  // trigger onColumnFilter prop whenever the column filters have changed
  useEffect(() => {
    if (oldFilters !== undefined && oldFilters !== filters) {
      onColumnFilter?.(filters, rows);
    }
  }, [oldFilters, filters, onColumnFilter]);

  useEffect(() => {
    mountedRef.current = true;
  }, []);

  useEffect(() => {
    onPageChange?.(page);
  }, [onPageChange, page]);

  return table;
};
