import { createIcon } from '@chakra-ui/react';

export const RefreshWithCircleIcon = createIcon({
  displayName: 'RefreshWithCircleIcon',
  viewBox: '0 0 24 24',
  d: 'M12 3a9 9 0 0 0-9 9H0l4 4 4-4H5a7 7 0 1 1 7 7c-1.5 0-2.91-.5-4.06-1.3L6.5 19.14A9.115 9.115 0 0 0 12 21a9 9 0 0 0 0-18Zm2 9a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
