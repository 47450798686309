import { ApolloError } from '@apollo/client';
import { IGetHumioTenentType } from '@gamma/investigator/queries';
import { ReactNode, useMemo, useState } from 'react';

import { LogScaleContext } from './LogScaleContext';

interface LogScaleProviderProps {
  children?: ReactNode;
}

export const LogScaleProvider = ({ children }: LogScaleProviderProps) => {
  const [logScaleURL, setLogScaleURL] = useState<string | undefined>();
  const [logScaleDashboardId, setLogScaleDashboardId] = useState<
    string | undefined
  >();
  const [logScaleURLParams, setLogScaleURLParams] = useState<
    string[] | [] | undefined
  >();
  const [logScaleDashboardsLoading, setLogScaleDashboardsLoading] =
    useState<boolean>(false);
  const [logScaleDashboardsError, setLogScaleDashboardsError] =
    useState<ApolloError>();
  const [logScaleDashboardsData, setLogScaleDashboardsData] =
    useState<IGetHumioTenentType>();
  const [iframeKey, setIframeKey] = useState<number>(0);
  const [logScaleDashboardParams, setLogScaleDashboardParams] = useState<any>();
  const [sessionIdTokenCorelight, setSessionIdTokenCorelight] = useState<
    string | undefined
  >();

  const logScaleContext = useMemo(() => {
    return {
      logScaleURL,
      logScaleDashboardId,
      logScaleURLParams,
      logScaleDashboardsLoading,
      logScaleDashboardsError,
      logScaleDashboardsData,
      setLogScaleURL,
      setLogScaleDashboardId,
      setLogScaleURLParams,
      setLogScaleDashboardsLoading,
      setLogScaleDashboardsError,
      setLogScaleDashboardsData,
      iframeKey,
      setIframeKey,
      logScaleDashboardParams,
      setLogScaleDashboardParams,
      sessionIdTokenCorelight,
      setSessionIdTokenCorelight,
    };
  }, [
    logScaleURL,
    logScaleDashboardId,
    logScaleURLParams,
    logScaleDashboardsLoading,
    logScaleDashboardsError,
    logScaleDashboardsData,
    setLogScaleURL,
    setLogScaleDashboardId,
    setLogScaleURLParams,
    setLogScaleDashboardsLoading,
    setLogScaleDashboardsError,
    setLogScaleDashboardsData,
    iframeKey,
    setIframeKey,
    logScaleDashboardParams,
    setLogScaleDashboardParams,
    sessionIdTokenCorelight,
    setSessionIdTokenCorelight,
  ]);

  return (
    <LogScaleContext.Provider value={{ ...logScaleContext }}>
      {children}
    </LogScaleContext.Provider>
  );
};
