import { Children, isValidElement, ReactNode, useMemo } from 'react';

export type ChildTab = { title: ReactNode; content?: ReactNode; props?: any };

export const useTabsFromChildren = (children: ReactNode) => {
  const tabsFromChildren: ChildTab[] = useMemo(() => {
    if (!children) {
      return [];
    }

    return (
      Children.map(children, (child) => {
        if (isValidElement(child) && child.props.title) {
          return {
            title: child.props.title,
            content: child.props.children,
            props: child.props,
          } as ChildTab;
        }

        return null;
      })?.filter((child) => child) || []
    );
  }, [children]);

  return tabsFromChildren;
};
