import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Center,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { Illustrations } from '@gamma/icons';
import { LicenseTypeIllustration } from '@gamma/shared/license-type-illustration';
import { i18n } from '@gamma/shared/localization';
import { ReactNode } from 'react';
import { Column, useSortBy, useTable } from 'react-table';

interface TableRows {
  label: string;
  standardeval?: ReactNode | string;
  standard?: ReactNode | string;
  advanced?: ReactNode | string;
  advancedeval?: ReactNode | string;
}

interface DaysType {
  more?: boolean;
  numOfDays: number;
}
const getFieldSign = (toCheck: boolean) => {
  if (toCheck) {
    return (
      <Center>
        <CheckIcon color={'green.400'} mt="8px" />
      </Center>
    );
  }
  return (
    <Center>
      <CloseIcon color={'red.400'} mt="8px" />
    </Center>
  );
};

const Days = ({ more, numOfDays }: DaysType) => {
  return (
    <Center>
      {numOfDays} {!more ? days : daysMore}
    </Center>
  );
};

const { standardEval, standard, advanced, advancedEval } =
  i18n.licenseComparisonTable.licenseStatus;

const {
  alerts,
  alertExport,
  days,
  daysMore,
  incidentResponse,
  detection,
  analytics,
  sensor,
  suricataId,
  mlDetections,
  iocDatabase,
  dataRetention,
  alertsAndDetection,
  alertLogs,
  alertLogsRetention,
  suricataLogs,
  suricataLogRetention,
  dataExport,
  suricataLogExport,
  admin,
  sso,
  securityAudit,
  fleetManager,
  smartPCAP,
  support,
  standardSupport,
  enterpriseSupport,
  quickstart,
  threat,
  optional,
  na,
} = i18n.licenseComparisonTable.compareModal;

const data: TableRows[] = [
  {
    label: incidentResponse,
  },
  {
    label: detection,
    standard: getFieldSign(true),
    standardeval: getFieldSign(true),
    advanced: getFieldSign(true),
    advancedeval: getFieldSign(true),
  },
  {
    label: alerts,
    standard: getFieldSign(true),
    standardeval: getFieldSign(true),
    advanced: getFieldSign(true),
    advancedeval: getFieldSign(true),
  },
  {
    label: analytics,
  },
  {
    label: sensor,
    standard: getFieldSign(true),
    standardeval: getFieldSign(true),
    advanced: getFieldSign(true),
    advancedeval: getFieldSign(true),
  },
  {
    label: suricataId,
    standard: getFieldSign(true),
    standardeval: getFieldSign(true),
    advanced: getFieldSign(true),
    advancedeval: getFieldSign(true),
  },
  {
    label: mlDetections,
    standard: getFieldSign(true),
    standardeval: getFieldSign(true),
    advanced: getFieldSign(true),
    advancedeval: getFieldSign(true),
  },
  {
    label: iocDatabase,
    standard: getFieldSign(true),
    standardeval: getFieldSign(true),
    advanced: getFieldSign(true),
    advancedeval: getFieldSign(true),
  },
  {
    label: dataRetention,
  },
  {
    label: alertsAndDetection,
    standard: <Days numOfDays={90} />,
    standardeval: <Days numOfDays={90} />,
    advanced: <Days numOfDays={90} />,
    advancedeval: <Days numOfDays={90} />,
  },
  {
    label: alertLogs,
    standard: <Days numOfDays={7} />,
    standardeval: <Days numOfDays={7} />,
    advanced: <Days numOfDays={30} />,
    advancedeval: <Days numOfDays={30} />,
  },
  {
    label: alertLogsRetention,
    standard: <Center>{optional}</Center>,
    standardeval: <Center>{na}</Center>,
    advanced: <Center>{na}</Center>,
    advancedeval: <Center>{na}</Center>,
  },
  {
    label: suricataLogs,
    standard: <Center>{na}</Center>,
    standardeval: <Center>{na}</Center>,
    advanced: <Days numOfDays={30} />,
    advancedeval: <Days numOfDays={30} />,
  },
  {
    label: suricataLogRetention,
    standard: <Center>{na}</Center>,
    standardeval: <Center>{na}</Center>,
    advanced: <Center>{optional}</Center>,
    advancedeval: <Center>{optional}</Center>,
  },
  {
    label: dataExport,
  },
  {
    label: suricataLogExport,
    standard: getFieldSign(true),
    standardeval: getFieldSign(true),
    advanced: getFieldSign(true),
    advancedeval: getFieldSign(true),
  },
  {
    label: alertExport,
    standard: getFieldSign(true),
    standardeval: getFieldSign(true),
    advanced: getFieldSign(true),
    advancedeval: getFieldSign(true),
  },
  {
    label: admin,
  },
  {
    label: sso,
    standard: getFieldSign(true),
    standardeval: getFieldSign(true),
    advanced: getFieldSign(true),
    advancedeval: getFieldSign(true),
  },
  {
    label: securityAudit,
    standard: getFieldSign(true),
    standardeval: getFieldSign(true),
    advanced: getFieldSign(true),
    advancedeval: getFieldSign(true),
  },
  {
    label: fleetManager,
    standard: getFieldSign(true),
    standardeval: getFieldSign(true),
    advanced: getFieldSign(true),
    advancedeval: getFieldSign(true),
  },
  {
    label: smartPCAP,
    standard: getFieldSign(true),
    standardeval: getFieldSign(true),
    advanced: getFieldSign(true),
    advancedeval: getFieldSign(true),
  },
  {
    label: support,
  },
  {
    label: standardSupport,
    standard: getFieldSign(true),
    standardeval: getFieldSign(true),
    advanced: getFieldSign(true),
    advancedeval: getFieldSign(true),
  },
  {
    label: enterpriseSupport,
    standard: <Center>{optional}</Center>,
    standardeval: <Center>{na}</Center>,
    advanced: <Center>{optional}</Center>,
    advancedeval: <Center>{na}</Center>,
  },
  {
    label: quickstart,
    standard: getFieldSign(true),
    standardeval: getFieldSign(true),
    advanced: getFieldSign(true),
    advancedeval: getFieldSign(true),
  },
  {
    label: threat,
    standard: <Center>{optional}</Center>,
    standardeval: <Center>{na}</Center>,
    advanced: <Center>{optional}</Center>,
    advancedeval: <Center>{na}</Center>,
  },
];
const columns: Column<TableRows>[] = [
  {
    Header: '',
    accessor: 'label',
  },
  {
    Header: (
      <Center>
        <LicenseTypeIllustration
          icon={<Illustrations.AdvancedEvalLicenseStatus />}
          title={advancedEval}
        />
      </Center>
    ),
    accessor: 'advancedeval',
  },
  {
    Header: (
      <Center>
        <LicenseTypeIllustration
          icon={<Illustrations.AdvancedLicenseStatus />}
          title={advanced}
        />
      </Center>
    ),
    accessor: 'advanced',
  },
];

export function LicenseComparisonTable() {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);
  const bgColor = useColorModeValue('gray.300', 'gray.700');

  return (
    <Table {...getTableProps()} variant={'license'}>
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => (
              <Th key={index}>{column.render('Header')}</Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return row.original.standard ? (
            <Tr {...row.getRowProps()} textAlign={'center'}>
              {row.cells.map((cell) => (
                <Td {...cell.getCellProps()} whiteSpace="nowrap">
                  {cell.render('Cell')}
                </Td>
              ))}
            </Tr>
          ) : (
            <Tr
              {...row.getRowProps()}
              textAlign={'center'}
              backgroundColor={bgColor}
            >
              {row.cells.map((cell) => (
                <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
              ))}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}
