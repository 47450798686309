import { createIcon } from '@chakra-ui/react';

export const TagsIcon = createIcon({
  displayName: 'TagsIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H4Zm7.45 3.287A.981.981 0 0 0 10.756 7H7.981A.981.981 0 0 0 7 7.981v2.775c0 .26.103.51.287.694l5.263 5.263a.981.981 0 0 0 1.388 0l2.775-2.775a.981.981 0 0 0 0-1.388L11.45 7.287Z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
