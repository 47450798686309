import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { DetectionPeriodSummary } from './types';

export interface IGetDetectionPeriodSummary {
  getDetectionPeriodSummary: DetectionPeriodSummary;
}

export const GET_DETECTION_PERIOD_SUMMARY = gql`
  query getDetectionPeriodSummary(
    $start: Float!
    $end: Float!
    $prev_start: Float
    $prev_end: Float
    $must_conds: [String]
  ) {
    getDetectionPeriodSummary(
      curr_period: { start: $start, end: $end }
      prev_period: { start: $prev_start, end: $prev_end }
      must_conds: $must_conds
    ) {
      count {
        current
        pct_chg
        previous
      }
      score {
        avg
        max
        min
      }
      total_alert_categories {
        current
        pct_chg
        previous
      }
      total_entities {
        current
        pct_chg
        previous
      }
    }
  }
`;

export const useGetDetectionPeriodSummary = (
  options: QueryHookOptions<IGetDetectionPeriodSummary, OperationVariables>,
) => {
  return useQuery<IGetDetectionPeriodSummary>(GET_DETECTION_PERIOD_SUMMARY, {
    ...options,
  });
};
