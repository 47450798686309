import { createIcon } from '@chakra-ui/react';

export const StatisticsIcon = createIcon({
  displayName: 'StatisticsIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H4Zm9 4h-2v8h2V8Zm-4 2H7v6h2v-6Zm6 2h2v4h-2v-4Z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
