import { Button, Center, Flex, Heading, Text } from '@chakra-ui/react';
import { Illustrations } from '@gamma/icons';
import { ROUTES } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { useGetConfiguredSensorStatus } from '@gamma/investigator/queries';
import { GraphQLReqStatus } from '@gamma/progress';
import { useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

const { home, privacyPolicy } = ROUTES;

interface IOnboard {
  isAdmin?: boolean;
}

export const Onboard = ({ isAdmin }: IOnboard) => {
  const { isOrgTenant } = useContext(AuthContext);

  const navigate = useNavigate();
  const { title, message, cta } = i18n.pages.system;

  const { loading, data, error } = useGetConfiguredSensorStatus({
    fetchPolicy: 'network-only',
  });

  const configured = data?.getConfiguredSensorStatus?.configured;
  const isEulaAccepted = localStorage.getItem('isEulaAccepted');

  if (loading) {
    return (
      <Center>
        <GraphQLReqStatus loading={loading} />
      </Center>
    );
  }

  if (error || configured || isOrgTenant) {
    if (isEulaAccepted == "false"){
      return <Navigate to={privacyPolicy} />;
    }
    return <Navigate to={home} />;
  }

  return (
    <Flex
      data-testid="onboardPage"
      height="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Center mb="64px">
        <Illustrations.TechChecklist />
      </Center>
      <Center mb={4}>
        <Heading textStyle="h3">{title}</Heading>
      </Center>
      <Center mb="40px">
        <Text textStyle="h5" color="gray.200" w="380px" textAlign="center">
          {isAdmin ? message.admin : message.analyst}
        </Text>
      </Center>
      {isAdmin && (
        <Center>
          <Button
            variant="outline"
            onClick={() => navigate('/system/settings/sensor-monitoring')}
          >
            {cta}
          </Button>
        </Center>
      )}
    </Flex>
  );
};
