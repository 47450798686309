import { createIcon } from '@chakra-ui/react';

export const CirclePlayIcon = createIcon({
  displayName: 'CirclePlayIcon',
  viewBox: '0 0 24 24',
  d: 'M12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Zm0-18a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm-2 14.5 6-4.5-6-4.5v9Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
