import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  Grid,
  GridItem,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
  useToken,
} from '@chakra-ui/react';
import { useBreakpointValue } from '@gamma/hooks';
import {
  Children,
  JSXElementConstructor,
  ReactNode,
  cloneElement,
  forwardRef,
  isValidElement,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { Label, LabelProps } from '../Components';

export interface InputProps extends Omit<LabelProps, 'children' | 'htmlFor'> {
  /** The name for the input field */
  name: string;
  /** Default value of input field */
  defaultValue?: ChakraInputProps['defaultValue'];
  /**
   * The value that the input field should have, used in conjunction with
   * onChange to create a controlled input
   */
  value?: ChakraInputProps['value'];
  /** The placeholder text for the input to display when valueless */
  placeholder?: string;
  /** The element to place inside the input field to the left */
  leftElement?: ReactNode;
  leftAddon?: ReactNode;
  /** The element to place inside the input field to the right */
  rightElement?: ReactNode;
  rightAddon?: ReactNode;
  id?: string;
  bg?: string;
  /** The data-testid to use for testing */
  ['data-testid']?: string;
  /** The type of input to render */
  type?: ChakraInputProps['type'];
  /** Whether to focus automatically on render */
  autoFocus?: boolean;
  /** The html input autocomplete value for the input */
  autoComplete?: ChakraInputProps['autoComplete'];
  pattern?: string;
  /** The function that triggers whenever the input value is updated */
  onChange?: ChakraInputProps['onChange'];
  /** The function that triggers whenever the input is focused */
  onFocus?: ChakraInputProps['onFocus'];
  /** The function that triggers whenever the input is blurred */
  onBlur?: ChakraInputProps['onBlur'];
  /** The function that triggers whenever the input is clicked */
  onClick?: ChakraInputProps['onClick'];
  /** The function that triggers whenever a key is pressed */
  onKeyPress?: ChakraInputProps['onKeyPress'];
  /** Node following the Input */
  afterInput?: ReactNode;
  /** Whether to make squiggly underlines under the stuff you mispelled */
  spellCheck?: boolean;
  /** Minimum value for number type inputs */
  min?: string | number;
  /** Maximum value for number type inputs */
  max?: string | number;
  /** Number of intervals in each step */
  step?: string | number;
  /** Size of input */
  size?: ChakraInputProps['size'];
  /** Maximum number of characters allowed */
  maxLength?: number;
  /** The width for the input */
  w?: string | number;
  /** The role for the input */
  role?: ChakraInputProps['role'];
  /** The border for the input */
  border?: ChakraInputProps['border'];
  /** The border color for the input */
  borderColor?: ChakraInputProps['borderColor'];
  /** The outline for the input */
  outline?: ChakraInputProps['outline'];
  /** The outline color for the input */
  outlineColor?: ChakraInputProps['outlineColor'];
  /** The tabIndex for the input */
  tabIndex?: ChakraInputProps['tabIndex'];
  /** The cursor for the input */
  cursor?: ChakraInputProps['cursor'];
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      afterInput,
      leftElement,
      leftAddon,
      rightElement,
      rightAddon,
      defaultValue,
      value,
      placeholder,
      name,
      bg,
      type = 'text',
      autoComplete,
      autoFocus,
      onChange,
      onFocus,
      onBlur,
      onClick,
      onKeyPress,
      'data-testid': dataTestId,
      pattern,
      spellCheck,
      min,
      max,
      step,
      size = 'md',
      w,
      role,
      border,
      borderColor,
      outline,
      outlineColor,
      tabIndex,
      cursor,
      ...rest
    },
    ref,
  ) => {
    const [bgColor] = useToken('colors', [bg || '']);
    const leftEleRef = useRef<HTMLDivElement>(null);
    const rightEleRef = useRef<HTMLDivElement>(null);
    const breakpointSize = useBreakpointValue(size) ?? 'md';

    // padding offsets for input to account for left/right elements
    const [pl, setPl] = useState<string | undefined>(undefined);
    const [pr, setPr] = useState<string | undefined>(undefined);

    // adjust left/right padding offset after initial mount
    useLayoutEffect(() => {
      if (leftElement && leftEleRef.current) {
        setPl(`${leftEleRef.current.offsetWidth}px !important`);
      }
      if (rightElement && rightEleRef.current) {
        setPr(`${rightEleRef.current.offsetWidth}px !important`);
      }
    }, [leftElement, rightElement]);

    return (
      <Label name={name} width="100%" {...rest}>
        <Grid templateColumns="1fr min-content" alignItems="center">
          <GridItem>
            <InputGroup size={size}>
              {leftAddon && <InputLeftAddon>{leftAddon}</InputLeftAddon>}
              {leftElement && (
                <InputLeftElement w="fit-content" px={2} ref={leftEleRef}>
                  {traverseChildrenAndResizeIcons(leftElement, breakpointSize)}
                </InputLeftElement>
              )}
              <ChakraInput
                autoFocus={autoFocus}
                ref={ref}
                defaultValue={defaultValue}
                value={value}
                placeholder={placeholder}
                name={name}
                type={type}
                autoComplete={autoComplete}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                data-testid={dataTestId}
                background={`${bgColor} !important`}
                pattern={pattern}
                spellCheck={spellCheck}
                min={min}
                max={max}
                step={step}
                w={w}
                role={role}
                border={border}
                borderColor={borderColor}
                outline={outline}
                outlineColor={outlineColor}
                tabIndex={tabIndex}
                cursor={cursor}
                onClick={onClick}
                onKeyPress={onKeyPress}
                pl={pl}
                pr={pr}
              />
              {rightElement && (
                <InputRightElement w="fit-content" px={2} ref={rightEleRef}>
                  {traverseChildrenAndResizeIcons(rightElement, breakpointSize)}
                </InputRightElement>
              )}
              {rightAddon && <InputRightAddon>{rightAddon}</InputRightAddon>}
            </InputGroup>
          </GridItem>
          {afterInput && (
            <GridItem pl={3} display="flex">
              {afterInput}
            </GridItem>
          )}
        </Grid>
      </Label>
    );
  },
);

const addonSizeMap = new Map<string, string>([
  ['xs', 'sm'],
  ['sm', 'sm'],
  ['md', 'md'],
  ['lg', 'md'],
]);

// Checks if the given object is a React component and has a displayName prop
const isComponentType = (
  obj: any,
): obj is JSXElementConstructor<any> & { displayName?: string } => {
  return (
    (typeof obj === 'object' || typeof obj === 'function') &&
    typeof obj.displayName === 'string'
  );
};

// Recursively traverses the ReactNode children, resizes icons based on the
// input size, and returns the updated ReactNode structure.
const traverseChildrenAndResizeIcons = (
  children: ReactNode,
  size: string,
): ReactNode => {
  return Children.map(children, (child) => {
    if (isValidElement(child)) {
      if (isComponentType(child.type)) {
        const { children, ...props } = child.props;
        if (child.type.displayName === 'Tooltip') {
          return child;
        } else if (child.type.displayName?.endsWith('Icon')) {
          return cloneElement<any>(child, { size: addonSizeMap.get(size) });
        } else if (child.type.displayName?.endsWith('Button')) {
          return cloneElement(
            child,
            { ...props, size: 'box-xs', color: props.color ?? 'text.primary' },
            children,
          );
        } else {
          const updatedChildren = traverseChildrenAndResizeIcons(
            children,
            size,
          );
          return cloneElement(child, props, updatedChildren);
        }
      }
    }
    return child;
  });
};
