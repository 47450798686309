import { createIcon } from '@chakra-ui/react';

export const ClockIcon = createIcon({
  displayName: 'ClockIcon',
  viewBox: '0 0 24 24',
  d: 'M12 20a8 8 0 1 0 0-16.001A8 8 0 0 0 12 20Zm0-18a10 10 0 1 1 0 20C6.47 22 2 17.5 2 12A10 10 0 0 1 12 2Zm.5 5v5.25l4.5 2.67-.75 1.23L11 13V7h1.5Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
