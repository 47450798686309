import { UserCookieAcceptance } from '@gamma/investigator/queries';
import moment from 'moment';

export const getIsCookieExpired = (
  getUserCookieAcceptance?: UserCookieAcceptance[],
) => {
  const analyticsCookie = getUserCookieAcceptance?.find(
    (cookie) => cookie.cookie_type === 'ANALYTICS',
  );

  const strictlyNecessaryCookie = getUserCookieAcceptance?.find(
    (cookie) => cookie.cookie_type === 'STRICTLY_NECESSARY',
  );

  const now = moment().unix();

  const isCookiePolicyExpired = Boolean(
    (getUserCookieAcceptance && getUserCookieAcceptance?.length <= 1) ||
      strictlyNecessaryCookie?.cookies_accepted === null ||
      (strictlyNecessaryCookie?.cookies_accepted_expiration &&
        strictlyNecessaryCookie?.cookies_accepted_expiration < now),
  );

  return {
    isCookiePolicyExpired,
    strictlyNecessaryCookie,
    analyticsCookie,
  };
};
