import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { Input, OptionType, Select } from '@gamma/form-fields';
import { RefreshWithCircleIcon } from '@gamma/icons';
import { DETECTION_AUTO_CLOSE_DURATION } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import {
  GET_PRIVILEGES,
  useUpdatePrivileges,
} from '@gamma/investigator/queries';
import { Modal } from '@gamma/overlay';
import { ChangeEvent, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { DetectionAutoCloseConfig } from '../DetectionSettingsDashboard';

interface UpdateDetectionAutoCloseConfigProps {
  detectionAutoCloseConfig: Array<DetectionAutoCloseConfig>;
}

const dayOptions: OptionType[] = [
  { value: 7, label: '7 days (default)' },
  { value: 14, label: '14 days' },
  { value: 30, label: '30 days' },
  { value: 60, label: '60 days' },
  { value: 90, label: '90 days' },
  { value: 'custom', label: 'Custom time' },
];

const { updateModal, buttons, days, defaultDays, toast, validationError } =
  i18n.pages.detectionSettings;

export const UpdateDetectionAutoCloseConfig = ({
  detectionAutoCloseConfig,
}: UpdateDetectionAutoCloseConfigProps) => {
  const initialValue = {
    value: detectionAutoCloseConfig[0]?.duration,
    label:
      detectionAutoCloseConfig[0]?.duration ===
      DETECTION_AUTO_CLOSE_DURATION.toString()
        ? i18n.formatString(defaultDays, detectionAutoCloseConfig[0]?.duration)
        : i18n.formatString(days, detectionAutoCloseConfig[0]?.duration),
  };

  const [customInputVisible, setCustomInputVisible] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [isResetDisabled, setIsResetDisabled] = useState<boolean>(
    initialValue.value === DETECTION_AUTO_CLOSE_DURATION.toString(),
  );
  const [updatedValue, setUpdatedValue] = useState<string>('');
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { isDirty },
    setValue,
  } = useForm();
  const { isOpen, onClose, onOpen } = useDisclosure({
    onClose: () => {
      setCustomInputVisible(false);
      reset();
      setShowError(false);
    },
  });
  const showToast = useToast();

  const [updatePrivileges, { loading: updatePrivilegesLoading }] =
    useUpdatePrivileges({
      awaitRefetchQueries: true,
      refetchQueries: [GET_PRIVILEGES],
      onCompleted: async () => {
        onClose();
        showToast({
          title: toast.success.title,
          description: i18n.formatString(
            toast.success.description,
            updatedValue,
          ),
          status: 'success',
          isClosable: true,
          position: 'bottom-right',
        });
      },
      onError: async () => {
        onClose();
        showToast({
          title: toast.error.title,
          description: toast.error.description,
          status: 'error',
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const handleSelectionChange = (event: {
    value: number | string;
    label: string;
  }) => {
    setIsResetDisabled(event.value === DETECTION_AUTO_CLOSE_DURATION);
    setCustomInputVisible(event.value === 'custom');
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    setShowError(value < 7 || value > 90);
  };

  const onSubmit = (formData: FieldValues) => {
    const input = {
      detection_auto_close_duration:
        formData?.detection_auto_close_duration?.value === 'custom'
          ? Number(formData?.custom_input)
          : Number(formData?.detection_auto_close_duration?.value),
    };
    setUpdatedValue(input.detection_auto_close_duration.toString());
    updatePrivileges({ variables: { input } });
  };

  return (
    <>
      <Tooltip label="Edit" placement="top" hasArrow>
        <IconButton
          data-testid="edit-auto-close-detection"
          size="box-md"
          variant="solid"
          colorScheme="gray"
          icon={<MuiIcon color="text.primary">edit</MuiIcon>}
          aria-label="edit auto close detection button"
          onClick={() => {
            onOpen();
          }}
        />
      </Tooltip>
      <Modal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        closeOnEsc={!updatePrivilegesLoading}
        closeOnOverlayClick={!updatePrivilegesLoading}
        isCloseDisabled={updatePrivilegesLoading}
        isCentered
        size="md"
        title={updateModal.title}
        body={
          <VStack alignItems="left">
            <Text mb={6}>{updateModal.body}</Text>
            <HStack alignItems="flex-end" spacing={4}>
              <Box width="-webkit-fill-available">
                <Controller
                  control={control}
                  name="detection_auto_close_duration"
                  render={({ field: { onChange, name, value } }) => {
                    return (
                      <Select
                        name={name}
                        label="Close Detections after"
                        value={value}
                        defaultValue={initialValue}
                        options={dayOptions}
                        onChange={(event) => {
                          onChange(event);
                          handleSelectionChange(event);
                        }}
                        isMenuPortal
                      />
                    );
                  }}
                />
              </Box>
              {customInputVisible && (
                <HStack>
                  <Input
                    label=""
                    width="34px"
                    type="number"
                    {...register('custom_input')}
                    onChange={handleInputChange}
                  ></Input>
                  <Text>{updateModal.days}</Text>
                </HStack>
              )}
            </HStack>
            {!showError && customInputVisible && (
              <Text>{updateModal.customDays}</Text>
            )}
            {showError && <Text color="red">{validationError}</Text>}
          </VStack>
        }
        footer={
          <HStack justifyContent="space-between" width="full">
            <Button
              colorScheme="blue"
              variant="link"
              leftIcon={<RefreshWithCircleIcon boxSize={4} />}
              isDisabled={isResetDisabled || updatePrivilegesLoading}
              onClick={() => {
                setValue(
                  'detection_auto_close_duration',
                  { value: '7', label: '7 days (default)' },
                  { shouldDirty: true },
                );
                setCustomInputVisible(false);
                setIsResetDisabled(true);
              }}
            >
              {buttons.reset}
            </Button>
            <ButtonGroup>
              <Button
                variant="solid"
                colorScheme="gray"
                onClick={onClose}
                isDisabled={updatePrivilegesLoading}
              >
                {buttons.cancel}
              </Button>
              <Button
                variant="solid"
                colorScheme="blue"
                onClick={handleSubmit(onSubmit)}
                isDisabled={updatePrivilegesLoading || !isDirty}
                isLoading={updatePrivilegesLoading}
              >
                {buttons.save}
              </Button>
            </ButtonGroup>
          </HStack>
        }
      />
    </>
  );
};
