import { createIcon } from '@chakra-ui/react';

export const FilterIcon = createIcon({
  displayName: 'FilterIcon',
  viewBox: '0 0 24 24',
  d: 'M6 13h12v-2H6v2ZM3 6v2h18V6H3Zm7 12h4v-2h-4v2Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
