import { createIcon } from '@chakra-ui/react';

export const ChatGPTIcon = createIcon({
  displayName: 'ChatGPTIcon',
  viewBox: '0 0 64 64',
  path: [
    <path
      d="M34.832 40H32.72L31.04 35.056H24.272L22.592 40H20.552L26.408 23.248H28.976L34.832 40ZM30.536 33.28L27.704 25.12H27.584L24.752 33.28H30.536Z"
      fill="#FDDC69"
    />,
    <path
      d="M43.535 40H36.815V38.32H39.167V24.928H36.815V23.248H43.535V24.928H41.183V38.32H43.535V40Z"
      fill="#FDDC69"
    />,
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.3333 10.6666H10.6666V53.3333H53.3333V10.6666ZM48 16H16V48H48V16Z"
      fill="#FDDC69"
    />,
    <rect x="18.6666" y="5.33337" width="5.33333" height="8" fill="#FDDC69" />,
    <rect x="29.3333" y="5.33337" width="5.33333" height="8" fill="#FDDC69" />,
    <rect x="40" y="5.33337" width="5.33333" height="8" fill="#FDDC69" />,
    <rect
      x="58.6666"
      y="18.6666"
      width="5.33333"
      height="8"
      transform="rotate(90 58.6666 18.6666)"
      fill="#FDDC69"
    />,
    <rect
      x="58.6666"
      y="29.3333"
      width="5.33334"
      height="8"
      transform="rotate(90 58.6666 29.3333)"
      fill="#FDDC69"
    />,
    <rect
      x="58.6666"
      y="40"
      width="5.33333"
      height="8"
      transform="rotate(90 58.6666 40)"
      fill="#FDDC69"
    />,
    <rect x="18.6666" y="50.6666" width="5.33333" height="8" fill="#FDDC69" />,
    <rect x="29.3333" y="50.6666" width="5.33333" height="8" fill="#FDDC69" />,
    <rect x="40" y="50.6666" width="5.33333" height="8" fill="#FDDC69" />,
    <rect
      x="13.3334"
      y="18.6666"
      width="5.33333"
      height="8"
      transform="rotate(90 13.3334 18.6666)"
      fill="#FDDC69"
    />,
    <rect
      x="13.3334"
      y="29.3333"
      width="5.33334"
      height="8"
      transform="rotate(90 13.3334 29.3333)"
      fill="#FDDC69"
    />,
    <rect
      x="13.3334"
      y="40"
      width="5.33333"
      height="8"
      transform="rotate(90 13.3334 40)"
      fill="#FDDC69"
    />,
  ],
  defaultProps: {
    height: '64px',
    width: '64px',
    fill: 'none',
    viewBox: '0 0 64 64',
  },
});
