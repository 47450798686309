import { createIcon } from '@chakra-ui/react';

export const DomainIcon = createIcon({
  displayName: 'DomainIcon',
  viewBox: '0 0 24 24',
  d: 'M17.9 17.39c-.26-.8-1.01-1.39-1.9-1.39h-1v-3a1 1 0 0 0-1-1H8v-2h2a1 1 0 0 0 1-1V7h2a2 2 0 0 0 2-2v-.41a7.984 7.984 0 0 1 2.9 12.8ZM11 19.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.22.21-1.79L9 15v1a2 2 0 0 0 2 2v1.93ZM12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
