import { i18n } from '@gamma/investigator/localization';
import {
  CardPageBackButton,
  CardPageHeading,
  CardPageIllustration,
  CardPageSubtitle,
} from '@gamma/pages';
import { useNavigate } from 'react-router';

export const TwoFactorRecovery = () => {
  const navigate = useNavigate();

  const { title, subtitle } = i18n.pages.twoFactorRecovery;

  return (
    <>
      <CardPageBackButton onClick={() => navigate('/login/trouble')} />
      <CardPageHeading>{title}</CardPageHeading>
      <CardPageIllustration
        illustration={'TwoFactorAuth'}
        width="160px"
        height="128px"
      />
      <CardPageSubtitle>{subtitle}</CardPageSubtitle>
    </>
  );
};
