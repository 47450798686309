export const boxPlotTooltipFormat = [
  {
    className: 'title',
  },
  {
    text: 'Actual',
    className: 'actual',
  },
  {
    text: '',
    className: 'delimiter',
  },
  {
    text: 'Mean',
    className: 'mean',
  },
  {
    text: 'Standard Deviation',
    className: 'deviation',
  },
  {
    text: 'Min',
    className: 'min',
  },
  {
    text: 'Max',
    className: 'max',
  },
  {
    text: '25th to 75th Percentile',
    className: 'quarterly',
  },
  {
    text: '5th to 95th Percentile',
    className: 'percentile',
  },
];
