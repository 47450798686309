import { i18n } from '@gamma/investigator/localization';
import { User } from '@gamma/investigator/queries';
import { CognitoUserInterface } from '@gamma/shared/types';

const { controls, detection } = i18n.pages.detections;

const { assignToMe } = controls;
const { unassigned } = detection;

interface filterAssignableUsersArgs {
  user?: CognitoUserInterface;
  users: User[];
}

export const filterAssignableUsers = ({
  user,
  users,
}: filterAssignableUsersArgs) =>
  users?.length > 0
    ? [
        ...users
          .filter((user) => !!user.alias)
          .filter((user) => user.status === 'active'),
        { alias: unassigned, username: '' },
      ]
        .map((investUser) => {
          return {
            label:
              user?.username === investUser?.username
                ? assignToMe
                : investUser?.alias,
            value: investUser?.username,
          };
        })
        .sort((a, b) => a?.label.localeCompare(b?.label))
    : [];
