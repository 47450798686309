import { gql, OperationVariables, QueryHookOptions, useQuery } from '@apollo/client';

import { IQueryDetectionsTotalItems } from './types';

export const QUERY_DETECTIONS_TOTAL_ITEMS = gql`
  query queryDetectionsPaginated(
    $query: String
    $offset: Int
    $size: Int
    $sort: [SortParameterInput]
  ) {
    queryDetectionsPaginated(
      query: $query
      offset: $offset
      size: $size
      sort: $sort
    ) {
      total_items
    }
  }
`;

export const useQueryDetectionsTotalItems = (
  options: QueryHookOptions<IQueryDetectionsTotalItems, OperationVariables>,
) => {
  return useQuery<IQueryDetectionsTotalItems>(
    QUERY_DETECTIONS_TOTAL_ITEMS,
    options,
  );
};
