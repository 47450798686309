import { createIcon } from '@chakra-ui/react';

export const PencilBoxIcon = createIcon({
  displayName: 'PencilBoxIcon',
  viewBox: '0 0 24 24',
  d: 'M5 3c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7h-2v7H5V5h7V3H5Zm12.78 1a.69.69 0 0 0-.48.2l-1.22 1.21 2.5 2.5L19.8 6.7c.26-.26.26-.7 0-.95L18.25 4.2c-.13-.13-.3-.2-.47-.2Zm-2.41 2.12L8 13.5V16h2.5l7.37-7.38-2.5-2.5Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
