import { createIcon } from '@chakra-ui/react';

export const DocsIcon = createIcon({
  displayName: 'DocsIcon',
  viewBox: '0 0 24 24',
  d: 'M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h4.5l2.5 2.5 2.5-2.5H19a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Zm-6 13v2h-2v-2h2ZM9.172 7.172A4 4 0 0 1 16 10c0 1.283-.79 1.973-1.56 2.646C13.712 13.283 13 13.905 13 15h-2c0-1.821.942-2.543 1.77-3.178.65-.498 1.23-.943 1.23-1.822a2 2 0 1 0-4 0H8a4 4 0 0 1 1.172-2.828Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
