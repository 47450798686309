import { Link } from '@chakra-ui/react';
import { i18n } from '@gamma/investigator/localization';
import { NavItem } from '@gamma/navigation';

export const AppFooter = () => {
  return (
    <NavItem pl={0}>
      <Link
        data-testid="privacy-policy"
        href={'https://corelight.com/privacy-policy/'}
        color="text.link"
        isExternal
        textStyle="body-md"
        pl={0}
      >
        {i18n.pages.appFooter.link}
      </Link>
    </NavItem>
  );
};
