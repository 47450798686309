import { createIcon } from '@chakra-ui/react';

export const DownloadIcon = createIcon({
  displayName: 'DownloadIcon',
  viewBox: '0 0 24 24',
  d: 'M5 20h14v-2H5v2ZM19 9h-4V3H9v6H5l7 7 7-7Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
