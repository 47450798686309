import { useQuery } from '@apollo/client';
import {
  GET_FEATURE_METADATA,
  IGetFeatureMetadata,
  ModelSummaryFeature,
} from '@gamma/investigator/queries';

export interface useEntityMLContributionsProps {
  models?: any[];
  pipeline?: string;
}

export const useEntityMLContributions = ({
  models,
  pipeline,
}: useEntityMLContributionsProps) => {
  const alertFeatures = [...(models?.[0]?.features ?? [])]?.sort(
    (a: ModelSummaryFeature, b: ModelSummaryFeature) =>
      b.contribution - a.contribution,
  );

  const {
    loading: featureMetaLoading,
    error: featureMetaError,
    data: featureMetaData,
  } = useQuery<IGetFeatureMetadata>(GET_FEATURE_METADATA, {
    skip: alertFeatures.length === 0,
    variables: {
      input: {
        pipeline,
        feature_names: [
          ...alertFeatures.map((feature) => feature.feature_name),
        ],
      },
    },
  });

  // A temporary merging of data across two APIs
  // will remove this once display_name is available
  // on QueryAlertsPaginated for ML alerts
  // https://jira.corelight.io/browse/INVEST-4318
  const alertFeaturesWithMeta = alertFeatures.map((feature) => ({
    ...featureMetaData?.getFeatureMetadata.find(
      (meta) => meta.name === feature.feature_name && meta,
    ),
    ...feature,
  }));

  return {
    isLoading: featureMetaLoading,
    error: featureMetaError,
    contributions: alertFeaturesWithMeta,
    significantContributions: alertFeaturesWithMeta?.slice(0, 4),
  };
};
