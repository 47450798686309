import {
  Box,
  chakra,
  Flex,
  Heading,
  HStack,
  Text,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import { transparentize } from '@chakra-ui/theme-tools';
import { i18n } from '@gamma/investigator/localization';
import { detailsProps } from '@gamma/investigator/queries';
import { intColor, stringifyAlertType } from '@gamma/investigator/utilities';
import { Panel } from '@gamma/layout';
import { Modal } from '@gamma/overlay';
import { ReactNode, useEffect, useState } from 'react';

import { ChatGPTToolTip } from '../ChatGPT';
import { SeverityScore } from '../SeverityScore';
import { SuricataAlertDetailsLayout } from '../SuricataAlertDetailsLayout';

export interface valueProps {
  alert_type?: string;
  alert_name?: string;
  category?: string;
  severity?: string;
  cve_reference?: string;
  alert_severity?: number;
  signature_id?: string;
  message?: string;
  description?: string;
  custom_severity?: number;
}

interface detailsPropsExtended extends Omit<detailsProps, 'input'> {
  input?: string | string[];
}

interface AlertDetailsModalProps {
  value?: valueProps;
  children: ReactNode;
  isGPTEnabled?: boolean;
}

export const SuricataAlertDetailsModal = ({
  value,
  children,
  isGPTEnabled,
}: AlertDetailsModalProps) => {
  const [content, setContent] = useState(value);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const theme = useTheme();
  const scoreColor = intColor(content?.alert_severity || 0);
  const severityBgColor = transparentize(scoreColor, 0.2)(theme);
  const suricataSummary =
    i18n.pages.alertDetails.suricata.summary.cards.alertSummary;

  useEffect(() => {
    if (value) {
      setContent(value);
    }
  }, [value]);

  const details: detailsPropsExtended[] = [
    {
      label: suricataSummary?.type,
      input: stringifyAlertType(content?.alert_type),
    },
    {
      label: suricataSummary.category,
      input: content?.category,
    },
    {
      label: suricataSummary.signatureId,
      input: content?.signature_id,
    },
    {
      label: suricataSummary.cve_reference,
      input: content?.cve_reference,
    },
    { label: suricataSummary.message, input: content?.message },
  ];

  const descriptionLabelDetails = {
    label: suricataSummary.description,
    input: content?.description,
    lineBreak: [true, true],
    truncate: false,
  };

  const scoreToShow = content?.alert_severity as number;
  const isCustom = content?.custom_severity;

  return (
    <Modal
      title={suricataSummary.heading}
      data-testid="suricata-alert-details-modal"
      size={'xl'}
      isCentered={true}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      body={
        <Panel layerStyle="second">
          <Flex
            alignItems="center"
            mt={
              content?.alert_severity ||
              (content?.message && content?.alert_type)
                ? 3
                : ''
            }
            mb={3}
          >
            {true && (
              <Box mr={2}>
                <SeverityScore
                  score={scoreToShow}
                  size="xl"
                  isCustom={Boolean(isCustom)}
                />
              </Box>
            )}
            {content?.alert_name && content?.alert_type && (
              <Heading textStyle="h4" wordBreak="break-word" maxW={'50%'}>
                {content?.alert_name}
              </Heading>
            )}
          </Flex>
          <Flex>
            <Flex mr={4} flexDir="column" flex="1 1 66%">
              <HStack mb={2}>
                {isGPTEnabled && <ChatGPTToolTip />}
                <chakra.span>
                  {i18n.pages.integrations.chatGPT.description}:
                </chakra.span>
              </HStack>
              <Text textStyle="body-md">{descriptionLabelDetails?.input}</Text>
            </Flex>
            <Flex flexDir="column" flex="1 1 34%">
              {details?.map((detail, index) => {
                if (detail.label === suricataSummary.description) {
                  return null;
                }
                return (
                  <SuricataAlertDetailsLayout
                    isInModal={true}
                    key={index}
                    label={detail.label}
                    input={detail.input}
                    options={{
                      lineBreak: detail.lineBreak,
                      truncate: detail.truncate,
                    }}
                  />
                );
              })}
            </Flex>
          </Flex>
        </Panel>
      }
    >
      {children}
    </Modal>
  );
};
