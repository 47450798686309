import { CookieContext } from '@gamma/investigator/context';
import { UserCookieAcceptance } from '@gamma/investigator/queries';
import { useContext } from 'react';

export const useGetHasUserAcceptedAnalytics = () => {
  const { getUserCookieAcceptance } = useContext(CookieContext);

  const analyticsCookie: UserCookieAcceptance = getUserCookieAcceptance?.filter(
    (cookie) => cookie.cookie_type === 'ANALYTICS',
  )?.[0];

  return () =>
    Boolean(
      analyticsCookie?.cookies_accepted_expiration &&
        analyticsCookie?.cookies_accepted,
    );
};
