import { ComponentStyleConfig } from '@chakra-ui/react';
import { bodyStyles } from '../../common';

export const SidebarStyles: ComponentStyleConfig = {
  parts: ['container', 'body', 'toggleButton', 'toggleIcon'],
  baseStyle: (props) => ({
    container: {
      position: 'sticky',
      top: '0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: '0 0',
      flexBasis: props.expanded ? '240px' : '48px',
      zIndex: 1,
      overflow: 'hidden',
      transition: 'ease-out flex-basis 300ms',
      borderRight: 'solid 1px',
      borderColor: 'border.layer.1',
      bg: 'layer.1',
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,
      py: 2,
    },
    toggleButton: {
      display: 'flex',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      justifyContent: 'start',
      alignItems: 'center',
      border: 'none',
      borderRadius: 'none',
      w: 'full',
      py: 3,
      px: 3,
      bg: 'layer.2',
      textAlign: 'left',
      color: 'text.secondary',
      ...bodyStyles['body-md'],
      letterSpacing: 'normal',
      _hover: {
        bg: 'layer.3',
      },
    },
    toggleIcon: {
      transition: 'ease-in-out transform 0.2s',
      transform: props.expanded ? 'rotate(0deg)' : 'rotate(180deg)',
      color: 'inherit',
      h: 6,
      w: 6,
    },
  }),
  defaultProps: {
    expanded: true,
  },
};
