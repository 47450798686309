import { Icon, useTheme } from '@chakra-ui/react';
import { ComponentProps } from 'react';

export const AnalystIcon = ({ css, ...rest }: ComponentProps<typeof Icon>) => {
  const theme = useTheme();
  return (
    <Icon viewBox="0 0 16 16" height="16px" width="16px" {...rest}>
      {' '}
      <path
        fill="currentColor"
        d="M6.667 2a2.667 2.667 0 1 0 0 5.333 2.667 2.667 0 0 0 0-5.333Zm0 6.667c-2.947 0-5.334 1.193-5.334 2.666v1.334h6.334A4.334 4.334 0 0 1 7.333 11c.003-.79.222-1.565.634-2.24-.42-.053-.847-.093-1.3-.093Z"
      />
      <path
        fill="#43BF79"
        d="M11.167 8.02a3.008 3.008 0 0 0-2.5 2.968c0 .798.316 1.565.878 2.13a2.994 2.994 0 0 0 4.243 0 3.02 3.02 0 0 0-.001-4.26l-.705.709a2.013 2.013 0 0 1 0 2.841 1.996 1.996 0 0 1-2.83 0 2.007 2.007 0 0 1 .914-3.358v1.074c-.3.176-.5.492-.5.864a1.006 1.006 0 0 0 1 1.004.998.998 0 0 0 1-1.005c0-.371-.2-.692-.5-.863V8.02c-.5-.045-1 0-1 0Z"
      />
    </Icon>
  );
};
