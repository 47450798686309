import { ListItem, ListItemProps } from '@chakra-ui/react';

export type NavItemProps = ListItemProps;

export const NavItem = ({ children, ...rest }: NavItemProps) => {
  return (
    <ListItem display="inline-block" px="2" textStyle="body-md" {...rest}>
      {children}
    </ListItem>
  );
};
