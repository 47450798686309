import { Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { USER_STATUS } from '@gamma/investigator/constants';
import { User, UserStatus } from '@gamma/investigator/queries';
import { Alert } from '@gamma/overlay';
import { Row } from 'react-table';

interface Message {
  user: string;
  users: string;
}
interface BulkStatusModalBody {
  users: Row<User>[];
  status: UserStatus;
  message?: Message;
}

export const BulkStatusModalBody = ({
  users,
  message,
  status,
}: BulkStatusModalBody) => {
  const { active, invited } = USER_STATUS;

  const isActivatingInvitedUsers =
    status === active &&
    users.some(({ original }) => original.status === invited);

  const statusIsActive = status === active;

  const noUpdateUsers = users.filter(({ original }) => {
    const statusMatches = original.status === status;
    const statusIsInvited = statusIsActive && original.status === invited;

    return statusMatches || statusIsInvited;
  });

  const bgColor = useColorModeValue('gray.300', 'gray.800');
  const borderColor = useColorModeValue('gray.100', 'gray.600');

  if (noUpdateUsers.length === 0) {
    return null;
  }

  return (
    <Stack spacing={4}>
      {isActivatingInvitedUsers && (
        <Alert status="warning">
          <Text>
            <b>Invited</b> status <b>cannot be changed</b> to active
          </Text>
        </Alert>
      )}
      <Stack>
        <Text>{noUpdateUsers.length > 1 ? message?.users : message?.user}</Text>
      </Stack>
      <Stack maxH={300} overflow="auto">
        {noUpdateUsers.map(({ original }, usrIndex: number) => {
          return (
            <Text
              px={4}
              py={2}
              border="1px"
              borderRadius={6}
              bgColor={bgColor}
              borderColor={borderColor}
              key={usrIndex}
            >
              {original.username}
            </Text>
          );
        })}
      </Stack>
    </Stack>
  );
};
