import {
  Box,
  Flex,
  IconButton,
  IconButtonProps,
  Text,
  Tooltip,
  TooltipProps,
} from '@chakra-ui/react';
import { Size } from 'chakra-react-select';
import moment, { unitOfTime } from 'moment';
import { useEffect, useMemo, useState } from 'react';

import { LabelProps } from '../Components';
import { DatePickerProps } from '../DatePicker';
import { DateRangePicker } from '../DateRangePicker';
import { OptionType, SelectOnChange } from '../hooks';
import { Select } from '../Select';

export type DateSelectOption = [number, unitOfTime.DurationConstructor];
export interface DateSelectProps
  extends Omit<LabelProps, 'htmlFor' | 'children' | 'width'> {
  /**
   * A 2D array of time ranges that the date select will use to generate the
   * select options
   */
  options: DateSelectOption[];
  /** Flag for specifying if there should be a custom range option */
  allowCustom?: boolean;
  /** the initial time range that is selected */
  defaultValue?: DateSelectOption | 'custom';
  /** The values to use when "custom" is selected */
  customDates?: Date[];
  /** the props to pass to the custom datePicker */
  pickerProps?: Partial<DatePickerProps>;
  /** The string to use as a placeholder for the select */
  placeholder?: string;
  orientation?: 'vertical' | 'horizontal';
  /** The function that gets triggered whenever a new date range is selected */
  onChange?: (
    startDate: Date,
    endDate: Date,
    option: DateSelectOption | 'custom',
  ) => void;
  /** Background color */
  bg?: string;
  /** The props that set the date select button configuration options that sets the props on the icon button and tool-tip components */
  dateSelectButtonConfig?: {
    toolTipProps: Omit<TooltipProps, 'children'>;
    iconButtonProps: IconButtonProps;
  };
  /** The prop to set the lapsed time after date in the select value */
  lapsedTimeDate?: number;
  size?: Size;
}

const createDateSelectOption = (
  option: DateSelectOption | 'custom',
  lapsedTimeDate?: number,
) => {
  if (option === 'custom') {
    return { label: 'Custom', value: 'custom' };
  } else {
    const [amount, type] = option;

    return {
      option,
      label: (
        <Flex alignItems="center">
          <Text>
            Last {amount} {type}
          </Text>
          {lapsedTimeDate && (
            <Text
              ml={1}
              textStyle="body-sm"
              color="text.secondary"
              fontWeight="light"
            >
              ({moment(lapsedTimeDate).fromNow()})
            </Text>
          )}
        </Flex>
      ),
      value: moment()
        .millisecond(0) // set millisecond to zero so our tests don't fail because of a ~100 ms difference
        .subtract(amount, type)
        .toDate()
        .toISOString(),
    };
  }
};

export const DateSelect = ({
  options,
  allowCustom,
  pickerProps,
  customDates,
  defaultValue,
  lapsedTimeDate,
  dateSelectButtonConfig,
  orientation = 'horizontal',
  onChange,
  bg,
  size,
  ...rest
}: DateSelectProps) => {
  const [datePickerVisible, setDatePickerVisible] = useState<boolean>(false);
  const [selectedDates, setSelectedDates] = useState<Date[]>(customDates ?? []);
  const isHorizontal = useMemo(() => {
    return orientation === 'horizontal';
  }, [orientation]);

  const generatedOptions = options.map((option) =>
    createDateSelectOption(option),
  );

  if (allowCustom) {
    generatedOptions.unshift({ label: 'Custom', value: 'custom' });
  }

  const handleSelectionChange: SelectOnChange<false, OptionType> = (
    value: OptionType | null,
  ) => {
    if (value?.value === 'custom') {
      setDatePickerVisible(true);
    } else {
      setDatePickerVisible(false);
      setSelectedDates([]);
      onChange?.(
        moment(value?.value as string | number).toDate(),
        moment().millisecond(0).toDate(),
        value?.option,
      );
    }
  };

  const handleDateChange = (dates: Date[]) => {
    onChange?.(
      dates[0],
      // include all of end date's day
      moment(dates[1]).hour(23).minute(59).second(59).toDate(),
      'custom',
    );
    setSelectedDates(dates);
  };

  useEffect(() => {
    if (defaultValue === 'custom') {
      setDatePickerVisible(true);
    }
  }, []);

  useEffect(() => {
    if (customDates) {
      setSelectedDates(customDates);
    }
  }, [customDates]);

  return (
    <Flex
      w="full"
      maxW="100%"
      direction={['column', isHorizontal ? 'row' : 'column']}
      data-testid="date-select-wrapper"
      alignItems={'flex-end'}
    >
      <Box
        minW="200px"
        w={['100%', 'auto']}
        display={lapsedTimeDate ? 'flex' : undefined}
      >
        {!datePickerVisible && dateSelectButtonConfig && (
          <Tooltip {...dateSelectButtonConfig?.toolTipProps}>
            <IconButton
              data-testid="date-select-button"
              {...dateSelectButtonConfig?.iconButtonProps}
              alignSelf={!rest.isLabelHidden ? 'flex-end' : undefined}
            />
          </Tooltip>
        )}
        <Select
          data-testid="date-select"
          {...(lapsedTimeDate && {
            styles: {
              control: () => ({
                borderRadius: '0px 4px 4px 0px',
                width: '265px',
              }),
            },
          })}
          defaultValue={
            defaultValue ? createDateSelectOption(defaultValue) : undefined
          }
          {...(lapsedTimeDate && {
            value: defaultValue
              ? createDateSelectOption(defaultValue, lapsedTimeDate)
              : undefined,
          })}
          options={generatedOptions}
          onChange={handleSelectionChange}
          bg={bg ?? 'layer.1'}
          isSearchable={false}
          isMulti={false}
          isMenuPortal
          size={size}
          {...rest}
        />
      </Box>
      {datePickerVisible && (
        <Box
          flex="1 0 auto"
          alignSelf={'flex-end'}
          ml={isHorizontal ? 3 : 0}
          mt={isHorizontal ? 0 : 3}
          w={['100%', !isHorizontal ? '100%' : 'auto']}
        >
          <DateRangePicker
            name="dateRangePicker"
            label="Select a Date Range"
            bg={bg ?? 'layer.1'}
            isLabelHidden
            selectedDates={selectedDates}
            onDateChange={handleDateChange}
            orientation={orientation}
            size={size}
            {...pickerProps}
          />
        </Box>
      )}
    </Flex>
  );
};
