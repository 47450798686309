import { Flex, FlexProps, StyleProps, useStyles } from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface CardPageBodyProps extends FlexProps, StyleProps {
  children: ReactNode;
}

export const CardPageBody = ({ children, ...rest }: CardPageBodyProps) => {
  const styles = useStyles();
  return (
    <Flex __css={styles.body} {...rest}>
      {children}
    </Flex>
  );
};
