import { ThemeComponents } from '@chakra-ui/react';

import {
  badgeStyles,
  dataListStyles,
  dataTableStyles,
  tableStyles,
  tagStyles,
} from './DataVis';
import {
  ButtonStyles,
  CheckboxStyles,
  FileUploadBoxStyles,
  FormControlStyles,
  FormErrorStyles,
  FormLabelStyles,
  InputStyles,
  NumberInputStyles,
  RadioStyles,
  SelectStyles,
  SwitchStyles,
  TextareaStyles,
  closeButtonStyles,
} from './Forms';
import { HeadingStyles } from './Heading.style';
import {
  DividerStyles,
  TabStyles,
  accordionStyles,
  pageHeaderStyles,
} from './Layout';
import { MuiIconStyles } from './MuiIcon.style';
import {
  SidebarItemStyles,
  SidebarStyles,
  breadcrumbStyles,
} from './Navigation';
import {
  AlertStyles,
  DrawerStyles,
  MenuStyles,
  ModalStyles,
  TooltipStyles,
} from './Overlay';
import { cardPageStyles, splitPageStyles } from './Pages';
import { PopoverStyles } from './Popover.style';
import { ProgressStyles } from './Progress';

export const components: ThemeComponents = {
  Accordion: accordionStyles,
  Alert: AlertStyles,
  Breadcrumb: breadcrumbStyles,
  Badge: badgeStyles,
  Button: ButtonStyles,
  CardPage: cardPageStyles,
  SplitPage: splitPageStyles,
  Checkbox: CheckboxStyles,
  DataTable: dataTableStyles,
  DataList: dataListStyles,
  Divider: DividerStyles,
  Drawer: DrawerStyles,
  Form: FormControlStyles,
  FormError: FormErrorStyles,
  FormLabel: FormLabelStyles,
  Heading: HeadingStyles,
  Input: InputStyles,
  Menu: MenuStyles,
  Modal: ModalStyles,
  NumberInput: NumberInputStyles,
  Popover: PopoverStyles,
  Progress: ProgressStyles,
  Radio: RadioStyles,
  Select: SelectStyles,
  Sidebar: SidebarStyles,
  SidebarItem: SidebarItemStyles,
  Switch: SwitchStyles,
  Tabs: TabStyles,
  Table: tableStyles,
  Tag: tagStyles,
  Textarea: TextareaStyles,
  Tooltip: TooltipStyles,
  CloseButton: closeButtonStyles,
  PageHeader: pageHeaderStyles,
  MuiIcon: MuiIconStyles,
  FileUploadBox: FileUploadBoxStyles,
};
