import { Column, Panel } from '@gamma/layout';
import { LicenseTypeIllustration } from '@gamma/shared/license-type-illustration';
import { ReactNode } from 'react';

interface LicenseStatusProps {
  selected: boolean;
  icon: ReactNode;
  title: string;
  bordered?: boolean;
}
export const LicenseStatus = ({
  selected = false,
  icon,
  title,
  bordered = true,
}: LicenseStatusProps) => {
  return (
    <Column
      col={{ base: 12, xl: 4.1 }}
      data-testid={selected ? 'license-status-selected' : 'license-status'}
    >
      <Panel
        layerStyle={bordered ? (selected ? 'third' : 'second') : undefined}
        minH={'144px'}
        borderColor={selected ? 'state.selected' : undefined}
        borderWidth={selected ? '2px' : undefined}
        opacity={selected ? 1 : 0.6}
      >
        <LicenseTypeIllustration icon={icon} title={title} />
      </Panel>
    </Column>
  );
};
