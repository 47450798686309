import {
  Badge,
  Box,
  Center,
  HStack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Illustrations } from '@gamma/icons';
import { i18n } from '@gamma/investigator/localization';
import { LicenseInfo } from '@gamma/investigator/queries';
import { Board, Column, Panel, PanelHeader } from '@gamma/layout';

import { MuiIcon } from '@gamma/display';
import { licenseType } from '@gamma/investigator/constants';
import { LicenseComparisonModal } from '../LicenseComparisonModal/LicenseComparisonModal';
import { LicenseStatus } from '../LicenseStatus/LicenseStatus';

interface LicenseOverviewProps {
  expirationMeta: {
    daysLeft: number;
    color: string;
    status: string;
    outputLabel: string;
  };
  data?: {
    getLicenseInfo: LicenseInfo;
  };
}

export const LicenseOverview = ({
  expirationMeta,
  data,
}: LicenseOverviewProps) => {
  const { licenseStatus } = i18n.pages.licenseDashboard;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { ADVANCED_EVAL, ADVANCED } = licenseType;

  const {
    license_type = '',
    retention_days,
    gbps,
  } = data?.getLicenseInfo ?? {};
  const { days } = licenseStatus.compareModal;
  const {
    title: statusTitle,
    typesLabel,
    logRetentionLabel,
    gbpsLabel,
    advanced,
    advancedEval,
    expiring,
    inDays,
  } = licenseStatus;

  return (
    <Column col={{ base: 12, lg: 6 }}>
      <Panel layerStyle="second">
        <PanelHeader>
          <HStack>
            <Box
              bg={`${expirationMeta.color}.600`}
              borderRadius="base"
              boxSize={8}
            >
              <Center h="full">
                <MuiIcon color="white">workspace_premium</MuiIcon>
              </Center>
            </Box>
            <Text textStyle="h5">{statusTitle}</Text>
          </HStack>
          <Badge colorScheme={expirationMeta.color} variant="solid">
            {expirationMeta.outputLabel}
            {expirationMeta.outputLabel === expiring &&
              inDays + expirationMeta.daysLeft + ' ' + days}
          </Badge>
        </PanelHeader>
        <Board>
          <Column col={{ base: 12, xl: 2 }} alignSelf="flex-start">
            <Text textStyle={'body-md'}>{typesLabel}</Text>
          </Column>

          <LicenseStatus
            icon={<Illustrations.AdvancedEvalLicenseStatus />}
            selected={license_type === ADVANCED_EVAL}
            title={advancedEval}
          />

          <LicenseStatus
            icon={<Illustrations.AdvancedLicenseStatus />}
            selected={license_type === ADVANCED}
            title={advanced}
          />

          {retention_days && (
            <>
              <Column col={{ base: 12, xl: 2 }}>
                <Center justifyContent="start" h="full">
                  <Text textStyle={'body-md'} whiteSpace={'nowrap'}>
                    {logRetentionLabel}
                  </Text>
                </Center>
              </Column>
              <Column col={{ base: 12, xl: 3 }}>
                <Panel layerStyle="third" h="auto">
                  <Center>
                    <Text textStyle={'h5'}>
                      {retention_days} {days}
                    </Text>
                  </Center>
                </Panel>
              </Column>
            </>
          )}
          {gbps && (
            <>
              <Column col={{ base: 12, xl: 1 }}>
                <Center justifyContent="start" h="full">
                  <Text textStyle={'body-md'} whiteSpace={'nowrap'}>
                    {gbpsLabel}
                  </Text>
                </Center>
              </Column>
              <Column col={{ base: 12, xl: 3 }}>
                <Panel layerStyle="third" h="auto">
                  <Center>
                    <Text textStyle={'h5'}>{gbps}</Text>
                  </Center>
                </Panel>
              </Column>
            </>
          )}
        </Board>
        <Column>
          <LicenseComparisonModal
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
          />
        </Column>
      </Panel>
    </Column>
  );
};
