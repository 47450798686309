import { Button, ButtonGroup, Flex, Text, VStack } from '@chakra-ui/react';
import { Switch } from '@gamma/form-fields';
import { AuthContext, CookieContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { useUserUpdateCookieStatus } from '@gamma/investigator/queries';
import { Modal } from '@gamma/overlay';
import { Dispatch, SetStateAction, useState } from 'react';

const { integrations } = i18n.pages;
const { cancel } = integrations;

const {
  pleaseEnableAnalyticsCookies,
  allInformationColletedByTheseCookies,
  analyticalCookies,
  confirmAndRefresh,
  enableCookiesForAccess,
} = integrations.chatGPT;

export const EnableCookiesModal = ({
  isOpen,
  onClose,
  updateURLParams,
  setPendoGuideType,
  pendoGuideType,
}: {
  isOpen?: boolean;
  onClose?: () => void;
  updateURLParams?: (type: string) => void;
  setPendoGuideType?: Dispatch<SetStateAction<string>>;
  pendoGuideType?: string;
}) => {
  const [userUpdateCookieStatus, { loading: userUpdateCookieStatusLoading }] =
    useUserUpdateCookieStatus();

  const [shouldEnableAnalyticsCookies, setShouldEnableAnalyticsCookies] =
    useState(false);

  const enableAnalyticsCookiesButton = async () => {
    await userUpdateCookieStatus({
      variables: {
        updates: [
          {
            cookie_type: 'ANALYTICS',
            cookie_status: 'ACCEPTED',
          },
        ],
      },
      onCompleted: () => {
        updateURLParams?.(pendoGuideType ?? '');
        setPendoGuideType?.('');
        window.location.reload();
        onClose?.();
      },
    });
  };

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      isCloseDisabled={userUpdateCookieStatusLoading}
      title={enableCookiesForAccess}
      data-testid="enable-cookies-modal"
      body={
        <VStack alignItems="flex-start" spacing={4}>
          <Text
            data-testid="please-enable-analytics-cookies-text"
            textStyle="body-md"
          >
            {pleaseEnableAnalyticsCookies}
          </Text>
          <Text
            data-testid="all-information-collected-by-these-cookies-text"
            textStyle="body-md"
          >
            {allInformationColletedByTheseCookies}
          </Text>
          <Flex alignItems="center">
            <Switch
              label={analyticalCookies}
              isLabelHidden={true}
              name="enableAnalyticalCookies"
              onChange={(e) =>
                setShouldEnableAnalyticsCookies(e.target.checked)
              }
              defaultChecked={shouldEnableAnalyticsCookies}
            />
            <Text ml={2} textStyle="body-md">
              {analyticalCookies}
            </Text>
          </Flex>
        </VStack>
      }
      footer={
        <ButtonGroup isDisabled={userUpdateCookieStatusLoading}>
          <Button onClick={onClose} variant="solid" colorScheme="gray">
            {cancel}
          </Button>
          <Button
            isDisabled={!shouldEnableAnalyticsCookies}
            isLoading={userUpdateCookieStatusLoading}
            onClick={enableAnalyticsCookiesButton}
            variant="solid"
            data-testid="confirm-enable-cookies-button"
          >
            {confirmAndRefresh}
          </Button>
        </ButtonGroup>
      }
    />
  );
};
