import { gql, MutationHookOptions, OperationVariables, useMutation } from '@apollo/client';

export const SAVE_EXPORTER = gql`
  mutation saveExporter($input: ExporterInput!) {
    saveExporter(input: $input) {
      name
      enabled
      exporter
      exporter_id

      ... on SplunkHecLogsExporter {
        hec_url
        index
        tenant
        token
        tls {
          verify_certificate
        }
      }

      ... on ElasticsearchExporter {
        endpoint
        tls {
          verify_certificate
        }
        auth {
          # these may be added in the future
          # access_key_id
          # assume_role
          # profile
          # region
          # secret_access_key
          # strategy
          password
          user
        }
        bulk {
          index
        }
      }

      ... on HttpExporter {
        uri
        http_auth: auth {
          basic {
            username
            password
          }
          bearer {
            token
          }
          strategy
        }
        tls {
          verify_certificate
        }
        headers {
          name
          value
        }
      }

      ... on LogscaleExporter {
        tenant
        url
        token
        tls {
          verify_certificate
        }
        index
        __typename
      }
    }
  }
`;

export const useSaveExporter = (
  options: MutationHookOptions<any, OperationVariables>,
) => {
  return useMutation(SAVE_EXPORTER, options);
};
