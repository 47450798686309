import { ApolloError } from '@apollo/client';
import { Badge, Box, Center, Text, Tooltip } from '@chakra-ui/react';
import { DataTable } from '@gamma/data-table';
import { i18n } from '@gamma/investigator/localization';
import { Panel } from '@gamma/layout';
import { GraphQLReqStatus } from '@gamma/progress';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useContext, useMemo } from 'react';
import { Column, SortingRule } from 'react-table';

import { AuthContext } from '@gamma/investigator/context';
import { Logs } from '../SecurityAudit';

const { columnHeaders } = i18n.pages.securityAudit;

type SortBy = SortingRule<Logs>[];

interface SecurityAuditTableProps {
  loading: boolean;
  error: ApolloError | undefined;
  logs: Logs[];
  pageCount: number;
  pageIndex: number;
  sortBy: SortBy;
  onFetchData: (pageIndex: number, pageSize: number, sortBy: SortBy) => void;
  pageSize: number;
  itemCount: number;
}

const ExpandedRow = ({ original }: { original: Logs }) => {
  return (
    <Text as={'code'} fontSize="xs" whiteSpace="pre-wrap" pl="250">
      {JSON.stringify(original, null, 2)}
    </Text>
  );
};

export const SecurityAuditTable = ({
  loading,
  error,
  logs,
  pageCount,
  pageIndex,
  sortBy,
  onFetchData,
  pageSize,
  itemCount,
}: SecurityAuditTableProps) => {
  const { text } = i18n.pages.securityAudit;
  const { isOrgTenant } = useContext(AuthContext);
  const hiddenCols = isOrgTenant ? [] : ['tenant_display_name'];

  const dataTableColumns: Column<Logs>[] = useMemo(
    () => [
      {
        Header: columnHeaders.timestamp,
        accessor: 'timestamp',
        width: 250,
        Cell: (props) => (
          <Text>{moment.unix(props.value).utc().format('lll')}</Text>
        ),
      },
      {
        Header: columnHeaders.author,
        accessor: 'user_id',
        width: 350,
        Cell: (props) => (
          <>
            {_.includes(props.value, ' ')
              ? _.startCase(props.value)
              : props.value}
          </>
        ),
      },
      {
        Header: columnHeaders.category,
        accessor: 'event_category',
        Cell: (props) => (
          <>{_.capitalize(_.split(_.toLower(props.value), '_')[0])}</>
        ),
        width: 200,
      },
      {
        Header: columnHeaders.type,
        accessor: 'audit_type',
        Cell: (props) => <>{_.capitalize(props.value)}</>,
        width: 200,
      },
      {
        Header: columnHeaders.tenant,
        accessor: 'tenant_display_name',
        Cell: (props) => (
          <Badge variant="outline" fontSize="0.8em" colorScheme="gray">
            {props.value}
          </Badge>
        ),
        width: 200,
      },
      {
        Header: columnHeaders.activity,
        accessor: 'message',
        disableSortBy: true,
        width: 450,
        Cell: (props) => (
          <Tooltip label={props.value} placement="top">
            <Text isTruncated>{props.value}</Text>
          </Tooltip>
        ),
      },
    ],
    [],
  );

  if (loading || error) {
    return (
      <Panel>
        <GraphQLReqStatus loading={loading} error={error} />
      </Panel>
    );
  }

  return (
    <Box>
      {!logs?.length ? (
        <Panel>
          <Center>
            <Text>{text.noLogs}</Text>
          </Center>
        </Panel>
      ) : (
        <DataTable
          data-testid="security-audit-table"
          isExpandable
          renderExpandedRow={ExpandedRow}
          columns={dataTableColumns}
          data={logs}
          pageCount={pageCount}
          autoResetPage={false}
          autoResetSortBy={false}
          isPaginationManual={true}
          initialState={{
            pageIndex,
            pageSize,
            sortBy,
            hiddenColumns: hiddenCols,
          }}
          onFetchData={onFetchData}
          itemCount={itemCount}
        />
      )}
    </Box>
  );
};
