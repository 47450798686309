import { IconButton, Popover, PopoverTrigger, Portal, Tooltip, useBoolean } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { i18n } from '@gamma/investigator/localization';
import { AlertEntity, Detection, useQueryAlertMetadataExcludedEntitiesPaginated } from '@gamma/investigator/queries';
import { GraphQLReqStatus } from '@gamma/progress';
import { useMemo } from 'react';

import { SuppressEntityContent } from '../DetectionActions';

const { suppress, unSuppress, suppressed, unSuppressed } =
  i18n.pages.entityDetails.excludeEntity;

interface DetectionEntityPopoverProps {
  refetchMain?: boolean;
  detectionInfo: Detection;
  isEntityHovered: boolean;
  isEntitySuppressed: boolean;
  entityInfo?: Pick<AlertEntity, 'entity_name' | 'entity_type'>;
  setIsEntitySupressed: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DetectionEntityPopover = ({
  entityInfo,
  refetchMain,
  detectionInfo,
  isEntityHovered,
  isEntitySuppressed,
  setIsEntitySupressed,
}: DetectionEntityPopoverProps) => {
  const [isEditing, setIsEditing] = useBoolean();

  const { alert_entity } = detectionInfo;
  const { entity_name, entity_type } = entityInfo || alert_entity;

  const queryVariables = useMemo(() => {
    return {
      query:
        entity_type === 'DOMAIN'
          ? `{"query": {"bool": {"must": [{"match": {"content_id": "${
              detectionInfo?.alert_info?.content_id
            }"}}, {"term": {"entity_name.keyword": "${entity_name}"}}, {"term": {"entity_type": "${entity_type}"}}${
              detectionInfo?.tenant_info?.tenant_id ? ',' : ''
            }${
              detectionInfo?.tenant_info?.tenant_id
                ? `{"terms":{"tenant":["${detectionInfo?.tenant_info?.tenant_id}"]}}`
                : ''
            }]}}}`
          : `{"query": {"bool": {"must": [{"match": {"content_id": "${
              detectionInfo?.alert_info?.content_id
            }"}}, {"term": {"entity_type": "${entity_type}"}}, {"bool": {"should": [{"term": {"entity_ip": "${entity_name}"}}, {"range": {"entity_ip_range": {"from": "${entity_name}", "to": "${entity_name}"}}}]}}${
              detectionInfo?.tenant_info?.tenant_id ? ',' : ''
            }${
              detectionInfo?.tenant_info?.tenant_id
                ? `{"terms":{"tenant":["${detectionInfo?.tenant_info?.tenant_id}"]}}`
                : ''
            }]}}}`,
      offset: 0,
      size: 10,
      sort: [
        {
          sort_by: 'entity_name.keyword',
          sort_dir: 'desc',
        },
      ],
    };
  }, [detectionInfo, entity_name, entity_type]);

  const {
    loading: excludedEntitiesLoading,
    data: excludedEntitiesData,
    error: excludedEntitiesError,
  } = useQueryAlertMetadataExcludedEntitiesPaginated({
    skip:
      !entity_name ||
      !entity_type ||
      !detectionInfo?.tenant_info?.tenant_id ||
      !detectionInfo?.alert_info?.content_id,
    variables: queryVariables,
  });

  if (excludedEntitiesError) {
    return (
      <GraphQLReqStatus
        loading={false}
        isBackground={true}
        error={excludedEntitiesError}
      ></GraphQLReqStatus>
    );
  }

  return (
    <Popover
      closeOnEsc={true}
      closeOnBlur={true}
      offset={[-145, 10]}
      isOpen={isEditing}
      onOpen={setIsEditing.on}
      onClose={setIsEditing.off}
    >
      <>
        {isEditing || isEntityHovered ? (
          <PopoverTrigger>
            <span>
              <Tooltip
                textStyle="body-md"
                label={isEntitySuppressed ? unSuppress : suppress}
              >
                <IconButton
                  w="24px"
                  h="24px"
                  size="box-md"
                  variant="solid"
                  colorScheme="gray"
                  data-testid="suppress-entity-popover-content-trigger"
                  aria-label={isEntitySuppressed ? unSuppressed : suppressed}
                >
                  <MuiIcon size="sm">
                    {isEntitySuppressed ? 'notifications' : 'notifications_off'}
                  </MuiIcon>
                </IconButton>
              </Tooltip>
            </span>
          </PopoverTrigger>
        ) : null}
        <Portal>
          <SuppressEntityContent
            refetchMain={refetchMain}
            onClose={setIsEditing.off}
            entityInfo={entityInfo}
            detectionData={detectionInfo}
            excludedEntitiesData={excludedEntitiesData}
            excludedEntitiesError={excludedEntitiesError}
            setIsEntitySupressedPassed={setIsEntitySupressed}
          />
        </Portal>
      </>
    </Popover>
  );
};
