import { createIcon } from '@chakra-ui/react';

export const ArrowDownIcon = createIcon({
  displayName: 'ArrowDownIcon',
  viewBox: '0 0 24 24',
  d: 'M11 4h2v12l5.5-5.5 1.42 1.42L12 19.84l-7.92-7.92L5.5 10.5 11 16V4Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
