import { ComponentStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

import { bodyStyles, textStyles } from '../../common';

export const CheckboxStyles: Partial<ComponentStyleConfig> = {
  baseStyle: (props: any) => ({
    icon: {
      color: 'gray.50',
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    control: {
      bg: 'layer.1',
      borderColor: 'border.1',
      boxShadow: '0px 1px 0px rgba(244, 244, 244, 0.1)',
      _checked: {
        bg: `${props.colorScheme}.500`,
        borderColor: `${props.colorScheme}.500`,
        _invalid: {
          bg: 'red.500',
          borderColor: 'red.500',
        },
      },
      _disabled: {
        opacity: 0.5,
      },
      _invalid: {
        borderColor: 'red.500',
      },
    },
  }),
  sizes: {
    sm: {
      control: {
        width: 3,
        height: 3,
      },
      label: {
        ...textStyles['body-sm'],
      },
    },
    md: {
      control: {
        borderWidth: '1px',
        width: 3.5,
        height: 3.5,
      },
      label: {
        ...textStyles['body-md'],
      },
      icon: {
        fontSize: '0.5rem',
      },
    },
    lg: {
      control: {
        width: 5,
        height: 5,
      },
      label: {
        ...textStyles['body-md'],
      },
    },
  },
  defaultProps: {
    colorScheme: 'blue',
  },
  variants: {
    radio: (props: any) => ({
      control: {
        height: 5,
        width: 5,
        border: '1px solid',
        borderColor: mode('gray.200', 'gray.600')(props),
        borderRadius: 'full',
        _checked: {
          bg: 'initial',
          borderColor: mode('gray.200', 'gray.600')(props),
          color: 'blue.500',

          _before: {
            content: '""',
            h: 'calc(50% - 1px)',
            w: 'calc(50% - 1px)',
            bg: 'blue.500',
            position: 'relative',
            borderRadius: 'full',
          },

          _hover: {
            borderColor: 'inherit',
            bg: mode('gray.300', 'gray.800')(props),
          },
        },
      },
      icon: {
        display: 'none',
      },
    }),
    box: (props) => ({
      // hide the checkbox
      control: {
        position: 'absolute',
        display: 'none',
        visibility: 'hidden',
        overflow: 'hidden',
        h: 0,
        w: 0,
      },
      container: {
        position: 'relative',
        px: 3,
        py: 2,
        borderRadius: 'base',
        bg: 'layer.1',
        border: 'solid 1px',
        borderColor: 'border.1',
        opacity: 0.6,
        _disabled: {
          opacity: 0.4,
        },
        _hover: {
          bgColor: 'layer.2',
          borderColor: 'border.2',
          opacity: 1,
        },
        _checked: {
          opacity: 1,
          bgColor: 'layer.2',
          borderColor: 'border.2',
        },
        _focusWithin: {
          boxShadow: 'outline',
        },
      },
      icon: {
        display: 'none',
      },
      label: {
        ml: 0,
        textTransform: 'none',
        color: 'inherit',
        ...bodyStyles['body-md'],
      },
    }),
  },
};
