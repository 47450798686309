import {
  Box,
  Button,
  ButtonGroup,
  Center,
  HStack,
  Spinner,
  Tag,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { DataTable } from '@gamma/data-table';
import { CrowdStrike, Elastic, Http, SplunkHec } from '@gamma/icons';
import { ROUTES } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  ElasticsearchExporter,
  Exporter,
  GET_EXPORTERS,
  HttpExporter,
  LogscaleExporter,
  SplunkHecLogsExporter,
  useDeleteExporter,
  useGetExporters,
  useGetExportersPublic,
} from '@gamma/investigator/queries';
import { Board, Column, FlushPanelContent, Panel } from '@gamma/layout';
import { RouterLink } from '@gamma/navigation';
import {
  Alert,
  ConfirmationModal,
  DeleteModal,
  Drawer,
  ErrorBoundary,
} from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';
import {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Column as TableColumn } from 'react-table';

import {
  ElasticSearchForm,
  HttpForm,
  HumioForm,
  SplunkHECForm,
} from './Components/Forms';

interface AlertExportProps {
  locked?: boolean;
}

const {
  alert,
  alertExports,
  exporters: exporterNames,
  forms,
  instructions,
  modal,
  toast,
} = i18n.pages.alertExport;

const { deleteModal, confirmation } = modal;

const {
  deleteSuccessDesc,
  deleteSuccessTitle,
  deleteFailDesc,
  deleteFailTitle,
} = toast;

const dataTableColumns: TableColumn<Exporter>[] = [
  {
    Header: i18n.tables.name,
    accessor: 'name',
  },
  {
    Header: i18n.tables.type,
    accessor: 'exporter',
    Cell: (props) => {
      switch (props.value) {
        case 'elasticsearch':
          return (
            <HStack>
              <Elastic boxSize={5} />
              <Text>{exporterNames.elastic}</Text>
            </HStack>
          );
        case 'http':
          return (
            <HStack>
              <Http boxSize={5} />
              <Text>{`${exporterNames.http} ${exporterNames.exporter}`}</Text>
            </HStack>
          );
        case 'logscale':
          return (
            <HStack>
              <CrowdStrike boxSize={5} />
              <Text>{exporterNames.logscale}</Text>
            </HStack>
          );
        case 'splunk_hec_logs':
          return (
            <HStack>
              <SplunkHec boxSize={5} />
              <Text>{exporterNames.splunk}</Text>
            </HStack>
          );
        default:
          return props.value;
      }
    },
  },
  {
    Header: i18n.tables.status,
    accessor: 'enabled',
    Cell: (props) => (
      <Tag
        size="md"
        variant="solid"
        colorScheme={props?.value ? 'green' : 'gray'}
      >
        {!props?.value ? i18n.tables.disabled : i18n.tables.enabled}
      </Tag>
    ),
  },
];

export const IntegrationsAlertExports = ({ locked }: AlertExportProps) => {
  const navigate = useNavigate();
  const { exporter_id } = useParams();

  const showToastMessage = useToast();

  const { user, userRole } = useContext(AuthContext);
  const tenant = user?.attributes['custom:tenant_id'];

  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const { onClose, onOpen, isOpen } = useDisclosure();
  const {
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
    isOpen: isDeleteOpen,
  } = useDisclosure();

  const [formRef, setFormRef] = useState<RefObject<HTMLFormElement> | null>(
    null,
  );

  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(true);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [isFormUnchanged, setIsFormUnchanged] = useState<boolean>(true);
  const [isCancelDisabled, setIsCancelDisabled] = useState<boolean>(false);

  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [newInProgress, setNewInProgress] = useState<string | null>(null);
  const [exporters, setExporters] = useState<
    SplunkHecLogsExporter[] | ElasticsearchExporter[] | LogscaleExporter[] | []
  >([]);
  const [activeExporter, setActiveExporter] = useState<
    SplunkHecLogsExporter | ElasticsearchExporter | LogscaleExporter | null
  >(null);

  const getExportsQuery =
    userRole === 'admin' ? useGetExporters : useGetExportersPublic;

  const { data, error, loading } = getExportsQuery({
    fetchPolicy: 'network-only',
    variables: {},
  });

  const dataExporters = data?.getExporters;

  const [deleteExporter, { loading: deleteLoading, error: deleteError }] =
    useDeleteExporter({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_EXPORTERS,
        },
      ],
      onCompleted: () => {
        setIsUpdating(false);
        showToastMessage({
          status: 'success',
          title: deleteSuccessTitle,
          description: deleteSuccessDesc,
          isClosable: true,
          position: 'bottom-right',
        });
        clearInProgress();
        navigate(ROUTES.systemSettingsIntegrationsAlertExports);
      },
      onError: (error) => {
        setIsUpdating(false);
        showToastMessage({
          duration: null,
          status: 'error',
          title: deleteFailTitle,
          description: deleteFailDesc,
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const deleteAlertExport = async () => {
    setIsUpdating(true);
    if (tenant && activeExporter?.exporter_id) {
      deleteExporter({
        variables: {
          exporter_id: {
            tenant,
            exporter_id: activeExporter?.exporter_id,
          },
        },
      });
    }
  };

  const handleSetNewInProgress = useCallback(
    (type: string) => {
      if (type === 'SplunkHecLogsExporter') {
        const newSplunkExporter = {
          name: 'New',
          exporter_id: '',
          tenant: '',
          exporter: '',
          enabled: true,
          hec_url: '',
          token: '',
          index: '',
          inProgress: true,
          tls: {
            verify_certificate: false,
          },
          __typename: 'SplunkHecLogsExporter',
        };
        setNewInProgress(type);
        setActiveExporter(newSplunkExporter);
        if (dataExporters) {
          setExporters([...dataExporters, newSplunkExporter]);
        } else {
          setExporters([newSplunkExporter]);
        }
      }

      if (type === 'ElasticsearchExporter') {
        const newElasticExporter: ElasticsearchExporter = {
          name: 'New',
          exporter_id: '',
          tenant: '',
          exporter: '',
          enabled: true,
          auth: {
            user: '',
            password: '',
          },
          bulk: {
            index: '',
          },
          endpoint: '',
          tls: {
            verify_certificate: false,
          },
          inProgress: true,
          __typename: 'ElasticsearchExporter',
        };
        setNewInProgress(type);
        setActiveExporter(newElasticExporter);
        if (dataExporters) {
          setExporters([...dataExporters, newElasticExporter]);
        } else {
          setExporters([newElasticExporter]);
        }
      }

      if (type === 'HttpExporter') {
        const newHttpExporter: HttpExporter = {
          name: 'New',
          exporter_id: '',
          tenant: '',
          exporter: '',
          enabled: true,
          http_auth: {
            basic: {
              username: '',
              password: '',
            },
            bearer: {
              token: '',
            },
            strategy: 'none',
          },
          headers: [],
          uri: '',
          tls: {
            verify_certificate: false,
          },
          inProgress: true,
          __typename: 'HttpExporter',
        };
        setNewInProgress(type);
        setActiveExporter(newHttpExporter);
        if (dataExporters) {
          setExporters([...dataExporters, newHttpExporter]);
        } else {
          setExporters([newHttpExporter]);
        }
      }

      if (type === 'LogscaleExporter') {
        const newLogscaleExporter: LogscaleExporter = {
          name: 'New',
          exporter_id: '',
          tenant: '',
          exporter: '',
          enabled: true,
          url: '',
          token: '',
          index: '',
          tls: {
            verify_certificate: false,
          },
          inProgress: true,
          __typename: 'LogscaleExporter',
        };
        setNewInProgress(type);
        setActiveExporter(newLogscaleExporter);
        if (dataExporters) {
          setExporters([...dataExporters, newLogscaleExporter]);
        } else {
          setExporters([newLogscaleExporter]);
        }
      }
    },
    [dataExporters],
  );

  const clearInProgress = useCallback(() => {
    setNewInProgress(null);
  }, []);

  const handleCancelInProgress = useCallback(() => {
    if (newInProgress) {
      setExporters(exporters.slice(1));
    }
    clearInProgress();
    navigate(ROUTES.systemSettingsIntegrationsAlertExports);
  }, [exporters, newInProgress, navigate, clearInProgress]);

  const handleSetExporter = (exporter_id: string) => {
    const selectedExporter =
      dataExporters &&
      dataExporters.find((exporter) => exporter.exporter_id === exporter_id);
    selectedExporter && setActiveExporter(selectedExporter);
  };

  useEffect(() => {
    if (exporter_id) {
      handleSetExporter(exporter_id);
    } else if (params.get('new')) {
      const exporterType = params.get('new');
      exporterType && handleSetNewInProgress(exporterType);
    } else {
      setActiveExporter(null);
    }
  }, [dataExporters, exporter_id, handleSetNewInProgress, params]);

  useEffect(() => {
    if (!params.get('new') && !newInProgress) {
      clearInProgress();
      if (dataExporters) {
        setExporters(dataExporters);
      } else {
        setExporters([]);
      }
    }
  }, [dataExporters, newInProgress, params, clearInProgress]);

  return (
    <ErrorBoundary page="AlertExport" styleClass="main">
      <>
        <Panel mb={4}>
          <VStack alignItems="start">
            <Text textStyle="h4">{alertExports}</Text>
            <Text mb={2} textStyle="body-md">
              {instructions.selectFrom}
            </Text>
            <Box mb={2}>
              <Alert variant="subtle">
                {instructions.configureOneOrMore}{' '}
                <Button
                  variant="link"
                  as={RouterLink}
                  target="_blank"
                  to="/docs/settings/alert-export.html"
                >
                  {alert.link}
                </Button>
                .
              </Alert>
            </Box>
            <HStack spacing={4}>
              <Panel
                h="60px"
                w="200px"
                display="flex"
                alignItems="center"
                layerStyle="second"
                data-testid="humio-button"
                opacity={userRole === 'admin' ? 'initial' : '50%'}
                cursor={userRole === 'admin' ? 'pointer' : 'not-allowed'}
                _hover={{
                  backgroundColor: '#474747',
                  borderColor: 'border.layer.2',
                }}
                onClick={() =>
                  userRole === 'admin' &&
                  navigate({
                    pathname: ROUTES.systemSettingsIntegrationsAlertExports,
                    search: `new=LogscaleExporter`,
                  })
                }
              >
                <CrowdStrike mr={2} />
                <Text fontWeight="500">
                  {exporterNames.crowdStrike}
                  <br />
                  {exporterNames.falconLogScale}
                </Text>
              </Panel>
              <Panel
                h="60px"
                w="200px"
                display="flex"
                alignItems="center"
                layerStyle="second"
                data-testid="elastic-search-button"
                opacity={userRole === 'admin' ? 'initial' : '50%'}
                cursor={userRole === 'admin' ? 'pointer' : 'not-allowed'}
                _hover={{
                  backgroundColor: '#474747',
                  borderColor: 'border.layer.2',
                }}
                onClick={() =>
                  userRole === 'admin' &&
                  navigate({
                    pathname: ROUTES.systemSettingsIntegrationsAlertExports,
                    search: `new=ElasticsearchExporter`,
                  })
                }
              >
                <Elastic mr={2} />
                <Text fontWeight="500">{exporterNames.elastic}</Text>
              </Panel>
              <Panel
                h="60px"
                w="200px"
                display="flex"
                alignItems="center"
                layerStyle="second"
                data-testid="http-button"
                opacity={userRole === 'admin' ? 'initial' : '50%'}
                cursor={userRole === 'admin' ? 'pointer' : 'not-allowed'}
                _hover={{
                  backgroundColor: '#474747',
                  borderColor: 'border.layer.2',
                }}
                onClick={() =>
                  userRole === 'admin' &&
                  navigate({
                    pathname: ROUTES.systemSettingsIntegrationsAlertExports,
                    search: `new=HttpExporter`,
                  })
                }
              >
                <Http mr={2} />
                <Text fontWeight="500">{`${exporterNames.http} ${exporterNames.exporter}`}</Text>
              </Panel>
              <Panel
                h="60px"
                w="200px"
                display="flex"
                alignItems="center"
                layerStyle="second"
                data-testid="splunk-hec-button"
                opacity={userRole === 'admin' ? 'initial' : '50%'}
                cursor={userRole === 'admin' ? 'pointer' : 'not-allowed'}
                _hover={{
                  backgroundColor: '#474747',
                  borderColor: 'border.layer.2',
                }}
                onClick={() =>
                  userRole === 'admin' &&
                  navigate({
                    pathname: ROUTES.systemSettingsIntegrationsAlertExports,
                    search: `new=SplunkHecLogsExporter`,
                  })
                }
              >
                <SplunkHec mr={2} />
                <Text fontWeight="500">{exporterNames.splunk}</Text>
              </Panel>
            </HStack>
          </VStack>
        </Panel>
        <Board data-testid="alert-export">
          {loading || error ? (
            <Column>
              <Panel>
                <GraphQLReqStatus loading={loading} error={error} />
              </Panel>
            </Column>
          ) : (
            dataExporters && (
              <Column>
                <Panel>
                  <FlushPanelContent>
                    <DataTable
                      data={dataExporters}
                      columns={dataTableColumns}
                      emptyBodyContent={
                        <Center m={4}>{i18n.tables.noResults}</Center>
                      }
                      onRowClick={
                        userRole !== 'admin'
                          ? undefined
                          : (row) => {
                              handleSetExporter(row.original.exporter_id);
                              navigate({
                                pathname: `${ROUTES.systemSettingsIntegrationsAlertExports}/${row.original.exporter_id}`,
                              });
                            }
                      }
                    />
                  </FlushPanelContent>
                </Panel>
              </Column>
            )
          )}
          <Drawer
            title={
              <>
                {activeExporter?.__typename === 'SplunkHecLogsExporter' && (
                  <HStack>
                    <SplunkHec />
                    <Text textStyle="h4">{`${exporterNames.splunk} ${exporterNames.exporter}`}</Text>
                  </HStack>
                )}
                {activeExporter?.__typename === 'LogscaleExporter' && (
                  <HStack>
                    <CrowdStrike />
                    <Text textStyle="h4">{`${exporterNames.crowdStrike} ${exporterNames.exporter}`}</Text>
                  </HStack>
                )}
                {activeExporter?.__typename === 'ElasticsearchExporter' && (
                  <HStack>
                    <Elastic />
                    <Text textStyle="h4">{`${exporterNames.elastic} ${exporterNames.exporter}`}</Text>
                  </HStack>
                )}
                {activeExporter?.__typename === 'HttpExporter' && (
                  <HStack>
                    <Http />
                    <Text textStyle="h4">{`${exporterNames.http} ${exporterNames.exporter}`}</Text>
                  </HStack>
                )}
              </>
            }
            size="lg"
            isOpen={userRole === 'admin' && !!activeExporter}
            onClose={() => {
              setActiveExporter(null);
              navigate(`${ROUTES.systemSettingsIntegrationsAlertExports}`);
            }}
            body={
              <>
                {activeExporter?.__typename === 'SplunkHecLogsExporter' && (
                  <SplunkHECForm
                    isUpdating={isUpdating}
                    setFormRef={setFormRef}
                    onDeleteOpen={onDeleteOpen}
                    deleteLoading={deleteLoading}
                    setIsUpdating={setIsUpdating}
                    activeExporter={activeExporter}
                    clearInProgress={clearInProgress}
                    setIsSaveLoading={setIsSaveLoading}
                    setIsSaveDisabled={setIsSaveDisabled}
                    setIsFormUnchanged={setIsFormUnchanged}
                    setIsCancelDisabled={setIsCancelDisabled}
                  />
                )}
                {activeExporter?.__typename === 'LogscaleExporter' && (
                  <HumioForm
                    isUpdating={isUpdating}
                    setFormRef={setFormRef}
                    onDeleteOpen={onDeleteOpen}
                    deleteLoading={deleteLoading}
                    setIsUpdating={setIsUpdating}
                    clearInProgress={clearInProgress}
                    setIsSaveLoading={setIsSaveLoading}
                    setIsSaveDisabled={setIsSaveDisabled}
                    setIsFormUnchanged={setIsFormUnchanged}
                    setIsCancelDisabled={setIsCancelDisabled}
                    activeExporter={activeExporter as LogscaleExporter}
                  />
                )}
                {activeExporter?.__typename === 'ElasticsearchExporter' && (
                  <ElasticSearchForm
                    isUpdating={isUpdating}
                    setFormRef={setFormRef}
                    onDeleteOpen={onDeleteOpen}
                    deleteLoading={deleteLoading}
                    setIsUpdating={setIsUpdating}
                    clearInProgress={clearInProgress}
                    setIsSaveLoading={setIsSaveLoading}
                    setIsSaveDisabled={setIsSaveDisabled}
                    setIsFormUnchanged={setIsFormUnchanged}
                    setIsCancelDisabled={setIsCancelDisabled}
                    activeExporter={activeExporter as ElasticsearchExporter}
                  />
                )}
                {activeExporter?.__typename === 'HttpExporter' && (
                  <HttpForm
                    isUpdating={isUpdating}
                    setFormRef={setFormRef}
                    onDeleteOpen={onDeleteOpen}
                    deleteLoading={deleteLoading}
                    setIsUpdating={setIsUpdating}
                    clearInProgress={clearInProgress}
                    setIsSaveLoading={setIsSaveLoading}
                    setIsSaveDisabled={setIsSaveDisabled}
                    setIsFormUnchanged={setIsFormUnchanged}
                    setIsCancelDisabled={setIsCancelDisabled}
                    activeExporter={activeExporter as HttpExporter}
                  />
                )}
              </>
            }
            footer={
              <ButtonGroup w="100%" justifyContent="end">
                <Button
                  size="lg"
                  variant="solid"
                  colorScheme="gray"
                  isDisabled={isCancelDisabled}
                  data-testid="alert-exporter-cancel-button"
                  onClick={() => {
                    setIsUpdating(false);
                    setActiveExporter(null);
                    if (!isFormUnchanged) {
                      onOpen();
                    } else {
                      handleCancelInProgress();
                    }
                  }}
                >
                  {forms.cancel}
                </Button>
                <Button
                  size="lg"
                  variant="solid"
                  isDisabled={isSaveDisabled}
                  onClick={() => {
                    if (formRef?.current) {
                      formRef.current.dispatchEvent(
                        new Event('submit', {
                          bubbles: true,
                          cancelable: true,
                        }),
                      );
                    }
                  }}
                  data-testid="alert-exporter-save-button"
                  leftIcon={isSaveLoading ? <Spinner size={'xs'} /> : undefined}
                >
                  {forms.save}
                </Button>
              </ButtonGroup>
            }
          />
        </Board>
        <ConfirmationModal
          size="sm"
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          body={confirmation.body}
          title={confirmation.title}
          onConfirm={() => handleCancelInProgress()}
        ></ConfirmationModal>
        <DeleteModal
          size="sm"
          isOpen={isDeleteOpen}
          onOpen={onDeleteOpen}
          onClose={() => {
            onDeleteClose();
            setIsUpdating(false);
          }}
          body={deleteModal.body}
          title={deleteModal.title}
          onConfirm={() => deleteAlertExport()}
        ></DeleteModal>
      </>
    </ErrorBoundary>
  );
};
