import { Button, ButtonProps, Flex, HStack, Tag, TagCloseButton, Text, VStack } from '@chakra-ui/react';
import { RefreshWithCircleIcon } from '@gamma/icons';
import { PanelProps } from '@gamma/layout';
import { ReactNode } from 'react';

export interface IPill {
  label: ReactNode;
  value: string;
}

export interface PillBoxProps {
  title?: string;
  pills: IPill[];
  panelCustomStyles?: PanelProps;
  layerStyle?: PanelProps['layerStyle'];
  colorScheme?: string;
  variant?: string;
  noPillsText?: ReactNode;
  clearButtonText?: ReactNode;
  clearButtonProps?: ButtonProps;
  shouldShowClearAllButton?: boolean;
  onClearAll?: () => void;
  onPillClose?: (pill: IPill, pillIndex: number) => void;
}

export const PillBox = ({
  title,
  pills,
  panelCustomStyles = {},
  colorScheme = 'blue',
  variant = 'solid',
  clearButtonText = 'Clear',
  noPillsText = 'No active filters',
  layerStyle = 'second',
  clearButtonProps,
  shouldShowClearAllButton = false,
  onClearAll,
  onPillClose,
}: PillBoxProps) => {
  return (
    <VStack w="100%" alignItems="start">
      {title && <Text textStyle="body-md-bold">{title}</Text>}
      <HStack
        w="100%"
        spacing={2}
        px={2}
        py={3}
        borderRadius="base"
        data-testid="pill-box-panel"
        justifyContent={
          shouldShowClearAllButton ? 'space-between' : 'flex-start'
        }
        {...panelCustomStyles}
        layerStyle={layerStyle}
      >
        <Flex flexWrap="wrap" gap={2}>
          {pills.length > 0 &&
            pills.map((pill, index) => (
              <Tag
                data-testid="pill-box-pill"
                colorScheme={colorScheme}
                variant={variant}
                key={pill.value}
                wordBreak="break-word"
              >
                {pill.label}
                <TagCloseButton
                  data-testid="pill-box-close-button"
                  onClick={() => onPillClose?.(pill, index)}
                />
              </Tag>
            ))}
          {pills.length === 0 && (
            <Text color="state.disabled">{noPillsText}</Text>
          )}
        </Flex>
        {shouldShowClearAllButton && (
          <Button
            w="auto"
            data-testid="pill-box-clear-all-button"
            variant="ghost"
            colorScheme="gray"
            leftIcon={<RefreshWithCircleIcon boxSize={4} />}
            {...clearButtonProps}
            size="sm"
            onClick={onClearAll}
            my={-1}
          >
            {clearButtonText}
          </Button>
        )}
      </HStack>
    </VStack>
  );
};
