import { createIcon } from '@chakra-ui/react';

export const CloudServerIcon = createIcon({
  displayName: 'CloudServerIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M6 18h2v2H6a6 6 0 0 1-6-6c0-3.1 2.34-5.64 5.35-5.97A7.506 7.506 0 0 1 12 4a7.49 7.49 0 0 1 7.35 6.03c2.6.19 4.65 2.33 4.65 4.97a5 5 0 0 1-2 4v-4a3 3 0 0 0-3-3h-1.5v-.5A5.5 5.5 0 0 0 12 6c-2.5 0-4.63 1.69-5.29 4H6a4 4 0 1 0 0 8Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.333 14H9.667a.666.666 0 0 0-.667.667v2.666a.667.667 0 0 0 .667.667h10.666a.666.666 0 0 0 .667-.667v-2.666a.666.666 0 0 0-.667-.667Zm-10 2.667v-1.334h1.334v1.334h-1.334ZM20.333 19.333H9.667A.667.667 0 0 0 9 20v2.667a.667.667 0 0 0 .667.666h10.666a.667.667 0 0 0 .667-.666V20a.666.666 0 0 0-.667-.667Zm-10 2.667v-1.333h1.334V22h-1.334Z"
        fill="currentColor"
      />
    </>
  ),
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
