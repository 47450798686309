import { createIcon } from '@chakra-ui/react';

export const PowerIcon = createIcon({
  displayName: 'PowerIcon',
  viewBox: '0 0 24 24',
  d: 'M16.56 5.44L15.11 6.89C16.84 7.94 18 9.83 18 12C18 13.5913 17.3679 15.1174 16.2426 16.2426C15.1174 17.3679 13.5913 18 12 18C10.4087 18 8.88258 17.3679 7.75736 16.2426C6.63214 15.1174 6 13.5913 6 12C6 9.83 7.16 7.94 8.88 6.88L7.44 5.44C5.36 6.88 4 9.28 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.28 18.64 6.88 16.56 5.44ZM13 3H11V13H13',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
