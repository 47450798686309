import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { Select } from '@gamma/form-fields';
import { ApexOptions } from 'apexcharts';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useRef } from 'react';
import Chart from 'react-apexcharts';

import { IGetDetectionTimelineSummary } from '@gamma/investigator/queries';
import { chartOptions } from './chartOptions';
import { seriesData } from './seriesData';

const intervalOptions: { label: string; value: string }[] = [
  {
    label: '24 hours',
    value: '86400',
  },
  {
    label: '8 hours',
    value: '28800',
  },
  {
    label: '1 hour',
    value: '3600',
  },
  {
    label: '30 minutes',
    value: '1800',
  },
  {
    label: '15 minutes',
    value: '900',
  },
  {
    label: '5 minutes',
    value: '300',
  },
];

interface ApexDetectionsChartProps {
  end?: number;
  start?: number;
  maxRows: number;
  interval: number;
  chartsData?: IGetDetectionTimelineSummary;
  setInterval: React.Dispatch<React.SetStateAction<number>>;
  setSelectedDetectionId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

export const ApexDetectionsChart = React.memo(
  ({
    end,
    start,
    maxRows,
    chartsData,
    interval,
    setInterval,
    setSelectedDetectionId,
  }: ApexDetectionsChartProps) => {
    const chartWrapper = useRef<HTMLDivElement>(null);

    const renderCustomizations = (
      chartWrapper: React.RefObject<HTMLDivElement>,
    ) => {
      if (chartWrapper?.current) {
        const labels = Array.from(
          chartWrapper.current?.getElementsByClassName(
            'apexcharts-yaxis-label',
          ),
        );
        labels?.forEach((label) => {
          const labelFirstChild = label?.firstElementChild;
          if (
            labelFirstChild?.nodeName === 'tspan' &&
            label?.classList?.contains('apexcharts-text')
          ) {
            const textContent = labelFirstChild?.textContent?.split(' ');
            if (textContent?.length && textContent?.length > 1) {
              label.classList.add(
                _.inRange(Number(textContent?.[0]), 4, 7)
                  ? 'severity-medium'
                  : _.inRange(Number(textContent?.[0]), 7, 11)
                  ? 'severity-high'
                  : 'severity-low',
              );
            }
          }
        });
      }
    };

    useEffect(() => {
      renderCustomizations(chartWrapper);
    }, [chartWrapper]);

    const currentDetection = seriesData({
      seriesMaxLength: maxRows,
      seriesData: chartsData?.getCurrentDetectionTimelineSummary,
    });

    const detectionDestination = seriesData({
      seriesMaxLength: maxRows,
      seriesData: chartsData?.getDetectionDestinationTimelineSummary,
    });

    const detectionSource = seriesData({
      seriesMaxLength: maxRows,
      seriesData: chartsData?.getDetectionSourceTimelineSummary,
    });

    const detectionSourceDestination = seriesData({
      seriesMaxLength: maxRows,
      seriesData: chartsData?.getDetectionSourceDestinationTimelineSummary,
    });

    const chartTimelineData: ApexOptions = useMemo(() => {
      return {
        chart: {
          id: 'detection-x',
          group: 'detections',
          type: 'rangeBar',
          toolbar: {
            autoSelected: 'pan',
            show: true,
            offsetX: -10000,
            offsetY: 0,
            tools: {
              download: false,
              pan: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M408.781 128.007C386.356 127.578 368 146.36 368 168.79V256h-8V79.79c0-22.43-18.356-41.212-40.781-40.783C297.488 39.423 280 57.169 280 79v177h-8V40.79C272 18.36 253.644-.422 231.219.007 209.488.423 192 18.169 192 40v216h-8V80.79c0-22.43-18.356-41.212-40.781-40.783C121.488 40.423 104 58.169 104 80v235.992l-31.648-43.519c-12.993-17.866-38.009-21.817-55.877-8.823-17.865 12.994-21.815 38.01-8.822 55.877l125.601 172.705A48 48 0 0 0 172.073 512h197.59c22.274 0 41.622-15.324 46.724-37.006l26.508-112.66a192.011 192.011 0 0 0 5.104-43.975V168c.001-21.831-17.487-39.577-39.218-39.993z"/></svg>`,
            },
          },
        },
        yaxis: {
          show: false,
          labels: {
            minWidth: 250,
            maxWidth: 250,
          },
        },
        xaxis: {
          type: 'datetime',
          floating: false,
          labels: {
            style: {
              colors: '#FFFFFF',
            },
          },
          axisBorder: {
            color: '#525252',
          },
          axisTicks: {
            color: '#525252',
          },
          min: start ? start * 1000 : 0,
          max: end ? end * 1000 : 0,
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        series: [],
      };
    }, []);

    const detectionChartData: {
      currentDetectionData?: ApexOptions;
      detectionSourceDestinationData?: ApexOptions;
      detectionSourceData?: ApexOptions;
      detectionDestinationData?: ApexOptions;
      chartTimelineData: ApexOptions;
    } = useMemo(() => {
      return {
        ...(currentDetection && {
          currentDetectionData: {
            ...chartOptions({
              maxRows,
              chartWrapper,
              renderCustomizations,
              setSelectedDetectionId,
              start: start ? start * 1000 : 0,
              end: end ? end * 1000 : 0,
              chartIndex: 0,
              chartGroup: 'detections',
              seriesData: currentDetection,
              titleText: 'Current Detection',
              showToolbar: true,
            }),
          },
        }),
        ...(detectionSourceDestination && {
          detectionSourceDestinationData: {
            ...chartOptions({
              maxRows,
              chartWrapper,
              renderCustomizations,
              setSelectedDetectionId,
              start: start ? start * 1000 : 0,
              end: end ? end * 1000 : 0,
              chartIndex: 1,
              chartGroup: 'detections',
              seriesData: detectionSourceDestination,
              titleText: 'Source - Dest. Pair',
              showToolbar: false,
            }),
          },
        }),
        ...(detectionSource && {
          detectionSourceData: {
            ...chartOptions({
              maxRows,
              chartWrapper,
              renderCustomizations,
              setSelectedDetectionId,
              start: start ? start * 1000 : 0,
              end: end ? end * 1000 : 0,
              chartIndex: 2,
              chartGroup: 'detections',
              seriesData: detectionSource,
              titleText: 'Source Only',
              showToolbar: false,
            }),
          },
        }),
        ...(detectionDestination && {
          detectionDestinationData: {
            ...chartOptions({
              maxRows,
              chartWrapper,
              renderCustomizations,
              setSelectedDetectionId,
              start: start ? start * 1000 : 0,
              end: end ? end * 1000 : 0,
              chartIndex: 3,
              chartGroup: 'detections',
              seriesData: detectionDestination,
              titleText: 'Destination Only',
              showToolbar: false,
            }),
          },
        }),
        chartTimelineData,
      };
    }, [
      start,
      end,
      currentDetection,
      detectionDestination,
      detectionSource,
      detectionSourceDestination,
      chartTimelineData,
    ]);

    const getBarHeight = (length: number) => {
      if (length === 1) return 70;
      if (length === 2) return length * 50;
      if (length === 3) return length * 45;
      if (length === 4) return length * 40;
      if (length === 5) return length * 35;
      return 0;
    };

    const currentDetectionDataSeries = detectionChartData?.currentDetectionData
      ?.series as ApexAxisChartSeries;
    const detectionSourceDestinationDataSeries = detectionChartData
      ?.detectionSourceDestinationData?.series as ApexAxisChartSeries;
    const detectionSourceDataSeries = detectionChartData?.detectionSourceData
      ?.series as ApexAxisChartSeries;
    const detectionDestinationDataSeries = detectionChartData
      ?.detectionDestinationData?.series as ApexAxisChartSeries;

    const currentDetectionDataLength = currentDetectionDataSeries
      ? currentDetectionDataSeries?.filter((data) => data.name !== '')?.length
      : 0;

    const detectionSourceDestinationDataLength =
      detectionSourceDestinationDataSeries
        ? detectionSourceDestinationDataSeries?.filter(
            (data) => data.name !== '',
          )?.length
        : 0;

    const detectionSourceDataLength = detectionSourceDataSeries
      ? detectionSourceDataSeries?.filter((data) => data.name !== '')?.length
      : 0;

    const detectionDestinationDataLength = detectionDestinationDataSeries
      ? detectionDestinationDataSeries?.filter((data) => data.name !== '')
          ?.length
      : 0;

    return (
      <Box
        css={{
          '#chart-wrapper': {
            '.chakra-form-control': {
              flexDirection: 'row-reverse',
              label: {
                marginRight: '8px',
              },
            },
          },
          '.apexcharts-toolbar': {
            marginRight: '8px',
          },
          '.apexcharts-tooltip-rangebar .category': {
            color: '#FFFFFF',
          },
          '.apexcharts-pan-icon svg': {
            fill: '#6e8192',
          },
          '.apexcharts-pan-icon.apexcharts-selected svg': {
            fill: '#008ffb',
          },
          '.x-axis-labels': {
            // bottom: 0,
            // position: 'absolute',
          },
          '.apexcharts-rangebar-area': {
            cursor: 'pointer',
          },
          'div[type="rangeBar"]': {
            minHeight: '0 !important',
          },
          '.chart-0-tooltip': {
            marginTop: '55px',
          },
          '.chart-1-tooltip, .chart-2-tooltip, .chart-3-tooltip': {
            marginTop: '-55px',
          },
          '.detection-tooltip': {
            border: 0,
            padding: '6px 12px',
            fontSize: '12px',
            color: '#F4F4F4',
            borderRadius: '6px',
            background: '#393939',
          },
          '.apexcharts-tooltip.apexcharts-theme-light': {
            border: 'none',
            boxShadow: 'none',
            background: 'transparent',
          },
          '.severity-high::first-letter': {
            fill: '#FF8389',
          },
          '.severity-medium::first-letter': {
            fill: '#D2A106',
          },
          '.severity-low::first-letter': {
            fill: '#08BDBA',
          },
        }}
      >
        <Box
          maxH="450px"
          overflow="scroll"
          ref={chartWrapper}
          id="chart-wrapper"
        >
          <Flex
            mr="136px"
            top="4px"
            zIndex={1500}
            position="relative"
            justifyContent="end"
            color="text.secondary"
          >
            <HStack spacing={1}>
              <Flex mr={2}>
                <Select
                  size="sm"
                  inline={true}
                  width="125px"
                  name="interval"
                  label="Interval:"
                  isMulti={false}
                  options={intervalOptions}
                  value={intervalOptions.find(
                    ({ value }) => value === String(interval),
                  )}
                  onChange={(selected) => {
                    selected && setInterval(Number(selected.value));
                  }}
                />
              </Flex>
              <MuiIcon>calendar_today</MuiIcon>
              <Text>
                Date Range:{' '}
                {start &&
                  end &&
                  `${moment(start * 1000).format('MMM D')} - ${moment(
                    end * 1000,
                  ).format('MMM D')}`}
              </Text>
            </HStack>
          </Flex>
          <Box mt="-20px">
            {currentDetectionDataLength > 0 && (
              <Box>
                <Chart
                  type="rangeBar"
                  height="90px"
                  series={currentDetectionDataSeries}
                  options={detectionChartData.currentDetectionData}
                />
              </Box>
            )}
            {detectionSourceDestinationDataLength > 0 && (
              <Box>
                <Chart
                  type="rangeBar"
                  series={detectionSourceDestinationDataSeries}
                  options={detectionChartData.detectionSourceDestinationData}
                  height={`${getBarHeight(
                    detectionSourceDestinationDataLength,
                  )}px`}
                />
              </Box>
            )}
            {detectionSourceDataLength > 0 && (
              <Box>
                <Chart
                  type="rangeBar"
                  series={detectionSourceDataSeries}
                  options={detectionChartData.detectionSourceData}
                  height={`${getBarHeight(detectionSourceDataLength)}px`}
                />
              </Box>
            )}
            {detectionDestinationDataLength > 0 && (
              <Box>
                <Chart
                  type="rangeBar"
                  series={detectionDestinationDataSeries}
                  options={detectionChartData.detectionDestinationData}
                  height={`${getBarHeight(detectionDestinationDataLength)}px`}
                />
              </Box>
            )}
            {detectionChartData?.chartTimelineData && (
              <Box>
                <Chart
                  height="50px"
                  type="rangeBar"
                  series={[]}
                  options={detectionChartData.chartTimelineData}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  },
);
