import { createIcon } from '@chakra-ui/react';

export const ChevronRightIcon = createIcon({
  displayName: 'ChevronRightIcon',
  d: 'M8.59 16.58 13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.42Z',
  viewBox: '0 0 24 24',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
