import { MuiIcon } from '@gamma/display';
import { i18n } from '@gamma/investigator/localization';
import { DetectionEscalationData } from '@gamma/investigator/queries';
import moment from 'moment';

import { PillWrapper } from '.';
import { treatEscalationName } from '../../Utils';

const { detection } = i18n.pages.detections;

interface SendToPillProps {
  escalation_data: DetectionEscalationData;
}

export const SendToPill = ({ escalation_data }: SendToPillProps) => {
  const treatedEscalationName =
    treatEscalationName(escalation_data?.escalation_service_name) || '';

  return (
    <PillWrapper
      label={`${i18n.formatString(
        detection.detectionIsSentBy,
        treatedEscalationName,
        escalation_data?.escalated_by_user_alias,
        moment(escalation_data?.escalated_timestamp * 1000).format('lll'),
      )}`}
    >
      <MuiIcon size="sm">forward</MuiIcon>
    </PillWrapper>
  );
};
