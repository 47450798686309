import {
  Button,
  HStack,
  PopoverBody,
  PopoverFooter,
  PopoverHeader,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  DetectionDetailCard,
  SeverityScore,
} from '@gamma/investigator/components';
import { ROUTES, graphqlErrorRedirects } from '@gamma/investigator/constants';
import { OrgTenantsContext } from '@gamma/investigator/context';
import { useDateRangeQueryString } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  DetectionSummary,
  EntityDetectionTimelineItem,
  useGetEntityDetectionTimelinePaginated,
} from '@gamma/investigator/queries';
import { GraphQLReqStatus } from '@gamma/progress';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export const TopDetectionEntitiesTablePopperContent = ({
  first_seen,
  last_seen,
  detection_count,
  entity_name,
  entity_category,
  tenant_entity,
  severity,
}: DetectionSummary) => {
  const { orgTenantsQueryParam } = useContext(OrgTenantsContext);

  const { start, end } = useDateRangeQueryString();
  const navigate = useNavigate();

  const { loading, error, data } = useGetEntityDetectionTimelinePaginated({
    skip: !orgTenantsQueryParam?.length,
    variables: {
      size: 5,
      offset: 0,
      start,
      end,
      tenant_entity,
      must_conds: [
        `{"terms": {"tenant": ${JSON.stringify(orgTenantsQueryParam)}}}`,
        '{"term": {"detection_status": "open"}}',
      ],
    },
  });

  if (loading || error) {
    return (
      <GraphQLReqStatus
        loading={loading}
        error={error}
        extended={graphqlErrorRedirects}
      />
    );
  }

  const entityDetectionTimeline = data?.getEntityDetectionTimelinePaginated;

  return (
    <>
      <PopoverHeader>
        <HStack>
          <SeverityScore score={severity} />
          <Text
            data-testid="top-entity-table-popper-entity-name"
            textStyle="body-md"
          >
            {entity_name}
          </Text>
        </HStack>
      </PopoverHeader>
      <PopoverBody>
        <Text
          data-testid="top-entity-table-popper-entity-alert-information"
          textStyle="body-sm"
        >
          {i18n.popper.found}{' '}
          <strong>
            {detection_count}{' '}
            {detection_count === 1
              ? i18n.popper.detection
              : i18n.popper.detections}
          </strong>{' '}
          {i18n.popper.fromTheFollowing}{' '}
          <strong>{`${i18n.popper.alertCategories}:`}</strong>
        </Text>
        <Stack
          maxHeight="300px"
          overflowY="scroll"
          overflowX="hidden"
          spacing={2}
          mt={2}
        >
          {entityDetectionTimeline?.data?.map(
            (alert: EntityDetectionTimelineItem, index: number) => (
              <DetectionDetailCard
                key={index}
                condensed={true}
                alertRollup={alert}
              />
            ),
          )}
          {detection_count > 5 && (
            <Text>
              {i18n.formatString(i18n.popper.xOfXItems, 5, detection_count)}
            </Text>
          )}
        </Stack>
      </PopoverBody>
      <PopoverFooter border={0}>
        <Button
          w="full"
          variant="solid"
          onClick={() =>
            navigate({
              pathname: ROUTES.detections,
              search: `start=${start}&end=${end}&${
                entity_category ?? 'source'
              }_name=${entity_name}`,
            })
          }
          data-testid="top-entity-table-popper-entity-navigation-button"
        >
          {detection_count === 1
            ? i18n.buttons.viewDetection
            : i18n.buttons.viewDetections}
        </Button>
      </PopoverFooter>
    </>
  );
};
