import { createIcon } from '@chakra-ui/react';

export const CertificateIcon = createIcon({
  displayName: 'CertificateIcon',
  viewBox: '0 0 18 16',
  d: 'm9.833 15.5 1.667-.833 1.667.833V9.667H9.833M13.167 5.5V3.833l-1.667.834-1.667-.834V5.5l-1.666.833 1.666.834v1.666L11.5 8l1.667.833V7.167l1.666-.834M15.667.5H2.333A1.667 1.667 0 0 0 .667 2.167V10.5a1.667 1.667 0 0 0 1.666 1.667h5.834V10.5H2.333V2.167h13.334V10.5h-.834v1.667h.834a1.666 1.666 0 0 0 1.666-1.667V2.167A1.667 1.667 0 0 0 15.667.5Zm-7.5 4.167h-5V3h5M6.5 7.167H3.167V5.5H6.5m1.667 4.167h-5V8h5v1.667Z',
  defaultProps: {
    height: '16px',
    width: '18px',
  },
});
