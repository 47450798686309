import { createIcon } from '@chakra-ui/react';

export const CaretLeftIcon = createIcon({
  displayName: 'CaretLeftIcon',
  d: 'm14 7-5 5 5 5V7Z',
  viewBox: '0 0 24 24',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
