import { createIcon } from '@chakra-ui/react';

export const Welcome = createIcon({
  displayName: 'Welcome',
  viewBox: '0 0 160 128',
  path: (
    <>
      <rect
        x="33.5"
        y="11.5"
        width="93.03"
        height="90.791"
        rx="3.5"
        fill="#3A424A"
        stroke="#273038"
      />
      <path
        d="M37 11.5h86a3.5 3.5 0 0 1 3.5 3.5v3.5h-93V15a3.5 3.5 0 0 1 3.5-3.5Z"
        fill="#273038"
        stroke="#273038"
      />
      <path
        d="M37.478 16.97a1.493 1.493 0 1 0 0-2.985 1.493 1.493 0 0 0 0 2.985ZM41.955 16.97a1.493 1.493 0 1 0 0-2.985 1.493 1.493 0 0 0 0 2.985ZM46.433 16.97a1.493 1.493 0 1 0 0-2.985 1.493 1.493 0 0 0 0 2.985Z"
        fill="#646A70"
      />
      <rect
        x="54.395"
        y="26.425"
        width="51.239"
        height="4.97"
        rx="2.485"
        stroke="#273038"
      />
      <rect
        x="43.948"
        y="41.351"
        width="72.134"
        height="28.851"
        rx="3.5"
        fill="#273038"
        stroke="#646A70"
      />
      <path
        d="M55.388 61.746a5.97 5.97 0 1 0 0-11.94 5.97 5.97 0 0 0 0 11.94Z"
        fill="#35FE94"
      />
      <rect
        x="65.836"
        y="50.552"
        width="27.612"
        height="3.731"
        rx="1.866"
        fill="#35FE94"
      />
      <rect
        x="65.836"
        y="57.269"
        width="44.776"
        height="3.731"
        rx="1.866"
        fill="#35FE94"
      />
      <rect
        x="101.657"
        y="63.985"
        width="8.955"
        height="3.731"
        rx="1.866"
        fill="#646A70"
      />
      <rect
        x="43.948"
        y="81.649"
        width="72.134"
        height="28.851"
        rx="3.5"
        fill="#273038"
        stroke="#646A70"
      />
      <path
        d="M55.388 102.045a5.97 5.97 0 1 0 0-11.94 5.97 5.97 0 0 0 0 11.94Z"
        fill="#35FE94"
      />
      <rect
        x="65.836"
        y="90.851"
        width="27.612"
        height="3.731"
        rx="1.866"
        fill="#35FE94"
      />
      <rect
        x="65.836"
        y="97.567"
        width="44.776"
        height="3.731"
        rx="1.866"
        fill="#35FE94"
      />
      <rect
        x="101.657"
        y="104.284"
        width="8.955"
        height="3.731"
        rx="1.866"
        fill="#646A70"
      />
      <path
        d="M59.755 3.539a.838.838 0 0 1-.46-1.005.42.42 0 0 0-.252-.509.41.41 0 0 0-.472.14.4.4 0 0 0-.046.083.817.817 0 0 1-.996.463.392.392 0 0 0-.342.049.417.417 0 0 0-.023.682.397.397 0 0 0 .081.046.838.838 0 0 1 .46 1.005.42.42 0 0 0 .252.509.41.41 0 0 0 .472-.141.4.4 0 0 0 .046-.082.817.817 0 0 1 .996-.463c.029.01.06.016.09.018a.41.41 0 0 0 .413-.272.42.42 0 0 0-.138-.477.397.397 0 0 0-.081-.046ZM8.754 70.539a.838.838 0 0 1-.46-1.005c.01-.03.017-.06.02-.092a.42.42 0 0 0-.27-.416.41.41 0 0 0-.473.14.401.401 0 0 0-.046.082.828.828 0 0 1-.41.423.816.816 0 0 1-.585.04.41.41 0 0 0-.504.254.42.42 0 0 0 .138.477.396.396 0 0 0 .082.046.838.838 0 0 1 .46 1.005.42.42 0 0 0 .251.509.41.41 0 0 0 .518-.223.817.817 0 0 1 .996-.463c.029.01.06.016.09.018a.411.411 0 0 0 .413-.272.42.42 0 0 0-.138-.477.393.393 0 0 0-.082-.046Z"
        fill="#4299E1"
      />
      <path
        d="M131.5 44.027c.828 0 1.5-.678 1.5-1.514 0-.835-.672-1.513-1.5-1.513s-1.5.678-1.5 1.514c0 .835.672 1.513 1.5 1.513ZM38.5 116.027c.828 0 1.5-.678 1.5-1.514 0-.835-.672-1.513-1.5-1.513s-1.5.678-1.5 1.513c0 .836.672 1.514 1.5 1.514ZM143.5 95.027c.828 0 1.5-.678 1.5-1.514 0-.835-.672-1.513-1.5-1.513s-1.5.678-1.5 1.513c0 .836.672 1.514 1.5 1.514Z"
        fill="#F56565"
      />
      <path
        d="M135.754 4.539a.83.83 0 0 1-.459-1.005.419.419 0 0 0-.251-.509.408.408 0 0 0-.519.223.823.823 0 0 1-.995.463.413.413 0 0 0-.513.515.42.42 0 0 0 .147.216.418.418 0 0 0 .082.046.83.83 0 0 1 .459 1.005.419.419 0 0 0 .251.509.408.408 0 0 0 .473-.141.42.42 0 0 0 .046-.082.823.823 0 0 1 .995-.463.413.413 0 0 0 .513-.515.42.42 0 0 0-.147-.216.418.418 0 0 0-.082-.046ZM28.755 31.539a.838.838 0 0 1-.46-1.005.42.42 0 0 0-.252-.509.41.41 0 0 0-.518.223.827.827 0 0 1-.41.423.817.817 0 0 1-.585.04.41.41 0 0 0-.504.254.42.42 0 0 0 .138.477.4.4 0 0 0 .081.046.838.838 0 0 1 .46 1.005.42.42 0 0 0 .252.509.41.41 0 0 0 .472-.141.405.405 0 0 0 .046-.082.817.817 0 0 1 .996-.463c.029.01.06.016.09.018a.41.41 0 0 0 .413-.272.42.42 0 0 0-.138-.477.4.4 0 0 0-.081-.046Z"
        fill="#4299E1"
      />
      <path
        d="M25.5 60.027c.828 0 1.5-.678 1.5-1.514 0-.835-.672-1.513-1.5-1.513s-1.5.678-1.5 1.514c0 .835.672 1.513 1.5 1.513ZM14.5 12.027c.828 0 1.5-.678 1.5-1.513C16 9.678 15.328 9 14.5 9s-1.5.678-1.5 1.514c0 .835.672 1.513 1.5 1.513ZM127.765 111h-.53v3.027h.53V111Z"
        fill="#35FE94"
      />
      <path
        d="M129 112.78v-.534h-3v.534h3ZM15.765 90h-.53v3.027h.53V90Z"
        fill="#35FE94"
      />
      <path
        d="M17 91.78v-.534h-3v.535h3ZM140.765 67h-.53v3.027h.53V67Z"
        fill="#35FE94"
      />
      <path
        d="M142 68.78v-.534h-3v.535h3ZM143.375 28.06l.9.832L145.55 27l.45.378-1.725 2.27L143 28.212l.375-.151Z"
        fill="#35FE94"
      />
    </>
  ),
  defaultProps: {
    height: '128px',
    width: '160px',
  },
});
