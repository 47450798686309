import { createIcon } from '@chakra-ui/react';

export const CopyIcon = createIcon({
  displayName: 'CopyIcon',
  viewBox: '0 0 24 24',
  d: 'M19 21H8V7h11v14Zm0-16H8a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2Zm-3-4H4a2 2 0 0 0-2 2v14h2V3h12V1Z',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
