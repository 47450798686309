import { Box, Flex, Text } from '@chakra-ui/react';
import { graphqlErrorRedirects } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import { SanitizedMlAlert } from '@gamma/investigator/pages/detections';
import { FeatureMinMaxData, ModelSummaryContribution, useGetModelSummaries } from '@gamma/investigator/queries';
import { Column } from '@gamma/layout';
import { GraphQLReqStatus } from '@gamma/progress';

import { BoxPlotChart, BoxPlotChartLegend } from '../LegacyCharts';
import { smartNumber } from '../LegacyCharts/helpers';

export interface EntityVsPopChartProps {
  alertData?: SanitizedMlAlert;
  features?: ModelSummaryContribution[];
}

export const EntityVsPopChart = ({
  alertData,
  features,
}: EntityVsPopChartProps) => {
  const { title, constantValue } =
    i18n.pages.entityDetails.ml.analysis.cards.entityVsPop;

  const { loading, error, data } = useGetModelSummaries({
    variables: { model_ids: alertData?.models?.[0]?.model_name },
  });

  if (loading || error) {
    return (
      <Column col={7} display="flex" flexDir="column">
        <Flex alignItems="start" justifyContent="center" flexGrow={1}>
          <GraphQLReqStatus
            loading={true}
            error={error}
            extended={graphqlErrorRedirects}
          />
        </Flex>
      </Column>
    );
  }

  const modelSummaries = data?.getModelSummaries?.[0];

  if (!modelSummaries) {
    return null;
  }

  return (
    <Column
      col={7}
      display="flex"
      flexDir="column"
      justifyContent="space-between"
      data-testid="entity-vs-population-chart"
    >
      <Text textStyle="h6">{title}</Text>
      <Flex direction="column">
        <BoxPlotChartLegend />
        <Box>
          {features?.map(
            ({ actual, difference, display_name, feature_name }, index) => {
              const featureMinMax =
                modelSummaries?.feature_details?.feature_minmax?.find(
                  (feature: FeatureMinMaxData) => feature.name === feature_name,
                );

              const boxPlotData = {
                ...featureMinMax,
                actual,
                difference,
                display_name,
              };

              return actual === 0 ? (
                <Flex alignItems="center" key={feature_name} height="50px">
                  <Box mr={1} w="40px" textAlign="right">
                    {smartNumber(actual)}
                  </Box>
                  <Text
                    textStyle="body-md"
                    flexGrow={1}
                    padding="3px 0"
                    textAlign="center"
                    border="1px dashed rgba(255, 255, 255, 0.3)"
                  >
                    {constantValue}
                  </Text>
                  <Box ml={1} w="40px"></Box>
                </Flex>
              ) : (
                <Flex>
                  <BoxPlotChart
                    key={index}
                    id={feature_name}
                    data={boxPlotData}
                    tooltipPosition="top"
                  />
                </Flex>
              );
            },
          )}
        </Box>
      </Flex>
    </Column>
  );
};
