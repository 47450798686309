import './loader.scss';

import { Center, Fade } from '@chakra-ui/react';
import { Illustrations } from '@gamma/icons';
import { useEffect, useState } from 'react';

export const HumioLoaderOverlay = ({ reloadKey }: { reloadKey?: number }) => {
  const [overlayVisiblity, setOverlayVisiblity] = useState<boolean>(false);
  useEffect(() => {
    setOverlayVisiblity(true);
    const pageLoadTimeout = setTimeout(() => {
      setOverlayVisiblity(false);
    }, 5000);
    return () => clearInterval(pageLoadTimeout);
  }, [reloadKey]);

  return (
    <Fade in={overlayVisiblity} unmountOnExit={true}>
      <Center
        width="100%"
        height="100%"
        position="absolute"
        top="0"
        left="0"
        zIndex="1"
        backgroundColor="layer.3"
        data-testid="humio-loader-overlay"
      >
        <Illustrations.HumioLoader />
      </Center>
    </Fade>
  );
};
