import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spinner,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { i18n } from '@gamma/investigator/localization';
import { Dispatch, SetStateAction } from 'react';

export interface IAlertTableHeaderStatusMenuProps {
  loading: boolean;
  setBulkActive: Dispatch<SetStateAction<boolean | null>>;
}

export const AlertTableHeaderStatusMenu = ({
  loading,
  setBulkActive,
}: IAlertTableHeaderStatusMenuProps) => {
  const handleStatusSubmit = (status: boolean) => {
    setBulkActive(status);
  };

  return (
    <Menu>
      <MenuButton
        color="gray.50"
        borderColor="gray.50"
        _active={{
          color: 'gray.50',
          borderColor: 'gray.50',
        }}
        _hover={{
          color: 'gray.50',
          borderColor: 'gray.50',
        }}
        data-testid="bulk-exclude-menu-button"
        disabled={loading}
        as={Button}
        rightIcon={
          loading ? (
            <Spinner data-testid="bulk-exclude-loader" size={'xs'} />
          ) : (
            <MuiIcon data-testid="alert-catalog-table-header-status-menu-list-chevron">
              keyboard_arrow_down
            </MuiIcon>
          )
        }
      >
        {i18n.pages.system.alertCatalog.status}
      </MenuButton>
      <Portal>
        <MenuList>
          <MenuItem
            data-testid="alert-catalog-table-header-status-menu-list-active-button"
            onClick={() => handleStatusSubmit(true)}
          >
            {i18n.pages.system.alertCatalog.active}
          </MenuItem>
          <MenuItem
            data-testid="alert-catalog-table-header-status-menu-list-inactive-button"
            onClick={() => handleStatusSubmit(false)}
          >
            {i18n.pages.system.alertCatalog.inactive}
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  );
};
