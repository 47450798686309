import { createIcon } from '@chakra-ui/react';

export const CrowdStrikeLogo = createIcon({
  displayName: 'CrowdStrikeLogo',
  viewBox: '0 0 164 29',
  path: (
    <>
      <g fillRule="evenodd" clipRule="evenodd">
        <path d="M25.988 13.582v.003l.004-.008a.17.17 0 0 0-.004.005zm0-.292v.292a7.043 7.043 0 0 1-3.73 2.386 47.243 47.243 0 0 0-4.663-3.378c-.46-.308-.944-.623-1.455-.954-.945-.614-1.978-1.284-3.096-2.072a2.443 2.443 0 0 0-.021-.198c-.012-.085-.023-.17-.023-.259C13 5.038 16.186 2 20.393 2A7.155 7.155 0 0 1 26 4.646v.295l-2.452 2.148h-.286a3.936 3.936 0 0 0-2.881-1.327 3.172 3.172 0 0 0-2.327.97 3.299 3.299 0 0 0-.923 2.383 3.298 3.298 0 0 0 .919 2.38c.61.632 1.448.984 2.319.974 1.1-.018 2.143-.5 2.881-1.331h.286zm-11.448.224-.004-.006h-.004zm4 2.486a7.122 7.122 0 0 1-4-2.486c.11.07.216.142.322.213.164.109.327.218.503.327 1 .688 2.071 1.31 3.175 1.946zM40.356 12.377 41 15.086l-.092.296a2.766 2.766 0 0 1-1.806.617c-1.672 0-2.6-.402-3.762-2.2l-.05-.082c-.576-.927-1.741-2.802-2.659-2.802h-.388v4.829H28V2h6.396c3.678 0 5.66 1.775 5.66 4.593.013 1.66-1.032 3.155-2.626 3.753.448.282.817.667 1.074 1.12l.038.06c.25.39.546.85 1.016.85a1 1 0 0 0 .56-.145zM35.707 6.81c.012.378-.143.742-.427 1.001-.283.26-.668.388-1.057.353h-1.98v-2.79h1.98c.4-.02.788.125 1.07.399.283.273.433.65.414 1.037zM48.498 2C52.788 2 56 4.992 56 9c0 3.988-3.215 7-7.502 7S41 13.008 41 9s3.207-7 7.498-7zm1.699 9.956A3.297 3.297 0 0 0 51.825 9a3.297 3.297 0 0 0-1.628-2.956 3.349 3.349 0 0 0-3.394 0A3.297 3.297 0 0 0 45.174 9a3.297 3.297 0 0 0 1.629 2.956 3.35 3.35 0 0 0 3.394 0z" />
      </g>
      <path d="M75.82 2H72.03l-2.346 7.756L67.193 2h-2.405l-2.467 7.71L59.971 2H56.18L56 2.322 60.856 16h2.404l2.728-7.624L68.74 16h2.408L76 2.322z" />
      <path
        fillRule="evenodd"
        d="M84.078 2c4.28 0 6.91 2.798 6.91 6.981L91 8.973C91 13.198 88.384 16 84.09 16H78V2zm-.161 10.553c1.744 0 3.013-1.273 3.014-3.553v.002-.004V9c-.001-2.285-1.27-3.553-3.014-3.553h-1.952v7.106z"
        clipRule="evenodd"
      />
      <path d="M113.667 5.446H118V2h-13v3.446h4.333V16h4.334z" />
      <path
        fillRule="evenodd"
        d="M125.213 2c3.574 0 5.499 1.812 5.499 4.682l.016-.017c0 1.832-1.003 3.102-2.489 3.818L131 15.677l-.187.323h-4.118l-2.57-4.918h-.983V16H119V2zm1.29 4.904c.013.385-.138.757-.413 1.02a1.302 1.302 0 0 1-1.029.358h-1.92v-2.84h1.92c.388-.023.767.125 1.042.404.274.279.42.663.4 1.058z"
        clipRule="evenodd"
      />
      <path d="M133 2h4v14h-4zM146.932 8.269l4.712-5.947-.192-.322h-4.438l-3.85 4.975V2H139v14h4.164v-4.306l.756-.905L147.456 16h4.356l.188-.322zM163.938 5.446V2H154v14h10v-3.446h-6.026v-1.938h4.54V7.384h-4.54V5.446zM98.181 16c3.116 0 5.819-1.45 5.819-4.256 0-3.053-2.724-3.846-5.13-4.507-.943-.271-1.91-.582-1.91-1.248 0-.478.537-.769 1.35-.769 1.398 0 2.578.829 3.179 1.371h.3l2.019-2.284v-.29C102.736 2.877 100.588 2 98.228 2c-3.308 0-5.602 1.706-5.602 4.176 0 2.659 2.527 3.822 4.675 4.384 1.398.375 2.345.439 2.345 1.164 0 .522-.668.833-1.698.833-1.31 0-2.899-.797-3.67-1.538h-.3L92 13.386v.29c1.251 1.352 3.541 2.304 6.181 2.304M28 29c-.99-2.239-2.978-5.11-10.767-9.216C13.645 17.805 7.508 14.778 2 9c.497 2.084 3.059 6.664 14.082 12.385C19.125 23.025 24.288 24.582 28 29M27 25c-.907-2.657-2.544-6.057-10.328-11.108C12.883 11.347 7.322 8.137 0 0c.524 2.195 2.839 7.91 14.497 15.328C18.328 17.988 23.269 19.63 27 25" />
    </>
  ),
  defaultProps: {
    height: '29px',
    width: '164px',
  },
});
