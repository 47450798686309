import { createIcon } from '@chakra-ui/react';

export const GithubLogo = createIcon({
  displayName: 'GithubLogo',
  viewBox: '0 0 26 26',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 0C5.82105 0 0 5.73803 0 12C0 18.4792 3.72455 23.2826 9 25C9.54081 25.0953 9.77783 24.6996 9 24C9.77783 24.0552 9.76666 23.2495 9 22C6.14426 22.9545 5.38132 20.4619 6 20C4.78996 18.9812 3.93763 18.587 4 19C2.7573 17.7924 4.02701 17.8081 4 18C5.33184 17.8986 6.01817 19.1291 6 19C7.17775 21.0874 9.06117 20.5217 9 20C9.91989 19.3659 10.2559 18.801 10 19C7.74039 18.1574 4.70536 17.0575 5 12C4.70536 10.7475 5.21213 9.60272 6 9C5.90964 8.3832 5.46352 7.07949 6 5C6.1714 5.31552 7.26235 4.97091 9 6C10.7826 6.34464 11.8951 6.20302 13 6C14.1049 6.20302 15.2166 6.34464 17 6C18.7369 4.97091 19.8262 5.31552 20 5C20.5357 7.07949 20.0896 8.3832 20 9C20.7895 9.60272 21.2922 10.7475 21 12C21.2922 17.0701 18.2524 18.1535 15 19C15.8231 18.8663 16.2389 19.6484 17 21C16.2389 22.5571 16.223 23.9395 17 24C16.223 24.7027 16.4576 25.1016 17 25C22.2786 23.2778 26 18.4777 26 12C26 5.73803 20.1789 0 13 0Z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    height: '26px',
    width: '26px',
  },
});
