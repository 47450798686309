import { useColorModeValue } from '@chakra-ui/react';
import { graphqlErrorRedirects } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import { IGetDetectionPeriodSummary } from '@gamma/investigator/queries';
import { Panel } from '@gamma/layout';
import { ErrorMessage, GraphQLReqStatus } from '@gamma/progress';

import { StatisticPanel } from '../StatisticPanel';

interface EntitiesWithDetectionsPanelProps {
  data?: IGetDetectionPeriodSummary;
  loading: boolean;
  error?: ErrorMessage;
}

export const EntitiesWithDetectionsPanel = ({
  data,
  loading,
  error,
}: EntitiesWithDetectionsPanelProps) => {
  const iconBgColor = useColorModeValue('red.300', 'red.700');

  const { current, previous, pct_chg } =
    data?.getDetectionPeriodSummary?.total_entities || {};

  const { heading } = i18n.pages.securityOverview.panels.entitiesWithDetections;

  if (loading || error) {
    return (
      <Panel>
        <GraphQLReqStatus
          loading={loading}
          error={error}
          extended={graphqlErrorRedirects}
        />
      </Panel>
    );
  }

  return (
    <StatisticPanel
      iconBgColor={iconBgColor}
      heading={heading}
      current={current}
      previous={previous}
      pct_chg={pct_chg}
      infoLabelTestId="entities-with-detections-info-label"
    />
  );
};
