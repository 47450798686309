import { gql, LazyQueryHookOptions, useLazyQuery } from '@apollo/client';

export type IGetAppConfigurations = {
  getAppConfigurations: {
    body: {
      data: string;
      message: string;
      success: boolean;
    };
    status_code: number;
  };
};

export const GET_APP_CONFIGURATIONS = gql`
  query getAppConfigurations {
    getAppConfigurations {
      body {
        data
        message
        success
      }
      status_code
    }
  }
`;

export const useGetAppConfigurations = (
  options?: LazyQueryHookOptions<IGetAppConfigurations>,
) => {
  return useLazyQuery<IGetAppConfigurations>(GET_APP_CONFIGURATIONS, options);
};
