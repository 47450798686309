import { PageHeader } from '@gamma/investigator/components';
import { ROUTES } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import { Board, Column } from '@gamma/layout';
import { NavTab, NavTabs } from '@gamma/tabs';
import { Outlet } from 'react-router-dom';

export const UsersAndAccess = () => {
  const { title, panels } = i18n.pages.usersAccess;
  const { tabTitle } = i18n.pages.access;

  const tabs: NavTab[] = [
    {
      title: panels.users.heading,
      to: ROUTES.adminUsers,
      isDisabled: false,
    },
    {
      title: tabTitle,
      to: ROUTES.adminAccess,
      isDisabled: false,
    },
  ];

  return (
    <Board>
      <Column>
        <PageHeader showDateSelection={false}>{title}</PageHeader>
        <NavTabs tabs={tabs} isFitted={false} variant="line" basePath="/" />
      </Column>
      <Column
        flexGrow={1}
        display="flex"
        flexBasis="auto"
        flexDirection="column"
      >
        <Outlet />
      </Column>
    </Board>
  );
};
