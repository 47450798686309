import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

export const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      status_code
      body {
        data
        message
        success
      }
    }
  }
`;

export const useCreateUser = (
  options: MutationHookOptions<any, OperationVariables>,
) => {
  return useMutation(CREATE_USER, options);
};
