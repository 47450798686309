import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { UserCookieAcceptance } from './types';

export interface IGetUserCookieAcceptance {
  getUserCookieAcceptance?: UserCookieAcceptance[];
}

export const GET_USER_COOKIE_ACCEPTANCE = gql`
  query getUserCookieAcceptance {
    getUserCookieAcceptance {
      cookie_type
      cookies_accepted
      cookies_accepted_expiration
    }
  }
`;

export const useGetUserCookieAcceptance = (
  options: QueryHookOptions<IGetUserCookieAcceptance, OperationVariables>,
) => {
  return useQuery<IGetUserCookieAcceptance>(
    GET_USER_COOKIE_ACCEPTANCE,
    options,
  );
};
