import { createIcon } from '@chakra-ui/react';

export const SendToIcon = createIcon({
  d: 'M9.81404 9.7707L8.63596 8.6183L11.3672 5.88709L8.63596 3.15239L9.81404 2L13.6976 5.88709L9.81404 9.7707ZM1.00001 11.0803V8.2572C1.00001 7.37057 1.31276 6.61636 1.93825 5.99457C2.56375 5.37276 3.31611 5.06186 4.19534 5.06186H6.96839L5.04782 3.14131L6.22242 2L10.106 5.88709L6.22242 9.7707L5.04782 8.63288L6.96839 6.71231H4.19534C3.77179 6.71231 3.40842 6.86148 3.10524 7.1598C2.80205 7.45813 2.65045 7.82393 2.65045 8.2572V11.0803H1.00001Z',
  defaultProps: {
    viewBox: '0 0 14 14',
    height: '14px',
    width: '14px',
    fill: '#FFB784',
  },
});
