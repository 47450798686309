import { createIcon } from '@chakra-ui/react';

export const SensorsIcon = createIcon({
  displayName: 'SensorsIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6a2 2 0 0 1 2-2h6l2 2h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6Zm7.25 7A2.75 2.75 0 0 1 12 10.25V9a4 4 0 1 0 4 4h-1.25a2.75 2.75 0 1 1-5.5 0Z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
