import { ComponentStyleConfig } from '@chakra-ui/react';
import { cssVar } from '@chakra-ui/theme-tools';

const $popperBg = cssVar('popper-bg');
const $arrowBg = cssVar('popper-arrow-bg');
const $arrowShadowColor = cssVar('popper-arrow-shadow-color');
const $arrowSize = cssVar('popper-arrow-size');

export const PopoverStyles: ComponentStyleConfig = {
  baseStyle: (props: any) => ({
    popper: {
      width: 'fit-content',
      maxWidth: 'fit-content',
      zIndex: 1500,
      [$arrowSize.variable]: '7px',
    },
    header: {
      pt: 3.5,
    },
    closeButton: {
      position: 'absolute',
      top: 3,
    },
    content: {
      [$popperBg.variable]: `colors.layer.1`,
      bg: 'layer.1',
      [$arrowBg.variable]: `colors.layer.1`,
      [$arrowShadowColor.variable]: `colors.border.layer.2`,
      width: '100%',
      borderColor: 'border.1',
    },
    arrow: {
      boxShadow: `-1px -1px 0px 0px ${$arrowShadowColor.reference} !important`,
    },
  }),
};
