import { graphqlErrorRedirects } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { User, useListUsers } from '@gamma/investigator/queries';
import {
  Board,
  Column,
  FlushPanelContent,
  Panel,
  PanelHeader,
} from '@gamma/layout';
import { ErrorBoundary } from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';
import { useContext, useState } from 'react';
import { Row } from 'react-table';

import { UsersAccessToolbar, UsersTable } from './Components';

export const UsersAccess = () => {
  const { user } = useContext(AuthContext);

  const {
    loading: listUsersLoading,
    error,
    data: { listUsers = [] } = {},
  } = useListUsers({
    skip: !user?.attributes['custom:tenant_id'],
    fetchPolicy: 'cache-and-network',
    variables: {
      tenants: [`${user?.attributes['custom:tenant_id']}`],
    },
  });

  const [tableSearch, setTableSearch] = useState<string>('');
  const [userSelection, setUserSelection] = useState<Row<User>[]>([]);

  const loading = listUsersLoading;

  if (loading || error) {
    return (
      <ErrorBoundary page="UsersAccess" styleClass="main">
        <Panel>
          <GraphQLReqStatus
            loading={loading}
            error={error}
            extended={graphqlErrorRedirects}
          />
        </Panel>
      </ErrorBoundary>
    );
  } else if (!listUsers) {
    return null;
  }

  return (
    <ErrorBoundary page="UsersAccess" styleClass="main">
      <Board data-testid="users-access-page">
        <Column>
          <Panel>
            <PanelHeader>
              <UsersAccessToolbar
                userSelection={userSelection}
                onSearchChange={setTableSearch}
                tableSearch={tableSearch}
              />
            </PanelHeader>
            <FlushPanelContent>
              <UsersTable
                users={listUsers}
                tableSearch={tableSearch}
                onRowSelection={setUserSelection}
              />
            </FlushPanelContent>
          </Panel>
        </Column>
      </Board>
    </ErrorBoundary>
  );
};
