import { createIcon } from '@chakra-ui/react';

export const AlertCategoryFilledIcon = createIcon({
  displayName: 'AlertCategoryFilledIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2ZM7 7h2v2H7V7Zm0 4h2v2H7v-2Zm0 4h2v2H7v-2Zm10 2h-6v-2h6v2Zm0-4h-6v-2h6v2Zm0-4h-6V7h6v2Z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
