import { HStack, Link, Text, VStack } from '@chakra-ui/react';
import { MitreTactic } from '@gamma/investigator/queries';

export interface MitreTechniquesGridProps {
  tactics: MitreTactic[];
}

export const MitreTechniquesGrid = ({ tactics }: MitreTechniquesGridProps) => {
  return (
    <VStack alignItems="start">
      {!!tactics?.length &&
        tactics.map((mitreMapping) =>
          mitreMapping?.techniques?.map((mitreTechnique) =>
            mitreTechnique.name && mitreTechnique.link ? (
              <HStack
                key={mitreTechnique.name}
                data-testid={`mitreTechnique-${mitreTechnique.name}`}
              >
                <Text color="text.secondary">
                  {mitreTechnique.tag.split('.')[1]}
                </Text>
                <Link
                  isExternal
                  color="text.link"
                  textDecoration="none"
                  href={mitreTechnique.link}
                >
                  <Text>{mitreTechnique.name}</Text>
                </Link>
              </HStack>
            ) : null,
          ),
        )}
    </VStack>
  );
};
