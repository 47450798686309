import {
  Box,
  chakra,
  ListItem,
  OrderedList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { CTAButton } from '@gamma/buttons';
import { Input } from '@gamma/form-fields';
import { AuthContext } from '@gamma/investigator/context';
import { useLogOperationCall } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { useAcceptHumioTC } from '@gamma/investigator/queries';
import {
  chunkSubstring,
  setHumioSessionId,
  setRole,
} from '@gamma/investigator/utilities';
import { ErrorBoundary } from '@gamma/overlay';
import { CardPageBody, CardPageCTA, CardPageIllustration } from '@gamma/pages';
import { LoadingSpinner } from '@gamma/progress';
import { formatOTPCode } from '@gamma/util';
import { Auth } from 'aws-amplify';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const pageContent = i18n.pages.registration.verifyOtp;

const NeedAppTooltipContent = () => {
  const { title, instructions } = pageContent.tooltips.app;
  return (
    <Stack>
      <Text textStyle="body-md">{title}</Text>
      <Spacer />
      <Text textStyle="body-md">{instructions.title}</Text>
      <Box>
        <OrderedList spacing={1}>
          <ListItem textStyle="body-md">{instructions.step1}</ListItem>
          <ListItem textStyle="body-md">{instructions.step2}</ListItem>
          <ListItem textStyle="body-md">
            {i18n.formatString(instructions.step3.body, {
              hint: (
                <chakra.span color="gray.500">
                  {instructions.step3.hint}
                </chakra.span>
              ),
            })}
          </ListItem>
        </OrderedList>
      </Box>
    </Stack>
  );
};
const CantScanTooltipContent = ({
  otpClientSecret,
}: {
  otpClientSecret: string;
}) => {
  const { title, instructions } = pageContent.tooltips.barcode;
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Text
          color="blue.400"
          textDecoration="underline"
          as="span"
          tabIndex={0}
        >
          {title}
        </Text>
      </PopoverTrigger>
      <PopoverContent sx={{ width: '400px' }}>
        <PopoverBody>
          <Stack>
            <Text textStyle="body-md">{title}</Text>
            <Spacer />
            <Box>
              <OrderedList spacing={1}>
                <ListItem textStyle="body-md">{instructions.step1}</ListItem>
                <ListItem textStyle="body-md">{instructions.step2}</ListItem>
                <ListItem textStyle="body-md">
                  {i18n.formatString(instructions.step3.body, {
                    key: (
                      <chakra.span color="gray.500">
                        {chunkSubstring(otpClientSecret)}
                      </chakra.span>
                    ),
                  })}
                </ListItem>
                <ListItem textStyle="body-md">{instructions.step4}</ListItem>
                <ListItem textStyle="body-md">{instructions.step5}</ListItem>
              </OrderedList>
            </Box>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export const ConfirmOTPCode = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    setFocus,
    formState: { errors, isDirty },
  } = useForm<{ OTPCode: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user, isPOVTenant, setUser, setUserRole } = useContext(AuthContext);
  const [acceptHumioTC] = useAcceptHumioTC();
  const [qrCode, setQrCode] = useState<string>('');
  const [qrCodeLoading, setQrCodeLoading] = useState<boolean>(true);
  const [otpClientSecret, setOtpClientSecret] = useState<string>('');

  const logOperationCall = useLogOperationCall();

  useEffect(() => {
    if (user?.username) {
      Auth.setupTOTP(user)
        .then((code) => {
          setOtpClientSecret(code);
          setQrCode(
            `otpauth://totp/Corelight%20Investigator:${user.username}?secret=${code}&issuer=Corelight%20Investigator`,
          );
          setQrCodeLoading(false);
        })
        .catch(() => {
          navigate('/login', { replace: true });
        });
    } else {
      navigate('/login', { replace: true });
    }
  }, []);

  const handleOTPInputChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setValue('OTPCode', formatOTPCode(value));
  };

  const handleSubmission: SubmitHandler<{ OTPCode: string }> = async ({
    OTPCode,
  }) => {
    setIsLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      const id_token = user?.signInUserSession.idToken?.jwtToken;

      try {
        await setHumioSessionId(id_token, isPOVTenant);
        acceptHumioTC({
          variables: {
            humioSessionToken: localStorage.getItem('session_id_token') || '',
          },
        });
      } catch (e) {
        console.error(e);
      }

      const verification = await Auth.verifyTotpToken(
        user,
        OTPCode.replace(' ', ''),
      );

      if (verification) {
        const setMfa = await Auth.setPreferredMFA(user, 'TOTP');
        const updatedUser = await Auth.currentAuthenticatedUser();

        if (setMfa && updatedUser) {
          setUser?.(updatedUser);
          setRole({ user, setUserRole });
          window.localStorage.setItem(
            'docs_id_token',
            updatedUser?.signInUserSession?.idToken?.jwtToken,
          );
          logOperationCall('MFA_REGISTERED', 'USER');
          navigate('../complete', { replace: true });
        }
      }
    } catch (e) {
      localStorage.removeItem('docs_id_token');
      setError('OTPCode', {
        message: i18n.forms.validation.otp,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setFocus('OTPCode');
  }, []);
  const { cta, instructions } = pageContent;

  return (
    <ErrorBoundary page="Registration MFA Confirm OTP Page">
      {qrCodeLoading ? (
        <LoadingSpinner />
      ) : (
        <CardPageIllustration qrCodeLink={qrCode} />
      )}
      <CardPageBody>
        <OrderedList mb={4}>
          <ListItem fontSize={'xs'}>
            {i18n.formatString(
              instructions.step1.body,
              <strong>{i18n.dictionary.open}</strong>,
              <Tooltip label={<NeedAppTooltipContent />}>
                <Text
                  color="blue.400"
                  textDecoration="underline"
                  as="span"
                  tabIndex={0}
                >
                  {instructions.step1.help}
                </Text>
              </Tooltip>,
            )}
          </ListItem>
          <ListItem fontSize={'xs'}>
            {i18n.formatString(
              instructions.step2.body,
              <strong>{i18n.dictionary.add}</strong>,
              <CantScanTooltipContent
                otpClientSecret={otpClientSecret || ''}
              />,
            )}
          </ListItem>
          <ListItem fontSize={'xs'}>
            {i18n.formatString(
              instructions.step3.body,
              <strong>{instructions.step3.start}</strong>,
            )}
          </ListItem>
        </OrderedList>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <form onSubmit={handleSubmit(handleSubmission)} id="confirmOTPCode">
            <Input
              label={i18n.forms.labels.enterOTP}
              autoComplete="one-time-code"
              placeholder="xxx xxx"
              isLabelHidden
              {...register('OTPCode', {
                onChange: handleOTPInputChange,
              })}
              error={errors?.OTPCode?.message}
            />
          </form>
        )}
      </CardPageBody>
      <CardPageCTA>
        <CTAButton
          type="submit"
          form="confirmOTPCode"
          disabled={!isDirty}
          isLoading={isLoading}
        >
          {cta}
        </CTAButton>
      </CardPageCTA>
    </ErrorBoundary>
  );
};
