import { useLogOperation } from '@gamma/investigator/queries';

export const useLogOperationCall = () => {
  const [logOperation] = useLogOperation();

  const logOperationCall = async (
    eventSubCategory: string,
    eventCategory?: string,
    data?: string,
  ) => {
    await logOperation({
      variables: {
        input: {
          data: data,
          event_category: eventCategory,
          event_sub_category: eventSubCategory,
        },
      },
    });
  };

  return logOperationCall;
};
