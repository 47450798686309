import { CompleteRegistration } from './Complete';
import { ConfirmOTPCode } from './MFA';
import RegistrationOutlet from './Registration';
import { SetPassword } from './SetPassword';
import { SSOWelcome } from './SSOWelcome';
import { Welcome } from './Welcome';

export const Registration = {
  main: RegistrationOutlet,
  setPassword: SetPassword,
  confirmOTP: ConfirmOTPCode,
  complete: CompleteRegistration,
  welcome: Welcome,
  ssoWelcome: SSOWelcome,
};

export * from './Complete';
export * from './MFA';
export * from './Registration';
export * from './SetPassword';
export * from './SSOWelcome';
export * from './Welcome';
