import { RefObject, useEffect } from 'react';

interface useSetExporterFormStateProps {
  isUpdating: boolean;
  saveLoading: boolean;
  deleteLoading: boolean;
  isSaveDisabled: boolean;
  formIsUnchanged: boolean;
  shouldDisableSave: boolean;
  formRef: RefObject<HTMLFormElement> | null;
  setIsSaveLoading: (isSaveLoading: boolean) => void;
  setIsSaveDisabled: (isSaveDisabled: boolean) => void;
  setIsFormUnchanged: (isFormUnchanged: boolean) => void;
  setIsCancelDisabled: (isCancelDisabled: boolean) => void;
  setFormRef: (formRef: RefObject<HTMLFormElement> | null) => void;
}

export const useSetExporterFormState = ({
  formRef,
  isUpdating,
  saveLoading,
  deleteLoading,
  isSaveDisabled,
  formIsUnchanged,
  shouldDisableSave,
  setFormRef,
  setIsSaveLoading,
  setIsSaveDisabled,
  setIsFormUnchanged,
  setIsCancelDisabled,
}: useSetExporterFormStateProps) => {
  useEffect(() => {
    setIsSaveDisabled((isSaveDisabled && shouldDisableSave) || isUpdating);
  }, [isSaveDisabled, isUpdating, setIsSaveDisabled, shouldDisableSave]);

  useEffect(() => {
    setIsSaveLoading(saveLoading);
  }, [saveLoading]);

  useEffect(() => {
    setIsFormUnchanged(formIsUnchanged);
  }, [formIsUnchanged, setIsFormUnchanged]);

  useEffect(() => {
    setIsCancelDisabled(saveLoading || deleteLoading || isUpdating);
  }, [deleteLoading, isUpdating, saveLoading]);

  useEffect(() => {
    if (formRef?.current) {
      setFormRef(formRef);
    }
  }, [formRef?.current, setFormRef]);
};
