import { useMutation } from '@apollo/client';
import {
  Button,
  ButtonGroup,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Input } from '@gamma/form-fields';
import { DISABLE_SSO_CONFIRMATION_TEXT } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import {
  DELETE_IDENTITY_PROVIDER,
  DESCRIBE_IDENTITY_PROVIDER,
  ENABLE_DISABLE_USER_POOL_APP_CLIENT_IDP,
} from '@gamma/investigator/queries';
import { Alert, Modal } from '@gamma/overlay';
import { ReactNode, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

interface DisableSsoModalProps {
  children: ReactNode;
}

const { alertTitle, inputLabel, title, toast } =
  i18n.pages.access.disableSsoModal;
const { button } = i18n.pages.access;

export const DisableSsoModal = ({ children }: DisableSsoModalProps) => {
  const { handleSubmit, register, reset } = useForm();
  const { isOpen, onClose, onOpen } = useDisclosure({
    onClose: () => {
      reset();
      setIsButtonDisabled(true);
    },
  });
  const showToast = useToast();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const [deleteIdentityProvider, { loading: deleteIdentityProviderLoading }] =
    useMutation(DELETE_IDENTITY_PROVIDER, {
      fetchPolicy: 'network-only',
      awaitRefetchQueries: true,
      refetchQueries: [DESCRIBE_IDENTITY_PROVIDER, 'describeIdentityProvider'],
      onCompleted: () => {
        onClose();
        showToast({
          title: toast.success.title,
          description: toast.success.description,
          status: 'success',
          isClosable: true,
          position: 'bottom-right',
        });
      },
      onError: (error) => {
        showToast({
          title: toast.error.title,
          description: error?.message,
          status: 'error',
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const [disableSSO, { loading: disableSSOLoading }] = useMutation(
    ENABLE_DISABLE_USER_POOL_APP_CLIENT_IDP,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        deleteIdentityProvider();
      },
      onError: (error) => {
        showToast({
          title: toast.error.title,
          description: error?.message,
          status: 'error',
          isClosable: true,
          position: 'bottom-right',
        });
      },
    },
  );

  const onSubmit = (formData: FieldValues) => {
    if (formData.confirm === DISABLE_SSO_CONFIRMATION_TEXT) {
      disableSSO({
        variables: {
          enable: false,
        },
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      closeOnEsc={!deleteIdentityProviderLoading || !disableSSOLoading}
      closeOnOverlayClick={!deleteIdentityProviderLoading || !disableSSOLoading}
      isCloseDisabled={deleteIdentityProviderLoading || disableSSOLoading}
      isCentered
      size="md"
      title={title}
      body={
        <VStack>
          <Alert status="error" title={alertTitle} />
          <Input
            label={inputLabel}
            isRequired
            {...register('confirm', {
              required: true,
              onChange(event) {
                setIsButtonDisabled(
                  !(event.target.value === DISABLE_SSO_CONFIRMATION_TEXT),
                );
              },
            })}
          />
        </VStack>
      }
      footer={
        <ButtonGroup>
          <Button
            variant="solid"
            colorScheme="gray"
            onClick={onClose}
            isDisabled={disableSSOLoading || deleteIdentityProviderLoading}
          >
            {button.cancel}
          </Button>
          <Button
            variant="solid"
            colorScheme="red"
            onClick={handleSubmit(onSubmit)}
            isDisabled={
              isButtonDisabled ||
              disableSSOLoading ||
              deleteIdentityProviderLoading
            }
            isLoading={disableSSOLoading || deleteIdentityProviderLoading}
          >
            {button.disable}
          </Button>
        </ButtonGroup>
      }
    >
      {children}
    </Modal>
  );
};
