import { createIcon } from '@chakra-ui/react';

export const MLModelsIcon = createIcon({
  displayName: 'MLModelsIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H4Zm7.476 5.49a1.538 1.538 0 1 1 1.026 0v1.176a2.052 2.052 0 0 1 1.462 2.54l1.02.59a1.536 1.536 0 0 1 1.769-.164c.738.425.989 1.364.563 2.097a1.531 1.531 0 0 1-2.097.564 1.532 1.532 0 0 1-.748-1.61l-1.021-.59-.01.01a2.05 2.05 0 0 1-2.915-.014l-1.022.589a1.536 1.536 0 0 1-.744 1.615 1.536 1.536 0 0 1-2.102-.564 1.537 1.537 0 0 1 2.333-1.938l1.023-.589a2.052 2.052 0 0 1 1.463-2.535V9.489Z"
      fill="currentColor"
    />
  ),
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
