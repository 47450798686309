import { Button, useToast } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  Detection,
  GET_DETECTIONS,
  QUERY_DETECTIONS_PAGINATED,
  useAssignDetections,
  useListUsers,
} from '@gamma/investigator/queries';
import { ActionPopover } from '@gamma/overlay';
import { LoadingSpinner } from '@gamma/progress';
import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import { IGetDetectionVariables } from '../../..';
import { DetectionQueryVariables } from '../../../..';

const { controls, detection, toasts } = i18n.pages.detections;

const { assignTo, assignToMe, assignUser } = controls;
const { unassigned } = detection;

interface AssignUserPopoverProps {
  refetchMain?: boolean;
  detectionData?: Detection;
  getDetectionVariables?: IGetDetectionVariables;
  queryDetectionsPaginatedVariables?: DetectionQueryVariables;
}

export const AssignUserPopover = ({
  refetchMain,
  detectionData,
  getDetectionVariables,
  queryDetectionsPaginatedVariables,
}: AssignUserPopoverProps) => {
  const showToastMessage = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  const { user } = useContext(AuthContext);

  const { assignment_info, detection_id, update_info, tenant_info } =
    detectionData || {};

  const {
    loading: listUsersLoading,
    error: listUsersError,
    data: { listUsers = [] } = {},
  } = useListUsers({
    skip: !user?.attributes['custom:tenant_id'],
    variables: {
      tenants: [`${user?.attributes['custom:tenant_id']}`],
      groups: ['admin', 'analyst'],
    },
  });

  const refetchQueries = [
    {
      query: GET_DETECTIONS,
      variables: getDetectionVariables,
    },
  ];

  const [assignDetections, { loading: assignLoading, error: assignError }] =
    useAssignDetections({
      awaitRefetchQueries: true,
      refetchQueries: !queryDetectionsPaginatedVariables
        ? refetchQueries
        : [
            ...refetchQueries,
            {
              query: QUERY_DETECTIONS_PAGINATED,
              variables: queryDetectionsPaginatedVariables,
            },
          ],
      onCompleted: (data) => {
        showToastMessage({
          status: 'success',
          title: toasts.success,
          description: toasts.assigneeSet,
          isClosable: true,
          position: 'bottom-right',
        });
        if (refetchMain) {
          setSearchParams((params) => {
            params.set('refetch_main', 'true');
            return params;
          });
        }
      },
      onError: (error) => {
        showToastMessage({
          status: 'error',
          title: toasts.error,
          description: toasts.assigneeNotSet,
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  return (
    <ActionPopover
      withArrow={false}
      title={assignUser}
      placement="bottom-start"
      isSearchable={true}
      data-testid="assign-detection-popover"
      value={assignment_info?.assigned_to_username}
      isLoading={assignLoading || listUsersLoading}
      options={
        listUsers?.length > 0
          ? [
              ...listUsers
                .filter((user) => !!user.alias)
                .filter((user) => user.status === 'active'),
              { alias: unassigned, username: '' },
            ]
              .map((investUser) => {
                return {
                  label:
                    user?.username === investUser?.username
                      ? assignToMe
                      : investUser?.alias,
                  value: investUser?.username,
                };
              })
              .sort((a, b) => a?.label.localeCompare(b?.label))
          : []
      }
      onApply={(selectedValue) => {
        assignDetections({
          variables: {
            items: [
              {
                assignment_info: {
                  assigned_to_username: selectedValue || null,
                },
                update_info: {
                  last_updated_timestamp: update_info?.last_updated_timestamp,
                },
                detection_id,
                tenant: user?.attributes['custom:tenant_id'],
              },
            ],
          },
        });
      }}
    >
      <Button
        variant="solid"
        colorScheme="gray"
        data-testid="assign-detection-trigger"
        leftIcon={
          assignLoading ? (
            <LoadingSpinner size="sm" />
          ) : (
            <MuiIcon size="sm">account_circle</MuiIcon>
          )
        }
      >
        {assignTo}
      </Button>
    </ActionPopover>
  );
};
