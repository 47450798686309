import { createIcon } from '@chakra-ui/react';

export const Elastic = createIcon({
  displayName: 'Elastic',
  viewBox: '0 0 36 36',
  path: (
    <>
      <g clip-path="url(#a)">
        <path
          fill="#fff"
          d="M36 18.866a7.082 7.082 0 0 0-4.687-6.679c.122-.637.183-1.284.183-1.933C31.495 4.63 26.93.075 21.33.075c-3.285 0-6.33 1.568-8.246 4.215a5.401 5.401 0 0 0-8.702 4.275c0 .654.122 1.293.335 1.886A7.155 7.155 0 0 0 0 17.148a7.099 7.099 0 0 0 4.701 6.695 10.159 10.159 0 0 0 9.966 12.081 10.12 10.12 0 0 0 8.232-4.23 5.318 5.318 0 0 0 3.302 1.157c2.982 0 5.4-2.419 5.4-5.4a5.57 5.57 0 0 0-.335-1.886A7.167 7.167 0 0 0 36 18.866Z"
        />
        <path
          fill="#FED10A"
          d="m14.15 15.52 7.882 3.59 7.942-6.968a8.328 8.328 0 0 0 .168-1.75 8.899 8.899 0 0 0-8.886-8.886 8.876 8.876 0 0 0-7.319 3.865l-1.324 6.862 1.537 3.286Z"
        />
        <path
          fill="#24BBB1"
          d="M5.996 23.858a8.617 8.617 0 0 0-.168 1.78c0 4.916 4.001 8.9 8.916 8.9a8.91 8.91 0 0 0 7.364-3.894l1.309-6.832-1.75-3.347-7.912-3.606-7.759 6.999Z"
        />
        <path
          fill="#EF5098"
          d="m5.95 10.226 5.4 1.278 1.186-6.13a4.24 4.24 0 0 0-2.587-.867A4.264 4.264 0 0 0 5.69 8.764c0 .502.09 1.004.26 1.46"
        />
        <path
          fill="#17A8E0"
          d="M5.479 11.518c-2.404.79-4.093 3.105-4.093 5.645a5.969 5.969 0 0 0 3.834 5.569l7.577-6.847-1.384-2.967-5.934-1.4Z"
        />
        <path
          fill="#93C83E"
          d="M23.479 30.645a4.275 4.275 0 0 0 2.571.882 4.264 4.264 0 0 0 4.26-4.26c.002-.504-.086-1.003-.258-1.476l-5.387-1.264-1.186 6.118Z"
        />
        <path
          fill="#0779A1"
          d="m24.573 23.112 5.935 1.384a5.996 5.996 0 0 0 4.093-5.66 5.951 5.951 0 0 0-3.834-5.554l-7.76 6.802 1.566 3.028Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </>
  ),
  defaultProps: {
    height: '36px',
    width: '36px',
  },
});
