import { Box, ComponentWithAs, IconProps, Text } from '@chakra-ui/react';
import { MODAL_STEPS, ROUTES } from '@gamma/investigator/constants';
import { Alert } from '@gamma/overlay';
import { CognitoUserInterface } from '@gamma/shared/types';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  AuthLoginBody,
  AuthMFABody,
  ConfigureDetailsBody,
} from './AuthModalSteps';
import { IHelperText, SetStepType } from './types';

interface AuthModalBodyProps {
  step: string;
  email: string;
  isButtonLoading: boolean;
  helperTexts: IHelperText;
  loginIcon?: ComponentWithAs<'svg', IconProps>;
  showAlert?: boolean;
  errorMessage: string;
  width?: string;
  isExportNoMfa?: boolean;
  setStep: SetStepType;
  setIsButtonLoading: (e: boolean) => void;
  setIsSignInButtonDisabled: (e: boolean) => void;
  setIsViewInfoButtonDisabled: (e: boolean) => void;
}

export const AuthModalBody = ({
  email,
  step,
  setStep,
  isButtonLoading,
  helperTexts,
  loginIcon,
  showAlert,
  errorMessage,
  width,
  isExportNoMfa,
  setIsSignInButtonDisabled,
  setIsViewInfoButtonDisabled,
  setIsButtonLoading,
}: AuthModalBodyProps) => {
  const [verifyUser, setVerifyUser] = useState<CognitoUserInterface>(
    {} as CognitoUserInterface,
  );

  const location = useLocation();

  switch (step) {
    case MODAL_STEPS.login:
      return (
        <AuthLoginBody
          email={email}
          helperTexts={helperTexts}
          setIsButtonLoading={setIsButtonLoading}
          setStep={setStep}
          setVerifyUser={setVerifyUser}
          setIsSignInButtonDisabled={setIsSignInButtonDisabled}
          loginIcon={loginIcon}
          showAlert={showAlert}
          errorMessage={errorMessage}
          width={width}
        />
      );
    case MODAL_STEPS.mfa:
      return (
        <AuthMFABody
          isButtonLoading={isButtonLoading}
          helperTexts={helperTexts}
          setIsButtonLoading={setIsButtonLoading}
          setIsViewInfoButtonDisabled={setIsViewInfoButtonDisabled}
          verifyUser={verifyUser}
          width={width}
        />
      );
    case MODAL_STEPS.warning:
      return (
        <Box mb={2}>
          <Alert variant="subtle">
            <Text>
              <strong> {helperTexts?.warning?.header} </strong>
            </Text>
            <Text> {helperTexts?.warning?.body}</Text>
          </Alert>
        </Box>
      );
    case MODAL_STEPS.details:
      if (
        isExportNoMfa &&
        location.pathname === ROUTES.systemSettingsIntegrationsAlertExports
      ) {
        return <></>;
      }
      return <ConfigureDetailsBody />;

    default:
      return null;
  }
};
