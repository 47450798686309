import { createIcon } from '@chakra-ui/react';

export const ChevronLeftIcon = createIcon({
  displayName: 'ChevronLeftIcon',
  d: 'M15.41 16.58 10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.42Z',
  viewBox: '0 0 24 24',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
