import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

import { UserSuccessOutput } from '../user';

export interface IEnableDisableUsers {
  enableDisableUsers: UserSuccessOutput;
}

export const ENABLE_DISABLE_USERS = gql`
  mutation enableDisableUsers($disable: Boolean!, $usernames: [String!]!) {
    enableDisableUsers(disable: $disable, usernames: $usernames) {
      status_code
      body {
        data
        message
        success
      }
    }
  }
`;

export const useEnableDisableUsers = (
  options: MutationHookOptions<IEnableDisableUsers, OperationVariables>,
) => {
  return useMutation(ENABLE_DISABLE_USERS, options);
};
