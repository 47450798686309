import { createIcon } from '@chakra-ui/react';

export const ThumbUpIcon = createIcon({
  displayName: 'ThumbUpIcon',
  viewBox: '0 0 20 20',
  path: (
    <path
      d="M15.0001 17.5002H5.83342V6.66683L11.6667 0.833496L12.7084 1.87516C12.8056 1.97238 12.8855 2.10433 12.948 2.271C13.0105 2.43766 13.0417 2.59738 13.0417 2.75016V3.04183L12.1251 6.66683H17.5001C17.9445 6.66683 18.3334 6.8335 18.6667 7.16683C19.0001 7.50016 19.1667 7.88905 19.1667 8.3335V10.0002C19.1667 10.0974 19.1529 10.2016 19.1251 10.3127C19.0973 10.4238 19.0695 10.5279 19.0417 10.6252L16.5417 16.5002C16.4167 16.7779 16.2084 17.0141 15.9167 17.2085C15.6251 17.4029 15.3195 17.5002 15.0001 17.5002ZM7.50008 15.8335H15.0001L17.5001 10.0002V8.3335H10.0001L11.1251 3.75016L7.50008 7.37516V15.8335ZM5.83342 6.66683V8.3335H3.33341V15.8335H5.83342V17.5002H1.66675V6.66683H5.83342Z"
      fill="#FCFCFC"
    />
  ),
  defaultProps: {
    width: '20px',
    height: '20px',
    fill: 'none',
  },
});
