import { usePrevious } from '@gamma/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

export interface DataListInitialState {
  pageIndex?: number;
  pageSize?: number;
}

interface UseDataListPaginationProps {
  dataLength: number;
  pageCount?: number;
  autoResetPage: boolean;
  initialState?: DataListInitialState;
  onFetchData?: (pageIndex: number, pageSize: number) => void;
}

interface UseDataListPaginationReturn {
  pageIndex: number;
  pageSize: number;
  setPageIndex: (pageIndex: number) => void;
  setPageSize: (pageSize: number) => void;
  canPrevPage: boolean;
  canNextPage: boolean;
  nextPage: () => void;
  prevPage: () => void;
  goToPage: (page: number | ((pageIndex: number) => number)) => void;
  pageCount: number;
}

export const useDataListPagination = ({
  dataLength,
  pageCount: manualPageCount,
  initialState,
  autoResetPage,
  onFetchData,
}: UseDataListPaginationProps): UseDataListPaginationReturn => {
  const [pageIndex, setPageIndex] = useState<number>(
    initialState?.pageIndex || 0,
  );
  const [pageSize, setPageSize] = useState<number>(
    initialState?.pageSize || 10,
  );
  // Calculate the total number of pages based on data length and page size
  const pageCount = useMemo(() => {
    if (manualPageCount) {
      return manualPageCount;
    }
    return Math.ceil(dataLength / pageSize) || 1;
  }, [dataLength, pageSize, manualPageCount]);

  // Reset the page index to 0 when data length changes (if autoResetPage is true)
  useEffect(() => {
    if (autoResetPage) {
      setPageIndex(0);
    }
  }, [dataLength, autoResetPage]);

  const prevPageIndex = usePrevious(pageIndex);
  const prevPageSize = usePrevious(pageSize);
  // Fetch data when the page index or page size changes
  useEffect(() => {
    if (pageIndex !== prevPageIndex || pageSize !== prevPageSize) {
      onFetchData?.(pageIndex, pageSize);
    }
  }, [onFetchData, pageIndex, prevPageIndex, pageSize, prevPageSize]);

  // Memoized value to determine if the user can navigate to the previous page
  const canPrevPage = useMemo(() => pageIndex > 0, [pageIndex]);

  // Memoized value to determine if the user can navigate to the next page
  const canNextPage = useMemo(() => {
    return pageIndex + 1 !== pageCount;
  }, [pageIndex, pageCount]);

  // Callback to navigate to the next page
  const nextPage = useCallback(() => {
    setPageIndex(pageIndex + 1);
  }, [pageIndex]);

  // Callback to navigate to the previous page
  const prevPage = useCallback(() => {
    setPageIndex(pageIndex - 1);
  }, [pageIndex]);

  // Callback to navigate to a specific page or update the page index using a function
  const goToPage = useCallback(
    (page: number | ((pageIndex: number) => number)) => {
      setPageIndex(page);
    },
    [],
  );

  const handlePageSizeChange = useCallback(
    (newPageSize: number) => {
      if (!manualPageCount) {
        const pageCount = Math.ceil(dataLength / newPageSize) || 1;
        if (pageIndex > pageCount - 1) {
          setPageIndex(pageCount - 1);
        }
      }
    },
    [dataLength, manualPageCount, pageIndex],
  );

  useEffect(() => {
    if (pageSize !== prevPageSize) {
      handlePageSizeChange(pageSize);
    }
  }, [
    pageSize,
    prevPageSize,
    manualPageCount,
    pageIndex,
    handlePageSizeChange,
  ]);

  return {
    pageIndex,
    pageSize,
    setPageIndex,
    setPageSize,
    canPrevPage,
    canNextPage,
    nextPage,
    prevPage,
    goToPage,
    pageCount,
  };
};
