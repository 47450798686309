import { createIcon } from '@chakra-ui/react';

export const TerminalIcon = createIcon({
  displayName: 'TerminalIcon',
  d: 'M8.68902 12L4.80612 15.8829L6.22612 17.2929L11.519 12L6.22612 6.70712L4.80612 8.11712L8.68902 12ZM19.1938 14.7929L12.6938 14.7929L12.6938 16.8554L19.1938 16.8554L19.1938 14.7929Z',
  viewBox: '0 0 24 24',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
