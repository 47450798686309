import {
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Alert as ChakraAlert,
  AlertProps as ChakraAlertProps,
  CloseButton,
  Stack,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { ReactNode, useMemo } from 'react';

export interface AlertProps<IsClosable extends boolean = false> {
  /** The status of the Alert, controls the color scheme */
  status?: ChakraAlertProps['status'];
  /** The style variant of the Alert */
  variant?: ChakraAlertProps['variant'];
  /** The bold text to place before the body text */
  title?: ReactNode;
  /** The Alert body text */
  children?: ReactNode;
  /** Specify if the Alert should have a close button */
  isClosable?: IsClosable;
  /** The method to be called when the close button is clicked */
  onClose?: IsClosable extends true ? () => void : undefined;
  isOpen?: IsClosable extends true ? boolean : undefined;
  showIcon?: boolean;
  /** Mui icon string to override status based icon */
  icon?: string;
  ['data-testid']?: string;
}

export const Alert = <IsClosable extends boolean = false>({
  status = 'info',
  variant = 'solid',
  title,
  children,
  isClosable,
  onClose,
  isOpen = true,
  showIcon = true,
  icon,
  'data-testid': dataTestId = 'gamma-alert',
}: AlertProps<IsClosable>) => {
  if ((!title && !children) || !isOpen) {
    return null;
  }

  const statusIcon = useMemo(() => {
    if (icon) {
      return icon;
    }

    switch (status) {
      case 'error':
        return 'error';
      case 'info':
        return 'info';
      case 'success':
        return 'check_circle';
      case 'warning':
        return 'error';
      default:
        return 'info';
    }
  }, [status, icon]);
  return (
    <ChakraAlert status={status} variant={variant} data-testid={dataTestId}>
      {showIcon && (
        <AlertIcon>
          <MuiIcon mt={0.5}>{statusIcon}</MuiIcon>
        </AlertIcon>
      )}
      <Stack justifyItems="center" h="100%" w="full" spacing={0}>
        {title && (
          <AlertTitle data-testid={`${dataTestId}-title`}>{title}</AlertTitle>
        )}
        {children && (
          <AlertDescription data-testid={`${dataTestId}-description`}>
            {children}
          </AlertDescription>
        )}
      </Stack>
      {isClosable && (
        <CloseButton
          alignSelf="flex-start"
          onClick={onClose}
          data-testid={`${dataTestId}-close-button`}
          ml="auto"
          h={6}
          w={6}
        >
          <MuiIcon>close</MuiIcon>
        </CloseButton>
      )}
    </ChakraAlert>
  );
};
