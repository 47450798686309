import { REGEX } from '@gamma/investigator/constants';
import { escapeRegExp } from 'lodash';

const { DOUBLE_QUOTES, ESCAPED_PERIODS } = REGEX;

export const escapeSpecialChars = ({
  chars,
  unescapePeriods,
}: {
  chars: string;
  unescapePeriods?: boolean;
}) => {
  let escapedChars = escapeRegExp(chars);
  if (escapedChars.includes('"')) {
    escapedChars = escapedChars?.replace(DOUBLE_QUOTES, '\\"');
  }

  if (unescapePeriods) {
    escapedChars = escapedChars.replace(ESCAPED_PERIODS, '.');
  }

  return escapedChars;
};
