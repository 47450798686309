import { UserCookieAcceptance } from '@gamma/investigator/queries';
import { createContext, Dispatch, SetStateAction } from 'react';

export interface CookieContext {
  isOpen: boolean;
  initialStep: 1 | 2 | undefined;
  getUserCookieAcceptance: UserCookieAcceptance[];
  isLoaded?: boolean;
  setCookieConfig?: Dispatch<SetStateAction<CookieContext>>;
}

export const CookieContext = createContext<CookieContext>({
  isLoaded: false,
  isOpen: false,
  initialStep: undefined,
  setCookieConfig: undefined,
  getUserCookieAcceptance: [],
});
