import { ComponentStyleConfig } from '@chakra-ui/react';

export const SelectStyles: ComponentStyleConfig = {
  variants: {
    pagination: (props) => {
      return {
        field: {
          pl: 2,
          pr: '1.25rem',
          border: 'solid 1px',
          borderColor: 'border.1',
          _focus: {
            borderColor: 'blue.500',
            boxShadow: '0px 0px 0px 1.5px #0093ee',
          },
        },
        icon: {
          w: 'auto',
        },
      };
    },
  },
};
