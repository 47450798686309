import { createIcon } from '@chakra-ui/react';

export const CalendarIcon = createIcon({
  displayName: 'CalendarIcon',
  viewBox: '0 0 24 24',
  d: 'M19 19H5V8h14v11ZM16 1v2H8V1H6v2H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-1V1',
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
