import {
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useInterval,
  VStack,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { PageHeader } from '@gamma/investigator/components';
import { useDateRangeContext } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { useQueryDetectionsTotalItems } from '@gamma/investigator/queries';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { DetectionQueryVariables } from '../../Detections';

const { controls, detection } = i18n.pages.detections;

const { assignTo, closeDetection, suppressEntity } = controls;

const {
  detection: detectionHeading,
  detectionDescription,
  performActions,
  tuneSystem,
  updateStatus,
  updateAssignment,
} = detection;

export interface DetectionsPageHeaderProps {
  queryVariables?: DetectionQueryVariables;
  isLoading?: boolean;
}

export const formatTotalItemsQuery = ({
  startDate,
  endDate,
  query,
}: {
  startDate: number;
  endDate: number;
  query: string;
}) => {
  if (!query) {
    return '';
  }

  const queryObject = JSON.parse(query);

  const mustArray = queryObject?.query?.bool?.must;

  const updatedMustArray = mustArray?.map((item: any) => {
    if (item?.range?.latest_start_timestamp) {
      item.range.latest_start_timestamp.gte = startDate;
    } else if (item?.range?.earliest_start_timestamp) {
      item.range.earliest_start_timestamp.lte = endDate;
    }

    return item;
  });

  const resultQuery = JSON.stringify({
    query: {
      bool: {
        must: updatedMustArray,
      },
    },
  });

  return resultQuery;
};

export const DetectionsPageHeader = ({
  queryVariables,
  isLoading,
}: DetectionsPageHeaderProps) => {
  const { showCustomDateRangePicker } = useDateRangeContext();
  const [totalItems, setTotalItems] = useState<number>(0);
  const [currentTimeStamp, setCurrentTimeStamp] = useState<number>(
    moment().unix(),
  );
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const date_end = params.get('end');

  const totalItemsQuery = useMemo(
    () =>
      formatTotalItemsQuery({
        startDate: Number(date_end),
        endDate: currentTimeStamp,
        query: queryVariables?.query ?? '',
      }),
    [currentTimeStamp, queryVariables, date_end],
  );

  useQueryDetectionsTotalItems({
    skip: !queryVariables?.query || !totalItemsQuery || !currentTimeStamp,
    variables: {
      ...queryVariables,
      query: totalItemsQuery,
    },
    onCompleted: (data) => {
      if (totalItems !== data?.queryDetectionsPaginated?.total_items) {
        setTotalItems(data?.queryDetectionsPaginated?.total_items ?? 0);
      }
    },
  });

  useInterval(
    () => setCurrentTimeStamp(moment().unix()),
    showCustomDateRangePicker ? null : 15000,
  );

  return (
    <PageHeader totalItems={totalItems} isLoading={isLoading}>
      <Flex alignItems="center">
        <Text mr={2}>{i18n.navigation.detections}</Text>
        <Popover isLazy trigger="hover" openDelay={300}>
          <PopoverTrigger>
            <span>
              <MuiIcon size="sm">help</MuiIcon>
            </span>
          </PopoverTrigger>
          <PopoverContent w="332px">
            <PopoverArrow />
            <PopoverCloseButton fontSize="12px" size="sm" />
            <PopoverHeader>
              <Text textStyle="body-md" fontWeight="600">
                {detectionHeading}
              </Text>
            </PopoverHeader>
            <PopoverBody pb={4}>
              <Text as="p" mb={2} textStyle="body-md">
                {detectionDescription}
              </Text>
              <Text as="p" mb={2} textStyle="body-md">
                {performActions}:
              </Text>
              <VStack alignItems="flex-start">
                <HStack>
                  <MuiIcon>check_circle</MuiIcon>
                  <Text textStyle="body-md" fontWeight="600">
                    {closeDetection}:
                  </Text>
                  <Text textStyle="body-md">{updateStatus}</Text>
                </HStack>
                <HStack>
                  <MuiIcon>visibility</MuiIcon>
                  <Text textStyle="body-md" fontWeight="600">
                    {suppressEntity}:
                  </Text>
                  <Text textStyle="body-md">{tuneSystem}</Text>
                </HStack>
                <HStack>
                  <MuiIcon>account_circle</MuiIcon>
                  <Text textStyle="body-md" fontWeight="600">
                    {assignTo}:
                  </Text>
                  <Text textStyle="body-md">{updateAssignment}</Text>
                </HStack>
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
    </PageHeader>
  );
};
