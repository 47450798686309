import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

export const LOG_OPERATION = gql`
  mutation logOperation($input: LogOperationInput!) {
    logOperation(input: $input) {
      body {
        data
        message
        success
      }
      status_code
    }
  }
`;

export const useLogOperation = (
  options?: MutationHookOptions<any, OperationVariables>,
) => {
  return useMutation(LOG_OPERATION, options);
};
