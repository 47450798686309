import { VStack } from '@chakra-ui/react';
import { PageHeader } from '@gamma/investigator/components';
import { ROUTES } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import { Board, Column } from '@gamma/layout';
import { ErrorBoundary } from '@gamma/overlay';
import { NavTab, NavTabs } from '@gamma/tabs';
import { Outlet, useLocation } from 'react-router-dom';
import { MoreDashboards } from './HumioDashboardOverview';

const navigation = i18n.navigation;

export const Overview = () => {
  const { pathname } = useLocation();

  const tabs: NavTab[] = [
    {
      title: navigation.securityOverview,
      to: ROUTES.home,
      isDisabled: false,
      'data-testid': 'security-overview-tab',
    },
    {
      title: navigation.networkOverview,
      to: ROUTES.networkOverview,
      isDisabled: false,
      'data-testid': 'network-overview-tab',
    },
    {
      title: navigation.securityPosture,
      to: ROUTES.securityPosture,
      isDisabled: false,
      'data-testid': 'security-posture-tab',
    },
    {
      title: <MoreDashboards />,
      to: ROUTES.moreOverview,
      isDisabled: true,
      'data-testid': 'more-dashboards-tab',
    },
  ];
  return (
    <ErrorBoundary page="Overview" styleClass="main">
      <Board
        data-testid="overview"
        height="inherit"
        flexWrap="inherit"
        flexDirection="column"
        overflowX="hidden"
        overflowY="scroll"
      >
        <Column data-testid="overview-header" flex="auto">
          <PageHeader
            showDateSelection={pathname.includes(ROUTES.home) ? true : false}
          >
            {i18n.navigation.overview}
          </PageHeader>
          <VStack>
            <NavTabs tabs={tabs} isFitted={false} variant="line" basePath="/" />
          </VStack>
        </Column>
        <Column flex="auto" height="inherit" overflow="auto" mb={2} padding={0}>
          <Outlet />
        </Column>
      </Board>
    </ErrorBoundary>
  );
};
